/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { withTranslation } from "react-i18next";
import Loader from "react-loader";
import PageHeader from "./documentation-styled-components/PageHeader";
import URLs from "./documentation-styled-components/URLs";
import SampleNav from "./documentation-styled-components/SampleNav";
import SampleParameters from "./documentation-styled-components/SampleParameters";
import SampleBody from "./documentation-styled-components/SampleBody";
import SampleRequests from "./documentation-styled-components/SampleRequests";
import SampleResponses from "./documentation-styled-components/SampleResponses";
import SampleNotes from "./documentation-styled-components/SampleNotes";
import SampleLinks from "./documentation-styled-components/SampleLinks";

const apiSampleCss = {
  padding: "1em 200px 1em 1em"
};

class ApiSampleView extends React.Component {
  componentDidMount() {
    const { filename, fetchApiSample } = this.props;

    if (filename) {
      fetchApiSample(filename);
    }
  }

  componentDidUpdate(prevProps) {
    const { filename, fetchApiSample } = this.props;

    if (filename && filename !== prevProps.filename) {
      fetchApiSample(filename);
    }
  }

  render() {
    const { t, isLoading, apiSample } = this.props;
    const api = apiSample && apiSample.Api ? apiSample.Api[0] : null;

    return (
      <div css={apiSampleCss}>
        <Loader loaded={!isLoading}>
          {api && (
            <React.Fragment>
              <SampleNav documentation={api.Documentation} />
              <PageHeader
                title={
                  t("documentation:API Reference") +
                  " - " +
                  t(`documentation-remote:${api.ApiName || ""}`)
                }
                description={t(
                  `documentation-remote:${api.Documentation.Description}`
                )}
              />
              <URLs
                title={t("documentation:URLs")}
                servers={api.Server}
                method={api.Method}
                path={api.Path}
              />
              <SampleParameters
                id="headers"
                title={t("documentation:Request Headers")}
                params={api.Documentation["Header Parameters"]}
              />
              <SampleParameters
                id="parameters"
                title={t("documentation:Request Parameters")}
                params={api.Documentation.Parameters}
              />
              {api.Documentation["Request Body"] && (
                <SampleBody body={api.Documentation["Request Body"]} />
              )}
              {api.Documentation["Request Sample"] && (
                <SampleRequests sample={api.Documentation["Request Sample"]} />
              )}
              {api.Documentation["Response Sample"] && (
                <SampleResponses
                  sample={api.Documentation["Response Sample"]}
                />
              )}
              {Object.keys(api.Documentation["Usage Notes"]).length > 0 && (
                <SampleNotes notes={api.Documentation["Usage Notes"]} />
              )}
              {Object.keys(api.Documentation["Extranl Docs"]).length > 0 && (
                <SampleLinks links={api.Documentation["Extranl Docs"]} />
              )}
            </React.Fragment>
          )}
        </Loader>
      </div>
    );
  }
}

export default withTranslation(["documentation", "documentation-remote"])(
  ApiSampleView
);
