/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SelectField from "../../../../components/forms/fields/SelectField";

const locationSelectCss = {
  alignItems: "center",
  display: "flex",
  flexGrow: 2,
  height: 48,
  margin: "4px 0 0 auto",
  maxWidth: 300,

  ".Select": {
    width: "100%"
  }
};

const LocationSelect = props => {
  const { t } = useTranslation("adc-details");
  const { searchLocations, pushAdcDetailsView } = props;

  const changeLocation = selectedOption => {
    pushAdcDetailsView(selectedOption.value);
  };

  return (
    <div css={locationSelectCss} data-qa="select-change-locations">
      <SelectField
        isAsync={true}
        placeholder={t("adc-details:Change locations")}
        loadOptions={searchLocations}
        onChange={changeLocation}
        isClearable={false}
        defaultOptions={false}
        cacheOptions={false}
        minWidth="100%"
      />
    </div>
  );
};

LocationSelect.propTypes = {
  searchLocations: PropTypes.func,
  pushAdcDetailsView: PropTypes.func
};

export default LocationSelect;
