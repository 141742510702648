/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";

import {
  TiStarOutline,
  TiStarFullOutline,
  TiPencil,
  TiTrash
} from "react-icons/ti";

import { withTranslation, useTranslation } from "react-i18next";
import { MediaQueries, useIsMediumAndDown } from "../responsive";

import colors from "../../styles/colors";
import SelectField from "../forms/fields/SelectField";

import DownloadDataButton from "./DownloadDataButton";

const EditSavedSearchButtons = ({ disabled, onEdit, onDelete }) => {
  const { t } = useTranslation("components");

  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <div
        css={{
          fontSize: 12.5,
          fontStyle: "italic",
          marginRight: 10,
          textDecoration: "underline",
          color: colors.text.LIGHTER_GRAY,
          alignItems: "center",
          display: "flex",
          cursor: "pointer"
        }}
        disabled={disabled}
        onClick={() => onEdit()}
      >
        {t("mt-shared:Edit")}{" "}
        <TiPencil style={{ fontSize: "1.9em", marginLeft: "0.1em" }} />
      </div>
      <div
        css={{
          fontSize: 12.5,
          fontStyle: "italic",
          marginRight: 10,
          textDecoration: "underline",
          color: colors.text.LIGHTER_GRAY,
          alignItems: "center",
          display: "flex",
          cursor: "pointer"
        }}
        disabled={disabled}
        onClick={() => onDelete()}
      >
        {t("mt-shared:Delete")}{" "}
        <TiTrash style={{ fontSize: "1.9em", marginLeft: "0.1em" }} />
      </div>
    </div>
  );
};

const initialState = {
  // TODO: When the search view is complete this should be passed as a prop from there
  savedSearchName: "",
  searchParamsChangedDummy: false,
  editing: false,
  showEditSearchModal: false,
  showDeleteSearchModal: false,
  savedSearch: null,
  name: "",
  searchObj: { label: "", value: "" },
  searchCategory: "everything"
};

const SaveCurrentSearch = ({
  changeHandler,
  deleteConfirmHandler,
  savedSearch,
  editHandler,
  savedSearchOptions,
  starIcon,
  t
}) => {
  const isMobile = useIsMediumAndDown();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        maxWidth: 500,
        alignItems: "center",
        border: `1px solid ${colors.background.LIGHT_GRAY}`,
        padding: isMobile ? "5px 0 5px 10px" : "10px 0 10px 15px",
        borderRadius: "3px",
        width: isMobile ? "100%" : null
      }}
    >
      {!savedSearch ? (
        <div
          onClick={() => editHandler()}
          css={{
            color: colors.text.LIGHTER_GRAY,
            fontSize: 12.5,
            fontStyle: "italic",
            textDecoration: "underline",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            marginRight: isMobile ? null : "1em",
            marginBottom: isMobile ? "1em" : null
          }}
        >
          {t("components:Save Current Search")}
          {starIcon}
        </div>
      ) : (
        <EditSavedSearchButtons
          disabled={!savedSearch}
          onEdit={editHandler}
          onDelete={deleteConfirmHandler}
        />
      )}
      <div style={{ width: isMobile ? "100%" : 300, marginRight: "1em" }}>
        <SelectField
          options={savedSearchOptions}
          stateValue={savedSearch ? savedSearch.id : null}
          onChange={changeHandler}
          placeholder={t("components:Saved Searches")}
          placeholderFontStyle="italic"
          boxShadow="0px 3px 14px 0.5px rgba(0,0,0,0.05)"
          borderWidth="0.5px"
          borderColor="#f2f2f2"
          fontSize={12.5}
        />
      </div>
    </div>
  );
};

class SavedSearchSelect extends Component {
  static propTypes = {
    clearSavedSearch: PropTypes.func,
    deleteSearch: PropTypes.func.isRequired,
    editSearch: PropTypes.func.isRequired,
    exportSearch: PropTypes.func,
    fetchSavedSearches: PropTypes.func.isRequired,
    loadSavedSearch: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    resetSavedSearch: PropTypes.func.isRequired,
    saveSearch: PropTypes.func.isRequired,
    savedSearch: PropTypes.object,
    savedSearches: PropTypes.array,
    searchCategory: PropTypes.object,
    searchFilters: PropTypes.object,
    searchParamsChanged: PropTypes.object,
    searchText: PropTypes.string,
    selectSavedSearch: PropTypes.func.isRequired,
    solutionId: PropTypes.string,
    t: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = initialState;

    this.onSave = this.onSave.bind(this);
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onCancelEdit = this.onCancelEdit.bind(this);
    this.onExport = this.onExport.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { fetchSavedSearches } = this.props;
    fetchSavedSearches();
  }

  componentDidUpdate() {
    const {
      savedSearch,
      loadSavedSearch,
      clearSavedSearch,
      searchParamsChanged
    } = this.props;

    if (this.state.showEditSearchModal) {
      return;
    }

    searchParamsChanged && clearSavedSearch();

    if (!savedSearch && this.state.savedSearch) {
      this.setState(initialState);
    }

    if (savedSearch && savedSearch !== this.state.savedSearch) {
      this.setState({
        savedSearch: savedSearch,
        name: savedSearch.name,
        showFilters: true
      });

      loadSavedSearch(savedSearch);
    }
  }

  getSavedSearchOptions() {
    const { savedSearches } = this.props;

    return (
      savedSearches &&
      savedSearches.map(savedSearch => ({
        label: savedSearch.name,
        value: savedSearch.id
      }))
    );
  }

  selectedSavedSearch(selectedId) {
    const { savedSearches, selectSavedSearch, solutionId } = this.props;
    const selectedSavedSearch = savedSearches.find(
      savedSearch => savedSearch.id === selectedId
    );

    selectSavedSearch(solutionId, selectedSavedSearch);
  }

  onEdit() {
    const { savedSearch } = this.props;

    const savedSearchName = savedSearch ? savedSearch.name : "";
    this.setState({
      savedSearchName: savedSearchName,
      editing: true,
      showEditSearchModal: true
    });
  }

  onExport() {
    const { exportSearch, solutionId } = this.props;
    exportSearch(solutionId);
  }

  showDeleteConfirm() {
    this.setState({ showDeleteSearchModal: true });
  }

  confirmDelete() {
    const { savedSearch, deleteSearch, resetSavedSearch } = this.props;
    deleteSearch(savedSearch.id);
    resetSavedSearch();
    this.setState({ showDeleteSearchModal: false });
  }

  onSave() {
    const {
      savedSearch,
      saveSearch,
      editSearch,
      searchFilters,
      searchCategory,
      searchText
    } = this.props;

    const { editing, savedSearchName } = this.state;

    if (editing && savedSearch) {
      editSearch(savedSearch.id, savedSearchName, {
        ...searchFilters,
        [searchCategory.queryKey]: searchText
      });
    } else {
      saveSearch(savedSearchName, {
        ...searchFilters,
        [searchCategory.queryKey]: searchText
      });
    }

    this.setState({ editing: false });
  }

  onCancelEdit() {
    this.setState({ editing: false });
  }

  onChange(selected) {
    const { resetSavedSearch } = this.props;
    // When selected it null it means that button 'x' button
    // was clicked or backspace (or delete) was pressed and
    // data was cleaned, so we may just reset
    if (selected === null) {
      resetSavedSearch();
    }
    return this.selectedSavedSearch(selected.value);
  }

  render() {
    const { readOnly, t } = this.props;
    const {
      showEditSearchModal,
      showDeleteSearchModal,
      saved,
      savedSearch
    } = this.state;

    // const total = savedSearches.length;

    const starIcon = saved ? (
      <TiStarFullOutline
        style={{
          fontSize: "1.8em",
          marginLeft: "0.2em",
          color: colors.text.YELLOW
        }}
      />
    ) : (
      <TiStarOutline
        style={{
          fontSize: "1.8em",
          marginLeft: "0.2em"
        }}
      />
    );

    return (
      <div
        css={{
          display: "flex",
          alignItems: "center",
          [MediaQueries.smallAndDown]: {
            justifyContent: "center",
            width: "100%"
          },
          [MediaQueries.mediumAndUp]: {
            justifyContent: "flex-end",
            width: "unset"
          }
        }}
      >
        {!readOnly ? (
          <div
            css={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              [MediaQueries.smallAndDown]: {
                flexDirection: "column"
              },
              [MediaQueries.mediumAndUp]: {
                flexDirection: "row"
              }
            }}
          >
            <DownloadDataButton onClick={this.onExport} />
            <SaveCurrentSearch
              changeHandler={this.onChange}
              deleteConfirmHandler={this.showDeleteConfirm}
              savedSearch={savedSearch}
              editHandler={this.onEdit}
              savedSearchOptions={this.getSavedSearchOptions()}
              starIcon={starIcon}
              t={t}
            />
          </div>
        ) : (
          <div
            css={{
              width: 300,
              [MediaQueries.mediumAndUp]: { marginLeft: "auto" }
            }}
          >
            <SelectField
              options={this.getSavedSearchOptions()}
              stateValue={savedSearch ? savedSearch.id : null}
              onChange={this.onChange}
              placeholder={t("components:Saved Searches")}
              placeholderFontStyle="italic"
              boxShadow="0px 3px 14px 0.5px rgba(0,0,0,0.05)"
              borderWidth="0.5px"
              borderColor="#f2f2f2"
              fontSize={12.5}
            />
          </div>
        )}

        <this.props.editModal
          show={showEditSearchModal}
          hide={() => this.setState({ showEditSearchModal: false })}
          savedSearch={savedSearch}
        />

        <this.props.deleteModal
          show={showDeleteSearchModal}
          hide={() => this.setState({ showDeleteSearchModal: false })}
          handleConfirm={this.confirmDelete}
        />
      </div>
    );
  }
}

export default withTranslation(["components"])(SavedSearchSelect);
