/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SelectFilter from "../../../components/tables/Filter/SelectFilter";
import DomainDataState from "../../domain-data/DomainDataState";

// Dropdown filter allowing selection of Shipper on Manage Locations search results table
const ShipperFilter = props => {
  const { value, onChange } = props;

  const [selectedShipperId, setSelectedShipperId] = useState(null);
  useEffect(() => {
    setSelectedShipperId(value);
  }, [value]);

  const shippers = useSelector(DomainDataState.selectors.getShippers);
  const options = shippers.map(s => {
    return { value: s.id, label: s.name };
  });

  // todo clear icon not showing up

  return (
    <SelectFilter
      placeholder={null}
      value={selectedShipperId}
      options={options}
      onChange={selected => {
        const value = selected ? selected.value : null;
        setSelectedShipperId(value);
        onChange(value);
      }}
    />
  );
};

export default ShipperFilter;
