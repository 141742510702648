/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import Colors from "../../../styles/colors";
import {
  PanelContainer,
  Panel
} from "../../../vendor/signal-widgets/components/panel";

const columnCss = {
  width: "calc(33.33% - 2em)",
  margin: "0 1em",
  marginBottom: "1em"
};

const containerCss = {
  cursor: "pointer"
};

const panelCss = {
  border: "none"
};

const panelHeaderCss = {
  backgroundColor: Colors.background.DARK_GRAY,
  color: "white",
  fontWeight: "300",
  padding: "1em"
};

const descriptionCss = {
  padding: "1em",
  color: Colors.background.DARK_BLUE
};

const LinkPanel = props => {
  const { title, description, file, type, baseUrl, eventHandler } = props;

  return (
    <div css={columnCss}>
      <PanelContainer
        style={containerCss}
        onClick={() => eventHandler(file, type, baseUrl)}
      >
        <Panel
          title={title}
          canMaximize={false}
          style={panelCss}
          headerStyle={panelHeaderCss}
        >
          <div css={descriptionCss}>{description}</div>
        </Panel>
      </PanelContainer>
    </div>
  );
};

LinkPanel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  file: PropTypes.string,
  type: PropTypes.string,
  eventHandler: PropTypes.func.isRequired
};
export default LinkPanel;
