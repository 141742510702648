import { connect } from "react-redux";
import CallbackView from "./CallbackView";

function mapStateToProps(state) {
  return {
    authCode: state.callback.authCode,
    authState: state.callback.authState,
    error: state.callback.error,
    authType: state.callback.authType
  };
}

export default connect(mapStateToProps)(CallbackView);
