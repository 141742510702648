import buildSavedSearchState from "../../components/saved-search/SavedSearchStateBuilder";
import { SEARCH_CATEGORIES } from "./FinVehicleSearchCategoryDefs";
import apiUrl from "../../api-url";
import SearchState from "./FinVehicleSearchBarState";

const STORE_MOUNT_POINT = "fvSavedSearch";

export default buildSavedSearchState({
  topic: STORE_MOUNT_POINT,
  savedSearchUrl: apiUrl("/preferences-ng/search-history"),
  searchType: "ENTITY",
  searchCategories: SEARCH_CATEGORIES,
  actions: {
    clearSearchFilters: SearchState.actionCreators.clearSearchFilters,
    setSearchCategory: SearchState.actionCreators.setSearchCategory,
    setSearchText: SearchState.actionCreators.setSearchText,
    setSearchFilter: SearchState.actionCreators.setSearchFilter
  }
});
