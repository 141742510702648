import { connect } from "react-redux";
import EditSavedSearchModal from "../../components/saved-search/EditSavedSearchModal";
import ShipmentSavedSearchState from "./ShipmentSavedSearchState";
import ShipmentSearchBarState from "./ShipmentSearchBarState";
import SearchBarContainer from "./ShipmentSearchBarContainer";
import FilterSectionContainer from "./ShipmentSearchFiltersContainer";

const mapStateToProps = state => {
  const {
    getSearchFilters,
    getSearchCategory,
    getSearchText
  } = ShipmentSearchBarState.selectors;

  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer
  };
};

const mapDispatchToProps = dispatch => {
  const {
    saveSearch,
    editSearch,
    deleteSearch
  } = ShipmentSavedSearchState.actionCreators;

  return {
    saveSearch: (name, search) => dispatch(saveSearch(name, search)),
    editSearch: (id, name, search) => dispatch(editSearch(id, name, search)),
    deleteSearch: id => dispatch(deleteSearch(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSavedSearchModal);
