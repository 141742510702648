/**
 * Here Maps Shapes Implementation
 */
import polylabel from "polylabel";

import MouseMarkerIcon from "../../../../assets/geofence-mouse-marker.svg";
import FirstMarkerIcon from "../../../../assets/geofence-marker-0.svg";
import MarkerIcon from "../../../../assets/geofence-marker.svg";

import ShapesPlatformInterface, {
  MARKER_PARAMS,
  pointList,
  computeCircleMetrics
} from "../ShapesPlatformInterface";

import Colors from "../../../../styles/colors";

const { fillColor, strokeColor } = Colors.geofence;

class HereMapsShapes extends ShapesPlatformInterface {
  createGroup(items = [], id = null) {
    const group = new this.api.map.Group();
    if (items) {
      group.addObjects(items);
    }
    if (id) {
      group.setData({ groupId: id });
    }
    return group;
  }

  createCircle(
    latitude,
    longitude,
    fillColor,
    strokeColor,
    radiusMeters,
    draggable,
    selected
  ) {
    const style = selected ? {} : { fillColor, strokeColor };
    const geofenceCircle = new this.api.map.Circle(
      { lat: latitude, lng: longitude },
      radiusMeters,
      {
        style: style
      }
    );
    geofenceCircle.draggable = draggable;
    return geofenceCircle;
  }

  createPolygonDrawingDraft(
    locationId,
    polygonIndex,
    mouseGeoCoords,
    tracePoints,
    onPolygonDrawEnd
  ) {
    if (!mouseGeoCoords) {
      return null;
    }

    const groupId = `tracing:geofence:${locationId}:polyline`;
    const objects = this._generateDrawingMarkersFromTracePoints(
      tracePoints,
      groupId,
      polygonIndex,
      onPolygonDrawEnd
    );

    // Draw a line connecting all the points in the trace
    if (tracePoints.length > 1) {
      objects.push(
        new this.api.map.Polyline(this.lineString(tracePoints), {
          style: { fillColor, strokeColor }
        })
      );

      // Draw a transparent polygon signaling the area that will be covered by the current
      // trace.
      objects.push(
        new this.api.map.Polygon(this.lineString(tracePoints), {
          style: { fillColor, strokeColor }
        })
      );
    }

    // Add the mouse marker position.
    const mouseMarker = new this.api.map.Marker({
      lat: mouseGeoCoords.lat,
      lng: mouseGeoCoords.lng
    });

    mouseMarker.setIcon(
      new this.api.map.Icon(MouseMarkerIcon, {
        size: { h: MARKER_PARAMS.h, w: MARKER_PARAMS.w },
        anchor: {
          x: MARKER_PARAMS.x,
          // Offset the mouse marker's by a bit to prevent the ontap event triggering it.
          y: MARKER_PARAMS.y + MARKER_PARAMS.mouseMarkerOffsetY
        }
      })
    );
    mouseMarker.setData({ bnewtId: `${groupId}:mouseMarker` });
    objects.push(mouseMarker);

    // Draw a line between the current mouse position and the last point in the trace.
    if (tracePoints.length > 0) {
      const lineToMouse = this.lineString([
        tracePoints[tracePoints.length - 1],
        [mouseGeoCoords.lng, mouseGeoCoords.lat]
      ]);

      objects.push(
        new this.api.map.Polyline(lineToMouse, {
          style: { fillColor, strokeColor, lineWidth: 4, lineDash: [5, 10] }
        })
      );
    }
    return this.createGroup(objects, groupId);
  }

  createPolygon(
    points,
    locationId,
    index,
    draggable,
    selected,
    isShowingLabel,
    svg = null
  ) {
    const groupId = `geofence:${locationId}:group:${index}`;
    const polygonId = `${groupId}:poly`;

    const objects = [];
    // Omit the last coord, which just duplicates the first in order to close
    // the polygon
    const coords = points.slice(0, -1);

    const style = selected ? {} : { fillColor, strokeColor };
    const geofencePoly = new this.api.map.Polygon(this.lineString(coords), {
      style: style
    });

    geofencePoly.draggable = draggable;
    objects.push(geofencePoly);

    // This might be too costly to just display a label.
    if (isShowingLabel) {
      const [labelLng, labelLat] = polylabel([coords]);
      // Add also the number beyond the svg itself
      objects.push(this.createMarker(labelLat, labelLng, groupId, index, svg));
    }

    // Polygon index will also be stored in the data instead of getting it from the
    // bnewtId for performance purposes.
    geofencePoly.setData({
      groupId: groupId,
      bnewtId: polygonId,
      polygonIndex: index
    });

    if (draggable) {
      objects.push(
        ...coords.flatMap(([longitude, latitude], i) =>
          this.createDrawingMarker(latitude, longitude, groupId, index, i)
        )
      );
    }
    return this.createGroup(objects, groupId);
  }

  createDrawingMarker(
    latitude,
    longitude,
    id,
    polygonIndex,
    index,
    draggable = true,
    onPolygonDrawEnd = null
  ) {
    let iconSize, iconAnchor, iconSrc;
    // If tracing the first point will be clicked to complete the polygon, make
    // it bigger to increase its hitbox size.
    if (id.includes("tracing") && index === 0) {
      iconSrc = FirstMarkerIcon;
      iconSize = { h: MARKER_PARAMS.h * 1.5, w: MARKER_PARAMS.w * 1.5 };
      iconAnchor = { x: MARKER_PARAMS.x * 1.5, y: MARKER_PARAMS.y * 1.5 };
    } else {
      iconSrc = MarkerIcon;
      iconSize = { h: MARKER_PARAMS.h, w: MARKER_PARAMS.w };
      iconAnchor = { x: MARKER_PARAMS.x, y: MARKER_PARAMS.y };
    }

    const icon = new this.api.map.Icon(iconSrc, {
      size: iconSize,
      anchor: iconAnchor
    });

    let marker = new this.api.map.Marker({ lat: latitude, lng: longitude });
    marker.setIcon(icon);

    marker.setData({
      groupId: id,
      // Global identifier for the control marker in the map objects dictionary.
      bnewtId: `${id}:${index}`,
      // These two represent the indeces in
      // geofence.geometry.coordinates[polygonIndex][pointIndex]
      // to quickly identify which point the user is interacting with.
      // It's duplicate information since we already store this in the bnewtId but dict
      // retrieval is faster than getting the indeces from the id.
      polygonIndex: polygonIndex,
      pointIndex: index
    });
    marker.draggable = draggable;

    return marker;
  }

  createMarker(
    latitude,
    longitude,
    id,
    index,
    svg = null,
    width = 48,
    height = 48
  ) {
    const { anchorX, anchorY } = computeCircleMetrics(width);

    // Create marker itself
    const marker = new this.api.map.Marker(
      { lat: latitude, lng: longitude },
      {
        min: 13
      }
    );

    if (svg) {
      // Create marker icon
      const icon = new this.api.map.Icon(svg, {
        size: { h: height, w: width },
        anchor: { x: anchorX, y: anchorY }
      });

      marker.setIcon(icon);
    }

    // Associate marker data and properties
    marker.setData({
      groupId: id,
      // Global identifier for the marker in the map objects dictionary.
      bnewtId: `${id}:${index}:label`,
      polygonIndex: index
    });
    marker.draggable = false;
    return marker;
  }

  // Specific to here maps
  lineString(points) {
    return new this.api.geo.LineString(pointList(points), "values lat lng alt");
  }
}

export default HereMapsShapes;
