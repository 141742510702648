/** @jsx jsx */
import { jsx } from "@emotion/core";

//eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";

import FormRow from "./FormRow";

import Colors from "../../styles/colors";
import { FaRegCheckCircle } from "react-icons/fa";

/**
 *
 * @param isValid
 * @param msg
 * @return {*}
 * @constructor
 */
const ValidationMessage = ({ isValid, msg }) => (
  <FormRow css={{ marginBottom: ".25em" }}>
    <FaRegCheckCircle
      style={{
        color: isValid ? Colors.highlight.GREEN : "#e0e0e0",
        fontSize: "large"
      }}
    />
    <span css={{ fontSize: "small", marginLeft: ".5em" }}>{msg}</span>
  </FormRow>
);

ValidationMessage.propTypes = {
  isValid: PropTypes.bool.isRequired,
  msg: PropTypes.string.isRequired
};

export default ValidationMessage;
