export function calculateRoute(
  activeOrganization,
  mapTypeOverride,
  hereMapsPlatform,
  googleMaps,
  numWaypoints,
  params,
  resultCallback,
  errorCallback
) {
  if (
    mapTypeOverride === "Here" ||
    (!mapTypeOverride && activeOrganization.map_type === "Here")
  ) {
    const router = hereMapsPlatform.getRoutingService();
    router.calculateRoute(params, resultCallback, errorCallback);
  } else {
    let origin = params["waypoint0"];
    let destination = params[`waypoint${numWaypoints - 1}`];

    let waypoints = [];
    for (let i = 0; i < numWaypoints; i++) {
      if (i === 0 || i === numWaypoints - 1) {
        continue;
      }

      let waypoint = params[`waypoint${i}`];
      let waypointLatLng = waypoint.split(",");
      waypoints.push({
        location: new googleMaps.LatLng(waypointLatLng[0], waypointLatLng[1])
      });
    }

    let directionsService = new googleMaps.DirectionsService();
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: waypoints,
        travelMode: "DRIVING"
      },
      function(response, status) {
        if (status === "OK") {
          resultCallback(response);
        } else {
          errorCallback(response);
        }
      }
    );
  }
}
