/** @jsx jsx */
import { useMemo, useState } from "react";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { get, isEmpty, sortBy, size } from "lodash";
import moment from "moment";
import { Table } from "react-bootstrap";

import { MdErrorOutline } from "react-icons/md";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";
import { getAssetID } from "./ShipmentUtils";
import {
  localizedTimeFormatter,
  localizedDateFormatter
} from "../../utils/date-time";
import DetailsTable from "../../components/DetailsTable";
import { NaPlaceholder } from "../../components/no-data-placeholders";
import {
  BoldText,
  BoldSpan,
  ItalicText,
  FlexDiv,
  FlexColDiv
} from "../../styles/container-elements";

const BEHIND_SCHEDULE_EXCEPTION = {
  shipment_exception_type_id: 27,
  status_code: "behind_schedule"
};

const PointerOnHover = styled.span({
  ":hover": {
    cursor: "pointer"
  }
});

const ErrorIcon = props => (
  <MdErrorOutline
    size={"1.5em"}
    style={{ marginRight: "0.25em", marginTop: "-2px" }}
  />
);

const _statusSeverity = {
  warning: { textColor: Colors.text.YELLOW, showIcon: true },
  critical: { textColor: Colors.highlight.RED, showIcon: true },
  none: { textColor: Colors.background.GRAY_BLUE, showIcon: false }
};

const getStatusSeverity = severity =>
  get(_statusSeverity, severity, _statusSeverity.none);

const TypeElem = ({ label, show, toggleHandler, toggleVisible = true }) => {
  return (
    <div>
      {label}
      {toggleVisible ? (
        <PointerOnHover
          style={{ marginLeft: ".35em" }}
          onClick={() => toggleHandler(!show)}
        >
          {show ? (
            <FaMinusCircle style={{ color: Colors.highlight.RED }} />
          ) : (
            <FaPlusCircle style={{ color: Colors.highlight.GREEN }} />
          )}
        </PointerOnHover>
      ) : null}
    </div>
  );
};

const HumanDetails = ({ row, t }) => {
  // the notes are in either the remarks or details
  // field.  remarks for a status, details for an exception
  const rowNotes = row.remarks ? row.remarks : row.details;

  return (
    <Table responsive>
      <tbody>
        <tr>
          <td css={{ borderTop: "0 !important", width: "5em" }}>
            {t("shipment-details:By")}
          </td>
          <td css={{ borderTop: "0 !important" }}>
            {row.actor_email ? row.actor_email : "N/A"}
          </td>
        </tr>
        <tr>
          <td css={{ width: "5em" }}>{t("shipment-details:At")}</td>
          <td>
            <BoldSpan>{localizedTimeFormatter(row.created_at)}</BoldSpan>
            <br />
            {localizedDateFormatter(row.created_at)}
          </td>
        </tr>
        <tr>
          <td css={{ width: "5em" }}>{t("shipment-details:Notes")}</td>
          <td>{rowNotes}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const AssetDetails = ({ row }) => {
  if (!row.grouped) {
    return null;
  }

  let assetRow = row.grouped.map((g, i) => {
    return (
      <tr key={i}>
        <td css={{ borderTop: i === 0 ? "0 !important" : null, width: "69%" }}>
          {row.status_name}
        </td>
        <td css={{ borderTop: i === 0 ? "0 !important" : null }}>
          <BoldSpan>{localizedTimeFormatter(g.actual_created_at)}</BoldSpan>
          <br />
          <span>{localizedDateFormatter(g.actual_created_at)}</span>
        </td>
        <td css={{ borderTop: i === 0 ? "0 !important" : null }}>
          <BoldSpan>{localizedTimeFormatter(g.created_at)}</BoldSpan>
          <br />
          <span>{localizedDateFormatter(g.created_at)}</span>
        </td>
      </tr>
    );
  });

  return (
    <Table responsive>
      <tbody>{assetRow}</tbody>
    </Table>
  );
};

const getCancelDetails = (row, t) => {
  return {
    sourceType:
      row.status_details && row.status_details.source_type_name
        ? row.status_details.source_type_name
        : "N/A",
    email: row.actor_email ? row.actor_email : "N/A",
    reportedAt:
      row.status_details && row.status_details.reported_at
        ? `${localizedTimeFormatter(
            row.status_details.reported_at
          )} ${localizedDateFormatter(row.status_details.remark)}`
        : "",
    actorType:
      row.actor_type && row.actor_type === "human"
        ? t("shipment-details:Manual")
        : t("shipment-details:System Generated"),
    remarks: row.remarks ? row.remarks : ""
  };
};

const CancelDetails = ({ row, t }) => {
  const details = getCancelDetails(row, t);

  return (
    <Table responsive>
      <tbody>
        <tr>
          <td css={{ borderTop: "0 !important", width: "5em" }}>
            {t("shipment-details:Notes")}
          </td>
          <td css={{ borderTop: "0 !important" }}>{details.sourceType}</td>
        </tr>
        <tr>
          <td css={{ width: "5em" }} />
          <td>{`${t("shipment-details:Reported at")}: ${
            details.reportedAt
          }`}</td>
        </tr>
        <tr>
          <td css={{ width: "5em" }} />
          <td>{`${t("shipment-details:Created by")}: ${details.email}`}</td>
        </tr>
        {/* H1-2055 Remove Actor Type from Cancel Details */}
        {/* <tr>
          <td css={{ width: "5em" }} />
          <td>{`${t("shipment-details:Actor type")}: ${details.actorType}`}</td>
        </tr> */}
        <tr>
          <td css={{ width: "5em" }} />
          <td>{`${details.remarks}`}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const RpDetails = ({ row }) => {
  const remarkNotes = row.remarks;
  let remarks = remarkNotes.split("\n");
  let remarkRow = [];

  for (let i = 1; i < remarks.length + 1; i++) {
    let remark = remarks[i];
    if (i === 1) {
      remarkRow.push(
        <tr key={`remarks-${i}`}>
          <td css={{ borderTop: "0 !important", width: "9em" }}>
            {remarks[0]}
          </td>
          <td css={{ borderTop: "0 !important" }}>{remark}</td>
        </tr>
      );
    } else {
      remarkRow.push(
        <tr key={`remarks-${i}`}>
          <td css={{ width: "9em" }} />
          <td>{remark}</td>
        </tr>
      );
    }
  }

  return (
    <Table responsive>
      <tbody>{remarkRow}</tbody>
    </Table>
  );
};

const StatusNoteDetails = ({ row }) => {
  const noteParas = row.status_note
    .split("\n")
    .map((line, i) => <p key={`status-note-${i}`}>{line}</p>);

  return <div css={{ marginLeft: "0.5em" }}>{noteParas}</div>;
};

const getUpdatedStops = statusDetail => {
  if (!statusDetail || !statusDetail.updated_stops) {
    return [];
  }
  return getStops(statusDetail.updated_stops);
};

const getOriginalStops = statusDetail => {
  if (!statusDetail || !statusDetail.original_stops) {
    return [];
  }
  return getStops(statusDetail.original_stops);
};

const getStops = stops => {
  const sortedStops = sortBy(stops, "stop_sequence");
  return sortedStops.map(stop => {
    return {
      locationCode: stop.creator_location_id,
      address: stop.address,
      city: stop.city,
      state: stop.state,
      country: stop.country,
      postalCode: stop.postal_code,
      earliest: stop.earliest_arrival_datetime,
      latest: stop.latest_arrival_datetime
    };
  });
};

const getUpdateDetails = row => {
  return {
    email: row.actor_email ? row.actor_email : "N/A",
    original: getOriginalStops(row.status_details),
    updated: getUpdatedStops(row.status_details)
  };
};

const StopUpdateWindow = ({ window }) => {
  return (
    <FlexDiv
      style={{
        fontSize: "smaller"
      }}
    >
      <FlexColDiv
        style={{
          justifyContent: "center",
          marginRight: ".25em",
          minWidth: "11em"
        }}
      >
        {window.locationCode && <span>{window.locationCode}</span>}
        {window.address && <span>{window.address}</span>}
        <span>
          {window.city && <span>{window.city + " "}</span>}
          {window.state && <span>{window.state + " "}</span>}
          {window.postalCode && <span>{window.postalCode + " "}</span>}
          {window.country && <span>{window.country}</span>}
        </span>
      </FlexColDiv>
      <FlexColDiv>
        <BoldText>{localizedTimeFormatter(window.earliest)}</BoldText>
        <div>{localizedDateFormatter(window.earliest)}</div>
      </FlexColDiv>
      <FlexColDiv style={{ marginLeft: ".35em", marginRight: ".35em" }}>
        <BoldText>{"to"}</BoldText>
      </FlexColDiv>
      <FlexColDiv>
        <BoldText>{localizedTimeFormatter(window.latest)}</BoldText>
        <div>{localizedDateFormatter(window.latest)}</div>
      </FlexColDiv>
    </FlexDiv>
  );
};

const ShipmentUpdatedDetails = ({ row, t }) => {
  const details = getUpdateDetails(row);

  const updateCount = Math.max(size(details.original), size(details.updated));

  let detailsRow = [];

  for (let i = 0; i < updateCount; i++) {
    let original = details.original[i];
    let updated = details.updated[i];

    detailsRow.push(
      <tr key={i}>
        <td />
        <td>{original ? <StopUpdateWindow window={original} /> : null}</td>
        <td>{updated ? <StopUpdateWindow window={updated} /> : null}</td>
      </tr>
    );
  }

  return (
    <Table responsive>
      <tbody>
        <tr>
          <td css={{ borderTop: "0 !important", width: "5em" }}>
            {t("shipment-details:Notes")}
          </td>
          <td css={{ borderTop: "0 !important" }} colSpan={2}>{`${t(
            "shipment-details:Updated by"
          )}: ${details.email}`}</td>
        </tr>
        <tr>
          <td />
          <td>{t("shipment-details:Original Stop details")}</td>
          <td>{t("shipment-details:Updated Stop details")}</td>
        </tr>
        {detailsRow}
      </tbody>
    </Table>
  );
};

// EXCEPTIONS

/*
  HT-100 Change to table to display a Reported Time & Received Time per client request
  for Exceptions: created_utc = Reported, n/a = Received
*/

const ExceptionRow = ({ exception, t }) => {
  const [showDetails, setShowDetails] = useState(false);

  return [
    <tr key={`exception`} css={{ color: Colors.background.GRAY_BLUE }}>
      <td>
        <BoldText style={{ color: Colors.highlight.RED }}>
          <ErrorIcon />
          {t(`exceptions:${exception.name}`)}
        </BoldText>
      </td>
      <td css={{ width: "11em" }}>
        {exception.actor_type === "human" ? (
          <ItalicText>
            <TypeElem
              label={t("shipment-details:Manually Entered")}
              show={showDetails}
              toggleHandler={setShowDetails}
            />
          </ItalicText>
        ) : null}
      </td>
      <td>
        <BoldSpan>{localizedTimeFormatter(exception.created_utc)}</BoldSpan>
        <br />
        <span>{localizedDateFormatter(exception.created_utc)}</span>
      </td>
      <td>
        <NaPlaceholder />
      </td>
    </tr>,
    showDetails ? (
      <tr
        key={`execption-details`}
        css={{ color: Colors.background.GRAY_BLUE }}
      >
        <td colSpan={4} css={{ backgroundColor: "white" }}>
          {exception.actor_type === "human" ? (
            <HumanDetails row={exception} t={t} />
          ) : null}
        </td>
      </tr>
    ) : null
  ];
};

const getFilteredExceptions = exceptions => {
  // H1-641:  The 'Shipment is behind schedule' exception should be displayed as
  // many times as it's triggered, so we will skip drawing it here if it's the
  // current active exception and instead display it on the getStatusRows
  // function.
  return exceptions
    ? exceptions
        .filter(
          exc =>
            exc.shipment_exception_type_id !==
            BEHIND_SCHEDULE_EXCEPTION.shipment_exception_type_id
        )
        .map(exc => {
          const exceptionTime = moment.utc(exc.created_utc).local();

          return {
            time: exceptionTime,
            exception: exc
          };
        })
    : [];
};

// STATUSES

const DetailsElem = ({ row, t }) => {
  const statusName = row.status_name;

  // case - Asset Assigned
  if (statusName.includes("Asset Assigned")) {
    return <AssetDetails row={row} />;
  }

  // case - Shipment Cancel
  if (statusName.includes("Shipment Canceled")) {
    return <CancelDetails row={row} t={t} />;
  }

  // case - Manually Entered
  if (row.actor_type === "human") {
    return <HumanDetails row={row} t={t} />;
  }

  // case - status code RP
  if (row.status_code && row.status_code === "RP") {
    return <RpDetails row={row} />;
  }

  // case - has status note
  const hasStatusNote = !!row.status_note;
  if (hasStatusNote) {
    return <StatusNoteDetails row={row} />;
  }

  // case - Shipment Updated
  if (statusName.includes("Shipment Updated")) {
    return <ShipmentUpdatedDetails row={row} t={t} />;
  }

  return null;
};

const StatusTypeElem = ({ status, showDetails, setShowDetails, t }) => {
  const statusName = status.status_name;
  // case - Asset Assigned
  if (statusName.includes("Asset Assigned")) {
    return (
      <TypeElem
        label={status.status_type_name}
        show={showDetails}
        toggleVisible={status.count && status.count > 1}
        toggleHandler={setShowDetails}
      />
    );
  }
  // case - Shipment Cancel
  if (statusName.includes("Shipment Canceled")) {
    return (
      <TypeElem
        label={t("shipment-details:Details")}
        show={showDetails}
        toggleHandler={setShowDetails}
      />
    );
  }

  // case - Manually Entered
  if (status.actor_type === "human") {
    return (
      <TypeElem
        label={t("shipment-details:Manually Entered")}
        show={showDetails}
        toggleHandler={setShowDetails}
      />
    );
  }

  // case - status code RP or has status note
  const hasStatusNote = !!status.status_note;
  const isRp = status.status_code && status.status_code === "RP";
  if (isRp || hasStatusNote) {
    return (
      <TypeElem
        label={
          status.status_type_name
            ? status.status_type_name
            : t("shipment-details:System Generated")
        }
        show={showDetails}
        toggleHandler={setShowDetails}
      />
    );
  }

  // case - Shipment Updated
  if (statusName.includes("Shipment Updated")) {
    return (
      <TypeElem
        label={t("shipment-details:Details")}
        show={showDetails}
        toggleHandler={setShowDetails}
      />
    );
  }

  return <ItalicText>{status.status_type_name}</ItalicText>;
};

const StatusNameElem = ({ status, organization }) => {
  const isBehindSchedule =
    status.status_code === BEHIND_SCHEDULE_EXCEPTION.status_code;

  let statusName = status.status_name.includes("Asset Assigned")
    ? `Asset Assigned: ${getAssetID(organization, status, null)}`
    : status.status_name;

  if (isBehindSchedule) {
    return (
      <BoldText style={{ color: Colors.highlight.RED }}>
        <ErrorIcon />
        {statusName}
      </BoldText>
    );
  }

  const { textColor, showIcon } = getStatusSeverity(status.severity);
  return (
    <BoldText style={{ color: textColor }}>
      {showIcon && <ErrorIcon />}
      {statusName}
    </BoldText>
  );
};

const getStatusEventTime = status => {
  if (status.count && status.count > 1 && status.grouped) {
    return (
      <span>
        {`${localizedTimeFormatter(status.grouped[0].actual_created_at)} -
            ${localizedTimeFormatter(
              status.grouped[status.grouped.length - 1].actual_created_at
            )}`}
      </span>
    );
  }

  return localizedTimeFormatter(status.actual_created_at);
};

/*
  HT-100 Change to table to display a Reported Time & Received Time per client request
  for Statuses: actual_created_at = Reported, created_at = Received
*/

const StatusRow = ({ status, organization, t }) => {
  const [showDetails, setShowDetails] = useState(false);

  return [
    <tr key={`status`} css={{ color: Colors.background.GRAY_BLUE }}>
      <td>
        <StatusNameElem status={status} organization={organization} />
      </td>
      <td css={{ width: "11em" }}>
        <StatusTypeElem
          status={status}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
          t={t}
        />
      </td>
      <td>
        <BoldSpan>{getStatusEventTime(status)}</BoldSpan>
        <br />
        <span>{localizedDateFormatter(status.actual_created_at)}</span>
      </td>
      <td>
        <BoldSpan>{localizedTimeFormatter(status.created_at)}</BoldSpan>
        <br />
        <span>{localizedDateFormatter(status.created_at)}</span>
      </td>
    </tr>,
    showDetails ? (
      <tr key={`status-details`} css={{ color: Colors.background.GRAY_BLUE }}>
        <td colSpan={4} css={{ backgroundColor: "white" }}>
          <DetailsElem row={status} t={t} />
        </td>
      </tr>
    ) : null
  ];
};

const groupAssets = (arr, org) => {
  let result = [];
  let prev = {};

  arr.forEach((o, i) => {
    if (
      o.status_name.includes("Asset Assigned") &&
      o.status_type_name === "Carrier Provided"
    ) {
      let assetId = getAssetID(org, o, null);
      let update;

      if (prev && prev.assetId && prev.assetId === assetId) {
        result[result.length - 1].count += 1;
        result[result.length - 1].grouped.push(o);
      } else {
        update = Object.assign({}, o, {
          assetId: assetId,
          count: 1,
          grouped: [o]
        });
        prev = update;
        result.push(update);
      }
    } else {
      result.push(o);
      prev = {};
    }
  });

  return result;
};

const getFilteredStatuses = (statuses, organization) => {
  /* HT-122 Group consecutive Asset Assigned AssetID */
  const assetGroupedStatuses = statuses
    ? groupAssets(statuses, organization)
    : [];

  // Do not process any exceptions which appear in the status list, they will
  // be handled separately (H1-641: Except for 'Shipment is behind schedule')
  // H1-561 - Filter out System Generated ETAs from table
  return !isEmpty(assetGroupedStatuses)
    ? assetGroupedStatuses
        .filter(
          stat =>
            (stat.is_exception === false ||
              // H1-641: Process the behind schedule exception here.
              stat.status_code === BEHIND_SCHEDULE_EXCEPTION.status_code) &&
            !(
              stat.status_name === "Estimated Delivery" &&
              stat.status_type_name === "System Generated"
            )
        )
        .map(stat => {
          const statusTime = moment.utc(stat.actual_created_at).local();
          return {
            time: statusTime,
            status: stat
          };
        })
    : [];
};

const UpdatesTable = ({ exceptions, organization, statuses, t }) => {
  const filteredExceptions = useMemo(() => getFilteredExceptions(exceptions), [
    exceptions
  ]);

  const filteredStatuses = useMemo(
    () => getFilteredStatuses(statuses, organization),
    [statuses, organization]
  );

  const sortedRows = sortBy(
    [...filteredStatuses, ...filteredExceptions],
    "time"
  )
    .reverse()
    .map((row, i) => {
      if (row.exception) {
        return (
          <ExceptionRow
            key={`exception-${i}`}
            exception={row.exception}
            t={t}
          />
        );
      }

      return (
        <StatusRow
          key={`status-${i}`}
          status={row.status}
          organization={organization}
          t={t}
        />
      );
    });

  return (
    <DetailsTable
      headers={[
        t("shipment-details:Status"),
        t("shipment-details:Type"),
        t("shipment-details:Event Time"),
        t("shipment-details:Received Time")
      ]}
      rows={sortedRows}
    />
  );
};

export default withTranslation("shipment-details")(UpdatesTable);
