/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";

import DetailsTable from "../../../components/DetailsTable";
import { getArrivalWindowText, getDeliveryWindowText } from "../ArrivalWindow";
import BoldCell from "../../../components/tables/Cell/BoldCell";
import EmptyCell from "../../../components/tables/Cell/EmptyCell";
import LocationCell from "../../../components/tables/Cell/LocationCell";

import {
  BoldText,
  ItalicText,
  UnderlineText
} from "../../../styles/container-elements";

import {
  localizedTimeFormatter,
  localizedDateFormatter,
  dateFormatter
} from "../../../utils/date-time";

import { NaPlaceholder } from "../../../components/no-data-placeholders";

const getStopOrder = (stops, index) => {
  if (index === 0) {
    return "O";
  } else if (index === stops.length - 1) {
    return "D";
  } else if (
    stops[index].stop_identifier &&
    stops[index].stop_identifier.includes("ETAI")
  ) {
    return "I";
  } else {
    return index.toString();
  }
};

const DateTimeCell = ({ time, isETA }) => (
  <div>
    {isETA ? (
      <ItalicText>{localizedTimeFormatter(time)}</ItalicText>
    ) : (
      <BoldText>{localizedTimeFormatter(time)}</BoldText>
    )}
    <BoldText>{localizedDateFormatter(time)}</BoldText>
  </div>
);

function getStopRows(filteredStops, mode, shipmentMissedDropOff, showETA, t) {
  return filteredStops.map((s, i) => {
    const hasArrivalTime = s.arrived_at != null;
    const hasDepartedTime = s.departed_at != null;
    const hasETA = s.eta != null;
    const isLTL = mode === "LTL";
    /* DEV-1306 - change stop cell display for CLM stops */
    const isCLM = s.stop_identifier && s.stop_identifier.includes("ETAI");
    const { windowTextLine1, windowTextLine2 } = getArrivalWindowText(
      Object.assign({}, s, {
        isDateOnly: isLTL
      })
    );

    return (
      <tr key={i} data-qa="rows-stops-table-single">
        <BoldCell>{getStopOrder(filteredStops, i)}</BoldCell>
        <LocationCell location={s.location} showAddress={!isCLM} />
        <td css={{ minWidth: "10em" }}>
          <BoldText>{windowTextLine1}</BoldText>
          <div>{windowTextLine2}</div>
        </td>
        {!shipmentMissedDropOff && showETA[i] && hasETA ? (
          <td css={{ minWidth: "10em" }}>
            {isLTL ? (
              <span>{dateFormatter(s.eta)}</span>
            ) : (
              <DateTimeCell time={s.eta} isETA={hasETA} />
            )}
          </td>
        ) : (
          <EmptyCell />
        )}
        <td css={{ minWidth: "10em" }}>
          <UnderlineText>{t("Arrival")}</UnderlineText>
          {hasArrivalTime ? (
            <DateTimeCell time={s.arrived_at} isETA={false} />
          ) : (
            <NaPlaceholder />
          )}
          <UnderlineText style={{ marginTop: 5 }}>
            {t("Departure")}
          </UnderlineText>
          {hasDepartedTime ? (
            <DateTimeCell time={s.departed_at} isETA={false} />
          ) : (
            <NaPlaceholder />
          )}
        </td>
      </tr>
    );
  });
}

// H1-1033: Fix to align delivery text to bottom of row and pickup info to top of row
const TimeCellContainer = styled.div({
  minWidth: "10em",
  display: "flex",
  flex: 1,
  flexDirection: "column"
});

const HalfCellRow = styled.div({
  alignItems: "flex-start",
  display: "flex",
  flex: 1,
  flexDirection: "column",
  height: "50%",
  minHeight: "100px"
});

function getMultiStopRows(stops, t) {
  return stops.map((s, i) => {
    const hasArrivalTime = s.arrived !== null;
    // const hasScheduledArrival = s.earliest_arrival_datetime !== null;
    const hasDepartedTime = s.departed !== null;
    const hasScheduledDeparted = s.latest_arrival_datetime !== null;
    /* DEV-1306 - change stop cell display for CLM stops */
    const isCLM = s.stop_identifier && s.stop_identifier.includes("ETAI");

    /* H1-1349 get Scheduled Pickup window */
    const { windowTextLine1, windowTextLine2 } = getArrivalWindowText(
      Object.assign({}, s, {
        isDateOnly: false
      })
    );

    /* H1-1349 get Scheduled Delivery window */
    const delieveryWindowText = getDeliveryWindowText(s);

    /* H1-1349 display Delivery first, then Pickup, updated data for actual pickup and delivery */
    return (
      <tr key={i} data-qa="rows-stops-table-multi">
        <BoldCell>{getStopOrder(stops, i)}</BoldCell>
        <LocationCell location={s.location} showAddress={!isCLM} />
        <td>
          <TimeCellContainer>
            {i > 0 ? (
              <HalfCellRow>
                <UnderlineText style={{ marginTop: 5 }}>
                  {t("Delivery")}
                </UnderlineText>
                <BoldText>{delieveryWindowText.windowTextLine1}</BoldText>
                <div>{delieveryWindowText.windowTextLine2}</div>
              </HalfCellRow>
            ) : null}
            {i < stops.length - 1 ? (
              <HalfCellRow>
                <UnderlineText>{t("Pickup")}</UnderlineText>
                <BoldText>{windowTextLine1}</BoldText>
                <div>{windowTextLine2}</div>
              </HalfCellRow>
            ) : null}
          </TimeCellContainer>
        </td>
        <td
          css={{
            minWidth: "10em"
          }}
        >
          <TimeCellContainer>
            {/* H1-1034 Don't display estimated delivery times for legs that actual delivery time */
            i > 0 && !hasDepartedTime ? (
              <HalfCellRow>
                <UnderlineText style={{ marginTop: 5 }}>
                  {t("Delivery")}
                </UnderlineText>
                {hasScheduledDeparted ? (
                  <DateTimeCell
                    time={s.latest_arrival_datetime}
                    isETA={false}
                  />
                ) : (
                  <NaPlaceholder />
                )}
              </HalfCellRow>
            ) : null}
            {i < stops.length - 1 ? <HalfCellRow /> : null}
          </TimeCellContainer>
        </td>
        <td>
          <TimeCellContainer>
            {i > 0 ? (
              <HalfCellRow>
                <UnderlineText style={{ marginTop: 5 }}>
                  {t("Delivery")}
                </UnderlineText>
                {hasDepartedTime ? (
                  <DateTimeCell time={s.delivery} isETA={false} />
                ) : (
                  <NaPlaceholder />
                )}
              </HalfCellRow>
            ) : null}
            {i < stops.length - 1 ? (
              <HalfCellRow>
                <UnderlineText>{t("Pickup")}</UnderlineText>
                {hasArrivalTime ? (
                  <DateTimeCell time={s.pickup} isETA={false} />
                ) : (
                  <NaPlaceholder />
                )}
              </HalfCellRow>
            ) : null}
          </TimeCellContainer>
        </td>
      </tr>
    );
  });
}

const StopsTable = ({ mode, stops, active_exceptions_ng, t }) => {
  if (!stops) {
    return null;
  }

  // HT-61
  // filter out termianl arrivals from LTL shipments which are now being treated as stops in the b.e
  const filteredStops = stops.filter(
    s => !(s.mode_id === 3 && s.stop_reason === "X4")
  );

  // DEV-1221:
  // If the shipment has missed the destination (related to DEV-1093),
  // then we prevent the ETA from showing on the stops tab as well
  const shipmentMissedDropOff = active_exceptions_ng === "Missed Drop-Off";

  // There are certain instances where we miss a stop
  // in the sequence, and arrive at a later stop
  // in those cases, we do not want to show ETA for any
  // stop before a stop we have arrived at
  let foundActual = false;
  let showETA = {};
  for (let i = filteredStops.length - 1; i >= 0; i--) {
    if (filteredStops[i].arrived_at != null) {
      foundActual = true;
    }
    showETA[i] = !foundActual;
  }

  /* DEV-1545 LTL shipments displays dates only for no times for Scheduled or Estimated,
  but time for Actual. */
  /* H1-841 Slightly modified display for Multimodal Stops */
  let rows =
    mode.toLowerCase() === "multimodal"
      ? getMultiStopRows(stops, t)
      : getStopRows(filteredStops, mode, shipmentMissedDropOff, showETA, t);

  let headers = ["", t("Stop"), t("Scheduled"), t("Estimated"), t("Actual")];

  return <DetailsTable headers={headers} rows={rows} />;
};

export default withTranslation("shipment-details")(StopsTable);
