import {
  getBasicQueryString,
  getApiLogsDateRangeQueryString
} from "../../../components/search-bar/search-filter-query-strings";
import {
  SelectFilterButton,
  TextFilterButton,
  DateRangeFilterButton
} from "../../../components/search-bar/FilterButtons";

const typeFilterOptions = [
  {
    label: "Completed and Failed",
    value: "event-error"
  },
  {
    label: "Failed Only",
    value: "error"
  }
];

const eventTypeFilterOptions = () => {
  const vals = [
    "Create/Replace Shipment",
    "Cancel Shipment",
    "Supplemental Data",
    "Shipment Status",
    "Shipment Patch",
    "Create/Replace/Delete Shipment (EDI)",
    "Other"
  ];
  return vals.map(val => ({ label: val, value: val }));
};

export const FILTERS = [
  {
    queryKey: "type",
    label: t => t("documentation:Status Type"),
    component: SelectFilterButton,
    optionsGetter: () => typeFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "ts",
    label: t => t("documentation:Date Range"),
    component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getApiLogsDateRangeQueryString
  },
  {
    queryKey: "requestId",
    label: t => t("documentation:Request ID"),
    component: TextFilterButton,
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "shipmentId",
    label: t => t("documentation:Shipment ID"),
    component: TextFilterButton,
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "shipperId",
    label: t => t("documentation:Shipper"),
    component: SelectFilterButton,
    optionsGetter: props => props.shipperFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "eventType",
    label: t => t("documentation:Event Type"),
    component: SelectFilterButton,
    optionsGetter: () => eventTypeFilterOptions(),
    queryBuilder: getBasicQueryString
  }
];
