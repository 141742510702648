import apiUrl from "../../api-url";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";

// URLS
const STORE_MOUNT_POINT = "fvExceptions";

const exceptionsDuck = buildFetchDuck(STORE_MOUNT_POINT);

// Action creators
function fetchExceptions(solutionId) {
  const url = apiUrl(
    `/entity/solution/${solutionId}/entity/exception/count?status=ACTIVE&lifeCycleState=Active,Completed,Delivered`
  );
  return dispatch => dispatch(exceptionsDuck.fetch(url));
}

// Selectors
const getExceptions = state => state[STORE_MOUNT_POINT].data;
const getIsLoading = state => state[STORE_MOUNT_POINT].isLoading;

// interface
const FinVehicleExceptionsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchExceptions
  },
  selectors: {
    getExceptions,
    getIsLoading
  },
  reducer: exceptionsDuck.reducer
};
export default FinVehicleExceptionsState;
