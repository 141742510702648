/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { withTranslation } from "react-i18next";
import produce from "immer";
import moment from "moment";
import momentTz from "moment-timezone";
import { Alert } from "react-bootstrap";
import _ from "lodash";

import { FlexColDiv, FlexDiv, Section } from "../../styles/container-elements";
import FormRow from "../../components/forms/FormRow";

import SelectInput from "../../components/forms/inputs/SelectInput";

import {
  ROLE_FORM_MAP,
  ROLES,
  SHIPPERS,
  SHIPPER_SOLUTION_IDS,
  isRequired
} from "./MilestoneFormFieldDefs";
import CreateMilestoneForm from "./components/CreateMilestoneForm";
import CreateMilestoneFormControls from "./components/CreateMilestoneFormControls";
import MixpanelUtils from "../../trackers/mixpanel";

const USER_TIMEZONE = momentTz.tz.guess();

class CreateMilestoneView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formFields: null,
      selectedShipper: 0,
      data: {
        customer: "",
        role: "",
        milestone: {}
      }
    };

    this.updateMilestoneField = this.updateMilestoneField.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidMount() {
    MixpanelUtils.track("Viewed Page: Partners / Create Milestone");
  }

  componentDidUpdate(prevProps) {
    const { createStatus, resetCreateForm } = this.props;

    if (createStatus && createStatus !== prevProps.createStatus) {
      if (createStatus !== "ERROR") {
        this.resetForm();
      }
      setTimeout(resetCreateForm, 5000);
    }
  }

  updateField(field, val) {
    this.setState(
      produce(draft => {
        draft.data[field] = val;
      })
    );
  }

  roleSelect(val) {
    if (!val) {
      this.resetForm();
    } else {
      this.setState(
        produce(draft => {
          let formFields = ROLE_FORM_MAP[val];
          draft.formFields = formFields;

          let milestoneData = {};

          formFields.forEach((field, i) => {
            if (field.type === "datetime") {
              milestoneData[field.key] = null;
            } else {
              milestoneData[field.key] = "";
            }
          });

          draft.data.milestone = milestoneData;
        })
      );
    }
  }

  customerSelect(val) {
    const { organizations } = this.props;
    const org = _.head(organizations.filter(o => o.fv_id === val));
    this.setState(
      produce(draft => {
        draft.selectedShipper = org;
      })
    );
  }

  updateMilestoneField(field, val) {
    this.setState(
      produce(draft => {
        draft.data.milestone[field] = val ? val : "";
      })
    );
  }

  resetForm() {
    this.setState(
      produce(draft => {
        draft.formFields = null;
        draft.selectedShipper = 0;
        draft.data = {
          customer: "",
          role: "",
          milestone: {}
        };
      })
    );
  }

  getMilestonePayload() {
    const { activeOrganization } = this.props;
    const { data, formFields, selectedShipper } = this.state;

    /* H2-1027 sourceId should from be active Organization */
    let payload = {
      statusUpdateTs: "",
      code: "",
      codeDescription: "",
      locationCode: "",
      sourceId: activeOrganization.fv_id,
      comments: "",
      locationOrganizationId: String(selectedShipper.organization_id),
      references: [{ qualifier: "User Role", value: data.role }]
    };
    let vins = [];

    if (data.milestone) {
      Object.keys(data.milestone).forEach(key => {
        let field = formFields.find(obj => obj.key === key);

        if (key === "VIN_LIST") {
          vins = data.milestone[key]
            .split(/[ ,\n\r]+/)
            .filter(el => el.trim() !== "");
        } else if (key === "STATUS_DATE_TIME") {
          payload.statusUpdateTs = moment(data.milestone[key])
            .tz(USER_TIMEZONE)
            .format();
        } else if (key === "MILESTONE_EVENT") {
          payload.code = data.milestone[key].value || "";
          payload.codeDescription = data.milestone[key].label || "";
        } else if (
          key === "REASON_CODE" &&
          data.milestone[key].value &&
          data.milestone[key].value !== ""
        ) {
          payload["subcode"] = data.milestone[key].value || "";
          payload["subcodeDescription"] = data.milestone[key].label || "";
        } else if (key === "COMMENTS") {
          payload.comments = data.milestone[key];
        } else if (key === "STATUS_LOCATION") {
          payload.locationCode = _.get(data.milestone[key], "code", "");
        } else if (
          key !== "MILESTONE_STATUS_CODE" &&
          key !== "REASON_CODE" &&
          data.milestone[key] !== ""
        ) {
          let qualifier = field.label;
          let value = data.milestone[key];

          if (key === "SHIP_TO" || key === "SHIP_FROM") {
            value = data.milestone[key].id;
          } else if (key === "LANE_TYPE") {
            value = data.milestone[key].label;
          }

          payload.references.push({ qualifier, value: String(value) });
        }
      });
    }

    return { payload, vins };
  }

  submitForm() {
    const { createMilestone } = this.props;
    const { selectedShipper } = this.state;
    const payload = this.getMilestonePayload();

    createMilestone(SHIPPER_SOLUTION_IDS[selectedShipper.fv_id], payload);
  }

  validateForm() {
    const { data, formFields, selectedShipper } = this.state;

    let isValid = true;

    if (data.customer === "" || data.role === "" || selectedShipper === 0) {
      isValid = false;
    }

    if (formFields) {
      formFields.forEach((field, i) => {
        if (
          isRequired(field, {
            role: data.role,
            milestoneEvent: data.milestone.MILESTONE_EVENT.value
          }) &&
          (data.milestone[field.key] === null ||
            data.milestone[field.key] === "")
        ) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }

    return isValid;
  }

  render() {
    const { createStatus, t } = this.props;
    const { data, formFields, selectedShipper } = this.state;

    return (
      <Section>
        <FlexColDiv
          style={{
            flex: 1,
            paddingLeft: "1em",
            paddingRight: "1em",
            paddingTop: "1em"
          }}
        >
          {createStatus && createStatus === "CREATED" ? (
            <Alert
              variant="success"
              style={{ display: "flex", paddingBottom: "2.25em" }}
            >
              {t("create-milestone:New milestone has been created.")}
            </Alert>
          ) : null}
          {createStatus && createStatus === "ERROR" ? (
            <Alert
              variant="danger"
              style={{ display: "flex", paddingBottom: "2.25em" }}
            >
              {t("create-milestone:Create milestone failed.")}
            </Alert>
          ) : null}
          <FormRow>
            <SelectInput
              label={t("create-milestone:Customer/Shipper")}
              options={SHIPPERS}
              value={data.customer}
              onChange={value => {
                this.updateField("customer", value);
                this.customerSelect(value);
              }}
            />
            <SelectInput
              label={t("create-milestone:User Role")}
              options={ROLES}
              value={data.role}
              onChange={value => {
                this.updateField("role", value);
                this.roleSelect(value);
              }}
            />
            <div />
          </FormRow>
          <FormRow style={{ marginTop: "1em" }} divider />
          {formFields ? (
            <CreateMilestoneForm
              data={data.milestone}
              role={data.role}
              formFields={formFields}
              updateField={this.updateMilestoneField}
              selectedShipper={selectedShipper}
              timezone={USER_TIMEZONE}
            />
          ) : (
            <FlexDiv style={{ padding: "1em" }}>
              {t("create-milestone:Select a User Role to populate form")}
            </FlexDiv>
          )}
        </FlexColDiv>
        <CreateMilestoneFormControls
          resetHandler={this.resetForm}
          submitHandler={this.submitForm}
          isValid={this.validateForm()}
        />
      </Section>
    );
  }
}

export default withTranslation(["create-milestone"])(CreateMilestoneView);
