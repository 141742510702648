/** @jsx jsx */
import { jsx } from "@emotion/core";

const definitionListCss = {
  display: "flex",
  listStyle: "none",
  margin: 0,
  padding: "20px 15px"
};

// Bar containing multiple DefinitionListItem columns
// Used on Shipment Details, VIN Details, ADC Details
const DefinitionList = props => {
  return (
    <ul css={{ ...definitionListCss, ...props.style }}>{props.children}</ul>
  );
};

export default DefinitionList;
