/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Alert } from "react-bootstrap";
import { useApiLogs } from "./ApiLogsHooks";

const alertCss = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  alignItems: "center"
};

// API Logs alert message - display API error response or filter validation warning
const ApiLogsAlert = props => {
  const { t, isLoading, loadingError } = useApiLogs();
  const isDetailedError =
    loadingError && loadingError.response && loadingError.response.data;
  const detailedError = isDetailedError
    ? loadingError.response.data.error
    : null;

  if (isLoading || !loadingError) {
    return null;
  }

  return isDetailedError ? (
    <Alert variant="danger" css={alertCss}>
      <h3>{t("documentation:An Error Occurred")}</h3>
      <p data-qa="text-alert-message">{detailedError.message}</p>
      <p data-qa="text-alert-response">{detailedError.response}</p>
      <p data-qa="text-alert-stack">{detailedError.stack}</p>
    </Alert>
  ) : (
    <Alert variant="warning" css={alertCss}>
      <p data-qa="text-alert-message">{t(`documentation:${loadingError}`)}</p>
    </Alert>
  );
};

export default ApiLogsAlert;
