import React from "react";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  InputGroup,
  Badge
} from "react-bootstrap";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import Colors from "../../styles/colors";
import { withTranslation } from "react-i18next";

const VersionDesc = "v2";

class MobileTrackingModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      format: true,
      phoneNumber: null,
      noteValue: ""
    };
    this.handleOnEnter = this.handleOnEnter.bind(this);
    this.handleOnChangeNote = this.handleOnChangeNote.bind(this);
    this.handleChangePhone = this.handleChangePhone.bind(this);
    this.handleChangeCountry = this.handleChangeCountry.bind(this);
  }

  handleOnChangeNote(event) {
    this.setState({ noteValue: event.target.value });
  }

  handleOnEnter() {
    this.setState({
      selectedCountryCodeIndex: 0,
      phoneNumber: null,
      noteValue: ""
    });
  }

  handleChangeCountry(_unused1, countryData, _unused2, _unused3) {
    // H1-297: Disable auto formatting for mx numbers.
    if (countryData.iso2 === "mx") {
      this.setState({ format: false });
    } else {
      this.setState({ format: true });
    }
  }

  handleChangePhone(status, value, countryData, number, id) {
    this.setState({
      phoneNumber: {
        status,
        value,
        countryData,
        number
      }
    });
  }

  render() {
    const {
      show,
      hide,
      shipment,
      startMobileTracking,
      stopMobileTracking,
      organization,
      organizations,
      toEnable,
      t
    } = this.props;
    const { phoneNumber, noteValue } = this.state;

    // Same modal used to enable/disable the feature
    let modal_title = t("Enable Mobile Tracking");
    let prompt_text = t(
      "Enter the driver's phone number to enable mobile tracking updates"
    );
    let phone_number_input = (
      <FormGroup>
        <IntlTelInput
          onPhoneNumberChange={this.handleChangePhone}
          onPhoneNumberBlur={this.handleChangePhone}
          containerClassName="intl-tel-input"
          inputClassName="form-control"
          preferredCountries={["us", "ca", "mx"]}
          separateDialCode={true}
          format={this.state.format}
          onSelectFlag={this.handleChangeCountry}
          customPlaceholder={(selCountryPlaceholder, selCountryData) => {
            // H1-297: Disable the builtin placeholder for MX that IntTelInput provides.
            return selCountryData.iso2 === "mx"
              ? "22 34 56 78 90"
              : selCountryPlaceholder;
          }}
        />
      </FormGroup>
    );
    let on_click = () => {
      let formattedNumber = phoneNumber.number.replace(/-|\s/g, "");

      startMobileTracking(
        shipment,
        organization,
        organizations,
        formattedNumber,
        noteValue
      );
      hide();
    };
    if (!toEnable) {
      modal_title = t("Disable Mobile Tracking");
      prompt_text = t("Please confirm desire to disable mobile tracking.");
      phone_number_input = <InputGroup />;
      on_click = () => {
        stopMobileTracking(shipment, organization, organizations, noteValue);
        hide();
      };
    }

    return (
      <Modal
        show={show}
        style={{ ":width": "300px" }}
        onHide={() => hide()}
        onEnter={this.handleOnEnter}
      >
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {modal_title} <Badge>{VersionDesc}</Badge>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <FormGroup>{prompt_text}</FormGroup>
          <FormGroup>{phone_number_input}</FormGroup>
          <br />
          <FormGroup>{`${t("Comments")}:`}</FormGroup>
          <InputGroup style={{ width: "100%" }}>
            <FormControl
              as="textarea"
              placeholder={t("User may record comments here")}
              value={noteValue}
              onChange={this.handleOnChangeNote}
              style={{ resize: "none" }}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em",
              border: "1px solid #ccc"
            }}
            onClick={() => hide()}
          >
            {t("Cancel")}
          </Button>
          <Button
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300",
              border: "1px solid #ccc"
            }}
            onClick={on_click}
          >
            {t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const withT = withTranslation("shipment-details")(MobileTrackingModal);
export default connect(mapStateToProps, mapDispatchToProps)(withT);
