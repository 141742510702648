/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from "react";
import { jsx } from "@emotion/core";
import { MediaQueries } from "../../../components/responsive";
import Colors from "../../../styles/colors";
import DonutChart from "../../../components/charts/DonutChart";
import { useTranslation } from "react-i18next";

import { FlexDiv, FlexColDiv } from "../../../styles/container-elements";

import { DataWidgetFrame } from "../../../components/widgets/DataWidgetFrame";
import { RackTotalWidget } from "./RackTotalWidget";
import { MdDone } from "react-icons/md";

const expiringChartData = counts => {
  if (!counts.total) {
    return [{ x: "Metal racks", y: 1, fill: Colors.background.GRAY }];
  }

  return [
    {
      x: "7-14 days",
      y: (counts.under14days / counts.total) * 100,
      fill: Colors.highlight.YELLOW
    },
    {
      x: "<7 days",
      y: (counts.under7days / counts.total) * 100,
      fill: Colors.highlight.ORANGE
    },
    {
      x: "<24 hours",
      y: (counts.under1day / counts.total) * 100,
      fill: Colors.highlight.RED
    },
    {
      x: "15+ days",
      y: (counts.morethan15days / counts.total) * 100,
      fill: Colors.highlight.GREEN
    }
  ];
};

export const expiringCounts = racks => {
  let counts = {
    under1day: 0,
    under7days: 0,
    under14days: 0,
    morethan15days: 0,
    total: 0
  };

  racks.map(rack => {
    if (rack.warning === "<24 hours") {
      counts.under1day += 1;
      counts.total += 1;
    } else if (rack.warning === "<7 days") {
      counts.under7days += 1;
      counts.total += 1;
    } else if (rack.warning === "<7-14 days") {
      counts.under14days += 1;
      counts.total += 1;
    } else {
      counts.morethan15days += 1;
      counts.total += 1;
    }
    return null;
  });
  return counts;
};

export const ExpiringRacks = ({
  solutionId,
  metalTrackingEntities,
  setSearchFilter
}) => {
  const counts = expiringCounts(metalTrackingEntities);
  const { t } = useTranslation("mt-dashboard");

  return (
    <DataWidgetFrame
      header={t("mt-dashboard:Metal Racks Expiration")}
      styles={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch"
      }}
    >
      <FlexDiv
        css={{
          height: "100%",
          flexDirection: "column",
          [MediaQueries.mediumAndUp]: {
            flexDirection: "row"
          }
        }}
      >
        <FlexDiv
          css={{
            width: "100%",
            [MediaQueries.mediumAndUp]: {
              width: "50%"
            }
          }}
        >
          <DonutChart
            data={expiringChartData(counts)}
            handler={null}
            label={t("mt-dashboard:Metal Racks")}
            total={counts.total}
            styles={{
              marginLeft: "auto",
              marginRight: "auto",
              [MediaQueries.mediumAndUp]: {
                width: "12em"
              }
            }}
          />
        </FlexDiv>
        <FlexColDiv
          css={{
            justifyContent: "space-around",
            padding: 10,
            marginLeft: "auto",
            marginRight: "auto",
            [MediaQueries.mediumAndUp]: {
              alignItems: "left",
              flexDirection: "column",
              flexGrow: 1,
              width: "50%"
            }
          }}
        >
          <RackTotalWidget
            count={counts.under1day}
            label={`< 24 ${t("mt-dashboard:hours")}`}
            color={Colors.highlight.RED}
            handler={() => {
              setSearchFilter("warning", "<24 hours");
            }}
          />
          <RackTotalWidget
            count={counts.under7days}
            label={`< 7  ${t("mt-dashboard:days")}`}
            color={Colors.highlight.ORANGE}
            handler={() => {
              setSearchFilter("warning", "<7 days");
            }}
          />
          <RackTotalWidget
            count={counts.under14days}
            label={`7-14 ${t("mt-dashboard:days")}`}
            color={Colors.highlight.YELLOW}
            handler={() => {
              setSearchFilter("warning", "<7-14 days");
            }}
          />
          <RackTotalWidget
            count={counts.morethan15days}
            label={`15+ ${t("mt-dashboard:days")}`}
            icon={MdDone}
            color={Colors.highlight.GREEN}
            handler={() => {
              setSearchFilter("warning", "15+ days");
            }}
          />
        </FlexColDiv>

        <FlexColDiv
          css={{
            justifyContent: "space-around",
            [MediaQueries.mediumAndUp]: {
              alignItems: "center",
              flexDirection: "row",
              flexGrow: 1
            }
          }}
        />
      </FlexDiv>
    </DataWidgetFrame>
  );
};
