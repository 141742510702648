/** @jsx jsx */
import { jsx } from "@emotion/core";

//eslint-disable-next-line
import React from "react";

import Colors from "../../styles/colors";
import { FormGroup, InputGroup } from "react-bootstrap";
import PropTypes from "prop-types";

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const FormCell = props => {
  const { label } = props;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        paddingRight: "0.5em",
        paddingLeft: "0.5em",
        flex: 1
      }}
    >
      <span style={{ color: Colors.background.DARK_BLUE, marginBottom: "5px" }}>
        {label}
      </span>
      <FormGroup>
        <InputGroup style={{ width: "100%" }}>{props.children}</InputGroup>
      </FormGroup>
    </div>
  );
};

FormCell.propTypes = {
  children: PropTypes.node,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default FormCell;
