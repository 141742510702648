// eslint-disable-next-line
import PropTypes from "prop-types";
import React from "react";
import { localizedTimeFormatter } from "../../../utils/date-time";
import BoldCell from "./BoldCell";

const ReportedTimeCell = ({ coord }) => {
  const startFormatted = localizedTimeFormatter(coord.start);
  const endFormatted = localizedTimeFormatter(coord.end);
  const showMulti = coord.count > 1 && startFormatted !== endFormatted;
  const timeFormatted = showMulti
    ? `${startFormatted}-${endFormatted}`
    : startFormatted;
  return <BoldCell>{timeFormatted}</BoldCell>;
};

ReportedTimeCell.propTypes = {
  coord: PropTypes.object.isRequired
};

export default ReportedTimeCell;
