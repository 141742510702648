/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector, useDispatch } from "react-redux";
import ResultsTable from "../../components/tables/ResultsTable";
import { useTranslation } from "react-i18next";
import { getSolutionId } from "../organizations/OrganizationsState";
import PartnerDashboardSearchBarState from "./PartnerDashboardSearchBarState";
import {
  milestoneEventsTableColumns,
  keyByIdOrAccessor
} from "./MilestoneEventsTableColumns";
import { RevealedDetailsTable } from "./MilestoneEventsRevealedDetails";

const {
  getMilestoneEvents,
  getIsLoading,
  getPage,
  getPageSize,
  getTotalPages
} = PartnerDashboardSearchBarState.selectors;
const { setSort } = PartnerDashboardSearchBarState.actionCreators;

const setPagination = dispatch => (solutionId, page, pageSize) => {
  return dispatch(
    PartnerDashboardSearchBarState.actionCreators.setPagination(
      solutionId,
      page,
      pageSize
    )
  );
};

const setSortLocal = (dispatch, colDefsByColId) => (
  solutionId,
  sortCol,
  isReverseSort
) => {
  const colDef = colDefsByColId[sortCol];
  if (!colDef) {
    return;
  }
  return dispatch(setSort(solutionId, colDef.api_sort_column, isReverseSort));
};

const expandedRow = row => {
  return (
    <div css={{ marginLeft: "2em", marginBottom: "1em" }}>
      <h3>Details</h3>
      <RevealedDetailsTable rowData={row.original} />
    </div>
  );
};

const MilestoneEventsTable = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const milestoneEvents = useSelector(getMilestoneEvents);
  const solutionId = useSelector(getSolutionId);
  const isLoading = useSelector(getIsLoading);
  const page = useSelector(getPage);
  const pageSize = useSelector(getPageSize);
  const totalPages = useSelector(getTotalPages);

  const columns = milestoneEventsTableColumns(t);
  const colDefsByColId = keyByIdOrAccessor(columns);

  return (
    <ResultsTable
      data={milestoneEvents}
      columns={() => columns}
      rowClickHandler={() => {}}
      rowHeight={60}
      showPagination={true}
      manual={true}
      loading={isLoading}
      page={page}
      pageSize={pageSize}
      totalPages={totalPages}
      setPagination={setPagination(dispatch)}
      fixPaginationToBottom={false}
      setSort={setSortLocal(dispatch, colDefsByColId)}
      solutionId={solutionId}
      SubComponent={expandedRow}
    />
  );
};

export default MilestoneEventsTable;
