import _ from "lodash";
import axios from "axios";
import * as math from "mathjs";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { v4 as uuidv4 } from "uuid";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import apiUrl from "../../api-url";
// import { buildSearchQueryString } from "../create-shipment/ShipmentStopsSearchBar";

import { buildLinkPayload } from "../location-resolution-edit/LocationMatchingState";

export const INITIAL_RADIUS_M = math.unit(0.25, "mile").to("m").value;

// URL parameter for adding locations
export const ADD_LOCATION_ID = "add";

// URLs
const LOCATIONS_URL = apiUrl("/location/locations");
const UNRESOLVED_LOCATIONS_URL = apiUrl("/location/unlinked");
const HEAT_MAP_URL = apiUrl("/shipping-ng/heat_map");

const heatMapDuck = buildFetchDuck("heatmap");
const locationDuck = buildFetchDuck("searchLocations");
const countriesDuck = buildFetchDuck("locationCountries");
const subdivisionsDuck = buildFetchDuck("subdivisions");

const SET_SEARCH_STR = "locations/SET_SEARCH_STR";
const SET_LOCATION_SAVED = "locations/LOCATION_SAVED";
const SET_LOCATION_LINKED = "locations/LOCATION_LINKED";

const ADD_LOCATION_FAILED = "locations/ADD_LOCATION_FAILED";
const CLEAR_ACTION_STATUS = "locations/CLEAR_ACTION_STATUS";
const FETCH_LOCATION_DETAILS = "locations/FETCH_LOCATION_DETAILS";
const RECEIVE_LOCATION_DETAILS = "locations/RECEIVE_LOCATION_DETAILS";
const RECEIVE_LOCATIONS = "locations/RECEIVE_LOCATIONS";
const RECEIVE_LOCATIONS_SUMMARY = "locations/RECEIVE_LOCATIONS_SUMMARY";
const RECEIVE_UNRESOLVED_LOCATIONS = "locations/RECEIVE_UNRESOLVED_LOCATIONS";
const RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY =
  "locations/RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY";
const SEARCH_LOCATIONS = "locations/SEARCH_LOCATIONS";
const SEARCH_LOCATIONS_SUMMARY = "locations/SEARCH_LOCATIONS_SUMMARY";
const SET_PAGINATION = "locations/SET_PAGINATION";

const CLEAR_SEARCH_RESULTS = "locations/CLEAR_SEARCH_RESULTS";
const CLEAR_SUBDIVISIONS = "locations/CLEAR_SUBDIVISIONS";
const SET_CURRENT_SUBDIVISIONS_COUNTRY_CODE =
  "locations/subdivisions/SET_CURRENT_SUBDIVISIONS_COUNTRY_CODE";

// Fields coming in the locations summary response.
const LOCATION_SUMMARY_FIELDS = [
  "address",
  "address2",
  "category",
  "city",
  "code",
  "country",
  "id",
  "lad.lad_id",
  "name",
  "postal_code",
  "state"
];

const urlBuilder = ({
  page = 0,
  pageSize = 20,
  searchStr = "",
  verbose = true,
  paginate = true
} = {}) => {
  let url = LOCATIONS_URL;
  url += verbose ? "?verbose=true" : "?verbose=false";

  if (paginate) {
    url += `&pageNumber=${page}&pageSize=${pageSize}`;
  }

  if (searchStr) {
    url += `?everything=${searchStr}`;
  }

  return url;
};

export function fetchLadLocations(code, verbose = false) {
  let qs = `organization_lad_id=${encodeURIComponent(code)}`;

  let searchLocationsUrl = apiUrl(`/location/locations`);
  searchLocationsUrl += verbose ? "?verbose=true" : "?verbose=false";
  const url = `${searchLocationsUrl}&${qs}`;

  return dispatch => {
    return Promise.all([axios.get(url)])
      .then(responses => {
        dispatch({
          type: RECEIVE_LOCATIONS,
          data: responses[0].data
        });
      })
      .catch(err => {
        //throw new Error(err);
      });
  };
}

export function searchLocations(resetPagination = true) {
  return (dispatch, getState) => {
    if (resetPagination) {
      dispatch({ type: SET_PAGINATION, page: 0, pageSize: 20 });
    }

    //TODO: clear existing search
    const state = getState();
    const url = urlBuilder({
      page: getPage(state),
      pageSize: getPageSize(state),
      verbose: false
    });

    dispatch(locationDuck.fetch(url));
    dispatch({ type: "LOCATION_SEARCH" });
  };
}

// Retrieves the details for a particular location.
export function fetchLocationDetails(locationId, dereference = false) {
  return dispatch => {
    dispatch({ type: FETCH_LOCATION_DETAILS, data: locationId });
    // Append param if fetching a dereferenced location as a Carrier
    const queryString = dereference ? "?dereference=t" : "";
    return Promise.all([
      axios.get(`${LOCATIONS_URL}/${locationId}${queryString}`)
    ])
      .then(responses => {
        const data = responses[0].data;
        // Replace ID if fetching a dereferenced location as a Carrier
        if (dereference && Array.isArray(data.child_ids)) {
          data.id = data.child_ids[0];
        }
        dispatch({ type: RECEIVE_LOCATION_DETAILS, data: data });
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

export function fetchUnresolvedLocations(summary = false, locationId = "") {
  let headers = {};
  let actionType = RECEIVE_UNRESOLVED_LOCATIONS;
  if (summary) {
    headers = { Accept: "application/json;version=summary" };
    actionType = RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY;
  }
  return dispatch => {
    const url = locationId
      ? `${LOCATIONS_URL}?near_location=${locationId}`
      : `${UNRESOLVED_LOCATIONS_URL}`;

    return Promise.all([axios.get(url, { headers: headers })])
      .then(responses => {
        dispatch({ type: actionType, data: responses[0].data });
      })
      .catch(err => {
        // Getting a 404 on the unlinked locations request for FreightVerify org.
        //throw new Error(err);
      });
  };
}

export function fetchHeatMapData(locationID) {
  return dispatch =>
    dispatch(heatMapDuck.fetch(`${HEAT_MAP_URL}/${locationID}`));
}

export function fetchCountries() {
  const url = apiUrl("/location/countries");
  return dispatch => dispatch(countriesDuck.fetch(url));
}

export function fetchSubdivisions(countryCode) {
  const url = apiUrl(`/location/subdivisions/${countryCode}`);
  return dispatch => {
    dispatch({
      type: SET_CURRENT_SUBDIVISIONS_COUNTRY_CODE,
      payload: { countryCode }
    });
    return dispatch(subdivisionsDuck.fetch(url));
  };
}

export function addLocation(data) {
  const payload = { ...data, organization_lad_id: data.lad.id };
  return dispatch => {
    return Promise.all([axios.post(`${LOCATIONS_URL}`, payload)])
      .then(responses => {
        dispatch(searchLocations(false));
        dispatch(setLocationSaved(true));
      })
      .catch(err => {
        dispatch({ type: ADD_LOCATION_FAILED, error: err });
      });
  };
}

export function addAndLinkLocation(
  newLocation,
  unresolvedLocation,
  unresolvedShipment
) {
  const payload = { ...newLocation, organization_lad_id: newLocation.lad.id };
  return dispatch => {
    return Promise.all([axios.post(`${LOCATIONS_URL}`, payload)])
      .then(responses => {
        dispatch(setLocationSaved(true));

        // Pull the location ID out of the response
        const newLocationID = responses[0].data.id;

        const linkData = buildLinkPayload(
          unresolvedLocation,
          newLocationID,
          unresolvedShipment
        );

        // Perform the match location
        dispatch(matchLocation(linkData.id, linkData.payload));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

export function editLocation(id, data) {
  const payload = { ...data, organization_lad_id: data.lad.id };
  return dispatch => {
    return Promise.all([axios.put(`${LOCATIONS_URL}/${id}`, payload)])
      .then(responses => {
        dispatch(searchLocations(false));
        dispatch(setLocationSaved(true));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

// Match does a PUT just like edit, but does not add the organization lad id
export function matchLocation(id, data) {
  return dispatch => {
    return Promise.all([axios.put(`${LOCATIONS_URL}/${id}`, data)])
      .then(responses => {
        dispatch(searchLocations(false));
        dispatch(fetchUnresolvedLocations(true));
        dispatch(setLocationLinked(true));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

export function deleteLocation(id) {
  return dispatch => {
    return Promise.all([axios.delete(`${LOCATIONS_URL}/${id}`)])
      .then(responses => {
        dispatch(searchLocations(false));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

export function setSearchStr(str) {
  return dispatch => {
    dispatch({ type: SET_SEARCH_STR, searchStr: str });
    dispatch(searchLocations(false));
  };
}

export function setLocationSaved(value) {
  return {
    type: SET_LOCATION_SAVED,
    value: value
  };
}

export function setLocationLinked(value) {
  return {
    type: SET_LOCATION_LINKED,
    value: value
  };
}

export function clearActionStatus() {
  return {
    type: CLEAR_ACTION_STATUS
  };
}

export function clearSubdivisions() {
  return {
    type: CLEAR_SUBDIVISIONS
  };
}

export const setPagination = (page, pageSize) => {
  return dispatch => {
    dispatch({ type: SET_PAGINATION, page, pageSize });
    dispatch(searchLocations(false));
  };
};

// helpers

export const buildSearchQueryString = (page = 0, pageSize = 20) => {
  let queryString = "";
  queryString += `&pageNumber=${page}&pageSize=${pageSize}`;
  return queryString;
};

// selectors
export const getLocations = state => {
  return state && state.locations && state.locations.locations
    ? state.locations.locations.data
    : [];
};

export const getUnresolvedLocations = state => {
  return state.locations.locations.unresolvedLocationsData;
};

export const isLocationSaved = state => state.locations.locations.locationSaved;
export const isLocationLinked = state =>
  state.locations.locations.locationLinked;

export const getLocationsById = createSelector([getLocations], locations =>
  _.keyBy(locations, "id")
);

export const getSelectedLocationId = state => {
  return _.get(state, "location.payload.location_id");
};

export const getSelectedLocation = createSelector(
  [getSelectedLocationId, getLocationsById],
  (locationId, locationsById) => locationsById[locationId]
);

export const getCurrentSubdivisionsCountryCode = state =>
  state.locations.subdivisions.countryCode;

export function getDefaultLocation() {
  return {
    name: "",
    country: "",
    state: "",
    city: "",
    address: "",
    postal_code: "",
    codes: [],
    geofence: {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: []
      },
      properties: {
        buffer: INITIAL_RADIUS_M,
        center: {
          latitude: null,
          longitude: null
        }
      },
      lad: {}
    }
  };
}

export const getSubdivisions = state => {
  return state && state.locations && state.locations.subdivisions
    ? state.locations.subdivisions.data
    : [];
};

// Select label/value options for <Select> dropdowns
export const getSubdivisionOptions = createSelector(
  [getSubdivisions],
  subdivisions =>
    subdivisions.map(subdivision => ({
      label: subdivision.name,
      value: subdivision.code
    }))
);

export const getSearchObj = state => state.search.searchObj;
export const getSearchCategory = state => state.search.searchCategory;
export const getSearchFilters = state => state.search.searchFilters;
export const getPage = state => state.locations.locations.page;
export const getPageSize = state => state.locations.locations.pageSize;
export const getTotalPages = state => state.locations.locations.totalPages;
export const getSearchStr = state => state.locations.locations.searchStr;
export const getTotalLocations = state =>
  state.locations.locations.totalLocations;

// initial state

const initialState = {
  data: [getDefaultLocation()],
  prevData: null,
  searchStr: "",
  locationSaved: false,
  locationLinked: false,
  actionStatus: null,
  page: 0,
  pageSize: 20,
  totalPages: 0,
  totalLocations: 0
};

// reducer
function LocationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH_STR:
      return { ...state, searchStr: action.searchStr };
    case SET_LOCATION_SAVED:
      return { ...state, locationSaved: action.value };
    case SET_LOCATION_LINKED:
      return { ...state, locationLinked: action.value };
    case ADD_LOCATION_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 409
            ? "Duplicate_Location"
            : null
      };
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null
      };
    case FETCH_LOCATION_DETAILS: {
      let locs = _.clone(state.data);
      const locationId = action.data;
      // Look for this location in the locations and mark it as loading
      let existing = locs.find(loc => loc.id === locationId);

      // If the location doesn't yet exist in the locations array (user entered a URL
      // directly and the location list  was not loaded), insert a temporary
      // placeholder until it loads, otherwise fill the details with default values.
      if (existing) {
        locs.splice(locs.indexOf(existing), 1, {
          isLoadingDetails: true,
          geofence: getDefaultLocation().geofence,
          ...existing
        });
      } else {
        locs.push({
          id: locationId,
          isLoadingDetails: true,
          ...getDefaultLocation()
        });
      }

      return {
        ...state,
        data: locs
      };
    }
    case RECEIVE_LOCATION_DETAILS: {
      let locs = _.clone(state.data);
      const newLocationData = action.data;
      // Check if there's an existing location with this id, if so, merge the incoming
      // data with it.
      let existing = locs.find(loc => loc.id === newLocationData.id);

      existing
        ? locs.splice(locs.indexOf(existing), 1, {
            ...newLocationData,
            isSummary: false,
            isLoadingDetails: false
          })
        : locs.push(newLocationData);

      return {
        ...state,
        data: locs
      };
    }
    case RECEIVE_LOCATIONS:
    case SEARCH_LOCATIONS:
      return {
        ...state,
        data: action.data
      };
    case RECEIVE_LOCATIONS_SUMMARY:
    case RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY:
    case SEARCH_LOCATIONS_SUMMARY:
      let idAttr;
      let existingData;
      if (action.type === RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY) {
        idAttr = "location_id";
        existingData = state.unresolvedLocationData;
      } else {
        idAttr = "id";
        existingData = state.data;
      }

      // Potential outdated data pitfall:
      // We're usually loading a summary of the locations when the app is loaded.
      // This poses a race condition: If the user is navigating directly to a location
      // URL, the request for loading the location list could return AFTER the
      // particular location details are already loaded, to prevent overwriting it,
      // check if a location already had its details loaded, if so, merge the incoming
      // summarized values but preserved the details.
      // If the summarized location list request takes too long to finish, we could be
      // merging outdated data!
      const summarizedData = action.data.map(summary => {
        const existingLocation =
          existingData &&
          existingData.find(
            loadedLoc => _.get(loadedLoc, idAttr) === _.get(summary, idAttr)
          );

        if (existingLocation && !existingLocation.isSummary) {
          let existingMerged = existingLocation;
          // Merge the incoming fields from the summary, leave the rest of the details
          // as we loaded it initially.
          LOCATION_SUMMARY_FIELDS.forEach(field =>
            _.set(existingMerged, field, _.get(summary, field))
          );
          return existingMerged;
        } else {
          // Complete the rest of the geofence values in the summary.
          let tmpGeofence = getDefaultLocation().geofence;
          tmpGeofence.properties.center = summary.geofence.properties.center;
          // Add an attribute to signal that each entry is a summary.
          return { ...summary, isSummary: true, geofence: tmpGeofence };
        }
      });

      if (action.type === RECEIVE_UNRESOLVED_LOCATIONS_SUMMARY) {
        return {
          ...state,
          unresolvedLocationsData: summarizedData
        };
      } else {
        return {
          ...state,
          data: summarizedData
        };
      }

    case SET_PAGINATION:
      return { ...state, page: action.page, pageSize: action.pageSize };
    case CLEAR_SEARCH_RESULTS:
      return {
        ...state
        //...duck.initialState
      };
    default:
      return state;
  }
}

function HeatMapReducer(state = heatMapDuck.initialState, action = {}) {
  switch (action.type) {
    // If we get a response or an error, set the updated UID
    case heatMapDuck.actions.RECEIVE:
    case heatMapDuck.actions.REQUEST_ERROR:
      const newState = Object.assign({}, state, {
        heatMapUid: uuidv4()
      });
      return heatMapDuck.reducer(newState, action);
    default:
      return heatMapDuck.reducer(state, action);
  }
}

function CountriesReducer(state = countriesDuck.initialState, action = {}) {
  switch (action.type) {
    case countriesDuck.actions.RECEIVE:
    case countriesDuck.actions.REQUEST_ERROR:
      return countriesDuck.reducer(state, action);
    default:
      return countriesDuck.reducer(state, action);
  }
}

function SubdivisionsReducer(
  state = {
    countryCode: null,
    ...subdivisionsDuck.initialState
  },
  action = {}
) {
  switch (action.type) {
    case subdivisionsDuck.actions.RECEIVE:
    case subdivisionsDuck.actions.REQUEST_ERROR:
      return subdivisionsDuck.reducer(state, action);
    case CLEAR_SUBDIVISIONS:
      state.data = [];
      action.payload = [];
      return subdivisionsDuck.reducer(state, action);
    case SET_CURRENT_SUBDIVISIONS_COUNTRY_CODE:
      return {
        ...state,
        countryCode: action.payload.countryCode
      };
    default:
      return subdivisionsDuck.reducer(state, action);
  }
}

export default combineReducers({
  locations: LocationsReducer,
  heatmap: HeatMapReducer,
  countries: CountriesReducer,
  subdivisions: SubdivisionsReducer
});
