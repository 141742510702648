import axios from "axios";
import apiUrl from "../../api-url";
import { createSelector } from "reselect";

const STORE_MOUNT_POINT = "connectedCarDomainData";
const CCE = actionName => `${STORE_MOUNT_POINT}/${actionName}`;
const RECEIVE_CONNECTED_CAR_DOMAIN_DATA = CCE("RECEIVE_CONNECTED_DOMAIN_DATA");

/**
 * Fetch all domain data needed for ConnectedCar
 */
export function fetchDomainData(solutionId) {
  const fieldsToGetFromEndpoint = [
    "lifecycleState",
    "makeModelYearRefs",
    "positionUpdateState",
    "positionUpdateCity"
  ];

  const queryString = fieldsToGetFromEndpoint
    .map(item => `${item}=t`)
    .join("&");

  const url = apiUrl(
    `/entity-search/solution/${solutionId}/list?${queryString}`
  );

  const config = {
    headers: {
      Accept: "application/json;version=filter"
    }
  };
  return dispatch => {
    axios
      .get(url, config)
      .then(response => {
        dispatch({
          type: RECEIVE_CONNECTED_CAR_DOMAIN_DATA,
          payload: response.data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

// Selectors

const convertToOptions = items => {
  return items.map(item => ({
    label: item,
    value: item
  }));
};

const getMakes = state => state[STORE_MOUNT_POINT].makes;
const getModels = state => state[STORE_MOUNT_POINT].models;
const getYears = state => state[STORE_MOUNT_POINT].years;
const getStates = state => state[STORE_MOUNT_POINT].states;
const getCities = state => state[STORE_MOUNT_POINT].cities;
const getLifeCycle = state => state[STORE_MOUNT_POINT].lifecycleState;
const getMakesOptions = createSelector(getMakes, convertToOptions);
const getLifeCycleOptions = createSelector(getLifeCycle, convertToOptions);
const getModelsOptions = createSelector(getModels, convertToOptions);
const getYearsOptions = createSelector(getYears, convertToOptions);
const getStatesOptions = createSelector(getStates, convertToOptions);
const getCitiesOptions = createSelector(getCities, convertToOptions);

// Reducers

const initialState = {
  makes: [],
  models: [],
  years: [],
  states: [],
  cities: [],
  lifecycleState: []
};

const ConnectedCarDomainDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_CONNECTED_CAR_DOMAIN_DATA:
      const data = action.payload || {};
      const makes = data["Decoded-Make"] || [];
      const years = data["Decoded-ModelYear"] || [];
      const models = data["Decoded-Model"] || [];
      const states = data["positionUpdateState"] || [];
      const cities = data["positionUpdateCity"] || [];
      const lifecycleState = data["lifecycleState"] || [];

      return {
        ...state,
        makes: makes.sort(),
        years: years.sort(),
        models: models.sort(),
        states: states.sort(),
        cities: cities.sort(),
        lifecycleState: lifecycleState.sort()
      };

    default:
      return state;
  }
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData
  },
  selectors: {
    getMakes,
    getModels,
    getYears,
    getStates,
    getCities,
    getLifeCycle,
    getLifeCycleOptions,
    getMakesOptions,
    getModelsOptions,
    getYearsOptions,
    getStatesOptions,
    getCitiesOptions
  },
  reducer: ConnectedCarDomainDataReducer
};
