/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  FormLabel,
  InputGroup,
  FormControl,
  FormGroup,
  Modal
} from "react-bootstrap";
import Dialog from "react-bootstrap-dialog";
import { withTranslation } from "react-i18next";

import { Privileges } from "../../auth/Authorization";
import { getAuthorization } from "../../auth/AuthorizationSelectors";
import { getActiveOrganization } from "../../organizations/OrganizationsState";
import {
  clearActionStatus,
  resetPassword,
  setUserPassword
} from "../UsersState";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput,
  FormButton
} from "../../../components/modal-elems";
import Colors from "../../../styles/colors";
import { FlexRowDiv } from "../../../styles/container-elements";
import {
  generatePassword,
  isPassLengthValid,
  isResetPassRegexValid
} from "../../../utils/password-utils";

const isPasswordValid = passwordToSet => {
  return (
    isPassLengthValid(passwordToSet) && isResetPassRegexValid(passwordToSet)
  );
};

const isFormValid = ({ passwordToSet }) => {
  return passwordToSet === "" || isPasswordValid(passwordToSet);
};

const initUser = {
  id: "",
  email: "",
  passwordToSet: ""
};

const UserPasswordModal = ({
  actionStatus,
  activeOrganization,
  clearActionStatus,
  hide,
  authorization,
  resetPassword,
  show,
  setUserPassword,
  t,
  user
}) => {
  const canChangePassword = authorization.hasPrivileges([
    Privileges.CHANGE_USER_PASSWORDS
  ]);
  let dialog = useRef();

  useEffect(() => {
    Dialog.setOptions({
      defaultOkLabel: t("users:Yes"),
      defaultCancelLabel: t("users:No"),
      primaryClassName: "btn-success"
    });
  });

  useEffect(() => {
    if (
      actionStatus &&
      ["USER_PASSWORD_SET", "USER_PASSWORD_RESET"].includes(actionStatus)
    ) {
      setTimeout(() => {
        clearActionStatus();
        clearForm();
        hide();
      }, 3000);
    }
  }, [actionStatus, hide, clearActionStatus]);

  const [editUser, setUser] = useState(initUser);

  const org = useMemo(
    () =>
      activeOrganization && activeOrganization.org_name
        ? activeOrganization.org_name
        : "",
    [activeOrganization]
  );

  useEffect(() => {
    setUser(prevState => {
      return {
        ...prevState,
        id: user ? user.user_id : "",
        email: user ? user.email : ""
      };
    });
  }, [user]);

  const inputHandler = value => {
    setUser(prevState => {
      return { ...prevState, ...value };
    });
  };

  const clearForm = () => {
    setUser(initUser);
  };

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        clearForm();
        hide();
      }}
    >
      <ModalHeader title={t("users:Reset or Set Password")} />
      <ModalBody>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={editUser.email}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold"
            }}
          />
        </FlexRowDiv>
        {!canChangePassword ? null : (
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <div css={{ width: "50%", color: "#acb5be" }}>
              <FormGroup>
                <FormLabel style={{ fontWeight: "normal" }}>
                  {t("users:Set password")}
                </FormLabel>
                <InputGroup size="sm">
                  <FormControl
                    type="text"
                    placeholder={t("users:Leave empty for password reset")}
                    value={editUser.passwordToSet}
                    onChange={e =>
                      inputHandler({ passwordToSet: e.target.value })
                    }
                  />
                  <Button
                    size="sm"
                    onClick={() =>
                      inputHandler({ passwordToSet: generatePassword() })
                    }
                  >
                    {t("users:Generate")}
                  </Button>
                </InputGroup>
              </FormGroup>
            </div>
          </FlexRowDiv>
        )}
      </ModalBody>
      <ModalFooter>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={t("users:Cancel")}
          clickHandler={() => {
            clearForm();
            hide();
          }}
        />
        <FormButton
          disabled={!isFormValid(editUser)}
          actionType="ACTION"
          label={
            editUser.passwordToSet !== ""
              ? t("users:Set Password")
              : t("users:Reset Password")
          }
          clickHandler={() => {
            dialog.show({
              body:
                editUser.passwordToSet !== ""
                  ? t(
                      "users:Are you sure you want to set this user's password?"
                    )
                  : t(
                      "users:Are you sure you want to reset this user's password?"
                    ),
              actions: [
                Dialog.CancelAction(dialog => {
                  dialog.hide();
                }),
                Dialog.OKAction(() => {
                  editUser.passwordToSet !== ""
                    ? setUserPassword(editUser.id, editUser.passwordToSet)
                    : resetPassword(user);
                })
              ],
              size: "sm",
              onHide: dialog => {
                dialog.hide();
              }
            });
          }}
        />
        <Dialog
          ref={el => {
            dialog = el;
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

UserPasswordModal.propTypes = {
  actionStatus: PropTypes.string,
  activeOrganization: PropTypes.object.isRequired,
  clearActionStatus: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  authorization: PropTypes.object.isRequired,
  resetPassword: PropTypes.func.isRequired,
  setUserPassword: PropTypes.func.isRequired,
  user: PropTypes.object,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
    actionStatus: state.users.actionStatus,
    authorization: getAuthorization(state)
  };
}

const mapDispatchToProps = {
  clearActionStatus,
  resetPassword,
  setUserPassword
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["users"])(UserPasswordModal));
