/**
 * Defines the interface of methods that should be implemented by a platform
 * to be used by BaseMap.
 */
class BaseMapPlatformInterface {
  constructor(baseMap) {
    this.baseMap = baseMap;
    this.shapes = null;

    this.dragStartLat = 0;
    this.dragLat = 0;
    this.dragStartLng = 0;
    this.dragLng = 0;

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onTap = this.onTap.bind(this);
  }

  static get name() {
    throw Error("name static method is not defined");
  }

  // Just to make it possible accessing static property to an object derived
  // from this interface
  get name() {
    return this.constructor.name;
  }

  // Methods to help keeping signatures simpler inside platform implementations
  get props() {
    return this.baseMap.props;
  }

  get map() {
    return this.baseMap.map;
  }

  set map(data) {
    this.baseMap.map = data;
  }

  get mapObjects() {
    return this.baseMap.mapObjects;
  }

  set mapObjects(data) {
    this.baseMap.mapObjects = data;
  }

  get mapDiv() {
    return this.baseMap.mapDiv;
  }

  get mapLayers() {
    return this.baseMap.mapLayers;
  }

  set mapLayers(data) {
    this.baseMap.mapLayers = data;
  }

  get state() {
    return this.baseMap.state;
  }

  setState(data) {
    this.baseMap.setState(data);
  }

  setMapCenter(centerPos) {
    throw Error("setMapCenter not implemented");
  }

  // Initialization
  initMap(activeOrganization, mapTypeOverride) {
    throw Error("initMap not implemented");
  }

  initHeatMap(coords) {
    throw Error("initHeatMap not implemented");
  }

  deinitHeatMap() {
    throw Error("deinitHeatMap not implemented");
  }

  // Events
  onTap(e) {
    throw Error("onTap not implemented");
  }

  onMouseMove(e) {
    throw Error("onMouseMove not implemented");
  }

  // Events Related
  addMapEventListener(eventName, callback) {
    throw Error("addMapEventListener not implemented");
  }

  addMapMarkerEventListener(marker, eventName, callback) {
    throw Error("addMapMarkerEventListener not implemented");
  }

  // Drawing
  createAndAddMapMarker(
    name,
    pos,
    zIndex,
    data,
    isClickable,
    iconSvg,
    iconHeight,
    iconWidth,
    iconDefaultHeight,
    iconDefaultWidth,
    iconXOffsetForGoogle,
    iconYOffsetForGoogle,
    iconXAnchorForHERE,
    iconYAnchorForHERE
  ) {
    throw Error("createAndAddMapMarker not implemented");
  }
  createAndAddMapPolyline(
    name,
    linestring,
    zIndex,
    lineWidth,
    lineJoin,
    hasArrows
  ) {
    throw Error("createAndAddMapPolyline not implemented");
  }

  createMapLineString() {
    throw Error("createMapLineString not implemented");
  }

  createAndAddMapInfoBubble(pos, content) {
    throw Error("createAndAddMapInfoBubble not implemented");
  }

  addMapLineStringLatLong(linestring, lat, lng) {
    throw Error("addMapLineStringLatLong not implemented");
  }

  drawGeofence(location, geofenceGroup, geofenceMapId) {
    throw Error("drawGeofence not implemented");
  }

  addTraceGroup(traceGroup) {
    throw Error("addTraceGroup not implemented");
  }

  // Clearing
  clearInfoBubbles() {
    throw Error("clearInfoBubbles not implemented");
  }

  clearMap(excludeKeyPrefix = null) {
    throw Error("clearMap not implemented");
  }

  clearMapMarkers(prefix) {
    throw Error("clearMapMarkers not implemented");
  }

  // Actions
  setMapViewBounds(boundsRect) {
    throw Error("setMapViewBounds not implemented");
  }

  resizeMap() {
    throw Error("resizeMap not implemented");
  }

  zoomSingleLocation(location) {
    throw Error("zoomSingleLocation not implemented");
  }

  zoomLocations(locations) {
    throw Error("zoomLocations not implemented");
  }

  mergeBounds(bounds1, bounds2) {
    throw Error("mergeBounds not implemented");
  }

  removeMapObject(name) {
    throw Error("removeMapObject not implemented");
  }

  // Helpers
  isMapViewBoundsContainPoint(pos) {
    throw Error("isMapViewBoundsContainPoint not implemented");
  }

  isMapViewBoundsContainLatLng(lat, lng) {
    throw Error("isMapViewBoundsContainLatLng not implemented");
  }

  getBoundsRect(pos, boundsBuffer = 0.01) {
    throw Error("getBoundsRect not implemented");
  }
}

export default BaseMapPlatformInterface;
