import Colors from "./colors";

export const minimalistTableStyles = {
  table: {
    margin: 0,
    border: 0
  },
  thead: {
    height: 45,
    display: "flex",
    textAlign: "left",
    alignItems: "center",
    fontWeight: "bold",
    borderBottom: "1px solid rgba(0,0,0,0.05)",
    backgroundColor: "rgba(0,0,0,0.05)"
  }
};

export const datatableThStyles = {
  backgroundColor: Colors.background.DARK_GRAY,
  color: Colors.tableHeadingText,
  paddingTop: "1em",
  paddingBottom: "1em",
  fontWeight: "normal",
  textAlign: "left",
  paddingLeft: "0.5em"
};

// Styles for Results Tables (typically used to display search results)
export const resultsTableStyles = {
  table: {
    borderRadius: 3,
    boxShadow: "0px 3px 14px 0.5px rgba(0,0,0,0.05)"
  },
  thead: {
    height: 54,
    boxShadow: "none",
    zIndex: 0
  },
  theadTh: {
    fontSize: 14,
    color: "white",
    backgroundColor: Colors.background.DARK_GRAY,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "initial",
    boxSizing: "border-box",
    textAlign: "left",
    paddingLeft: 20
  },
  theadFilterTh: {
    overflow: "visible",
    textAlign: "left"
  },
  theadFilterThSelect: {
    width: "100%",
    border: "none",
    padding: 0,
    height: 30,
    ".Select-control": {
      border: "1px solid rgba(0,0,0,0.1)",
      borderRadius: 3,
      padding: "0 7px",
      height: 28
    },
    ".Select-placeholder": {
      lineHeight: "28px",
      paddingLeft: 7,
      paddingRight: 7
    },
    ".Select-value": {
      lineHeight: "28px !important",
      padding: "0 7px !important"
    },
    ".Select-input": {
      height: 28,
      lineHeight: "28px",
      padding: 0
    },
    ".Select-input input": {
      border: "none !important",
      padding: "0 !important"
    }
  },
  td: {
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    paddingLeft: 20,
    color: Colors.holds.DARK_BLUE
  }
};

const stickyHeaderStyles = headerBackgroundColor => ({
  // Remove overflow from the table
  // and let .ReactTable can handle overflow
  // - This is done so the vertical and horizontal overflow
  //   is handled in the same container
  overflow: "auto",
  ".rt-table": {
    overflow: "unset"
  },
  // Remove the top border from .ReactTable
  // and let .rt-thead handle displaying the header
  // - The border on .ReactTable was part of the scroll area
  //   so it would scroll away with the rest of the results
  borderTop: "none",
  ".rt-thead.-header": {
    borderTop: `1px solid ${Colors.reactTableBorder}`,
    // Makes the header stick to the top of .ReactTable
    position: "sticky",
    // Need to explicitly define the initial offset from the top of the container
    top: 0,
    // Set the background so we don't see results appear through transparency
    background: headerBackgroundColor
  }
});

export const darkResultsTableStyles = {
  ...resultsTableStyles,
  ...stickyHeaderStyles(Colors.background.DARK_GRAY),
  width: "100%",
  color: "white",
  ".rt-tr": {
    padding: "0 !important"
  },
  ".rt-th": {
    padding: "1em 1em 1em 1.3em !important",
    fontWeight: "bold",
    textAlign: "left"
  },
  ".rt-td": {
    padding: "1em 1em 1em 1.3em !important"
  },
  ".-even div, div.rt-tr.-even:hover .rt-td:not(.-selected)": {
    backgroundColor: Colors.background.DARK_GRAY
  },
  ".-odd div, div.rt-tr.-odd:hover .rt-td:not(.-selected)": {
    backgroundColor: Colors.background.DARK_HIGHLIGHT_GRAY
  },
  "div.rt-tr:hover .rt-td:not(.-selected):not(.-no-highlight) div": {
    backgroundColor: "transparent !important"
  },
  ".rt-noData": {
    visibility: "hidden"
  },
  ".-loading": {
    backgroundColor: `rgba(37, 37, 37, 0.7)`,
    color: "white"
  },
  ".-loading > div": {
    top: "10% !important",
    color: "white"
  },
  ".-pagination input, .-pagination select, .-pagination .-btn": {
    backgroundColor: Colors.highlight.DARK_GRAY,
    color: "white"
  }
};

// convenience for using one of our style objects in a react-table
// callback prop
export const styleify = obj => {
  return () => {
    return { style: obj };
  };
};
