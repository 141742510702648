import styled from "@emotion/styled";

/**
 *
 * @type {*}
 */
const FormRow = styled.div(
  {
    display: "flex",
    flexFlow: "row wrap",
    marginLeft: "0.5em",
    marginRight: "0.5em",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  ({ divider }) => ({
    borderBottom: divider ? `1px solid ${"#ddd"}` : "none",
    marginBottom: divider ? `1em` : "none"
  })
);

export default FormRow;
