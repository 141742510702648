import React, { Component } from "react";
import AuthenticationUtils from "../auth/authentication";

class CallbackView extends Component {
  componentDidMount() {
    const { authCode, authState, error, authType } = this.props;

    if (error) {
      window.location.href = "/accessForbiddenError";
      return;
    }

    if (authType === "CALLBACK" && authCode) {
      AuthenticationUtils.handleAuthentication(authCode);
      return true;
    } else if (authType === "SAML_CALLBACK" && authState) {
      AuthenticationUtils.handleSamlAuthentication(authState);
      return true;
    }
  }

  render() {
    const style = {
      position: "absolute",
      display: "flex",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: "white"
    };

    return <div style={style} />;
  }
}

export default CallbackView;
