import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import * as math from "mathjs";
import StandardInput from "../../components/forms/inputs/StandardInput";
import NumberInput from "../../components/forms/inputs/NumberInput";

import { FlexRow } from "./GeofenceEditForm";
import { geofenceWithUpdatedCoordinates } from "./geofence-coordinates";
import { withTranslation } from "react-i18next";

class RadialForm extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    isReadOnly: PropTypes.bool,
    t: PropTypes.func.isRequired,
    updateGeofence: PropTypes.func.isRequired
  };

  state = {
    radiusMiles: 0.25 /* DEV-1158 Default radius updated to 1/4 mile */
  };

  componentDidUpdate(prevProps) {
    const { buffer } = this.props.data.geofence.properties;
    const prevBuffer = prevProps.data.geofence.properties.buffer;
    if (buffer === prevBuffer) {
      return;
    }
    const radiusMeters = math.unit(buffer, "m");
    const radiusMilesRaw = radiusMeters.to("mile");
    const radiusMiles = _.round(radiusMilesRaw.toNumber(), 5);
    this.setState({ radiusMiles });
  }

  render() {
    const { t, data, updateGeofence, isReadOnly } = this.props;
    const [lng, lat] = data.geofence.geometry.coordinates;

    return (
      <FlexRow>
        <NumberInput
          label={t("geofence-edit:Latitude")}
          value={lat}
          onChange={val => {
            const newGeofence = geofenceWithUpdatedCoordinates(data.geofence, [
              lng,
              Number.parseFloat(val)
            ]);
            updateGeofence(newGeofence);
          }}
          isReadOnly={isReadOnly}
        />
        <NumberInput
          label={t("geofence-edit:Longitude")}
          value={lng}
          onChange={val => {
            const newGeofence = geofenceWithUpdatedCoordinates(data.geofence, [
              Number.parseFloat(val),
              lat
            ]);
            updateGeofence(newGeofence);
          }}
          isReadOnly={isReadOnly}
        />
        <StandardInput
          label={t("geofence-edit:Radius (mi)")}
          value={this.state.radiusMiles}
          onChange={valMiles => {
            this.setState({ radiusMiles: valMiles });
            try {
              const radiusMeters = math.unit(valMiles, "mile").to("m");
              const newGeofence = {
                ...data.geofence,
                properties: {
                  ...data.geofence.properties,
                  buffer: radiusMeters.toNumber()
                }
              };
              updateGeofence(newGeofence);
            } catch (err) {
              // ignore invalid number - probably temporary as user continues to edit
            }
          }}
          isReadOnly={isReadOnly}
        />
      </FlexRow>
    );
  }
}

export default withTranslation(["geofence-edit"])(RadialForm);
