import { connect } from "react-redux";
import debouncePromise from "debounce-promise";

import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "./ShipmentSearchBarState";
import DomainDataState from "../domain-data/DomainDataState";

import {
  selectTrailerNumberCategoryOptions,
  selectAssetIdOptions,
  selectTrainIdOptions
} from "./ShipmentSearchCategorySelectors";

const mapStateToProps = state => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata,
    getLocations,
    getLocationsLoading,
    getVins,
    getVinsLoading
  } = SearchBarState.selectors;

  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    locations: getLocations(state),
    locationsLoading: getLocationsLoading(state),
    shipmentIDs: DomainDataState.selectors.getShipmentIDs(state),
    parts: DomainDataState.selectors.getParts(state),
    trailerNumbers: selectTrailerNumberCategoryOptions(state),
    routeIDs: DomainDataState.selectors.getRouteIDs(state),
    vins: getVins(state),
    vinsLoading: getVinsLoading(state),
    orderNumbers: DomainDataState.selectors.getOrderNumbers(state),
    tripPlanNumbers: DomainDataState.selectors.getTripPlanNumbers(state),
    assetIDs: selectAssetIdOptions(state),
    carriers: DomainDataState.selectors.selectCarriersAlphabetically(state),
    trainIDs: selectTrainIdOptions(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    setSearchValue,
    clearSearchText,
    resetSearchBar,
    searchEntities,
    searchLocations,
    searchVins
  } = SearchBarState.actionCreators;

  return {
    setSearchCategory: key => dispatch(setSearchCategoryForKey(key)),
    setSearchText: text => dispatch(setSearchText(text)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    searchEntities: () => dispatch(searchEntities()),
    searchLocations: debouncePromise(
      query => dispatch(searchLocations(query)),
      500
    ),
    searchVins: query => dispatch(searchVins(query)),
    setSearchValue: value => dispatch(setSearchValue(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
