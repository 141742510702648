import ReactGA from "react-ga";
import _ from "lodash";
import ApplicationConfig from "../config";

/**
 * Helper function for GA tracking
 * @param query
 * @return {*}
 */
const stringify = query => {
  return _.snakeCase(query);
};

/**
 * Helper function for GA tracking
 * @param obj
 * @return {boolean}
 */
const isEmptyObj = obj => {
  return _.isEmpty(obj);
};

export function isGoogleAnalyticsEnabled() {
  return !!ApplicationConfig.GOOGLE_ANALYTICS_UA;
}

/**
 * We need to init google analytics before any calls are made.
 */
export function initGoogleAnalytics() {
  if (!isGoogleAnalyticsEnabled()) {
    return false;
  }

  ReactGA.initialize(ApplicationConfig.GOOGLE_ANALYTICS_UA, {
    debug: false,
    gaOptions: {
      cookieDomain: "auto"
    }
  });
}

/**
 * A redux middleware used to automatically track pageviews based on route changes.
 * @param getState
 * @return {function(*): function(*=): *}
 */
export function trackWithGoogleAnalytics({ getState }) {
  return next => action => {
    //
    // Skip if it isn't a react-router action
    if (!action.hasOwnProperty("meta")) {
      return next(action);
    }

    //
    // Skip if GA isn't enabled.
    if (!isGoogleAnalyticsEnabled()) {
      return next(action);
    }

    //
    // TODO: Document reasoning.
    if (action.type === "SHIPMENT_SEARCH" && !isEmptyObj(action.payload)) {
      const query = stringify(action.payload);
      const url = `/search?q=${query}`;
      ReactGA.ga("send", "pageview", url);
    } else if (action.type !== "SHIPMENT_SEARCH") {
      ReactGA.pageview(action.meta.location.current.pathname);
    }

    return next(action);
  };
}
