import PropTypes from "prop-types";
import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaCog } from "react-icons/fa";
import { useDispatch } from "react-redux";

/**
 * Location Page Navigation
 */
const SectionNavigation = ({ activeTab, className = "" }) => {
  const { t } = useTranslation("locations");
  const dispatch = useDispatch();

  return (
    <Nav justify variant="tabs" activeKey={activeTab} className={className}>
      <Nav.Item>
        <Nav.Link
          eventKey="locations"
          onClick={() => dispatch({ type: "LOCATION_MANAGEMENT" })}
        >
          {t("locations:Locations")}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="lads"
          onClick={() => dispatch({ type: "LOCATION_LADS" })}
        >
          <span className="font-size-1 mr-1 mr-lg-1 d-block d-lg-inline">
            <FaCog />
          </span>
          {t("locations:Edit LAD Labels")}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="unresolved"
          onClick={() => dispatch({ type: "LOCATION_UNRESOLVED" })}
        >
          {t("locations:Unresolved Locations")}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

SectionNavigation.propTypes = {
  activeTab: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default SectionNavigation;
