/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import debouncePromise from "debounce-promise";

import { Translation } from "react-i18next";
import AsyncPaginate from "react-select-async-paginate";
import FormCell from "../../components/forms/FormCell";
import Colors from "../../styles/colors";

export class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.performSearch = this.performSearch.bind(this);
    this.getLocations = this.getLocations.bind(this);
    this.getLocationTimeZone = this.getLocationTimeZone.bind(this);
  }

  componentDidMount() {
    this.setState({ locations: [] });
  }

  componentDidUpdate(prevProps) {
    if (this.props.shipperLocations !== prevProps.shipperLocations) {
      this.setState({ locations: this.props.shipperLocations });
    }
  }

  performSearch() {
    const { selectedShipper, ind } = this.props;
    return this.props.searchLocations(selectedShipper.organization_id, ind);
  }

  getLocations(ind) {
    const { locations } = this.state;
    return locations && locations[ind] ? locations[ind] : [];
  }

  getLocationTimeZone(ind, locationId) {
    const locations = this.getLocations(ind);
    const location = locations.find(l => l.id === locationId);
    return location ? location.time_zone : null;
  }

  render() {
    const {
      label,
      ind,
      shipperLocationsLoading,
      eventHandler,
      stop,
      setSearchText,
      searchLocations,
      selectedShipper
    } = this.props;

    // todo incorporate this into SelectField to remove duplicate code
    const customStyles = {
      control: (provided, { isDisabled }) => ({
        ...provided,
        borderRadius: "5px",
        borderWidth: "2px",
        borderColor: "#ced4da",
        height: "3em",
        backgroundColor: isDisabled ? Colors.background.LIGHT_GRAY : "#fff"
      }),
      container: provided => ({
        ...provided,
        width: "100%"
      }),
      menuList: provided => ({
        ...provided,
        paddingTop: 0,
        paddingBottom: 0
      })
    };

    // H2-453: Use react-select to search locations asynchronously
    return (
      <FormCell label={label}>
        <Translation ns="create-shipment">
          {t => (
            <AsyncPaginate
              styles={customStyles}
              isMulti={false}
              cacheOptions={false}
              key={selectedShipper ? selectedShipper.organization_id : 0}
              label={label}
              value={stop.location}
              placeholder={
                stop.location && stop.location_name
                  ? stop.location_name
                  : `${t("create-shipment:Select")}...`
              }
              isDisabled={!selectedShipper}
              isLoading={shipperLocationsLoading}
              onChange={newOption => {
                eventHandler(ind, "location", newOption.value);
                eventHandler(ind, "location_name", newOption.label);
                if (newOption && newOption.value) {
                  eventHandler(
                    ind,
                    "timezone",
                    this.getLocationTimeZone(ind, newOption.value)
                  );
                }
              }}
              loadOptions={debouncePromise(input => {
                if (input.length < 2) {
                  return {
                    options: [],
                    hasMore: false
                  };
                }

                setSearchText(input, ind);
                return searchLocations(
                  selectedShipper.organization_id,
                  ind
                ).then(() => {
                  const locations = this.getLocations(ind);
                  const locationOptions = locations.map(l => {
                    return { value: l.id, label: `${l.name} (${l.code})` };
                  });
                  return { options: locationOptions };
                });
              }, 500)}
            />
          )}
        </Translation>
      </FormCell>
    );
  }
}
