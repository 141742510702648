/** @jsx jsx */ // eslint-disable-next-line
/**
 * A Donut chart with a number (normally the total) and a text inside it.
 * Something similar to this one:
 *
 *
 *                         ;;;;;;;;;;;;;;;;;;;;;
 *                  oooooooo;;;;;;;;;;;;;;;;;;;;;;;;;;;
 *             oooooooooooooo;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
 *         ooooooooooooooooo                 ;;;;;;;;;;;;#####
 *       oooooooooooooo                           ;#############
 *      oooooooooooo                                 ############
 *     oooooooooooo               55                  ############
 *     ooooooooooo                                     ###########
 *     oooooooooooo                                   ::::::::::::
 *      oooooooooooo             Total               ::::::::::::
 *       ooooooooo:::::                           ::::::::::::::
 *         o::::::::::::::::                 :::::::::::::::::
 *            :::::::::::::::::::::::::::::::::::::::::::::
 *                 :::::::::::::::::::::::::::::::::::
 *                        :::::::::::::::::::::
 *
 */
// eslint-disable-next-line
import React, { Component } from "react";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { VictoryPie } from "victory";

import Colors from "../../styles/colors";
import { MediaQueries } from "../responsive";
import { FlexDiv, FlexColDiv } from "../../styles/container-elements";

function calculateTotalFontSize(total) {
  let totalsFontSize;
  if (total > 999999) {
    totalsFontSize = "large";
  } else if (total > 9999) {
    totalsFontSize = "x-large";
  } else {
    totalsFontSize = "xx-large";
  }
  return totalsFontSize;
}

const DonutChart = ({ data, total, label, handler, styles }) => {
  const totalsFontSize = calculateTotalFontSize(total);

  return (
    <FlexDiv
      css={{
        minHeight: "10em",
        minWidth: "10em",
        [MediaQueries.smallAndDown]: {
          width: "20em"
        },
        [MediaQueries.mediumAndUp]: {
          width: "10em"
        },
        alignItems: "center",
        position: "relative",
        justifyContent: "center",
        ":hover": {
          cursor: handler ? "pointer" : "auto"
        },
        ...styles
      }}
      onClick={handler}
    >
      <FlexColDiv
        css={{
          position: "absolute",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div
          css={{
            color: Colors.background.DARK_BLUE,
            fontSize: totalsFontSize
          }}
        >
          {total}
        </div>
        <div
          css={{
            fontSize: "small",
            fontStyle: "italic",
            color: Colors.background.DARK_BLUE
          }}
        >
          {label}
        </div>
      </FlexColDiv>
      <FlexColDiv>
        <VictoryPie
          data={data}
          innerRadius={130}
          padAngle={0} /* H1-1720 Remove space between pie pieces */
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => datum.fill
            }
          }}
        />
      </FlexColDiv>
    </FlexDiv>
  );
};

DonutChart.propTypes = {
  data: PropTypes.array,
  label: PropTypes.string,
  total: PropTypes.number,
  handler: PropTypes.func,
  styles: PropTypes.object
};

export default DonutChart;
