/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import CountryName from "./CountryName";

const propTypes = {
  location: PropTypes.object
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const LocationAddress = ({ location }) => {
  return (
    <div>
      <div>
        {location.address}
        {location.address && (location.city || location.state) ? ", " : ""}
        {location.city}
        {location.city && location.state ? ", " : ""}
        {location.state}
      </div>
      {location.country ? <CountryName countryCode={location.country} /> : null}
    </div>
  );
};

LocationAddress.propTypes = propTypes;

export default LocationAddress;
