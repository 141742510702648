import Axios from "axios";
import _ from "lodash";
import jwtDecode from "jwt-decode";
import apiUrl from "../../api-url";
import { UserAuthorizationNamespace } from "./Authorization";
import MixpanelUtils from "../../trackers/mixpanel";
import ApplicationConfig, { getCurrentEnvironment } from "../../config";

const AUDIENCE = "urn:fv-api";
const REDIRECT_URI = "https://" + window.location.hostname + "/callback";

class Authentication {
  constructor() {
    this.setIsLoggingIn(false);
  }

  /**
   *
   * @param code
   */
  handleAuthentication(code) {
    if (!code) {
      return false;
    }

    this.setIsLoggingIn(true);

    const url = this.getEnvAuth0BaseUri() + `/oauth/token`;

    const params = {
      grant_type: "authorization_code",
      scope: "openid profile",
      client_id: this.getEnvAuth0ClientId(),
      code: code,
      redirect_uri: this.getEnvRedirectUri()
    };

    Axios.post(url, params)
      .then(resp => {
        this.setSession(resp.data);
        MixpanelUtils.identify(this.userId, this.userMixpanelPeopleProperties);
        // Send the access_token to new login audit endpoint
        const loginAuditUrl = apiUrl("/iam/login-audit");
        Axios.post(loginAuditUrl)
          .then(loginAuditResp => {
            //if not silent authentication
            if (!this.getIsSilentAuthentication()) {
              const url = this.getLastRequestedUrl();
              this.setLastRequestedUrl("");

              if (url) {
                window.location.assign(url);
              } else {
                window.location.assign("/");
              }
            } else {
              // Redirect to home
              this.setLastRequestedUrl("");
              window.location.assign("/");
            }
          })
          .catch(error => {
            // Something went wrong, but continue anyway
            // Redirect to home
            this.setLastRequestedUrl("");
            window.location.assign("/");
          });
      })
      .catch(error => {
        window.location.assign("/verifyUserError");
      });
  }

  handleSamlAuthentication(state) {
    if (!state) {
      return false;
    }

    this.logout();
    this.setIsSilentAuthentication(true);
    window.location.assign(
      `${this.getEnvAuth0BaseUri()}/authorize?response_type=code&client_id=${this.getEnvAuth0ClientId()}&redirect_uri=${this.getEnvRedirectUri()}&state=${state}&scope=openid%20profile&prompt=none`
    );
  }

  isAuthenticated() {
    let expiresAt = JSON.parse(sessionStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
    //return expiresAt > 0;
  }

  isLoggingIn() {
    return sessionStorage.getItem("isLoggingIn");
  }

  login() {
    this.setIsSilentAuthentication(false);
    window.location.assign(
      `${this.getEnvAuth0BaseUri()}/authorize?scope=openid%20profile&response_type=code&client_id=${this.getEnvAuth0ClientId()}&audience=${AUDIENCE}&redirect_uri=${this.getEnvRedirectUri()}`
    );
  }

  logout() {
    // Clear access token and ID token from local storage
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("id_token");
    sessionStorage.removeItem("expires_at");
    sessionStorage.removeItem("isLoggingIn");
    sessionStorage.removeItem("currentOrganization");
    sessionStorage.removeItem("isSilentAuthentication");
    sessionStorage.removeItem("solutionId");

    window.location.assign("/");
  }

  setSession(authResult) {
    let expiresAt = JSON.stringify(
      authResult.expires_in * 1000 + new Date().getTime()
    );
    sessionStorage.setItem("access_token", authResult.access_token);
    sessionStorage.setItem("id_token", authResult.id_token);
    sessionStorage.setItem("expires_at", expiresAt);
    //sessionStorage.setItem('refresh_token', authResult.refresh_token);
    sessionStorage.setItem("isLoggingIn", false);
  }

  setLastRequestedUrl(url) {
    sessionStorage.setItem("last_requested_url", url);
  }

  setIsLoggingIn(flag) {
    sessionStorage.setItem("isLoggingIn", flag);
  }

  setIsSilentAuthentication(flag) {
    sessionStorage.setItem("isSilentAuthentication", flag);
  }

  getIsSilentAuthentication() {
    return JSON.parse(sessionStorage.getItem("isSilentAuthentication"));
  }

  getLastRequestedUrl() {
    return sessionStorage.getItem("last_requested_url");
  }

  getIdToken() {
    return sessionStorage.getItem("id_token");
  }

  getAccessToken() {
    return sessionStorage.getItem("access_token");
  }

  getExpiresAt() {
    return sessionStorage.getItem("expires_at");
  }

  getDecodedToken() {
    return jwtDecode(sessionStorage.getItem("access_token"));
  }

  getDecodedIdToken() {
    return jwtDecode(sessionStorage.getItem("id_token"));
  }

  get authConfiguration() {
    const environment = getCurrentEnvironment(true);

    if (!environment) {
      return null;
    }

    return ApplicationConfig.AUTH[environment];
  }

  getEnvAuth0BaseUri() {
    const config = this.authConfiguration;
    return config["loginUrl"];
  }

  getEnvAuth0ClientId() {
    const config = this.authConfiguration;
    return config["clientId"];
  }

  getEnvRedirectUri() {
    if (window.location.hostname.indexOf("localhost") >= 0) {
      return `http://${window.location.hostname}:${window.location.port}/callback`;
    } else {
      return REDIRECT_URI;
    }
  }

  getAuthHeaders() {
    const token = this.getAccessToken();

    // User is not logged in so that there is no headers to be returned
    if (_.isEmpty(token)) {
      return {};
    }

    const headers = {
      authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      "X-Active-Org": sessionStorage.getItem("currentOrganization") || ""
    };

    const activeRoleHeader = sessionStorage.getItem("userRoleOverride");
    const invalidRoles = [null, undefined, "null", "None"];
    if (!invalidRoles.includes(activeRoleHeader)) {
      headers["X-Active-Role"] = activeRoleHeader;
    }
    return headers;
  }

  installAuthInterceptor() {
    Axios.interceptors.request.use(config => {
      const newHeaders = { ...config.headers, ...this.getAuthHeaders() };
      return { ...config, ...{ headers: newHeaders } };
    });
  }

  /**
   * TODO:
   * Rename this, auth0 has it's own version of a profile and this might
   * end up being confusing.
   */
  get userProfile() {
    const token = this.getDecodedIdToken();
    return token[UserAuthorizationNamespace];
  }

  /**
   * This is a global unique id for a given user, provided by auth0.
   *
   * Example: auth0|asdfasdfq345345.
   */
  get userId() {
    return this.userProfile.user_id;
  }

  /**
   *
   * @return {*}
   */
  get userEmail() {
    return this.userProfile.email;
  }

  /**
   * Username is just the first part of the email address
   *
   * @return {*|string}
   */
  get userUsername() {
    const email = this.userEmail;
    return email.split("@")[0];
  }

  get userPicture() {
    const token = this.getDecodedIdToken();
    return token.picture;
  }

  /**
   *
   */
  get userMixpanelPeopleProperties() {
    const profile = this.userProfile;

    return {
      $email: profile.email,
      $name: profile.email,
      "User Privileges": profile.privileges,
      "User Solutions": profile.solutions,
      "User Org Profiles": profile.org_profiles
    };
  }
}

const AuthenticationUtils = new Authentication();

export default AuthenticationUtils;
