/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Table } from "react-bootstrap";
import Colors from "../styles/colors";

const DetailsTable = props => {
  const headers = props.headers.map((h, i) => <th key={i}>{h}</th>);
  return (
    <div
      css={{
        display: "block",
        overflowY: "auto",
        backgroundColor: "white",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)",
        borderRadius: "3px"
      }}
    >
      <Table striped={true} size="sm">
        <thead css={{ color: Colors.background.GRAY_BLUE }}>
          <tr data-qa="header-table-details">{headers}</tr>
        </thead>
        <tbody data-qa="body-table-details">{props.rows}</tbody>
      </Table>
    </div>
  );
};

DetailsTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired
};

export default DetailsTable;
