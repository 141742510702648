import * as math from "mathjs";

/*

Using the quick and dirty meters-to-longitude approach outlined here:
    https://gis.stackexchange.com/questions/2951/algorithm-for-offsetting-a-latitude-longitude-by-some-amount-of-meters

*/

// meters in one degree of latitude
const M_TO_LAT = 111111;

const mToLat = m => m / M_TO_LAT;

const mToLng = (m, lat) => {
  const latDeg = math.unit(lat, "deg");
  return m / (M_TO_LAT * math.cos(latDeg));
};

export const getRadialBounds = (lat, lng, radiusM) => {
  const rLat = mToLat(radiusM);
  const rLng = mToLng(radiusM, lat);
  const top = lat + rLat;
  const left = lng - rLng;
  const bottom = lat - rLat;
  const right = lng + rLng;
  return [top, left, bottom, right];
};
