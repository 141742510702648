import { connect } from "react-redux";
import LocationEditView from "./LocationEditView";
import { getAuthorization } from "../auth/AuthorizationSelectors";
import {
  addLocation,
  addAndLinkLocation,
  editLocation,
  deleteLocation,
  fetchLocationDetails,
  getSelectedLocationId,
  getSelectedLocation,
  fetchHeatMapData,
  isLocationLinked,
  isLocationSaved,
  setLocationSaved,
  setLocationLinked,
  clearActionStatus,
  fetchCountries,
  fetchSubdivisions,
  clearSubdivisions,
  getCurrentSubdivisionsCountryCode
} from "../location/LocationsState";
import LadsState from "../lads/LadsState";
import LocationMatchingState from "../location-resolution-edit/LocationMatchingState";
import * as OrganizationState from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  const locationID = getSelectedLocationId(state);
  return {
    authorization: getAuthorization(state),
    lads: LadsState.selectors.getLadsList(state),
    heatmapData: state.locations.heatmap.data,
    locationID,
    heatmapIsLoading: state.locations.heatmap.isLoading,
    selectedLocation: getSelectedLocation(state),
    unresolvedLocation: LocationMatchingState.selectors.getUnresolvedLocation(
      state
    ),
    unresolvedShipment: LocationMatchingState.selectors.getShipmentWithUnresolvedLocation(
      state
    ),
    activeOrganization: OrganizationState.getActiveOrganization(state),
    mapTypeOverride: OrganizationState.getMapTypeOverride(state),
    googleMaps: state.maps.googleMaps,
    hereMapsPlatform: state.maps.hereMapsPlatform,
    locationSaved: isLocationSaved(state),
    locationLinked: isLocationLinked(state),
    actionStatus: state.locations.locations.actionStatus,
    countries: state.locations.countries.data,
    subdivisions: state.locations.subdivisions.data,
    subdivisionsCountryCode: getCurrentSubdivisionsCountryCode(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addLocation: data => dispatch(addLocation(data)),
    addAndLinkLocation: (newLocation, unresolvedID, unresolvedLocation) =>
      dispatch(
        addAndLinkLocation(newLocation, unresolvedID, unresolvedLocation)
      ),
    editLocation: (id, data) => dispatch(editLocation(id, data)),
    deleteLocation: id => dispatch(deleteLocation(id)),
    fetchLocationDetails: (id, dereference) =>
      dispatch(fetchLocationDetails(id, dereference)),
    setLocationSaved: value => dispatch(setLocationSaved(value)),
    setLocationLinked: value => dispatch(setLocationLinked(value)),
    pushLocationsScreen: () => dispatch({ type: "LOCATION_MANAGEMENT" }),
    pushShipmentDetailView: shipmentID =>
      dispatch({
        type: "SHIPMENT_DETAIL",
        payload: { shipment_id: shipmentID }
      }),
    fetchHeatMapData: locationID => dispatch(fetchHeatMapData(locationID)),
    clearActionStatus: () => dispatch(clearActionStatus()),
    clearSubdivisions: () => dispatch(clearSubdivisions()),
    fetchCountries: () => dispatch(fetchCountries()),
    fetchSubdivisions: countryCode => dispatch(fetchSubdivisions(countryCode))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationEditView);
