/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FaRegTimesCircle } from "react-icons/fa";
import colors from "../../../styles/colors";

const LocationsCloseButton = ({
  t,
  style,
  height = 40,
  width = 60,
  ...props
}) => {
  return (
    <div css={{ cursor: "pointer" }}>
      <figure
        css={{
          alignItems: "center",
          backgroundColor: colors.background.DARK_GRAY,
          borderRadius: "3px",
          display: "flex",
          height: height,
          flex: "0 0 100%",
          flexDirection: "column",
          justifyContent: "space-around",
          width: width,
          ":hover": {
            backgroundColor: colors.background.DARK_TEAL,
            cursor: "pointer"
          },
          ...style
        }}
        {...props}
      >
        <figcaption
          css={{
            color: "white",
            fontSize: 24,
            fontWeight: 400,
            textTransform: "uppercase",
            textAlign: "center"
          }}
        >
          <FaRegTimesCircle css={{ marginTop: -5 }} />
          <div css={{ fontSize: 10, fontStyle: "italic", marginTop: -5 }}>
            {t("end-to-end:Close")}
          </div>
        </figcaption>
      </figure>
    </div>
  );
};

export default LocationsCloseButton;
