/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import Colors from "../../styles/colors";
import DownloadDataButton from "../../components/saved-search/DownloadDataButton";
import * as Styles from "./PartnerDashboardStyles";
import PartnerDashboardSearchBarState from "./PartnerDashboardSearchBarState";
import MilestoneEventsTable from "./MilestoneEventsTable";
import PartnerDashboardSearchBarContainer from "./PartnerDashboardSearchBarContainer";
import FilterSectionContainer from "./MilestoneEventFilterSectionContainer";
import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";

const DataHeader = ({ total }) => {
  const { t } = useTranslation("fv-dashboard");

  return (
    <div css={[Styles.flexRow, Styles.centeredRow]}>
      <span style={{ fontSize: 16 }}>
        <span style={{ fontWeight: 600 }}>
          {total.toLocaleString(undefined)}
        </span>{" "}
        {t("fv-dashboard:Total VINs")}
      </span>
    </div>
  );
};

const PartnerDashboard = props => {
  const [showFilters, setShowFilters] = useState(true);
  useTrackWithMixpanelOnce("Viewed Page: Partners / Dashboard");
  const totalEntities = useSelector(
    PartnerDashboardSearchBarState.selectors.getTotalEntities
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(PartnerDashboardSearchBarState.actionCreators.searchEntities());
  }, [dispatch]);

  return (
    <div
      css={[
        Styles.flexCol,
        Styles.whiteBg,
        Styles.overflowWrapper,
        { padding: "20px 20px 0 20px" }
      ]}
    >
      <PartnerDashboardSearchBarContainer
        isShowingFilters={showFilters}
        toggleShowFilters={() => {
          setShowFilters(!showFilters);
        }}
      />
      <FilterSectionContainer
        show={showFilters}
        backgroundColor={Colors.background.GRAY}
        sectionStyle={{ paddingRight: "12em" }}
      />
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 0"
        }}
      >
        <DataHeader total={totalEntities} />
        <DownloadDataButton
          onClick={() => {
            dispatch(
              PartnerDashboardSearchBarState.actionCreators.exportSearch()
            );
          }}
        />
      </div>
      <div>
        <MilestoneEventsTable />
      </div>
    </div>
  );
};

export default PartnerDashboard;
