import moment from "moment";
import axios from "axios";

import { SEARCH_CATEGORIES } from "./MetalTrackingSearchBarCategoryDefs";
import { FILTERS } from "./MetalTrackingFilterSectionCategoryDefs";
import apiUrl from "../../api-url";
import buildSearchBarState from "../../components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "mtSearch";

// Actions

const FVE = actionName => `${STORE_MOUNT_POINT}/${actionName}`;
const RECEIVE_METAL_TRACKING_ENTITIES = FVE("RECEIVE_METAL_TRACKING_ENTITIES");
const RECEIVE_METAL_TRACKING_RACK_WATCH_TOGGLE = FVE(
  "RECEIVE_METAL_TRACKING_RACK_WATCH_TOGGLE"
);

// Helpers
const findReference = (entity, key) => {
  if (!entity.references) {
    return null;
  }
  const ref = entity.references.find(obj => key in obj);
  return ref ? ref[key] : null;
};

/**
 * Calculate the warning type given an entity
 */
const calculateWarning = entity => {
  const now = moment();
  const toExpire = entity.useByDate
    ? moment(entity.useByDate).diff(now, "hours")
    : 30 * 24;
  if (toExpire < 24) {
    return "<24 hours";
  }
  if (toExpire < 24 * 7) {
    return "<7 days";
  }
  if (toExpire < 24 * 14) {
    return "7-14 days";
  }
  return "15+ days";
};

export const translateEntity = entity => {
  let data = {
    id: entity.id,
    description: entity.description,
    watch: JSON.parse(entity.watch), // for some reason "false" comes as string
    ts: entity.ts,
    plantId: findReference(entity, "plantId"),
    tagId: findReference(entity, "barcode"),
    cspcId: findReference(entity, "cspcId"),
    heatTreatDate: findReference(entity, "heatTreatDate"),
    useByDate: findReference(entity, "useByDate"),
    maxQuantity: findReference(entity, "maxQuantity"),
    actualQuantity: findReference(entity, "actualQuantity"),
    rackLocation: findReference(entity, "rackLocation")
  };
  data["warning"] = calculateWarning(data);
  return data;
};

// Action Creators

const fetchSearch = (qs, solutionId, duck, dispatch) => {
  dispatch({ type: "METAL_TRACKING_SEARCH_RESULTS" });
};

// Action creators
export const fetchMetalTrackingEntities = solutionId => {
  // H1-1445: Set page size manually
  const url = apiUrl(`/entity/solution/GM_PAT/entity?pageSize=100000`);
  return dispatch => {
    return axios.get(`${url}`).then(responses => {
      dispatch({
        type: RECEIVE_METAL_TRACKING_ENTITIES,
        payload: responses.data
      });
    });
  };
};

export const toggleWatchedRackFlag = (solutionId, entity) => {
  const url = apiUrl(`/entity/solution/GM_PAT/entity/${entity.id}`);
  return dispatch => {
    return Promise.all([axios.patch(`${url}`, { watch: !entity.watch })]).then(
      responses => {
        dispatch({
          type: RECEIVE_METAL_TRACKING_RACK_WATCH_TOGGLE,
          payload: responses[0].data
        });
      }
    );
  };
};

const MetalTrackingEntitiesReducer = (state, action) => {
  switch (action.type) {
    case RECEIVE_METAL_TRACKING_ENTITIES:
      return {
        ...state,
        data: { data: action.payload.data.map(translateEntity) }
      };
    case RECEIVE_METAL_TRACKING_RACK_WATCH_TOGGLE:
      return {
        ...state,
        data: {
          data: state.data.data.map(item => {
            // Change "watch" property of the right element
            if (item.id === action.payload.id) {
              item.watch = action.payload.watch;
            }
            return item;
          })
        }
      };

    default:
      return state;
  }
};

export default buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [MetalTrackingEntitiesReducer]
);
