import React, { useState } from "react";
import { DropdownButton, DropdownItem } from "react-bootstrap";
import Colors from "../../styles/colors";
import {
  getMobileTrackingEnabled,
  getShipmentCompleted
} from "../shipment-detail/ShipmentUtils";
import { useTranslation } from "react-i18next";

export const ActionNav = ({
  shipment,
  enableReviewStatus,
  eventHandler,
  enableShipmentEvents,
  enableCancelShipment,
  assetID
}) => {
  const { t } = useTranslation("shipment-details");
  const [isOpen, setIsOpen] = useState(false);
  const actionToggle = () => setIsOpen(!isOpen);

  return (
    <DropdownButton
      variant="primary"
      open={isOpen}
      title={t("shipment-details:Actions")}
      id="action-selector"
      color={Colors.tableHeadingBackground}
      onToggle={() => {
        actionToggle();
      }}
      className="float-right"
    >
      {enableReviewStatus && (
        <DropdownItem
          onClick={() => {
            eventHandler(null, "EDIT_REVIEW_STATUS");
          }}
        >
          {t("shipment-details:Edit Review Status")}
        </DropdownItem>
      )}
      {enableShipmentEvents && (
        <DropdownItem
          onClick={() => {
            eventHandler(null, "SHOW_ADD_ASSET");
          }}
        >
          {t("shipment-details:Assign Asset/Trailer")}
        </DropdownItem>
      )}
      {enableShipmentEvents && assetID && assetID !== "" && (
        <DropdownItem
          onClick={() => {
            eventHandler(null, "SHOW_UNASSIGN_ASSET");
          }}
        >
          {t("shipment-details:Unassign Asset")}
        </DropdownItem>
      )}
      {enableShipmentEvents && (
        <DropdownItem
          onClick={() => {
            eventHandler(null, "SHOW_ADD_EVENTS");
          }}
        >
          {t("shipment-details:Report Arrival/Departure Events")}
        </DropdownItem>
      )}
      {enableCancelShipment && (
        <DropdownItem
          onClick={() => {
            eventHandler(null, "SHOW_CANCEL_SHIPMENT");
          }}
        >
          {t("shipment-details:Cancel Shipment")}
        </DropdownItem>
      )}
      {!getMobileTrackingEnabled(shipment) && !getShipmentCompleted(shipment) && (
        <DropdownItem
          onClick={() => {
            eventHandler(null, "TOGGLE_ENABLE_MOBILE_TRACKING");
          }}
        >
          {t("shipment-details:Enable Mobile Tracking")}
        </DropdownItem>
      )}
      {getMobileTrackingEnabled(shipment) && !getShipmentCompleted(shipment) && (
        <DropdownItem
          onClick={() => {
            eventHandler(null, "TOGGLE_DISABLE_MOBILE_TRACKING");
          }}
        >
          {t("shipment-details:Disable Mobile Tracking")}
        </DropdownItem>
      )}
    </DropdownButton>
  );
};
