/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { FlexColDiv } from "../../../styles/container-elements";
import _ from "lodash";

import Colors from "../../../styles/colors";
import { CHICLET_COLORS } from "../../../components/chiclets";
import {
  localizedDateFormatter,
  localizedTimeFormatter
} from "../../../utils/date-time";

const Addr = styled.div({
  color: "#a1a1a1",
  lineHeight: "1em",
  marginTop: ".5em"
});

export const CoordinateInfoPopup = ({ data }) => {
  let col = CHICLET_COLORS.Unclassified;

  const city = data.city ? data.city : _.get(data, "address.City", null);
  const state = data.state ? data.state : _.get(data, "address.State", null);

  return (
    <FlexColDiv
      css={{
        backgroundColor: "white",
        border: `4px solid ${col.primary}`,
        borderRadius: 4,
        padding: ".5em",
        fontSize: "x-small",
        width: "10em"
      }}
    >
      <Addr>
        <span
          style={{ color: Colors.text.DARK_BLUE, fontWeight: "bold" }}
        >{`${localizedDateFormatter(data.time)}`}</span>
      </Addr>
      <Addr>
        <span
          style={{ color: Colors.text.DARK_BLUE, fontWeight: "bold" }}
        >{`${localizedTimeFormatter(data.time)}`}</span>
      </Addr>
      {!_.isNil(city) && !_.isNil(state) ? (
        <Addr>{`${city}, ${state}`}</Addr>
      ) : null}
      {/* <div
        css={{
          borderLeft: "12px solid transparent",
          borderRight: "12px solid transparent",
          borderTop: `12px solid ${col.primary}`,
          bottom: -6,
          height: 0,
          position: "absolute",
          right: 11,
          width: 0
        }}
      /> */}
    </FlexColDiv>
  );
};
