import _ from "lodash";
import moment from "moment";
import { getLocations } from "../../modules/location/LocationsState";

// query parameter builders
export const getBasicQueryString = (queryParameter, filterValue) => {
  let url = "";
  if (isValidBasicFilter(filterValue)) {
    url += `&${queryParameter}=${encodeURIComponent(filterValue)}`;
  }
  return url;
};

// Some endpoints require params to be JSON strings (uncommon)
export const getJsonQueryString = (queryParameter, filterValue) => {
  let url = "";
  if (isValidBasicFilter(filterValue)) {
    url += `&${queryParameter}=${JSON.stringify(filterValue)}`;
  }
  return url;
};

/**
 * In some cases where filter label and value are different, this query string
 * method will give priority to the value instead of the label.
 */
export const getBasicQueryStringFilterValuePriority = (
  queryParameter,
  filterLabel,
  filterValue
) => {
  return getBasicQueryString(queryParameter, filterValue || filterLabel);
};

// is a filterValue a valid array with elements or non-blank string search?
const isValidBasicFilter = filterValue => {
  if (
    filterValue != null &&
    ((Array.isArray(filterValue) && filterValue.length > 0) ||
      (filterValue && !/^\s*$/.test(filterValue)))
  ) {
    return true;
  }
  return false;
};

export const getShipmentDateRangeQueryString = (
  queryParameter,
  filterValue
) => {
  // Add on up to two query parameters -- the start and end dates in filterValue
  let url = "";
  if (isValidDateRangeFilter(filterValue)) {
    if (filterValue.from != null) {
      url += `&${queryParameter}_start=${moment(filterValue.from).unix()}`;
    }
    if (filterValue.to != null) {
      url += `&${queryParameter}_end=${moment(filterValue.to).unix()}`;
    }
  }
  return url;
};

export const getEntityDateRangeQueryString = (queryParameter, filterValue) => {
  // Add on up to two query parameters -- the start and end dates in filterValue
  let url = "";
  if (isValidDateRangeFilter(filterValue)) {
    if (filterValue.from != null) {
      url += `&${queryParameter}From=${moment(filterValue.from).format(
        "YYYY-MM-DD HH:mm:ss"
      )}`;
    }
    if (filterValue.to != null) {
      url += `&${queryParameter}To=${moment(filterValue.to).format(
        "YYYY-MM-DD HH:mm:ss"
      )}`;
    }
  }
  return url;
};

// Format a date range querystring as "ts=from -> to""
export const getApiLogsDateRangeQueryString = (queryParameter, filterValue) => {
  let url = "";
  if (isValidDateRangeFilter(filterValue)) {
    if (filterValue.from != null && filterValue.to != null) {
      const pattern = "YYYY-MM-DDTHH:mm:ss";
      const from = moment(filterValue.from).format(pattern);
      const to = moment(filterValue.to).format(pattern);
      url += `&${queryParameter}=${from} -> ${to}`;
    }
  }
  return url;
};

export const getEmptyQueryString = () => "";

// is a filterValue a valid date range object?
const isValidDateRangeFilter = filterValue => {
  if (
    filterValue != null &&
    typeof filterValue === "object" &&
    ("from" in filterValue || "to" in filterValue)
  ) {
    return true;
  }
  return false;
};

export const getEverythingQueryString = (
  queryParameter,
  filterValue,
  state
) => {
  // Map locations in the search string to their IDs using state
  const locations = getLocations(state);
  const matchingLocations = getMatchingLocationIDs(locations, filterValue);

  // DEV-1193: append matching location ids to the shipper_location_ids query parameter
  if (matchingLocations.length > 0 && filterValue !== matchingLocations[0]) {
    const queryStr = `${matchingLocations.join()}`;
    return `&${queryParameter}=${encodeURIComponent(
      filterValue
    )}&shipper_location_ids=${encodeURIComponent(queryStr)}`;
  } else {
    return `&${queryParameter}=${encodeURIComponent(filterValue)}`;
  }
};

// Helper for query builders
const getMatchingLocationIDs = (locations, searchValue) => {
  // If the search value is a number, the search is for a location ID directly
  if (_.isNumber(searchValue)) {
    return [searchValue];
  }
  if (!locations) {
    return [];
  }
  const matchingLocations = locations.filter(l => {
    return (
      (l.name && l.name.toLowerCase().includes(searchValue.toLowerCase())) ||
      (l.city && l.city.toLowerCase().includes(searchValue.toLowerCase())) ||
      (l.state && l.state.toLowerCase().includes(searchValue.toLowerCase())) ||
      (l.code && l.code.toLowerCase().includes(searchValue.toLowerCase()))
    );
  });
  return matchingLocations.map(l => l.id);
};

// Is the filterValue valid?
export const isValidFilter = filterValue => {
  return isValidBasicFilter(filterValue) || isValidDateRangeFilter(filterValue);
};
