import { connect } from "react-redux";

import ShipmentSearchView from "./ShipmentSearchView";
import ShipmentSearchBarState from "./ShipmentSearchBarState";
import LadsState from "../lads/LadsState";
import { getSolutionId } from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    shipments: ShipmentSearchBarState.selectors.getSearchResults(state),
    isLoading: ShipmentSearchBarState.selectors.getIsLoading(state),
    isExporting: ShipmentSearchBarState.selectors.getIsExporting(state),
    exportFailed: ShipmentSearchBarState.selectors.getExportFailed(state),
    searchCategory: ShipmentSearchBarState.selectors.getSearchCategory(state),
    searchFilters: ShipmentSearchBarState.selectors.getSearchFilters(state),
    lads: LadsState.selectors.getLadsList(state),
    page: ShipmentSearchBarState.selectors.getPage(state),
    pageSize: ShipmentSearchBarState.selectors.getPageSize(state),
    totalPages: ShipmentSearchBarState.selectors.getTotalPages(state),
    totalShipments: ShipmentSearchBarState.selectors.getTotalEntities(state),
    showFilters: ShipmentSearchBarState.selectors.getShowAdvancedSearch(state),
    searchObj: ShipmentSearchBarState.selectors.getSelectedSavedSearch(state),
    solutionId: getSolutionId(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        ShipmentSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize
        )
      ),
    setSort: (solutionId, sortColumn, reverse) =>
      dispatch(
        ShipmentSearchBarState.actionCreators.setSort(
          solutionId,
          sortColumn,
          reverse
        )
      ),
    resetSearch: () => {
      dispatch(ShipmentSearchBarState.actionCreators.resetSearchAndFilters());
      dispatch(ShipmentSearchBarState.actionCreators.searchEntities());
    },
    pushShipmentDetailView: shipmentID =>
      dispatch({
        type: "SHIPMENT_DETAIL",
        payload: { shipment_id: shipmentID }
      }),
    exportAllShipments: () => {
      dispatch(ShipmentSearchBarState.actionCreators.exportSearch());
    },
    toggleShowFilters: showFilters =>
      dispatch(
        ShipmentSearchBarState.actionCreators.toggleShowFilters(showFilters)
      )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentSearchView);
