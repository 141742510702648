/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Enum } from "enumify";
import _ from "lodash";
import { IoMdWarning } from "react-icons/io";
import Colors from "../../styles/colors";

export const AT_CAPACITY_LOWER_LIMIT = 60;
export const AT_CAPACITY_UPPER_LIMIT = 80;

export class AdcCapacity extends Enum {}
AdcCapacity.initEnum({
  NA: {
    get label() {
      return "N/A";
    },
    get color() {
      return Colors.text.LIGHT_GRAY;
    },
    get iconElem() {
      return null;
    },
    get queryString() {
      return "";
    }
  },
  OVER_CAPACITY: {
    get label() {
      return "Over Capacity";
    },
    get color() {
      return Colors.highlight.RED;
    },
    get iconElem() {
      return <IoMdWarning />;
    },
    get queryString() {
      return `&percentFrom=${AT_CAPACITY_UPPER_LIMIT + 1}`;
    }
  },
  AT_CAPACITY: {
    get label() {
      return "At Capacity";
    },
    get color() {
      return Colors.highlight.YELLOW;
    },
    get iconElem() {
      return null;
    },
    get queryString() {
      return `&percentFrom=${AT_CAPACITY_LOWER_LIMIT +
        1}&percentTo=${AT_CAPACITY_UPPER_LIMIT}`;
    }
  },
  UNDER_CAPACITY: {
    get label() {
      return "Under Capacity";
    },
    get color() {
      return Colors.highlight.GREEN;
    },
    get iconElem() {
      return null;
    },
    get queryString() {
      return `&percentFrom=0&percentTo=${AT_CAPACITY_LOWER_LIMIT}`;
    }
  }
});

AdcCapacity.forPercentFill = (percentFill, capacity) => {
  if (!capacity) {
    return AdcCapacity.NA;
  }

  if (percentFill === undefined || percentFill === null) {
    return AdcCapacity.NA;
  }

  if (percentFill >= 0 && percentFill <= AT_CAPACITY_LOWER_LIMIT) {
    return AdcCapacity.UNDER_CAPACITY;
  }

  if (
    percentFill > AT_CAPACITY_LOWER_LIMIT &&
    percentFill <= AT_CAPACITY_UPPER_LIMIT
  ) {
    return AdcCapacity.AT_CAPACITY;
  }

  if (percentFill > AT_CAPACITY_UPPER_LIMIT) {
    return AdcCapacity.OVER_CAPACITY;
  }
  return AdcCapacity.NA;
};

const _labelIndex = _.keyBy(AdcCapacity.enumValues, "label");

AdcCapacity.forLabel = label => {
  return _.get(_labelIndex, label, AdcCapacity.NA);
};
