/** @jsx jsx */
import { jsx } from "@emotion/core";
import colors from "../../../styles/colors";
import { FaAngleDoubleRight, FaAngleDoubleLeft } from "react-icons/fa";

const LadsListToggleButton = ({
  style,
  showLadsPanel,
  showLocationsPanel,
  height = 60,
  width = 60,
  selectedLad,
  ...props
}) => {
  return (
    <div css={{ cursor: "pointer" }}>
      <figure
        css={{
          alignItems: "center",
          backgroundColor: colors.background.DARK_GRAY,
          borderRadius: "3px",
          display: "flex",
          height: height,
          flex: "0 0 100%",
          flexDirection: "column",
          justifyContent: "space-around",
          width: width,
          ":hover": {
            backgroundColor: colors.background.DARK_TEAL,
            cursor: "pointer"
          },
          ...style
        }}
        {...props}
      >
        <figcaption
          css={{
            color: "white",
            fontSize: 24,
            fontWeight: 400,
            textTransform: "uppercase",
            textAlign: "center"
          }}
        >
          {showLadsPanel || showLocationsPanel ? (
            <FaAngleDoubleRight />
          ) : (
            <FaAngleDoubleLeft />
          )}
        </figcaption>
      </figure>
    </div>
  );
};

export default LadsListToggleButton;
