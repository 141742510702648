/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Button } from "react-bootstrap";
import { ADD_LOCATION_ID } from "../location/LocationsState";
import { useTranslation } from "react-i18next";

const LocationMatchingControls = ({
  setReturnToPreviousScreen,
  pushLocationEditScreen,
  enableUpdate,
  assignLocationMatch
}) => {
  const { t } = useTranslation("location-matching");

  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <Button
        variant="success"
        style={{
          margin: "0.5em",
          padding: "0.75em",
          paddingLeft: "1.0em",
          paddingRight: "1.0em"
        }}
        onClick={() => {
          pushLocationEditScreen(ADD_LOCATION_ID);
        }}
      >
        {t("location-matching:Create New Location")}
      </Button>
      <Button
        variant="outline-primary"
        style={{
          margin: "0.5em",
          padding: "0.75em",
          paddingLeft: "3.0em",
          paddingRight: "3.0em"
        }}
        onClick={() => {
          setReturnToPreviousScreen(true);
        }}
      >
        {t("location-matching:Cancel")}
      </Button>
      <Button
        variant="primary"
        disabled={!enableUpdate}
        style={{
          margin: "0.5em",
          leftPadding: "2.0em",
          paddingLeft: "1.0em",
          paddingRight: "1.0em"
        }}
        onClick={() => {
          assignLocationMatch();
        }}
      >
        {t("location-matching:Update Location")}
      </Button>
    </div>
  );
};

LocationMatchingControls.propTypes = {
  assignLocationMatch: PropTypes.func.isRequired,
  enableUpdate: PropTypes.bool.isRequired,
  pushLocationEditScreen: PropTypes.func.isRequired,
  setReturnToPreviousScreen: PropTypes.func.isRequired
};

export default LocationMatchingControls;
