import { connect } from "react-redux";
import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "./AdcSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import AdcDomainDataState from "../domain-data/AdcDomainData";
import {
  getAdcCountryFilterOptions,
  getAdcLocationFilterOptions
} from "./AdcSearchBarStateSelectors";
import AdcEntitiesState from "../adc-dashboard/AdcEntitiesState";

const mapStateToProps = state => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata
  } = SearchBarState.selectors;
  const {
    getProductTypes,
    getHoldTypes,
    getExceptionTypes
  } = AdcDomainDataState.selectors;
  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    solutionId: getSolutionId(state),
    productTypes: getProductTypes(state),
    holdTypes: getHoldTypes(state),
    exceptionTypes: getExceptionTypes(state),
    countries: getAdcCountryFilterOptions(state),
    adcLocations: getAdcLocationFilterOptions(state)
  };
};

const {
  setSearchCategoryForKey,
  setSearchText,
  clearSearchText,
  resetSearchBar,
  searchEntities
} = SearchBarState.actionCreators;

const { fetchDomainData } = AdcDomainDataState.actionCreators;

const { fetchDemandAreaList } = AdcEntitiesState.actionCreators;

const actionCreators = {
  setSearchCategory: setSearchCategoryForKey,
  setSearchText,
  clearSearchText,
  resetSearchBar,
  searchEntities,
  fetchDomainData: solutionId => {
    return dispatch => {
      dispatch(fetchDomainData(solutionId));
      dispatch(fetchDemandAreaList(solutionId));
    };
  }
};

export default connect(mapStateToProps, actionCreators)(SearchBar);
