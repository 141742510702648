/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import SelectInput from "../../../components/forms/inputs/SelectInput";
import FormRow from "../../../components/forms/FormRow";

const ApiSelect = ({
  apiNameOptions,
  apiTypeOptions,
  requestSampleOptions,
  eventHandler,
  selectedApiName,
  selectedApiType,
  selectedRequestSample,
  t
}) => {
  return (
    <FormRow style={{ borderBottom: "1px solid #ddd", paddingBottom: "1em" }}>
      <SelectInput
        label={t("API Type")}
        options={apiTypeOptions}
        value={selectedApiType}
        onChange={val => eventHandler("API_TYPE", val)}
        minWidth="52em"
      />
      <SelectInput
        label={t("API Name")}
        options={apiNameOptions}
        value={selectedApiName}
        onChange={val => eventHandler("API_NAME", val)}
        minWidth="52em"
      />
      <SelectInput
        label={t("Request Sample")}
        options={requestSampleOptions}
        value={selectedRequestSample}
        onChange={val => eventHandler("REQUEST_SAMPLE", val)}
        minWidth="52em"
      />
    </FormRow>
  );
};

ApiSelect.propTypes = {
  apiTypeOptions: PropTypes.array,
  selectedApiType: PropTypes.number
};

export default withTranslation(["documentation"])(ApiSelect);
