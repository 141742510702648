import axios from "axios";
import apiUrl from "../../api-url";

const STORE_MOUNT_POINT = "createMilestone";

// Actions
const CREATE_MILESTONE_SUCCEEDED = "createMilestone/CREATE_MILESTONE_SUCCEEDED";
const CREATE_MILESTONE_FAILED = "createMilestone/CREATE_MILESTONE_FAILED";
const RESET_CREATE_FORM = "createMilestone/RESET_CREATE_FORM";

// Action creators
function createMilestone(solutionId, data) {
  return dispatch => {
    let requests = [];

    requests = data.vins.map(vin => {
      const url = apiUrl(
        `/entity/solution/${solutionId}/entity/${vin}/status-update`
      );
      return axios.post(url, { ...data.payload });
    });

    return Promise.all(requests)
      .then(responses => {
        dispatch({ type: CREATE_MILESTONE_SUCCEEDED });
      })
      .catch(err => {
        dispatch({ type: CREATE_MILESTONE_FAILED, error: err });
      });
  };
}

function resetCreateForm() {
  return {
    type: RESET_CREATE_FORM
  };
}

// Initial state
export const initialState = {
  createStatus: null
};

// selectors
const getCreateStatus = state => state[STORE_MOUNT_POINT].createStatus;

// Reducer
function CreateMilestoneReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_MILESTONE_SUCCEEDED:
      return Object.assign({}, state, {
        createStatus: "CREATED"
      });
    case CREATE_MILESTONE_FAILED:
      return Object.assign({}, state, {
        createStatus: "ERROR"
      });
    case RESET_CREATE_FORM:
      return Object.assign({}, state, {
        createStatus: null
      });
    default:
      return state;
  }
}

// interface
const CreateMilestoneState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    createMilestone,
    resetCreateForm
  },
  selectors: {
    getCreateStatus
  },
  reducer: CreateMilestoneReducer
};
export default CreateMilestoneState;
