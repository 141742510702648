import React from "react";
// import _ from "lodash";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";

import { FlexColDiv } from "../../styles/container-elements";

class UnassignAssetModal extends React.Component {
  render() {
    const {
      show,
      hide,
      assetID,
      unassignAsset,
      organization,
      organizations,
      shipment,
      t
    } = this.props;

    return (
      <Modal
        show={show}
        style={{ ":width": "300px" }}
        onHide={() => hide()}
        onEnter={this.handleOnEnter}
      >
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {t("Unassign Asset")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <FlexColDiv
            css={{
              padding: "1em"
            }}
          >
            {`${t("Are you sure you wish to unassign asset ID")} ${assetID}?`}
          </FlexColDiv>
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em"
            }}
            onClick={() => hide()}
          >
            {t("Cancel")}
          </Button>
          <Button
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300"
            }}
            onClick={() => {
              unassignAsset(shipment, organization, organizations, assetID);
              hide();
            }}
          >
            {t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const withT = withTranslation("shipment-details")(UnassignAssetModal);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withT);
