import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

/**
 * This exists to prevent unknown props from being applied to the column divs.
 * We use a lot of getProps for the cols, but these extra props are applied
 * directly to the parent div, and then used in in the individual cells.
 *
 * Example:
 *
 * Warning: React does not recognize the `toggleWatchedRackFlag` prop on a DOM element.
 * If you intentionally want it to appear in the DOM as a custom attribute,
 * spell it as lowercase `togglewatchedrackflag` instead.
 * If you accidentally passed it from a parent component, remove it from the DOM element.
 *
 *
 * @param toggleSort
 * @param className
 * @param children
 * @param rest
 * @return {*}
 * @constructor
 */
const ReactTableTd = ({ toggleSort, className, children, ...rest }) => {
  const tdElementAttributes = {
    style: rest.style ?? null,
    onClick: rest.onClick ?? null
  };

  return (
    <div
      className={classnames("rt-td", className)}
      role="gridcell"
      {...tdElementAttributes}
    >
      {children}
    </div>
  );
};

ReactTableTd.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  toggleSort: PropTypes.func
};

export default ReactTableTd;
