/**@jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { IdBox } from "../../../components/multimodal-components/InfoBoxes";

const propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object
};

const LocationName = ({ name, style = {} }) => {
  return (
    <IdBox css={{ fontWeight: "bold", ...style }} data-qa="text-name-location">
      {name}
    </IdBox>
  );
};

LocationName.propTypes = propTypes;

export default LocationName;
