/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { find } from "lodash";

import { useIsMediumAndDown } from "../../../../components/responsive";
import HeaderDefinitionList from "../../../../components/definition-list/HeaderDefinitionList";
import DefinitionListItem from "../../../../components/definition-list/DefinitionListItem";
import LocationAddress from "../../../location/components/LocationAddress";
import DemandAreaListItem from "./DemandAreaListItem";
import StatusListItem from "./StatusListItem";

const getCapacity = location => {
  let capacity = null;

  const references = (location && location.location_references) || [];

  capacity = find(references, ["qualifier", "capacity"]);

  return capacity ? capacity.value : "";
};

const HeaderDefinitions = props => {
  const { t } = useTranslation("adc-details");
  const isMobile = useIsMediumAndDown();
  const { location } = props;
  const capacity = getCapacity(location);

  return (
    <HeaderDefinitionList
      listStyles={{
        flexDirection: isMobile ? "column" : "row"
      }}
    >
      <DefinitionListItem title={t("adc-details:Location Name & Address")}>
        <div data-qa="text-location-name">{location.name}</div>
        <div data-qa="text-location-address">
          <LocationAddress location={location} />
        </div>
      </DefinitionListItem>
      <DefinitionListItem title={t("adc-details:Total Customer Capacity")}>
        <div data-qa="text-capacity">{capacity}</div>
      </DefinitionListItem>
      <DefinitionListItem title={t("adc-details:Onsite Total")}>
        <div data-qa="text-onsite-total">{location.onSiteCount}</div>
      </DefinitionListItem>
      <DefinitionListItem title={t("adc-details:Total Aged VINs")}>
        <div data-qa="text-aged-vins">{location.agedCount}</div>
      </DefinitionListItem>
      <DemandAreaListItem location={location} />
      <StatusListItem location={location} capacity={capacity} />
    </HeaderDefinitionList>
  );
};

HeaderDefinitions.propTypes = {
  location: PropTypes.object
};

export default HeaderDefinitions;
