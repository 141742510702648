/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { LOBS } from "../../components/lads";

import PageNavigation from "./components/PageNavigation";
import { useState } from "react";
import SectionNavigation from "../../components/sections/location/SectionNavigation";
import LadTable from "./components/LadTable";

const LocationLadsListView = ({ ladsGrouped, updateLadLabel }) => {
  const [activeTab, setActiveTab] = useState(LOBS[0]);

  return (
    <div className="p-3">
      <SectionNavigation activeTab="lads" className="mb-3" />
      <PageNavigation
        activeTab={activeTab}
        onTabSelect={tab => {
          if (activeTab === tab) {
            return false;
          }
          setActiveTab(tab);
        }}
      />
      <LadTable data={ladsGrouped[activeTab]} updateLadLabel={updateLadLabel} />
    </div>
  );
};

LocationLadsListView.propTypes = {
  ladsGrouped: PropTypes.object,
  updateLadLabel: PropTypes.func.isRequired
};

export default LocationLadsListView;
