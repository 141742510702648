import moment from "moment";
import axios from "axios";
import _ from "lodash";

import { SEARCH_CATEGORIES } from "./FinVehicleSearchCategoryDefs";
import { FILTERS } from "./FinVehicleSearchFilterDefs";
import apiUrl from "../../api-url";
import buildSearchBarState from "../../components/search-bar/SearchBarStateBuilder";

// Helpers

const STORE_MOUNT_POINT = "fvSearch";

// H1-1594 Add lifecycle state to all VIN searches
const addLifeCycleParam = qs => {
  if (qs.includes("lifeCycleState")) {
    return qs;
  }

  // Include the default lifeCycleState if not already specified by a filter
  return `${qs}&lifeCycleState=Active,Completed,Delivered`;
};

const entitiesUrl = (solutionId, queryString) => {
  const qs = addLifeCycleParam(queryString);
  return apiUrl(`/entity/solution/${solutionId}/entity?${qs}`);
};

const batchSearchUrl = (solutionId, queryString, batchType) => {
  const qs = addLifeCycleParam(queryString);
  return apiUrl(
    `/entity/solution/${solutionId}/batch_search?${qs}&batchType=${batchType}`
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess()
    }
  };
};

// Actions

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (batchFilter) {
    // Batch search POST
    batchSearch(solutionId, queryString, dispatch, batchFilter);
  } else {
    // Normal search GET
    const url = entitiesUrl(solutionId, queryString);
    const config = axiosConfig();
    dispatch(duck.fetch(url, config));
    dispatch({ type: "VIN_SEARCH" });
  }
};

const batchSearch = (solutionId, queryString, dispatch, batchFilter) => {
  const url = batchSearchUrl(solutionId, queryString, batchFilter.batch_type);
  const data = {
    batch_list: batchFilter.batch_list
  };
  const config = axiosConfig();

  dispatch({
    type: `Fvsearch/REQUEST_FVSEARCH`
  });

  return axios
    .post(url, data, config)
    .then(response => {
      dispatch({
        type: `Fvsearch/RECEIVE_FVSEARCH`,
        payload: response.data ? response.data : []
      });
      dispatch({ type: "VIN_SEARCH" });
    })
    .catch(err => {
      dispatch({
        type: `Fvsearch/REQUEST_FVSEARCH_ERROR`,
        err
      });
    });
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch
);

SearchBarState.actionCreators.exportSearch = _.partial(
  SearchBarState.actionCreators.exportEntities,
  entitiesUrl,
  batchSearchUrl,
  { headers: { Accept: "text/csv;version=full" } },
  "fin-vehicle-search-results"
);

export default SearchBarState;
