/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { FlexDiv } from "../../styles/container-elements";
import ContentSectionOverlay from "./ContentSectionOverlay";

const ContentSection = props => {
  const { children, isMobile, isShowingOverlay } = props;
  return (
    <FlexDiv
      css={{
        flex: 1,
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        // For ContentSectionOverlay to position itself absolutely
        position: "relative",
        // Let the map and results take up the full screen height
        // so that the user can scroll down and see the map and table with more space
        height: isMobile ? "100vh" : null
      }}
    >
      {isShowingOverlay ? <ContentSectionOverlay /> : null}
      {children}
    </FlexDiv>
  );
};

ContentSection.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isShowingOverlay: PropTypes.bool.isRequired
};

export default ContentSection;
