/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { useState, useEffect, useMemo } from "react";

import { useIsMediumAndDown } from "../../components/responsive";

import { Row, Col } from "react-bootstrap";

import SearchBarContainer from "../adc-search/AdcSearchBarContainer";
import FilterSectionContainer from "../adc-search/AdcFilterSectionContainer";

import AgingVinsByLocation from "./components/AgingVinsByLocation";
import InboundVins from "./components/InboundVins";
import SavedSearchContainer from "../adc-search/AdcSavedSearchContainer";
import AdcCapacity from "./components/AdcCapacity";
import WatchedLocationsTableContainer from "./components/WatchedLocationsTableContainer";
import { useTranslation } from "react-i18next";
import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";
import {
  ResponsiveSection,
  FlexDiv,
  FlexColDiv
} from "../../styles/container-elements";
import SelectField from "../../components/forms/fields/SelectField";
import Colors from "../../styles/colors";

export const AdcDashboardView = props => {
  const [showFilters, setShowFilters] = useState(false);
  const [selectedDemandArea, setSelectedDemandArea] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const { t } = useTranslation("adc-dashboard");
  useTrackWithMixpanelOnce("Viewed Page: ADC / Dashboard");

  const {
    fetchAgingVinsByLocation,
    fetchAdcCapacities,
    fetchInboundVins,
    fetchLocationsCount,
    fetchDemandAreaList,
    resetSearchBar,
    clearSearchFilters,
    resetSavedSearch,
    solutionId
  } = props;

  useEffect(() => {
    fetchLocationsCount();
    fetchDemandAreaList(solutionId);
    resetSearchBar();
    clearSearchFilters();
    resetSavedSearch();
  }, [
    fetchLocationsCount,
    fetchDemandAreaList,
    resetSearchBar,
    clearSearchFilters,
    resetSavedSearch,
    solutionId
  ]);

  const {
    agingVinsByLocation,
    adcCapacities,
    inboundVins,
    demandAreas,
    locationsCount,
    pushAdcDetailsView
  } = props;
  const isMobile = useIsMediumAndDown();

  const demandAreaOptions = useMemo(() => {
    if (demandAreas && demandAreas.data && demandAreas.data.demandAreas) {
      let result = demandAreas.data.demandAreas.map(demandArea => ({
        value: demandArea,
        label: demandArea
      }));
      return result;
    }

    return [];
  }, [demandAreas]);

  useEffect(() => {
    // Reset selected locations when the Demand Area changes
    setSelectedLocations([]);
  }, [selectedDemandArea]);

  useEffect(() => {
    if (selectedDemandArea) {
      fetchAgingVinsByLocation(solutionId, selectedDemandArea.value);
      fetchAdcCapacities(solutionId, selectedDemandArea.value);
    } else {
      fetchAgingVinsByLocation(solutionId);
      fetchAdcCapacities(solutionId);
    }
  }, [
    fetchAgingVinsByLocation,
    fetchAdcCapacities,
    selectedDemandArea,
    solutionId
  ]);

  useEffect(() => {
    if (selectedDemandArea) {
      fetchInboundVins(solutionId, selectedLocations, selectedDemandArea.value);
    } else {
      fetchInboundVins(solutionId, selectedLocations);
    }
  }, [fetchInboundVins, selectedLocations, selectedDemandArea, solutionId]);

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        padding: "1em"
      }}
    >
      <SearchBarContainer
        isShowingFilters={showFilters}
        toggleShowFilters={() => {
          setShowFilters(!showFilters);
        }}
      />
      <FilterSectionContainer
        show={showFilters}
        backgroundColor={Colors.background.GRAY}
        sectionStyle={{ paddingRight: "12em" }}
      />
      <FlexColDiv>
        <FlexDiv
          style={{
            flexDirection: isMobile ? "column" : "row",
            justifyContent: "space-between",
            alignItems: isMobile ? "flex-start" : "center",
            fontSize: 16,
            margin: ".5em 0 .5em 0"
          }}
        >
          <FlexDiv>
            <FlexDiv
              style={{
                width: "18em",
                marginRight: "1em",
                alignItems: "center"
              }}
            >
              <span>
                <span css={{ fontWeight: 600 }}>
                  {locationsCount.toLocaleString()}
                </span>{" "}
                <span>{t("adc-dashboard:Total Locations")}</span>
              </span>
            </FlexDiv>

            <SelectField
              options={demandAreaOptions}
              value={selectedDemandArea}
              onChange={setSelectedDemandArea}
              placeholder={t("components:Demand Area")}
              placeholderFontStyle="italic"
              boxShadow="0px 3px 14px 0.5px rgba(0,0,0,0.05)"
              borderWidth="0.5px"
              borderColor="#f2f2f2"
              fontSize={12.5}
              isClearable={true}
            />
          </FlexDiv>
          <SavedSearchContainer readOnly={true} />
        </FlexDiv>
        <Row>
          <Col style={{ marginBottom: "1em" }}>
            <AgingVinsByLocation
              bucketsData={agingVinsByLocation}
              handler={pushAdcDetailsView}
            />
          </Col>
          <Col style={{ marginBottom: "1em" }}>
            <AdcCapacity
              capacityData={adcCapacities}
              handler={pushAdcDetailsView}
            />
          </Col>
          <Col style={{ marginBottom: "1em" }}>
            <InboundVins
              demandArea={selectedDemandArea?.value}
              inboundVins={inboundVins}
              selectedLocations={selectedLocations}
              setSelectedLocations={setSelectedLocations}
            />
          </Col>
        </Row>
      </FlexColDiv>
      <div className="mt-2 mb-2">
        <WatchedLocationsTableContainer />
      </div>
    </ResponsiveSection>
  );
};

AdcDashboardView.propTypes = {
  adcCapacities: PropTypes.object,
  agingVinsByLocation: PropTypes.object,
  inboundVins: PropTypes.object,
  clearSearchFilters: PropTypes.func.isRequired,
  fetchAgingVinsByLocation: PropTypes.func.isRequired,
  fetchAdcCapacities: PropTypes.func.isRequired,
  fetchInboundVins: PropTypes.func.isRequired,
  fetchLocationsCount: PropTypes.func.isRequired,
  fetchDemandAreaList: PropTypes.func.isRequired,
  pushAdcDetailsView: PropTypes.func.isRequired,
  resetSavedSearch: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  solutionId: PropTypes.string,
  locationsCount: PropTypes.number,
  demandAreas: PropTypes.object
};

export default AdcDashboardView;
