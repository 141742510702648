import React from "react";
import PropTypes from "prop-types";
import SimpleMap from "../map/components/SimpleMap";
import AdcLocationInfoPopup from "../map/widgets/AdcLocationInfoPopup";

// the SimpleMap won't render any markers unless we pass `selectedLad`. This
// function just pulls out the first lad it finds, although in the future it
// could be made more sophisticated. Since all the locations should be
// distribution centers the lad marker should always be the same anyway.
const getFirstLad = locations => {
  if (locations.length > 0) {
    return locations[0].lad;
  }
  return undefined;
};

const AdcSearchResultsMap = ({ allAdcLocations, onViewLocation }) => {
  return (
    <SimpleMap
      mapLocations={allAdcLocations}
      selectedLad={getFirstLad(allAdcLocations)}
      drawAllGeofences
      useBoxChiclets
      markerIsClickable
      eventHandler={loc => onViewLocation(loc)}
      popupComponent={({ data }) => {
        return (
          <AdcLocationInfoPopup
            location={data}
            onMoreDetailsClick={onViewLocation}
          />
        );
      }}
    />
  );
};

AdcSearchResultsMap.propTypes = {
  allAdcLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
  onViewLocation: PropTypes.func
};

export default AdcSearchResultsMap;
