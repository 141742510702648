import { connect } from "react-redux";

import UsersView from "./UsersView";
import { fetchUsers } from "./UsersState";
import RolesState from "../roles/RolesState";
import {
  fetchOrganizationMembers,
  getActiveOrganization
} from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    isOrganizationMembersLoaded:
      state.organizations.isOrganizationMembersLoaded,
    activeOrganization: getActiveOrganization(state),
    roles: RolesState.selectors.getRoles(state),
    currentOrganizationId: state.organizations
      ? state.organizations.currentOrganizationId
      : "",
    organizationMembers: state.organizations.organizationMembers
      ? state.organizations.organizationMembers.response
      : state.organizations.organizationMembers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    fetchRoles: () => dispatch(RolesState.actionCreators.fetchRoles()),
    fetchOrganizationMembers: organizationId =>
      dispatch(fetchOrganizationMembers(organizationId))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersView);
