/** @jsx jsx */
//eslint-disable-next-line
import React, { Component } from "react";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { withTranslation } from "react-i18next";
import SectionNavigation from "../../components/sections/location/SectionNavigation";

import { ADD_LOCATION_ID } from "../location/LocationsState";
import { Privileges } from "../auth/Authorization";

import { getLadByLabelName, getLadLobLabel } from "../../components/lads";
import { isCarrier } from "../../routes";
import TableMetaTotalRecords from "../../components/tables/MetaData/TableMetaTotalRecords";
import LocationTable from "./components/LocationTable";
import LocationOperations from "./components/LocationOperations";

// Map each table column accessor with a search filter for the API request
const tableFilterMap = {
  name: "name_search",
  address: "full_address_search",
  code: "code_search",
  organization: "customer_id",
  type: "organization_lad_id"
};

class LocationsView extends Component {
  static propTypes = {
    activeOrganization: PropTypes.any,
    authorization: PropTypes.object,
    clearSearchFilter: PropTypes.func.isRequired,
    clearSearchFilters: PropTypes.func.isRequired,
    clearSearchText: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    lads: PropTypes.array,
    locationSearchResults: PropTypes.array,
    organizations: PropTypes.array,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    pushLocationEditScreen: PropTypes.func.isRequired,
    pushLocationMatchingView: PropTypes.func.isRequired,
    searchFilters: PropTypes.object.isRequired,
    searchLocations: PropTypes.func.isRequired,
    searchText: PropTypes.string,
    setPagination: PropTypes.func.isRequired,
    setSearchFilter: PropTypes.func.isRequired,
    setShipmentWithUnresolvedLocation: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    solutionId: PropTypes.string,
    t: PropTypes.func.isRequired,
    totalLocations: PropTypes.number,
    totalPages: PropTypes.number
  };

  state = {
    defaultFiltered: []
  };

  constructor(props) {
    super(props);

    this.resetSearchFilters = this.resetSearchFilters.bind(this);
    this.applyTableFilters = this.applyTableFilters.bind(this);
    this.filterResolvedLocations = this.filterResolvedLocations.bind(this);
  }

  componentDidMount() {
    const {
      locationSearchResults,
      clearSearchText,
      searchLocations,
      solutionId
    } = this.props;

    if (!locationSearchResults.length) {
      this.resetSearchFilters();
      clearSearchText();
      searchLocations(solutionId);
    }

    this.applyTableFilters();
  }

  componentDidUpdate(prevProps) {
    const { searchFilters } = this.props;

    if (searchFilters !== prevProps.searchFilters) {
      this.applyTableFilters();
    }
  }

  /**
   *
   * @return {boolean|boolean}
   */
  canUserManageLocations() {
    const { authorization, activeOrganization } = this.props;

    const isCarrierOrg = isCarrier(activeOrganization);

    return (
      authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS]) &&
      !isCarrierOrg
    );
  }

  resetSearchFilters() {
    const {
      clearSearchFilters,
      setSearchFilter,
      activeOrganization
    } = this.props;

    clearSearchFilters();

    // H1-1701: Apply additional filters to Carrier location search query
    if (isCarrier(activeOrganization)) {
      setSearchFilter("dereference", "t");
      setSearchFilter("dereference_search", "t");
      setSearchFilter("category", "Shipper");
    }
  }

  // Convert API search filters to their mapped table filter values
  applyTableFilters() {
    const { searchFilters, lads, activeOrganization } = this.props;

    const tableFilters = [];

    const isCarrierOrg = isCarrier(activeOrganization);

    Object.keys(tableFilterMap).forEach(tableAccessor => {
      const apiParam = tableFilterMap[tableAccessor];
      let apiValue = searchFilters[apiParam];

      // Convert LAD IDs to label strings for table filter
      if (tableAccessor === "type" && Array.isArray(apiValue)) {
        const ladLabels = apiValue.map(ladId => {
          /* carrier org */
          if (isCarrierOrg) {
            let combinedLads = [];
            combinedLads = Object.values(lads)
              .map(orgLads => orgLads)
              .flat();
            const shipperLad = combinedLads.find(l => l.org_lad_id === ladId);

            const shipperLadLabel = shipperLad
              ? `${shipperLad.org_id}_${getLadLobLabel(shipperLad)}`
              : "";
            return shipperLadLabel.toLowerCase();
          } else {
            /* shipper org */
            const lad = lads.find(l => l.id === ladId);
            const ladLabel = lad ? getLadLobLabel(lad) : "";
            return ladLabel.toLowerCase();
          }
        });

        apiValue = ladLabels;
      }

      if (apiValue) {
        tableFilters.push({
          id: tableAccessor,
          value: apiValue
        });
      }
    });

    this.setState({ defaultFiltered: tableFilters });
  }

  filterResolvedLocations(filtered) {
    const {
      lads,
      clearSearchFilter,
      setSearchFilter,
      searchLocations,
      solutionId
    } = this.props;

    if (!filtered || !filtered.length) {
      this.resetSearchFilters();
    }

    // Apply table filters as API search filters
    filtered.forEach(filter => {
      const tableAccessor = filter.id;
      const apiParam = tableFilterMap[tableAccessor];
      let val = filter.value;

      if (!apiParam) {
        return;
      }

      // Only apply valid LADS for the Location Type filter
      if (tableAccessor === "type" && Array.isArray(val)) {
        const ladIds = val.map(ladName => {
          /* carrier org */
          if (ladName.includes("_")) {
            const [orgId, name] = ladName.split("_");

            const lad = getLadByLabelName(name, lads[orgId]);

            return lad ? lad.org_lad_id : null;
          } else {
            /* shipper org */
            const lad = getLadByLabelName(ladName, lads);

            return lad ? lad.id : null;
          }
        });

        val = ladIds || [];
      }

      setSearchFilter(apiParam, val);
    });

    // Clear API search filters that are not applied as a table filter
    Object.keys(tableFilterMap).forEach(tableAccessor => {
      const apiParam = tableFilterMap[tableAccessor];
      const tableFilter = filtered.find(l => l.id === tableAccessor);

      if (tableFilter) {
        return;
      }

      clearSearchFilter(apiParam);
    });

    searchLocations(solutionId);
  }

  render() {
    const {
      totalLocations,
      pushLocationEditScreen,
      isLoading,
      page,
      pageSize,
      totalPages,
      setPagination,
      setSort,
      solutionId,
      activeOrganization,
      locationSearchResults,
      t
    } = this.props;

    const { defaultFiltered } = this.state;

    const canUserManageLocations = this.canUserManageLocations();
    const isCarrierOrg = isCarrier(activeOrganization);
    return (
      <div className="p-3">
        {canUserManageLocations && <SectionNavigation activeTab="locations" />}
        <LocationOperations
          canAddNewLocation={canUserManageLocations}
          onAddNewLocation={() => {
            pushLocationEditScreen(ADD_LOCATION_ID);
          }}
        />
        <TableMetaTotalRecords
          total={totalLocations}
          label={t("locations:Locations")}
        />
        <LocationTable
          showCarrierColumns={isCarrierOrg}
          loading={isLoading}
          page={page}
          pageSize={pageSize}
          totalPages={totalPages}
          setPagination={setPagination}
          setSort={setSort}
          solutionId={solutionId}
          data={isLoading ? [] : locationSearchResults}
          rowClickHandler={row => {
            let id = row.original.id;

            if (isCarrierOrg && row.original.child_ids) {
              id = row.original.child_ids[0];
            }

            pushLocationEditScreen(id);
          }}
          onFilteredChange={(filtered, column) => {
            this.filterResolvedLocations(filtered, column);
          }}
          defaultFiltered={defaultFiltered}
        />
      </div>
    );
  }
}

export default withTranslation(["locations"])(LocationsView);
