import _ from "lodash";
import { isCarrier, isShipper, isPartner } from "../../../routes";

const shipperOnlyPaths = ["/locations", "/reporting"];
const carrierOnlyPaths = ["/create-shipment"];
const partnerAllowedPaths = [
  "/partners",
  "/partners/create-milestone-event",
  "/usermanagement"
];
const patOnlyPath = "/plant-asset";

// Check shipper/carrier routes
// TODO: Move to Authorization() or create a new class for these checks
function isRouteForbidden(authorization, activeOrganization, route) {
  if (!isShipper(activeOrganization) && shipperOnlyPaths.includes(route.path)) {
    return true;
  }
  if (!isCarrier(activeOrganization) && carrierOnlyPaths.includes(route.path)) {
    return true;
  }
  if (
    isPartner(activeOrganization) &&
    !partnerAllowedPaths.includes(route.path)
  ) {
    return true;
  }
  if (
    authorization.featureAuthorization.plantAssetTrackingOnly() &&
    !route.path.includes(patOnlyPath)
  ) {
    return true;
  }
  return false;
}

// Build a list of links and auth data from a navGroup's child routes
export function groupData(authorization, activeOrganization, navGroup) {
  const groupLinks = [];
  const groupPrivileges = [];
  const groupFeatures = [];
  const groupOrganizationTypes = [];

  _.keys(navGroup.routes).forEach(key => {
    const route = navGroup.routes[key];
    if (route.hidden) {
      return;
    }
    const privileges = route.privileges || [];
    const features = route.features || [];
    const organizationTypes = route.organizationTypes || [];
    const isAuthorized = authorization.isAuthorized(
      privileges,
      features,
      organizationTypes
    );
    const isForbidden = isRouteForbidden(
      authorization,
      activeOrganization,
      route
    );
    if (isAuthorized && !isForbidden) {
      groupLinks.push({ key, route });
    }
    groupPrivileges.push.apply(groupPrivileges, privileges);
    groupFeatures.push.apply(groupFeatures, features);
    groupOrganizationTypes.push.apply(
      groupOrganizationTypes,
      organizationTypes
    );
  });
  return {
    links: groupLinks,
    privileges: [...new Set(groupPrivileges)],
    features: [...new Set(groupFeatures)]
  };
}
