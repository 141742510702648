/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import PropTypes from "prop-types";
import { withTranslation, useTranslation } from "react-i18next";
import produce from "immer";

import PageHeader from "./documentation-styled-components/PageHeader";
import ApiSelect from "./documentation-styled-components/ApiSelect";
import ApiRequest from "./documentation-styled-components/ApiRequest";
import ApiResponse from "./documentation-styled-components/ApiResponse";
import FormRow from "../../components/forms/FormRow";

import SectionHeader from "./documentation-styled-components/SectionHeader";
import StandardInput from "../../components/forms/inputs/StandardInput";

/**
 * Accepts a username and password from the user
 */
const AuthorizationCredentials = ({
  username,
  password,
  onUsernameChange,
  onPasswordChange
}) => {
  const { t } = useTranslation(["documentation"]);
  return (
    <React.Fragment>
      <div css={{ padding: "1em 1em 0 1em" }}>
        <SectionHeader title={t("Authorization Credentials")} />
      </div>
      <FormRow
        style={{
          borderBottom: "1px solid #ddd",
          padding: "0 0.5em 0.5em 0.5em"
        }}
      >
        <StandardInput
          label={t("User Name")}
          value={username}
          onChange={onUsernameChange}
        />
        <StandardInput
          type="password"
          label={t("Password")}
          value={password}
          onChange={onPasswordChange}
        />
      </FormRow>
    </React.Fragment>
  );
};

AuthorizationCredentials.defaultProps = {
  username: "",
  password: ""
};

AuthorizationCredentials.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  onUsernameChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired
};

const tryApiCss = {
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  paddingLeft: "0.5em",
  paddingTop: "1.25em",
  paddingRight: "0.5em",
  paddingBottom: "1.25em"
};

class TryApiView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      apiNameOptions: [],
      apiTypeOptions: [],
      requestSampleOptions: [],
      data: {
        headers: {},
        parameters: {},
        body: {}
      },
      selectedApiName: null,
      selectedApiType: null,
      selectedRequestSample: null,
      username: "",
      password: ""
    };

    this.apiSelectHandler = this.apiSelectHandler.bind(this);
    this.requestUpdateHandler = this.requestUpdateHandler.bind(this);
    this.tryApiHandler = this.tryApiHandler.bind(this);
  }

  componentDidMount() {
    this.props.fetchApiGroup("carrierApiSamples.json");
  }

  componentDidUpdate(prevProps) {
    const { apiSample, apiGroup } = this.props;

    // Groups will populate the API Type field
    if (apiGroup !== prevProps.apiGroup) {
      this.constructSelectors(apiGroup);
    }

    if (apiSample !== prevProps.apiSample) {
      this.constructTryApi(apiSample);
    }
  }

  /** Set up request related information and available sample options
   * @param {object} sample - An object containing the API reference
   */
  constructTryApi(sample) {
    const { t } = this.props;

    if (!sample || !sample.Api) {
      return;
    }

    const { Documentation } = sample.Api[0];

    this.setState(
      produce(draft => {
        // Clear previous header fields
        // This removes text in the existing header fields
        draft.data.headers = {};

        // Set up the headers for the request
        Documentation["Header Parameters"].forEach(prop => {
          // Ignore Authorization Header parameter
          // Will be generated from username and password
          if (prop.name === "Authorization") {
            return;
          }

          draft.data.headers[prop.name] = {
            name: prop.name,
            value: "",
            required: prop.required,
            ...prop.schema
          };
        });

        // Set up the parameters for the request
        Documentation["Parameters"].forEach(prop => {
          draft.data.parameters[prop.name] = {
            name: prop.name,
            value: "",
            required: prop.required,
            ...prop.schema
          };
        });

        const requestSampleKey = "Request Sample";
        // If there isn't a request sample, we don't need to do anything
        if (Documentation[requestSampleKey]) {
          // Set the request sample options environment options
          draft.requestSampleOptions = Object.getOwnPropertyNames(
            Documentation[requestSampleKey]
          ).map((key, index) => {
            const value = Documentation[requestSampleKey][key];
            return {
              value: index,
              label: t(`documentation-remote:${value.summary}`),
              sample: value.value
            };
          });
        }
      })
    );
  }

  constructSelectors(group) {
    const { t } = this.props;

    if (!group || !group.groups) {
      return;
    }

    // Get the available API Types
    let options = group.groups.map((g, i) => {
      return {
        value: i,
        label: t(`documentation-remote:${g.name}`),
        samples: g.samples.map((s, j) => {
          return {
            value: j,
            label: t(`documentation-remote:${s.name}`),
            file: s.file
          };
        })
      };
    });

    // Update state with the available Api Types and Names
    // Preselect index 1 of Api Types
    this.setState(
      produce(draft => {
        draft.apiTypeOptions = options;
        draft.selectedApiType = 1;
        draft.apiNameOptions = options[1].samples;
      })
    );
  }

  /** Respond to one of the drop downs' change event
   * @param {string} type - The event type; Some string constant that refers to a drop down
   * @param {number} val - The selected index for the drop down
   */
  apiSelectHandler(type, val) {
    const { apiTypeOptions, apiNameOptions, requestSampleOptions } = this.state;

    if (type === "API_NAME") {
      // Fetch the request samples for the selected API
      this.props.fetchApiSample(apiNameOptions[val].file);
    }

    // Clear the Request Body if we are changing API Type or Name
    if (type === "API_TYPE" || type === "API_NAME") {
      this.setState(
        produce(draft => {
          draft.data.body = {};
        })
      );
    }

    this.setState(
      produce(draft => {
        switch (type) {
          // If the API Type selection is being changed
          case "API_TYPE":
            // Set selected index of API Type
            draft.selectedApiType = val;
            // Clear the API Name and Request Sample field
            draft.selectedApiName = null;
            draft.selectedRequestSample = null;
            // Update the Api Name drop down options
            draft.apiNameOptions = apiTypeOptions[val].samples;
            break;
          // If the API Name selection is being changed
          case "API_NAME":
            // Set the selected index of API Name
            draft.selectedApiName = val;
            // Clear the selected Request Sample and options
            draft.selectedRequestSample = null;
            draft.requestSampleOptions = [];
            break;
          case "REQUEST_SAMPLE":
            // Set the selected index of Request Sample
            draft.selectedRequestSample = val;
            // Set the code editor to the selected sample
            draft.data.body = requestSampleOptions[val].sample;
            break;
          default:
            break;
        }
      })
    );
  }

  requestUpdateHandler(section, name, val) {
    this.setState(
      produce(draft => {
        if (section === "body") {
          draft.data.body = val;
        } else {
          draft.data[section][name].value = val;
        }
      })
    );
  }

  tryApiHandler() {
    const { apiSample, callTryApi } = this.props;
    const { data, username, password } = this.state;

    const { Method, Path, Server } = apiSample.Api[0];

    // Find the test URL from the Servers array
    // This isn't great because the description text and urls could change
    // But the description for this is treated like an ID in the backend.
    // We can revist this implementaiton later
    const testServer = Server.find(
      value => value.description === "Test Environment"
    );
    if (testServer) {
      callTryApi(data, Method, Path, testServer.url, { username, password });
    }
  }

  onUsernameChange = value => {
    this.setState({ username: value });
  };

  onPasswordChange = value => {
    this.setState({ password: value });
  };

  render() {
    const { t, content, isLoading, tryResponse } = this.props;
    const {
      apiNameOptions,
      apiTypeOptions,
      requestSampleOptions,
      data,
      selectedApiName,
      selectedApiType,
      selectedRequestSample
    } = this.state;

    const title = content.title || "";
    const description = content.Description || "";

    return (
      <div css={{ padding: "1em" }}>
        <PageHeader
          title={t(`documentation-remote:${title}`)}
          description={t(`documentation-remote:${description}`)}
        />
        <div style={tryApiCss}>
          <ApiSelect
            apiNameOptions={apiNameOptions}
            apiTypeOptions={apiTypeOptions}
            requestSampleOptions={requestSampleOptions}
            selectedApiName={selectedApiName}
            selectedApiType={selectedApiType}
            selectedRequestSample={selectedRequestSample}
            eventHandler={this.apiSelectHandler}
          />
          <AuthorizationCredentials
            username={this.state.username}
            password={this.state.password}
            onUsernameChange={this.onUsernameChange}
            onPasswordChange={this.onPasswordChange}
          />
          <ApiRequest
            data={data}
            eventHandler={this.requestUpdateHandler}
            submitHandler={this.tryApiHandler}
            isLoading={isLoading}
          />
        </div>
        <ApiResponse tryResponse={tryResponse} />
      </div>
    );
  }
}

TryApiView.propTypes = {
  fetchApiGroup: PropTypes.func.isRequired,
  fetchApiSample: PropTypes.func.isRequired,
  apiSample: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  apiGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  callTryApi: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  tryResponse: PropTypes.object,

  /** Internal prop from withTranslation */
  t: PropTypes.func.isRequired
};

export default withTranslation(["documentation", "documentation-remote"])(
  TryApiView
);
