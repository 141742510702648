import { css } from "@emotion/core";
import Colors from "../../styles/colors";

export const flexCol = css`
  display: flex;
  flex-direction: column;
`;

export const flexRow = css`
  display: flex;
  flex-direction: row;
`;

export const whiteBg = css`
  background-color: white;
`;

export const centeredRow = css`
  justify-content: space-between;
  align-items: center;
`;

export const overflowWrapper = css`
  position: relative;
  overflow: auto;
`;

export const exportLink = css({
  fontSize: 12.5,
  fontStyle: "italic",
  marginRight: 10,
  textDecoration: "underline",
  color: Colors.text.LIGHTER_GRAY,
  alignItems: "center",
  display: "flex",
  cursor: "pointer"
});

export const zeroMargin = css`
  margin: 0;
`;
