/** @jsx jsx */
import { jsx } from "@emotion/core";

const CellRenderer = props => {
  return (
    <div
      css={{
        textOverflow: "auto",
        overflow: "none",
        backgroundColor: "transparent !important"
      }}
    >
      {props.value}
    </div>
  );
};

export const getColumns = t => {
  const columnDefaultStyle = (state, rowInfo) => ({
    style: {
      paddingLeft: "0.6em",
      whiteSpace: "unset"
    }
  });
  return [
    {
      Header: t("connected-car:VIN"),
      accessor: "vin",
      width: 200,
      Cell: CellRenderer,
      getProps: columnDefaultStyle
    },
    {
      Header: t("connected-car:Make"),
      accessor: "make",
      Cell: CellRenderer,
      getProps: columnDefaultStyle
    },
    {
      Header: t("connected-car:Model"),
      accessor: "model",
      Cell: CellRenderer,
      getProps: columnDefaultStyle
    },
    {
      Header: t("connected-car:Year"),
      accessor: "year",
      Cell: CellRenderer,
      getProps: columnDefaultStyle
    },
    {
      Header: t("connected-car:Location"),
      accessor: "location",
      Cell: CellRenderer,
      getProps: columnDefaultStyle
    },
    {
      Header: t("connected-car:Timestamp"),
      accessor: "timestamp",
      Cell: CellRenderer,
      getProps: columnDefaultStyle
    }
  ];
};
