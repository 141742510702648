// Perform authorization checks based on user's active organization type
// Example: Partners module
export default class OrganizationTypeAuthorization {
  constructor(activeOrganization) {
    this.organizationType = activeOrganization
      ? activeOrganization.org_type
      : null;
  }

  hasAnyOrganizationType(organizationTypes) {
    return organizationTypes.some(t => t === this.organizationType);
  }
}
