/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component, Fragment } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";

import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";

import { getDistance } from "../map/utils/geo";
import { getStopLatLong } from "../map/utils/RoutingMapUtils";

import { isShipper } from "../../routes";
import { Privileges } from "../auth/Authorization";
import { getAuthorization } from "../auth/AuthorizationSelectors";
import Colors, { shadeColor } from "../../styles/colors";
import {
  LadChicletCSS,
  MadChicletCSS,
  colorForLad
} from "../../components/chiclets";
import { getDefaultUnclassifiedLad } from "../lads/LadsState";

import ShipmentDetailTabs from "./ShipmentDetailTabs";
import { detailPanelColorForExceptionType } from "../exceptions/ExceptionLink";
import {
  getAssetID,
  getCurrentModeName,
  getReferenceValue
} from "../shipment-detail/ShipmentUtils";
import TimeForceCarousel from "./TimeForceCarousel";
import LastUpdateDisplay from "./shipment-detail-styled-components/LastUpdateDisplay";

import { getLocationDataFromShipment } from "./shipment-detail-styled-components/OriginDestinationBox";
import ShipmentDetailsCollapsible from "./shipment-detail-styled-components/ShipmentDetailsCollapsible";
import ArrivalDepartureCollapsible from "./shipment-detail-styled-components/ArrivalDepartureCollapsible";
import { ActionNav } from "./ActionNav";

const Circle = ({ bgColor, borderColor }) => {
  const circleStyle = {
    padding: 10,
    display: "inline-block",
    backgroundColor: bgColor,
    border: "2px solid " + borderColor,
    borderRadius: "50%",
    width: 30,
    height: 30
  };
  return <div style={circleStyle} />;
};

Circle.propTypes = {
  bgColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired
};

function computeStopSpacing(stops) {
  let totalDistance = 0.0;
  let stopDistances = [];
  let lastPos = null;

  // Start by finding the distance between
  // each of the stops.  The JSON back from the
  // API does have a distance field, but it
  // looks to be invalid, so recompute it
  // here based on math distance, so not perfect
  // but close enough for our UI display
  stops.forEach(s => {
    let pos = getStopLatLong(s);

    let d = 0.0;
    if (lastPos !== null) {
      d = getDistance(pos, lastPos);
    }

    lastPos = pos;
    totalDistance += d;
    stopDistances.push(totalDistance);
  });

  // The default stop spacing is just a percentage
  // of the way along the route for that shipment
  let stopSpacing = [];
  stopDistances.forEach(d => {
    stopSpacing.push((d / totalDistance) * 100.0);
  });

  // This function is used to adjust inner
  // spacing of the stops.  If the percentages
  // are too close, the displayed LADs will overlap
  // so for UI purposes, try to adjust the spacing
  // This will slide things down to left in an
  // accordion fashion.
  function adjustStopSpacing() {
    const minSpacing = 11.0;
    let adjustedSpacing = false;

    for (let i = stopSpacing.length - 2; i > 1; i--) {
      const diff = stopSpacing[i] - stopSpacing[i - 1];

      // If at least the min spacing requirement
      // skip this one
      if (diff >= minSpacing) {
        continue;
      }

      // If the diff is near zero it is likely the same
      // location, let it draw exactly on itself
      if (diff < 0.5) {
        continue;
      }

      adjustedSpacing = true;

      // Let's see if we should move this shipment to the left
      // or to the right
      const leftSpace = stopSpacing[i - 1] - stopSpacing[i - 2];
      const rightSpace = stopSpacing[i + 1] - stopSpacing[i];

      if (rightSpace > leftSpace && rightSpace > minSpacing) {
        stopSpacing[i] = stopSpacing[i - 1] + minSpacing;
      } else {
        // At this point, slide the i-1 stop over
        // a bit, but don't pass the i-2 stop
        stopSpacing[i - 1] = _.max([
          stopSpacing[i - 2] + 1.0,
          stopSpacing[i] - minSpacing
        ]);
      }
    }
    return !adjustedSpacing;
  }

  // If we have at least three stops, check if
  // any of them need to be adjusted
  if (stopSpacing.length > 2) {
    let validSpacing = false;
    let tryCount = 0;
    while (!validSpacing && tryCount < 5) {
      validSpacing = adjustStopSpacing();
      tryCount += 1;
    }
  }

  // Looks like a rounding error for placement
  // of the last tick mark for the final destination
  // So bring it in a smidge
  if (stopSpacing.length > 1) {
    stopSpacing[stopSpacing.length - 1] = 99.5;
  }

  return stopSpacing;
}

//event bar helper. return an array of spacing percentages between events,
//but make sure to round the last event to %100
const eventBarSpacing = numEvents => {
  return Array(numEvents)
    .fill()
    .map((_, i) => {
      const space = 100.0 / (numEvents - 1);
      return i < numEvents - 1 ? i * space : 100;
    });
};

const EventBarScroller = ({
  t,
  leftScrollHandler,
  rightScrollHandler,
  numStops,
  index,
  scrollSize
}) => {
  const totalPartitions = Math.ceil(numStops / scrollSize);
  const currentPartition = Math.ceil((index + 1) / scrollSize);
  const rightScrollEnabled = index + scrollSize < numStops;
  const leftScrollEnabled = index - scrollSize >= 0;
  return (
    <div
      css={{
        width: "98%",
        textAlign: "center"
      }}
    >
      {totalPartitions > 1 && (
        <button
          onClick={() => {
            leftScrollHandler();
          }}
          style={{ float: "left" }}
          disabled={!leftScrollEnabled}
        >
          {t("shipment-details:Previous")}
        </button>
      )}
      {totalPartitions > 1 && (
        <button
          onClick={() => {
            rightScrollHandler();
          }}
          style={{ float: "right" }}
          disabled={!rightScrollEnabled}
        >
          {t("shipment-details:Next")}
        </button>
      )}
      {`${currentPartition} ${t("shipment-details:of")} ${totalPartitions}`}
    </div>
  );
};

// a bar that displays carrier events as updates occur in a sequential order
// spaces between events should be equal so the bar should dynamically recreate recreate
// itself as shipment updates come in

export class EventBar extends Component {
  constructor(props) {
    super(props);
    this.state = { startIndex: 0 };
    this.rightScroll = this.rightScroll.bind(this);
    this.leftScroll = this.leftScroll.bind(this);
  }

  rightScroll() {
    this.setState({
      startIndex: this.state.startIndex + this.props.scrollSize
    });
  }

  leftScroll() {
    this.setState({
      startIndex: this.state.startIndex - this.props.scrollSize
    });
  }

  render() {
    const { t, shipment, scrollSize, multimodal } = this.props;
    if (shipment.shipment_stops == null) {
      return null;
    }
    const { shipment_stops } = shipment;
    // overkill but a holdover from when we were using lads

    const eventsData = shipment_stops.map((s, i) => {
      let event_str = "";
      let arrived = false;
      if (i === 0) {
        event_str = multimodal
          ? t("shipment-details:Picked up from")
          : t("shipment-details:Departed Origin");
        // H1-253: Origin should fill-in only after the truck has departed.
        // This does that without changing the has_arrived name, but we set
        // a different value calculated here (for the Origin).
        arrived = s.departed_at !== null;
      } else if (i === shipment_stops.length - 1) {
        event_str = multimodal
          ? t("shipment-details:Delivered to")
          : t("shipment-details:Delivered");
        // For the Destination..
        arrived = s.arrived_at !== null;
      } else {
        event_str = multimodal
          ? t("shipment-details:Arrived at")
          : t("shipment-details:Arrived at Terminal");
        // ..and all other stops, we still fill-in based on arrival.
        arrived = s.arrived_at !== null;
      }
      return {
        address: s.location.name,
        eventname: event_str,
        has_arrived: arrived
      };
    });

    //restrict events data to only show {scrollSize} stops at a time
    const eventsDataSlice = eventsData.slice(
      this.state.startIndex,
      this.state.startIndex + scrollSize
    );

    const eventSpacing = eventBarSpacing(eventsDataSlice.length);
    const hasDelivered =
      eventsDataSlice.length > 0
        ? eventsDataSlice[eventsDataSlice.length - 1].has_arrived
        : false;
    // find the last index that has_arrived == true, currently only used on multimodal to avoid crashing on non multimodal pages that use this LTL bar
    const lastArrived = _.findLastIndex(eventsDataSlice, ["has_arrived", true]);
    const events = eventsDataSlice.map((e, i) => {
      const borderColor = e.has_arrived
        ? Colors.highlight.LIGHT_BLUE
        : Colors.background.GRAY;

      return (
        <div
          key={i}
          css={{
            position: "absolute",
            left: `${eventSpacing[i]}%`,
            //H1-1483: Hack to get text to wrap on all but the last event.
            width:
              (i + 1) % eventsDataSlice.length !== 0
                ? `${100 / eventsDataSlice.length}%`
                : null,
            ":hover": {},
            top: 0
          }}
        >
          <Circle bgColor="white" borderColor={borderColor} />
          <div>
            {e.eventname}
            <br />
            {e.address}
          </div>
        </div>
      );
    });
    let progress = !hasDelivered ? eventSpacing[eventSpacing.length - 2] : 100;
    if (multimodal) {
      progress = eventSpacing[lastArrived];
    }

    return (
      <div
        css={{
          position: "relative",
          display: "flex",
          margin: "1em",
          marginRight: "2em",
          marginBottom: "6em",
          justifyContent: "space-between",
          height: 90
        }}
      >
        <EventBarScroller
          t={t}
          leftScrollHandler={this.leftScroll}
          rightScrollHandler={this.rightScroll}
          numStops={shipment_stops.length}
          index={this.state.startIndex}
          scrollSize={scrollSize}
        />
        <div
          css={{
            zIndex: 1,
            display: "flex",
            flex: 1,
            justifyContent: "space-between"
          }}
        >
          <div
            css={{
              position: "absolute",
              width: "calc(100% - 38px)",
              left: 0,
              display: "flex",
              alignItems: "flex-start"
            }}
          >
            <div
              css={{
                position: "absolute",
                height: 3,
                backgroundColor: shadeColor(Colors.highlight.LIGHT_BLUE, 0.5),
                left: 19,
                top: 47,
                right: `${100 - progress}%`,
                zIndex: 0
              }}
            />
            <div
              css={{
                position: "absolute",
                top: 30,
                width: "100%"
              }}
            >
              {events}
            </div>
          </div>
        </div>
        <div
          css={{
            position: "absolute",
            height: 4,
            backgroundColor: Colors.background.GRAY,
            marginTop: 46,
            left: 19,
            right: 19,
            zIndex: 0
          }}
        />
      </div>
    );
  }
}

export const ShipmentTimeline = props => {
  const {
    shipment,
    eventHandler,
    organization,
    shipmentModes,
    equalSpacing,
    width,
    hidePercentage
  } = props;
  const authorization = useSelector(getAuthorization);

  if (shipment.shipment_stops == null) {
    return null;
  }

  let stopSpacing;
  let spacingInterval = 0;

  // H1-609/H1-842: Added the equal spacing option
  if (equalSpacing) {
    spacingInterval = Math.round(width / (shipment.shipment_stops.length - 1));
    stopSpacing = shipment.shipment_stops.map((s, i) =>
      Math.round((100 * i * spacingInterval) / width)
    );
  } else {
    // there is a stop distance field in the backend but doesn't look valid
    stopSpacing = computeStopSpacing(shipment.shipment_stops);
  }

  // In order for LAD to clickable, we must be
  // a shipper org and the LAD must be unclassified
  const ladPermissions =
    isShipper(organization) &&
    authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS]);

  const stops = shipment.shipment_stops.map((s, i) => {
    const lad =
      s.location && s.location.lad
        ? s.location.lad
        : getDefaultUnclassifiedLad();
    const clickable = ladPermissions && lad.default_name === "Unclassified";
    return (
      <div key={i}>
        <div
          css={{
            position: "absolute",
            left: `${stopSpacing[i]}%`,
            ":hover": clickable ? { cursor: "pointer" } : {}
          }}
          onClick={() => {
            if (clickable) {
              eventHandler(s.location, "TIMELINE_LAD_CLICK");
            }
          }}
        >
          <LadChicletCSS lad={lad} width={40} height={40} showLadLabel />
        </div>
        <div
          css={{
            position: "absolute",
            width: 2,
            height: 33,
            backgroundColor: colorForLad(lad),
            left: `${stopSpacing[i]}%`,
            bottom: -72,
            marginLeft: 19,
            zIndex: 0
          }}
        />
      </div>
    );
  }); // map

  // Falling back to using the distance progress field
  // have found this to be more accurate
  const progStr = shipment.current_location
    ? shipment.current_location.distance_progress.replace("%", "")
    : "0";

  let progress;
  if (equalSpacing) {
    const numFullLegs = shipment.current_location.active_leg_idx - 1;
    const pixelsProgress =
      (numFullLegs + Number(progStr) / 100) * spacingInterval;
    progress = (pixelsProgress / width) * 100;
  } else {
    progress = Number(progStr);
  }
  progress = _.max([0.0, progress]);

  const currentModeName = getCurrentModeName(shipment, shipmentModes);

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        margin: "1em",
        marginBottom: "5em",
        justifyContent: "space-between",
        height: 35
      }}
    >
      <div
        css={{
          zIndex: 1,
          display: "flex",
          flex: 1,
          justifyContent: "space-between"
        }}
      >
        <div
          css={{ position: "absolute", width: "calc(100% - 38px)", left: 0 }}
        >
          <div
            css={{
              position: "absolute",
              height: 25,
              backgroundColor: shadeColor(
                shipment.active_exceptions_ng
                  ? detailPanelColorForExceptionType(
                      shipment.active_exceptions_ng
                    )
                  : Colors.highlight.GREEN,
                0.6
              ),
              left: 19,
              top: 47,
              right: `${100 - progress}%`,
              zIndex: 0
            }}
          />

          <div
            css={{
              position: "absolute",
              left: `${progress}%`,
              top: 42.5,
              zIndex: 1
            }}
          >
            <MadChicletCSS
              shipmentMode={shipment.mode_name}
              stopMode={currentModeName}
              activeException={shipment.active_exceptions_ng}
              width={35}
              height={35}
            />
            {hidePercentage ? null : (
              <div
                css={{
                  color: Colors.background.DARK_BLUE,
                  textAlign: "center"
                }}
              >
                {`${progress.toFixed(0)}%`}
              </div>
            )}
          </div>
          {stops}
        </div>
      </div>
      <div
        css={{
          position: "absolute",
          height: 27,
          backgroundColor: Colors.background.GRAY,
          marginTop: 46,
          left: 19,
          right: 19,
          zIndex: 0,
          border: "1px solid #999"
        }}
      />
    </div>
  );
};

const tabStyle = {
  display: "flex",
  flex: 1,
  justifyContent: "space-around",
  alignItems: "center",
  padding: "0.5em",
  color: Colors.background.GRAY_BLUE,
  marginRight: "0.5em"
};

const ShipmentDetailPanel = ({
  shipment,
  ladsList,
  selectCoordinate,
  clearCoordinate,
  selectedCoordinate,
  eventHandler,
  enableReviewStatus,
  enableShipmentEvents,
  enableCancelShipment,
  organization,
  enableActionMenu,
  pushShipmentDetailView,
  shipmentModes,
  finishedVehicleView,
  vin,
  isLoaded,
  showLinkedShipments = false
}) => {
  const { t } = useTranslation("shipment-details");

  const mode = shipment.mode_name || "";

  let tabElem;
  let tabPanel = [];
  let activeLeg;

  // Note on the Tabs for this component:
  // - `linked_shipments` is used for certain edge cases where shipments are related to each other
  // - e.g. A shipments that crosses borders will be associated together in this key
  if (showLinkedShipments && shipment.linked_shipments) {
    tabElem = shipment.linked_shipments.map((l, i) => {
      /* Adding a blank panel to get rid of a JS warning, Num of TabPanels needs to equal Tabs */
      tabPanel.push(<TabPanel key={i} />);
      return (
        <Tab key={i} style={tabStyle}>
          {`${t("shipment-details:Leg")} ${i + 1}`}
        </Tab>
      );
    });

    activeLeg = shipment.linked_shipments.findIndex(l => l.id === shipment.id);
  }

  /* H1-337 WD High Value Asset Tracking */
  const is_HVA = shipment.has_event_refs;
  // eslint-disable-next-line
  let progressElem;

  if (is_HVA) {
    progressElem = (
      <Fragment>
        <TimeForceCarousel shipment={shipment} />
      </Fragment>
    );
  } else if (mode === "LTL") {
    progressElem = <EventBar shipment={shipment} scrollSize={4} t={t} />;
  } else if (mode === "Air") {
    progressElem = <null />;
  } else {
    progressElem = (
      <Fragment>
        <ShipmentTimeline
          shipment={shipment}
          ladsList={ladsList}
          eventHandler={eventHandler}
          organization={organization}
          shipmentModes={shipmentModes}
        />
        <LastUpdateDisplay shipment={shipment} />
      </Fragment>
    );
  }

  const assetID = getAssetID(organization, shipment, shipmentModes);

  if (!isLoaded) {
    return null;
  }

  const { origin, destination } = getLocationDataFromShipment(shipment, null);

  // Get Trailer #
  let trailerNumber = null;
  if (mode === "Rail") {
    trailerNumber = getReferenceValue(shipment, "equipment_number");
  } else {
    trailerNumber = shipment.trailer_number;
  }

  return (
    <div
      css={{
        flexDirection: "column",
        backgroundColor: Colors.background.LIGHT_GRAY,
        height: "100em",
        overflow: "visible"
      }}
    >
      {shipment.linked_shipments && shipment.linked_shipments.length > 1 ? (
        <Tabs
          style={{ marginRight: "1em", marginLeft: "1em", flex: 1 }}
          selectedIndex={activeLeg}
          onSelect={(index, lastIndex, evt) => {
            evt.preventDefault();
            if (finishedVehicleView) {
              pushShipmentDetailView("SHIPMENT_DETAIL_FINISHED_VEHICLE", {
                carrier_scac: shipment.linked_shipments[index].carrier_scac
                  ? shipment.linked_shipments[index].carrier_scac
                  : null,
                creator_shipment_id:
                  shipment.linked_shipments[index].creator_shipment_id,
                vin: vin
              });
            } else {
              pushShipmentDetailView("SHIPMENT_DETAIL", {
                shipment_id: shipment.linked_shipments[index].id
              });
            }
          }}
        >
          <TabList
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 1,
              border: "none"
            }}
          >
            {tabElem}
          </TabList>
          {tabPanel}
        </Tabs>
      ) : null}

      {/* Details Group */}
      <ShipmentDetailsCollapsible
        shipmentId={shipment.creator_shipment_id}
        assetId={
          assetID.indexOf("FVMB") === 0 ? "MOBILE" : assetID /* DEV-926 */
        }
        mode={mode}
        status={`${shipment.active_status_ng} ${shipment.current_status_ng}`}
        shipmentType={shipment.shipment_type_ng}
        shipperName={shipment.shipper_name}
        carrierName={shipment.carrier_name}
        carrierScac={shipment.carrier_scac}
        currentRoad={shipment.current_road_name}
        trailerNumber={trailerNumber}
        exception={shipment.active_exceptions_ng}
        railTrainId={shipment.rail_train_id}
        railLoadedStatus={shipment.rail_loaded_status}
        extraHeaderContent={
          enableActionMenu && (
            <ActionNav
              shipment={shipment}
              eventHandler={eventHandler}
              enableReviewStatus={enableReviewStatus}
              enableShipmentEvents={enableShipmentEvents}
              enableCancelShipment={enableCancelShipment}
              assetID={assetID}
            />
          )
        }
      />

      {/* Arrivals and Departures Group */}
      <ArrivalDepartureCollapsible
        shipment={shipment}
        destShipment={null}
        origin={origin}
        destination={destination}
      />

      {/* DEV-1545 LTL shipments hide Shipment Timeline */}
      {progressElem}
      <div
        css={{
          ".react-tabs__tab": { borderBottom: "1px solid #aaa" },
          ".react-tabs__tab--selected": {
            border: "1px solid #aaa !important",
            borderBottom: "1px solid white !important"
          },
          minHeight: "40em"
        }}
      >
        <ShipmentDetailTabs
          shipment={shipment}
          selectCoordinate={selectCoordinate}
          clearCoordinate={clearCoordinate}
          selectedCoordinate={selectedCoordinate}
          organization={organization}
        />
      </div>
    </div>
  );
};

export default ShipmentDetailPanel;
