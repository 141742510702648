/** @jsx jsx */
/**
 * Standard configuration of Map Search Results dashboards.
 *
 *  .------------------------------------------.
 *  |  Search                                  |
 *  |------------------------------------------|
 *  |                       < |                |
 *  |          Map            |     Results    |
 *  |                         |                |
 *  |_________________________|________________|
 *
 *
 * TODO: for future, add possibility to change pass props and change:
 *
 *  - Places where sections are shown
 *  - Styles and colors of sections
 */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import _ from "lodash";
import { useState } from "react";
import Loader from "react-loader";

import ContentSection from "./ContentSection";
import MapSection from "./MapSection";
import ResultsSection from "./ResultsSection";
import RecordLimitModal from "./RecordLimitModal";
import MapSectionExpandCollapseButton from "./MapSectionExpandCollapseButton";
import { useIsMediumAndDown } from "../responsive";

const RESULTS_SECTION_COLLAPSED_WIDTH = 50;

const MapSearchResults = ({
  search,
  map,
  results,
  isShowingOverlay = false,
  isShowingRecordLimitModal = false,
  isCollapsable = true,
  isExporting = false,
  onHideRecordLimitModal = _.noop
}) => {
  const isMobile = useIsMediumAndDown();
  const [isMapSectionExpanded, setIsMapSectionExpanded] = useState(false);

  return (
    <div
      className="d-flex flex-column h-100"
      data-qa="dashboard-map-search-results"
    >
      {/* Need this div so that whatever search renders is contained in a single flex child */}
      <div>{search}</div>
      <ContentSection isMobile={isMobile} isShowingOverlay={isShowingOverlay}>
        <Loader loaded={!isExporting} />
        <MapSection isExpanded={isMapSectionExpanded}>{map}</MapSection>
        <ResultsSection
          isMobile={isMobile}
          isExpanded={!isMapSectionExpanded}
          collapsedWidth={RESULTS_SECTION_COLLAPSED_WIDTH}
        >
          {isCollapsable ? (
            <MapSectionExpandCollapseButton
              isMobile={isMobile}
              width={RESULTS_SECTION_COLLAPSED_WIDTH}
              height={RESULTS_SECTION_COLLAPSED_WIDTH}
              onCollapse={() => {
                setIsMapSectionExpanded(false);
              }}
              onExpand={() => {
                setIsMapSectionExpanded(true);
              }}
            />
          ) : null}
          {!isMapSectionExpanded ? results : null}
        </ResultsSection>
        <RecordLimitModal
          show={isShowingRecordLimitModal}
          onHide={onHideRecordLimitModal}
        />
      </ContentSection>
    </div>
  );
};

MapSearchResults.propTypes = {
  search: PropTypes.node.isRequired,
  map: PropTypes.node.isRequired,
  results: PropTypes.node.isRequired,
  isCollapsable: PropTypes.bool,
  isExporting: PropTypes.bool,
  isShowingOverlay: PropTypes.bool,
  isShowingRecordLimitModal: PropTypes.bool,
  onHideRecordLimitModal: PropTypes.func
};

export default MapSearchResults;
