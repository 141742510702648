/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useState } from "react";
import Colors from "../../styles/colors";
import {
  localizedTimeFormatter,
  localizedDateFormatter
} from "../../utils/date-time";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSparkles } from "@fortawesome/pro-solid-svg-icons";
import { FlexDiv } from "../../styles/container-elements";
import {
  LinkButton,
  actionButtonStyle
} from "../../styles/table-action-styles";
import { faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import Loader from "react-loader";

const ActionCell = props => {
  const { deleteActionCallback, t } = props.value;
  const { reportId } = props.original;
  const [isDeleting, setIsDeleting] = useState(false);

  const onCancel = () => {
    setIsDeleting(false);
  };

  return (
    <FlexDiv style={{ position: "relative" }}>
      <Loader loaded={!isDeleting} scale={0.33} color={Colors.text.HIT_GRAY}>
        <LinkButton
          style={{
            ...actionButtonStyle,
            display: "flex",
            alignItems: "center"
          }}
          clickHandler={() => {
            setIsDeleting(true);
            return deleteActionCallback(reportId, onCancel);
          }}
        >
          {t("reports:Delete")}
          <FontAwesomeIcon
            css={{
              height: "1.5em",
              width: "1.5em",
              margin: "4px",
              marginLeft: "0.5em",
              ":hover": { cursor: "pointer", color: "rgba(0,0,0,0.5)" }
            }}
            icon={faTrashAlt}
            alt={t("Delete Report")}
          />
        </LinkButton>
      </Loader>
    </FlexDiv>
  );
};

ActionCell.propTypes = {
  value: PropTypes.object.isRequired,
  original: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

export const getColumns = (
  t,
  showActions = false,
  deleteActionCallback = null
) => {
  return [
    {
      Header: t("reports:Report Name"),
      accessor: "name",
      minWidth: 200,
      style: {
        minWidth: 200,
        display: "flex",
        whiteSpace: "normal"
      },
      Cell: row => (
        <div>
          {row.value}
          {row.original.new === true && (
            <span
              style={{
                border: `1px solid ${Colors.highlight.GREEN}`,
                color: Colors.highlight.GREEN,
                borderRadius: 4,
                padding: "2px 5px",
                marginLeft: 10,
                fontWeight: "bold"
              }}
            >
              <FontAwesomeIcon
                style={{ marginRight: 4 }}
                icon={faSparkles}
                alt={t("reports:New")}
              />
              New!
            </span>
          )}
        </div>
      )
    },
    {
      Header: t("reports:Description"),
      accessor: "description",
      minWidth: 300,
      style: {
        minWidth: 300,
        display: "flex",
        whiteSpace: "normal"
      }
    },
    {
      Header: t("reports:Created By"),
      accessor: "createdBy",
      minWidth: 160,
      style: {
        minWidth: 160,
        display: "flex",
        whiteSpace: "normal"
      }
    },
    {
      Header: t("reports:Last Update"),
      accessor: "lastUpdated",
      minWidth: 100,
      style: {
        minWidth: 100,
        display: "flex",
        whiteSpace: "normal"
      },
      Cell: row => (
        <div>
          {localizedDateFormatter(row.value)}{" "}
          {localizedTimeFormatter(row.value)}
        </div>
      )
    },
    {
      Header: () => (
        <div style={{ textAlign: "center" }}>{t("reports:Actions")}</div>
      ),
      id: "user-actions",
      show: showActions,
      minWidth: 100,
      style: {
        minWidth: 100,
        display: "flex",
        justifyContent: "center"
      },
      //Cell: ActionCell,
      Cell: props => <ActionCell {...props} />,
      accessor: d => {
        return {
          deleteActionCallback: deleteActionCallback,
          t: t
        };
      }
    }
  ];
};
