/** @jsx jsx */
//eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";

import ResultsTable from "../../../components/tables/ResultsTable";

import {
  LocationAddressColumn,
  LocationCarrierColumn,
  LocationCodeColumn,
  LocationCountColumn,
  LocationNameColumn
} from "../../location/utils/table-columns";

const UnresolvedLocationTable = props => {
  const { isLoading, data, rowClickHandler } = props;

  return (
    <div className="mt-3">
      <ResultsTable
        data={data}
        loading={isLoading}
        columns={[
          LocationNameColumn,
          LocationCarrierColumn,
          LocationCodeColumn,
          LocationAddressColumn,
          LocationCountColumn
        ]}
        rowClickHandler={rowClickHandler}
        filterable={true}
        cellCursorPointerEnabled={true}
        fixPaginationToBottom={true}
        rowHeight="auto"
        showPagination={true}
      />
    </div>
  );
};

UnresolvedLocationTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  rowClickHandler: PropTypes.func.isRequired
};

export default UnresolvedLocationTable;
