import { connect } from "react-redux";

import EventsList from "./EventsList";
import {
  fetchEvents,
  extractEventsFromShipments,
  clearEvents
} from "./EventsState";

function mapStateToProps(state) {
  return {
    events: state.events.data,
    currentOrganizationId: state.organizations.currentOrganizationId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearEvents: () => dispatch(clearEvents()),
    fetchEvents: () => dispatch(fetchEvents()),
    extractEventsFromShipments: shipments =>
      dispatch(extractEventsFromShipments(shipments)),
    pushShipmentDetailView: shipmentID =>
      dispatch({
        type: "SHIPMENT_DETAIL",
        payload: { shipment_id: shipmentID }
      })
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventsList);
