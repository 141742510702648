import _ from "lodash";

export const geofenceWithUpdatedCoordinates = (geofence, newCoords) => {
  return {
    ...geofence,
    geometry: {
      ...geofence.geometry,
      coordinates: newCoords
    }
  };
};

export const isLatValid = lat => {
  if (!_.isNumber(lat)) {
    return false;
  }
  if (_.isNaN(lat)) {
    return false;
  }
  if (lat > 90 || lat < -90) {
    return false;
  }
  return true;
};

export const isLngValid = lng => {
  if (!_.isNumber(lng)) {
    return false;
  }
  if (_.isNaN(lng)) {
    return false;
  }
  if (lng > 180 || lng < -180) {
    return false;
  }
  return true;
};

export const isCoordValid = (lat, lng) => {
  return isLatValid(lat) && isLngValid(lng);
};
