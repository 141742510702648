/**@jsx jsx */
import { jsx } from "@emotion/core";
import { useTranslation } from "react-i18next";
import {
  PanelGroup,
  PanelGroupHeader,
  PanelGroupContent
} from "../../../components/panel-group/PanelGroup";
import { FlexColDiv, FlexRowDiv } from "../../../styles/container-elements";
import DetailItem from "./DetailItem";
import { getRailLoadedStatusDisplayValue } from "../ShipmentUtils";
import { textColorForExceptionType } from "../../exceptions/ExceptionLink";
import { MediaQueries } from "../../../components/responsive";
import Colors from "../../../styles/colors";
import { MadChicletCSS } from "../../../components/chiclets";

const shipmentDetailPanelStyles = {
  detailsGroupContentContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    [MediaQueries.mediumAndUp]: {
      flexFlow: "row nowrap"
    }
  },
  detailFieldContainer: {
    [MediaQueries.mediumAndUp]: {
      flex: "2 0"
    }
  },
  separator: {
    height: 0,
    borderTop: "1px solid #aaa",
    marginBottom: "1em"
  },
  exceptionsContainer: {
    [MediaQueries.mediumAndUp]: {
      flex: "1 0",
      marginLeft: "1em",
      alignSelf: "flex-start"
    }
  },
  detailGroup: {
    flexWrap: "wrap"
  },
  detailItem: {
    flex: "1 0 45%",
    marginBottom: "1em"
  },
  exceptionStatus: {
    textAlign: "center",
    background: Colors.background.DARK_GRAY,
    borderRadius: "4px",
    padding: "1em",
    span: { color: "white" }
  }
};

const ShipmentDetailsCollapsible = ({
  extraHeaderContent,
  shipmentId,
  assetId,
  mode,
  showMadChiclet = false,
  status,
  shipmentType,
  shipperName,
  carrierName,
  carrierScac,
  currentRoad,
  trailerNumber,
  exception,
  railTrainId,
  railLoadedStatus
}) => {
  const { t } = useTranslation("shipment-details");
  const railLoadedStatusDisplay = getRailLoadedStatusDisplayValue(
    railLoadedStatus,
    t
  );

  // Exception Text
  /* H1-1999 an exception None was added to multimodal End-to-End active leg with no exception, display as N/A */
  const exceptionText = exception && exception !== "None" ? exception : "N/A";

  /* H1-1999 Change for multimodal End-to-End to include "(Leg n)" in display, which affect the color check.
     added a regex to filter that out for the lookup */
  const exceptionLookup = exceptionText.includes("Leg")
    ? exceptionText.replace(/\s\(Leg\s.*\)/g, "")
    : exceptionText;

  // Exception Color and Styles
  const exceptionColor = exception
    ? textColorForExceptionType(exceptionLookup)
    : Colors.highlight.GREEN;

  const exceptionOutlineStyles = {
    ...shipmentDetailPanelStyles.exceptionStatus,
    background: exceptionColor
  };

  return (
    <PanelGroup>
      <PanelGroupHeader title={t("Details")}>
        {/* Extra header content */}
        {extraHeaderContent && extraHeaderContent}
      </PanelGroupHeader>
      <PanelGroupContent>
        <div css={shipmentDetailPanelStyles.detailsGroupContentContainer}>
          {/* Details */}
          <FlexColDiv css={shipmentDetailPanelStyles.detailFieldContainer}>
            {/* Group 1 */}
            <FlexRowDiv css={shipmentDetailPanelStyles.detailGroup}>
              <DetailItem
                style={shipmentDetailPanelStyles.detailItem}
                label={t("shipment-details:Shipment ID")}
                data-qa="text-shipment-id"
              >
                {shipmentId ? shipmentId : t("N/A")}
              </DetailItem>
              {assetId && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Asset ID")}
                  data-qa="text-asset-id"
                >
                  {assetId}
                </DetailItem>
              )}
              <DetailItem
                style={shipmentDetailPanelStyles.detailItem}
                label={t("shipment-details:Mode")}
                data-qa="text-asset-mode"
              >
                <FlexRowDiv css={{ alignItems: "center" }}>
                  {showMadChiclet && (
                    <MadChicletCSS
                      shipmentMode={mode}
                      stopMode={mode}
                      width={25}
                      height={25}
                      style={{ marginRight: 10, border: 0 }}
                    />
                  )}
                  {mode}
                </FlexRowDiv>
              </DetailItem>
              <DetailItem
                style={shipmentDetailPanelStyles.detailItem}
                label={t("shipment-details:Status")}
                data-qa="text-asset-status"
              >
                {status}
              </DetailItem>
              {shipmentType && shipmentType === "Rack Return" && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Shipment Type")}
                  data-qa="text-shipment-type"
                >
                  {shipmentType}
                </DetailItem>
              )}
            </FlexRowDiv>

            {/* Separator */}
            <div css={shipmentDetailPanelStyles.separator} />

            {/* Group 2 */}
            <FlexRowDiv css={shipmentDetailPanelStyles.detailGroup}>
              {shipperName && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Shipper")}
                  data-qa="text-shipper"
                >
                  {shipperName}
                </DetailItem>
              )}
              {carrierName && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Carrier")}
                  data-qa="text-carrier-name"
                >
                  {carrierName}
                </DetailItem>
              )}
              {carrierScac && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:SCAC")}
                  data-qa="text-carrier-scac"
                >
                  {carrierScac}
                </DetailItem>
              )}
              {currentRoad && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("Current Road")}
                  data-qa="text-current-road"
                >
                  {currentRoad}
                </DetailItem>
              )}
              {trailerNumber && mode !== "Air" && mode !== "Ocean" && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Trailer #")}
                  data-qa="text-trailer-number"
                >
                  {trailerNumber}
                </DetailItem>
              )}
              {/*
                H1-2101: show extra rail info
              */}
              {railTrainId && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Train ID")}
                  data-qa="text-shipment-rail-id"
                >
                  {railTrainId}
                </DetailItem>
              )}
              {railLoadedStatus && (
                <DetailItem
                  style={shipmentDetailPanelStyles.detailItem}
                  label={t("shipment-details:Loaded Status")}
                  data-qa="text-shipment-rail-loaded-status"
                >
                  {railLoadedStatusDisplay}
                </DetailItem>
              )}
            </FlexRowDiv>
          </FlexColDiv>

          {/* Active Exceptions */}
          <FlexColDiv css={shipmentDetailPanelStyles.exceptionsContainer}>
            <DetailItem
              style={exceptionOutlineStyles}
              label={t("shipment-details:Active Exceptions")}
              data-qa="text-active-exceptions"
            >
              {/*
                  note: despite "active exceptions" being plural above, what's in the
                  shipment record appears to be a single bare string. So our display
                  component only handles a single exception.
                */}
              {t(`exceptions:${exceptionText}`)}
            </DetailItem>
          </FlexColDiv>
        </div>
      </PanelGroupContent>
    </PanelGroup>
  );
};

export default ShipmentDetailsCollapsible;
