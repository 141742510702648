// eslint-disable-next-line
import PropTypes from "prop-types";
import React from "react";
import BoldCell from "./BoldCell";

import { localizedTimeFormatter } from "../../../utils/date-time";

const ReceivedTimeCell = ({ coord }) => {
  const timeFormatted = localizedTimeFormatter(coord.db_time);
  return <BoldCell>{timeFormatted}</BoldCell>;
};

ReceivedTimeCell.propTypes = {
  coord: PropTypes.object
};

export default ReceivedTimeCell;
