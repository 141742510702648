import React from "react";
import { advancedExpandTableHOC } from "react-table/lib/hoc/advancedExpandTable";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import Colors from "../styles/colors";
import "./table-styles.css";

const AdvancedExpandReactTable = advancedExpandTableHOC(ReactTable);

export const generateHeaderCell = (title, sortBool, sortDir) => {
  const desc = sortBool && sortDir;
  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      {title}
      {title ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <IoIosArrowUp
            style={{
              marginBottom: "-5px",
              color: !desc ? null : Colors.text.GRAY
            }}
          />
          <IoIosArrowDown style={{ color: desc ? null : Colors.text.GRAY }} />
        </div>
      ) : null}
    </div>
  );
};

class TabTableExpandable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sortKey: "",
      sortDir: ""
    };

    this.sortHandler = this.sortHandler.bind(this);
    this.DEFAULT_PAGE_SIZE = 20;
  }

  sortHandler(sortKey, dir) {
    this.setState({
      sortKey: sortKey,
      sortDir: dir
    });
  }

  render() {
    const {
      data,
      columns,
      sortHandler,
      defaultSorted,
      rowClickHandler,
      isLoading,
      defaultPageSize,
      showPagination,
      subComponent,
      "data-qa": dataQa
    } = this.props;
    const { sortKey, sortDir } = this.state;
    const validDefaultSorting = defaultSorted && defaultSorted.id !== "";

    return (
      <AdvancedExpandReactTable
        className="customtable"
        style={{
          border: "none"
        }}
        onSortedChange={(newSorted, column, shiftKey) => {
          this.sortHandler(newSorted[0].id, newSorted[0].desc);
          sortHandler && sortHandler(newSorted[0].id, newSorted[0].desc);
        }}
        defaultSorted={
          validDefaultSorting
            ? [
                {
                  id: defaultSorted.id,
                  desc: defaultSorted.desc
                }
              ]
            : [{ id: null, desc: null }]
        }
        showPagination={showPagination || false}
        minRows={!data || data.length === 0 ? 5 : 0} // H2-211 : 'No rows' overlaps columns
        defaultPageSize={
          defaultPageSize ||
          (data && data.length > this.DEFAULT_PAGE_SIZE
            ? data.length
            : this.DEFAULT_PAGE_SIZE)
        }
        getTheadProps={() => {
          return {
            style: {
              height: 45,
              boxShadow: "none",
              borderBottom: "1px solid rgba(0,0,0,0.05)"
            },
            "data-qa": dataQa ? `header-tab-table-expandable-${dataQa}` : null
          };
        }}
        getTheadThProps={(state, rowInfo, column, instance) => {
          const sorted = column.id === sortKey;
          return {
            style: {
              display: "flex",
              alignItems: "center",
              paddingLeft: 25,
              color: sorted ? null : Colors.text.GRAY,
              fontWeight: 600,
              textDecoration: sorted ? "none" : "underline",
              fontStyle: sorted ? null : "italic",
              boxShadow: "none",
              outline: 0
            }
          };
        }}
        getTrProps={(state, rowInfo) => {
          return {
            onClick: e => {
              rowClickHandler &&
                rowClickHandler(
                  rowInfo.original.type,
                  rowInfo.original.reason_code,
                  rowInfo
                );
            },
            "data-qa": dataQa ? `rows-tab-table-expandable-${dataQa}` : null
          };
        }}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              color: "#2d3848",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              minHeight: 45,
              padding: "10px 20px",
              flexGrow: 1
            }
          };
        }}
        data={data}
        columns={columns(sortKey, sortDir)}
        loading={isLoading || false}
        SubComponent={subComponent}
      />
    );
  }
}

TabTableExpandable.propTypes = {
  /** Array of data objects to display in table */
  data: PropTypes.array.isRequired,
  /** Function that generates columns for the table */
  columns: PropTypes.func.isRequired,
  /** An optional function to fire on sort */
  sortHandler: PropTypes.func,
  /** An object that specifies a default sort order */
  defaultSorted: PropTypes.object
};

export default TabTableExpandable;
