import PropTypes from "prop-types";
import React, { Suspense } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import styled from "@emotion/styled";
import Link from "redux-first-router-link";
import { useTranslation } from "react-i18next";
import { MdAccountCircle } from "react-icons/md";

import AppNavView from "./AppNavView";
import Colors from "../../styles/colors";
import {
  ShowForLargeAndUp,
  ShowForMediumAndDown
} from "../../components/responsive";
import { FlexDiv, FlexColDiv } from "../../styles/container-elements";
import LinkButton from "../../vendor/signal-widgets/components/link-button";
import AuthenticationUtils from "../auth/authentication";

const ProfileLinkContainer = styled(FlexDiv)(
  {
    margin: "1em 0px 1em 0.75em"
  },
  props => {
    if (props.sidebarMinimize) {
      return { justifyContent: "center", margin: "1em 0 1em 0" };
    }
  }
);

const ProfileIconContainer = styled(FlexColDiv)(
  {
    justifyContent: "center"
  },
  props => {
    if (props.sidebarMinimize) {
      return { margin: 0 };
    } else {
      return { margin: "0 0.5em 0 0.25em" };
    }
  }
);

const BarFixed = styled(FlexDiv)({
  color: Colors.text.HIT_GRAY,
  backgroundColor: Colors.background.DARK_GRAY
});

const SidebarFooterCol = styled(FlexColDiv)({
  margin: "1em 0 0 .5em",
  justifyContent: "center",
  backgroundColor: Colors.highlight.DARK_GRAY
});

const ProfileName = styled.div({
  color: Colors.text.HIT_GRAY,
  ":hover": { color: "white" },
  ":hover > .nav-rollover": { visibility: "visible" }
});

const SidebarProfileLink = ({ sidebarMinimize }) => {
  const { t } = useTranslation();

  let userName = "";
  if (AuthenticationUtils.isAuthenticated()) {
    userName = AuthenticationUtils.userUsername;
  }

  const isSilentAuthentication = AuthenticationUtils.getIsSilentAuthentication();

  return (
    <ProfileLinkContainer sidebarMinimize={sidebarMinimize}>
      <ProfileIconContainer sidebarMinimize={sidebarMinimize}>
        <Link to={{ type: "PROFILE" }}>
          <span
            style={{
              color: Colors.background.DARK_TEAL
            }}
          >
            {isSilentAuthentication ? null : (
              <MdAccountCircle
                style={{
                  height: "1.75em",
                  width: "1.75em",
                  ":hover": {
                    color: Colors.bannerLinkText
                  }
                }}
              />
            )}
          </span>
        </Link>
      </ProfileIconContainer>
      {!sidebarMinimize && (
        <FlexColDiv css={{ marginLeft: ".5em" }}>
          <FlexDiv>
            <Link to={{ type: "PROFILE" }} style={{ textDecoration: "none" }}>
              <ProfileName>{userName}</ProfileName>
            </Link>
          </FlexDiv>
          <FlexDiv>
            {isSilentAuthentication ? null : (
              <LinkButton
                style={{
                  color: Colors.linkText,
                  fontSize: ".75em",
                  textDecoration: "none",
                  ":hover": {
                    color: Colors.bannerLinkText,
                    cursor: "pointer",
                    textDecoration: "none"
                  }
                }}
                onClick={() => AuthenticationUtils.logout()}
                data-qa="link-logout"
              >
                {t("Log Out")}
              </LinkButton>
            )}
          </FlexDiv>
        </FlexColDiv>
      )}
    </ProfileLinkContainer>
  );
};

SidebarProfileLink.propTypes = {
  sidebarMinimize: PropTypes.bool.isRequired
};

export const SidebarFooter = ({ sidebarMinimize }) => {
  return (
    <SidebarFooterCol className="sidebar-footer-col-div w-100 ml-0">
      <SidebarProfileLink sidebarMinimize={sidebarMinimize} />
    </SidebarFooterCol>
  );
};

SidebarFooter.propTypes = {
  sidebarMinimize: PropTypes.bool.isRequired
};

export const SidebarMinimized = ({ location }) => {
  let currentLocation = location.type || "";
  return (
    <BarFixed className="sidebar">
      <div className="d-flex flex-column">
        <AppNavView currentLocation={currentLocation} sidebarMinimize={true} />
        <div className="d-flex" />
        <BarFixed>
          <Suspense fallback="">
            <SidebarFooter sidebarMinimize={true} />
          </Suspense>
        </BarFixed>
      </div>
    </BarFixed>
  );
};

SidebarMinimized.propTypes = {
  location: PropTypes.object
};

export const SidebarMaximized = ({ location }) => {
  let currentLocation = location.type || "";
  return (
    <BarFixed
      className="sidebar"
      style={{
        overflowY: "auto"
      }}
    >
      <div className="d-flex flex-column w-100">
        <SimpleBar
          autoHide={false}
          style={{ overflow: "auto", height: "100%" }}
        >
          <AppNavView
            currentLocation={currentLocation}
            sidebarMinimize={false}
          />
        </SimpleBar>
        <div className="d-flex" />
        <BarFixed>
          <Suspense fallback="">
            <SidebarFooter sidebarMinimize={false} />
          </Suspense>
        </BarFixed>
      </div>
    </BarFixed>
  );
};

SidebarMaximized.propTypes = {
  location: PropTypes.object
};

export const Sidebar = ({ location }) => {
  return (
    <React.Fragment>
      <ShowForMediumAndDown>
        <SidebarMinimized location={location} />
      </ShowForMediumAndDown>
      <ShowForLargeAndUp>
        <SidebarMaximized location={location} />
      </ShowForLargeAndUp>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  location: PropTypes.object.isRequired
};
