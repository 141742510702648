import React from "react";

export const convertLineBreaksToHtml = text => {
  if (!text || !text.includes("\n")) {
    return text;
  }

  return text.split("\n").map((line, key) => {
    return <div key={key}>{line}</div>;
  });
};
