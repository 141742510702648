import PropTypes from "prop-types";
import React from "react";
import { TextCell } from "./vin-details-columns";
import { withTranslation } from "react-i18next";
import Colors from "../../../styles/colors";

const plusIconCss = {
  backgroundColor: Colors.highlight.GREEN,
  borderRadius: "50%",
  width: "1em",
  height: "1em",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1em"
};

const minusIconCss = {
  backgroundColor: Colors.highlight.RED,
  borderRadius: "50%",
  width: "1em",
  height: "1em",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontSize: "1em"
};

class UpdateSubComponent extends React.Component {
  static propTypes = {
    isExpanded: PropTypes.bool,
    nestingPath: PropTypes.string,
    original: PropTypes.object,
    columnProps: PropTypes.object
  };

  render() {
    const {
      original,
      isExpanded,
      columnProps: {
        rest: { toggleRowSubComponent }
      },
      nestingPath
    } = this.props;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          alignItems: "center"
        }}
      >
        <TextCell>
          {original.statusUpdate && original.statusUpdate.sourceName && (
            <span>{original.statusUpdate.sourceName}</span>
          )}
        </TextCell>
        {original.code === "StatusUpdated" && (
          <div
            style={{ cursor: "pointer", marginLeft: 10, marginRight: 10 }}
            onClick={e =>
              toggleRowSubComponent({ nestingPath: nestingPath }, e)
            }
          >
            {isExpanded ? (
              <span style={minusIconCss}>-</span>
            ) : (
              <span style={plusIconCss}>+</span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation("fv-vin-details")(UpdateSubComponent);
