/** @jsx jsx */
// eslint-disable-next-line
import PropTypes from "prop-types";
import React from "react";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { DataWidgetFrame } from "../../../components/widgets/DataWidgetFrame";
import { DataWidgetTooltip } from "../../../components/widgets/DataWidgetTooltip";
import { useIsMediumAndDown } from "../../../components/responsive";
import { FlexColDiv, FlexDiv } from "../../../styles/container-elements";
import Colors from "../../../styles/colors";
import LinkButton from "../../../vendor/signal-widgets/components/link-button";

const Badge = styled.div(({ color, backgroundColor }) => ({
  backgroundColor: backgroundColor,
  borderRadius: "2em",
  color: color,
  display: "inline-block",
  fontWeight: 600,
  textAlign: "center",
  marginRight: "1em",
  padding: "0.25em 0.75em",
  height: "2em",
  width: "4em",
  minWidth: "4em"
}));

/**
 *
 * @param percentage
 * @return {{backgroundColor: string, color: string}}
 */
function getColors(percentage) {
  let color = "#fff";
  let backgroundColor = "";

  if (percentage > 80) {
    backgroundColor = Colors.highlight.RED;
  } else if (percentage <= 80 && percentage > 60) {
    backgroundColor = Colors.highlight.YELLOW;
    color = Colors.text.DARK_BLUE;
  } else {
    backgroundColor = Colors.highlight.GREEN;
  }

  return {
    color,
    backgroundColor
  };
}

const noDataStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  fontSize: "1.5em",
  cursor: "default",
  color: Colors.background.GRAY_BLUE
};

const AdcCapacity = ({ capacityData, handler, t }) => {
  let capacityElem;
  let halfway;
  const capacityLocations = _.get(capacityData, "data.data");
  const maxLocations = 8;
  const isMobile = useIsMediumAndDown();

  /* H2-767 sort is now handled on the backend, use percentFill instead of calculating on front end */
  if (_.isArray(capacityLocations)) {
    capacityElem = capacityLocations.map((d, i) => {
      let badgeColors = {};
      if (d.percentFill) {
        badgeColors = getColors(d.percentFill);
      }
      return (
        <FlexDiv key={i} style={{ marginBottom: ".5em" }}>
          {d.percentFill ? (
            <Badge {...badgeColors}>{`${d.percentFill}%`}</Badge>
          ) : (
            <Badge color="white" backgroundColor="#CCC">
              N/A
            </Badge>
          )}
          <FlexColDiv style={{ overflow: "hidden" }}>
            <LinkButton
              style={{
                color: Colors.text.DARK_BLUE,
                cursor: "pointer",
                fontSize: "small",
                textAlign: "left",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
              onClick={() => handler(d.locationId)}
            >
              {d.name}
            </LinkButton>
            <div style={{ color: Colors.text.GRAY, fontSize: "small" }}>
              <span style={{ fontWeight: "bold" }}>{d.onSiteCount}</span>
              <span>{` / ${d.capacity}`}</span>
            </div>
          </FlexColDiv>
        </FlexDiv>
      );
    });

    // H2-805: Only show the top 8 ADC locations
    if (capacityElem && capacityElem.length > maxLocations) {
      capacityElem = capacityElem.slice(0, maxLocations);
    }

    halfway = Math.floor(capacityElem.length / 2);
  }

  return (
    <DataWidgetFrame
      header={
        <div>
          {t("adc-dashboard:Auto Distribution Center Capacity")}
          <DataWidgetTooltip
            tooltip={t(
              "adc-dashboard:This chart shows the top 8 locations with the highest capacity utilization. Beneath each location title, there is a ratio of utilization/ total capacity."
            )}
            icon={faInfoCircle}
            style={{ marginLeft: "0.5em" }}
          />
        </div>
      }
      styles={{
        height: isMobile ? "100%" : "26em",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginBottom: isMobile ? "1em" : null,
        minWidth: isMobile ? "null" : "20em",
        position: "relative"
      }}
      isLoading={capacityData && capacityData.isLoading}
    >
      <React.Fragment>
        {capacityLocations && capacityLocations.length > 0 ? (
          <React.Fragment>
            <FlexColDiv
              style={{
                backgroundColor: "#FFF",
                color: Colors.text.DARK_BLUE,
                padding: "1em",
                overflow: "auto",
                maxHeight: "85%"
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "50% 50%",
                  gridGap: 5
                }}
              >
                <FlexColDiv style={{ overflow: "hidden" }}>
                  {capacityElem.slice(0, halfway)}
                </FlexColDiv>
                <FlexColDiv>
                  {capacityElem.slice(halfway, capacityElem.length)}
                </FlexColDiv>
              </div>
            </FlexColDiv>
            <FlexDiv
              style={{
                justifyContent: "center",
                marginTop: "1em"
              }}
            ></FlexDiv>
          </React.Fragment>
        ) : (
          <div style={noDataStyle}>{t("adc-dashboard:No Data Available")}</div>
        )}
      </React.Fragment>
    </DataWidgetFrame>
  );
};

AdcCapacity.propTypes = {
  capacityData: PropTypes.object,
  handler: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation("adc-dashboard")(AdcCapacity);
