/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Colors from "../../../../styles/colors";
import DefinitionListItem from "../../../../components/definition-list/DefinitionListItem";

const getStatus = (t, location, capacity) => {
  if (!capacity) {
    return {
      color: "#ccc",
      text: t("adc-details:N/A")
    };
  }

  const total = (location && location.onSiteCount) || 0;
  const perc = (total / capacity) * 100;

  if (perc > 80) {
    return {
      color: Colors.highlight.RED,
      text: t("adc-details:Over Capacity")
    };
  } else if (perc <= 80 && perc > 60) {
    return {
      color: Colors.highlight.YELLOW,
      text: t("adc-details:At Capacity")
    };
  } else {
    return {
      color: Colors.highlight.GREEN,
      text: t("adc-details:Under Capacity")
    };
  }
};

const StatusListItem = props => {
  const { t } = useTranslation("adc-details");
  const { location, capacity } = props;
  const status = getStatus(t, location, capacity);

  return (
    <DefinitionListItem
      styles={{
        textAlign: "center"
      }}
      title={t("adc-details:Capacity Status")}
    >
      <div
        data-qa="text-status"
        css={{
          backgroundColor: status.color,
          borderRadius: 4,
          color: "white",
          padding: "1em"
        }}
      >
        {status.text}
      </div>
    </DefinitionListItem>
  );
};

StatusListItem.propTypes = {
  location: PropTypes.object.isRequired,
  capacity: PropTypes.string
};

export default StatusListItem;
