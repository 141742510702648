import { connect } from "react-redux";
import FilterSection from "../../components/search-bar/FilterSection";
import ConnectedCarSearchBarState from "./ConnectedCarSearchBarState";
import ConnectedCarDomainDataState from "../domain-data/ConnectedCarDomainData";
import { FILTERS } from "./ConnectedCarFilterSectionCategoryDefs";
import { getSolutionId } from "../organizations/OrganizationsState";

const mapStateToProps = state => {
  const {
    getLifeCycleOptions,
    getMakesOptions,
    getModelsOptions,
    getYearsOptions,
    getStatesOptions,
    getCitiesOptions
  } = ConnectedCarDomainDataState.selectors;

  return {
    makesFilterOptions: getMakesOptions(state),
    modelsFilterOptions: getModelsOptions(state),
    yearsFilterOptions: getYearsOptions(state),
    getLifeCycleOptions: getLifeCycleOptions(state),
    statesFilterOptions: getStatesOptions(state),
    citiesFilterOptions: getCitiesOptions(state),
    solutionId: getSolutionId(state),
    searchFilters: ConnectedCarSearchBarState.selectors.getSearchFilters(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    setSearchFilter,
    clearSearchFilter,
    searchEntities,
    clickedSearchOrEnter
  } = ConnectedCarSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: key => dispatch(clearSearchFilter(key)),
    searchEntities: solutionId => {
      dispatch(clickedSearchOrEnter(false));
      dispatch(searchEntities(solutionId, false));
    }
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FilterSection);
