/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import _ from "lodash";
import PageHeader from "./PageHeader";
import LinkPanels from "./LinkPanels";
import { useTranslation } from "react-i18next";

const ContentJson = ({ content, pushDocumentView }) => {
  const { t } = useTranslation("documentation-remote");

  if (!content) {
    return null;
  }

  const links = _.values(content.Widgets).map((widget, i) => {
    const linkTitle = widget.Title || "";
    const linkDescription = widget.Description || "";

    // If a baseUrl was sent, treat the type as a DOCUMENTATION_URL so we can pass the baseUrl to the ContentView
    let fileType = widget.type;
    if (
      widget.baseUrl &&
      widget.baseUrl.length > 0 &&
      widget.baseUrl.toLowerCase().startsWith("http")
    ) {
      fileType = "DOCUMENTATION_URL";
    }

    return {
      title: t(`documentation-remote:${linkTitle}`),
      description: t(`documentation-remote:${linkDescription}`),
      file: widget.file,
      type: fileType,
      baseUrl: widget.baseUrl,
      eventHandler: pushDocumentView
    };
  });

  const pageTitle = content.title || "";
  const pageDescription = content.Description || "";

  return (
    <div>
      <PageHeader
        title={t(`documentation-remote:${pageTitle}`)}
        description={t(`documentation-remote:${pageDescription}`)}
      />
      <LinkPanels links={links} />
    </div>
  );
};

ContentJson.propTypes = {
  content: PropTypes.object,
  pushDocumentView: PropTypes.func
};
export default ContentJson;
