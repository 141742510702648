/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import Colors from "../styles/colors";
import { MediaQueries } from "./responsive";
import { Tabs, TabList, Tab } from "react-tabs";
import PropTypes from "prop-types";
import _ from "lodash";
import "./tab-styles.css";

export const NumberBubble = styled.div({
  textAlign: "center",
  minWidth: 30,
  borderRadius: 20,
  padding: "2px 8px",
  color: "white",
  fontWeight: 600,
  marginBottom: 5
});

export const tabStyle = {
  display: "flex",
  cursor: "pointer",
  justifyContent: "space-around",
  alignItems: "center",
  color: Colors.background.GRAY_BLUE,
  height: 48,
  borderRadius: "5px 5px 0 0",
  flex: 1
};

export const tabPanelStyle = {
  backgroundColor: "white",
  boxShadow: "0px 15px 12px 0px rgba(0,0,0,0.05)"
  // border: "1px solid #aaa"
};

export const TabsContainerPanel = ({
  tabsData,
  tabsArray,
  numStyles,
  eventUpdates,
  onSelect = _.noop,
  children,
  style = {}
}) => {
  const tabs = tabsArray.map((tab, i) => {
    let tabNumber = tabsData[tab.accessor] ? tabsData[tab.accessor].length : 0;
    const shouldUseEventUpdatesLength =
      tab.accessor === "updates" &&
      tabNumber === 0 &&
      eventUpdates &&
      eventUpdates.length > 0;
    if (shouldUseEventUpdatesLength) {
      tabNumber = eventUpdates.length;
    }
    return (
      <Tab
        key={tab.title}
        style={{
          ...tabStyle,
          marginLeft: i === 0 ? 0 : 10
        }}
        className="details-tab react-tabs__tab"
        selectedClassName="details-tab__selected react-tabs__tab--selected"
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            [MediaQueries.smallAndUp]: {
              fontSize: 12
            },
            [MediaQueries.extraLarge]: {
              fontSize: 14
            }
          }}
        >
          {tab.hideNumber || !tab.accessor || !tabsData ? null : (
            <NumberBubble
              id="num-bubble"
              style={numStyles ? numStyles[tab.accessor] : null}
            >
              {tabNumber}
            </NumberBubble>
          )}
          {tab.title}
        </div>
      </Tab>
    );
  });
  return (
    <div
      css={{
        flexGrow: 1,
        ...style
      }}
    >
      <Tabs onSelect={onSelect}>
        <TabList
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 0,
            border: "none",
            padding: 0
          }}
        >
          {tabs}
        </TabList>
        {children}
      </Tabs>
    </div>
  );
};

TabsContainerPanel.propTypes = {
  /** This object contains the data for the tabs. This object is mainly used to
   * show a number in the number bubble if displayed on a specific tab, however
   * the data from this object can also be used to populate the body of the tab.
   * */
  tabsData: PropTypes.object,
  /** This array contains the names of the tab and the accessor name to access
   * data for the tab. If no accessor is given for a specific tab, no number
   * will be displayed. */
  tabsArray: PropTypes.array.isRequired,
  /** This object contains any custom styling for the number bubble on any of
   * the tabs. The style object is stored as the value with the accessor name
   * (specified in the tabsArray prop) is the key for that value.  */
  numStyles: PropTypes.object,
  /** Optional event handler function called when a tab is selected. Takes three args:
   * (index: number, lastIndex: number, event: Event). See
   * https://github.com/reactjs/react-tabs#onselect-index-number-lastindex-number-event-event--boolean
   * */
  onSelect: PropTypes.func
};
export default TabsContainerPanel;
