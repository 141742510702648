/** @jsx jsx */
import { jsx } from "@emotion/core";
import _ from "lodash";
import { extractLastReportedAt } from "../ShipmentUtils";
import { FlexDiv } from "../../../styles/container-elements";
import Colors from "../../../styles/colors";
import { humanizeTimeString } from "../../../utils/date-time";
import { useTranslation } from "react-i18next";

const LastUpdateDisplay = ({ shipment }) => {
  const { t } = useTranslation("shipment-details");
  // Display requirement same as ShipmentSearchColumns
  const reportedAt = extractLastReportedAt(
    shipment.active_status_ng,
    shipment.current_status_ng,
    shipment.active_exceptions_ng,
    shipment.current_location,
    shipment.destination_actual_arrival,
    shipment.mode_name,
    t
  );

  const duplicateCoordinates = shipment.current_location
    ? checkForDuplicateCoordinates(shipment.current_location.updates)
    : null;

  const timeBlock = duplicateCoordinates ? (
    <div
      css={{
        flexDirection: "column",
        marginTop: "1.25em"
      }}
    >
      <div>{reportedAt.lastTime}</div>
      <div css={{ fontWeight: "bold", color: Colors.text.YELLOW }}>
        {duplicateCoordinates}
      </div>
    </div>
  ) : (
    <div>{reportedAt.lastTime}</div>
  );

  // H1-820: Do not show 'miles out' for Ocean & Air shipments
  const hideRemainingDistanceModes = ["Ocean", "Air"];
  const showRemainingDistance = !hideRemainingDistanceModes.includes(
    shipment.mode_name
  );

  return reportedAt.showDistance ? (
    <FlexDiv
      css={{
        alignItems: "center",
        fontSize: "small",
        flex: 1,
        justifyContent: "space-between",
        marginRight: "3em",
        marginLeft: "1.5em",
        marginBottom: "-1.5em"
      }}
      data-qa="text-last-update"
    >
      <div css={{ fontSize: "1.2em", fontWeight: "bold" }}>{`${t(
        "Last Update"
      )}:`}</div>
      <span data-qa="text-time-last-update">{timeBlock}</span>
      <div data-qa="text-city-last-update">{reportedAt.lastCity}</div>
      {showRemainingDistance && (
        <div data-qa="text-distance-last-update">{`${_.round(
          shipment.remaining_distance,
          0
        )} ${t("miles out")}`}</div>
      )}
    </FlexDiv>
  ) : null;
};

const checkForDuplicateCoordinates = updates => {
  // Sort the time in descending order, so most
  // recent update is at top of list
  const sortedCoords = _.orderBy(updates, ["time"], ["desc"]);

  // See how many of the recent coordinates are duplicates
  let numDuplicates = 0;
  for (let i = 1; i < sortedCoords.length; i++) {
    if (
      sortedCoords[0].longitude === sortedCoords[i].longitude &&
      sortedCoords[0].latitude === sortedCoords[i].latitude
    ) {
      numDuplicates = i;
    } else {
      break;
    }
  }

  if (numDuplicates < 3) {
    return null;
  }

  // Compute the time since the first duplicate.
  const duplicateDuration = humanizeTimeString(
    sortedCoords[numDuplicates].time,
    false
  );

  return `Identical coordinates for ${duplicateDuration}`;
};

export default LastUpdateDisplay;
