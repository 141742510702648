import { connect } from "react-redux";
import AdcSearchResultsView from "./AdcSearchResultsView";
import AdcLocationsState from "../adc-dashboard/AdcLocationsState";
import AdcSearchBarState from "../adc-search/AdcSearchBarState";
import AdcEntitiesState from "../adc-dashboard/AdcEntitiesState";
import { getSolutionId } from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  const { getSearchResults } = AdcSearchBarState.selectors;
  const { getWatchedLocationIDs } = AdcEntitiesState.selectors;

  return {
    adcLocations: getSearchResults(state),
    solutionId: getSolutionId(state),
    watchedLocations: getWatchedLocationIDs(state)
  };
}

const { pushAdcDetailsView } = AdcLocationsState.actionCreators;
const { searchEntities, setPagination } = AdcSearchBarState.actionCreators;
const { fetchWatchedLocations } = AdcEntitiesState.actionCreators;

const actionCreators = {
  pushAdcDetailsView,
  searchEntities,
  setPagination,
  fetchWatchedLocations
};

export default connect(mapStateToProps, actionCreators)(AdcSearchResultsView);
