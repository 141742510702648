import { combineReducers } from "redux";
import apiUrl from "../../api-url";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";

// Redux mount point
const STORE_MOUNT_POINT = "shipmentAI";

// Fetch ducks
const etaDuck = buildFetchDuck("eta");
const percentOnTimeDuck = buildFetchDuck("percentOnTime");

// URLs
const ETA_URL = apiUrl("/shipping-ng/ai?callType=eta");
const PERCENT_ON_TIME_URL = apiUrl("/shipping-ng/ai?callType=percentOnTime");

// Action creators
function fetchETA(mode, originId, destinationId, latitude, longitude) {
  let url = ETA_URL;
  url += `&modeName=${mode}`;
  url += `&originId=${originId}`;
  url += `&destId=${destinationId}`;
  url += `&latitude=${latitude}`;
  url += `&longitude=${longitude}`;

  return dispatch => {
    dispatch(etaDuck.fetch(url));
  };
}

function fetchPercentOnTime(
  mode,
  originId,
  destinationId,
  latitude,
  longitude,
  hoursFromEarliestArrival,
  hoursFromLatestArrival
) {
  let url = PERCENT_ON_TIME_URL;
  url += `&modeName=${mode}`;
  url += `&originId=${originId}`;
  url += `&destId=${destinationId}`;
  url += `&latitude=${latitude}`;
  url += `&longitude=${longitude}`;
  url += `&t1=${hoursFromEarliestArrival}`;
  url += `&t2=${hoursFromLatestArrival}`;

  return dispatch => {
    dispatch(percentOnTimeDuck.fetch(url));
  };
}

// Selectors
const getETA = state => {
  const data = state[STORE_MOUNT_POINT].eta.data;
  return data && data.ETA ? data.ETA.median : null;
};
const getPercentOnTime = state => {
  const data = state[STORE_MOUNT_POINT].percentOnTime.data;
  return data && data.GMM ? data.GMM.proba : null;
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchETA,
    fetchPercentOnTime
  },
  selectors: {
    getETA,
    getPercentOnTime
  },
  reducer: combineReducers({
    eta: etaDuck.reducer,
    percentOnTime: percentOnTimeDuck.reducer
  })
};
