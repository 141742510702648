/** @jsx jsx */
import { jsx } from "@emotion/core";
import Colors from "../../../styles/colors";

const CellRenderer = props => {
  return (
    <div css={{ textOverflow: "auto", overflow: "none" }}>{props.value}</div>
  );
};

// Columns to display in API Logs search results table
export const getColumns = t => {
  let columns = [];

  columns.push({
    Header: t("documentation:Received Time"),
    accessor: "receivedTime",
    Cell: CellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px",
        whiteSpace: "unset"
      }
    })
  });

  columns.push({
    Header: t("documentation:Source"),
    accessor: "source",
    Cell: CellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px",
        whiteSpace: "unset"
      }
    })
  });

  columns.push({
    Header: t("documentation:Event Type"),
    accessor: "eventType",
    Cell: CellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px",
        whiteSpace: "unset"
      }
    })
  });

  columns.push({
    Header: t("documentation:Shipment ID"),
    accessor: "shipmentId",
    width: 300,
    Cell: CellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px",
        whiteSpace: "unset"
      }
    })
  });

  columns.push({
    Header: t("documentation:Shipper"),
    accessor: "shipperName",
    Cell: CellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px",
        whiteSpace: "unset"
      }
    })
  });

  columns.push({
    Header: t("documentation:Status"),
    accessor: "status",
    Cell: CellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px",
        whiteSpace: "unset",
        color:
          rowInfo && rowInfo.row && rowInfo.row.status === "Failed"
            ? Colors.highlight.RED
            : "inherit"
      }
    })
  });

  return columns;
};
