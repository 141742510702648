/** @jsx jsx */
import { jsx } from "@emotion/core";
import _ from "lodash";
import {
  localizedDateFormatter,
  localizedTimeFormatter
} from "../../utils/date-time";
import { zeroMargin } from "./PartnerDashboardStyles";

export const milestoneEventsTableColumns = t => [
  {
    accessor: "vin",
    Header: t("partner-search:VIN"),
    minWidth: 180,
    api_sort_column: "VIN"
  },
  {
    id: "shipper",
    accessor: d => _.get(d, "customerRef[0][0].name"),
    Header: t("partner-search:Shipper"),
    minWidth: 125,
    maxWidth: 175,
    // note: according to its diagnostic messages, the api does not allow
    // sorting on the shipper column
    api_sort_column: ""
  },
  {
    accessor: "status_description",
    Header: t("partner-search:Milestone Event"),
    minWidth: 125,
    maxWidth: 175,
    api_sort_column: "MILESTONE_EVENTS"
  },
  {
    accessor: "status_code",
    Header: t("partner-search:Milestone Status Code"),
    maxWidth: 150,
    api_sort_column: "MILESTONE_CODES"
  },
  {
    id: "location",
    accessor: d => {
      const location = _.get(d, "shipper_location[0]");
      if (_.isEmpty(location)) {
        return <div />;
      }
      const {
        name,
        address,
        address2,
        city,
        state,
        postal_code,
        code
      } = location;
      return (
        <div>
          <p css={[zeroMargin]}>{`${name} (${code})`}</p>
          <p css={[zeroMargin]}>{`${address} ${address2}`}</p>
          <p css={[zeroMargin]}>{`${city}, ${state} ${postal_code}`}</p>
        </div>
      );
    },
    Header: t("partner-search:Location"),
    minWidth: 200,
    api_sort_column: "MILESTONE_LOCATIONS"
  },
  {
    id: "timestamp",
    accessor: d => (
      <div>
        <p css={[zeroMargin]}>{localizedDateFormatter(d.ts)}</p>
        <p css={[zeroMargin]}>{localizedTimeFormatter(d.ts)}</p>
      </div>
    ),
    Header: t("partner-search:Timestamp"),
    minWidth: 125,
    maxWidth: 175,
    api_sort_column: "MILESTONE_TIME"
  },
  {
    accessor: "comments",
    Header: t("partner-search:Milestone Comments and References"),
    minWidth: 300,
    api_sort_column: "MILESTONE_COMMENTS"
  }
];

// Return an object that can be used to look up the column by a key. Sometimes
// the key is the column's accessor property, but sometimes the accessor is a
// function instead of a simple key. In those cases the key is the id.
export const keyByIdOrAccessor = columns => {
  const result = {};
  columns.forEach(col => {
    if (col.id) {
      result[col.id] = col;
    } else {
      result[col.accessor] = col;
    }
  });
  return result;
};
