/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import PropTypes from "prop-types";
import MetalTrackingDashboard from "./MetalTrackingDashboard";
import MixpanelUtils from "../../trackers/mixpanel";

export default class MetalTrackingDashboardView extends Component {
  componentDidMount() {
    const { solutionId, fetchMetalTrackingEntities } = this.props;
    fetchMetalTrackingEntities(solutionId);
    MixpanelUtils.track("Viewed Page: Metal / Dashboard");
  }

  render() {
    const {
      authorization,
      metalTrackingEntities,
      solutionId,
      toggleWatchedRackFlag,
      setSearchFilter,
      chooseLocation
    } = this.props;
    return (
      <MetalTrackingDashboard
        metalTrackingEntities={metalTrackingEntities}
        solutionId={solutionId}
        authorization={authorization}
        toggleWatchedRackFlag={toggleWatchedRackFlag}
        setSearchFilter={setSearchFilter}
        chooseLocation={chooseLocation}
      />
    );
  }
}

MetalTrackingDashboardView.propTypes = {
  authorization: PropTypes.object.isRequired,
  chooseLocation: PropTypes.func,
  fetchMetalTrackingEntities: PropTypes.any,
  metalTrackingEntities: PropTypes.array.isRequired,
  setSearchFilter: PropTypes.func,
  solutionId: PropTypes.string,
  toggleWatchedRackFlag: PropTypes.func
};
