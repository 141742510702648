/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from "react";
import { jsx } from "@emotion/core";
import { withTranslation } from "react-i18next";

import { DataWidgetFrame } from "../../../components/widgets/DataWidgetFrame";
import { RackCountWidget } from "./RackCountWidget";
import Colors from "../../../styles/colors";

export const getRackCountsByKey = (racks, key) => {
  let counts = [];
  racks.map(rack => {
    const value = rack[key];

    if (value !== null) {
      let item = counts.find(obj => obj.label === value);

      if (item) {
        item.count = item.count + 1;
      } else {
        counts.push({ label: value, count: 1 });
      }
    }
    return null;
  });

  return counts;
};

class RacksByType extends Component {
  render() {
    const { metalTrackingEntities, setSearchFilter, t } = this.props;

    const racksByType = getRackCountsByKey(
      metalTrackingEntities,
      "description"
    );

    return (
      <DataWidgetFrame
        header={t("mt-dashboard:Racks By Type")}
        styles={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyItems: "stretch"
        }}
      >
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            gridGap: 10,
            marginRight: 10,
            padding: 10
          }}
        >
          {racksByType.map((obj, i) => (
            <RackCountWidget
              key={i}
              count={obj.count}
              label={obj.label}
              color={Colors.highlight.GREEN}
              handler={() => {
                setSearchFilter("description", [obj.label]);
              }}
            />
          ))}
        </div>
      </DataWidgetFrame>
    );
  }
}

const RacksByTypeWithTranslation = withTranslation(["mt-dashboard"])(
  RacksByType
);
export { RacksByTypeWithTranslation as RacksByType };
