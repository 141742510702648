/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";
import styled from "@emotion/styled";
import { Enum } from "enumify";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";
import { FlexDiv, FlexColDiv } from "../../styles/container-elements";
import LinkButton from "../../vendor/signal-widgets/components/link-button";
import safari from "../../assets/icons/safari.svg";
import chrome from "../../assets/icons/chrome.svg";
import AuthenticationUtils from "../auth/authentication";
import MixpanelUtils from "../../trackers/mixpanel";

const linkStyles = {
  color: Colors.linkText,
  fontSize: "medium",
  textDecoration: "none",
  ":hover": {
    color: Colors.bannerLinkText,
    cursor: "pointer",
    textDecoration: "none"
  }
};

export const CenteredP = styled.p({ textAlign: "center" });

export class StatusCodes extends Enum {}
StatusCodes.initEnum({
  UNAUTHORIZED: {
    code: 401,
    primary: "UNAUTHORIZED",
    secondary:
      "Access Is Allowed Only For Authenticated Users. Login Or Contact Your System Administrator To Provide You Access."
  },
  ACCESS_FORBIDDEN: {
    code: 403,
    primary: "ACCESS FORBIDDEN",
    secondary: "You Are Not Authorized To Access The Page You Requested."
  },
  PAGE_NOT_FOUND: {
    code: 404,
    primary: "PAGE NOT FOUND",
    secondary: "The Page You Requested Could Not Be Found On Our Server."
  },
  NOT_SUPPORTED: {
    code: 403,
    primary: "UNSUPPORTED BROWSER",
    secondary: (
      <div>
        <CenteredP>
          <strong>{"Improve Your Experience"}</strong>
        </CenteredP>
        <CenteredP>{"You’re using a web browser we don’t support."}</CenteredP>
        <CenteredP>
          {
            "Try one of these options to have a better experience on FreightVerify."
          }
        </CenteredP>
      </div>
    )
  }
});

class ErrorView extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    MixpanelUtils.track(
      `Viewed Page: Error / ${this.props.statusCode.primary}`
    );
  }

  render() {
    const { t, statusCode } = this.props;

    let linkElem;

    if (statusCode === StatusCodes.UNAUTHORIZED) {
      linkElem = (
        <LinkButton
          style={linkStyles}
          onClick={() => {
            AuthenticationUtils.logout();
            AuthenticationUtils.login();
          }}
        >
          &laquo; {t("errors:Login")}
        </LinkButton>
      );
    } else if (statusCode === StatusCodes.NOT_SUPPORTED) {
      linkElem = (
        <FlexDiv
          css={{
            justifyContent: "space-around"
          }}
        >
          <LinkButton
            style={{ ...linkStyles, marginRight: "3em" }}
            onClick={() => {
              window.location.href = "https://www.google.com/chrome/";
            }}
          >
            <FlexColDiv
              css={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                src={chrome}
                style={{ height: "4.5em" }}
                alt={t("errors:Chrome")}
              />
            </FlexColDiv>
          </LinkButton>
          <LinkButton
            style={{
              ...linkStyles,
              marginLeft: "3em"
            }}
            onClick={() => {
              window.location.href =
                "https://support.apple.com/downloads/safari";
            }}
          >
            <FlexColDiv
              css={{
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                src={safari}
                style={{ height: "5em" }}
                alt={t("errors:Safari")}
              />
            </FlexColDiv>
          </LinkButton>
        </FlexDiv>
      );
    } else {
      linkElem = (
        <div>
          <LinkButton
            style={linkStyles}
            onClick={() => {
              window.history.back();
            }}
          >
            &laquo; {t("errors:Go Back")}
          </LinkButton>{" "}
          /{" "}
          <LinkButton
            style={linkStyles}
            onClick={() => {
              window.location.href = "/";
            }}
          >
            {t("errors:Go Home")} &raquo;
          </LinkButton>
        </div>
      );
    }

    return (
      <div
        css={{
          alignItems: "center",
          backgroundColor: Colors.background.DARK_GRAY,
          color:
            statusCode === StatusCodes.NOT_SUPPORTED
              ? Colors.text.DARK_BLUE
              : Colors.tableHeadingText,
          display: "flex",
          flex: "auto",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center"
        }}
      >
        <FlexColDiv
          css={{
            alignItem: "center",
            backgroundColor:
              statusCode === StatusCodes.NOT_SUPPORTED ? "white" : null,
            padding: "3em"
          }}
        >
          {statusCode !== StatusCodes.NOT_SUPPORTED ? (
            <FlexDiv css={{ alignItems: "center" }}>
              <span
                css={{
                  fontSize: 250
                }}
              >
                {statusCode.code}
              </span>
              <span
                css={{
                  fontSize: 40,
                  marginLeft: "1em"
                }}
              >
                {statusCode.primary}
              </span>
            </FlexDiv>
          ) : null}
          <FlexDiv css={{ justifyContent: "center" }}>
            <span
              css={{
                fontSize: "large",
                fontWeight: 400
              }}
            >
              {statusCode.secondary}
            </span>
          </FlexDiv>
          <FlexDiv
            css={{
              marginTop: "2.5em",
              justifyContent: "center"
            }}
          >
            {linkElem}
          </FlexDiv>
        </FlexColDiv>
      </div>
    );
  }
}

export default withTranslation(["errors"])(ErrorView);
