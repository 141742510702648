import _ from "lodash";
import debouncePromise from "debounce-promise";

import {
  getBasicQueryString,
  getEmptyQueryString,
  getShipmentDateRangeQueryString
} from "../../components/search-bar/search-filter-query-strings";
import {
  DateRangeFilterButton,
  BatchFilterButton,
  AsyncMultiSelectFilterButton
} from "../../components/search-bar/FilterButtons";
import { isCarrier } from "../../routes";
import SearchBarState from "./ShipmentSearchBarState";
import ShipmentSavedSearchState from "./ShipmentSavedSearchState";

/**
 *
 * @param queryParameter
 * @param filterValue
 * @return {string}
 */
const locationQueryString = (queryParameter, filterValue) => {
  let location_ids_str;

  // Extract the value properties if present (for simple types like integers, fall back to the raw value)
  if (Array.isArray(filterValue)) {
    const location_ids = filterValue.map(val => _.get(val, "value", val));
    location_ids_str = `${location_ids.toString()}`;
  }

  return getBasicQueryString(queryParameter, location_ids_str);
};

export const FILTERS = [
  {
    queryKey: "origin",
    component: AsyncMultiSelectFilterButton,
    label: t => t("shipment-search:Origin"),
    optionsLoader: debouncePromise((query, loadedOptions) => {
      // Calculate page number by checking if the currently loaded results are divisible by the pageSize
      let page = 0;
      const pageSize = 500;
      if (loadedOptions && loadedOptions.length > 0) {
        if (loadedOptions.length % pageSize === 0) {
          page = loadedOptions.length / pageSize;
        }
      }

      return SearchBarState.actionCreators.asyncSearchLocations(
        query,
        true,
        page,
        pageSize
      );
    }, 500),
    valuesLoader:
      ShipmentSavedSearchState.actionCreators.fetchSavedSearchLocations,
    filterOptions: options => options, // Disable client-side filtering
    queryBuilder: (queryParameter, filterValue) =>
      locationQueryString("origin_id", filterValue)
  },
  {
    queryKey: "destination",
    component: AsyncMultiSelectFilterButton,
    label: t => t("shipment-search:Destination"),
    optionsLoader: debouncePromise((query, loadedOptions) => {
      // Calculate page number by checking if the currently loaded results are divisible by the pageSize
      let page = 0;
      const pageSize = 500;
      if (loadedOptions && loadedOptions.length > 0) {
        if (loadedOptions.length % pageSize === 0) {
          page = loadedOptions.length / pageSize;
        }
      }

      return SearchBarState.actionCreators.asyncSearchLocations(
        query,
        true,
        page,
        pageSize
      );
    }, 500),
    valuesLoader:
      ShipmentSavedSearchState.actionCreators.fetchSavedSearchLocations,
    filterOptions: options => options, // Disable client-side filtering
    queryBuilder: (queryParameter, filterValue) =>
      locationQueryString("destination_id", filterValue)
  },
  {
    queryKey: "carrier",
    label: t => t("shipment-search:Carrier"),
    optionsGetter: props => props.carrierFilterOptions,
    queryBuilder: getBasicQueryString,
    hidden: props => isCarrier(props.activeOrganization)
  },
  {
    queryKey: "pickup",
    label: t => t("shipment-search:Pickup Date"),
    optionsGetter: () => [],
    component: DateRangeFilterButton,
    queryBuilder: getShipmentDateRangeQueryString
  },
  {
    queryKey: "delivery",
    label: t => t("shipment-search:Delivery Date"),
    optionsGetter: () => [],
    component: DateRangeFilterButton,
    queryBuilder: getShipmentDateRangeQueryString
  },
  {
    queryKey: "mode",
    label: t => t("shipment-search:Mode"),
    optionsGetter: props => props.shipmentModeFilterOptions,
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("shipment_mode", filterValue)
  },
  {
    queryKey: "location_type",
    label: t => t("shipment-search:Location Type"),
    optionsGetter: props => props.locationTypeFilterOptions,
    queryBuilder: getBasicQueryString,
    hidden: props => isCarrier(props.activeOrganization)
  },
  {
    queryKey: "status",
    label: t => t("shipment-search:Status"),
    optionsGetter: props => props.statusFilterOptions,
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("current_status_id", filterValue)
  },
  // DEV-1070 Shipment event filters will group 4 status ids into 2.
  {
    queryKey: "shipment_event",
    label: t => t("shipment-search:Shipment Event"),
    optionsGetter: props => props.shipmentEventFilterOptions,
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("current_status_id", filterValue)
  },
  {
    queryKey: "active_exception",
    label: t => t("shipment-search:Active Exception"),
    optionsGetter: props => {
      // H2-708: content of exceptions should be translated when presented.
      return props.exceptionFilterOptions.map(e => {
        return { value: e.value, label: props.t(`exceptions:${e.label}`) };
      });
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("exception_id", filterValue)
  },
  {
    queryKey: "shipment_type",
    label: t => t("shipment-search:Shipment Type"),
    optionsGetter: props => props.shipmentTypeFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "line_of_business",
    label: t => t("shipment-search:Line Of Business"),
    optionsGetter: props => props.lineOfBusinessFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "shipper",
    label: t => t("shipment-search:Shipper"),
    optionsGetter: props => props.shipperFilterOptions,
    queryBuilder: getBasicQueryString,
    hidden: props => !isCarrier(props.activeOrganization)
  },
  {
    queryKey: "pro_number",
    label: t => t("shipment-search:Pro Number"),
    optionsGetter: props => props.proNumberFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "trailer_number_filter",
    label: t => t("shipment-search:Trailer #"),
    optionsGetter: props => props.trailerNumberFilterOptions,
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("trailer_number", filterValue)
  },
  {
    queryKey: "route_id_filter",
    label: t => t("shipment-search:Route ID"),
    optionsGetter: props => props.routeNumberFilterOptions,
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("route_number", filterValue)
  },
  {
    queryKey: "trip_plan_number",
    label: t => t("shipment-search:Trip Plan ID"),
    optionsGetter: props => props.tripPlanNumberFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "rail_loaded_status",
    label: t => t("shipment-search:Rail Loaded Status"),
    optionsGetter: props => props.railLoadedStatusFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "batch",
    label: t => t("shipment-search:Batch Search"),
    component: BatchFilterButton,
    optionsGetter: props => {
      const { t } = props;
      return [
        { label: t("shipment-search:Trailer #"), value: "trailer_number" },
        { label: t("shipment-search:Route ID"), value: "route_number" },
        { label: t("shipment-search:Order #"), value: "order_number" },
        { label: t("shipment-search:VIN"), value: "vin" },
        { label: t("shipment-search:Part #"), value: "part_values" },
        { label: t("shipment-search:Trip Plan ID"), value: "trip_plan_number" }
      ];
    },
    queryBuilder: getEmptyQueryString
  }
];
