/** @jsx jsx */
import { jsx } from "@emotion/core";
import { InputGroup, FormControl, DropdownItem } from "react-bootstrap";
import colors from "../../styles/colors";
import { FlexColDiv, FlexDiv } from "../../styles/container-elements";
import { FaSearch } from "react-icons/fa";
import { OrganizationNames } from "./OrganizationsView";
import googleMapsIcon from "../../assets/icons/googlemaps.png";
import hereMapsIcon from "../../assets/icons/heremaps.png";

const activeElement = (t, active, top) => {
  return active ? (
    <div
      css={{
        color: colors.bannerText,
        fontStyle: "italic",
        position: "absolute",
        top: top,
        right: "1em",
        fontSize: "0.9em"
      }}
    >
      {t("header-bar:Active")}
    </div>
  ) : null;
};

const OrgSearchBar = ({ placeholderStr, searchStr, setSearchStr, style }) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        marginBottom: "1em",
        ...style
      }}
    >
      <InputGroup
        style={{
          border: "1px solid rgba(0, 0, 0, 0.1)",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          borderRadius: "3px",
          flex: 1
        }}
      >
        <FaSearch
          style={{
            color: colors.background.DARK_BLUE,
            position: "absolute",
            left: "0.8em",
            top: "0.85em",
            zIndex: 1
          }}
        />
        <FormControl
          type="text"
          onChange={e => setSearchStr(e.target.value)}
          placeholder={placeholderStr}
          value={searchStr}
          style={{
            backgroundColor: "white",
            border: "none",
            boxShadow: "none",
            flex: 1,
            height: "2.8em",
            paddingLeft: "2.4em",
            borderRadius: "3px",
            zIndex: 0
          }}
        />
      </InputGroup>
    </div>
  );
};

const OrgItem = ({
  t,
  fvAdmin,
  authorization,
  org,
  ind,
  active,
  mapType,
  mapTypeOverride,
  setCurrentOrganization,
  setActiveOrganization
}) => {
  let backgroundColor = "white";

  if (active === true) {
    backgroundColor = colors.background.YELLOW;
  } else if (ind % 2 === 0) {
    backgroundColor = colors.tableHeadingText;
  }

  return (
    <DropdownItem
      eventKey={ind}
      className="select-item p-0"
      css={{
        backgroundColor: backgroundColor
      }}
      onClick={() => {
        setCurrentOrganization(org.organization_id, authorization);
        setActiveOrganization(org);
      }}
    >
      <div
        css={{
          margin: 0,
          paddingLeft: ".5em",
          paddingTop: ".5em",
          paddingBottom: ".5em"
        }}
      >
        <FlexColDiv
          css={{
            flex: 1,
            justifyContent: "center",
            marginLeft: "1em",
            position: "relative"
          }}
        >
          <div css={{ color: colors.bannerText, fontWeight: "bold" }}>
            {org.org_name}
            {` (${
              org.org_type === OrganizationNames.CARRIER ? org.scac : org.fv_id
            })`}
          </div>
          <div css={{ color: "#999999" }}>{org.org_type}</div>
          {fvAdmin && (
            <div css={{ position: "absolute", right: "1em", bottom: 0 }}>
              <img
                src={mapType === "Here" ? hereMapsIcon : googleMapsIcon}
                width={14}
                height={14}
                css={{ opacity: !mapTypeOverride ? "1" : "0.4" }}
                alt={t("map:Map Type")}
              />
            </div>
          )}
          {activeElement(t, active, 0)}
        </FlexColDiv>
      </div>
    </DropdownItem>
  );
};

export const OrganizationSelect = ({
  t,
  fvAdmin,
  authorization,
  currentOrganizationId,
  organizations,
  searchStr,
  setCurrentOrganization,
  setActiveOrganization,
  setSearchStr,
  closeOrganizationDropdown,
  mapTypeOverride,
  setMapTypeOverride
}) => {
  let orgElem = organizations.map((org, i) => (
    <OrgItem
      t={t}
      fvAdmin={fvAdmin}
      authorization={authorization}
      ind={i}
      key={i}
      org={org}
      closeOrganizationDropdown={closeOrganizationDropdown}
      active={org.organization_id === currentOrganizationId}
      mapType={org.map_type}
      mapTypeOverride={mapTypeOverride}
      setCurrentOrganization={setCurrentOrganization}
      setActiveOrganization={setActiveOrganization}
    />
  ));

  return (
    <FlexColDiv
      css={{
        minHeight: "12em",
        width: "24em"
      }}
    >
      <FlexColDiv style={{ padding: "1em", position: "relative" }}>
        <FlexDiv
          css={{
            color: colors.formHeadingColor,
            fontWeight: 450,
            marginBottom: ".25em"
          }}
        >
          {t("header-bar:Organizations")}
          {fvAdmin && (
            <div css={{ position: "absolute", right: "1em" }}>
              Map Override:
              <img
                src={hereMapsIcon}
                width={14}
                height={14}
                css={{
                  opacity: mapTypeOverride === "Here" ? "1" : "0.4",
                  cursor: "pointer",
                  margin: "0 5px",
                  ":hover": {
                    opacity: "0.6"
                  }
                }}
                alt={t("map:HERE Maps")}
                title={t("map:HERE Maps")}
                onClick={() => {
                  if (mapTypeOverride === "Here") {
                    setMapTypeOverride("");
                  } else {
                    setMapTypeOverride("Here");
                  }
                }}
              />
              <img
                src={googleMapsIcon}
                width={14}
                height={14}
                css={{
                  opacity: mapTypeOverride === "Google" ? "1" : "0.4",
                  cursor: "pointer",
                  ":hover": {
                    opacity: "0.6"
                  }
                }}
                alt={t("map:Google Maps")}
                title={t("map:Google Maps")}
                onClick={() => {
                  if (mapTypeOverride === "Google") {
                    setMapTypeOverride("");
                  } else {
                    setMapTypeOverride("Google");
                  }
                }}
              />
            </div>
          )}
        </FlexDiv>
        <FlexDiv css={{ flex: 1 }}>
          <OrgSearchBar
            style={{ flex: 1, marginBottom: 0 }}
            placeholderStr={t("header-bar:Search Organizations")}
            searchStr={searchStr}
            setSearchStr={setSearchStr}
          />
        </FlexDiv>
      </FlexColDiv>
      <ul
        css={{
          display: "block",
          height: "30em",
          listStyle: "none",
          margin: 0,
          overflow: "auto",
          padding: 0
        }}
      >
        {orgElem}
      </ul>
    </FlexColDiv>
  );
};
