import { getEverythingQueryString } from "../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: t => t("connected-car:Search Vehicles"),
    placeholder: t => t("connected-car:Search Vehicles"),
    queryBuilder: getEverythingQueryString,
    property: null
  }
];
