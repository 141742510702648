import { connect } from "react-redux";
import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "./ConnectedCarSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import connectedCarDomainData from "../domain-data/ConnectedCarDomainData";

const mapStateToProps = state => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata
  } = SearchBarState.selectors;

  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    solutionId: getSolutionId(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    searchEntities,
    clickedSearchOrEnter
  } = SearchBarState.actionCreators;
  return {
    setSearchCategory: key => dispatch(setSearchCategoryForKey(key)),
    setSearchText: text => dispatch(setSearchText(text)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    searchEntities: solutionId => {
      dispatch(clickedSearchOrEnter(true));
      dispatch(searchEntities(solutionId, false));
    },
    fetchDomainData: solutionId =>
      dispatch(
        connectedCarDomainData.actionCreators.fetchDomainData(solutionId)
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
