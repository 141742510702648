/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import _ from "lodash";
import {
  Modal,
  Button,
  FormControl,
  InputGroup,
  FormGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Dialog from "react-bootstrap-dialog";
import { FaQuestionCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import fileDownload from "js-file-download";

import SelectField from "../forms/fields/SelectField";
import Colors from "../../styles/colors";
import { FlexDiv, FlexRowDiv } from "../../styles/container-elements";

// Modal for applying a batch search filter (allows user to upload CSV or enter line-separated filter values)
class BatchFilterModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSearchType: null,
      importFile: null,
      importCsv: null,
      autoCloseWindowTimer: null
    };

    this.isBatchValid = this.isBatchValid.bind(this);
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    this.fileResetHandler = this.fileResetHandler.bind(this);
    this.onFileLoad = this.onFileLoad.bind(this);
    this.fileDropHandler = this.fileDropHandler.bind(this);
    this.textChangeHandler = this.textChangeHandler.bind(this);
    this.convertLinesToCsv = this.convertLinesToCsv.bind(this);
  }

  componentDidMount() {
    // H2-659: Removed these two lines because we need batch text not cleared
    // for saved batch searches
    //const { clearBatchSearchText } = this.props;
    //clearBatchSearchText();
    Dialog.setOptions({
      defaultOkLabel: "Yes",
      defaultCancelLabel: "No",
      primaryClassName: "btn-success"
    });
  }

  onFileLoad(file) {
    const csv = file.target.result.trim();
    const lines = csv.split(/\r\n|\n/);
    if (lines.length > 1) {
      lines.splice(0, 1); // Remove the header row
      const linesCsv = this.convertLinesToCsv(lines);
      this.setState({ importCsv: linesCsv });
    }
  }

  convertLinesToCsv(lines) {
    const parsedLines = lines.map(l =>
      l.toString().replace(/"([^"]+(?="))"/g, "$1")
    );
    return parsedLines.join(",");
  }

  fileSelectHandler(e) {
    let newFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsText(newFile);
    reader.onload = this.onFileLoad;

    this.setState({ importFile: newFile });
  }

  fileResetHandler(e) {
    this.setState({ importFile: null });
  }

  fileDropHandler(e) {
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.items && e.dataTransfer.items.length === 1) {
      const file = e.dataTransfer.items[0].getAsFile();
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = this.onFileLoad;

      this.setState({ importFile: file });
    }
  }

  textChangeHandler(e) {
    const batchText = e && e.target && e.target.value ? e.target.value : "";
    const lines = batchText.split(/\r\n|\n/);
    const linesCsv = this.convertLinesToCsv(lines);
    this.setState({ importText: batchText, importCsv: linesCsv });
  }

  isBatchValid() {
    const { importFile, importCsv, importText } = this.state;
    const isFileValid = importFile && importCsv;
    const isTextValid = importText && importCsv;
    return isFileValid || isTextValid;
  }

  downloadTemplate() {
    const { searchTypeOptions } = this.props;
    const { selectedSearchType } = this.state;

    const selectedSearchTypeOption = _.find(
      searchTypeOptions,
      o => o.value === selectedSearchType
    );
    const filePrefix = selectedSearchTypeOption
      ? selectedSearchTypeOption.value
      : "batch";
    const rowPrefix = selectedSearchTypeOption
      ? selectedSearchTypeOption.label
      : "Batch Value";
    let csvContent = `${rowPrefix}s\n`;
    [1, 2, 3].forEach(i => (csvContent = `${csvContent}${rowPrefix} ${i}\n`));
    const exportFileName = `${filePrefix}-template-${Date.now()}.csv`;
    const templateContent = csvContent;

    fileDownload(templateContent, exportFileName);
  }

  render() {
    const { show, hide, onSubmit, searchTypeOptions, t } = this.props;
    const {
      importFile,
      importText,
      importCsv,
      selectedSearchType
    } = this.state;

    const selectedSearchTypeOption = _.find(
      searchTypeOptions,
      o => o.value === selectedSearchType
    );
    const selectedSearchTypeLabel = selectedSearchTypeOption
      ? selectedSearchTypeOption.label
      : "";

    const disabled = !this.isBatchValid();

    let csvTooltip = (
      <Tooltip id="cvs-tooltip">
        <div
          css={{
            padding: ".75em",
            textAlign: "left"
          }}
        >
          {`${t("components:The file must contain")}:`}
          <ul style={{ paddingLeft: "1.25em" }}>
            <li>{selectedSearchTypeLabel} 1</li>
            <li>{selectedSearchTypeLabel} 2</li>
            <li>{selectedSearchTypeLabel} 3</li>
          </ul>
        </div>
      </Tooltip>
    );

    return (
      <Modal
        // H1-314: User is selecting the email field via dragging towards the outside of
        // the modal, which closes it. This is apparently a bug with our version of
        // react-bootstrap https://github.com/reactjs/react-modal/issues/633 which
        // closes the modal when the backdrop detects an onMouseUp event.
        // For now just disable closing the modal when the user clicks outside of it,
        // if the users complain we will need to look for alternative ways of handling
        // this (maybe increasing the padding between the modal's border and its
        // contents).
        backdrop={"static"}
        show={show}
        onShow={() => {
          this.setState({
            importFile: null,
            importCsv: null
          });
        }}
        onHide={() => {
          this.setState({
            importFile: null,
            importCsv: null
          });
          hide();
        }}
      >
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {t("components:Batch Search")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <div>
            <div
              css={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                marginTop: "0em"
              }}
            />
            <div
              css={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                marginTop: "1em"
              }}
            />
          </div>
          <div />
          <div>
            <FormGroup>
              <SelectField
                placeholder={t("components:Select search criteria")}
                stateValue={selectedSearchType}
                options={searchTypeOptions}
                onChange={val =>
                  this.setState({ selectedSearchType: val.value })
                }
              />
            </FormGroup>

            {selectedSearchType ? (
              <div>
                <FlexRowDiv css={{ marginBottom: "1em" }}>
                  <span>{t("components:Select a CSV file to import")}</span>
                  {". "}
                  <OverlayTrigger placement="right" overlay={csvTooltip}>
                    <FaQuestionCircle />
                  </OverlayTrigger>
                  <FlexDiv
                    css={{
                      color: "#999",
                      marginLeft: "1em",
                      fontStyle: "italic",
                      textDecoration: "underline",
                      ":hover": {
                        cursor: "pointer",
                        color: Colors.background.DARK_BLUE
                      }
                    }}
                    onClick={() => this.downloadTemplate()}
                  >
                    {t("components:Download Template")}
                  </FlexDiv>
                </FlexRowDiv>

                <FormGroup>
                  <InputGroup>
                    <label className="input-group-btn">
                      <span
                        className={`btn btn-light ${
                          importText ? "disabled" : ""
                        }`}
                      >
                        {`${t("components:Browse")}`}
                        <input
                          id="files"
                          type="file"
                          accept=".csv"
                          title={null}
                          onChange={this.fileSelectHandler}
                          disabled={importText ? true : false}
                          data-qa="input-file-batch-modal"
                          hidden
                        />
                      </span>
                    </label>

                    <FormControl
                      type="type"
                      value={importFile ? importFile.name : ""}
                      placeholder={t("components:Select file")}
                      htmlFor="files"
                      style={{ zIndex: 0 }}
                      onDragOver={e => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                      onDrop={this.fileDropHandler}
                      readOnly
                    />
                    {importFile && (
                      <span className="input-group-btn">
                        <button
                          className="btn btn-default"
                          type="button"
                          onClick={this.fileResetHandler}
                          data-qa="button-remove-file-batch-modal"
                        >
                          <MdClose />
                        </button>
                      </span>
                    )}
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <label>
                    {`${t("Enter")} ${selectedSearchTypeLabel}s (${t(
                      "components:Line Separated"
                    )})`}
                  </label>
                  <textarea
                    className="form-control"
                    rows={10}
                    onChange={this.textChangeHandler}
                    disabled={importFile ? true : false}
                    data-qa="textarea-batch-modal"
                  ></textarea>
                </FormGroup>
              </div>
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em"
            }}
            onClick={() => {
              if (this.state.importFile) {
                this.setState({
                  importFile: null,
                  importCsv: null
                });
              }
              hide();
            }}
            data-qa="button-cancel-batch-modal"
          >
            {t("components:Cancel")}
          </Button>
          <Button
            disabled={disabled}
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300",
              border: "1px solid #ccc"
            }}
            onClick={() => {
              const payload = {
                batch_type: selectedSearchType,
                batch_list: importCsv ? `${importCsv},` : ""
              };

              onSubmit(payload);
              hide();
            }}
            data-qa="button-import-batch-modal"
          >
            {t("components:Import")}
          </Button>
          <Dialog
            ref={el => {
              this.dialog = el;
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation(["components"])(BatchFilterModal);
