// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { LadChicletCSS as Chiclet } from "../../chiclets";
import FormCell from "../FormCell";
import SelectField from "../fields/SelectField";

/**
 *
 * @param label
 * @param options
 * @param value
 * @param onChange
 * @param lads
 * @param isReadOnly
 * @param selectedLad
 * @return {*}
 * @constructor
 */
const LADSelectInput = ({
  label,
  options,
  value,
  onChange,
  lads,
  isReadOnly,
  selectedLad
}) => {
  /**
   *
   * @param label
   * @param value
   * @param innerProps
   * @return {*}
   */
  const customOption = ({ label, value, innerProps }) => {
    return (
      <div
        ref={innerProps.ref}
        {...innerProps}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 7,
          ":hover": {
            cursor: "pointer",
            backgroundColor: "#DEEBFF"
          },
          ":active": {
            backgroundColor: "#DEEBFF"
          }
        }}
      >
        <Chiclet
          height={25}
          width={25}
          style={{ marginRight: "10px" }}
          lad={lads.find(l => l.id === value)}
        />
        {label}
      </div>
    );
  };

  /**
   *
   * @param data
   * @return {*}
   */
  const customValue = ({ data }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          whiteSpace: "nowrap",
          marginRight: "5px",
          position: "absolute"
        }}
        title={data.label}
      >
        <Chiclet
          height={25}
          width={25}
          style={{ marginRight: "10px" }}
          lad={selectedLad}
        />
        {data.label}
      </div>
    );
  };

  customValue.propTypes = {
    data: PropTypes.object
  };

  return (
    <FormCell label={label}>
      <SelectField
        borderWidth="2px"
        indicatorContainerHeight={40}
        options={options}
        stateValue={value}
        onChange={value => {
          const selectedLad = lads.find(l => l.id === value.value);
          onChange(selectedLad);
        }}
        isDisabled={isReadOnly}
        components={{
          Option: customOption,
          SingleValue: customValue
        }}
        className="location-type-custom-select"
      />
    </FormCell>
  );
};

LADSelectInput.propTypes = {
  isReadOnly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  lads: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.any,
  innerProps: PropTypes.object,
  selectedLad: PropTypes.object
};

export default LADSelectInput;
