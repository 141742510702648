import { connect } from "react-redux";
import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "./LocationSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";

const mapStateToProps = state => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata
  } = SearchBarState.selectors;
  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    solutionId: getSolutionId(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    searchEntities,
    clearSearchFilter
  } = SearchBarState.actionCreators;

  return {
    setSearchCategory: key => dispatch(setSearchCategoryForKey(key)),
    setSearchText: text => dispatch(setSearchText(text)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    searchEntities: (solutionId, resetPagination, clickedSearchOrEnter) => {
      // When users search manually, it needs to search in the whole database.
      if (clickedSearchOrEnter) {
        dispatch(clearSearchFilter("near_location"));
      }
      dispatch(
        searchEntities(solutionId, resetPagination, clickedSearchOrEnter)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
