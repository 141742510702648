import axios from "axios";
import apiUrl from "../../api-url";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";

import * as OrganizationsActions from "../../modules/organizations/OrganizationsState";
import RolesState from "../../modules/roles/RolesState";

// URLS
const USERS_URL = apiUrl("/iam/users");

// Actions
const SET_CURRENT_USER = "Users/SET_CURRENT_USER";
const CLEAR_ACTION_STATUS = "Users/CLEAR_ACTION_STATUS";

const RECEIVE_USERS = "Users/RECEIVE_USERS";

const ADD_USER = "Users/ADD_USER";
const ADD_USER_SUCCEEDED = "Users/ADD_USER_SUCCEEDED";
const ADD_USER_FAILED = "Users/ADD_USER_FAILED";

const UPDATE_USER = "Users/UPDATE_USER";
const UPDATE_USER_SUCCEEDED = "Users/UPDATE_USER_SUCCEEDED";
const UPDATE_USER_FAILED = "Users/UPDATE_USER_FAILED";

const DELETE_USER = "Users/DELETE_USER";
const DELETE_USER_SUCCEEDED = "Users/DELETE_USER_SUCCEEDED";
const DELETE_USER_FAILED = "Users/DELETE_USER_FAILED";

const RESET_PASSWORD_USER = "Users/RESET_PASSWORD_USER";
const RESET_PASSWORD_USER_SUCCEEDED = "Users/RESET_PASSWORD_USER_SUCCEEDED";
const RESET_PASSWORD_USER_FAILED = "Users/RESET_PASSWORD_USER_FAILED";

const SET_PASSWORD_USER = "Users/SET_PASSWORD_USER";
const SET_PASSWORD_USER_SUCCEEDED = "Users/SET_PASSWORD_USER_SUCCEEDED";
const SET_PASSWORD_USER_FAILED = "Users/SET_PASSWORD_USER_FAILED";

const IMPORT_USERS = "Users/IMPORT_USERS";
const IMPORT_USERS_SUCCEEDED = "Users/IMPORT_USERS_SUCCEEDED";
const IMPORT_USERS_FAILED = "Users/IMPORT_USERS_FAILED";

const duck = buildFetchDuck("users");

// Action creators
export function setCurrentUser(user) {
  return dispatch => {
    return dispatch({ type: SET_CURRENT_USER, currentUser: user });
  };
}

export function clearActionStatus() {
  return {
    type: CLEAR_ACTION_STATUS
  };
}

export function fetchUsers() {
  const url = `${USERS_URL}`;
  return dispatch => dispatch(duck.fetch(url));
}

export function addUser(payload) {
  return function(dispatch) {
    dispatch({ type: ADD_USER, payload });
    dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(false));
    const url = USERS_URL;
    return axios
      .post(url, payload)
      .then(resp => {
        dispatch({ type: ADD_USER_SUCCEEDED });

        const organizationId = sessionStorage.getItem("currentOrganization");
        dispatch(OrganizationsActions.fetchOrganizationMembers(organizationId));
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch(error => {
        dispatch({ type: ADD_USER_FAILED, error });
        dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(true));
      });
  };
}

export function updateUser(userId, payload) {
  return function(dispatch) {
    dispatch({ type: UPDATE_USER, userId, payload });
    dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(false));
    const url = USERS_URL + `/${userId}`;
    return axios
      .patch(url, payload)
      .then(resp => {
        dispatch({ type: UPDATE_USER_SUCCEEDED, userId, payload });

        const organizationId = sessionStorage.getItem("currentOrganization");
        dispatch(OrganizationsActions.fetchOrganizationMembers(organizationId));
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch(error => {
        dispatch({ type: UPDATE_USER_FAILED, userId, error });
        dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(true));
      });
  };
}

export function deleteUser(user, organizationId) {
  return function(dispatch) {
    const userId = user.user_id;
    const payload = {
      organization_id: organizationId
    };

    dispatch({ type: DELETE_USER, userId, payload });
    dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(false));
    const url = USERS_URL + `/${userId}`;
    return axios
      .delete(url, { data: payload })
      .then(resp => {
        dispatch({ type: DELETE_USER_SUCCEEDED });

        const organizationId = sessionStorage.getItem("currentOrganization");
        dispatch(OrganizationsActions.fetchOrganizationMembers(organizationId));
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch(error => {
        dispatch({ type: DELETE_USER_FAILED, userId, error });
        dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(true));
      });
  };
}

export function blockUser(user, organizationId) {
  return function(dispatch) {
    const userId = user.user_id;
    const payload = {
      organization_id: organizationId
    };
    dispatch({ type: UPDATE_USER, userId, payload });
    dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(false));
    const url = USERS_URL + `/${userId}/block-user`;
    return axios
      .patch(url, payload)
      .then(resp => {
        dispatch({ type: UPDATE_USER_SUCCEEDED, userId, payload });

        const organizationId = sessionStorage.getItem("currentOrganization");
        dispatch(OrganizationsActions.fetchOrganizationMembers(organizationId));
      })
      .catch(error => {
        dispatch({ type: UPDATE_USER_FAILED, userId, error });
        dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(true));
      });
  };
}

export function unblockUser(user, organizationId) {
  const userId = user.user_id;
  return function(dispatch) {
    const payload = {
      organization_id: organizationId
    };
    dispatch({ type: UPDATE_USER, userId, payload });
    dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(false));
    const url = USERS_URL + `/${userId}/unblock-user`;
    return axios
      .patch(url, payload)
      .then(resp => {
        dispatch({ type: UPDATE_USER_SUCCEEDED, userId, payload });

        const organizationId = sessionStorage.getItem("currentOrganization");
        dispatch(OrganizationsActions.fetchOrganizationMembers(organizationId));
      })
      .catch(error => {
        dispatch({ type: UPDATE_USER_FAILED, userId, error });
        dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(true));
      });
  };
}

export function resetPassword(user) {
  const userId = user.user_id;
  return function(dispatch) {
    const payload = {
      email: user.email
    };
    dispatch({ type: RESET_PASSWORD_USER, userId, payload });
    const url = apiUrl("/iam") + `/password-change`;
    return axios
      .post(url, payload)
      .then(resp => {
        dispatch({ type: RESET_PASSWORD_USER_SUCCEEDED, payload });
      })
      .catch(error => {
        dispatch({ type: RESET_PASSWORD_USER_FAILED, error });
      });
  };
}

export function setUserPassword(userId, newPassword) {
  return function(dispatch) {
    const payload = { password: newPassword };
    dispatch({ type: SET_PASSWORD_USER, payload });

    const url = `${apiUrl("/iam/users")}/${userId}/change-password`;

    return axios
      .patch(url, payload)
      .then(resp => {
        dispatch({ type: SET_PASSWORD_USER_SUCCEEDED });
      })
      .catch(error => {
        dispatch({ type: SET_PASSWORD_USER_FAILED, error });
      });
  };
}

export function importUsers(payload) {
  return function(dispatch) {
    dispatch({ type: IMPORT_USERS, payload });
    dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(false));
    const url = USERS_URL;
    return axios
      .post(url, payload)
      .then(resp => {
        dispatch({ type: IMPORT_USERS_SUCCEEDED });

        const organizationId = sessionStorage.getItem("currentOrganization");
        dispatch(OrganizationsActions.fetchOrganizationMembers(organizationId));
        dispatch(RolesState.actionCreators.fetchRoles());
      })
      .catch(error => {
        dispatch({ type: IMPORT_USERS_FAILED, error });
        dispatch(OrganizationsActions.setIsOrganizationMembersLoaded(true));
      });
  };
}

export async function getUserEmail(userId) {
  try {
    const user = await axios.get(`${USERS_URL}/${userId}`);
    if (user.data && user.data.response && user.data.response.email != null) {
      return user.data.response.email;
    }
    return "";
  } catch (error) {
    console.log(error);
    return "";
  }
}

const initialState = {
  actionStatus: null,
  data: []
};

function UsersReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser
      };
    case RECEIVE_USERS:
      return {
        ...state,
        data: action.payload
      };
    case ADD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: "USER_ADDED"
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 409
            ? "Duplicate_User"
            : null
      };
    case IMPORT_USERS_SUCCEEDED:
      return {
        ...state,
        actionStatus: "USERS_IMPORTED"
      };
    case IMPORT_USERS_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          (action.error.response.status === 400 ||
            action.error.response.status === 403 ||
            action.error.response.status === 500)
            ? action.error.response.status === 403
              ? "Permission_Error"
              : "Import_Error"
            : null
      };
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null
      };
    case SET_PASSWORD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: "USER_PASSWORD_SET"
      };
    case RESET_PASSWORD_USER_SUCCEEDED:
      return {
        ...state,
        actionStatus: "USER_PASSWORD_RESET"
      };
    case RESET_PASSWORD_USER_FAILED:
    case SET_PASSWORD_USER_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          action.error.response.status === 401
            ? "Permission_Error"
            : null
      };
    default:
      return state;
  }
}

export default chainReducers([UsersReducer, duck.reducer]);
