import { createSelector } from "reselect";
import _ from "lodash";
import DomainDataState from "../domain-data/DomainDataState";
import { SHIPMENT_EVENTS_FILTER_OPTION_NAMES } from "../domain-data/DomainDataState";
import LadsState from "../lads/LadsState";
import { getLadLobLabel } from "../../components/lads";
import { railLoadedStatusCodesEN } from "../shipment-detail/ShipmentUtils";

const mapIdsAndNames = values =>
  values.map(v => ({
    value: v.id,
    label: v.name
  }));

export const selectOriginFilterOptions = [];

export const selectDestinationFilterOptions = [];

export const selectCarrierFilterOptions = createSelector(
  [DomainDataState.selectors.selectCarriersAlphabetically],
  carriers =>
    carriers.map(carrier => ({
      value: carrier.id,
      label: `${carrier.name} (${carrier.scac})`
    }))
);

export const selectModeFilterOptions = createSelector(
  [DomainDataState.selectors.selectShipmentModesAlphabetically],
  modes =>
    modes.map(mode => ({
      value: mode.id,
      label: mode.name
    }))
);

export const selectLocationTypeFilterOptions = createSelector(
  [LadsState.selectors.selectLadsListAlphabetically],
  lads =>
    lads.map(l => ({
      value: l.id,
      label: getLadLobLabel(l)
    }))
);

export const selectStatusFilterOptions = createSelector(
  [DomainDataState.selectors.selectStatusTypesAlphabetically],
  statusTypes =>
    statusTypes
      .filter(st =>
        // DEV-1070 Don't add the status grouped by the shipment_event filters.
        {
          return !SHIPMENT_EVENTS_FILTER_OPTION_NAMES.includes(st.name);
        }
      )
      .map(st => ({
        value: st.id,
        label: st.name
      }))
);

export const selectShipmentEventFilterOptions = createSelector(
  [DomainDataState.selectors.selectShipmentEventsAlphabetically],
  shipmentEvents => {
    let options = shipmentEvents
      .filter(se => {
        return !(se.name in SHIPMENT_EVENT_NAME_GROUP_MAP);
      })
      .map(se => {
        return { value: se.id, label: se.name };
      });

    // DEV-1070 Add the shipment events that will group some status filters.
    options.push(...getShipmentEventsGroups(shipmentEvents));

    return _.sortBy(options, "label");
  }
);

export const selectExceptionTypeFilterOptions = createSelector(
  [DomainDataState.selectors.selectExceptionTypesAlphabetically],
  mapIdsAndNames
);

export const selectShipmentTypeFilterOptions = createSelector(
  [DomainDataState.selectors.getShipmentTypes],
  mapIdsAndNames
);

export const selectShipperFilterOptions = createSelector(
  [DomainDataState.selectors.getShippers],
  mapIdsAndNames
);

export const selectProNumberFilterOptions = createSelector(
  [DomainDataState.selectors.getProNumbers],
  proNumbers =>
    proNumbers.map(n => ({ value: n[0], label: n[0] })).filter(x => x.value)
);

export const selectTrailerNumberFilterOptions = createSelector(
  [DomainDataState.selectors.getTrailerNumbers],
  trailerNumbers =>
    trailerNumbers.map(n => ({ value: n[0], label: n[0] })).filter(x => x.value)
);

export const selectRouteNumberFilterOptions = createSelector(
  [DomainDataState.selectors.getRouteIDs],
  routeIDs => routeIDs.sort().map(r => ({ value: r, label: r }))
);

export const selectTripPlanNumberFilterOptions = createSelector(
  [DomainDataState.selectors.getTripPlanNumbers],
  tripPlanNumbers => tripPlanNumbers.sort().map(t => ({ value: t, label: t }))
);

export const selectLineOfBusinessFilterOptions = createSelector(
  [DomainDataState.selectors.getLineOfBusiness],
  mapIdsAndNames
);

// H1-2101: this looks like a selector but there's no DomainData for it so we
// fake it for now with a constant
export const getRailLoadedStatusFilterOptions = state =>
  _.map(railLoadedStatusCodesEN, (label, code) => ({ value: code, label }));

// Helper functions

// DEV-1070 : Maps the consolidated status into these two filter names to act as filter
// groups.
const SHIPMENT_EVENT_NAME_GROUP_MAP = {
  "Arrived at Drop-Off": "Arrived at Stop",
  "Arrived at Pickup": "Arrived at Stop",
  "Departed Drop-Off": "Departed Stop",
  "Departed Pickup": "Departed Stop"
};

// DEV-1070 : Dummy ids used to identify the filter groups and replace the status each
// one of them groups.
const FILTER_GROUP_ID_MAP = {
  "Arrived at Stop": -1,
  "Departed Stop": -2
};

// DEV-1070 : Returns a list of filters for the shipment events that will group some
// of the status ids.
// In the form:
//   { id: dummyId, label: "the label", groupsIds: [list-of-ids-this-filter-groups] }
const getShipmentEventsGroups = shipmentEvents => {
  let groups = [];
  shipmentEvents.forEach(st => {
    if (st.name in SHIPMENT_EVENT_NAME_GROUP_MAP) {
      const shipmentEventName = SHIPMENT_EVENT_NAME_GROUP_MAP[st.name];
      const id = FILTER_GROUP_ID_MAP[shipmentEventName];
      let group = groups.find(o => o.label === shipmentEventName);
      group
        ? group.groupsIds.push(st.id)
        : groups.push({
            value: id,
            label: shipmentEventName,
            groupsIds: [st.id]
          });
    }
  });
  return groups;
};
