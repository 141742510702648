/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import SectionHeader from "./SectionHeader";
import ParameterTable from "./ParameterTable";

const parametersCss = {
  padding: "1em 0"
};

const SampleParameters = props => {
  const { id, title, params } = props;

  if (!params || !params.length) {
    return null;
  }

  return (
    <div id={id} css={parametersCss}>
      <SectionHeader title={title} />
      <ParameterTable params={params} />
    </div>
  );
};

SampleParameters.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  params: PropTypes.array
};
export default SampleParameters;
