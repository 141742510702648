/** @jsx jsx
 * Popup displayed when clicking a VIN on the Connected Car map
 */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import _ from "lodash";

import { FlexDiv, FlexColDiv } from "../../../styles/container-elements";
import {
  popupCss,
  pageButtonCss
} from "../styles/PaginatedLocationInfoPopupStyles";

// Hook to manage pagination between multiple locations
const usePagination = (
  locations,
  selectedLocation,
  onPopupChangePage = _.noop
) => {
  const total = locations.length;
  const enablePagination = total > 1;
  const selectedLocationIndex = selectedLocation
    ? _.findIndex(locations, {
        id: selectedLocation.id
      })
    : null;
  const [index, setIndex] = useState(selectedLocationIndex ?? 0);
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(false);
  const location = locations[index];

  useEffect(() => {
    let newIndex = index;
    setPrevDisabled(false);
    setNextDisabled(false);

    if (newIndex <= 0) {
      newIndex = 0;
      setPrevDisabled(true);
    }

    if (newIndex >= locations.length - 1) {
      newIndex = locations.length - 1;
      setNextDisabled(true);
    }

    setIndex(newIndex);
    onPopupChangePage(locations[newIndex]);
  }, [locations, selectedLocation, onPopupChangePage, index]);

  return {
    enablePagination,
    location,
    index,
    setIndex,
    total,
    prevDisabled,
    nextDisabled
  };
};

const PaginatedLocationInfoPopup = ({
  locations,
  selectedLocation,
  handler,
  onPopupChangePage = _.noop,
  t
}) => {
  const {
    enablePagination,
    location,
    index,
    setIndex,
    total,
    prevDisabled,
    nextDisabled
  } = usePagination(locations, selectedLocation, onPopupChangePage);

  return (
    <FlexColDiv data-qa="popup-location" css={popupCss}>
      <dl>
        <dt>{`${t("map:VIN")}:`}</dt>
        <dd data-qa="vin">{location.vin}</dd>

        <dt>{`${t("map:Make")}:`}</dt>
        <dd data-qa="make">{location.make}</dd>

        <dt>{`${t("map:Model")}:`}</dt>
        <dd data-qa="model">{location.model}</dd>

        <dt>{`${t("map:Year")}:`}</dt>
        <dd data-qa="year">{location.year}</dd>

        <dt>{`${t("map:Location")}:`}</dt>
        <dd data-qa="location">{location.location}</dd>

        <dt>{`${t("map:Time")}:`}</dt>
        <dd data-qa="timestamp">{location.timestamp}</dd>

        <dt>{`${t("map:Lat/Lon")}:`}</dt>
        <dd data-qa="lat_long">{`${location.latitude}/${location.longitude}`}</dd>
      </dl>

      <Button
        data-qa="button-details"
        className="btn-success"
        onClick={() => handler(location)}
      >
        {t("map:View More Details")}
      </Button>

      {enablePagination ? (
        <FlexDiv className="page-container">
          <Button
            data-qa="button-prev"
            disabled={prevDisabled}
            onClick={() => setIndex(index - 1)}
            css={pageButtonCss(prevDisabled)}
          >
            <IoIosArrowBack className="page-icon" />
          </Button>
          <div className="page-number">
            VIN <span data-qa="text-page-current">{index + 1}</span>/
            <span data-qa="text-page-total">{total}</span>
          </div>
          <Button
            data-qa="button-next"
            disabled={nextDisabled}
            onClick={() => setIndex(index + 1)}
            css={pageButtonCss(nextDisabled)}
          >
            <IoIosArrowForward className="page-icon" />
          </Button>
        </FlexDiv>
      ) : null}
    </FlexColDiv>
  );
};

PaginatedLocationInfoPopup.propTypes = {
  locations: PropTypes.array.isRequired,
  handler: PropTypes.func.isRequired,
  selectedLocation: PropTypes.object,
  onPopupChangePage: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default PaginatedLocationInfoPopup;
