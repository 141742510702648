import {
  getBasicQueryString,
  getEverythingQueryString
} from "../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "search_everything_values",
    label: t => t("partner-search:Search Everything"),
    placeholder: t =>
      t(
        "partner-search:Search VIN, Location, Milestone Event, Milestone Status Code"
      ),
    queryBuilder: getEverythingQueryString,
    property: null // meaning all other properties
  },
  {
    queryKey: "locationsCodes",
    label: t => t("partner-search:Location"),
    placeholder: t => t("partner-search:Search Location"),
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "milestone_events",
    label: t => t("partner-search:Milestone Event"),
    placeholder: t => t("partner-search:Search Milestone Event"),
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "milestone_codes",
    label: t => t("partner-search:Milestone Status Code"),
    placeholder: t => t("partner-search:Search Milestone Status Code"),
    queryBuilder: getBasicQueryString
  }
];
