import axios from "axios";
import apiUrl from "../../api-url";

// URLS
const STORE_MOUNT_POINT = "fvEntities";

// Actions

const FVE = actionName => `${STORE_MOUNT_POINT}/${actionName}`;
const REQUEST_ENTITY_COUNT = FVE("REQUEST_ENTITY_COUNT");
const RECEIVE_ENTITY_COUNT = FVE("RECEIVE_ENTITY_COUNT");
const REQUEST_WATCHED_ENTITIES = FVE("REQUEST_WATCHED_ENTITIES");
const RECEIVE_WATCHED_ENTITIES = FVE("RECEIVE_WATCHED_ENTITIES");
const RECEIVE_WATCHED_ENTITIES_ERROR = FVE("RECEIVE_WATCHED_ENTITIES_ERROR");

// Action creators
function fetchEntityCount(solutionId) {
  const countUrl = apiUrl(
    `/entity/solution/${solutionId}/entity?lifeCycleState=Active,Completed,Delivered`
  );

  const headers = { headers: { Accept: "application/json;version=count" } };

  return dispatch => {
    dispatch({
      type: REQUEST_ENTITY_COUNT
    });

    return Promise.all([axios.get(`${countUrl}`, headers)])
      .then(responses => {
        dispatch({
          type: RECEIVE_ENTITY_COUNT,
          payload: responses[0].data.meta.totalCount
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

function fetchWatchedEntities(solutionId) {
  return async dispatch => {
    dispatch({
      type: REQUEST_WATCHED_ENTITIES
    });

    const url = apiUrl(`/entity/solution/${solutionId}/entity?watched=1`);
    try {
      const resp = await axios.get(url);
      return dispatch({
        type: RECEIVE_WATCHED_ENTITIES,
        payload: { watchedEntities: resp.data.data }
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_ENTITIES_ERROR, error });
    }
  };
}

// Asynchronously return location search results for <Async> select component
function searchVINs(query, solutionId) {
  if (query.length < 2) {
    return {
      options: [],
      hasMore: false
    };
  }

  const url = apiUrl(
    `/entity/solution/${solutionId}/entity?entityId=${query}&pageNumber=0&pageSize=20&lifeCycleState=Active,Completed,Delivered`
  );

  return axios
    .get(url)
    .then(response => {
      if (
        !response ||
        !response.data ||
        !response.data.data ||
        response.data.data.length === 0
      ) {
        return { options: [], hasMore: false };
      } else {
        const vinOptions = buildSearchVinOptions(response.data.data);
        return {
          options: vinOptions,
          hasMore: false
        };
      }
    })
    .catch(err => {
      console.error(err);
    });
}

// Convert location search response data into options for <Async> select and typeahead components
export function buildSearchVinOptions(data) {
  return data.map(l => {
    try {
      return {
        value: l.id,
        label: l.id,
        id: l.id
      };
    } catch (e) {
      console.error(e);
      return { options: [], hasMore: false };
    }
  });
}

// Selectors
const getEntityCount = state => state[STORE_MOUNT_POINT].entityCount || 0;
const getEntityCountLoading = state =>
  state[STORE_MOUNT_POINT].entityCountLoading;

const getWatchedVins = state => state[STORE_MOUNT_POINT].watchedEntities;
const getWatchedVinsLoading = state =>
  state[STORE_MOUNT_POINT].watchedEntitiesLoading;

// Initial state
const initialState = {
  entityCount: 0,
  entityCountLoading: false,
  watchedEntities: [],
  watchedEntitiesLoading: false
};

const FinVehicleEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_ENTITY_COUNT:
      return {
        ...state,
        entityCountLoading: true
      };

    case RECEIVE_ENTITY_COUNT:
      return {
        ...state,
        entityCount: action.payload,
        entityCountLoading: false
      };

    case REQUEST_WATCHED_ENTITIES:
      return {
        ...state,
        watchedEntitiesLoading: true
      };

    case RECEIVE_WATCHED_ENTITIES:
      return {
        ...state,
        watchedEntities: action.payload.watchedEntities,
        watchedEntitiesLoading: false
      };

    default:
      return state;
  }
};

// interface
const FinVehicleEntitiesState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchEntityCount,
    fetchWatchedVins: fetchWatchedEntities, // TEMP
    fetchWatchedEntities,
    searchVINs
  },
  selectors: {
    getEntityCount,
    getEntityCountLoading,
    getWatchedVins,
    getWatchedVinsLoading
  },
  reducer: FinVehicleEntityReducer
};
export default FinVehicleEntitiesState;
