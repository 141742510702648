import { SEARCH_CATEGORIES } from "./LocationSearchCategoryDefs";
import { FILTERS } from "./LocationSearchFilterDefs";
import apiUrl from "../../api-url";
import buildSearchBarState from "../../components/search-bar/SearchBarStateBuilder";

const STORE_MOUNT_POINT = "fvLocationsSearch";

// Helpers

const locationsUrl = (solutionId, queryString) => {
  return apiUrl(
    `/location/locations?verbose=false&includeLinkLocations=true&${queryString}`
  );
};

// Actions

const fetchSearch = (queryString = "", solutionId, duck, dispatch) => {
  const url = locationsUrl(solutionId, queryString);
  dispatch(duck.fetch(url));
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "name"
  }
);

export default SearchBarState;
