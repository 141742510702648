/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Loader from "react-loader";
import { MediaQueries } from "../../components/responsive";
import Colors from "../../styles/colors";
import RoutingMap from "../map/components/RoutingMap";
import MultimodalVinDetailPanel from "./vin-details-styled-components/MultimodalVinDetailPanel";
import SingleShipmentVinDetailPanel from "./vin-details-styled-components/SingleShipmentVinDetailPanel";
import { ResponsiveSection } from "../shipment-detail/ShipmentDetailView";
import {
  convertTripLegData,
  addProgressToPlannedLegs,
  flattenPlannedLegs,
  getVinTabs
} from "./vin-details-utils";
import HeatmapButton from "../shipment-detail/HeatmapButton";
import { getLegShipments } from "../shipment-detail/ShipmentUtils";
import { withTranslation } from "react-i18next";
import MixpanelUtils from "../../trackers/mixpanel";

class VinDetailsView extends Component {
  static propTypes = {
    handleClose: PropTypes.func,
    trip: PropTypes.array
  };

  state = {
    isLoading: true,
    watch: null,
    showHeatmap: false,
    selectedLegID: null
  };

  constructor(props) {
    super(props);
    this.fetchEntityMedia = this.fetchEntityMedia.bind(this);
    this.toggleWatchEntity = this.toggleWatchEntity.bind(this);
    this.eventHandler = this.eventHandler.bind(this);
  }

  componentDidMount() {
    const { entityId } = this.props;

    entityId &&
      this.fetchEntityDetails().then(() => {
        this.setState({ isLoading: false });
      });

    MixpanelUtils.track("Viewed Page: VIN / Details");
  }

  componentDidUpdate(prevProps) {
    const {
      actualLegs,
      entityId,
      details,
      clearEntityMedia,
      fetchLegShipmentDetails
    } = this.props;

    const { watch } = this.state;

    if (entityId && prevProps.entityId !== entityId) {
      this.fetchEntityDetails().then(() => {
        this.setState({ isLoading: false });
      });
    }

    // If the details have been updated we need to make sure
    // that our watch checkbox matches the value in the props
    if (details && prevProps.details !== details) {
      clearEntityMedia();
      this.fetchEntityMedia();

      if (details.watch !== watch) {
        this.setState({ watch: details.watch });
      }
    }

    if (!_.isEqual(prevProps.actualLegs, actualLegs)) {
      fetchLegShipmentDetails(actualLegs);
    }
  }

  fetchEntityDetails() {
    const {
      solutionId,
      entityId,
      fetchEntityDetails,
      fetchEntityExceptions,
      fetchEntityHolds,
      fetchEntityPositionUpdates,
      fetchEntityEvents,
      fetchPlannedTripLeg,
      fetchActualTripLeg
    } = this.props;

    this.setState({ isLoading: true });

    // Non-layout data fetches; do not display spinner while loading
    fetchEntityExceptions(solutionId, entityId);
    fetchEntityHolds(solutionId, entityId);
    fetchEntityPositionUpdates(solutionId, entityId);
    fetchEntityEvents(solutionId, entityId);

    // Data fetches needed to render layout; return Promise to display isLoading spinner
    return Promise.all([
      fetchEntityDetails(solutionId, entityId),
      fetchPlannedTripLeg(solutionId, entityId),
      fetchActualTripLeg(solutionId, entityId)
    ]);
  }

  fetchEntityMedia() {
    const { details, solutionId, fetchEntityMedia } = this.props;

    if (!details || !details.references || !solutionId) {
      return null;
    }

    const make = _.find(details.references, { qualifier: "Decoded-Make" });
    const model = _.find(details.references, { qualifier: "Decoded-Model" });

    if (make && model) {
      const key = `${make.value}-${model.value}`;
      fetchEntityMedia(solutionId, key);
    }
  }

  toggleWatchEntity() {
    const { entityId, solutionId, setWatchEntity } = this.props;
    const { watch } = this.state;

    // Toggle our internal state, so the UI remains responsive
    // and then send the update.  If the state is not properly
    // changed on the backend, we will revert when we receive the
    // response from the API
    const newWatchState = !watch;
    this.setState({ watch: newWatchState });
    setWatchEntity(solutionId, entityId, newWatchState);
  }

  eventHandler(val, type) {
    switch (type) {
      case "SELECT_LEG":
        this.setState({ selectedLegID: val });
        break;
      default:
        break;
    }
  }

  render() {
    const { isLoading, watch, showHeatmap, selectedLegID } = this.state;

    const {
      ladsList,
      selectCoordinate,
      clearCoordinate,
      selectedCoordinate,
      details,
      combinedLegs,
      shipmentModes,
      childShipments,
      shipmentsLoaded,
      plannedLegs,
      actualLegs,
      type,
      connectedCarCoords,
      isShowingConnectedCarPositions,
      combinedExceptions,
      currentLocation,
      t
    } = this.props;

    const loaded = !isLoading && shipmentsLoaded;
    let tripPlan = [];
    let hasTripPlan = false;
    let hasActualLegs = false;
    let legShipments = [];
    let isMultimodal = false;
    let plannedLegsWithProgress = [];
    let plannedStops = null;
    let routeShipments = [];

    /* H1-1263 make sure child shipments are also loaded before attempting to render */
    if (loaded) {
      tripPlan = convertTripLegData(combinedLegs);
      hasTripPlan = tripPlan.length > 0;
      hasActualLegs =
        actualLegs && actualLegs.tripLegs && actualLegs.tripLegs.length > 0;

      legShipments = getLegShipments(
        childShipments,
        shipmentsLoaded,
        hasTripPlan || hasActualLegs
      );
      // H1-1546: Added plannedLegs to this condition, once the ticket says that
      // Trip plan should be showed even when actual trip legs do not exist
      isMultimodal = legShipments.length > 0 || plannedLegs.tripLegs.length > 0;

      plannedLegsWithProgress =
        isMultimodal && !_.isNil(plannedLegs.tripLegs)
          ? addProgressToPlannedLegs(plannedLegs, actualLegs)
          : [];
      plannedStops = !_.isNil(plannedLegsWithProgress)
        ? flattenPlannedLegs(plannedLegsWithProgress)
        : null;
      routeShipments = selectedLegID
        ? [childShipments[selectedLegID]]
        : legShipments;
    }

    const vinTabs = getVinTabs({
      t,
      combinedExceptions,
      currentLocation,
      details,
      connectedCarCoords
    });

    return (
      <Loader loaded={loaded}>
        <div
          css={{
            display: "flex",
            [MediaQueries.smallAndDown]: {
              flexDirection: "column"
            },
            [MediaQueries.mediumAndUp]: {
              flexDirection: "row"
            }
          }}
        >
          <ResponsiveSection>
            <RoutingMap
              shipments={routeShipments}
              showBreadCrumbs={true}
              showStopSequence={true}
              showHeatmap={showHeatmap}
              heatmapCoords={_.flatten(
                routeShipments.map(item =>
                  _.get(item, "current_location.updates")
                )
              )}
              isMultimodal={isMultimodal}
              activeShipment={isMultimodal ? details.activeTripLegId : null}
              connectedCarCoords={connectedCarCoords}
              isShowingConnectedCarPositions={isShowingConnectedCarPositions}
            />

            <HeatmapButton
              toggled={showHeatmap}
              onToggle={() => this.setState({ showHeatmap: !showHeatmap })}
            />
          </ResponsiveSection>
          <ResponsiveSection
            css={{ backgroundColor: Colors.background.LIGHT_GRAY }}
          >
            {shipmentsLoaded &&
              (isMultimodal ? (
                <MultimodalVinDetailPanel
                  watch={watch}
                  toggleWatchEntity={this.toggleWatchEntity}
                  details={details}
                  isLoaded={shipmentsLoaded}
                  eventHandler={this.eventHandler}
                  shipments={legShipments}
                  shipmentModes={shipmentModes}
                  selectCoordinate={selectCoordinate}
                  clearCoordinate={clearCoordinate}
                  selectedCoordinate={selectedCoordinate}
                  coords={this.props.coords}
                  connectedCarCoords={this.props.connectedCarCoords}
                  vinTabs={vinTabs}
                  hasTripPlan={hasTripPlan}
                  tripPlan={tripPlan}
                  hasActualLegs={hasActualLegs}
                  actualLegs={actualLegs}
                  plannedStops={plannedStops}
                />
              ) : (
                <SingleShipmentVinDetailPanel
                  watch={watch}
                  toggleWatchEntity={this.toggleWatchEntity}
                  details={details}
                  tripPlan={tripPlan}
                  shipmentModes={shipmentModes}
                  vinTabs={vinTabs}
                  coords={this.props.coords}
                  connectedCarCoords={this.props.connectedCarCoords}
                  selectCoordinate={selectCoordinate}
                  clearCoordinate={clearCoordinate}
                  type={type}
                  combinedLegs={combinedLegs}
                  plannedLegs={plannedLegs}
                  actualLegs={actualLegs}
                  shipments={convertTripLegData(combinedLegs)}
                  ladsList={ladsList}
                  hasTripPlan={hasTripPlan}
                />
              ))}
          </ResponsiveSection>
        </div>
      </Loader>
    );
  }
}

export default withTranslation(["fv-vin-details"])(VinDetailsView);
