import { connect } from "react-redux";
import FilterSection from "../../components/search-bar/FilterSection";
import FinVehicleSearchBarState from "./FinVehicleSearchBarState";
import { FILTERS } from "./FinVehicleSearchFilterDefs";
import {
  selectOriginFilterOptions,
  selectDestinationFilterOptions,
  selectCarrierFilterOptions,
  selectExceptionTypeFilterOptions,
  selectHoldTypeFilterOptions,
  selectSoldStatusFilterOptions,
  selectProductTypeFilterOptions,
  selectLifeCycleStateFilterOptions,
  selectOrderTypeFilterOptions,
  selectPartnerFilterOptions
} from "./FinVehicleSearchFilterSelectors";
import { getSolutionId } from "../organizations/OrganizationsState";

const mapStateToProps = state => ({
  originFilterOptions: selectOriginFilterOptions(state),
  destinationFilterOptions: selectDestinationFilterOptions(state),
  carrierFilterOptions: selectCarrierFilterOptions(state),
  exceptionTypeFilterOptions: selectExceptionTypeFilterOptions(state),
  holdTypeFilterOptions: selectHoldTypeFilterOptions(state),
  soldStatusFilterOptions: selectSoldStatusFilterOptions(state),
  productTypeFilterOptions: selectProductTypeFilterOptions(state),
  lifeCycleStateFilterOptions: selectLifeCycleStateFilterOptions(state),
  searchFilters: FinVehicleSearchBarState.selectors.getSearchFilters(state),
  orderTypeFilterOptions: selectOrderTypeFilterOptions(state),
  partnerFilterOptions: selectPartnerFilterOptions(state),
  solutionId: getSolutionId(state)
});

const mapDispatchToProps = dispatch => {
  const {
    setSearchFilter,
    clearSearchFilter,
    searchEntities
  } = FinVehicleSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: key => dispatch(clearSearchFilter(key)),
    searchEntities: solutionId => dispatch(searchEntities(solutionId))
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FilterSection);
