/** @jsx jsx */
/**
 * Wrapper of a data frame normally used in our dashboards.
 *
 * It has three configurable properties:
 *
 * > children: React DOM object that will be put into the frame.
 * > styles: dictionary containing the styles you eventually want to change.
 * > header: React DOM object you want to put as header.
 */
import PropTypes from "prop-types";

// eslint-disable-next-line
import React from "react";

import { jsx } from "@emotion/core";
import Loader from "react-loader";
import Colors from "../../styles/colors";

import { MediaQueries } from "../responsive";

export const DataWidgetFrame = ({ children, styles, header, isLoading }) => {
  return (
    <div
      css={{
        backgroundColor: "white",
        borderRadius: "3px",
        width: "100%",
        boxShadow: "0px 3px 14px 0.5px rgba(0,0,0,0.05)",
        ...styles
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          backgroundColor: Colors.background.DARK_GRAY,
          height: 54,
          color: "white",
          fontSize: 18,
          padding: 16,
          borderRadius: "3px 3px 0 0"
        }}
      >
        {header}
      </div>
      <div
        css={{
          padding: 16,
          overflow: "auto",
          flexGrow: 1,
          [MediaQueries.mediumAndUp]: {
            padding: 0
          }
        }}
      >
        {isLoading ? (
          <div css={{ marginTop: "25%" }}>
            <Loader options={{ position: "relative" }} />
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

DataWidgetFrame.defaultProps = {
  isLoading: false
};

DataWidgetFrame.propTypes = {
  children: PropTypes.object,
  header: PropTypes.node,
  isLoading: PropTypes.bool.isRequired,
  styles: PropTypes.object
};
