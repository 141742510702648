/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button, FormControl } from "react-bootstrap";

import Colors from "../../styles/colors";
import ShipmentSavedSearchState from "../shipment-search/ShipmentSavedSearchState";
import { withTranslation } from "react-i18next";

class RenameSavedSearchModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: ""
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedSavedSearch } = this.props;
    if (selectedSavedSearch !== prevProps.selectedSavedSearch) {
      this.setState({ name: selectedSavedSearch.name || "" });
    }
  }

  render() {
    const { t, show, hide, selectedSavedSearch, editSearch } = this.props;
    const { name } = this.state;

    return (
      <Modal show={show} onHide={() => hide()}>
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {t("profile:Rename Search")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <p css={{ fontWeight: "bold" }}>{t("profile:Name")}</p>
          <FormControl
            style={{
              backgroundColor: "white",
              height: "3em",
              boxShadow: "none"
            }}
            type="text"
            value={name || ""}
            placeholder={t("profile:Search name")}
            onChange={evt => this.setState({ name: evt.target.value })}
          />
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em"
            }}
            onClick={() => hide()}
          >
            {t("profile:Cancel")}
          </Button>
          <Button
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300"
            }}
            onClick={() => {
              editSearch(
                selectedSavedSearch.id,
                name,
                selectedSavedSearch.search
              );
              hide();
            }}
          >
            {t("profile:Save")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.profile
  };
}

function mapDispatchToProps(dispatch) {
  return {
    editSearch: (id, name, searchObj) =>
      dispatch(
        ShipmentSavedSearchState.actionCreators.editSearch(id, name, searchObj)
      )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["profile"])(RenameSavedSearchModal));
