import { connect } from "react-redux";

import CreateShipmentView from "./CreateShipmentView";
import CreateShipmentState from "./CreateShipmentState";
import {
  getActiveOrganization,
  getOrganizations
} from "../organizations/OrganizationsState";
import SearchBarState from "./ShipmentStopsSearchBarState";

function mapStateToProps(state) {
  const { getSearchResults } = SearchBarState.selectors;

  return {
    activeOrganization: getActiveOrganization(state),
    createStatus: state.createShipment.createStatus,
    shipperLocations: getSearchResults(state),
    organizations: getOrganizations(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createShipment: data =>
      dispatch(CreateShipmentState.actionCreators.createShipment(data)),
    resetCreateForm: () =>
      dispatch(CreateShipmentState.actionCreators.resetCreateForm())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateShipmentView);
