/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import {
	VictoryLine,
	VictoryChart,
	VictoryAxis,
	VictoryScatter,
	VictoryTooltip
} from "victory";
import _ from "lodash";
import moment from "moment";

class EndPoint extends React.Component {
	render() {
		const { x, y, datum } = this.props; // VictoryScatter supplies x, y and datum
		return (
			<svg>
				<rect
					x={x - 15}
					y={y - 15}
					width={30}
					height={30}
					rx={3}
					fill="#999"
					fillOpacity="0.7"
				/>
				<text x={x} y={y + 6} fontSize={15} textAnchor="middle" fill="white">
					{datum.text}
				</text>
			</svg>
		);
	}
}

const TimeForceGraph = ({ title, data, dangerThreshold, size, start, end }) => {
	const lastTrueIdx = _.findIndex(data, ["y", null]) - 1;
	const endpoints = [start, end].map((d, i) => {
		let label = i === 0 ? "O" : "D";
		return {
			x: moment(d).unix(),
			y: 0,
			readable_timestamp: moment(d).format("lll"),
			y_midline: dangerThreshold,
			text: label
		};
	});
	return (
		<div
			css={{
				height: 150,
				backgroundColor: "white",
				flexGrow: 1,
				padding: "10px",
				marginTop: 20,
				marginBottom: 20,
				boxShadow: "0px 2px 13px rgba(0, 0, 0, 0.1)"
			}}
		>
			<div css={{ fontSize: 16, fontWeight: 400, color: "black" }}>{title}</div>
			<svg width="100%" height={160}>
				<defs>
					<linearGradient id="myGradient" x1="0%" y1="0%" x2="0%" y2="100%">
						<stop offset="0%" stopColor="red" />
						<stop offset="50%" stopColor="#f4d442" />
						<stop offset="100%" stopColor="green" />
					</linearGradient>
				</defs>
				<VictoryChart
					domainPadding={{ y: 5 }}
					standalone={false}
					width={size.width - 130}
					height={100}
					padding={{ left: 20, right: 0, top: 10, bottom: 30 }}
				>
					<VictoryScatter
						data={data}
						size={15}
						labels={d => ""}
						x="timestamp"
						style={{ data: { fill: "white" } }}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={<TooltipLine width={size.width} />}
								y={100}
								renderInPortal={false}
							/>
						}
					/>
					<VictoryAxis
						tickFormat={() => ""}
						style={{
							axis: { stroke: "#999", strokeOpacity: "0.7" }
						}}
					/>
					<VictoryScatter data={endpoints} dataComponent={<EndPoint />} />
					<VictoryLine
						style={{
							data: { stroke: "url(#myGradient)" },
							labels: { fill: "green" }
						}}
						interpolation="monotoneX"
						data={data}
						scale={{ x: "time", y: "linear" }}
						x="timestamp"
					/>
					<VictoryLine
						data={endpoints}
						y="y_midline"
						style={{
							data: { stroke: "#999", strokeWidth: 1, strokeDasharray: 10 }
						}}
					/>
					<VictoryScatter
						data={data.slice(lastTrueIdx, lastTrueIdx + 1)}
						size={4}
						style={{ data: { fill: "white", stroke: "black", strokeWidth: 2 } }}
						x="timestamp"
					/>
				</VictoryChart>
			</svg>
		</div>
	);
};

class TooltipLine extends React.Component {
	render() {
		const { x, width, datum } = this.props;
		const textX = width - x > 180 ? x : x - 140;
		return (
			<g>
				<line x1={x} x2={x} y1={100} y2={0} stroke="black" />
				<circle cx={x} cy={3} r={3} fill="black" />
				<circle
					cx={x}
					cy={100}
					r={5}
					stroke="black"
					fill="white"
					strokeWidth={1}
				/>
				<circle
					cx={x}
					cy={100}
					r={2}
					stroke="black"
					fill="red"
					strokeWidth={1}
				/>
				<text x={textX + 10} y={105} fontWeight="600">
					{moment(datum.timestamp * 1000).format("L")}
				</text>
				<text x={textX + 90} y={105} fontWeight="400">
					{moment(datum.timestamp * 1000).format("HH:mm")}
				</text>
			</g>
		);
	}
}
TooltipLine.defaultEvents = VictoryTooltip.defaultEvents;
export default TimeForceGraph;
