/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Button } from "react-bootstrap";
import Select from "react-select";
import Loader from "react-loader";
import { withTranslation } from "react-i18next";

import { MdAdd } from "react-icons/md";

import BasicSearchBar from "../../components/search-bar/BasicSearchBar";
import Colors from "../../styles/colors";
import OrganizationsTable from "./components/OrganizationsTable";
import AddOrganizationModal from "./components/AddOrganizationModal";
import EditOrganizationModal from "./components/EditOrganizationModal";
import DeleteOrganizationModal from "./components/DeleteOrganizationModal";
import {
  FlexColDiv,
  FlexRowDiv,
  Section
} from "../../styles/container-elements";

// Helpers

/**
 * Return a function that is capable of, receiving some items, filter them
 * based on closured value of organizationName.
 */
const createOrganizationsSelector = organizationName => {
  return items =>
    _.filter(
      items,
      item => item.org_type === organizationName && !item.deleted_at
    );
};

// Definitions
export const ModalMode = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE"
};

export const OrganizationNames = {
  SHIPPER: "Shipper",
  CARRIER: "Carrier",
  SUPPLIER: "Supplier",
  THIRD_PARTY_LOGISTIC: "ThirdParty",
  PARTNER: "Partner"
};

/**
 * Organization Type definition
 */
const OrganizationTypesOptions = [
  {
    label: "Shippers",
    itemsSelector: createOrganizationsSelector(OrganizationNames.SHIPPER),
    value: OrganizationNames.SHIPPER
  },
  {
    label: "Carriers",
    itemsSelector: createOrganizationsSelector(OrganizationNames.CARRIER),
    value: OrganizationNames.CARRIER
  },
  {
    label: "Suppliers",
    itemsSelector: createOrganizationsSelector(OrganizationNames.SUPPLIER),
    value: OrganizationNames.SUPPLIER
  },
  {
    label: "Third-Party",
    itemsSelector: createOrganizationsSelector(
      OrganizationNames.THIRD_PARTY_LOGISTIC
    ),
    value: OrganizationNames.THIRD_PARTY_LOGISTIC
  },
  {
    label: "Partners",
    itemsSelector: createOrganizationsSelector(OrganizationNames.PARTNER),
    value: OrganizationNames.PARTNER
  }
];

const OrganizationsView = ({
  fetchOrganizationTypes,
  isOrganizationsLoaded,
  organizations,
  t
}) => {
  const [showModal, setShowModal] = useState({
    modalMode: null,
    organization: null,
    organizationName: ""
  });
  const [searchStr, setSearchStr] = useState("");
  const [orgType, setOrgType] = useState(null);

  useEffect(() => {
    fetchOrganizationTypes();
  }, [fetchOrganizationTypes]);

  const filteredOrganizations = useMemo(() => {
    const SearchableAttributes = ["org_name", "fv_id", "scac"];

    // Filter organizations by organization type if selected. all organizations if no type selected
    let filtered = orgType
      ? orgType.itemsSelector(organizations)
      : organizations;

    // Order by name
    filtered = _.sortBy(filtered, "org_name");

    // If there is no search filter, just return the selected organizations by
    // the current tab selected
    if (!searchStr) {
      return filtered;
    }

    // If there is search filter, execute the search on the searchable
    // attributes and return items that matches this search
    return filtered.filter(item =>
      SearchableAttributes.some(
        searchableAttribute =>
          item.hasOwnProperty(searchableAttribute) &&
          item[searchableAttribute] &&
          item[searchableAttribute]
            .toLowerCase()
            .includes(searchStr.toLowerCase())
      )
    );
  }, [organizations, searchStr, orgType]);

  const rowActionHandler = (action, val, name = "") => {
    setShowModal({
      modalMode: ModalMode[action],
      organization: val,
      organizationName: name
    });
  };

  const closeModal = () => {
    setShowModal({ modalMode: null, organization: null, organizationName: "" });
  };

  const totalOrganizations = _.size(filteredOrganizations);
  const orgTypeName = orgType ? orgType.value : "";

  return (
    <Section
      style={{
        flex: 1,
        justifyContent: "space-between",
        paddingLeft: "1em",
        paddingRight: "1em"
      }}
    >
      <Loader loaded={isOrganizationsLoaded} />
      <FlexColDiv>
        <FlexRowDiv
          css={{
            flex: 1,
            marginTop: "1em",
            height: "100%",
            marginBottom: "1em"
          }}
        >
          <BasicSearchBar
            placeholder={t("organizations:Search for organization")}
            value={searchStr}
            changeHandler={searchStr => setSearchStr(searchStr)}
          />
          <Button
            variant="success"
            style={{
              height: "3em",
              marginLeft: "0.5em"
            }}
            onClick={() => rowActionHandler("CREATE", null, "")}
          >
            <MdAdd /> {t("organizations:Add New Organization")}
          </Button>
        </FlexRowDiv>
        <FlexRowDiv
          style={{
            marginBottom: "1em",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <div
            style={{
              fontWeight: 600,
              color: Colors.background.GRAY_BLUE,
              marginLeft: ".5em"
            }}
          >{`${totalOrganizations} ${orgTypeName} ${t(
            "organizations:organizations"
          )}`}</div>

          <div style={{ width: "14em", zIndex: 200 }}>
            <Select
              name="selected-org-type"
              multi={false}
              isClearable={true}
              style={{ height: "3em" }}
              options={OrganizationTypesOptions}
              value={orgType}
              placeholder={t("organizations:Organization Type")}
              onChange={selections => setOrgType(selections)}
            />
          </div>
        </FlexRowDiv>
        <OrganizationsTable
          actionHandler={rowActionHandler}
          data={filteredOrganizations}
          organizationType={orgTypeName}
        />
      </FlexColDiv>
      <AddOrganizationModal
        show={showModal.modalMode === ModalMode.CREATE}
        hide={() => closeModal()}
        organizationName={showModal.organizationName}
      />
      <EditOrganizationModal
        show={showModal.modalMode === ModalMode.UPDATE}
        hide={() => closeModal()}
        organization={showModal.organization}
      />
      <DeleteOrganizationModal
        show={showModal.modalMode === ModalMode.DELETE}
        hide={() => closeModal()}
        organization={showModal.organization}
      />
    </Section>
  );
};

OrganizationsView.propTypes = {
  fetchOrganizationTypes: PropTypes.func.isRequired,
  isOrganizationsLoaded: PropTypes.bool.isRequired,
  organizations: PropTypes.array,
  t: PropTypes.func.isRequired
};

export default withTranslation(["organizations"])(OrganizationsView);
