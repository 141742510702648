import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import { FlexColDiv, FlexDiv } from "../../styles/container-elements";
import { SHIPPERS } from "./CreateShipmentState";
import FormRow from "../../components/forms/FormRow";
import StandardInput from "../../components/forms/inputs/StandardInput";
import SelectInput from "../../components/forms/inputs/SelectInput";

class ShipmentInfoForm extends React.Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    updateField: PropTypes.func.isRequired
  };

  render() {
    const { t, data, updateField } = this.props;

    return (
      <FlexColDiv style={{ marginTop: "1em", marginBottom: "1em" }}>
        <FormRow>
          <SelectInput
            label={
              <span>
                {t("create-shipment:Customer/Shipper")}
                <sup>*</sup>
              </span>
            }
            options={SHIPPERS}
            value={data.customer}
            onChange={value => updateField("customer", value)}
            borderColor="#ced4da"
          />

          <StandardInput
            label={
              <span>
                {t("create-shipment:Shipment ID")}
                <sup>*</sup>
              </span>
            }
            value={data.shipmentID}
            onChange={value => updateField("shipmentID", value)}
          />
          <StandardInput
            label={t("create-shipment:Asset ID")}
            value={data.assetID}
            onChange={value => updateField("assetID", value)}
          />
        </FormRow>
        <FormRow>
          <StandardInput
            label={t("create-shipment:Trailer ID")}
            value={data.trailerID}
            onChange={value => updateField("trailerID", value)}
          />
          <StandardInput
            label={t("create-shipment:Route ID")}
            value={data.routeID}
            onChange={value => updateField("routeID", value)}
          />
          <FlexDiv css={{ flex: 1, marginLeft: "1em" }} />
        </FormRow>
        <FormRow style={{ marginTop: "1em" }} divider />
      </FlexColDiv>
    );
  }
}

export default withTranslation(["create-shipment"])(ShipmentInfoForm);
