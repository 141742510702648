// Extensions to add Locations search to other stores (ShipmentSearchBarState, etc)

import _ from "lodash";
import axios from "axios";
import apiUrl from "../../api-url";
import { StatesUS } from "../location-edit/LocationEditData";

const FETCH_LOCATIONS = "locations/FETCH_LOCATIONS";
const RECEIVE_LOCATIONS = "locations/RECEIVE_LOCATIONS";

const locationsUrl = query =>
  apiUrl(`/location/locations?verbose=false&everything=${query}`);

// Actions

// Search locations and dispatch data to store
export const searchLocations = (query, getUrl = locationsUrl) => {
  return dispatch => {
    if (query.length < 2) {
      return null;
    }

    dispatch({ type: FETCH_LOCATIONS });

    const url = getUrl(query);
    return Promise.all([axios.get(url)])
      .then(responses => {
        const data =
          responses &&
          responses[0] &&
          responses[0].data &&
          responses[0].data.length
            ? responses[0].data
            : [];
        const locationOptions = buildSearchLocationOptions(data);

        dispatch({
          type: RECEIVE_LOCATIONS,
          payload: locationOptions
        });
      })
      .catch(err => {
        console.error(err);
        dispatch({
          type: RECEIVE_LOCATIONS,
          payload: []
        });
      });
  };
};

// Asynchronously search locations and return options for <Async> select components
export async function asyncSearchLocations(
  query,
  paginate = false,
  page = 0,
  pageSize = 500,
  getUrl = locationsUrl
) {
  if (query.length < 2) {
    if (paginate) {
      return {
        options: [],
        hasMore: false
      };
    }
    return null;
  }

  let url = getUrl(query);

  if (paginate) {
    url += `&pageNumber=${page}&pageSize=${pageSize}`;
  }

  return axios
    .get(url)
    .then(response => {
      let data;
      if (paginate) {
        data =
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length
            ? response.data.data
            : [];
      } else {
        data =
          response && response.data && response.data.length
            ? response.data
            : [];
      }
      const locationOptions = buildSearchLocationOptions(data);
      return {
        options: locationOptions,
        // If we get results that are not divisible by pageSize, then we've hit the last page
        hasMore:
          paginate &&
          locationOptions.length > 0 &&
          locationOptions.length % pageSize === 0
      };
    })
    .catch(err => {
      console.error(err);
    });
}

// Convert location search response data into options for <Async> select and typeahead components
export function buildSearchLocationOptions(data) {
  return data.map(l => {
    const state = _.find(StatesUS, { value: l.state });

    try {
      return {
        value: l.id,
        label: l.code ? `${l.name} (${l.code})` : l.name,
        id: l.id,
        code: l.code,
        name: l.name,
        address: l.address,
        state: l.state,
        stateName: state ? state.label : "",
        city: l.city,
        country: l.country
      };
    } catch (e) {
      console.error(e);
      return {};
    }
  });
}

// Reducer

const locationsInitialState = {
  locations: [],
  locationsLoading: false
};

export const locationsReducer = (
  state = locationsInitialState,
  action = {}
) => {
  switch (action.type) {
    case FETCH_LOCATIONS:
      return {
        ...state,
        locations: [],
        locationsLoading: true
      };
    case RECEIVE_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
        locationsLoading: false
      };
    default:
      return state;
  }
};
