import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import FilterSection from "../../components/search-bar/FilterSection";
import SearchBarState from "./ShipmentSearchBarState";
import { getActiveOrganization } from "../organizations/OrganizationsState";
import { FILTERS } from "./ShipmentSearchFilterDefs";

import {
  selectCarrierFilterOptions,
  selectModeFilterOptions,
  selectLocationTypeFilterOptions,
  selectStatusFilterOptions,
  selectShipmentEventFilterOptions,
  selectExceptionTypeFilterOptions,
  selectShipmentTypeFilterOptions,
  selectShipperFilterOptions,
  selectProNumberFilterOptions,
  selectTrailerNumberFilterOptions,
  selectRouteNumberFilterOptions,
  selectTripPlanNumberFilterOptions,
  getRailLoadedStatusFilterOptions,
  selectLineOfBusinessFilterOptions
} from "./ShipmentSearchFilterSelectors";

const mapStateToProps = state => ({
  carrierFilterOptions: selectCarrierFilterOptions(state),
  shipmentModeFilterOptions: selectModeFilterOptions(state),
  locationTypeFilterOptions: selectLocationTypeFilterOptions(state),
  statusFilterOptions: selectStatusFilterOptions(state),
  shipmentEventFilterOptions: selectShipmentEventFilterOptions(state),
  exceptionFilterOptions: selectExceptionTypeFilterOptions(state),
  shipmentTypeFilterOptions: selectShipmentTypeFilterOptions(state),
  shipperFilterOptions: selectShipperFilterOptions(state),
  proNumberFilterOptions: selectProNumberFilterOptions(state),
  trailerNumberFilterOptions: selectTrailerNumberFilterOptions(state),
  routeNumberFilterOptions: selectRouteNumberFilterOptions(state),
  tripPlanNumberFilterOptions: selectTripPlanNumberFilterOptions(state),
  railLoadedStatusFilterOptions: getRailLoadedStatusFilterOptions(state),
  activeOrganization: getActiveOrganization(state),
  lineOfBusinessFilterOptions: selectLineOfBusinessFilterOptions(state),
  searchFilters: SearchBarState.selectors.getSearchFilters(state)
});

const mapDispatchToProps = dispatch => {
  const {
    setSearchFilter,
    clearSearchFilter,
    searchEntities
  } = SearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: key => dispatch(clearSearchFilter(key)),
    searchEntities: () => dispatch(searchEntities())
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(
  withTranslation(["shipment-search", "components", "exceptions"])(
    FilterSection
  )
);
