/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { FlexDiv } from "../../../styles/container-elements";
import LinkPanel from "./LinkPanel";

const linkPanelsCss = {
  flexWrap: "wrap",
  margin: "2em -1em"
};

const LinkPanels = props => {
  const { links } = props;

  const linkComponents = links.map((link, i) => {
    return (
      <LinkPanel
        key={i}
        title={link.title}
        description={link.description}
        file={link.file}
        type={link.type}
        baseUrl={link.baseUrl}
        eventHandler={link.eventHandler}
      />
    );
  });

  if (!linkComponents) {
    return null;
  }

  return <FlexDiv css={linkPanelsCss}>{linkComponents}</FlexDiv>;
};

LinkPanels.propTypes = {
  links: PropTypes.array.isRequired
};
export default LinkPanels;
