/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import PropTypes from "prop-types";
import Colors from "../../../styles/colors";
import { withTranslation } from "react-i18next";

const desktopGroupCss = {
  a: {
    textDecoration: "none !important"
  }
};

const titleCss = (isOpen, sidebarMinimize) => {
  return {
    alignItems: "center",
    color: Colors.text.HIT_GRAY,
    cursor: "pointer",
    display: "flex",
    padding: "10px 5px 10px 0",
    position: "relative",
    fontSize: "1.0em",
    marginLeft: "0.5em",

    strong: {
      flexGrow: 1,
      fontWeight: 700
    },

    "&:hover": {
      color: "white",

      ".navicon": {
        color: Colors.background.AQUA
      },

      ".triangle": {
        borderBottomColor: isOpen ? "white" : "none",
        borderTopColor: isOpen ? "none" : "white"
      }
    }
  };
};

const iconCss = {
  marginTop: -5,
  textAlign: "center",
  fontSize: 24,
  color: Colors.background.DARK_TEAL
};

const triangleCss = isOpen => {
  return {
    width: 20,
    minWidth: 20,
    textAlign: "center",
    marginRight: 5,

    ".triangle": {
      display: "inline-block",
      border: "5px solid transparent",
      borderBottom: isOpen ? `5px solid ${Colors.text.HIT_GRAY}` : "none",
      borderTop: isOpen ? "none" : `5px solid ${Colors.text.HIT_GRAY}`,
      height: 5,
      verticalAlign: "middle",
      width: 10
    }
  };
};

const childLinksCss = {
  margin: "-5px 0 10px 0"
};

const mobileGroupCss = {
  position: "relative",

  ".mobile-menu": {
    backgroundColor: "#2c3948",
    display: "none",
    marginTop: 3,
    position: "fixed",
    left: 48,
    zIndex: 999,

    ".triangle": {
      position: "absolute",
      top: 15,
      left: -10,
      border: "5px solid transparent",
      borderRight: "5px solid #2c3948",
      height: 5,
      width: 10
    }
  },

  ".nav-item": {
    paddingLeft: "10px !important"
  },

  a: {
    textDecoration: "none !important"
  },

  "&:hover": {
    ".mobile-menu": {
      display: "block"
    }
  }
};

// Collapsible group of links displayed in primary nav bar
class NavGroup extends Component {
  constructor(props) {
    super(props);
    this.desktopGroup = this.desktopGroup.bind(this);
    this.mobileGroup = this.mobileGroup.bind(this);
  }

  desktopGroup() {
    const {
      t,
      groupKey,
      title,
      icon,
      children,
      toggleOpenGroup,
      isOpen,
      sidebarMinimize
    } = this.props;

    return (
      <div css={desktopGroupCss}>
        <div
          css={titleCss(isOpen, sidebarMinimize)}
          onClick={() => toggleOpenGroup(groupKey)}
          data-qa={`navigation-group-${groupKey}`}
        >
          <div
            css={iconCss}
            className="navicon"
            style={{
              width: 34,
              minWidth: 34,
              minHeight: 34
            }}
          >
            {icon}
          </div>
          <strong>{t(title)}</strong>
          <div css={triangleCss(isOpen, sidebarMinimize)}>
            <i className="triangle" />
          </div>
        </div>
        <div
          className="child-links"
          css={childLinksCss}
          style={{ display: isOpen ? "block" : "none" }}
        >
          {children}
        </div>
      </div>
    );
  }

  mobileGroup() {
    const { t, title, icon, children } = this.props;

    return (
      <div css={mobileGroupCss}>
        <div className="mobile-menu">
          <i className="triangle" />
          <div css={{ padding: 10 }}>
            <strong>{t(title)}</strong>
          </div>
          <div css={childLinksCss}>{children}</div>
        </div>
        <div
          css={iconCss}
          style={{
            width: 48,
            height: 48,
            minWidth: 48,
            minHeight: 48,
            lineHeight: "48px"
          }}
        >
          {icon}
        </div>
      </div>
    );
  }

  render() {
    const { sidebarMinimize } = this.props;
    return sidebarMinimize ? this.mobileGroup() : this.desktopGroup();
  }
}

NavGroup.propTypes = {
  groupKey: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleOpenGroup: PropTypes.func,
  sidebarMinimize: PropTypes.bool
};
export default withTranslation()(NavGroup);
