/** @jsx jsx */
// eslint-disable-next-line
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";
import { Tab } from "react-tabs";

import EndToEndTabIcon from "./EndToEndTabIcon";
import { MadChicletCSS } from "../../components/chiclets";
import { tabStyle } from "./tabStyles";
import { Translation } from "react-i18next";
import { ShowForSmallAndUp } from "../responsive";

const LegTabLabel = styled.span({
  display: "block",
  fontSize: 14,
  marginLeft: 10
});

/* omits label text entirely if screen too small */
const ResponsiveLegTabLabel = ({ children }) => {
  return (
    <ShowForSmallAndUp>
      <LegTabLabel>{children}</LegTabLabel>
    </ShowForSmallAndUp>
  );
};

ResponsiveLegTabLabel.propTypes = {
  children: PropTypes.node
};

export const endToEndTab = (showPrevTabPageButton, eventHandler) => {
  return (
    <Tab
      style={{ ...tabStyle, marginRight: showPrevTabPageButton ? 40 : 0 }}
      key="end-to-end"
      onClick={evt => eventHandler(null, "SELECT_LEG")}
      data-qa="tab-button-end-to-end"
    >
      <EndToEndTabIcon />
      <Translation ns="components">
        {t => (
          <ResponsiveLegTabLabel>
            {t("components:End to End")}
          </ResponsiveLegTabLabel>
        )}
      </Translation>
    </Tab>
  );
};

const isTabVisible = (index, tabPageIndex, tabPageSize) => {
  const pageStart =
    tabPageIndex === 0 ? tabPageIndex : tabPageIndex * tabPageSize;
  const pageEnd = pageStart + tabPageSize - 1;

  return index >= pageStart && index <= pageEnd;
};

export const legTabs = (
  childSummaries,
  childDetails,
  tabPageIndex,
  tabPageSize,
  eventHandler,
  isShipmentDetails
) => {
  return childSummaries.map((summary, i) => {
    const shipment = childDetails[summary.shipment_id];
    const isVisible = isTabVisible(i, tabPageIndex, tabPageSize);
    const display = isVisible ? "flex" : "none";
    return shipment ? (
      <Tab
        style={{ ...tabStyle, display: display }}
        key={summary.shipment_id}
        onClick={evt =>
          eventHandler(
            isShipmentDetails ? shipment.id : shipment.creator_shipment_id,
            "SELECT_LEG"
          )
        }
        data-qa={`tab-button-leg${i + 1}`}
      >
        <MadChicletCSS
          shipmentMode={shipment.mode_name}
          stopMode={shipment.mode_name}
          width={20}
          height={20}
        />
        <ResponsiveLegTabLabel>
          <Translation ns="components">
            {t => (
              <React.Fragment>
                {t("components:Leg")} {i + 1}
              </React.Fragment>
            )}
          </Translation>
        </ResponsiveLegTabLabel>
      </Tab>
    ) : null;
  });
};
