/** @jsx jsx */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { jsx } from "@emotion/core";
import Loader from "react-loader";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Colors from "../../styles/colors";
import { FlexColDiv } from "../../styles/container-elements";
import { MediaQueries } from "../../components/responsive";
import SearchBarContainer from "../fv-search/FinVehicleSearchBarContainer";
import FiltersContainer from "../fv-search/FinVehicleSearchFiltersContainer";
import SavedSearchContainer from "../fv-search/FinVehicleSavedSearchContainer";
import VinSearchResultsTable from "./vin-search-styled-components/vin-search-results-table";
import DataHeader from "../fv-dashboard/fv-dashboard-styled-components/DataHeader";
import MixpanelUtils from "../../trackers/mixpanel";

const VinSearchView = props => {
  const {
    savedSearch,
    loadSavedSearch,
    fetchWatchedVins,
    solutionId,
    toggleShowFilters,
    watchedVinArray,
    pushVinDetailsView,
    setWatchEntity,
    vinSearchResults,
    isLoading,
    showFilters,
    page,
    pageSize,
    totalPages,
    setPagination,
    setSort,
    totalEntities,
    isExporting,
    exportFailed
  } = props;

  const { t } = useTranslation("fv-vin-search");
  const vinSearchData = isLoading ? [] : vinSearchResults;
  const [watchedVins, setWatchedVins] = useState([]);

  useEffect(() => {
    MixpanelUtils.track("Viewed Page: VIN / Search");

    fetchWatchedVins(solutionId);
    toggleShowFilters(true);

    // If a saved search was previously selected, automatically preload its query values
    if (savedSearch) {
      loadSavedSearch(savedSearch);
    }
  }, [
    solutionId,
    fetchWatchedVins,
    toggleShowFilters,
    savedSearch,
    loadSavedSearch
  ]);

  useEffect(() => {
    if (watchedVinArray) {
      setWatchedVins(watchedVinArray.map(w => w.id));
    }
  }, [watchedVinArray]);

  const cellClickHandler = (state, rowInfo, column, instance) => {
    // Prevent navigation if clicking in "watch" checkbox cell
    if (column.id === "watch") {
      return;
    }

    // Navigate to VIN Details when clicking row
    pushVinDetailsView(rowInfo.original.id);
  };

  const toggleWatch = vin => {
    let newWatchedVins = [...watchedVins];

    if (!newWatchedVins.includes(vin)) {
      // VIN checkbox is unchecked - watch it
      newWatchedVins.push(vin);
      setWatchEntity(solutionId, vin, true);
    } else {
      // VIN checkbox is checked - unwatch it
      newWatchedVins = newWatchedVins.filter(v => v !== vin);
      setWatchEntity(solutionId, vin, false);
    }

    setWatchedVins(newWatchedVins);
  };

  return (
    <FlexColDiv>
      <Loader loaded={!isExporting} />
      <FlexColDiv
        css={{
          padding: "20px 20px 0 20px"
        }}
      >
        <SearchBarContainer
          isShowingFilters={showFilters}
          toggleShowFilters={() => toggleShowFilters(!showFilters)}
        />
        <FiltersContainer
          show={showFilters}
          backgroundColor={Colors.background.GRAY}
          sectionStyle={{ paddingRight: "12em" }}
        />
      </FlexColDiv>
      <div
        css={{
          backgroundColor: "white",
          padding: "0.5em 1em 1em 1em"
        }}
      >
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            [MediaQueries.smallAndUp]: {
              flexDirection: "row",
              width: "unset"
            }
          }}
        >
          <DataHeader total={totalEntities || 0} />
          <SavedSearchContainer />
        </div>

        {exportFailed ? (
          <Alert variant="danger">{t("fv-vin-search:Export Failed")}</Alert>
        ) : null}

        <VinSearchResultsTable
          data={vinSearchData}
          cellClickHandler={cellClickHandler}
          isLoading={isLoading}
          page={page}
          pageSize={pageSize}
          totalPages={totalPages}
          setPagination={setPagination}
          setSort={setSort}
          solutionId={solutionId}
          watchedVins={watchedVins}
          toggleWatch={toggleWatch}
        />
      </div>
    </FlexColDiv>
  );
};

VinSearchView.propTypes = {
  exportFailed: PropTypes.bool.isRequired,
  fetchWatchedVins: PropTypes.func.isRequired,
  isExporting: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadSavedSearch: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  pushVinDetailsView: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  setWatchEntity: PropTypes.func.isRequired,
  showFilters: PropTypes.bool.isRequired,
  solutionId: PropTypes.string.isRequired,
  toggleShowFilters: PropTypes.func.isRequired,
  totalEntities: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  vinSearchResults: PropTypes.array.isRequired,
  watchedVinArray: PropTypes.array.isRequired
};

export default VinSearchView;
