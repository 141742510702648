/** @jsx jsx */
import { jsx } from "@emotion/core";

import PropTypes from "prop-types";

import Colors from "../../../styles/colors";

const propTypes = {
  total: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired
};

const TableMetaTotalRecords = ({ total, label }) => {
  return (
    <div
      className="mt-3"
      style={{
        fontWeight: 600,
        color: Colors.background.GRAY_BLUE
      }}
    >{`${total} ${label}`}</div>
  );
};

TableMetaTotalRecords.propTypes = propTypes;

export default TableMetaTotalRecords;
