import { createSelector } from "reselect";
import _ from "lodash";
import { ROUTER_LOCATION_KEY } from "./redux/store";

export const getCurrentLocation = state => state[ROUTER_LOCATION_KEY];

export const getCurrentRouteMapEntry = createSelector(
  getCurrentLocation,
  location => _.get(location.routesMap, location.type, {})
);

export const getClosestVisibleRouteMapEntry = createSelector(
  getCurrentRouteMapEntry,
  entry => {
    if (!entry.hidden) {
      return entry;
    }
    if (entry.navProxyRoute) {
      return entry.navProxyRoute();
    }
    return entry;
  }
);
