/*
Blue Newt Software, Inc. Confidential Unpublished Copyright
(c) 2008-2018 Blue Newt Software, Inc., All Rights Reserved.

NOTICE: All information contained herein is, and remains the property of Blue
Newt Software, Inc. The intellectual and technical concepts contained herein
are proprietary to Blue Newt Software, Inc. and may be covered by U.S. and
Foreign Patents, patents in process, and are protected by trade secret or
copyright law. Dissemination of this information or reproduction of this
material is strictly forbidden unless prior written permission is obtained
from Blue Newt Software, Inc. Access to the source code contained herein is
hereby forbidden to anyone except current Blue Newt Software, Inc. employees,
managers or contractors who have executed  Confidentiality and Non-disclosure
agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended
publication or disclosure of this source code, which includes  information
that is confidential and/or proprietary, and is a trade secret, of Blue Newt
Software, Inc. ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC
PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT
THE EXPRESS WRITTEN CONSENT OF Blue Newt Software, Inc. IS STRICTLY
PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND INTERNATIONAL TREATIES.
THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES
NOT CONVEY OR IMPLY ANY RIGHTS  TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS
CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT MAY DESCRIBE, IN
WHOLE OR IN PART.
*/

/*
  Combine a series of reducers by chaining them into a single reducer
  function.

  For an incoming action, the combined reducer calls each original
  reducer in order. If any reducer returns a new state (identity-wise),
  return that new state as the result of the combined reducer. Return
  the original state if none of the chained reducers updated anything.
*/
export default function chainReducers(reducers, initialState) {
  return function(state = initialState, action = {}) {
    for (let reducer of reducers) {
      const newState = reducer(state, action);
      if (newState !== state) {
        return newState;
      }
    }
    return state;
  };
}
