// Actions
const SET_SEARCH_STR = "header/SET_SEARCH_STR";

export function setSearchStr(str) {
  return {
    type: SET_SEARCH_STR,
    searchStr: str
  };
}

const initialState = {
  searchStr: ""
};

export default function HeaderReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SEARCH_STR:
      return { ...state, searchStr: action.searchStr };
    default:
      return state;
  }
}
