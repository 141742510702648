/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";

import { connect } from "react-redux";
import styled from "@emotion/styled";
import _ from "lodash";

import { withTranslation } from "react-i18next";
import Dropdown from "react-bootstrap/Dropdown";

import { Logo } from "../../components/logos";
import fv from "../../assets/logos/fv_mark_light.svg";

import { routeForLocation } from "../../routes";
import { FlexDiv, FlexRowDiv } from "../../styles/container-elements";
import colors from "../../styles/colors";

import PageDescriptionTooltip from "./PageDescriptionTooltip";
import { setSearchStr } from "./HeaderBarState";
import {
  getActiveOrganization,
  getCurrentOrganizationId,
  getOrganizations,
  setCurrentOrganization,
  setActiveOrganization,
  getMapTypeOverride,
  setMapTypeOverride
} from "../organizations/OrganizationsState";
import { OrganizationSelect } from "../organizations/OrganizationSwitcher";
import { getAuthorization } from "../auth/AuthorizationSelectors";

const LogoText = styled.span(props => ({
  fontSize: "1.5em",
  fontWeight: "300",
  color: colors.background.GRAY_BLUE,
  ...props
}));

// DEV-1641 Matches SCAC, FV_ID
const ORG_FILTER_ATTRS = ["org_name", "scac", "fv_id"];

// Get the list of user organizations to show in the switcher dropdown
const authorizedOrganizations = (organizations, authorization) => {
  const isFvAdmin = authorization.isFvAdmin();
  const userOrgIds = authorization.organizationIds;
  let userOrganizations = organizations;

  if (!isFvAdmin && !_.isEmpty(organizations)) {
    userOrganizations = organizations.filter(o =>
      userOrgIds.includes(o.organization_id)
    );
  }

  return userOrganizations;
};

// Filter the organization list based on the user's search query
const filterOrganizations = (searchStr, organizations) => {
  let filteredOrganizations = [];

  if (searchStr != null && !_.isEmpty(organizations)) {
    const searchStrLC = searchStr.toLowerCase();
    filteredOrganizations = organizations.filter(o => {
      return (
        !o.deleted_at &&
        ORG_FILTER_ATTRS.some(
          attr =>
            !_.isNil(o[attr]) &&
            _.get(o, attr)
              .toLowerCase()
              .includes(searchStrLC)
        )
      );
    });
  }

  return filteredOrganizations;
};

class HeaderBar extends Component {
  static propTypes = {
    activeOrganization: PropTypes.object,
    currentOrganizationId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    location: PropTypes.object,
    mapTypeOverride: PropTypes.string,
    organizations: PropTypes.array,
    searchStr: PropTypes.string,
    setActiveOrganization: PropTypes.func.isRequired,
    setCurrentOrganization: PropTypes.func.isRequired,
    setMapTypeOverride: PropTypes.func.isRequired,
    setSearchStr: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  render() {
    const {
      t,
      authorization,
      activeOrganization,
      currentOrganizationId,
      location,
      organizations,
      searchStr,
      setSearchStr,
      setCurrentOrganization,
      setActiveOrganization,
      mapTypeOverride,
      setMapTypeOverride
    } = this.props;

    const currentRoute = routeForLocation(location);
    const isFvAdmin = authorization.isFvAdmin();
    const userOrganizations = authorizedOrganizations(
      organizations,
      authorization
    );
    const filteredOrganizations = filterOrganizations(
      searchStr,
      userOrganizations
    );
    const showDropdown = isFvAdmin || userOrganizations.length > 1;

    return (
      <header
        css={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          position: "relative"
        }}
      >
        <FlexDiv>
          <LogoText>
            {currentRoute ? t(currentRoute.title) : ""}
            <PageDescriptionTooltip description={t(currentRoute.description)} />
          </LogoText>
        </FlexDiv>
        <FlexDiv
          css={{
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {!showDropdown && (
            <FlexDiv
              css={{
                color: colors.formHeadingColor,
                marginRight: "1em",
                fontSize: "medium"
              }}
            >
              {t("header-bar:Organization")}:{" "}
              {activeOrganization ? activeOrganization.org_name : ""}
            </FlexDiv>
          )}
          {showDropdown && (
            <Dropdown id="organization-selector">
              <Dropdown.Toggle
                as={FlexRowDiv}
                css={{
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  border: "1px solid transparent",
                  borderRadius: 5,
                  padding: "2px 10px",
                  ":hover": {
                    border: "1px solid #ccc",
                    backgroundColor: "#fff"
                  }
                }}
              >
                <FlexDiv
                  css={{
                    color: colors.formHeadingColor,
                    marginRight: 10,
                    fontSize: "medium"
                  }}
                >
                  {activeOrganization && activeOrganization.org_name
                    ? activeOrganization.org_name
                    : t("header-bar:Organization")}{" "}
                </FlexDiv>
                <div
                  style={{
                    backgroundColor: "#2c3948",
                    borderRadius: 32,
                    cursor: "pointer",
                    width: 32,
                    height: 32,
                    textAlign: "center"
                  }}
                >
                  <Logo src={fv} style={{ height: 32, margin: "0 auto" }} />
                </div>
                <span style={{ marginRight: 3 }} className="caret" />
              </Dropdown.Toggle>

              <Dropdown.Menu rootCloseEvent="click">
                <OrganizationSelect
                  t={t}
                  fvAdmin={isFvAdmin}
                  currentOrganizationId={parseInt(currentOrganizationId, 10)}
                  setCurrentOrganization={setCurrentOrganization}
                  setActiveOrganization={setActiveOrganization}
                  searchStr={searchStr}
                  setSearchStr={setSearchStr}
                  organizations={filteredOrganizations}
                  mapTypeOverride={mapTypeOverride}
                  setMapTypeOverride={setMapTypeOverride}
                />
              </Dropdown.Menu>
            </Dropdown>
          )}
        </FlexDiv>
      </header>
    );
  }
}

HeaderBar.propTypes = {
  t: PropTypes.func,
  authorization: PropTypes.object,
  activeOrganization: PropTypes.object,
  currentOrganizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  location: PropTypes.object,
  organizations: PropTypes.array,
  searchStr: PropTypes.string,
  setSearchStr: PropTypes.func,
  setCurrentOrganization: PropTypes.func,
  setActiveOrganization: PropTypes.func,
  mapTypeOverride: PropTypes.string,
  setMapTypeOverride: PropTypes.func
};

function mapStateToProps(state) {
  return {
    ...state.header,
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    currentOrganizationId: getCurrentOrganizationId(state),
    organizations: getOrganizations(state),
    mapTypeOverride: getMapTypeOverride(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentOrganization: (id, authorization) =>
      dispatch(setCurrentOrganization(id, authorization)),
    setActiveOrganization: org => dispatch(setActiveOrganization(org)),
    setMapTypeOverride: mapType => dispatch(setMapTypeOverride(mapType)),
    setSearchStr: str => dispatch(setSearchStr(str))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["header-bar", "map"])(HeaderBar));
