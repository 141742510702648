import axios from "axios";
import apiUrl from "../../api-url";

const STORE_MOUNT_POINT = "profile";

// URLS
const USERS_URL = apiUrl(`/iam/users`);

// Actions
const SELECT_SAVED_SEARCH = "profile/SELECT_SAVED_SEARCH";
const CHANGE_PASSWORD = "profiles/CHANGE_PASSWORD";
const CHANGE_PASSWORD_SUCCEEDED = "profiles/CHANGE_PASSWORD_SUCCEEDED";
const CHANGE_PASSWORD_FAILED = "profiles/CHANGE_PASSWORD_FAILED";
const RESET_PASSWORD_FORM = "profiles/";

// Action creators

export function selectSavedSearch(item) {
  return {
    type: SELECT_SAVED_SEARCH,
    searchItem: item
  };
}

export function changePassword(payload, user_id) {
  return function(dispatch) {
    dispatch({ type: CHANGE_PASSWORD, payload });

    const url = `${USERS_URL}/${user_id}/change-password`;

    return axios
      .patch(url, payload)
      .then(resp => {
        dispatch({ type: CHANGE_PASSWORD_SUCCEEDED });
      })
      .catch(error => dispatch({ type: CHANGE_PASSWORD_FAILED, error }));
  };
}

export function resetPasswordForm() {
  return {
    type: RESET_PASSWORD_FORM
  };
}

// Initial state

export const initialState = {
  passwordStatus: null,
  selectedSavedSearch: {}
};

function ProfileReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SELECT_SAVED_SEARCH:
      return Object.assign({}, state, {
        selectedSavedSearch: action.searchItem
      });
    case CHANGE_PASSWORD_SUCCEEDED:
      return Object.assign({}, state, {
        passwordStatus: "CHANGED"
      });
    case CHANGE_PASSWORD_FAILED:
      return Object.assign({}, state, {
        passwordStatus: "ERROR"
      });
    case RESET_PASSWORD_FORM:
      return Object.assign({}, state, {
        passwordStatus: null
      });
    default:
      return state;
  }
}

// Selectors
const getSelectedSavedSearch = state =>
  state[STORE_MOUNT_POINT].selectedSavedSearch;

// export default ProfileReducer;
export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    changePassword,
    resetPasswordForm,
    selectSavedSearch
  },
  selectors: {
    getSelectedSavedSearch
  },
  reducer: ProfileReducer
};
