/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
// import colors from "../../../styles/colors";
// import { TiStarOutline, TiStarFullOutline } from "react-icons/ti";

import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class DataHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saved: false
    };

    this.saveHandler = this.saveHandler.bind(this);
  }

  saveHandler() {
    const { saveSearchHandler } = this.props;
    const { saved } = this.state;
    saveSearchHandler && saveSearchHandler();
    this.setState({
      saved: !saved
    });
  }

  render() {
    const { t, total } = this.props;
    // const { saved } = this.state;
    // const starIcon = saved ? (
    // 	<TiStarFullOutline
    // 		style={{
    // 			fontSize: "1.8em",
    // 			marginLeft: "0.2em",
    // 			color: colors.text.YELLOW
    // 		}}
    // 	/>
    // ) : (
    // 	<TiStarOutline
    // 		style={{
    // 			fontSize: "1.8em",
    // 			marginLeft: "0.2em"
    // 		}}
    // 	/>
    // );
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: 20
        }}
      >
        <span style={{ fontSize: 16 }}>
          <span style={{ fontWeight: 600 }}>
            {total.toLocaleString(undefined)}
          </span>{" "}
          {t("fv-dashboard:Total VINs")}
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <div
						onClick={this.saveHandler}
						css={{
							color: colors.text.LIGHTER_GRAY,
							fontSize: 12.5,
							fontStyle: "italic",
							textDecoration: "underline",
							cursor: "pointer",
							display: "flex",
							alignItems: "center",
							marginRight: "1em"
						}}
					>
						Save Current Search
						{starIcon}
					</div> */}
        </div>
      </div>
    );
  }
}

DataHeader.propTypes = {
  total: PropTypes.number,
  savedSearch: PropTypes.bool,
  saveSearchHandler: PropTypes.func,
  savedSearchList: PropTypes.array
};

export default withTranslation(["fv-dashboard"])(DataHeader);
