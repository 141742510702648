import { connect } from "react-redux";
import VinSearchView from "./VinSearchView";
import FinVehicleSearchBarState from "../fv-search/FinVehicleSearchBarState";
import FinVehicleSavedSearchState from "../fv-search/FinVehicleSavedSearchState";
import FinVehicleEntitiesState from "../fv-dashboard/FinVehicleEntitiesState";
import FinVehicleEntityDetailsState from "../fv-vin-details/FinVehicleEntityDetailsState";
import { getSolutionId } from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    savedSearch: FinVehicleSearchBarState.selectors.getSelectedSavedSearch(
      state
    ),
    vinSearchResults: FinVehicleSearchBarState.selectors.getSearchResults(
      state
    ),
    showFilters: FinVehicleSearchBarState.selectors.getShowAdvancedSearch(
      state
    ),
    isLoading: FinVehicleSearchBarState.selectors.getIsLoading(state),
    page: FinVehicleSearchBarState.selectors.getPage(state),
    pageSize: FinVehicleSearchBarState.selectors.getPageSize(state),
    totalPages: FinVehicleSearchBarState.selectors.getTotalPages(state),
    totalEntities: FinVehicleSearchBarState.selectors.getTotalEntities(state),
    solutionId: getSolutionId(state),
    isExporting: FinVehicleSearchBarState.selectors.getIsExporting(state),
    exportFailed: FinVehicleSearchBarState.selectors.getExportFailed(state),
    watchedVinArray: FinVehicleEntitiesState.selectors.getWatchedVins(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSavedSearch: savedSearch =>
      dispatch(
        FinVehicleSavedSearchState.actionCreators.loadSavedSearch(savedSearch)
      ),
    pushVinDetailsView: entityId =>
      dispatch({ type: "VIN_DETAILS", payload: { entity_id: entityId } }),
    toggleShowFilters: showFilters =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.toggleShowFilters(showFilters)
      ),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize
        )
      ),
    setSort: (solutionId, sortColumn, reverse) =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.setSort(
          solutionId,
          sortColumn,
          reverse
        )
      ),
    fetchWatchedVins: solutionId =>
      dispatch(
        FinVehicleEntitiesState.actionCreators.fetchWatchedVins(solutionId)
      ),
    setWatchEntity: (solutionId, entityId, watch) =>
      dispatch(
        FinVehicleEntityDetailsState.actionCreators.setWatchEntity(
          solutionId,
          entityId,
          watch
        )
      )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VinSearchView);
