import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { withTranslation } from "react-i18next";

import { updateOrganization } from "../OrganizationsState";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  SelectInput,
  TextInput
} from "../../../components/modal-elems";
import { FlexRowDiv } from "../../../styles/container-elements";
import { isValidEmail } from "../../../utils/validation-utils";

const isValidForm = ({ name, email, selectedType }) => {
  return email && isValidEmail(email) && name && selectedType ? true : false;
};

const initOrg = {
  id: null,
  name: "",
  selectedType: null,
  email: "",
  freightVerifyId: "",
  contactName: "",
  phoneNumber: "",
  scac: "",
  base64Image: null
};

const EditOrganizationModal = ({
  hide,
  organization,
  organizationTypes,
  show,
  t,
  updateOrganization
}) => {
  const [org, setOrg] = useState(initOrg);

  const inputHandler = value => {
    setOrg(prevState => {
      return { ...prevState, ...value };
    });
  };

  const orgTypeOptions = useMemo(
    () =>
      organizationTypes.map(ot => {
        return {
          value: ot.id,
          label: t(`organizations:${ot.name}`)
        };
      }),
    [organizationTypes, t]
  );

  useMemo(
    () =>
      setOrg(prevState => {
        const select = organization
          ? orgTypeOptions.find(type => type.label === organization.org_type)
          : null;

        return {
          ...prevState,
          id: organization ? organization.organization_id : null,
          name:
            organization && organization.org_name ? organization.org_name : "",
          email:
            organization && organization.email_address
              ? organization.email_address
              : "",
          freightVerifyId:
            organization && organization.fv_id ? organization.fv_id : "",
          contactName:
            organization && organization.contact_name
              ? organization.contact_name
              : "",
          phoneNumber:
            organization && organization.phone_no ? organization.phone_no : "",
          scac: organization && organization.scac ? organization.scac : "",
          selectedType: select
        };
      }),
    [organization, orgTypeOptions]
  );

  const onImageDrop = image => {
    if (image && image[0] instanceof Blob) {
      let reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = () => {
        inputHandler({ base64Image: reader.result });
      };
      reader.onerror = error => {
        console.error("Error: ", error);
      };
    }
  };

  const isCarrier =
    org.selectedType && org.selectedType.label === "Carrier" ? true : false;

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <ModalHeader title={t("organizations:Edit Organization")} />
      <ModalBody>
        <FlexRowDiv>
          <TextInput
            label={t("organizations:Organization Name")}
            value={org.name}
            changeHandler={inputHandler}
            prop="name"
          />
          <SelectInput
            label={t("organizations:Type")}
            options={orgTypeOptions}
            value={org.selectedType}
            changeHandler={inputHandler}
            prop="selectedType"
            multi={false}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <TextInput
            label={t("organizations:Email")}
            value={org.email}
            changeHandler={inputHandler}
            prop="email"
          />
          <TextInput
            label={t("organizations:FreightVerify ID")}
            value={org.freightVerifyId}
            changeHandler={inputHandler}
            prop="freightVerifyId"
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <TextInput
            label={t("organizations:Contact Name")}
            value={org.contactName}
            changeHandler={inputHandler}
            prop="contactName"
          />
          <TextInput
            label={t("organizations:Phone Number")}
            value={org.phoneNumber}
            changeHandler={inputHandler}
            prop="phoneNumber"
          />
        </FlexRowDiv>
        {isCarrier ? (
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <TextInput
              label={t("organizations:SCAC")}
              value={org.scac}
              changeHandler={inputHandler}
              prop="scac"
            />
          </FlexRowDiv>
        ) : null}
        <FlexRowDiv style={{ marginTop: "1.5em" }}>
          <ImageUploader
            withIcon={true}
            withPreview={true}
            singleImage={true}
            buttonText={t("organizations:Choose images")}
            label={t("organizations:Max file size 5mb, accepted jpg|gif|png")}
            fileSizeError={t("organizations:file size is too big")}
            fileTypeError={t("organizations:is not supported file extension")}
            onChange={onImageDrop}
            imgExtension={[".png"]}
            maxFileSize={5242880}
          />
        </FlexRowDiv>
      </ModalBody>
      <ModalFooter>
        <Button
          variant="light"
          style={{ marginRight: ".5em" }}
          onClick={() => {
            hide();
          }}
        >
          {t("organizations:Cancel")}
        </Button>
        <Button
          variant="success"
          disabled={!isValidForm(org)}
          onClick={() => {
            const payload = {
              name: org.name,
              email_address: org.email,
              fv_id: org.freightVerifyId,
              contact_name: org.contactName,
              phone_no: org.phoneNumber,
              organization_profile_id: org.selectedType
                ? organizationTypes[org.selectedType.value].profile_id
                : 0,
              logo: org.base64Image,
              scac: org.scac
            };

            updateOrganization(org.id, payload);
          }}
        >
          {t("organizations:Update")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

EditOrganizationModal.propTypes = {
  hide: PropTypes.func.isRequired,
  organization: PropTypes.object,
  organizationTypes: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  updateOrganization: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    actionStatus: state.organizations.actionStatus,
    organizationTypes: state.organizations.organizationTypes
      ? state.organizations.organizationTypes
      : []
  };
}

const mapDispatchToProps = {
  updateOrganization
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["organizations"])(EditOrganizationModal));
