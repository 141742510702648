/** @jsx jsx */
// eslint-disable-next-line
import PropTypes from "prop-types";
import React from "react";
import { jsx } from "@emotion/core";
import _ from "lodash";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Colors from "../../styles/colors";
import LinkButton from "../../vendor/signal-widgets/components/link-button";
import UpdatesTable from "./UpdatesTable";
import CoordinatesTable from "../../components/CoordinatesTable";
import DetailsTable from "../../components/DetailsTable";
import StopsTable from "./shipment-detail-styled-components/StopsTable";
import { isCarrier } from "../../routes";
import { Features } from "../auth/Authorization";
import { getAuthorization } from "../auth/AuthorizationSelectors";
import { useIsMediumAndDown } from "../../components/responsive";

const tabStyle = {
  display: "flex",
  flex: 1,
  justifyContent: "space-around",
  alignItems: "center",
  padding: "0.5em",
  color: Colors.background.GRAY_BLUE,
  marginRight: "0.5em"
};

const tabPanelStyle = {
  border: "1px solid #aaa",
  borderRadius: "0 0 3px 3px"
};

const PartsTable = ({ parts, t }) => {
  // Combine ASN parts and part values into one row for each unique part name
  let combinedParts = {};
  parts.forEach(p => {
    const partType = p.qualifier;
    /* H1-607 change the partName index to account for names that have spaces,
      add name to combined parts to preserve that original name for display */
    const partName = p.name.replace(/\s/g, "");
    if (partName in combinedParts) {
      combinedParts[partName][partType] = p.quantity;
    } else {
      combinedParts[partName] = { [partType]: p.quantity, name: p.name };
    }
  });

  const rows = Object.keys(combinedParts).map((p, i) => {
    const partValues = combinedParts[p];
    /*
    DEV-609 Parts with the qualifier 'part' should
    be in the Planned column, and part with the
    qualifier 'asnpart' should be in the Actual column.
    */
    return (
      <tr key={i} data-qa="rows-parts-table">
        <td>{partValues.name}</td>
        <td>{partValues.part}</td>
        <td>{partValues.asnpart}</td>
      </tr>
    );
  });

  return (
    <DetailsTable
      headers={[t("Part"), t("Planned"), t("Actual")]}
      rows={rows}
    />
  );
};

PartsTable.propTypes = {
  parts: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

const getReferenceLabelFromQualifier = (qualifier, t) => {
  switch (qualifier) {
    case "CN":
      return t("Pro Number");
    case "order_number":
      return t("Order Number");
    default:
      return qualifier;
  }
};

const ReferencesTable = ({ mode, references, equipment_type_ng, t }) => {
  const railReferences = [
    "equip_initial",
    "equipment_number",
    "ITEM",
    "BM",
    "equipment_description_code",
    "routing_sequence",
    "waybill_sender",
    "CN"
  ];

  const referencesToRemove = ["part", "asnpart", "SCAC", "CARRIER"];

  /* DEV-1635 LTL references are handled like Truck */
  let rows = references
    .filter(r =>
      mode === "Rail"
        ? _.includes(railReferences, r.qualifier)
        : !_.includes(referencesToRemove, r.qualifier)
    )
    .sort((r1, r2) => {
      let qualifier1 = r1.qualifier.toLowerCase();
      let qualifier2 = r2.qualifier.toLowerCase();
      let createdAt1 = r1.created_at.valueOf();
      let createdAt2 = r2.created_at.valueOf();

      if (qualifier1 < qualifier2) {
        return -1;
      }
      if (qualifier1 > qualifier2) {
        return 1;
      }

      if (createdAt1 < createdAt2) {
        return -1;
      }
      if (createdAt1 > createdAt2) {
        return 1;
      }

      return 0;
    })
    .map((r, i) => {
      return (
        <tr key={i} data-qa="rows-references-table">
          <td>{getReferenceLabelFromQualifier(r.qualifier, t)}</td>
          <td>{r.value}</td>
        </tr>
      );
    });

  if (equipment_type_ng && equipment_type_ng.length > 0) {
    rows.push(
      <tr key="ET">
        <td>{t("Equipment Type")}</td>
        <td>{equipment_type_ng}</td>
      </tr>
    );
  }

  return <DetailsTable headers={[t("Qualifier"), t("Value")]} rows={rows} />;
};

ReferencesTable.propTypes = {
  equipment_type_ng: PropTypes.string,
  mode: PropTypes.string,
  references: PropTypes.array,
  t: PropTypes.func.isRequired
};

const VINsTable = ({ vins, t }) => {
  const dispatch = useDispatch();
  const authorization = useSelector(getAuthorization);
  const isHotLinkEnabled = authorization.isAuthorized(
    [],
    [Features.FINISHED_VEHICLE]
  );
  const pushVinDetailsPageHandler = vin => () =>
    dispatch({ type: "VIN_DETAILS", payload: { entity_id: vin.name } });
  const rows = vins.map(vin => {
    const vinCellContents = isHotLinkEnabled ? (
      <LinkButton
        style={{
          color: Colors.linkText,
          cursor: "pointer",
          fontSize: "small"
        }}
        onClick={pushVinDetailsPageHandler(vin)}
      >
        {vin.name}
      </LinkButton>
    ) : (
      <React.Fragment>{vin.name}</React.Fragment>
    );
    return (
      <tr key={`shipment-vin-${vin.name}`}>
        <td>{vin.qualifier}</td>
        <td>{vinCellContents}</td>
      </tr>
    );
  });
  return <DetailsTable headers={[t("Name"), t("VIN")]} rows={rows} />;
};

VINsTable.propTypes = {
  t: PropTypes.func.isRequired,
  vins: PropTypes.array
};

const hasParts = shipment =>
  shipment.part_numbers != null && shipment.part_numbers.length > 0;
const hasRefs = shipment =>
  shipment.shipment_references != null &&
  shipment.shipment_references.length > 0;
const hasVins = shipment => shipment.vins && shipment.vins.length > 0;

const ShipmentDetailTabs = ({
  shipment,
  selectCoordinate,
  clearCoordinate,
  selectedCoordinate,
  organization
}) => {
  const { t } = useTranslation("shipment-details");

  /* H1-1057 Only show Parts tab on shipment details page if user’s organization matches the shipper organization on the shipment */
  /* H1-1104 Add same check for References tab */
  const isCreator = shipment.created_by_org_id === organization.organization_id;
  const isCreatorOrCarrier = isCreator || isCarrier;

  let appliedTabStyles = { ...tabStyle };
  if (useIsMediumAndDown()) {
    appliedTabStyles.fontSize = "0.8em";
  }

  // Used to determine if we are switching from this tab in <Tabs/>'s onSelect
  const coordinatesTabTitle = t("Coordinates");

  return (
    <Tabs
      style={{
        margin: "1em",
        marginTop: "3em",
        flex: 1
      }}
      onSelect={(index, lastIndex, e) => {
        // If we are moving away from the coordinate tab
        if (e.target.textContent !== coordinatesTabTitle) {
          clearCoordinate();
        }
      }}
    >
      <TabList
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 0,
          border: "none"
        }}
      >
        <Tab style={appliedTabStyles} data-qa="tab-button-updates">
          {t("Updates")}
        </Tab>
        <Tab style={appliedTabStyles} data-qa="tab-button-stops">
          {t("Stops")}
        </Tab>
        <Tab style={appliedTabStyles} data-qa="tab-button-coordinates">
          {coordinatesTabTitle}
        </Tab>
        {hasParts(shipment) && isCreatorOrCarrier ? (
          <Tab style={appliedTabStyles} data-qa="tab-button-parts">
            {t("Parts")}
          </Tab>
        ) : null}
        {hasVins(shipment) && (
          <Tab style={appliedTabStyles} data-qa="tab-button-vins">
            {t("VINs")}
          </Tab>
        )}
        {hasRefs(shipment) && isCreatorOrCarrier && (
          <Tab
            style={{ ...appliedTabStyles, marginRight: 0 }}
            data-qa="tab-button-references"
          >
            {t("References")}
          </Tab>
        )}
      </TabList>

      <TabPanel style={tabPanelStyle} data-qa="panel-updates">
        <UpdatesTable
          statuses={shipment.shipment_statuses}
          exceptions={shipment.shipment_exceptions}
          organization={organization}
        />
      </TabPanel>

      <TabPanel style={tabPanelStyle} data-qa="panel-stops">
        <StopsTable
          stops={shipment.shipment_stops}
          mode={shipment.mode_name}
          references={shipment.shipment_references}
          active_exceptions_ng={shipment.active_exceptions_ng}
        />
      </TabPanel>

      <TabPanel style={tabPanelStyle} data-qa="panel-coordinates">
        <CoordinatesTable
          coords={
            shipment.current_location ? shipment.current_location.updates : []
          }
          selectCoordinate={selectCoordinate}
          clearCoordinate={clearCoordinate}
          selectedCoordinate={selectedCoordinate}
        />
      </TabPanel>

      {hasParts(shipment) && isCreatorOrCarrier ? (
        <TabPanel style={tabPanelStyle} data-qa="panel-parts">
          <PartsTable parts={shipment.part_numbers} t={t} />
        </TabPanel>
      ) : null}
      {hasVins(shipment) && (
        <TabPanel style={tabPanelStyle} data-qa="panel-vins">
          <VINsTable vins={shipment.vins} t={t} />
        </TabPanel>
      )}
      {hasRefs(shipment) && isCreatorOrCarrier ? (
        <TabPanel style={tabPanelStyle} data-qa="panel-references">
          <ReferencesTable
            references={shipment.shipment_references}
            mode={shipment.mode_name}
            equipment_type_ng={shipment.equipment_type_ng}
            t={t}
          />
        </TabPanel>
      ) : null}
    </Tabs>
  );
};

ShipmentDetailTabs.propTypes = {
  clearCoordinate: PropTypes.func.isRequired,
  organization: PropTypes.object,
  selectCoordinate: PropTypes.func.isRequired,
  selectedCoordinate: PropTypes.object,
  shipment: PropTypes.object
};

export default ShipmentDetailTabs;
