/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { FaClone } from "react-icons/fa";
import Colors from "../../../styles/colors";

const copyToClipboard = require("copy-text-to-clipboard");

const descriptionCss = {
  color: Colors.text.GRAY,
  margin: "1em 0 0.5em 0"
};

const wrapperCss = {
  marginBottom: "1em",
  position: "relative"
};

const copyCss = {
  background: "transparent",
  border: "none",
  color: Colors.highlight.BRIGHT_BLUE,
  position: "absolute",
  top: "1em",
  right: "1em",
  ":hover": {
    color: "white"
  },
  span: {
    fontStyle: "italic",
    marginRight: "0.5em",
    textDecoration: "underline"
  }
};

const sampleCss = {
  backgroundColor: Colors.background.DARK_GRAY,
  border: "none",
  boxShadow: "inset 2px 2px 5px rgba(0, 0, 0, 0.05)",
  color: "white",
  padding: "1em",
  minHeight: "4em",
  ".key": {
    color: Colors.highlight.BRIGHT_BLUE
  },
  ".string": {
    color: Colors.highlight.ORANGE
  },
  ".number": {
    color: Colors.highlight.GREEN
  },
  ".boolean": {
    color: Colors.highlight.LIGHT_BLUE
  },
  ".null": {
    color: Colors.highlight.LIGHT_BLUE
  }
};

const syntaxHighlightJson = codeString => {
  const json = codeString
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function(match) {
      let cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
};

const CodeSample = props => {
  const { t, description, code } = props;

  // If it's an object, we can treat it as JSON
  // Otherwise, assume it's XML
  // Also, XML that we receive is already formatted
  // We may need to explicitly introduce formatting in the future
  const isJson = typeof code === "object";

  // Contains the string to be copied by the user
  let codeString = code;
  if (isJson) {
    codeString = JSON.stringify(code, null, 2);
  }

  return (
    <div>
      {description ? <div css={descriptionCss}>{description}</div> : null}
      <div css={wrapperCss}>
        <button
          css={copyCss}
          onClick={e => {
            copyToClipboard(codeString);
          }}
        >
          <span>{t("documentation:Copy")}</span> <FaClone />
        </button>
        <pre css={sampleCss}>
          {/*
           * We aren't using ReactHtmlParser for XML because
           * 1. ReactHtmlParser will interpret the XML tags as HTML
           *    and puts it directly in the DOM
           * 2. We don't have the spans for syntax highlighting
           *    like the JSON will after using syntaxHightlightJson
           */}
          {isJson
            ? ReactHtmlParser(syntaxHighlightJson(codeString))
            : codeString}
        </pre>
      </div>
    </div>
  );
};

CodeSample.propTypes = {
  description: PropTypes.string,
  /** Prop can be passed an object for JSON or a string for XML */
  code: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
};
export default withTranslation(["documentation"])(CodeSample);
