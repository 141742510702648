import moment from "moment";

/**
 * Compare dates using moment.
 * @param a
 * @param b
 * @return {number}
 */
export const tableDateSort = (a, b) => {
  const dateA = moment(a.date + " " + a.time);
  const dateB = moment(b.date + " " + b.time);

  if (!dateA._isValid) {
    return 1;
  } else if (!dateB._isValid) {
    return -1;
  }
  return dateA.diff(dateB) > 0 ? 1 : -1;
};
