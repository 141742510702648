/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import PageHeader from "./documentation-styled-components/PageHeader";
import LinkPanels from "./documentation-styled-components/LinkPanels";

const apiGroupCss = {
  padding: "1em"
};

class ApiGroupView extends React.Component {
  componentDidMount() {
    const { filename, fetchApiGroup } = this.props;

    if (filename) {
      fetchApiGroup(filename);
    }

    this.groups = this.groups.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { filename, fetchApiGroup } = this.props;

    if (filename && filename !== prevProps.filename) {
      fetchApiGroup(filename);
    }
  }

  groups() {
    const { apiGroup, pushDocumentView } = this.props;
    const groups = apiGroup && apiGroup.groups ? apiGroup.groups : [];

    return groups.map((group, i) => {
      const links = group.samples.map((sample, i) => {
        return {
          title: sample.name,
          file: sample.file,
          type: "API-SAMPLE",
          eventHandler: pushDocumentView
        };
      });

      return (
        <div key={i}>
          <PageHeader title={group.name} />
          <LinkPanels links={links} />
        </div>
      );
    });
  }

  linkClickHandler(file) {
    const { pushDocumentView } = this.props;

    const type = "API_SAMPLE";
    const payload = {
      filename: file
    };

    pushDocumentView(type, payload, null);
  }

  render() {
    return <div css={apiGroupCss}>{this.groups()}</div>;
  }
}

export default ApiGroupView;
