/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useApiLogs } from "./ApiLogsHooks";
import PageHeader from "../documentation-styled-components/PageHeader";
import ApiLogsSearchBar from "./ApiLogsSearchBar";
import ApiLogsSearchResults from "./ApiLogsSearchResults";
import ApiLogsAlert from "./ApiLogsAlert";
import { useTrackWithMixpanelOnce } from "../../../trackers/mixpanel";

const apiLogsCss = {
  padding: "1em"
};

// API Logs search dashboard
const ApiLogsView = props => {
  const { t } = useApiLogs();
  useTrackWithMixpanelOnce("Viewed Page: Docs / API Logs");

  return (
    <div css={apiLogsCss}>
      <PageHeader title={t("documentation:API Logs")} />
      <ApiLogsSearchBar />
      <ApiLogsAlert />
      <ApiLogsSearchResults />
    </div>
  );
};

export default ApiLogsView;
