import axios from "axios";
import apiUrl from "../../api-url";

// URLS
const STORE_MOUNT_POINT = "roles";
const ROLES_URL = apiUrl("/iam/roles");

// Actions
const RECEIVE_ROLES = "Roles/RECEIVE_ROLES";

// Action creators
function fetchRoles() {
  const url = `${ROLES_URL}`;
  return dispatch => {
    return Promise.all([axios.get(`${url}`)])
      .then(responses => {
        dispatch({
          type: RECEIVE_ROLES,
          payload: responses[0].data
        });
      })
      .catch(err => {
        //throw new Error(err);
        console.log(err);
      });
  };
}

function transformRolesResponse(response) {
  let roles = [];
  if (response) {
    for (let roleId in response) {
      if (response.hasOwnProperty(roleId)) {
        let obj = {};
        obj["id"] = roleId;
        obj["name"] = response[roleId].name;
        obj["description"] = response[roleId].name;

        // transform privileges
        let privileges = [];
        if (response[roleId].privileges) {
          for (let privilegeId in response[roleId].privileges) {
            if (response[roleId].privileges.hasOwnProperty(privilegeId)) {
              privileges.push(privilegeId);
            }
          }
        }
        obj["permissions"] = privileges;
        obj["privileges"] = response[roleId].privileges;
        obj["users"] = response[roleId].users;
        roles.push(obj);
      }
    }
  }

  const transformedResponse = {
    response: {
      roles: roles
    }
  };

  return transformedResponse;
}

const getRoles = state =>
  state.roles.roles.map(role => {
    return { ...role, friendlyName: getRoleFriendlyName(role.name) };
  });

// Return role enum value as human-readable name
const getRoleFriendlyName = roleName => {
  let str = roleName.replace(new RegExp("_", "g"), " ").toLowerCase();
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(str) {
    return str.toUpperCase();
  });
};

const initialState = {
  roles: []
};

function RolesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_ROLES:
      const transformedResponse = transformRolesResponse(
        action.payload.response
      );
      return {
        ...state,
        roles: transformedResponse.response.roles
      };
    default:
      return state;
  }
}
// interface
const RolesState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchRoles
  },
  selectors: {
    getRoles
  },
  reducer: RolesReducer
};
export default RolesState;
