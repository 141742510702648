/*
 * Helper to build saved search state in an informed mount point
 */
import produce from "immer";
import axios from "axios";
import _ from "lodash";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";

const buildSavedSearchState = ({
  topic,
  savedSearchUrl,
  searchType,
  searchCategories,
  actions
}) => {
  const actionNamer = phase => `${topic}/${phase}`;
  const REQUEST = actionNamer("request");
  const RECEIVE = actionNamer("receive");
  const REQUEST_ERROR = actionNamer("request");

  const savedSearchDuck = buildFetchDuck(topic);

  const fetchSavedSearches = () => {
    const entitySavedSearchUrl = savedSearchUrl + `?searchtype=${searchType}`;
    return dispatch => {
      dispatch(savedSearchDuck.fetch(entitySavedSearchUrl));
    };
  };

  /**
   * Adds a wrapper to the axios call to handle isLoading states during the
   * call.
   */
  const axiosCallIsLoadingWrapper = (method, url, data = null) => {
    return dispatch => {
      dispatch({ type: REQUEST });
      return axios[method](url, data)
        .then(response => {
          dispatch({ type: RECEIVE });
          dispatch(fetchSavedSearches());
        })
        .catch(err => {
          dispatch({ type: REQUEST_ERROR });
          throw new Error(err);
        });
    };
  };

  const saveSearch = (name, searchObj) => {
    const payload = {
      name,
      search: searchObj,
      searchtype: searchType
    };
    return axiosCallIsLoadingWrapper("post", `${savedSearchUrl}`, payload);
  };

  const editSearch = (id, name = null, searchObj = null) => {
    let payload = {};
    if (name !== null) {
      payload["name"] = name;
    }
    if (searchObj !== null) {
      payload["search"] = searchObj;
    }
    return axiosCallIsLoadingWrapper("put", `${savedSearchUrl}/${id}`, payload);
  };

  const deleteSearch = id => {
    return axiosCallIsLoadingWrapper("delete", `${savedSearchUrl}/${id}`);
  };

  const loadSavedSearch = savedSearch => {
    return (dispatch, getState) => {
      const { searchCategory, searchObj, searchFilters } = getSearchComponents(
        savedSearch.search
      );

      // Clear the search
      dispatch(actions.clearSearchFilters());

      // Set search category and value
      if (searchObj != null) {
        dispatch(actions.setSearchCategory(searchCategory));
        dispatch(actions.setSearchText(searchObj.value));
      }

      // Set filters
      Object.keys(searchFilters).forEach(k => {
        // set search filter expects the value to be a list
        // if our value is just an int, change it to a list

        const selectedFilters =
          typeof searchFilters[k] === "number"
            ? [searchFilters[k]]
            : searchFilters[k];

        dispatch(actions.setSearchFilter(k, selectedFilters));
      });
    };
  };

  // Helpers
  const getSearchComponents = search => {
    const searchCategory = getSearchCategory(search);
    const searchObj = getSearchObj(search, searchCategory);
    const searchFilters = _.omit(
      search,
      searchCategories.map(item => item.queryKey)
    );

    return {
      searchCategory,
      searchObj,
      searchFilters
    };
  };

  const getSearchCategory = search => {
    // Check for presence of search.categoryName string (search_everything_values, vin_values, etc)
    for (const cat of searchCategories) {
      if (
        search[cat.queryKey] !== null &&
        typeof search[cat.queryKey] === "string"
      ) {
        return cat;
      }
    }

    return null;
  };

  const getSearchObj = (search, searchCategory) => {
    if (_.isNil(search) || _.isNil(searchCategory)) {
      return null;
    }

    // Otherwise set the search label and value the same
    return {
      label: search[searchCategory.queryKey],
      value: search[searchCategory.queryKey]
    };
  };

  // Selectors
  const getSavedSearches = state => _.sortBy(state[topic].data, "name");
  const getIsLoading = state => state[topic].isLoading || false;

  const initialState = {
    data: [],
    url: "",
    isLoading: false
  };

  const reducer = (state = initialState, action) =>
    produce(state, draft => {
      switch (action.type) {
        case REQUEST:
          draft.isLoading = true;
          break;

        case RECEIVE:
          draft.isLoading = false;
          break;

        case REQUEST_ERROR:
          draft.isLoading = false;
          break;

        default:
          return draft;
      }
    });

  return {
    mountPoint: topic,
    actionCreators: {
      fetchSavedSearches,
      saveSearch,
      editSearch,
      deleteSearch,
      loadSavedSearch
    },
    selectors: {
      getSavedSearches,
      getIsLoading
    },
    helpers: {
      getSearchComponents
    },
    reducer: chainReducers([savedSearchDuck.reducer, reducer])
  };
};

export default buildSavedSearchState;
