/** @jsx jsx */
import { jsx } from "@emotion/core";
import Colors from "../../../styles/colors";
import PropTypes from "prop-types";

const DetailItem = props => {
  const { label, style, "data-qa": dataQa } = props;

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        flexGrow: 1,
        marginRight: "1em",
        ...style
      }}
    >
      <span css={{ color: "#999", marginBottom: "5px", fontSize: "normal" }}>
        {label}
      </span>
      <span
        css={{ color: Colors.background.DARK_BLUE, fontSize: "1.25em" }}
        data-qa={dataQa}
      >
        {props.children}
      </span>
    </div>
  );
};

DetailItem.propTypes = {
  label: PropTypes.string,
  style: PropTypes.object
};
export default DetailItem;
