/** @jsx jsx */
// eslint-disable-next-line
import { Component } from "react";
import { jsx } from "@emotion/core";
import { withTranslation } from "react-i18next";

import SearchBarContainer from "./MetalTrackingLocationResultsSearchBarContainer";
import ResultsTable from "../../components/tables/ResultsTable";
import { resultsTableColumns } from "../mt-dashboard/mt-dashboard-components/results-table-columns";
import DataHeader from "../mt-dashboard/mt-dashboard-components/DataHeader";
import MixpanelUtils from "../../trackers/mixpanel";

class MetalTrackingSearchResultsView extends Component {
  state = {
    // If the user adds a filter to a search, or edits it in any way, consider this
    // a new search which could be saved.
    searchParamsChanged: false,
    savedSearch: this.props.savedSearch,
    showDeleteSavedSearchModal: false
  };

  constructor(props) {
    super(props);
    this.rowClickHandler = this.rowClickHandler.bind(this);
  }

  componentDidMount() {
    const { savedSearch, loadSavedSearch } = this.props;

    // If a saved search was previously selected, automatically preload its query values
    if (savedSearch) {
      loadSavedSearch(savedSearch);
    }

    MixpanelUtils.track("Viewed Page: Metal / Search Results");
  }

  rowClickHandler(rowInfo) {
    this.props.pushRackDetailView(rowInfo.original.id);
  }

  render() {
    const {
      searchResults,
      isLoading,
      toggleWatchedRackFlag,
      page,
      pageSize,
      totalPages,
      setPagination,
      solutionId,
      rackLocation,
      t
    } = this.props;
    const searchData = isLoading ? [] : searchResults;

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff"
        }}
      >
        <div
          css={{
            backgroundColor: "#fff",
            padding: "0px 20px 20px 20px"
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              padding: "20px 0 20px 0"
            }}
          >
            <div
              css={{
                flex: "0 0 40%",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                justifyContent: "center"
              }}
            >
              <DataHeader
                title={t("mt-location-results:Racks")}
                complement={t("mt-location-results:in") + " " + rackLocation}
                total={searchResults.length || 0}
              />
            </div>
            <div
              css={{
                display: "flex",
                flex: "0 0 60%",
                flexDirection: "column"
              }}
            >
              <SearchBarContainer isShowingAdvancedSearchButton={false} />
            </div>
          </div>
          <ResultsTable
            manual={true}
            showPagination={true}
            fixPaginationToBottom={true}
            rowClickHandler={this.rowClickHandler}
            data={searchData}
            columns={() =>
              resultsTableColumns(t, solutionId, toggleWatchedRackFlag)
            }
            isLoading={isLoading}
            page={page}
            pageSize={pageSize}
            totalPages={totalPages}
            setPagination={setPagination}
            solutionId={solutionId}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation(["mt-location-results"])(
  MetalTrackingSearchResultsView
);
