/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import Colors from "../../../styles/colors";

const ShipmentInfoBox = ({
  title,
  children,
  border,
  styles,
  titleStyles,
  "data-qa": dataQa
}) => {
  return (
    <div
      css={{
        border: border ? `1px solid ${border}` : null,
        borderRadius: 3,
        width: "25%",
        padding: ".7em .5em",
        color: "#8c8888",
        ...styles
      }}
      data-qa={dataQa}
    >
      <div
        css={{
          fontSize: 14,
          marginBottom: 10,
          ...titleStyles
        }}
      >
        {title}
      </div>
      <div
        css={{
          color: Colors.background.DARK_BLUE
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default ShipmentInfoBox;
