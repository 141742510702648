import React, { useState } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';
import Colors from "../styles/colors";
import "./table-styles.css";

export const generateHeaderCell = (title, sortBool, sortDir) => {
  const desc = sortBool && sortDir;
  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      {title}
      {/* If we want to sort (sortBool) and we have a title */}
      {sortBool && title ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <IoIosArrowUp
            style={{
              marginBottom: "-5px",
              color: !desc ? null : Colors.text.GRAY
            }}
          />
          <IoIosArrowDown style={{ color: desc ? null : Colors.text.GRAY }} />
        </div>
      ) : null}
    </div>
  );
};

const DEFAULT_PAGE_SIZE = 20;

const TabTable = props => {
  const [sortKey, setSortKey] = useState("");
  const [sortDir, setSortDir] = useState("");

  const sortHandler = (sortKey, dir) => {
    setSortKey(sortKey);
    setSortDir(dir);
  };

  const validDefaultSorting =
    props.defaultSorted && props.defaultSorted.id !== "";

  const { "data-qa": dataQa } = props;

  return (
    <ReactTable
      className="customtable"
      style={{
        border: "none"
      }}
      onSortedChange={(newSorted, column, shiftKey) => {
        sortHandler(newSorted[0].id, newSorted[0].desc);
      }}
      defaultSorted={
        validDefaultSorting
          ? [
              {
                id: props.defaultSorted.id,
                desc: props.defaultSorted.desc
              }
            ]
          : [{ id: null, desc: null }]
      }
      showPagination={props.showPagination || false}
      minRows={!props.data || props.data.length === 0 ? 5 : 0} // H2-211 : 'No rows' overlaps columns
      defaultPageSize={
        props.defaultPageSize ||
        (props.data && props.data.length > DEFAULT_PAGE_SIZE
          ? props.data.length
          : DEFAULT_PAGE_SIZE)
      }
      getTheadProps={() => {
        return {
          style: {
            height: 45,
            boxShadow: "none",
            borderBottom: "1px solid rgba(0,0,0,0.05)"
          },
          "data-qa": dataQa ? `header-tab-table-${dataQa}` : null
        };
      }}
      getTheadThProps={(state, rowInfo, column, instance) => {
        const sorted = column.id === sortKey;
        return {
          style: {
            display: "flex",
            alignItems: "center",
            paddingLeft: 25,
            color: sorted ? null : Colors.text.GRAY,
            fontWeight: 600,
            textDecoration: sorted ? "none" : "underline",
            fontStyle: sorted ? null : "italic",
            boxShadow: "none",
            outline: 0
          }
        };
      }}
      getTrProps={(state, rowInfo) => {
        return {
          onClick: e => {
            props.rowClickHandler &&
              props.rowClickHandler(
                rowInfo.original.type,
                rowInfo.original.reason_code,
                rowInfo
              );
          },
          "data-qa": dataQa ? `rows-tab-table-${dataQa}` : null
        };
      }}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          style: {
            color: "#2d3848",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
            minHeight: 45,
            padding: "10px 20px",
            flexGrow: 1
          }
        };
      }}
      data={props.data}
      columns={props.columns(sortKey, sortDir)}
      loading={props.isLoading || false}
      sortable={props.sortable}
    />
  );
};

TabTable.propTypes = {
  /** Array of data objects to display in table */
  data: PropTypes.array.isRequired,
  /** Function that generates columns for the table */
  columns: PropTypes.func.isRequired,
  /** An object that specifies a default sort order */
  defaultSorted: PropTypes.shape({
    id: PropTypes.string,
    desc: PropTypes.bool
  }),
  /** Click handler for the table's row (tr) elements */
  rowClickHandler: PropTypes.any,
  /** Forwarded to ReactTable's showPagination prop */
  showPagination: PropTypes.bool,
  /** Forwarded to ReactTable's defaultPageSize prop */
  defaultPageSize: PropTypes.any,
  /** Forwarded to ReactTable's loading prop */
  isLoading: PropTypes.bool,
  /** Forwarded to ReactTable's sortable prop */
  sortable: PropTypes.bool
};

export default TabTable;
