/** @jsx jsx */
/**
 * Component with a half donut graph and list of at most 6 registries that
 * shows vins grouped by its aging and ADC.
 */
// eslint-disable-next-line
import PropTypes from "prop-types";
import React from "react";
import { jsx } from "@emotion/core";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { useIsMediumAndDown } from "../../../components/responsive";
import Colors from "../../../styles/colors";
import VerticalStackedBarChart from "../../../components/charts/VerticalStackedBarChart";
import { DataWidgetFrame } from "../../../components/widgets/DataWidgetFrame";
import { DataWidgetTooltip } from "../../../components/widgets/DataWidgetTooltip";

const generateTotalAgingPercentageChartData = (data, t) => {
  if (!data || data.length < 1 || !data.data || data.data.length < 1) {
    return {};
  }

  let ageData = [];
  let dwellData = [];

  const sortByAge = _.reverse(_.sortBy(data.data, "agedCount"));
  for (let location of sortByAge) {
    if (location.agedCount || location.dwellCount) {
      ageData.push({
        x: location.name,
        y: location.agedCount,
        locationId: location.locationId
      });
      dwellData.push({
        x: location.name,
        y: location.dwellCount,
        locationId: location.locationId
      });

      // We only want the top 5
      if (ageData.length === 5) {
        break;
      }
    }
  }

  return {
    [t("adc-dashboard:Aging")]: ageData,
    [t("adc-dashboard:Dwell")]: dwellData
  };
};

// Victory Chart style object
const barStyle = {
  data: {
    cursor: "pointer",
    opacity: 0.75,
    width: 42
  },
  labels: { fill: "white" }
};

// Victory Chart style object
const axisStyle = {
  axis: { stroke: Colors.border.BOTTOM_AXIS_BORDER }
};

const crossAxisStyle = {
  axis: { stroke: "transparent" },
  axisLabel: { fontSize: 20, padding: 30 },
  grid: { stroke: Colors.border.INNER_GRAY_BORDER },
  tickLabels: { fontSize: 15, padding: 5 }
};

// Victory Chart style object
const flyoutStyle = {
  stroke: "none",
  fill: Colors.background.DARK_GRAY
};

const colorScale = [Colors.highlight.RED, Colors.highlight.GREEN];

const noDataStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  fontSize: "1.5em",
  cursor: "default",
  color: Colors.background.GRAY_BLUE
};

const AgingVinsByLocation = ({ bucketsData, handler }) => {
  const { t } = useTranslation("adc-dashboard");

  const isMobile = useIsMediumAndDown();

  const chartData = generateTotalAgingPercentageChartData(bucketsData.data, t);
  const chartLabels = [
    t("adc-dashboard:Vins Aging"),
    t("adc-dashboard:Vins Dwell")
  ];

  return (
    <DataWidgetFrame
      header={
        <div>
          {t("adc-dashboard:Top 5 Locations with Aging VINs")}
          <DataWidgetTooltip
            tooltip={t(
              "adc-dashboard:This chart shows the top 5 locations with the greatest population of Aging VINs. The VIN population is broken down between Aging and Dwelling VINs per location."
            )}
            icon={faInfoCircle}
            style={{ marginLeft: "0.5em" }}
          />
        </div>
      }
      styles={{
        height: isMobile ? "100%" : "26em",
        display: "flex",
        flexDirection: "column",
        minWidth: isMobile ? null : "20em",
        svg: {
          maxHeight: 310
        }
      }}
      isLoading={bucketsData.isLoading}
    >
      <React.Fragment>
        {(chartData.Aging && chartData.Aging.length > 0) ||
        (chartData.Dwell && chartData.Dwell.length > 0) ? (
          <VerticalStackedBarChart
            data={chartData}
            height={320}
            barStyle={barStyle}
            axisStyle={axisStyle}
            crossAxisStyle={crossAxisStyle}
            flyoutStyle={flyoutStyle}
            barClick={(e, data) => {
              handler(data.datum.locationId);
            }}
            colorScale={colorScale}
            chartLabels={chartLabels}
          />
        ) : (
          <div style={noDataStyle}>{t("adc-dashboard:No Data Available")}</div>
        )}
      </React.Fragment>
    </DataWidgetFrame>
  );
};

AgingVinsByLocation.propTypes = {
  bucketsData: PropTypes.object,
  handler: PropTypes.func.isRequired
};

export default AgingVinsByLocation;
