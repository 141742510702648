import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import ResultsTable from "../../../components/tables/ResultsTable";
import CheckBoxCell from "../../../components/tables/Cell/CheckBoxCell";

import {
  FlexColDiv,
  FlexDiv,
  FlexRowDiv,
  BoldText
} from "../../../styles/container-elements";
import { MadChicletCSS as MadChiclet } from "../../../components/chiclets";
import {
  localizedTimeFormatter,
  localizedDateFormatter
} from "../../../utils/date-time";
import { parseTimeWindow } from "../../fv-vin-details/vin-details-utils";
import { NaPlaceholder } from "../../../components/no-data-placeholders";
import MilestoneCellContainer from "./MilestoneCellContainer";
import CountryName from "../../location/components/CountryName";

// Helpers
const findReference = (references, key) => {
  if (references === undefined) {
    return null;
  }
  const ref = references.find(obj => obj.qualifier === key);
  return ref ? ref.value : null;
};

const VinCell = styled.div({
  fontWeight: 600
});

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const ProductTypeCell = props => {
  return (
    <div css={{ textOverflow: "auto", overflow: "none" }}>{props.value}</div>
  );
};

ProductTypeCell.propTypes = {
  value: PropTypes.string
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const ActiveShipmentCell = props => {
  const { tripLegId, mode, lastPosition, lastProgress } = props.value;

  if (_.isNil(tripLegId)) {
    return <div />;
  }
  return (
    <FlexColDiv>
      <FlexDiv style={{ marginBottom: ".25em" }}>
        {tripLegId ? tripLegId : ""}
      </FlexDiv>
      {lastPosition || lastProgress ? (
        <FlexDiv style={{ marginBottom: ".25em" }}>Last Update:</FlexDiv>
      ) : null}
      <FlexRowDiv>
        {mode ? (
          <div>
            <MadChiclet
              shipmentMode={mode}
              stopMode={mode}
              activeException={null}
              height={24}
              width={24}
            />
          </div>
        ) : null}
        <FlexColDiv style={{ marginLeft: ".5em" }}>
          {lastPosition ? (
            <div>{`${lastPosition.city}, ${lastPosition.state}`}</div>
          ) : null}
          {lastProgress ? (
            <div>
              <div>{`${lastProgress.remainingMiles} miles out`}</div>
              {lastProgress.destinationEta ? (
                <div>{`ETA ${localizedTimeFormatter(
                  lastProgress.destinationEta
                )} ${localizedDateFormatter(
                  lastProgress.destinationEta
                )}`}</div>
              ) : null}
            </div>
          ) : null}
        </FlexColDiv>
      </FlexRowDiv>
    </FlexColDiv>
  );
};

ActiveShipmentCell.propTypes = {
  value: PropTypes.object.isRequired
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const LocationCell = props => {
  const { location } = props.value;

  if (_.isNil(location)) {
    return <div />;
  }

  const scheduledWindow = parseTimeWindow(location.scheduledArrivalWindow);

  return (
    <FlexRowDiv>
      <FlexColDiv style={{ marginRight: "1em" }}>
        <FlexDiv>{location.code}</FlexDiv>
        <FlexDiv>{location.name}</FlexDiv>
        {location.city && location.state ? (
          <FlexDiv>{`${location.city}, ${location.state}`}</FlexDiv>
        ) : null}
        <FlexDiv>{location.postalCode}</FlexDiv>
        <FlexDiv>
          <CountryName countryCode={location.country} />
        </FlexDiv>
      </FlexColDiv>
      <FlexColDiv>
        <BoldText style={{ textDecoration: "underline" }}>Scheduled</BoldText>
        <BoldText>{`${localizedTimeFormatter(
          scheduledWindow[0]
        )} ${localizedDateFormatter(scheduledWindow[0])} to`}</BoldText>
        <FlexDiv>{`${localizedTimeFormatter(
          scheduledWindow[1]
        )} ${localizedDateFormatter(scheduledWindow[1])}`}</FlexDiv>
      </FlexColDiv>
    </FlexRowDiv>
  );
};

LocationCell.propTypes = {
  value: PropTypes.object.isRequired
};

const OrderTypeCell = props => {
  const { references } = props.value;

  if (_.isNil(references)) {
    return <NaPlaceholder />;
  }

  const orderType = findReference(references, "OrderType") || "";

  return orderType !== "" ? <div>{orderType}</div> : <NaPlaceholder />;
};

OrderTypeCell.propTypes = {
  value: PropTypes.object.isRequired
};

const VinSearchResultsTable = ({
  t,
  data,
  cellClickHandler,
  isLoading,
  page,
  pageSize,
  totalPages,
  setPagination,
  setSort,
  solutionId,
  watchedVins,
  toggleWatch
}) => {
  const resultsColumns = () => {
    return [
      {
        Header: t("fv-vin-search:Watch"),
        accessor: "watch",
        sortable: false,
        width: 67,
        Cell: props => {
          const vin = props.original.id;
          return (
            <CheckBoxCell
              checked={watchedVins.includes(vin)}
              onChange={() => toggleWatch(vin)}
            />
          );
        },
        getProps: (state, rowInfo) => ({
          style: {
            whiteSpace: "unset"
          }
        })
      },
      {
        Header: t("fv-vin-search:VIN"),
        id: "vin",
        accessor: "id",
        width: 175,
        Cell: row => <VinCell>{row.value}</VinCell>,
        getProps: (state, rowInfo) => ({
          style: {
            whiteSpace: "unset"
          }
        })
      },
      {
        Header: t("fv-vin-search:Product Type"),
        id: "productType",
        width: 100,
        accessor: "description",
        Cell: ProductTypeCell,
        getProps: (state, rowInfo) => ({
          style: {
            whiteSpace: "unset"
          }
        })
      },
      {
        Header: t("fv-vin-search:Last Milestone"),
        id: "lastMilestone",
        Cell: cellInfo => {
          return (
            <MilestoneCellContainer
              lastMilestone={cellInfo.original.lastStatusUpdate}
            />
          );
        },
        getProps: (state, rowInfo) => ({
          style: {
            whiteSpace: "unset"
          }
        })
      },
      {
        Header: t("fv-vin-search:Active Shipment"),
        id: "activeShipment",
        accessor: d => {
          return {
            tripLegId: d.activeTripLegId,
            mode: d.activeTransportMode,
            lastPosition: d.lastPositionUpdate,
            lastProgress: d.lastProgressUpdate
          };
        },
        Cell: ActiveShipmentCell,
        getProps: (state, rowInfo) => ({
          style: {
            whiteSpace: "unset"
          }
        })
      },
      {
        Header: t("fv-vin-search:Origin"),
        id: "origin",
        width: 275,
        accessor: d => {
          return {
            location: d.ultimateOrigin
          };
        },
        Cell: LocationCell,
        getProps: (state, rowInfo) => ({
          style: {
            whiteSpace: "unset"
          }
        })
      },
      {
        Header: t("fv-vin-search:Destination"),
        id: "destination",
        width: 275,
        accessor: d => {
          return {
            location: d.ultimateDestination
          };
        },
        Cell: LocationCell,
        getProps: (state, rowInfo) => ({
          style: {
            whiteSpace: "unset"
          }
        })
      },
      {
        Header: t("fv-vin-search:Order Type"),
        id: "orderType",
        sortable: false,
        accessor: d => {
          return {
            references: d.references
          };
        },
        Cell: OrderTypeCell,
        width: 110,
        getProps: (state, rowInfo) => ({
          style: {
            whiteSpace: "unset"
          }
        })
      }
    ];
  };

  return (
    <div>
      <ResultsTable
        data={data}
        manual={true}
        columns={resultsColumns}
        cellClickHandler={cellClickHandler}
        cellCursorPointerEnabled={true}
        showPagination={true}
        loading={isLoading}
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        setPagination={setPagination}
        setSort={setSort}
        fixPaginationToBottom={true}
        solutionId={solutionId}
        rowHeight={150}
        sortable={true}
      />
    </div>
  );
};

VinSearchResultsTable.propTypes = {
  cellClickHandler: PropTypes.func.isRequired,
  data: PropTypes.any,
  isLoading: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  setPagination: PropTypes.func,
  setSort: PropTypes.func.isRequired,
  solutionId: PropTypes.string,
  t: PropTypes.func.isRequired,
  toggleWatch: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  watchedVins: PropTypes.array
};

export default withTranslation("fv-vin-search")(VinSearchResultsTable);
