import { useSelector, useDispatch } from "react-redux";
import { getSolutionId } from "../../modules/organizations/OrganizationsState";

// Hook for interacting with a search store created with SearchBarStateBuilder
export const useSearchBarState = searchBarState => {
  const dispatch = useDispatch();

  const { selectors, actionCreators } = searchBarState;
  const solutionId = useSelector(getSolutionId);

  return {
    dispatch,
    solutionId,
    searchText: useSelector(selectors.getSearchText),
    searchValue: useSelector(selectors.getSearchValue),
    typeaheadOptionsMetadata: useSelector(
      selectors.getTypeaheadOptionsMetadata
    ),
    searchFilters: useSelector(selectors.getSearchFilters),
    searchCategory: useSelector(selectors.getSearchCategory),
    selectedSavedSearch: useSelector(selectors.getSelectedSavedSearch),
    entities: useSelector(selectors.getEntities),
    searchResults: useSelector(selectors.getSearchResults),
    showAdvancedSearch: useSelector(selectors.getShowAdvancedSearch),
    isLoading: useSelector(selectors.getIsLoading),
    isLoadingError: useSelector(selectors.getIsLoadingError),
    loadingError: useSelector(selectors.getLoadingError),
    page: useSelector(selectors.getPage),
    pageSize: useSelector(selectors.getPageSize),
    totalPages: useSelector(selectors.getTotalPages),
    totalEntities: useSelector(selectors.getTotalEntities),
    sortColumn: useSelector(selectors.getSortColumn),
    reverseSort: useSelector(selectors.getReverseSort),
    isExporting: useSelector(selectors.getIsExporting),
    exportFailed: useSelector(selectors.getExportFailed),
    searchQueryString: useSelector(selectors.selectSearchQueryString),
    setSearchText: searchText =>
      dispatch(actionCreators.setSearchText(searchText)),
    setSearchValue: searchValue =>
      dispatch(actionCreators.setSearchValue(searchValue)),
    clearSearchText: () => dispatch(actionCreators.clearSearchText()),
    setSearchCategory: category =>
      dispatch(actionCreators.setSearchCategory(category)),
    setSearchCategoryForKey: catKey =>
      dispatch(actionCreators.setSearchCategoryForKey(catKey)),
    setSearchFilter: (key, value) =>
      dispatch(actionCreators.setSearchFilter(key, value)),
    clearSearchFilter: key => dispatch(actionCreators.clearSearchFilter(key)),
    clearSearchFilters: () => dispatch(actionCreators.clearSearchFilters()),
    resetSearchBar: () => dispatch(actionCreators.resetSearchBar()),
    resetSearchAndFilters: () =>
      dispatch(actionCreators.resetSearchAndFilters()),
    selectSavedSearch: (solutionId, item, preventSearch) =>
      dispatch(
        actionCreators.selectSavedSearch(solutionId, item, preventSearch)
      ),
    resetSavedSearch: () => dispatch(actionCreators.resetSavedSearch()),
    searchEntities: (solutionId, resetPagination) =>
      dispatch(actionCreators.searchEntities(solutionId, resetPagination)),
    clearEntities: () => dispatch(actionCreators.clearEntities()),
    exportEntities: () => dispatch(actionCreators.exportEntities()),
    toggleShowFilters: showFilters =>
      dispatch(actionCreators.toggleShowFilters(showFilters)),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(actionCreators.setPagination(solutionId, page, pageSize)),
    setSort: (solutionId, sortColumn, reverseSort) =>
      dispatch(actionCreators.setSort(solutionId, sortColumn, reverseSort))
  };
};

export default useSearchBarState;
