import { connect } from "react-redux";

import ConnectedCarView from "./ConnectedCarView";
import ConnectedCarSearchBarState, {
  RECORD_LIMIT
} from "./ConnectedCarSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  const {
    getSearchResults,
    getTotalEntities,
    getIsLoadingError,
    getIsLoading,
    getIsExporting,
    getClickedSearchOrEnter,
    getAreThereFiltersSelected
  } = ConnectedCarSearchBarState.selectors;
  return {
    ...state.endToEnd,
    connectedCarEntities: getSearchResults(state),
    totalEntities: getTotalEntities(state),
    isLoading: getIsLoading(state),
    isLoadingError: getIsLoadingError(state),
    isExporting: getIsExporting(state),
    solutionId: getSolutionId(state),
    clickedSearchOrEnter: getClickedSearchOrEnter(state),
    areThereFiltersSelected: getAreThereFiltersSelected(state)
  };
}

function mapDispatchToProps(dispatch) {
  const {
    setPagination,
    clickedSearchOrEnter
  } = ConnectedCarSearchBarState.actionCreators;
  return {
    setPagination: solutionId => {
      // Set the pageSize to 10,000 to ensure that all VINs are displayed on the map
      dispatch(setPagination(solutionId, 0, RECORD_LIMIT, true));
    },
    pushVinDetailsScreen: entityId => {
      dispatch({ type: "VIN_DETAILS", payload: { entity_id: entityId } });
    },
    resetClickedSearchOrEnter: () => {
      dispatch(clickedSearchOrEnter(false));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedCarView);
