import { createSelector } from "reselect";
import AdcDomainDataState from "../domain-data/AdcDomainData";
import AdcEntitiesState from "../adc-dashboard/AdcEntitiesState";

export const selectCarrierFilterOptions = createSelector(
  [AdcDomainDataState.selectors.selectCarriersAlphabetically],
  carriers =>
    carriers.map(carrier => ({
      value: carrier.carrier_id,
      label: `${carrier.carrier_name} (${carrier.carrier_scac})`
    }))
);

// H2-978 display Hold Type reason code in selector
export const selectHoldTypeFilterOptions = createSelector(
  [AdcDomainDataState.selectors.selectHoldTypesAlphabetically],
  rows =>
    rows.map(r => ({
      label: `${r.name} (${r.reasonCode})`,
      value: r.id
    }))
);

export const selectExceptionTypeFilterOptions = createSelector(
  [AdcDomainDataState.selectors.selectExceptionTypesAlphabetically],
  data =>
    data.map(exc => ({
      label: exc.name,
      value: exc.id
    }))
);

export const selectProductTypeFilterOptions = createSelector(
  [AdcDomainDataState.selectors.getProductTypes],
  types =>
    types.map(productType => ({
      label: productType,
      value: productType
    }))
);

export const selectOrderTypeFilterOptions = createSelector(
  [AdcDomainDataState.selectors.selectOrderTypesAlphabetically],
  types =>
    types.map(orderType => ({
      label: orderType,
      value: orderType
    }))
);

export const selectDemandAreaFilterOptions = createSelector(
  [AdcEntitiesState.selectors.getDemandAreaList],
  state => {
    if (state?.data?.demandAreas) {
      return state.data.demandAreas.map(demandArea => ({
        label: demandArea,
        value: demandArea
      }));
    } else {
      return [];
    }
  }
);
