/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { flattenShipmentStops } from "../../fv-vin-details/vin-details-styled-components/TripProgressBarNew";
import EndToEndTabsPanel from "./EndToEndTabsPanel";
import ShipmentTimelineContainer from "../shipment-detail-styled-components/ShipmentTimelineContainer";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import ShipmentDetailsCollapsible from "../shipment-detail-styled-components/ShipmentDetailsCollapsible";
import ArrivalDepartureCollapsible from "../shipment-detail-styled-components/ArrivalDepartureCollapsible";
import { getLocationDataFromShipment } from "../shipment-detail-styled-components/OriginDestinationBox";
import Colors from "../../../styles/colors";

const endToEndTabsArray = [
  { title: "Trip Summary", accessor: "updates", hideNumber: true },
  { title: "Stops", accessor: "stops", hideNumber: true },
  { title: "Coordinates", accessor: "coordinates", hideNumber: true }
];

/* Temp data */
const endToEndTabsData = {
  updates: [],
  stops: [],
  coordinates: []
};

// H1-711: End-to-end exception should only be N/A or Behind Schedule
// const exceptionText = lastShipment => {
//   let exception = "";

//   if (!lastShipment) {
//     return exception;
//   }

//   let lastShipmentETA = null;
//   if (lastShipment.destination_actual_arrival !== null) {
//     lastShipmentETA = lastShipment.destination_actual_arrival;
//   } else if (lastShipment.active_exceptions_ng !== "Missed Drop-Off") {
//     lastShipmentETA = lastShipment.destination_calculated_eta;
//   }

//   if (lastShipmentETA > lastShipment.destination_latest_arrival) {
//     exception = "Behind Schedule";
//   }

//   return exception;
// };

export const EndToEndLegPanel = props => {
  const {
    t,
    parentShipment,
    childShipments,
    lads,
    selectCoordinate,
    clearCoordinate,
    selectedCoordinate
  } = props;
  const tripLegsEndToEnd = flattenShipmentStops(
    t,
    parentShipment,
    childShipments,
    lads
  );
  const firstShipmentId = parentShipment.child_shipments[0].shipment_id;
  const lastShipmentId =
    parentShipment.child_shipments[parentShipment.child_shipments.length - 1]
      .shipment_id;
  const firstShipment = childShipments[firstShipmentId];

  const lastShipment = childShipments[lastShipmentId];
  // H1-815: Use parent shipment destination ETA for ultimate destination ETA
  const destShipment = lastShipment
    ? {
        ...lastShipment,
        destination_calculated_eta: parentShipment.destination_eta
      }
    : lastShipment;

  const tripProgress =
    tripLegsEndToEnd.length > 0 &&
    tripLegsEndToEnd.filter(t => t.active === true).length > 0
      ? tripLegsEndToEnd.filter(t => t.active === true)[0].progress
      : 0;

  const { origin, destination } = getLocationDataFromShipment(
    firstShipment ? firstShipment : {},
    destShipment
  );

  const activeLegIdx = _.findIndex(tripLegsEndToEnd, ["active", true]);
  return (
    <div
      css={{ backgroundColor: Colors.background.LIGHT_GRAY, overflow: "auto" }}
    >
      <ShipmentDetailsCollapsible
        shipmentId={parentShipment.creator_shipment_id}
        assetId={null}
        mode={parentShipment.mode_name}
        showMadChiclet={false}
        status={parentShipment.active_status_ng}
        shipperName={firstShipment ? firstShipment.shipper_name : ""}
        carrierName={null}
        carrierScac={null}
        trailerNumber={null}
        exception={parentShipment.active_exception_ng}
      />
      <ArrivalDepartureCollapsible
        shipment={firstShipment}
        destShipment={destShipment}
        origin={origin}
        destination={destination}
      />
      <ShipmentTimelineContainer
        shipment={{
          shipment_stops: tripLegsEndToEnd,
          active_exceptions_ng: parentShipment.active_exception_ng,
          current_location: {
            distance_progress: `${tripProgress}%`,
            active_leg_idx: activeLegIdx
          }
        }}
        t={t}
      />
      <div css={{ padding: "1em", marginTop: "4.5em" }}>
        <EndToEndTabsPanel
          tabsArray={endToEndTabsArray}
          tabsData={endToEndTabsData}
          parentShipment={parentShipment}
          childShipments={childShipments || []}
          selectCoordinate={selectCoordinate}
          clearCoordinate={clearCoordinate}
          selectedCoordinate={selectedCoordinate}
        />
      </div>
    </div>
  );
};

EndToEndLegPanel.propTypes = {
  parentShipment: PropTypes.object,
  childShipment: PropTypes.object
};
export default withTranslation("shipment-detail")(EndToEndLegPanel);
