/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from "react";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import Colors from "../../../styles/colors";
import { MdWarning } from "react-icons/md";

import { FlexDiv, FlexRowDiv } from "../../../styles/container-elements";

export const RackTotalWidget = ({
  count,
  label,
  color,
  icon,
  handler = null
}) => {
  const Icon = icon ? icon : MdWarning;
  return (
    <FlexRowDiv
      css={{
        border: "1px solid white",
        borderRadius: "5px",
        ":hover": {
          border: `1px solid ${Colors.background.GRAY_BLUE}`,
          backgroundColor: "#fcfcfc",
          cursor: handler ? "pointer" : "auto",
          boxShadow: `inset 0 0 1px ${Colors.background.GRAY_BLUE}`
        }
      }}
      onClick={handler}>
      <div
        css={{
          marginTop: ".08em",
          marginRight: 10
        }}
      />
      <FlexRowDiv>
        <FlexDiv>
          <span
            css={{
              margin: "5px 5px 0 0"
            }}>
            <Icon
              css={{
                width: 24,
                height: 24,
                color: color
              }}
            />
          </span>
        </FlexDiv>
        <FlexDiv css={{ alignItems: "center" }}>
          <div
            css={{
              color: Colors.background.DARK_BLUE,
              fontSize: "x-large",
              padding: "0 10px 0 5px"
            }}>
            {count}
          </div>
          <div
            css={{
              fontSize: 13,
              color: Colors.text.GRAY,
              fontStyle: "italic",
              fontWeight: 600,
              paddingTop: 8
            }}>
            {label}
          </div>
        </FlexDiv>
      </FlexRowDiv>
    </FlexRowDiv>
  );
};

RackTotalWidget.propTypes = {
  data: PropTypes.object,
  handler: PropTypes.func
};
