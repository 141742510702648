/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from "react";
import { jsx } from "@emotion/core";
import Colors from "../../../styles/colors";
import { withTranslation } from "react-i18next";

import { DataWidgetFrame } from "../../../components/widgets/DataWidgetFrame";
import { RackCountWidget } from "./RackCountWidget";

import { getRackCountsByKey } from "./RacksByType";

class RacksByLocation extends Component {
  render() {
    const { metalTrackingEntities, setSearchFilter, t } = this.props;

    const racksByLocation = getRackCountsByKey(
      metalTrackingEntities,
      "rackLocation"
    );

    return (
      <DataWidgetFrame
        header={t("mt-dashboard:Racks By Location")}
        styles={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyItems: "stretch"
        }}>
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "50% 50%",
            gridGap: 10,
            marginRight: 10,
            padding: 10
          }}>
          {racksByLocation.map((obj, i) => (
            <RackCountWidget
              key={i}
              count={obj.count}
              label={obj.label}
              color={Colors.highlight.BRIGHT_BLUE}
              handler={() => {
                setSearchFilter("rackLocation", [obj.label]);
              }}
            />
          ))}
        </div>
      </DataWidgetFrame>
    );
  }
}

const RacksByLocationWithTranslation = withTranslation(["mt-dashboard"])(
  RacksByLocation
);
export { RacksByLocationWithTranslation as RacksByLocation };
