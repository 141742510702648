import _ from "lodash";
import { generate } from "generate-password";

export function generatePassword() {
  return generate({
    length: 8,
    numbers: true,
    uppercase: true,
    strict: true
  });
}

export function isPassLengthValid(pass) {
  return _.size(pass) >= 8;
}

// requires 1 digit, 1 lowercase and 1 uppercase character
export function isPassRegexValid(pass) {
  return /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/.test(pass);
}

// requires 1 digit and 1 lowercase character
export function isResetPassRegexValid(pass) {
  return /^(?:(?=.*\d)(?=.*[a-z]).*)$/.test(pass);
}

export function isPassMatchValid(pass, cpass) {
  return !_.isEmpty(pass) && !_.isEmpty(cpass) ? pass === cpass : false;
}
