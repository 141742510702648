/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component, Suspense } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import * as OrganizationsActions from "../../../modules/organizations/OrganizationsState";
import { routeGroupsMap } from "../../../routes";
import {
  getCurrentRouteMapEntry,
  getClosestVisibleRouteMapEntry
} from "../../../route-selectors";
import NavGroup from "./NavGroup";
import NavLink from "./NavLink";
import { getAuthorization } from "../../auth/AuthorizationSelectors";
import DocumentationNav from "../../documentation/DocumentationNav";
import { groupData } from "./NavRouteAuthorization";

export const primaryNavCss = {
  display: "flex",
  flexDirection: "column"
};

export const getNavLinks = (links, groupKey, activeRoute) => {
  return links.map(({ key, route }) => {
    const isActiveRoute = activeRoute.path === route.path;
    const compositeKey = _.kebabCase(`${groupKey}-${key}`);
    return (
      <NavLink
        key={compositeKey}
        actionType={key}
        title={route.title}
        isActiveScreen={isActiveRoute}
      />
    );
  });
};

// Generate nav groups with child links from the routeGroupsMap in routes.js
class PrimaryNav extends Component {
  state = {
    openGroups: []
  };

  constructor(props) {
    super(props);

    this.toggleOpenGroup = this.toggleOpenGroup.bind(this);
  }

  componentDidMount() {
    this.initOpenGroups();
  }

  // Make sure the group containing the current view is open by default
  initOpenGroups() {
    const { currentLocation } = this.props;
    const openGroups = [];

    Object.entries(routeGroupsMap).forEach(entry => {
      const groupKey = entry[0];
      const navGroup = entry[1];

      _.keys(navGroup.routes).forEach(childKey => {
        if (childKey === currentLocation) {
          openGroups.push(groupKey);
        }
      });
    });

    this.setState({ openGroups: openGroups });
  }

  // Open/close a navigation group
  toggleOpenGroup(groupKey) {
    const { openGroups } = this.state;
    let newOpenGroups = [...openGroups];

    if (newOpenGroups.includes(groupKey)) {
      newOpenGroups = newOpenGroups.filter(g => g !== groupKey);
    } else {
      newOpenGroups.push(groupKey);
    }

    this.setState({ openGroups: newOpenGroups });
  }

  render() {
    const sidebarMinimize = false;
    const { openGroups } = this.state;
    const {
      authorization,
      activeOrganization,
      closestVisibleRouteMapEntry
    } = this.props;

    const groups = Object.entries(routeGroupsMap).map(entry => {
      const groupKey = entry[0];
      const navGroup = entry[1];

      if (navGroup.hidden) {
        return null;
      }

      const { title, icon } = navGroup;
      const { links, privileges, features, organizationTypes } = groupData(
        authorization,
        activeOrganization,
        navGroup
      );
      const linkElems = getNavLinks(
        links,
        groupKey,
        closestVisibleRouteMapEntry
      );
      const isAuthorized = authorization.isAuthorized(
        privileges,
        features,
        organizationTypes
      );
      const isOpen = openGroups.includes(groupKey);

      if (!links || !links.length || !isAuthorized) {
        return null;
      }

      return (
        <NavGroup
          key={groupKey}
          groupKey={groupKey}
          title={title}
          icon={icon}
          isOpen={isOpen}
          toggleOpenGroup={this.toggleOpenGroup}
          sidebarMinimize={sidebarMinimize}
        >
          {linkElems}
        </NavGroup>
      );
    });

    return (
      <Suspense fallback="">
        <div css={primaryNavCss}>
          {groups}
          <DocumentationNav
            isOpen={openGroups.includes("DOCUMENTATION")}
            toggleOpenGroup={this.toggleOpenGroup}
            sidebarMinimize={sidebarMinimize}
          />
        </div>
      </Suspense>
    );
  }
}

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    activeOrganization: OrganizationsActions.getActiveOrganization(state),
    currentRouteMapEntry: getCurrentRouteMapEntry(state),
    closestVisibleRouteMapEntry: getClosestVisibleRouteMapEntry(state)
  };
}

export default connect(mapStateToProps)(PrimaryNav);
