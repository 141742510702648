/*
    FIXME: dummy initial data, will eventually come from the api
*/

const STORE_MOUNT_POINT = "fvProductTypes";

const getProductTypes = state => state[STORE_MOUNT_POINT];

// dummy initial data

const MAKE = "Jovian";
const models = ["Io", "Europa", "Ganymede", "Callisto"];
const thisYear = new Date().getFullYear();

const initialState = models.map((model, i) => ({
  id: i,
  name: `${MAKE} ${model} ${thisYear}`
}));

// end dummy

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  selectors: { getProductTypes },
  reducer
};
