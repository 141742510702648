/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaRegQuestionCircle } from "react-icons/fa";

// Display (?) icon next to page title - hovering displays page description
const PageDescriptionTooltip = props => {
  const { description } = props;

  if (!description) {
    return null;
  }

  const overlay = overlayProps => {
    return <Tooltip {...overlayProps}>{description}</Tooltip>;
  };

  return (
    <OverlayTrigger placement="bottom" overlay={overlay}>
      <sup className="ml-1">
        <small>
          <FaRegQuestionCircle />
        </small>
      </sup>
    </OverlayTrigger>
  );
};

PageDescriptionTooltip.propTypes = {
  description: PropTypes.string
};

export default PageDescriptionTooltip;
