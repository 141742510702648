/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { FlexDiv, FlexColDiv } from "../../../styles/container-elements";
import { withTranslation } from "react-i18next";
import Colors from "../../../styles/colors";

// H1-1509: comment out Percentage On Time
// import FreightVerifiedPercentOnTime from "../../shipment-ai/FreightVerifiedPercentOnTime";

// HT-56 - Show historical route performance: on_time_percentage
const RouteHeader = ({ shipment, t }) => {
  if (!shipment || !shipment.route_number) {
    return null;
  }

  let tripPlanElem = shipment.trip_plan_number ? (
    <FlexDiv
      style={{
        justifyContent: "flex-start"
      }}
    >
      <span>{`${t("Trip Plan ID")}: ${shipment.trip_plan_number}`}</span>
    </FlexDiv>
  ) : null;

  return (
    <FlexColDiv
      style={{
        background: Colors.background.WARM_GRAY,
        color: "white",
        fontWeight: "bold",
        padding: "1em"
      }}
    >
      <FlexDiv
        style={{
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <span css={{ flex: 1 }}>
          {t("Route ID")}:{" "}
          <span data-qa="text-route-number">{shipment.route_number}</span>
        </span>
        {shipment.on_time_percentage && (
          <span>
            {t("On Time")}{" "}
            <span data-qa="text-on-time-percentage">
              {shipment.on_time_percentage}
            </span>
          </span>
        )}
        {/* H1-1509: comment out Percentage On Time
        {!Array.isArray(shipment) && (
          <FreightVerifiedPercentOnTime shipment={shipment} />
        )} */}
      </FlexDiv>
      {tripPlanElem}
    </FlexColDiv>
  );
};

RouteHeader.propTypes = {
  shipment: PropTypes.object,
  t: PropTypes.func.isRequired
};

export default withTranslation("shipment-details")(RouteHeader);
