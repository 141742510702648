import moment from "moment";
import apiUrl from "../../../api-url";
import buildSearchBarState from "../../../components/search-bar/SearchBarStateBuilder";
import { FILTERS } from "./ApiLogsFilterDefs";

const STORE_MOUNT_POINT = "documentationApiLogs";
const SEARCH_CATEGORIES = [];

const logsUrl = (solutionId, queryString) => {
  return apiUrl(`/support/get_api_logs?${queryString}`);
};

const fetchSearch = (qs = "", solutionId, duck, dispatch, state) => {
  const { searchFilters } = state[STORE_MOUNT_POINT];
  const filterKeys = Object.keys(searchFilters);
  const requiredKeys = ["type"];
  const atLeastOneKeys = ["ts", "requestId", "shipmentId"];
  const hasRequiredKeys = filterKeys.some(f => requiredKeys.includes(f));
  const hasAtLeastOneKeys = filterKeys.some(f => atLeastOneKeys.includes(f));
  const dateRange = searchFilters["ts"] || {};
  const { from, to } = dateRange;
  let hasValidDateRange = !from && !to;
  let error = null;

  if (from && to) {
    const days = moment(to).diff(moment(from), "days");
    hasValidDateRange = days <= 14;
  }

  if (!hasRequiredKeys) {
    error = "Please specify a Status Type";
  } else if (!hasAtLeastOneKeys) {
    error = "Please specify a Date Range, Request ID, or Shipment ID";
  } else if (!hasValidDateRange) {
    error = "Please specify a Date Range of 14 days or less";
  }

  if (error) {
    dispatch({
      type: duck.actions.REQUEST_ERROR,
      error
    });
  } else {
    const url = logsUrl(solutionId, qs);
    dispatch(duck.fetch(url));
  }
};

export default buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch
);
