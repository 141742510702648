/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";

const MapSection = props => {
  const { children } = props;

  return (
    <div
      data-qa="section-map"
      css={{
        display: "flex",
        flexDirection: "column",
        flex: 1
      }}
    >
      {children}
    </div>
  );
};

MapSection.propTypes = {
  children: PropTypes.node.isRequired
};

export default MapSection;
