/** @jsx jsx */
import { jsx } from "@emotion/core";
import { getType } from "../geofence-edit/geofence-types";
import { FaCheckSquare, FaRegSquare } from "react-icons/fa";
import { MdClose } from "react-icons/md";

import { getOrganizationByID } from "../organizations/OrganizationUtils";
import Colors from "../../styles/colors";

import { LadChicletCSS as Chiclet } from "../../components/chiclets";
import SimpleAddressCell from "../../components/tables/Cell/SimpleAddressCell";

const SelectedCellRenderer = props => {
  const { checkType, selected } = props.value;

  let check = null;

  if (checkType === "check") {
    if (selected) {
      check = (
        <FaCheckSquare
          style={{
            color: Colors.highlight.GREEN,
            fontSize: "large"
          }}
        />
      );
    } else {
      check = (
        <FaRegSquare
          style={{
            color: Colors.text.LIGHT_GRAY,
            fontSize: "large"
          }}
        />
      );
    }
  } else if (checkType === "dismiss" && selected) {
    check = (
      <MdClose
        style={{
          color: Colors.highlight.RED,
          fontSize: "large"
        }}
      />
    );
  }
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%"
      }}
    >
      {check}
    </div>
  );
};

const NameCellRenderer = props => {
  const { lad, name } = props.value;

  if (lad == null) {
    return <div css={{ height: "100%" }} />;
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "100%"
      }}
    >
      <div>
        <Chiclet height={25} width={25} lad={lad} />
      </div>
      <div
        css={{
          marginLeft: "0.5em",
          marginRight: "0.5em",
          width: "100%",
          fontWeight: "bold"
        }}
      >
        {name}
      </div>
    </div>
  );
};
const CarrierNameRenderer = props => {
  const { name } = props.value;
  return <div>{name}</div>;
};

const GeofenceCellRenderer = props => {
  if (props.value) {
    const fenceType = getType(props.value);

    return (
      <div>
        <div>{fenceType.name}</div>
      </div>
    );
  }

  return null;
};

export const getColumns = (
  t,
  lads,
  checkType,
  selectedLocations,
  organizations
) => {
  const getLad = location => {
    let lad = lads.find(l =>
      location.lad ? l.id === Number(location.lad.id) : false
    );
    if (lad) {
      return lad;
    }

    if (location.name) {
      // Return the unassigned lad
      return {
        id: 0,
        code: "u",
        description: t("location-matching:unclassified")
      };
    }

    return null;
  };

  let columns = [];

  columns.push({
    Header: "",
    id: "selected",
    accessor: d => {
      return {
        name: d.name,
        checkType: checkType,
        selected: selectedLocations.find(l => d.id === l.id) ? true : false
      };
    },
    maxWidth: 30,
    Cell: SelectedCellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        whiteSpace: "unset"
      }
    })
  });
  columns.push({
    Header: t("location-matching:Name"),
    id: "name",
    accessor: d => {
      return {
        name: d.name,
        lad: getLad(d)
      };
    },
    Cell: NameCellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        whiteSpace: "unset"
      }
    })
  });
  if (organizations) {
    columns.push({
      Header: t("location-matching:Carrier"),
      id: "owner_id",
      accessor: d => {
        const org = getOrganizationByID(d.owner_id, organizations);
        return {
          name: org ? org.org_name : ""
        };
      },
      Cell: CarrierNameRenderer,
      getProps: (state, rowInfo) => ({
        style: {
          paddingLeft: "10px",
          whiteSpace: "unset"
        }
      })
    });
  }

  columns.push({
    Header: t("location-matching:Address"),
    id: "address",
    accessor: d => {
      return {
        address: d.address,
        city: d.city,
        state: d.state,
        zip: d.postal_code
      };
    },
    Cell: SimpleAddressCell,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px"
      }
    })
  });
  columns.push({
    Header: t("location-matching:Location Code"),
    accessor: "code",
    minWidth: 50,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px"
      }
    })
  });
  columns.push({
    Header: t("location-matching:Geofence"),
    accessor: "geofence",
    minWidth: 50,
    Cell: GeofenceCellRenderer,
    getProps: (state, rowInfo) => ({
      style: {
        paddingLeft: "10px"
      }
    })
  });
  return columns;
};
