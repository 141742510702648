/*
 * Helper to build search bar state in a mount point expected
 */
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import { createSelector } from "reselect";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";
import {
  csvHeaders,
  downloadCsvFromReflectedFetch,
  withoutPagination,
  ensurePagination,
  exportReducer
} from "../../utils/export-search";

/**
 * Add byKey attribute to the list if it has no attribute like this
 */
const addByKeyAttribute = list => {
  if (!_.isArray(list)) {
    return list;
  }
  if (list.byKey) {
    return list;
  }
  const _filtersByKey = _.keyBy(list, "queryKey");
  list.byKey = _.partial(_.get, _filtersByKey);
  return list;
};

const buildSearchBarState = (
  topic,
  searchCategories,
  searchFiltersCategories,
  fetchSearch,
  reducers = [],
  config = {}
) => {
  const TS = actionName => `${topic}/Search/${actionName}`;
  const SET_SEARCH_TEXT = TS("SET_SEARCH_TEXT");
  const SET_SEARCH_VALUE = TS("SET_SEARCH_VALUE");
  const CLEAR_SEARCH_TEXT = TS("CLEAR_SEARCH_TEXT");
  const SET_CATEGORY = TS("SET_SEARCH_CATEGORY");
  const SET_FILTER = TS("SET_SEARCH_FILTER");
  const CLEAR_FILTER = TS("CLEAR_SEARCH_FILTER");
  const CLEAR_SEARCH_FILTERS = TS("CLEAR_SEARCH_FILTERS");
  const RESET_SEARCH_BAR = TS("RESET_SEARCH_BAR");
  const SELECT_SAVED_SEARCH = TS("SELECT_SAVED_SEARCH");
  const RESET_SAVED_SEARCH = TS("RESET_SAVED_SEARCH");
  const SET_SHOW_ADVANCED_SEARCH = TS("SET_SHOW_ADVANCED_SEARCH");
  const SET_PAGINATION = TS("SET_PAGINATION");
  const SET_SORT = TS("SET_SORT");
  const EXPORT_REQUEST = TS("EXPORT_REQUEST");
  const EXPORT_SEARCH_SUCCEEDED = TS("EXPORT_SEARCH_SUCCEEDED");
  const EXPORT_SEARCH_FAILED = TS("EXPORT_SEARCH_FAILED");
  const CLEAR_ENTITIES = TS("CLEAR_ENTITIES");

  const duck = buildFetchDuck(topic);

  addByKeyAttribute(searchCategories);
  addByKeyAttribute(searchFiltersCategories);

  const searchEntities = (
    solutionId,
    resetPagination = true,
    clickedSearchOrEnter = false
  ) => {
    return (dispatch, getState) => {
      let state = getState();

      if (resetPagination) {
        dispatch({ type: SET_PAGINATION, page: 0, pageSize: 20 });
      }

      // Set default search query params if provided to the builder
      if (config.defaultSort && !state[topic].sortColumn) {
        const sortColumn = config.defaultSort || null;
        const reverseSort = config.reverseSort || null;
        dispatch({ type: SET_SORT, sortColumn, reverseSort });
      }

      // Refresh the state object to ensure paging/sort keys are set
      state = getState();

      let qs = selectSearchQueryString(state, config);
      if (qs.startsWith("&")) {
        qs = qs.slice(1);
      }

      fetchSearch(qs, solutionId, duck, dispatch, state);
    };
  };

  /**
   * Generate CSV file calling an endpoint on the server which will send us
   * a file url that will be downloaded after all.
   */
  const exportEntities = (
    entitiesUrl,
    batchUrl = null,
    config = {},
    filename = "search-results",
    solutionId = null
  ) => {
    return (dispatch, getState) => {
      const state = getState();

      // TODO: check a better way to do this!
      const batchFilter = state[topic].searchFilters.batch;
      const qs = withoutPagination(selectSearchQueryString(state));
      const url = entitiesUrl(solutionId, qs);

      if (_.isEmpty(config)) {
        config.headers = csvHeaders({ tz: null });
      } else {
        config.headers["x-time-zone"] = moment.tz.guess();
      }

      const exportFileName = `${filename}-${Date.now()}.csv`;

      if (batchFilter) {
        // Batch export POST
        if (batchUrl === null) {
          throw Error(
            "Trying to export a batch search without batchUrl definition. " +
              "Please, inform batchUrl parameter to exportEntities function."
          );
        }
        const batchUrlString = batchUrl(solutionId, qs, batchFilter.batch_type);
        return batchExport({
          url: batchUrlString,
          batchFilter,
          config,
          dispatch,
          exportFileName
        });
      } else {
        // Normal export GET
        const fetchPromise = axios.get(url, config);
        return downloadCsvFromReflectedFetch(
          dispatch,
          fetchPromise,
          config,
          exportFileName
        );
      }
    };
  };

  /**
   * When we are talking about a export for a batch search, this function
   * will add parameters and call the right endpoint for batch search
   * export.
   */
  const batchExport = ({
    url,
    batchFilter,
    config,
    dispatch,
    exportFileName
  }) => {
    // H1-1405: There's a bug in the batch_search endpoint that will return the
    // entire unfiltered dataset if there are no pagination params on the query
    // string. To work around that we add a large pageSize, which triggers a
    // different code path in the endpoint that returns the filtered result set.
    // The pagination params can be removed once the endpoint is fixed.
    // Batch search is applied; POST the data payload
    const data = {
      batch_list: batchFilter.batch_list
    };
    const paginatedUrl = ensurePagination(url, {
      pageNumber: 0,
      pageSize: 1000000
    });
    const fetchPromise = axios.post(paginatedUrl, data, config);
    return downloadCsvFromReflectedFetch(
      dispatch,
      fetchPromise,
      config,
      exportFileName
    );
  };

  const setSearchText = searchText => ({
    type: SET_SEARCH_TEXT,
    searchText
  });

  const setSearchValue = searchValue => ({
    type: SET_SEARCH_VALUE,
    searchValue
  });

  const clearSearchText = () => ({
    type: CLEAR_SEARCH_TEXT
  });

  const setSearchCategory = category => ({
    type: SET_CATEGORY,
    category
  });

  const setSearchCategoryForKey = catKey =>
    setSearchCategory(searchCategories.byKey(catKey));

  const setSearchFilter = (key, value) => {
    return dispatch => {
      dispatch({ type: SET_FILTER, key, value });
    };
  };

  const setPagination = (solutionId, page, pageSize, preventSearch = false) => {
    return dispatch => {
      dispatch({ type: SET_PAGINATION, page, pageSize });

      if (!preventSearch) {
        dispatch(searchEntities(solutionId, false));
      }
    };
  };

  const setSort = (solutionId, sortColumn, reverseSort = false) => {
    return dispatch => {
      dispatch({ type: SET_SORT, sortColumn, reverseSort });
      dispatch(searchEntities(solutionId, false));
    };
  };

  const clearEntities = () => {
    return dispatch => {
      dispatch({ type: CLEAR_ENTITIES });
    };
  };

  const clearSearchFilter = key => {
    return dispatch => {
      dispatch({ type: CLEAR_FILTER, key });
    };
  };

  const clearSearchFilters = () => {
    return dispatch => {
      dispatch({ type: CLEAR_SEARCH_FILTERS });
    };
  };

  const resetSearchBar = () => ({
    type: RESET_SEARCH_BAR
  });

  const resetSearchAndFilters = () => {
    return dispatch => {
      dispatch({ type: RESET_SEARCH_BAR });
      dispatch({ type: CLEAR_SEARCH_FILTERS });
    };
  };

  const selectSavedSearch = (solutionId, item, preventSearch = false) => {
    return dispatch => {
      return Promise.all([
        dispatch({ type: SELECT_SAVED_SEARCH, searchItem: item })
      ])
        .then(() => {
          if (!preventSearch) {
            dispatch(searchEntities(solutionId));
          }
        })
        .catch(err => {
          throw new Error(err);
        });
    };
  };

  const resetSavedSearch = () => {
    // When reseting the search, we also need to remove data loaded
    // from saved search on search bar and filters
    return dispatch => {
      dispatch({ type: RESET_SAVED_SEARCH });
      dispatch({ type: RESET_SEARCH_BAR });
      dispatch({ type: CLEAR_SEARCH_FILTERS });
    };
  };

  const toggleShowFilters = showFilters => {
    return dispatch => {
      dispatch({
        type: SET_SHOW_ADVANCED_SEARCH,
        value: showFilters
      });
    };
  };

  // Helpers
  const buildSearchQueryString = (
    searchText,
    searchValue,
    searchCategory,
    filterValues,
    paginate = true,
    page = 0,
    pageSize = 20,
    sortColumn = null,
    reverseSort = false
  ) => {
    let queryString = "";
    let searchQs = "";
    if (searchCategory && !_.isEmpty(searchText)) {
      searchQs = `${searchCategory.queryKey}=${encodeURIComponent(searchText)}`;

      if (searchCategory.queryBuilder) {
        searchQs = searchCategory.queryBuilder(
          searchCategory.queryKey,
          searchText,
          searchValue
        );
      }
    }
    const filterQs = _.isEmpty(filterValues)
      ? ""
      : _.map(filterValues, (val, key) => {
          const filterDef =
            searchFiltersCategories.byKey(key) ||
            _.find(searchFiltersCategories, { queryKey: key });
          if (filterDef) {
            return filterDef.queryBuilder(filterDef.queryKey, val);
          }
        }).join("");

    if (searchQs || filterQs) {
      queryString += `${searchQs}${filterQs}`;
    }
    if (paginate) {
      queryString += `&pageNumber=${page}&pageSize=${pageSize}`;
    }
    if (sortColumn) {
      queryString += `&sortColumn=${sortColumn}&reverseSort=${
        reverseSort ? 1 : 0
      }`;
    }

    return queryString;
  };

  // Selectors
  const getEntities = state =>
    state[topic].data ? state[topic].data.data : [];
  const getSearchCategory = state => state[topic].searchCategory;
  const getSearchText = state => state[topic].searchText;
  const getSearchValue = state => state[topic].searchValue;
  const getSearchFilters = state => state[topic].searchFilters;
  const getAreThereFiltersSelected = state => {
    const searchFilters = getSearchFilters(state);
    const hasFilter = Object.keys(searchFilters).find(
      key => !_.isNil(searchFilters[key])
    );
    return !!hasFilter || false;
  };
  const getSelectedSavedSearch = state => state[topic].savedSearch;
  const getTypeaheadOptionsMetadata = state =>
    state[topic].typeaheadOptionsMetadata;

  /**
   * In some cases, search results will not go to the server, it
   * will just filter data on the browser. That's the reason behind some
   * complexity here.
   *
   */
  const getSearchResults = state => {
    let searchResults = getEntities(state);
    const searchText = getSearchText(state);
    const searchCategory = getSearchCategory(state);
    const searchFilters = getSearchFilters(state);
    let filteredResults = searchResults;

    // Filter by search category (search bar) if there is some memory filter
    // to be applied (we check for this by checking existance of
    // applyFilter property)
    if (!_.isEmpty(searchCategory) && searchCategory.applyFilter) {
      filteredResults = filteredResults.map(item => {
        if (searchCategory.applyFilter(item, searchText)) {
          return item;
        } else {
          return null;
        }
      });
      filteredResults = filteredResults.filter(x => x);
    }

    // Filter results by search filters (filter section) if there is some
    // memory filter to be applied (we check for this by checking existance of
    // applyFilter property)
    if (!_.isEmpty(searchFilters)) {
      filteredResults = filteredResults.map(item => {
        // Apply filters as an "and"
        for (const searchFilterKey of Object.getOwnPropertyNames(
          searchFilters
        )) {
          const filter = searchFiltersCategories.byKey(searchFilterKey);

          if (filter === undefined || filter.applyFilter === undefined) {
            continue;
          }

          if (_.isEmpty(searchFilters[searchFilterKey])) {
            continue;
          }

          // Check if pass through the filter, if it does not pass, just
          // return null and ignore the item.
          if (
            filter.applyFilter(
              item,
              searchFilterKey,
              searchFilters[searchFilterKey]
            ) === false
          ) {
            return null;
          }
        }
        return item;
      });
      filteredResults = filteredResults.filter(x => x);
    }
    return filteredResults;
  };
  const getPage = state => state[topic].page;
  const getPageSize = state => state[topic].pageSize;

  // TODO: Remove fallbacks once all APIs consistently return meta.totalPages
  const getTotalPages = state => {
    if (state[topic].data && state[topic].data.meta) {
      console.assert(
        state[topic].data.meta["total-pages"] === undefined,
        "total-pages property should not be returned. Please, fix it on backend."
      );
      return state[topic].data.meta.totalPages;
    } else {
      return 0;
    }
  };

  // TODO: Remove fallbacks once all APIs consistently return meta.totalCount
  const getTotalEntities = state => {
    if (state[topic].data) {
      console.assert(
        state[topic].data.recordsFiltered === undefined,
        "recordsFiltered property should not be returned. Please, fix it on backend."
      );
      return state[topic].data.meta ? state[topic].data.meta.totalCount : 0;
    } else {
      return 0;
    }
  };
  const getIsLoading = state => state[topic].isLoading || false;
  const getIsLoadingError = state => state[topic].isLoadingError || false;
  const getLoadingError = state => state[topic].loadingError;
  const getSortColumn = state => state[topic].sortColumn;
  const getReverseSort = state => state[topic].reverseSort;

  const getIsExporting = state => state[topic].isExporting || false;
  const getExportFailed = state => state[topic].exportFailed || false;

  const selectSearchQueryString = createSelector(
    [
      getSearchText,
      getSearchValue,
      getSearchCategory,
      getSearchFilters,
      paginate => true,
      getPage,
      getPageSize,
      getSortColumn,
      getReverseSort
    ],
    buildSearchQueryString
  );

  const getShowAdvancedSearch = state => state[topic].isAdvancedSearchVisible;

  // state

  const initialState = {
    searchText: "",
    searchValue: null,
    typeaheadOptionsMetadata: searchCategories,
    searchCategory: searchCategories[0],
    searchFilters: {},
    isAdvancedSearchVisible: true,
    selectedSavedSearch: {},
    page: 0,
    pageSize: 20,
    isExporting: false,
    exportFailed: false
  };

  const searchFiltersReducer = (state = initialState, action) => {
    switch (action.type) {
      case CLEAR_SEARCH_TEXT:
        return { ...state, searchText: "", searchValue: null };

      case RESET_SEARCH_BAR:
        return {
          ...state,
          searchText: initialState.searchText,
          searchValue: initialState.searchValue,
          searchCategory: initialState.searchCategory
        };

      case SET_SEARCH_TEXT:
        return {
          ...state,
          searchText: action.searchText,
          searchValue: null
        };

      case SET_SEARCH_VALUE:
        return {
          ...state,
          searchValue: action.searchValue
        };

      case SET_CATEGORY:
        return {
          ...state,
          searchCategory: action.category || initialState.searchCategory
        };

      case SET_FILTER:
        return {
          ...state,
          searchFilters: {
            ...state.searchFilters,
            [action.key]: action.value
          }
        };

      case CLEAR_FILTER:
        return {
          ...state,
          searchFilters: {
            ...state.searchFilters,
            [action.key]: null
          }
        };

      case EXPORT_REQUEST:
        return {
          ...state,
          isExporting: true,
          exportFailed: false
        };

      case EXPORT_SEARCH_SUCCEEDED:
        return {
          ...state,
          isExporting: false
        };

      case EXPORT_SEARCH_FAILED:
        return {
          ...state,
          isExporting: false,
          exportFailed: true
        };

      case CLEAR_SEARCH_FILTERS:
        return {
          ...state,
          searchFilters: {}
        };

      case SELECT_SAVED_SEARCH:
        return {
          ...state,
          savedSearch: action.searchItem
        };

      case RESET_SAVED_SEARCH:
        return {
          ...state,
          savedSearch: null
        };

      case SET_SHOW_ADVANCED_SEARCH:
        return {
          ...state,
          isAdvancedSearchVisible: action.value
        };

      case SET_PAGINATION:
        return {
          ...state,
          page: action.page,
          pageSize: action.pageSize
        };

      case SET_SORT:
        return {
          ...state,
          sortColumn: action.sortColumn,
          reverseSort: action.reverseSort
        };

      case CLEAR_ENTITIES:
        if (state.data && state.data.data) {
          return {
            ...state,
            data: {
              ...state.data,
              data: []
            }
          };
        }
        return state;

      default:
        return state;
    }
  };

  return {
    mountPoint: topic,
    actionCreators: {
      setSearchText,
      setSearchValue,
      clearSearchText,
      setSearchCategory,
      setSearchCategoryForKey,
      setSearchFilter,
      clearSearchFilter,
      clearSearchFilters,
      resetSearchBar,
      resetSearchAndFilters,
      selectSavedSearch,
      resetSavedSearch,
      searchEntities,
      clearEntities,
      exportEntities,
      toggleShowFilters,
      setPagination,
      setSort
    },
    selectors: {
      getSearchText,
      getSearchValue,
      getTypeaheadOptionsMetadata,
      getAreThereFiltersSelected,
      getSearchFilters,
      getSearchCategory,
      getSelectedSavedSearch,
      getEntities,
      getSearchResults,
      getShowAdvancedSearch,
      getIsLoading,
      getIsLoadingError,
      getLoadingError,
      getPage,
      getPageSize,
      getTotalPages,
      getTotalEntities,
      getSortColumn,
      getReverseSort,
      getIsExporting,
      getExportFailed,
      selectSearchQueryString
    },
    reducer: chainReducers([
      searchFiltersReducer,
      duck.reducer,
      exportReducer,
      ...reducers
    ])
  };
};

export default buildSearchBarState;
