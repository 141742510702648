/** @jsx jsx */
import { jsx } from "@emotion/core";
import { VictoryPie } from "victory";
import Colors from "../../styles/colors";
import { donutChartColorForExceptionType } from "./ExceptionLink";
import { useTranslation } from "react-i18next";
import { MediaQueries } from "../../components/responsive";

// Caution: Magic Number Land
// The font-sizes here were arbitrarily chosen based on what worked
// relatively well with the donut graph.
//
// After 19 digits, the text can cross over the graph but may not
// because the font face is nont monospaced (i.e. 1 is not as wide as 0)
const calculateTotalShipmentsFontSize = (baseSize, text) => {
  let reducedSize = 0;
  if (text && text.length) {
    if (text.length > 15) {
      reducedSize = 1.65;
    } else if (text.length > 12) {
      reducedSize = text.length * 0.11;
    } else if (text.length > 7) {
      reducedSize = text.length * 0.115;
    } else if (text.length > 4) {
      reducedSize = text.length * 0.11;
    }
  }
  return `${baseSize - reducedSize}em`;
};

export const ExceptionDonutChart = props => {
  const { t } = useTranslation("exceptions");

  const {
    shipmentType,
    shipmentsTotals,
    onClick,
    isWatchlist,
    isTotalShipments
  } = props;

  let data = [];

  /* H1-669 Remove Inbound label from saved searches */
  let chartLabel = isWatchlist ? "" : t(`exceptions:${shipmentType}`);

  /* H1-666 use total_shipments for saved searches */
  let chartTotal =
    isTotalShipments || isWatchlist
      ? shipmentsTotals && shipmentsTotals.total_shipments
      : shipmentsTotals && shipmentsTotals.total_inbound_shipments;

  if (shipmentsTotals == null || shipmentsTotals.total_shipments == null) {
    chartLabel = `${t("exceptions:Loading")}...`;
  } else if (shipmentsTotals.total_shipments === 0) {
    data = [{ x: 1, y: 1, fill: Colors.background.GRAY }];
  } else {
    data = shipmentsTotals.details.map(e => {
      // Exclude exceptions we don't define a color for
      // DEV-1429: use a specially named function that allows us
      // to exclude Inbound shipments and include On Time shipments.
      // We do this because Inbound is the total in the middle, we need
      // the green segment to come from the total Inbound shipments WITHOUT
      // exceptions, the "On Time" shipments.
      if (donutChartColorForExceptionType(e.key) !== null) {
        return {
          x: e.key,
          y: e.count,
          fill: donutChartColorForExceptionType(e.key)
        };
      } else {
        return {
          x: "",
          y: 0,
          fill: null
        };
      }
    });

    // Remove data with 0 exceptions
    data = data.filter(d => d.y > 0);
  }

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        ":hover": {
          cursor: "pointer"
        }
      }}
      onClick={onClick}
    >
      <div
        css={{
          minHeight: "10em",
          minWidth: "10em",
          [MediaQueries.smallAndDown]: {
            minHeight: "8em",
            minWidth: "8em"
          },
          maxWidth: "10em",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div
          css={{
            position: "absolute",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: Colors.background.GRAY_BLUE,
            fontSize: calculateTotalShipmentsFontSize(2.25, String(chartTotal))
          }}
          data-qa="total-donut-chart"
        >
          {chartTotal}
        </div>
        <VictoryPie
          data={data}
          innerRadius={135}
          padAngle={0} /* H1-1720 Remove space between pie pieces */
          labels={() => null}
          style={{
            data: {
              fill: ({ datum }) => datum.fill
            }
          }}
        />
      </div>
      {!isWatchlist && (
        <div
          css={{
            fontStyle: "italic",
            fontSize: "small",
            color: Colors.background.DARK_BLUE,
            margin: "-14px 0 10px -4px",
            textAlign: "center"
          }}
          data-qa="label-donut-chart"
        >
          {chartLabel}
        </div>
      )}
    </div>
  );
};
