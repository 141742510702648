import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ApiLogsSearchBarState from "./ApiLogsSearchBarState";
import { useSearchBarState } from "../../../components/search-bar/useSearchBarState";
import {
  localizedDateFormatter,
  localizedTimeFormatterWithSeconds
} from "../../../utils/date-time";

// Hook for interacting with common API Logs state
export const useApiLogs = () => {
  const { t } = useTranslation(["documentation"]);
  const state = useSearchBarState(ApiLogsSearchBarState);

  return {
    t,
    ...state,
    searchResults: formatSearchResults(state.searchResults)
  };
};

// Hook to apply default filters to the API Logs search
export const useDefaultFilters = () => {
  const dispatch = useDispatch();
  const { setSearchFilter } = ApiLogsSearchBarState.actionCreators;

  useEffect(() => {
    dispatch(setSearchFilter("type", "event-error"));
  }, [dispatch, setSearchFilter]);
};

// Hook to automatically submit initial search
export const useInitialSearch = searchEntities => {
  const [searchAttempted, setSearchAttempted] = useState(false);
  useEffect(() => {
    if (!searchAttempted) {
      setSearchAttempted(true);
      searchEntities();
    }
  }, [searchAttempted, searchEntities]);
};

// Format the API Logs search results for display
const formatSearchResults = results =>
  results.map(row => {
    const {
      source,
      eventType,
      shipmentId,
      shipperName,
      status,
      errorMessage,
      requestBody,
      requestId,
      fvShipmentId,
      correlationId
    } = row;
    const receivedTime = row.receivedTime
      ? `${localizedDateFormatter(
          row.receivedTime
        )} ${localizedTimeFormatterWithSeconds(row.receivedTime)}`
      : "";

    return {
      receivedTime,
      source,
      eventType,
      shipmentId,
      shipperName,
      status,
      errorMessage,
      requestBody,
      requestId,
      fvShipmentId,
      correlationId
    };
  });
