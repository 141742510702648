import { DEFAULT_GEOCODING } from "./utils/geocoding";

const HEREMAP_APP_ID = "602zvaCi0ZlxyGhkr8aA";
const HEREMAP_APP_CODE = "8x2VACMtnGhAL_9K4A-IKQ";

const STORE_MOUNT_POINT = "maps";

// Initial state

export const initialState = {
  hereMaps: {},
  hereMapsPlatform: {},
  googleMaps: null,
  selectedCoordinate: null
};

// Actions

const INIT_HERE = "fv/maps/INIT_HERE";
const SELECT_COORD = "fv/maps/SELECT_COORD";
const CLEAR_COORD = "fv/maps/CLEAR_COORD";

// Action creators
function initHere(H, google) {
  let platform = new H.service.Platform({
    app_id: HEREMAP_APP_ID,
    app_code: HEREMAP_APP_CODE,
    useCIT: false,
    useHTTPS: true
  });

  return {
    type: INIT_HERE,
    hereMaps: H,
    hereMapsPlatform: platform,
    googleMaps: google ? google.maps : null
  };
}

const selectCoordinate = (lat, long, time, ind, data = DEFAULT_GEOCODING) => {
  return dispatch =>
    dispatch({
      type: SELECT_COORD,
      data: { lat, long, time, ind, data }
    });
};

const clearCoordinate = () => {
  return dispatch =>
    dispatch({
      type: CLEAR_COORD
    });
};

// selectors
const getSelectedCoordinate = state => state.maps.selectedCoordinate;

export const getGoogleMaps = state => state.maps.googleMaps;

// Reducer
const MapReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case INIT_HERE:
      return Object.assign({}, state, {
        hereMaps: action.hereMaps,
        hereMapsPlatform: action.hereMapsPlatform,
        googleMaps: action.googleMaps,
        selectedCoordinate: null
      });

    case SELECT_COORD:
      return {
        ...state,
        selectedCoordinate: action.data
      };

    case CLEAR_COORD:
      return {
        ...state,
        selectedCoordinate: null
      };

    default:
      return state;
  }
};

// interface
export default {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: {
    initHere,
    selectCoordinate,
    clearCoordinate
  },
  selectors: {
    getSelectedCoordinate
  },
  reducer: MapReducer
};
