/** @jsx jsx */
//eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Button } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { useTranslation } from "react-i18next";
import SearchBarContainer from "../../location-search/LocationSearchBarContainer";

const propTypes = {
  canAddNewLocation: PropTypes.bool.isRequired,
  onAddNewLocation: PropTypes.func.isRequired
};

const LocationOperations = ({ canAddNewLocation, onAddNewLocation }) => {
  const { t } = useTranslation("locations");

  return (
    <div className="d-flex mt-3">
      <div className="flex-grow-1">
        <SearchBarContainer isShowingAdvancedSearchButton={false} />
      </div>
      {canAddNewLocation && (
        <Button
          variant="success"
          onClick={() => {
            onAddNewLocation();
          }}
        >
          <MdAdd size="2em" />{" "}
          <span className="d-none d-lg-inline">
            {t("locations:Add New Location")}
          </span>
        </Button>
      )}
    </div>
  );
};

LocationOperations.propTypes = propTypes;

export default LocationOperations;
