/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Colors from "../../../styles/colors";
import ReactMarkdown from "react-markdown";

const tableCss = {
  borderCollapse: "collapse",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)",
  marginBottom: "1em",
  backgroundColor: "white",
  thead: {
    backgroundColor: Colors.background.DARK_GRAY,
    color: "white"
  },
  "th, td": {
    padding: "1em",
    verticalAlign: "top"
  },
  "thead th": {
    borderTop: `1px solid ${Colors.text.DARK_BLUE}`,
    borderBottom: `1px solid ${Colors.text.DARK_BLUE}`,
    borderLeft: "1px solid #000",
    borderRight: "1px solid #000"
  },
  "thead th:first-of-type": {
    borderLeftColor: "transparent"
  },
  "thead th:last-of-type": {
    borderRightColor: "transparent"
  },
  "tbody td": {
    border: "1px solid #ddd"
  },
  "tbody tr:first-of-type td": {
    borderTopColor: "transparent"
  },
  "tbody tr:last-of-type td": {
    borderBottomColor: "transparent"
  },
  "tbody td:first-of-type": {
    borderLeftColor: "transparent"
  },
  "tbody td:last-of-type": {
    borderRightColor: "transparent"
  }
};

const paramRows = (t, params) => {
  return params.map((param, i) => {
    const description = param.description || "";

    return (
      <tr key={i}>
        <td>
          <strong>
            <pre
              css={{ padding: 0, border: 0, backgroundColor: "transparent" }}
            >
              {param.name}
            </pre>
          </strong>
        </td>
        <td>
          <ReactMarkdown source={t(`documentation-remote:${description}`)} />
        </td>
        <td>
          {param.required ? t("documentation:Yes") : t("documentation:No")}
        </td>
      </tr>
    );
  });
};

const ParameterTable = props => {
  const { t, params } = props;

  return (
    <table css={tableCss}>
      <thead>
        <tr>
          <th>{t("documentation:Parameter")}</th>
          <th>{t("documentation:Description")}</th>
          <th>{t("documentation:Required")}</th>
        </tr>
      </thead>
      <tbody>{paramRows(t, params)}</tbody>
    </table>
  );
};

ParameterTable.propTypes = {
  params: PropTypes.array
};
export default withTranslation(["documentation", "documentation-remote"])(
  ParameterTable
);
