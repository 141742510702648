import { createSelector } from "reselect";
import moment from "moment-timezone";
import _ from "lodash";
import { storeCreds } from "./auth-utils";

const SUBMIT_CREDENTIALS = "auth/SUBMIT_CREDENTIALS";
const LOGIN_USER = "auth/LOGIN_USER";
const LOGOUT = "auth/LOGOUT";
const SHOW_LOGIN_PANEL = "auth/SHOW_LOGIN_PANEL";

export function showLoginPanel() {
  return { type: SHOW_LOGIN_PANEL };
}

/*
  TODO: replace with (username, password) logic once ready
*/
export function submitCredentials(email, token) {
  return dispatch => {
    storeCreds(email, token);
    // there's no payload since we store the creds in localStorage, not
    // the store, and of course we don't want the password in the console
    dispatch({ type: SUBMIT_CREDENTIALS });
    return dispatch(login(email));
  };
}

export function login(email) {
  return dispatch => {
    return dispatch({ type: LOGIN_USER, payload: { email } });
  };
}

export function logout() {
  return dispatch => dispatch({ type: LOGOUT });
}

// selectors

export const getCurrentUser = state => state.users.currentUser;
export const getAuth = state => state.users.auth;

export const selectLastLoginMoment = createSelector(
  [getCurrentUser],
  currentUser => {
    if (_.isNil(currentUser) || !currentUser.iat) {
      return moment.unix(0);
    }

    return moment.unix(currentUser.iat);
  }
);

const initialState = {
  isLoggedIn: false,
  email: "",
  // note: we don't store the token in app state; if you really need it
  // you can use getCreds() from auth-utils.js
  isShowingLoginPanel: false
};

export default function AuthReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SHOW_LOGIN_PANEL:
      return {
        ...state,
        isShowingLoginPanel: true
      };
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: true,
        email: action.payload.email,
        isShowingLoginPanel: false
      };

    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        email: ""
      };

    default:
      return state;
  }
}
