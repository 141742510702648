/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";
import styled from "@emotion/styled";
import {
  Alert,
  Button,
  FormLabel,
  Form,
  FormGroup,
  FormControl
} from "react-bootstrap";
import ReactTable from "react-table";

import { FlexDiv, FlexColDiv, Section } from "../../styles/container-elements";
import { datatableThStyles, styleify } from "../../styles/table-styles";
import { getColumns } from "./SavedSearchColumns";
import colors from "../../styles/colors";

import RenameSavedSearchModalContainer from "./RenameSavedSearchModalContainer";
import DeleteSavedSearchModal from "../../components/saved-search/DeleteSavedSearchModal";
import {
  isPassLengthValid,
  isPassRegexValid,
  isPassMatchValid
} from "../../utils/password-utils";
import IsPasswordValidChecks from "../../components/password-checks";
import { withTranslation } from "react-i18next";

import AuthenticationUtils from "../auth/authentication";
import MixpanelUtils from "../../trackers/mixpanel";

const FlexRow = styled(FlexDiv)({ width: "100%" });
const FormItem = styled(FlexDiv)({ flex: "0 0 50%", marginRight: ".5em" });

const FormInput = ({ evt, handler, lbl, placeholder, type = "text", val }) => {
  return (
    <FormGroup>
      <FormLabel
        style={{
          color: colors.text.GRAY,
          fontSize: "small",
          fontWeight: "normal"
        }}
      >
        {lbl}
      </FormLabel>
      <FormControl
        onChange={e => handler(e.currentTarget.value, evt)}
        placeholder={placeholder}
        type={type}
        value={val}
      />
    </FormGroup>
  );
};

class ProfileView extends Component {
  static propTypes = {
    fetchSavedSearches: PropTypes.func.isRequired,
    deleteSearch: PropTypes.func.isRequired,
    changePassword: PropTypes.func.isRequired,
    resetPasswordForm: PropTypes.func.isRequired,
    selectSavedSearch: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  state = {
    pass: "",
    npass: "",
    cpass: "",
    showDeleteSavedSearchModal: false,
    showRenameSavedSearchModal: false
  };

  constructor(props) {
    super(props);
    this.eventHandler = this.eventHandler.bind(this);
  }

  componentDidMount() {
    const { fetchSavedSearches } = this.props;
    fetchSavedSearches();
    MixpanelUtils.track("Viewed Page: User / Profile");
  }

  componentDidUpdate(prevProps) {
    const { passwordStatus, resetPasswordForm } = this.props;

    if (passwordStatus && passwordStatus !== prevProps.passwordStatus) {
      console.log("password changed or error");
      this.setState({ npass: "", cpass: "" });
      setTimeout(resetPasswordForm, 3000);
    }
  }

  eventHandler(val, type) {
    const { selectSavedSearch, changePassword } = this.props;
    switch (type) {
      case "INPUT_PASS":
        this.setState({ pass: val });
        break;
      case "INPUT_NPASS":
        this.setState({ npass: val });
        break;
      case "INPUT_CPASS":
        this.setState({ cpass: val });
        break;
      case "DELETE":
        selectSavedSearch(val);
        this.setState({ showDeleteSavedSearchModal: true });
        break;
      case "RENAME":
        selectSavedSearch(val);
        this.setState({ showRenameSavedSearchModal: true });
        break;
      case "CHANGE_PASS":
        let payload = {
          password: val
        };
        changePassword(payload, AuthenticationUtils.userId);
        break;
      default:
        break;
    }
  }

  render() {
    const {
      t,
      carriers,
      exceptionTypes,
      locations,
      passwordStatus,
      savedSearches,
      shipmentModes,
      statusTypes,
      proNumbers,
      trailerNumbers,
      deleteSearch,
      selectedSavedSearch
    } = this.props;

    const {
      // pass,
      npass,
      cpass,
      showDeleteSavedSearchModal,
      showRenameSavedSearchModal
    } = this.state;

    const columns = getColumns(
      t,
      savedSearches,
      this.eventHandler,
      statusTypes,
      exceptionTypes,
      shipmentModes,
      carriers,
      locations,
      proNumbers,
      trailerNumbers
    );

    let passLengthValidationState = isPassLengthValid(npass);
    let passRegexValidationState = isPassRegexValid(npass);
    let passMatchValidationState = isPassMatchValid(npass, cpass);

    return (
      <FlexDiv
        css={{
          position: "relative"
        }}
      >
        <Section
          css={{
            backgroundColor: colors.background.LIGHT_GRAY,
            flex: ".5",
            justifyContent: "flex-start"
          }}
        >
          <FlexColDiv
            css={{
              border: "1px solid white",
              borderRadius: 8,
              margin: "1.75em",
              padding: "1.25em"
            }}
          >
            <div
              css={{
                fontWeight: 400,
                marginBottom: "1em"
              }}
            >
              {t("profile:Change Your Password")}
            </div>
            <Form>
              {/* <FlexRow>
                <FormItem>
                  <FormInput
                    lbl="Password"
                    val={pass}
                    evt="INPUT_PASS"
                    handler={this.eventHandler}
                    type="password"
                  />
                </FormItem>
              </FlexRow> */}
              <FlexRow>
                <FormItem>
                  <FormInput
                    lbl={t("profile:New Password")}
                    placeholder={t("profile:New Password")}
                    val={npass}
                    evt="INPUT_NPASS"
                    handler={this.eventHandler}
                    type="password"
                  />
                </FormItem>
                <FormItem>
                  <FormInput
                    lbl={t("profile:Confirm Password")}
                    placeholder={t("profile:Confirm Password")}
                    val={cpass}
                    evt="INPUT_CPASS"
                    handler={this.eventHandler}
                    type="password"
                  />
                </FormItem>
              </FlexRow>
            </Form>
            <IsPasswordValidChecks
              lengthIsValid={passLengthValidationState}
              passwordsMatch={passMatchValidationState}
              regexIsValid={passRegexValidationState}
            />
            <FlexRow
              style={{
                justifyContent: "flex-end"
              }}
            >
              {!passwordStatus ? (
                <Button
                  disabled={
                    !passLengthValidationState ||
                    !passRegexValidationState ||
                    !passMatchValidationState
                  }
                  onClick={() => this.eventHandler(cpass, "CHANGE_PASS")}
                >
                  {t("profile:Change My Password")}
                </Button>
              ) : null}
              {passwordStatus && passwordStatus === "CHANGED" ? (
                <Alert variant="success" style={{ display: "flex", flex: 1 }}>
                  {t("profile:Your password has been changed")}
                </Alert>
              ) : null}
              {passwordStatus && passwordStatus === "ERROR" ? (
                <Alert variant="danger" style={{ display: "flex", flex: 1 }}>
                  {t("profile:Change password failed")}
                </Alert>
              ) : null}
            </FlexRow>
          </FlexColDiv>
        </Section>
        <Section
          css={{
            backgroundColor: colors.background.LIGHT_GRAY,
            flex: ".5",
            justifyContent: "flex-start",
            paddingTop: "1.75em",
            paddingRight: "1.75em",
            paddingBottom: "1.75em"
          }}
        >
          <FlexColDiv>
            <div
              css={{
                color: colors.text.GRAY,
                fontSize: "small",
                marginBottom: ".5em"
              }}
            >
              {t("profile:My Saved Searches")}
            </div>
            <ReactTable
              className="customtable"
              data={savedSearches}
              columns={columns}
              getTheadThProps={styleify(datatableThStyles)}
            />
          </FlexColDiv>
        </Section>
        <RenameSavedSearchModalContainer
          show={showRenameSavedSearchModal}
          hide={() => this.setState({ showRenameSavedSearchModal: false })}
        />
        <DeleteSavedSearchModal
          show={showDeleteSavedSearchModal}
          hide={() => this.setState({ showDeleteSavedSearchModal: false })}
          handleConfirm={() => {
            deleteSearch(selectedSavedSearch.id);
            this.setState({ showDeleteSavedSearchModal: false });
          }}
        />
      </FlexDiv>
    );
  }
}

export default withTranslation(["profile"])(ProfileView);
