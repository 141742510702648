/** @jsx jsx */
import { jsx } from "@emotion/core";
import _ from "lodash";
import LocationsCloseButton from "./LocationsCloseButton";
import LocItem from "./LocItem";
import BasicSearchBar from "../../../components/search-bar/BasicSearchBar";
import { FlexDiv, FlexColDiv } from "../../../styles/container-elements";
import { getLadName } from "../../../components/lads";

const LocationsList = ({
  t,
  locations,
  eventHandler,
  selectedLad,
  searchStr,
  setSearchStr
}) => {
  let locItemElem = locations
    ? locations.map((loc, i) => (
        <LocItem key={i} loc={loc} ind={i} eventHandler={eventHandler} />
      ))
    : null;

  let ladLbl = selectedLad ? getLadName(selectedLad) : "";

  return (
    <FlexColDiv>
      <FlexColDiv
        className="inline-flex align-middle text-center pl-2 pr-2 pt-2"
        css={{ minHeight: "6.75em" }}
      >
        <FlexDiv css={{ flex: 1 }}>
          <BasicSearchBar
            changeHandler={val => setSearchStr(val)}
            placeholder={`${t("end-to-end:Search")} ${ladLbl}s`}
            value={searchStr}
            style={{ flex: "1 1 40%" }}
          />
          <LocationsCloseButton
            t={t}
            onClick={() => eventHandler(false, "HIDE_LOCATIONS")}
          />
        </FlexDiv>
        <FlexDiv
          css={{
            alignItems: "center",
            flex: 1,
            justifyContent: "center"
          }}
        >
          {`${locations ? _.size(locations) : 0} ${ladLbl}s`}
        </FlexDiv>
      </FlexColDiv>
      <FlexColDiv
        css={{
          flex: 1,
          overflowY: "auto"
        }}
      >
        {locItemElem}
      </FlexColDiv>
    </FlexColDiv>
  );
};

export default LocationsList;
