import { createSelector } from "reselect";
import { getCurrentUser } from "./AuthenticationState";
import Authorization from "./Authorization";
import {
  getFederationData,
  getFeatureData,
  getActiveOrganization
} from "../organizations/OrganizationsState";

export const getAuthorization = createSelector(
  [getCurrentUser, getActiveOrganization, getFederationData, getFeatureData],
  (currentUser, activeOrganization, federationData, featureData) => {
    return new Authorization(
      currentUser,
      activeOrganization,
      federationData,
      featureData
    );
  }
);
