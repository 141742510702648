import { connect } from "react-redux";
import _ from "lodash";
import EndToEndView from "./EndToEndView";
import LadsState from "../lads/LadsState";
import SearchState from "../shipment-search/ShipmentSearchBarState";
import { SEARCH_CATEGORIES } from "../shipment-search/ShipmentSearchCategoryDefs";
import {
  selectLob,
  selectLocation,
  setSearchStr,
  selectLad,
  toggleLadsPanel,
  toggleLocationsPanel
} from "./EndToEndState";
import { getLocations, fetchLadLocations } from "../location/LocationsState";

const locationCategory = _.find(SEARCH_CATEGORIES, { queryKey: "location" });

function mapStateToProps(state) {
  const { getLadsList, selectGroupedLads } = LadsState.selectors;
  return {
    ...state.endToEnd,
    locations: getLocations(state),
    ladsList: getLadsList(state),
    ladsGrouped: selectGroupedLads(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    selectLob: ind => dispatch(selectLob(ind)),
    selectLocation: loc => dispatch(selectLocation(loc)),
    selectLad: lad => dispatch(selectLad(lad)),
    setSearchStr: str => dispatch(setSearchStr(str)),
    toggleLadsPanel: toggle => dispatch(toggleLadsPanel(toggle)),
    toggleLocationsPanel: toggle => dispatch(toggleLocationsPanel(toggle)),
    searchLocation: (locationName, locationID) => {
      dispatch(SearchState.actionCreators.resetSearchAndFilters());
      dispatch(SearchState.actionCreators.setSearchCategory(locationCategory));
      dispatch(SearchState.actionCreators.setSearchText(locationName));
      dispatch(SearchState.actionCreators.searchEntities());
      dispatch({ type: "SHIPMENT_SEARCH" });
    },
    fetchLadLocations: code => dispatch(fetchLadLocations(code))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EndToEndView);
