import { connect } from "react-redux";

import ShipmentDetailView from "./ShipmentDetailView";
import ShipmentsStatusState from "../shipment-status/ShipmentsState";
import EditShipmentState from "./EditShipmentState";
import SearchState from "../shipment-search/ShipmentSearchBarState";
import LadsState from "../lads/LadsState";
import MapState from "../map/MapState";
import RolesState from "../roles/RolesState";
import { getActiveOrganization } from "../organizations/OrganizationsState";
import LocationMatchingState from "../location-resolution-edit/LocationMatchingState";
import DomainDataState from "../domain-data/DomainDataState";

function mapStateToProps(state) {
  const {
    getShipmentDetails,
    getShipmentDetailsIsLoaded,
    getRouteHeatmap,
    getChildShipmentDetails,
    getIsLoaded
  } = ShipmentsStatusState.selectors;
  const { getLadsList } = LadsState.selectors;
  const { getSelectedCoordinate } = MapState.selectors;

  return {
    ladsList: getLadsList(state),
    shipmentID: state.location.payload.shipment_id,
    scac: state.location.payload.carrier_scac,
    creatorShipmentID: state.location.payload.creator_shipment_id,
    shipment: getShipmentDetails(state),
    routeHeatmap: getRouteHeatmap(state),
    selectedCoordinate: getSelectedCoordinate(state),
    organizations: state.organizations.data
      ? state.organizations.data.response
      : state.organizations.data,
    activeOrganization: getActiveOrganization(state),
    roles: RolesState.selectors.getRoles(state),
    shipmentModes: DomainDataState.selectors.getShipmentModes(state),
    isShipmentLoaded: getShipmentDetailsIsLoaded(state),
    vin: state.location.payload.vin || null,
    childShipmentID: state.location.payload.child_shipment_id,
    childShipments: getChildShipmentDetails(state),
    isLoaded: getIsLoaded(state)
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchShipmentDetails,
    fetchShipmentDetailsFromCarrierInfo,
    fetchShipmentDetailsFromVin,
    fetchRouteHeatmap
  } = ShipmentsStatusState.actionCreators;
  const { selectCoordinate, clearCoordinate } = MapState.actionCreators;
  const {
    setShipmentWithUnresolvedLocation
  } = LocationMatchingState.actionCreators;

  const {
    assignAsset,
    assignTrailer,
    updateReviewStatus,
    createShipmentEvent,
    cancelShipment,
    startMobileTracking,
    stopMobileTracking,
    unassignAsset
  } = EditShipmentState.actionCreators;

  return {
    fetchShipmentDetails: shipmentID =>
      dispatch(fetchShipmentDetails(shipmentID)),
    fetchShipmentDetailsFromCarrierInfo: (scac, creatorShipmentID) =>
      dispatch(fetchShipmentDetailsFromCarrierInfo(scac, creatorShipmentID)),
    fetchShipmentDetailsFromVin: (scac, creatorShipmentID, vin) =>
      dispatch(fetchShipmentDetailsFromVin(scac, creatorShipmentID, vin)),
    fetchRouteHeatmap: routeID => dispatch(fetchRouteHeatmap(routeID)),
    searchLocation: (locationName, locationID) => {
      dispatch(SearchState.actionCreators.resetSearchAndFilters());
      dispatch(SearchState.actionCreators.setSearchCategory("location"));
      dispatch(SearchState.actionCreators.setSearchText(locationName));
      dispatch(SearchState.actionCreators.searchEntities());
      dispatch({ type: "SHIPMENT_SEARCH" });
    },
    selectCoordinate: (lat, long, time, ind) =>
      dispatch(selectCoordinate(lat, long, time, ind)),
    clearCoordinate: () => dispatch(clearCoordinate()),
    updateReviewStatus: (shipmentID, reviewStatus, notes) =>
      dispatch(
        updateReviewStatus(
          shipmentID,
          reviewStatus,
          notes,
          fetchShipmentDetails
        )
      ),
    cancelShipment: (shipment, organization, organizations, notes) =>
      dispatch(
        cancelShipment(
          shipment,
          organization,
          organizations,
          notes,
          fetchShipmentDetails
        )
      ),
    assignAsset: (
      shipment,
      organization,
      organizations,
      assetID,
      assignDate,
      notes
    ) =>
      dispatch(
        assignAsset(
          shipment,
          organization,
          organizations,
          assetID,
          assignDate,
          notes,
          fetchShipmentDetails
        )
      ),
    unassignAsset: (shipment, organization, organizations, assetID) =>
      dispatch(
        unassignAsset(
          shipment,
          organization,
          organizations,
          assetID,
          fetchShipmentDetails
        )
      ),
    assignTrailer: (
      shipment,
      organization,
      organizations,
      trailerNumber,
      notes
    ) =>
      dispatch(
        assignTrailer(
          shipment,
          organization,
          organizations,
          trailerNumber,
          notes,
          fetchShipmentDetails
        )
      ),
    createShipmentEvent: (
      shipment,
      organization,
      organizations,
      stop,
      eventCode,
      eventDate,
      notes
    ) =>
      dispatch(
        createShipmentEvent(
          shipment,
          organization,
          organizations,
          stop,
          eventCode,
          eventDate,
          notes,
          fetchShipmentDetails
        )
      ),
    startMobileTracking: (
      shipment,
      organization,
      organizations,
      phoneNumber,
      notes
    ) =>
      dispatch(
        startMobileTracking(
          shipment,
          organization,
          organizations,
          phoneNumber,
          notes,
          fetchShipmentDetails
        )
      ),
    stopMobileTracking: (shipment, organization, organizations, notes) =>
      dispatch(
        stopMobileTracking(
          shipment,
          organization,
          organizations,
          notes,
          fetchShipmentDetails
        )
      ),
    pushLocationMatchingView: locID =>
      dispatch({
        type: "LOCATION_MATCHING",
        payload: { location_id: locID }
      }),
    pushShipmentDetailView: (type, payload) => {
      dispatch({
        type: type,
        payload: payload
      });
    },
    setShipmentWithUnresolvedLocation: shipment =>
      dispatch(setShipmentWithUnresolvedLocation(shipment))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentDetailView);
