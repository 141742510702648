/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import StatusUpdateDetailState from "./StatusUpdateDetailState";
import LocationAddressComplex from "../location/components/LocationAddressComplex";
import Colors from "../../styles/colors";
import LocationName from "../location/components/LocationName";

export const RevealedDetailsTable = ({ rowData }) => {
  const statusUpdateId = rowData.id;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      StatusUpdateDetailState.actionCreators.fetchStatusUpdateDetails(
        statusUpdateId
      )
    );
  }, [statusUpdateId, dispatch]);
  const statusUpdateDetails = useSelector(
    StatusUpdateDetailState.selectors.statusUpdateDetailGetter(statusUpdateId)
  );

  // note: per Craig, we are ignoring translation until requested by a customer
  // (as of Jan 2020)
  const partnerData = _.get(rowData, "partnerRef", []);
  const partnerRows = partnerData.map(partnerRef => ({
    label: "Partner Name",
    value: partnerRef.org_name
  }));

  const statusRefData = _.get(statusUpdateDetails, "statusRef", []);
  const statusRefRows = statusRefData.map(refRow => {
    const { qualifier, value } = refRow;

    const isLocationField = ["Ship To", "Ship From"].includes(qualifier);

    return {
      label: qualifier,
      value,
      // We want the additional info from this statusRef if it is a location
      // refRow's for locations contain
      // { qualifier, value, name, code, address, address2, city, state, postal_code, country }
      ...(isLocationField ? refRow : {}),
      isLocationField
    };
  });

  const rows = [...partnerRows, ...statusRefRows];
  const rowElems = rows.map(row => {
    return (
      <tr key={`detail-${rowData.id}-${row.label}`}>
        <td
          css={{
            verticalAlign: "top"
          }}
        >
          {row.label}
        </td>
        <td>
          {row.isLocationField
            ? [
                <LocationName
                  name={row.name}
                  style={{
                    // Sorry :(
                    // TODO: Modify LocationName component to support these styles without !important
                    fontWeight: "normal !important",
                    fontSize: "1em !important"
                  }}
                />,
                <LocationAddressComplex
                  address1={row.address1}
                  address2={row.address2}
                  city={row.city}
                  state={row.state}
                  postalCode={row.postal_code}
                  country={row.country}
                  addressColor={Colors.text.GRAY}
                />
              ]
            : row.value}
        </td>
      </tr>
    );
  });

  return (
    <table css={{ minWidth: "40em" }}>
      <tbody>{rowElems}</tbody>
    </table>
  );
};
