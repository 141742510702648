import _ from "lodash";
import apiUrl from "../../api-url";
import axios from "axios";

import { getDefaultLocation } from "../location/LocationsState";
const SET_UNRESOLVED_LOCATION = "locationMatching/SET_UNRESOLVED_LOCATION";
const SET_SHIPMENT_WITH_UNRESOLVED_LOCATION =
  "locationMatching/SET_SHIPMENT_WITH_UNRESOLVED_LOCATION";
const SET_RETURN_TO_PREVIOUS_SCREEN =
  "locationMatching/SET_RETURN_TO_PREVIOUS_SCREEN";

const CLEAR_UNRESOLVED_LOCATION = "locationMatching/CLEAR_UNRESOLVED_LOCATION";

const RECEIVE_UNRESOLVED_LOCATION_DETAILS =
  "locationMatching/RECEIVE_UNRESOLVED_LOCATION_DETAILS";

const STORE_MOUNT_POINT = "locationMatching";

const LOCATIONS_URL = apiUrl("/location/locations");

const SET_UNRESOLVED_LOCATION_IS_LOADING =
  "locationMatching/SET_UNRESOLVED_LOCATION_LOADING";

export const buildLinkPayload = (unresolvedLocation, targetID, shipment) => {
  let payload = Object.assign({}, unresolvedLocation, {
    referent_id: targetID
  });

  // H1-576: hack to fix field naming issue
  if (payload.hasOwnProperty("organization_id")) {
    payload["owner_id"] = payload.organization_id;
  }

  // If the owner ID does not exist AND we have shipment
  // information, we can provide it
  if (!payload.hasOwnProperty("owner_id") && shipment) {
    payload["owner_id"] = shipment.carrier_organization_id;
  }

  const locationID = payload.hasOwnProperty("id")
    ? payload.id
    : payload.location_id;

  return { id: locationID, payload: payload };
};

function setShipmentWithUnresolvedLocation(shipment) {
  return {
    type: SET_SHIPMENT_WITH_UNRESOLVED_LOCATION,
    data: shipment
  };
}

function setReturnToPreviousScreen(value) {
  return {
    type: SET_RETURN_TO_PREVIOUS_SCREEN,
    data: value
  };
}

// Wrapper around the method to do the actual
// call to the backend, so we can ensure
// the call succeeds before we return to the previous screen
function linkLocation(id, data, matchLocation) {
  return dispatch => {
    return Promise.all([dispatch(matchLocation(id, data))])
      .then(responses => {
        dispatch(setReturnToPreviousScreen(true));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

function fetchUnresolvedLocationDetails(locationId) {
  return dispatch => {
    dispatch({ type: SET_UNRESOLVED_LOCATION_IS_LOADING, data: true });
    return Promise.all([axios.get(`${LOCATIONS_URL}/${locationId}`)])
      .then(responses => {
        dispatch({
          type: RECEIVE_UNRESOLVED_LOCATION_DETAILS,
          data: responses[0].data
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

function clearUnresolvedLocation() {
  return {
    type: CLEAR_UNRESOLVED_LOCATION
  };
}

// initial state
const initialState = {
  // Initially filled in with just summary data will be populated with full details
  // after the fetch action.
  unresolvedLocation: null,
  isUnresolvedLocationLoading: false,
  shipmentWithUnresolvedLocation: null,
  //Selected locations with their full data
  returnToPreviousScreen: false
};

// selectors
export const getUnresolvedLocationId = state => {
  return _.get(state, "location.payload.location_id");
};
const getUnresolvedLocation = state =>
  state.locationMatching.unresolvedLocation;
const getShipmentWithUnresolvedLocation = state =>
  state.locationMatching.shipmentWithUnresolvedLocation;
const getReturnToPreviousScreen = state =>
  state.locationMatching.returnToPreviousScreen;

// reducer
function LocationMatchingReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_UNRESOLVED_LOCATION: {
      let tmpUnresolved = action.data;
      //Some components send the unresolved location as null to reset the view.
      // Use a placeholder geofence while the location details load.
      if (action.data) {
        tmpUnresolved.geofence = getDefaultLocation().geofence;
      }
      return {
        ...state,
        unresolvedLocation: tmpUnresolved
      };
    }

    case SET_SHIPMENT_WITH_UNRESOLVED_LOCATION:
      return {
        ...state,
        shipmentWithUnresolvedLocation: action.data
      };

    case SET_RETURN_TO_PREVIOUS_SCREEN:
      return {
        ...state,
        returnToPreviousScreen: action.data
      };

    case RECEIVE_UNRESOLVED_LOCATION_DETAILS:
      return {
        ...state,
        isUnresolvedLocationLoading: false,
        unresolvedLocation: action.data
      };

    case CLEAR_UNRESOLVED_LOCATION:
      return {
        ...state,
        unresolvedLocation: null
      };

    case SET_UNRESOLVED_LOCATION_IS_LOADING:
      return {
        ...state,
        isUnresolvedLocationLoading: true
      };

    default:
      return state;
  }
}

// interface
const LocationMatchingState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    clearUnresolvedLocation,
    fetchUnresolvedLocationDetails,
    setShipmentWithUnresolvedLocation,
    setReturnToPreviousScreen,
    linkLocation
  },
  selectors: {
    getUnresolvedLocationId,
    getUnresolvedLocation,
    getShipmentWithUnresolvedLocation,
    getReturnToPreviousScreen
  },
  reducer: LocationMatchingReducer
};
export default LocationMatchingState;
