import { connect } from "react-redux";
import debouncePromise from "debounce-promise";

import LocationsView from "./LocationsView";
import LocationSearchBarState from "../location-search/LocationSearchBarState";

import { getAuthorization } from "../auth/AuthorizationSelectors";
import LadsState from "../lads/LadsState";
import LocationMatchingState from "../location-resolution-edit/LocationMatchingState";
import {
  getSolutionId,
  getActiveOrganization
} from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getTotalPages,
    getTotalEntities,
    getSearchFilters
  } = LocationSearchBarState.selectors;

  return {
    lads: LadsState.selectors.getLadsList(state),
    locationSearchResults: getSearchResults(state),
    isLoading: getIsLoading(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPages(state),
    totalLocations: getTotalEntities(state),
    organizations: state.organizations.data
      ? state.organizations.data.response
      : state.organizations.data,
    solutionId: getSolutionId(state),
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    searchFilters: getSearchFilters(state)
  };
}

function mapDispatchToProps(dispatch) {
  const {
    setShipmentWithUnresolvedLocation
  } = LocationMatchingState.actionCreators;

  return {
    pushLocationEditScreen: locID =>
      dispatch({ type: "LOCATION_EDIT", payload: { location_id: locID } }),
    pushLocationMatchingView: locID =>
      dispatch({
        type: "LOCATION_MATCHING",
        payload: { location_id: locID }
      }),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        LocationSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize
        )
      ),
    setSort: (solutionId, sortColumn, reverse) =>
      dispatch(
        LocationSearchBarState.actionCreators.setSort(
          solutionId,
          sortColumn,
          reverse
        )
      ),
    setSearchFilter: (key, value) =>
      dispatch(
        LocationSearchBarState.actionCreators.setSearchFilter(key, value)
      ),
    clearSearchText: () =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchText()),
    clearSearchFilters: () =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchFilters()),
    clearSearchFilter: key =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchFilter(key)),
    searchLocations: debouncePromise(
      solutionId =>
        dispatch(
          LocationSearchBarState.actionCreators.searchEntities(solutionId)
        ),
      500
    ),
    setShipmentWithUnresolvedLocation: shipment =>
      dispatch(setShipmentWithUnresolvedLocation(shipment))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LocationsView);
