import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import DomainDataState from "../../domain-data/DomainDataState";

class CountryName extends React.Component {
  static propTypes = {
    countries: PropTypes.array,
    countryCode: PropTypes.string,
    style: PropTypes.object,
    t: PropTypes.func
  };

  render() {
    const { t, countryCode, countries, style, "data-qa": dataQa } = this.props;
    const countryLookup = _.find(countries, { code: countryCode });
    const countryName = countryLookup ? countryLookup.name : countryCode;

    return (
      <span style={{ ...style }} data-qa={dataQa}>
        {t(countryName)}
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    countries: DomainDataState.selectors.getCountries(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["domain"])(CountryName));
