import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Modal, Button, FormControl, FormLabel } from "react-bootstrap";
import moment from "moment";
import ValidationMessage from "../../components/forms/ValidationMessage";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";

import { FlexColDiv } from "../../styles/container-elements";

import SelectInput from "../../components/forms/inputs/SelectInput";
import { EditShipmentRow } from "./AddAssetModal";

const checkTime = (now, ts, tz) => {
  if (ts === null || ts === "") {
    return true;
  }

  const tsAdjusted = tz ? moment(ts).tz(tz) : moment(ts);

  return tsAdjusted < now;
};

const validTimes = (userChangedData, stops, data) => {
  if (userChangedData === false) {
    return false;
  }

  const now = moment();
  let validTime = true;
  for (let i = 0; i < stops.length; i++) {
    const stopTZ = stops[i].timezone;

    validTime &= checkTime(now, data[i].arrivedAt, stopTZ);
    validTime &= checkTime(now, data[i].departedAt, stopTZ);
    validTime &= checkTime(now, data[i].loadedAt, stopTZ);
    validTime &= checkTime(now, data[i].unloadedAt, stopTZ);
  }

  return validTime;
};

const StopPane = ({ data, selectedStopIndex, updateField, t }) => {
  return (
    <FlexColDiv
      css={{
        padding: "1em"
      }}
    >
      <EditShipmentRow
        label={t("Arrived")}
        value={data[selectedStopIndex].arrivedAt}
        valueKey={JSON.stringify({
          index: selectedStopIndex,
          field: "arrivedAt"
        })}
        updateField={updateField}
        isTime={true}
        systemValue={null}
      />
      <EditShipmentRow
        label={t("Completed Unloading")}
        value={data[selectedStopIndex].unloadedAt}
        valueKey={JSON.stringify({
          index: selectedStopIndex,
          field: "unloadedAt"
        })}
        updateField={updateField}
        isTime={true}
        systemValue={null}
      />
      <EditShipmentRow
        label={t("Completed Loading")}
        value={data[selectedStopIndex].loadedAt}
        valueKey={JSON.stringify({
          index: selectedStopIndex,
          field: "loadedAt"
        })}
        updateField={updateField}
        isTime={true}
        systemValue={null}
      />
      <EditShipmentRow
        label={t("Departed")}
        value={data[selectedStopIndex].departedAt}
        valueKey={JSON.stringify({
          index: selectedStopIndex,
          field: "departedAt"
        })}
        updateField={updateField}
        isTime={true}
        systemValue={null}
      />
    </FlexColDiv>
  );
};

class ShipmentEventsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      noteValue: "",
      selectedStopIndex: 0
    };
    this.initialData = this.state.data;

    this.handleOnEnter = this.handleOnEnter.bind(this);
    this.handleOnChangeNote = this.handleOnChangeNote.bind(this);
    this.handleChangeStop = this.handleChangeStop.bind(this);
  }

  componentDidMount() {
    this.resetStopData(this.props.shipment);
  }

  handleOnChangeNote(evt) {
    this.setState({ noteValue: evt.target.value });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shipment !== this.props.shipment) {
      this.resetStopData(this.props.shipment);
    }
  }

  handleOnEnter() {
    this.resetStopData(this.props.shipment);
  }

  createNewEvents() {
    const {
      createShipmentEvent,
      shipment,
      organization,
      organizations
    } = this.props;

    const { noteValue, data } = this.state;
    const sortedStops = _.sortBy(shipment.shipment_stops, "stop_sequence");

    const statusMap = {
      origin: {
        arrivedAt: "X3",
        unloadedAt: "D1",
        loadedAt: "CP",
        departedAt: "AF"
      },
      middle: {
        arrivedAt: "X3",
        unloadedAt: "D1",
        loadedAt: "CP",
        departedAt: "AF"
      },
      destination: {
        arrivedAt: "X1",
        unloadedAt: "D1",
        loadedAt: "CP",
        departedAt: "CD"
      }
    };

    // Look through any events which have changed
    let mapKey = null;

    for (let i = 0; i < data.length; ++i) {
      const uiValues = data[i];

      if (i === 0) {
        mapKey = "origin";
      } else if (i === data.length - 1) {
        mapKey = "destination";
      } else {
        mapKey = "middle";
      }

      for (let key in statusMap[mapKey]) {
        if (uiValues[key] !== this.initialData[i][key]) {
          createShipmentEvent(
            shipment,
            organization,
            organizations,
            sortedStops[i],
            statusMap[mapKey][key],
            uiValues[key],
            noteValue
          );
        }
      }
    }
  }

  resetStopData(shipment) {
    if (!shipment.shipment_stops) {
      return null;
    }

    const stopData = shipment.shipment_stops.map(s => {
      return {
        arrivedAt: null,
        unloadedAt: null,
        loadedAt: null,
        departedAt: null
      };
    });

    this.setState({ data: stopData, noteValue: "", selectedStopIndex: 0 });
    this.initialData = stopData;
  }

  handleChangeStop(value) {
    this.setState({ selectedStopIndex: value });
  }

  render() {
    const { show, hide, shipment, t } = this.props;
    const { data, noteValue, selectedStopIndex } = this.state;

    const updateField = (field, val) => {
      const fieldData = JSON.parse(field);
      const updatedData = { ...data[fieldData.index], [fieldData.field]: val };
      this.setState({
        data: [
          ...data.slice(0, fieldData.index),
          updatedData,
          ...data.slice(fieldData.index + 1)
        ]
      });
    };

    if (!("shipment_stops" in shipment) || data.length === 0) {
      return null;
    }

    const userChangedData = !_.isEqual(this.initialData, data);
    const hasValidData = validTimes(
      userChangedData,
      shipment.shipment_stops,
      data
    );

    const stopOptions = shipment.shipment_stops.map((s, i) => {
      let stopNumber = String(i);
      if (i === 0) {
        stopNumber = "O";
      } else if (i === shipment.shipment_stops.length - 1) {
        stopNumber = "D";
      }

      return {
        value: i,
        label: "(" + stopNumber + ") " + s.location.name
      };
    });

    return (
      <Modal
        show={show}
        style={{ ":width": "300px" }}
        onHide={() => hide()}
        onEnter={this.handleOnEnter}
      >
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {t("Report Arrival/Departure Events")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <SelectInput
            label={t("Stops")}
            options={stopOptions}
            value={selectedStopIndex}
            onChange={this.handleChangeStop}
          />
          <StopPane
            data={data}
            shipment={shipment}
            selectedStopIndex={selectedStopIndex}
            updateField={updateField}
            t={t}
          />
          <FlexColDiv
            css={{
              margin: "0.4em"
            }}
          >
            <ValidationMessage
              isValid={hasValidData}
              msg={t("Times must be in the past")}
            />
          </FlexColDiv>
          <FlexColDiv>
            <FormLabel>{`${t("Notes")}:`}</FormLabel>
            <FormControl
              as="textarea"
              placeholder={t("Enter notes about the change here")}
              value={noteValue}
              onChange={this.handleOnChangeNote}
              style={{ resize: "none" }}
            />
          </FlexColDiv>
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em"
            }}
            onClick={() => hide()}
          >
            {t("Cancel")}
          </Button>
          <Button
            disabled={!hasValidData}
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300"
            }}
            onClick={() => {
              this.createNewEvents();
              hide();
            }}
          >
            {t("Submit")}
          </Button>{" "}
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const withT = withTranslation("shipment-details")(ShipmentEventsModal);
export default connect(mapStateToProps, mapDispatchToProps)(withT);
