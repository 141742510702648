/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import React, { Component } from "react";
import styled from "@emotion/styled";
import Loader from "react-loader";
import _ from "lodash";
// import Loader from "react-loader";

import { ExceptionRow } from "../exceptions/ExceptionLink";
import EventsListContainer from "../events/EventsListContainer";
import WatchlistPanel from "./WatchlistPanel";
import EditSavedSearchModalContainer from "../shipment-search/ShipmentEditSavedSearchModalContainer";

import Colors from "../../styles/colors";
import {
  PanelContainer,
  Panel
} from "../../vendor/signal-widgets/components/panel";
import SearchBarContainer from "../shipment-search/ShipmentSearchBarContainer";
import FiltersContainer from "../shipment-search/ShipmentSearchFiltersContainer";
import { withTranslation } from "react-i18next";

import {
  ShowForMediumAndUp,
  ShowForSmallAndDown
} from "../../components/responsive";

import MixpanelUtils from "../../trackers/mixpanel";

// Panels
const ExceptionsPanel = props => {
  const {
    title,
    shipmentType,
    exceptionTotals,
    exceptionTypes,
    loadSavedSearch,
    shipmentTypes
    // isExceptionTotalsLoaded
  } = props;

  /* H1-411 Remove load spinner */
  return (
    <Panel
      title={title}
      canMaximize={false}
      style={{ border: "none", width: "100%" }}
      headerStyle={{
        backgroundColor: Colors.background.DARK_GRAY,
        color: "white",
        fontWeight: "300"
      }}
      data-qa="panel-exceptions"
    >
      <ExceptionRow
        shipmentType={shipmentType}
        shipmentsTotals={exceptionTotals}
        exceptionTypes={exceptionTypes}
        loadSavedSearch={loadSavedSearch}
        shipmentTypes={shipmentTypes}
      />
      {/* <Loader loaded={isExceptionTotalsLoaded} /> */}
    </Panel>
  );
};

ExceptionsPanel.propTypes = {
  exceptionTotals: PropTypes.object,
  exceptionTypes: PropTypes.array,
  loadSavedSearch: PropTypes.func.isRequired,
  shipmentType: PropTypes.string.isRequired,
  shipmentTypes: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired
};

const Section = styled.section({
  display: "flex",
  flex: 1,
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "1em",
  paddingTop: "0em"
});

class ShipmentStatusView extends Component {
  static propTypes = {
    activeOrganization: PropTypes.object,
    clearSearchFilters: PropTypes.func.isRequired,
    currentOrganizationId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    exceptionTotals: PropTypes.object,
    exceptionTypes: PropTypes.array,
    fetchInboundShipmentTotals: PropTypes.func.isRequired,
    fetchSavedSearches: PropTypes.func.isRequired,
    isExceptionTotalsLoaded: PropTypes.bool,
    isLoadingSavedSearch: PropTypes.bool,
    loadSavedSearch: PropTypes.func.isRequired,
    resetSearch: PropTypes.func.isRequired,
    savedSearches: PropTypes.array,
    shipmentTypes: PropTypes.array,
    t: PropTypes.func.isRequired
  };

  state = {
    showWatchlistModal: false,
    selectedSearch: {},
    showFilters: false
  };

  componentDidMount() {
    const {
      fetchSavedSearches,
      fetchInboundShipmentTotals,
      resetSearch,
      clearSearchFilters
    } = this.props;

    fetchSavedSearches();
    fetchInboundShipmentTotals();
    resetSearch();
    clearSearchFilters();

    MixpanelUtils.track("Viewed Page: Shipments / Dashboard");
  }

  componentDidUpdate(prevProps) {
    const { currentOrganizationId, fetchInboundShipmentTotals } = this.props;
    if (currentOrganizationId !== prevProps.currentOrganizationId) {
      fetchInboundShipmentTotals();
    }
  }

  render() {
    const {
      t,
      exceptionTotals,
      savedSearches,
      exceptionTypes,
      loadSavedSearch,
      shipmentTypes,
      isExceptionTotalsLoaded,
      activeOrganization,
      resetSearch,
      clearSearchFilters,
      isLoadingSavedSearch
    } = this.props;
    const { showWatchlistModal, selectedSearch, showFilters } = this.state;

    let watchlistPanels = (
      <div style={{ margin: "auto", marginTop: "5%" }}>
        {t("shipment-status:You still have no saved searches.")}
      </div>
    );
    if (!_.isEmpty(savedSearches)) {
      watchlistPanels = savedSearches.map((s, i) => (
        <WatchlistPanel
          key={i}
          savedSearch={s}
          isWatchlist={true}
          editWatchlistItem={() => {
            this.setState({ selectedSearch: s, showWatchlistModal: true });
            loadSavedSearch(s, true);
          }}
          shipmentTypes={shipmentTypes}
        />
      ));
    }

    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div css={{ marginTop: "1em", marginLeft: "1em", marginRight: "1em" }}>
          <SearchBarContainer
            isShowingFilters={showFilters}
            toggleShowFilters={() =>
              this.setState({ showFilters: !showFilters })
            }
          />
          <FiltersContainer
            show={showFilters}
            backgroundColor={Colors.background.GRAY}
            sectionStyle={{ paddingRight: "12em" }}
          />
        </div>
        <Section>
          <PanelContainer css={{ width: "100%" }}>
            <ExceptionsPanel
              title={t("shipment-status:All Shipment Exceptions")}
              shipmentType={t("shipment-status:Total Shipments")}
              exceptionTotals={exceptionTotals}
              exceptionTypes={exceptionTypes}
              loadSavedSearch={loadSavedSearch}
              shipmentTypes={shipmentTypes}
              isExceptionTotalsLoaded={isExceptionTotalsLoaded}
              activeOrganization={activeOrganization.org_name}
            />
            <div
              css={{
                display: "flex",
                flexBasis: "100%",
                justifyContent: "space-between",
                alignContent: "flex-end",
                fontSize: "1.5em",
                fontWeight: "300",
                color: Colors.background.GRAY_BLUE,
                marginTop: "0.5em",
                marginBottom: "0.5em",
                marginLeft: "14px"
              }}
            >
              {t("shipment-status:My Saved Searches")}
              <div
                css={{
                  display: "flex",
                  alignItems: "flex-end",
                  fontSize: "0.7em",
                  fontWeight: "400",
                  fontStyle: "italic",
                  textDecoration: "underline",
                  color: Colors.background.GRAY_BLUE,
                  ":hover": { cursor: "pointer" }
                }}
                onClick={() =>
                  this.setState({
                    selectedSearch: {},
                    showWatchlistModal: true
                  })
                }
                data-qa="button-add-saved-search"
              >
                + {t("shipment-status:Add Saved Search")}
              </div>
            </div>
            {isLoadingSavedSearch ? (
              <div
                css={{ margin: "auto", marginTop: "10%", position: "relative" }}
              >
                <Loader />
              </div>
            ) : (
              <React.Fragment>
                <ShowForSmallAndDown>
                  <div css={{ width: "100%" }}>{watchlistPanels}</div>
                </ShowForSmallAndDown>
                <ShowForMediumAndUp>{watchlistPanels}</ShowForMediumAndUp>
              </React.Fragment>
            )}
          </PanelContainer>
          <EventsListContainer />
          <EditSavedSearchModalContainer
            show={showWatchlistModal}
            hide={() => {
              this.setState({ showWatchlistModal: false });
              resetSearch();
              clearSearchFilters();
            }}
            savedSearch={selectedSearch}
            preventSearchOnChange={true}
          />
        </Section>
      </div>
    );
  }
}

export default withTranslation(["shipment-status"])(ShipmentStatusView);
