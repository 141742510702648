import { connect } from "react-redux";
import debouncePromise from "debounce-promise";
import LocationMatchingView from "./LocationMatchingView";
import LadsState from "../lads/LadsState";
import LocationMatchingState from "./LocationMatchingState";
import {
  fetchHeatMapData,
  fetchLocationDetails,
  getLocations,
  matchLocation
} from "../location/LocationsState";

import LocationSearchBarState from "../location-search/LocationSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  const {
    getUnresolvedLocation,
    getUnresolvedLocationId,
    getShipmentWithUnresolvedLocation,
    getReturnToPreviousScreen
  } = LocationMatchingState.selectors;

  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getTotalPages,
    getTotalEntities
  } = LocationSearchBarState.selectors;

  const locationID = getUnresolvedLocationId(state);

  return {
    locationID: locationID,
    unresolvedLocation: getUnresolvedLocation(state),
    isUnresolvedLocationLoading:
      state.locationMatching.isUnresolvedLocationLoading,
    shipment: getShipmentWithUnresolvedLocation(state),
    returnToPreviousScreen: getReturnToPreviousScreen(state),
    lads: LadsState.selectors.getLadsList(state),
    locations: getLocations(state),
    heatmapData: state.locations.heatmap.data,
    heatmapUid: state.locations.heatmap.heatMapUid,
    heatmapIsLoading: state.locations.heatmap.isLoading,
    locationSearchResults: getSearchResults(state),
    isLoading: getIsLoading(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPages(state),
    totalLocations: getTotalEntities(state),
    solutionId: getSolutionId(state)
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchUnresolvedLocationDetails,
    clearUnresolvedLocation,
    setShipmentWithUnresolvedLocation,
    setReturnToPreviousScreen,
    linkLocation
  } = LocationMatchingState.actionCreators;

  return {
    clearUnresolvedLocation: () => dispatch(clearUnresolvedLocation()),
    setShipmentWithUnresolvedLocation: shipment =>
      dispatch(setShipmentWithUnresolvedLocation(shipment)),
    linkLocation: (id, data) => dispatch(linkLocation(id, data, matchLocation)),
    fetchUnresolvedLocationDetails: id =>
      dispatch(fetchUnresolvedLocationDetails(id)),
    fetchLocationDetails: id => dispatch(fetchLocationDetails(id)),
    setReturnToPreviousScreen: value =>
      dispatch(setReturnToPreviousScreen(value)),

    pushShipmentDetailView: shipmentID =>
      dispatch({
        type: "SHIPMENT_DETAIL",
        payload: { shipment_id: shipmentID }
      }),
    pushLocationEditScreen: locID =>
      dispatch({ type: "LOCATION_EDIT", payload: { location_id: locID } }),
    pushLocationsScreen: () => dispatch({ type: "LOCATION_UNRESOLVED" }),
    fetchHeatMapData: locationID => dispatch(fetchHeatMapData(locationID)),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        LocationSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize
        )
      ),
    clearSearchText: () =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchText()),
    clearSearchFilters: () =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchFilters()),
    setSearchFilter: (key, value) =>
      dispatch(
        LocationSearchBarState.actionCreators.setSearchFilter(key, value)
      ),
    clearSearchFilter: key =>
      dispatch(LocationSearchBarState.actionCreators.clearSearchFilter(key)),
    searchLocations: debouncePromise(
      solutionId =>
        dispatch(
          LocationSearchBarState.actionCreators.searchEntities(solutionId)
        ),
      500
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationMatchingView);
