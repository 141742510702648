/** @jsx jsx */
// eslint-disable-next-line
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";
import _ from "lodash";
import styled from "@emotion/styled";
import Loader from "react-loader";
import HeatmapButton from "./HeatmapButton";
import RoutingMap from "../map/components/RoutingMap";
import ShipmentDetailPanel from "./ShipmentDetailPanel";
import MultimodalDetailPanel from "./MultimodalDetailPanel";
import UnderReviewModal from "./UnderReviewModal";
import AddAssetModal from "./AddAssetModal";
import UnassignAssetModal from "./UnassignAssetModal";
import ShipmentEventsModal from "./ShipmentEventsModal";
import CancelShipmentModal from "./CancelShipmentModal";
import MobileTrackingModal from "./MobileTrackingModal";
import { isCarrier } from "../../routes";
import {
  getAssetID,
  getRouteShipments
} from "../shipment-detail/ShipmentUtils";
import {
  getMobileTrackingEnabled,
  getShipmentCompleted
} from "./ShipmentUtils";
import { MediaQueries, useIsMediumAndDown } from "../../components/responsive";
import Colors from "../../styles/colors";
import MixpanelUtils from "../../trackers/mixpanel";

export const Section = styled.section({
  position: "relative",
  display: "flex",
  flex: 0.5,
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100vh - 3.3em)",
  backgroundColor: "white",
  maxWidth: "50%"
});

const OneColumnSection = styled(Section)({
  height: "calc(40vh - 3em)",
  maxWidth: "100%"
});

export const ResponsiveSection = props => {
  const isMobile = useIsMediumAndDown();
  const SectionComponent = isMobile ? OneColumnSection : Section;
  return <SectionComponent {...props}>{props.children}</SectionComponent>;
};

ResponsiveSection.propTypes = {
  children: PropTypes.node.isRequired
};

export default class ShipmentDetailView extends Component {
  static propTypes = {
    customerView: PropTypes.bool, // If true, scac and creatorShipmentID must come in the location payload
    pushLocationMatchingView: PropTypes.func.isRequired,
    shipment: PropTypes.object.isRequired,
    routeHeatmap: PropTypes.array,
    setShipmentWithUnresolvedLocation: PropTypes.func.isRequired,
    shipmentID: PropTypes.any,
    fetchShipmentDetails: PropTypes.func.isRequired,
    fetchShipmentDetailsFromCarrierInfo: PropTypes.func.isRequired,
    fetchRouteHeatmap: PropTypes.func.isRequired,
    ladsList: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
      .isRequired,
    selectCoordinate: PropTypes.func.isRequired,
    clearCoordinate: PropTypes.func.isRequired,
    assignAsset: PropTypes.func.isRequired,
    unassignAsset: PropTypes.func.isRequired,
    assignTrailer: PropTypes.func.isRequired,
    createShipmentEvent: PropTypes.func.isRequired,
    updateReviewStatus: PropTypes.func.isRequired,
    activeOrganization: PropTypes.object.isRequired,
    cancelShipment: PropTypes.func.isRequired,
    startMobileTracking: PropTypes.func.isRequired,
    pushShipmentDetailView: PropTypes.func.isRequired,
    shipmentModes: PropTypes.array.isRequired,
    selectedCoordinate: PropTypes.object,
    organizations: PropTypes.array,
    childShipments: PropTypes.object.isRequired,
    finishedVehicleView: PropTypes.node.isRequired,
    scac: PropTypes.string,
    creatorShipmentID: PropTypes.string,
    fetchShipmentDetailsFromVin: PropTypes.func.isRequired,
    vin: PropTypes.string,
    childShipmentID: PropTypes.string,
    stopMobileTracking: PropTypes.func.isRequired,
    isShipmentLoaded: PropTypes.bool.isRequired,
    isLoaded: PropTypes.bool.isRequired
  };

  static defaultProps = {
    // This prop controls how we're fetching the shipment details.
    // It also signals the location path we're coming from:
    //
    // If false (default), we will fetch the details using the shipment_id provided
    // in the location payload (/shipments/:shipment_id), otherwise it means we're
    // coming from /carriers/:scac/shipments/:creator_shipment_id so
    // will instead use the carrier info in that payload to fetch the data.
    customerView: false,
    // Similar trigger for Finished Vehicle case.
    finishedVehicleView: false
  };

  constructor(props) {
    super(props);

    this.state = {
      showUnderReviewModal: false,
      showAddAssetModal: false,
      showAddShipmentEventsModal: false,
      showEnableMobileTrackingModal: false,
      showDisableMobileTrackingModal: false,
      showHeatmap: false,
      showUnassignAssetModal: false,
      selectedLegID: null
    };

    this.getReviewStatus = this.getReviewStatus.bind(this);
    this.eventHandler = this.eventHandler.bind(this);
  }

  componentDidMount() {
    const {
      customerView,
      finishedVehicleView,
      shipmentID,
      scac,
      creatorShipmentID,
      fetchShipmentDetails,
      fetchShipmentDetailsFromCarrierInfo,
      fetchShipmentDetailsFromVin,
      vin
    } = this.props;

    /* H2-268 add another case for loading Finishined Vehicle Shipment Details */
    if (customerView) {
      fetchShipmentDetailsFromCarrierInfo(scac, creatorShipmentID);
    } else if (finishedVehicleView) {
      fetchShipmentDetailsFromVin(scac, creatorShipmentID, vin);
    } else {
      fetchShipmentDetails(shipmentID);
    }

    MixpanelUtils.track("Viewed Page: Shipments / Details");
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;
    const {
      customerView,
      finishedVehicleView,
      shipmentID,
      scac,
      creatorShipmentID,
      shipment,
      fetchShipmentDetails,
      fetchShipmentDetailsFromCarrierInfo,
      fetchShipmentDetailsFromVin,
      fetchRouteHeatmap,
      vin
    } = prevProps;

    if (
      customerView &&
      (scac !== nextProps.scac ||
        creatorShipmentID !== nextProps.creatorShipmentID)
    ) {
      fetchShipmentDetailsFromCarrierInfo(
        nextProps.scac,
        nextProps.creatorShipmentID
      );
    } else if (
      finishedVehicleView &&
      (scac !== nextProps.scac ||
        creatorShipmentID !== nextProps.creatorShipmentID ||
        vin !== nextProps.vin)
    ) {
      fetchShipmentDetailsFromVin(
        nextProps.scac,
        nextProps.creatorShipmentID,
        nextProps.vin
      );
    } else if (shipmentID !== nextProps.shipmentID) {
      fetchShipmentDetails(nextProps.shipmentID);
    }

    if (shipment !== nextProps.shipment) {
      fetchRouteHeatmap(nextProps.shipment.route_number);
    }
  }

  getReviewStatus(shipment) {
    return shipment.active_exceptions_ng === "Under Review";
  }

  eventHandler(val, type) {
    const {
      pushLocationMatchingView,
      shipment,
      setShipmentWithUnresolvedLocation
    } = this.props;

    switch (type) {
      case "EDIT_REVIEW_STATUS":
        this.setState({ showUnderReviewModal: true });
        break;
      case "SHOW_ADD_ASSET":
        this.setState({ showAddAssetModal: true });
        break;
      case "SHOW_ADD_EVENTS":
        this.setState({ showShipmentEventsModal: true });
        break;
      case "SHOW_CANCEL_SHIPMENT":
        this.setState({ showCancelShipmentModal: true });
        break;
      case "TOGGLE_ENABLE_MOBILE_TRACKING":
        this.setState({ showEnableMobileTrackingModal: true });
        break;
      case "TOGGLE_DISABLE_MOBILE_TRACKING":
        this.setState({ showDisableMobileTrackingModal: true });
        break;
      case "TIMELINE_LAD_CLICK":
        setShipmentWithUnresolvedLocation(shipment);
        pushLocationMatchingView(val.id);
        break;
      case "SHOW_UNASSIGN_ASSET":
        this.setState({ showUnassignAssetModal: true });
        break;
      case "SELECT_LEG":
        this.setState({ selectedLegID: val });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      shipment,
      childShipmentID,
      childShipments,
      routeHeatmap,
      ladsList,
      selectCoordinate,
      clearCoordinate,
      selectedCoordinate,
      assignAsset,
      assignTrailer,
      createShipmentEvent,
      updateReviewStatus,
      activeOrganization,
      cancelShipment,
      organizations,
      startMobileTracking,
      stopMobileTracking,
      pushShipmentDetailView,
      shipmentModes,
      isShipmentLoaded,
      unassignAsset,
      finishedVehicleView,
      scac,
      vin,
      isLoaded
    } = this.props;

    const {
      showUnderReviewModal,
      showAddAssetModal,
      showShipmentEventsModal,
      showCancelShipmentModal,
      showEnableMobileTrackingModal,
      showDisableMobileTrackingModal,
      showHeatmap,
      showUnassignAssetModal,
      selectedLegID
    } = this.state;
    const enableActionMenu = isCarrier(activeOrganization);
    const enableReviewStatus = enableActionMenu;
    const enableShipmentEvents = enableActionMenu;
    // H1-1944: remove Cancel Shipment from action menu if shipment is already
    // canceled
    const isAlreadyCanceled = shipment.active_status === "canceled";
    const enableCancelShipment = enableActionMenu && !isAlreadyCanceled;
    const assetID = getAssetID(activeOrganization, shipment, shipmentModes);

    let routeShipments = getRouteShipments(
      shipment,
      childShipments,
      selectedLegID,
      isLoaded
    );

    const isMultimodal =
      shipment.mode_name && shipment.mode_name.toLowerCase() === "multimodal";
    return (
      <Loader loaded={isLoaded}>
        <div
          css={{
            display: "flex",
            [MediaQueries.mediumAndDown]: {
              flexDirection: "column"
            },
            [MediaQueries.largeAndUp]: {
              flexDirection: "row"
            }
          }}
        >
          <ResponsiveSection>
            <RoutingMap
              ladsList={ladsList}
              shipments={routeShipments}
              showBreadCrumbs={true}
              showStopSequence={true}
              showHeatmap={showHeatmap}
              heatmapCoords={
                routeHeatmap && routeHeatmap.length > 0
                  ? routeHeatmap
                  : _.get(shipment, "current_location.updates")
              }
              isMultimodal={isMultimodal}
              activeShipment={
                isMultimodal
                  ? shipment && shipment.active_shipment
                    ? shipment.active_shipment.toString()
                    : null
                  : null
              }
            />
            <HeatmapButton
              toggled={showHeatmap}
              onToggle={() => this.setState({ showHeatmap: !showHeatmap })}
            />
          </ResponsiveSection>
          <ResponsiveSection
            css={{
              backgroundColor: Colors.background.LIGHT_GRAY,
              [MediaQueries.largeAndUp]: {
                overflow: "auto"
              }
            }}
          >
            {shipment.mode_name &&
            shipment.mode_name.toLowerCase() === "multimodal" ? (
              <MultimodalDetailPanel
                parentShipment={shipment}
                childShipments={childShipments}
                selectedChildShipmentID={childShipmentID}
                ladsList={ladsList}
                selectCoordinate={selectCoordinate}
                clearCoordinate={clearCoordinate}
                selectedCoordinate={selectedCoordinate}
                eventHandler={this.eventHandler}
                enableActionMenu={enableActionMenu}
                enableReviewStatus={enableReviewStatus}
                enableShipmentEvents={enableShipmentEvents}
                enableCancelShipment={enableCancelShipment}
                organization={activeOrganization}
                pushShipmentDetailView={pushShipmentDetailView}
                shipmentModes={shipmentModes}
                isShipmentLoaded={isShipmentLoaded}
                finishedVehicleView={finishedVehicleView}
                vin={vin}
                scac={scac}
                isLoaded={isLoaded}
              />
            ) : (
              <ShipmentDetailPanel
                shipment={shipment}
                ladsList={ladsList}
                selectCoordinate={selectCoordinate}
                clearCoordinate={clearCoordinate}
                selectedCoordinate={selectedCoordinate}
                eventHandler={this.eventHandler}
                enableActionMenu={enableActionMenu}
                enableReviewStatus={enableReviewStatus}
                enableShipmentEvents={enableShipmentEvents}
                enableCancelShipment={enableCancelShipment}
                organization={activeOrganization}
                pushShipmentDetailView={pushShipmentDetailView}
                shipmentModes={shipmentModes}
                isShipmentLoaded={isShipmentLoaded}
                finishedVehicleView={finishedVehicleView}
                vin={vin}
                scac={scac}
                isLoaded={isLoaded}
                showLinkedShipments
              />
            )}
          </ResponsiveSection>
          {enableReviewStatus && (
            <UnderReviewModal
              show={showUnderReviewModal}
              shipment={shipment}
              updateReviewStatus={updateReviewStatus}
              reviewStatus={this.getReviewStatus(shipment)}
              hide={() => this.setState({ showUnderReviewModal: false })}
            />
          )}
          {enableShipmentEvents && (
            <AddAssetModal
              show={showAddAssetModal}
              assignAsset={assignAsset}
              assignTrailer={assignTrailer}
              shipment={shipment}
              organization={activeOrganization}
              organizations={organizations}
              hide={() => this.setState({ showAddAssetModal: false })}
            />
          )}
          {enableShipmentEvents && (
            <UnassignAssetModal
              show={showUnassignAssetModal}
              assetID={assetID}
              shipment={shipment}
              organization={activeOrganization}
              organizations={organizations}
              unassignAsset={unassignAsset}
              hide={() => this.setState({ showUnassignAssetModal: false })}
            />
          )}
          {enableShipmentEvents && (
            <ShipmentEventsModal
              show={showShipmentEventsModal}
              createShipmentEvent={createShipmentEvent}
              shipment={shipment}
              organization={activeOrganization}
              organizations={organizations}
              hide={() => this.setState({ showShipmentEventsModal: false })}
            />
          )}
          {enableCancelShipment && (
            <CancelShipmentModal
              show={showCancelShipmentModal}
              cancelShipment={cancelShipment}
              shipment={shipment}
              organization={activeOrganization}
              organizations={organizations}
              hide={() => this.setState({ showCancelShipmentModal: false })}
            />
          )}
          {!getMobileTrackingEnabled(shipment) &&
            !getShipmentCompleted(shipment) && (
              <MobileTrackingModal
                show={showEnableMobileTrackingModal}
                startMobileTracking={startMobileTracking}
                shipment={shipment}
                organization={activeOrganization}
                organizations={organizations}
                toEnable={true}
                hide={() =>
                  this.setState({ showEnableMobileTrackingModal: false })
                }
              />
            )}
          {getMobileTrackingEnabled(shipment) &&
            !getShipmentCompleted(shipment) && (
              <MobileTrackingModal
                show={showDisableMobileTrackingModal}
                stopMobileTracking={stopMobileTracking}
                shipment={shipment}
                organization={activeOrganization}
                organizations={organizations}
                toEnable={false}
                hide={() =>
                  this.setState({ showDisableMobileTrackingModal: false })
                }
              />
            )}
        </div>
      </Loader>
    );
  }
}
