const HASH_PRECISION = 4;

export function hashNum(numstring) {
  return Number(numstring).toFixed(HASH_PRECISION);
}

export const stopPositionStringify = stop =>
  `${stop.location.geofence.properties.center.latitude},${stop.location.geofence.properties.center.longitude}`;

export const shipmentUpdateStringify = pos =>
  `${pos.latitude},${pos.longitude}`;

export function stopHasLocation(stop) {
  return (
    stop.location.geofence.properties.center.latitude != null &&
    stop.location.geofence.properties.center.longitude != null
  );
}

export function getStopLatLong(stop) {
  if (stopHasLocation(stop)) {
    return {
      lat: stop.location.geofence.properties.center.latitude,
      lng: stop.location.geofence.properties.center.longitude
    };
  }

  return { lat: "0", lng: "0" };
}

export function updateHasLocation(update) {
  return (
    update !== undefined &&
    update.latitude !== null &&
    update.longitude !== null
  );
}

export function getUpdateLatLong(update) {
  if (updateHasLocation(update)) {
    return {
      lat: update.latitude,
      lng: update.longitude
    };
  }

  return { lat: "0", lng: "0" };
}

export const getStopModes = (stops, index, defaultMode) => {
  const stop = stops[index];
  const stopMode = stop.mode_id ? stop.mode_id : defaultMode;
  let nextStopMode = stopMode;

  // Try to get the next stop mode if we can
  if (index < stops.length - 1) {
    if (stops[index + 1].mode_id) {
      nextStopMode = stops[index + 1].mode_id;
    }
  }

  return {
    stopMode: stopMode,
    nextStopMode: nextStopMode
  };
};

export const getPositionsForShipments = shipmentHash => {
  let positions = [];
  for (let hash in shipmentHash) {
    const shipmentList = shipmentHash[hash];

    for (let shipmentIndex in shipmentList) {
      const shipment = shipmentList[shipmentIndex];
      for (let stopIndex in shipment.shipment_stops) {
        const stop = shipment.shipment_stops[stopIndex];
        if (stopHasLocation(stop)) {
          const pos = getStopLatLong(stop);
          positions.push(pos);
        } else {
          console.log("Stop without location details:", stop);
        }
      }
    }
  }
  return positions;
};
