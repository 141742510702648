import validator from "validator";
import _ from "lodash";

import { Countries } from "./LocationEditData";

const isNotEmpty = text => {
  return !validator.isEmpty(text, { ignore_whitespace: true });
};

export const isFullAddressGeocodable = ({
  addressLine1,
  addressLine2,
  city,
  state,
  postal_code,
  country
}) => {
  return (
    isNotEmpty(state) ||
    isNotEmpty(city) ||
    isPostalCodeValid(postal_code, country)
  );
};

const FvCountries = new Set(_.map(Countries, "value"));

export const isCountryCodeValidForFV = countryCode => {
  return FvCountries.has(countryCode.toUpperCase());
};

export const isPostalCodeValid = (postalCode, countryCode) => {
  return (
    countryCode &&
    postalCode &&
    isCountryCodeValidForFV(countryCode) &&
    validator.isPostalCode(postalCode, countryCode.toUpperCase())
  );
};
