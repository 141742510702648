import moment from "moment";
import axios from "axios";
import _ from "lodash";

import { SEARCH_CATEGORIES } from "./ShipmentSearchCategoryDefs";
import { FILTERS } from "./ShipmentSearchFilterDefs";
import apiUrl from "../../api-url";
import chainReducers from "../../vendor/signal-utils/chain-reducers";
import buildSearchBarState from "../../components/search-bar/SearchBarStateBuilder";
import {
  searchLocations,
  asyncSearchLocations,
  locationsReducer
} from "../location-search/LocationSearchStateExtensions";
import { searchVins, vinsReducer } from "./VinSearchStateExtensions";

// Helpers

const STORE_MOUNT_POINT = "search";

// NOTE: solutionId parameter is here to follow pattern required by
// exportEntities
const shipmentsUrl = (solutionId, queryString) => {
  return apiUrl(`/shipping-ng/shipments?${queryString}`);
};

// NOTE: solutionId parameter is here to follow pattern required by
// exportEntities
const batchSearchUrl = (solutionId, queryString, batchType) => {
  return apiUrl(
    `/shipping-ng/batch_shipments?${queryString}&batchType=${batchType}`
  );
};

const axiosConfig = () => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess()
    }
  };
};

// Actions

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  const batchFilter = state[STORE_MOUNT_POINT].searchFilters.batch;

  if (batchFilter) {
    // Batch search POST
    batchSearch(queryString, dispatch, batchFilter);
  } else {
    // Normal search GET
    const url = shipmentsUrl(null, queryString);
    const config = axiosConfig();
    dispatch(duck.fetch(url, config));
    dispatch({ type: "SHIPMENT_SEARCH" });
  }
};

const batchSearch = (queryString, dispatch, batchFilter) => {
  const url = batchSearchUrl(null, queryString, batchFilter.batch_type);
  const data = {
    batch_list: batchFilter.batch_list
  };
  const config = axiosConfig();

  dispatch({
    type: `Search/REQUEST_SEARCH`
  });

  return Promise.all([axios.post(url, data, config)])
    .then(responses => {
      dispatch({
        type: `Search/RECEIVE_SEARCH`,
        payload: responses ? responses[0].data : []
      });
      dispatch({ type: "SHIPMENT_SEARCH" });
    })
    .catch(err => {
      dispatch({
        type: `Search/REQUEST_SEARCH_ERROR`,
        err
      });
    });
};

// Additional selectors for async search results
const getLocations = state => state[STORE_MOUNT_POINT].locations || [];
const getLocationsLoading = state =>
  state[STORE_MOUNT_POINT].locationsLoading || false;
const getVins = state => state[STORE_MOUNT_POINT].vins || [];
const getVinsLoading = state => state[STORE_MOUNT_POINT].vinsLoading || false;

// Override the exported interface to expose our replacements
const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [],
  {
    defaultSort: "destination_earliest_arrival"
  }
);

SearchBarState.actionCreators = {
  ...SearchBarState.actionCreators,
  exportSearch: _.partial(
    SearchBarState.actionCreators.exportEntities,
    shipmentsUrl,
    batchSearchUrl,
    { headers: { Accept: "text/csv;version=basic" } },
    "shipment-search-results"
  ),
  searchLocations,
  asyncSearchLocations,
  searchVins
};

SearchBarState.selectors = {
  ...SearchBarState.selectors,
  getLocations,
  getLocationsLoading,
  getVins,
  getVinsLoading
};

SearchBarState.reducer = chainReducers([
  SearchBarState.reducer,
  locationsReducer,
  vinsReducer
]);

export default SearchBarState;
