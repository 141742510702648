import Colors from "../../../styles/colors";

export const vinHoldsFill = data => {
  const colors = Object.keys(Colors.holds);
  return data.map((d, i) => {
    return {
      ...d,
      // H2-940: Add Hold Code next to Description in Parentheses
      // Generating the full display text here to be used wherever it is needed
      fullDescription: `${d.type} (${d.reason_code})`,
      fill: Colors.holds[colors[i]]
    };
  });
};
