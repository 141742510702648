import { connect } from "react-redux";

import MetalTrackingSearchResultsView from "./MetalTrackingSearchResultsView";
import MetalTrackingSavedSearchState from "../mt-search/MetalTrackingSavedSearchState";
import MetalTrackingSearchBarState, {
  toggleWatchedRackFlag
} from "../mt-search/MetalTrackingSearchBarState";

import { getSolutionId } from "../organizations/OrganizationsState";
import { getAuthorization } from "../auth/AuthorizationSelectors";

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    savedSearch: MetalTrackingSearchBarState.selectors.getSelectedSavedSearch(
      state
    ),
    searchResults: MetalTrackingSearchBarState.selectors.getSearchResults(
      state
    ),
    showFilters: MetalTrackingSearchBarState.selectors.getShowAdvancedSearch(
      state
    ),
    isLoading: MetalTrackingSearchBarState.selectors.getIsLoading(state),
    page: MetalTrackingSearchBarState.selectors.getPage(state),
    pageSize: MetalTrackingSearchBarState.selectors.getPageSize(state),
    totalPages: MetalTrackingSearchBarState.selectors.getTotalPages(state),
    totalEntities: MetalTrackingSearchBarState.selectors.getTotalEntities(
      state
    ),
    solutionId: getSolutionId(state),
    isExporting: MetalTrackingSearchBarState.selectors.getIsExporting(state),
    exportFailed: MetalTrackingSearchBarState.selectors.getExportFailed(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSavedSearch: savedSearch =>
      dispatch(
        MetalTrackingSavedSearchState.actionCreators.loadSavedSearch(
          savedSearch
        )
      ),
    pushRackDetailView: entityId => {
      return; // TODO: enable it after creating RACK_DETAIL view
      // eslint-disable-next-line
      dispatch({
        type: "METAL_TRACKING_RACK_DETAIL",
        payload: { entity_id: entityId }
      });
    },
    toggleShowFilters: showFilters =>
      dispatch(
        MetalTrackingSearchBarState.actionCreators.toggleShowFilters(
          showFilters
        )
      ),
    setPagination: (solutionId, page, pageSize) =>
      dispatch(
        MetalTrackingSearchBarState.actionCreators.setPagination(
          solutionId,
          page,
          pageSize
        )
      ),
    toggleWatchedRackFlag: (solutionId, entity) =>
      dispatch(toggleWatchedRackFlag(solutionId, entity))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MetalTrackingSearchResultsView);
