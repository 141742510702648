/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";
import AdcEntitiesState from "../AdcEntitiesState";
import AdcLocationsState from "../AdcLocationsState";
import { getSolutionId } from "../../organizations/OrganizationsState";
import ResultsTable from "../../../components/tables/ResultsTable";
import Colors from "../../../styles/colors";
import {
  WatchColumn,
  NameAndLocationColumn,
  DemandAreasColumn,
  TotalOnsiteVinsColumn,
  TotalAgingVinsColumn,
  CapacityStatusColumn
} from "../../../modules/adc-search-results/AdcSearchResultsColumns";

export const WatchedLocationsTable = props => {
  const {
    watchedLocations,
    isWatchedLocationsLoading,
    fetchWatchedLocations,
    pushAdcDetailsView
  } = props;
  const { t } = useTranslation("fv-dashboard");

  const [watchedLocationEntities, setWatchedLocationEntities] = useState([]);

  useEffect(() => {
    fetchWatchedLocations();
  }, [fetchWatchedLocations]);

  useEffect(() => {
    // Add watch status to the existing location entries
    if (watchedLocations && watchedLocations.length > 0) {
      setWatchedLocationEntities(
        watchedLocations.map(location => ({
          ...location,
          watch: true
        }))
      );
    }
  }, [watchedLocations]);

  const DarkNameAndLocationColumn = {
    ...NameAndLocationColumn,
    style: {
      ...NameAndLocationColumn.style,
      color: Colors.holds.DARK_BLUE,
      display: "block"
    }
  };

  return (
    <div>
      <h3 css={{ fontSize: 16 }}>{t("fv-dashboard:Watched Locations")}</h3>
      <div css={{ position: "relative", minHeight: 54 }}>
        <Loader loaded={!isWatchedLocationsLoading} />
        <ResultsTable
          data={watchedLocationEntities}
          columns={[
            WatchColumn,
            DarkNameAndLocationColumn,
            DemandAreasColumn,
            TotalOnsiteVinsColumn,
            TotalAgingVinsColumn,
            CapacityStatusColumn
          ]}
          rowHeight={110}
          cellClickHandler={(state, rowInfo, column, instance) => {
            if (
              column.id === WatchColumn.id ||
              column.id === DemandAreasColumn.id
            ) {
              return;
            } else {
              pushAdcDetailsView(rowInfo.original.locationId);
            }
          }}
          cellCursorPointerEnabled={true}
          fixPaginationToBottom={false}
        />
      </div>
    </div>
  );
};

WatchedLocationsTable.propTypes = {
  watchedLocations: PropTypes.array,
  isWatchedLocationsLoading: PropTypes.bool,
  fetchWatchedLocations: PropTypes.func.isRequired,
  setWatchLocation: PropTypes.func.isRequired,
  pushAdcDetailsView: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  const {
    getWatchedLocations,
    getIsWatchedLocationsLoading
  } = AdcEntitiesState.selectors;

  return {
    solutionId: getSolutionId(state),
    watchedLocations: getWatchedLocations(state),
    isWatchedLocationsLoading: getIsWatchedLocationsLoading(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    fetchWatchedLocations,
    setWatchLocation
  } = AdcEntitiesState.actionCreators;
  const { pushAdcDetailsView } = AdcLocationsState.actionCreators;

  return {
    fetchWatchedLocations: () => dispatch(fetchWatchedLocations()),
    setWatchLocation: (locationId, watch) =>
      dispatch(setWatchLocation(locationId, watch)),
    pushAdcDetailsView: locationId => dispatch(pushAdcDetailsView(locationId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchedLocationsTable);
