/** @jsx jsx */
import { jsx } from "@emotion/core";
import _ from "lodash";
import { Component } from "react";
import { Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import EndToEndTabPanel from "./EndToEndTabPanel";
import LegTabPageButtons from "./LegTabPageButtons";
import { getLads } from "../../fv-vin-details/vin-details-styled-components/TripProgressBarNew";
import LastUpdateDisplay from "../shipment-detail-styled-components/LastUpdateDisplay";
import ShipmentDetailTabs from "../ShipmentDetailTabs";
import { ShipmentTimeline } from "../ShipmentDetailPanel";
import {
  tabsCss,
  tabPanelStyle
} from "../../../components/multimodal-components/tabStyles";
import ShipmentDetailsCollapsible from "../shipment-detail-styled-components/ShipmentDetailsCollapsible";
import ArrivalDepartureCollapsible from "../shipment-detail-styled-components/ArrivalDepartureCollapsible";
import {
  endToEndTab,
  legTabs
} from "../../../components/multimodal-components/tabComponents";
import { getAssetID, getReferenceValue } from "../ShipmentUtils";
import "./shipment-tab-styles.css";
import { getLocationDataFromShipment } from "../shipment-detail-styled-components/OriginDestinationBox";

export const lastUpdateDisplayCss = {
  margin: "0 1em",
  paddingTop: 10,
  " > div": {
    margin: 0
  },
  div: {
    fontSize: "1em !important"
  }
};

export const shipmentDetailTabsCss = {
  paddingBottom: 10,
  ".react-tabs__tab": {
    backgroundColor: "#ddd",
    height: 66
  },
  ".react-tabs__tab--selected": {
    backgroundColor: "#fff",
    borderColor: "transparent",
    height: "66px !important",
    marginTop: "4px !important"
  },
  ".react-tabs__tab-panel": {
    backgroundColor: "#fff",
    borderColor: "transparent !important"
  },
  ".react-tabs__tab-panel > div": {
    padding: "0 10px"
  },
  ".table-condensed > thead > tr > th": {
    borderBottomWidth: 1,
    color: "#8c8888",
    fontWeight: "normal",
    padding: 10
  },
  ".table-condensed > tbody > tr > td": {
    padding: 10
  }
};

const endToEndPanel = (
  parentShipment,
  childShipments,
  lads,
  shipmentModes,
  selectCoordinate,
  clearCoordinate,
  selectedCoordinate
) => {
  return (
    <TabPanel style={{ border: "1px solid #aaa" }} key="end-to-end">
      <EndToEndTabPanel
        parentShipment={parentShipment}
        childShipments={childShipments}
        lads={lads}
        shipmentModes={shipmentModes}
        selectCoordinate={selectCoordinate}
        clearCoordinate={clearCoordinate}
        selectedCoordinate={selectedCoordinate}
      />
    </TabPanel>
  );
};

const legPanels = props => {
  const {
    parentShipment,
    childShipments,
    selectCoordinate,
    clearCoordinate,
    selectedCoordinate,
    organization,
    shipmentModes,
    eventHandler
  } = props;
  const childSummaries = parentShipment.child_shipments || [];
  const childDetails = childShipments;
  return childSummaries.map((summary, i) => {
    const leg = `Leg ${i + 1}`;

    const shipment = childDetails[summary.shipment_id];

    if (!shipment) {
      return null;
    }

    // Shipment/Shipper Details
    const shipmentId = shipment.creator_shipment_id;
    const assetId =
      organization && shipment && shipmentModes
        ? getAssetID(organization, shipment, shipmentModes)
        : null;
    const mode = shipment ? shipment.mode_name : "";
    const status = shipment
      ? `${shipment.active_status_ng} ${shipment.current_status_ng}`
      : "";

    const shipperName = shipment.shipper_name;
    const carrierName = shipment.carrier_name;
    const carrierScac = shipment.carrier_scac;

    let trailerNumber = null;
    if (mode === "Rail") {
      trailerNumber = getReferenceValue(shipment, "equipment_number");
    } else {
      trailerNumber = shipment.trailer_number;
    }

    const exception = shipment ? shipment.active_exceptions_ng : "";

    // Arrivals/Departures
    const { origin, destination } = getLocationDataFromShipment(shipment, null);

    return shipment ? (
      <TabPanel style={tabPanelStyle} key={summary.shipment_id}>
        <ShipmentDetailsCollapsible
          shipmentId={shipmentId}
          assetId={leg ? assetId : null}
          mode={mode}
          showMadChiclet={!_.isEmpty(mode)}
          status={status}
          shipperName={
            (parentShipment && parentShipment.shipper_name) ||
            (shipment.shipper_name && !parentShipment)
              ? shipperName
              : null
          }
          carrierName={carrierName}
          carrierScac={carrierScac}
          trailerNumber={trailerNumber}
          exception={exception}
        />
        <ArrivalDepartureCollapsible
          shipment={shipment}
          destShipment={null}
          origin={origin}
          destination={destination}
        />
        <ShipmentTimeline
          shipment={shipment}
          organization={organization}
          shipmentModes={shipmentModes}
          hidePercentage={false}
          eventHandler={eventHandler}
        />
        <div css={lastUpdateDisplayCss}>
          <LastUpdateDisplay shipment={shipment} />
        </div>
        <div css={shipmentDetailTabsCss}>
          <ShipmentDetailTabs
            shipment={shipment}
            selectCoordinate={selectCoordinate}
            clearCoordinate={clearCoordinate}
            selectedCoordinate={selectedCoordinate}
            organization={organization}
          />
        </div>
      </TabPanel>
    ) : null;
  });
};

class ShipmentLegTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabPageIndex: 0,
      tabPageSize: 4,
      tabPageCount: 1,
      showNextTabPageButton: false,
      showPrevTabPageButton: false
    };

    this.resetTabPaging = this.resetTabPaging.bind(this);
    this.isPrevTabPageButtonVisible = this.isPrevTabPageButtonVisible.bind(
      this
    );
    this.isNextTabPageButtonVisible = this.isNextTabPageButtonVisible.bind(
      this
    );
    this.showNextTabPage = this.showNextTabPage.bind(this);
    this.showPrevTabPage = this.showPrevTabPage.bind(this);
  }

  resetTabPaging(parentShipment) {
    const tabCount = parentShipment.child_shipments.length;
    const tabPageCount = tabCount / this.state.tabPageSize;
    this.setState({ tabPageIndex: 0, tabPageCount: tabPageCount });
  }

  isPrevTabPageButtonVisible(pageIndex) {
    const minTabPage = 0;
    return pageIndex > minTabPage;
  }

  isNextTabPageButtonVisible(pageIndex) {
    const maxTabPage = this.state.tabPageCount - 1;
    return pageIndex < maxTabPage;
  }

  showNextTabPage() {
    const maxTabPage = this.state.tabPageCount - 1;
    const currentTabPage = this.state.tabPageIndex;
    const nextTabPage =
      currentTabPage >= maxTabPage ? maxTabPage : currentTabPage + 1;

    this.setState({
      tabPageIndex: nextTabPage,
      showPrevTabPageButton: this.isPrevTabPageButtonVisible(nextTabPage),
      showNextTabPageButton: this.isNextTabPageButtonVisible(nextTabPage)
    });
  }

  showPrevTabPage() {
    const minTabPage = 0;
    const currentTabPage = this.state.tabPageIndex;
    const prevTabPage =
      currentTabPage <= minTabPage ? minTabPage : currentTabPage - 1;

    this.setState({
      tabPageIndex: prevTabPage,
      showPrevTabPageButton: this.isPrevTabPageButtonVisible(prevTabPage),
      showNextTabPageButton: this.isNextTabPageButtonVisible(prevTabPage)
    });
  }

  componentDidMount() {
    const { parentShipment } = this.props;
    this.resetTabPaging(parentShipment);
  }

  componentDidUpdate(prevProps) {
    const { parentShipment, childShipments } = this.props;

    if (parentShipment && parentShipment !== prevProps.parentShipment) {
      this.resetTabPaging(parentShipment);
    }

    if (childShipments && childShipments !== prevProps.childShipments) {
      this.setState({
        tabPageIndex: 0,
        showPrevTabPageButton: this.isPrevTabPageButtonVisible(0),
        showNextTabPageButton: this.isNextTabPageButtonVisible(0)
      });
    }
  }

  render() {
    const {
      parentShipment,
      childShipments,
      selectedChildShipmentID,
      ladsList,
      shipmentModes,
      eventHandler,
      selectCoordinate,
      clearCoordinate,
      selectedCoordinate
    } = this.props;
    const {
      tabPageIndex,
      tabPageSize,
      showPrevTabPageButton,
      showNextTabPageButton
    } = this.state;
    const childSummaries = parentShipment.child_shipments || [];
    const childDetails = childShipments;
    const tabs = [
      endToEndTab(showPrevTabPageButton, eventHandler),
      ...legTabs(
        childSummaries,
        childDetails,
        tabPageIndex,
        tabPageSize,
        eventHandler,
        true
      )
    ];
    const lads = getLads(ladsList);
    const panels = [
      endToEndPanel(
        parentShipment,
        childDetails,
        lads,
        shipmentModes,
        selectCoordinate,
        clearCoordinate,
        selectedCoordinate
      ),
      ...legPanels({ ...this.props, lads })
    ];
    const tabListStyle = {
      display: "flex",
      paddingRight: showNextTabPageButton ? 50 : 10
    };
    let selectedTabIndex = 0;
    if (selectedChildShipmentID && parentShipment && parentShipment.child_ids) {
      selectedTabIndex =
        parentShipment.child_ids.indexOf(selectedChildShipmentID) + 1;
    }

    return (
      <Tabs css={tabsCss} defaultIndex={selectedTabIndex}>
        <LegTabPageButtons
          showPrevTabPageButton={showPrevTabPageButton}
          showNextTabPageButton={showNextTabPageButton}
          showPrevTabPage={this.showPrevTabPage}
          showNextTabPage={this.showNextTabPage}
        />
        <TabList style={tabListStyle}>{tabs}</TabList>
        {panels}
      </Tabs>
    );
  }
}

ShipmentLegTabs.propTypes = {
  parentShipment: PropTypes.object,
  childShipments: PropTypes.object
};
export default ShipmentLegTabs;
