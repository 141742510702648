/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import { getAuthorization } from "../../auth/AuthorizationSelectors";
import { getActiveOrganization } from "../../organizations/OrganizationsState";
import RolesState from "../../roles/RolesState";
import { updateUser } from "../UsersState";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput,
  SelectInput,
  FormButton
} from "../../../components/modal-elems";
import { filterRoleTypes } from "./modal-utils";
import Colors from "../../../styles/colors";
import { FlexRowDiv } from "../../../styles/container-elements";

const isValidForm = ({ selectedRoleTypes }) => {
  return selectedRoleTypes && selectedRoleTypes.length > 0 ? true : false;
};

const initUser = {
  id: "",
  email: "",
  selectedRoleTypes: null
};

const EditUserModal = ({
  authorization,
  activeOrganization,
  hide,
  roles,
  show,
  t,
  updateUser,
  user
}) => {
  const [editUser, setUser] = useState(initUser);

  const org = useMemo(
    () =>
      activeOrganization && activeOrganization.org_name
        ? activeOrganization.org_name
        : "",
    [activeOrganization]
  );

  const roleTypes = useMemo(() => {
    return roles ? filterRoleTypes(roles, authorization.isFvAdmin()) : [];
  }, [authorization, roles]);

  useEffect(() => {
    const roleIds =
      roles && user
        ? roles.filter(role => role.users.includes(user.user_id)).map(r => r.id)
        : null;

    setUser({
      id: user ? user.user_id : "",
      email: user ? user.email : "",
      selectedRoleTypes: roleIds
    });
  }, [user, roles]);

  const inputHandler = value => {
    setUser(prevState => {
      return { ...prevState, ...value };
    });
  };

  let formattedSelectedRoles = [];
  if (show && editUser.selectedRoleTypes && editUser.selectedRoleTypes.length) {
    editUser.selectedRoleTypes.forEach(roleType => {
      if (typeof roleType === "string") {
        const match = roleTypes.find(({ id }) => id === roleType);
        formattedSelectedRoles.push({
          value: roleType,
          label: match.friendlyName
        });
      } else {
        formattedSelectedRoles.push(roleType);
      }
    });
  }

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <ModalHeader title={t("users:Edit User")} />
      <ModalBody>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={editUser.email}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold"
            }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <SelectInput
            label={t("users:User Roles")}
            options={roleTypes.map(r => {
              return {
                value: r.id,
                label: t(`users:${r.friendlyName}`)
              };
            })}
            changeHandler={inputHandler}
            prop="selectedRoleTypes"
            value={formattedSelectedRoles}
          />
        </FlexRowDiv>
      </ModalBody>
      <ModalFooter>
        <FormButton
          style={{ marginRight: ".5em", border: "1px solid #ccc" }}
          label={t("users:Cancel")}
          clickHandler={() => hide()}
        />
        <FormButton
          disabled={!isValidForm(editUser)}
          variant="success"
          actionType="ACTION"
          style={{ border: "1px solid #ccc" }}
          label={t("users:Update")}
          clickHandler={() => {
            const role_ids = editUser.selectedRoleTypes.map(r => r.value);
            const payload = {
              role_ids: role_ids
            };
            updateUser(editUser.id, payload);
            hide();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

EditUserModal.propTypes = {
  user: PropTypes.object,
  updateUser: PropTypes.func.isRequired,
  authorization: PropTypes.object.isRequired,
  activeOrganization: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  roles: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    roles: RolesState.selectors.getRoles(state)
  };
}

const mapDispatchToProps = {
  updateUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["users"])(EditUserModal));
