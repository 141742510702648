import React from "react";
import { TextCell } from "./vin-details-columns";
import { withTranslation } from "react-i18next";
import CountryName from "../../location/components/CountryName";

class UpdateSubComponent extends React.Component {
  render() {
    const { original } = this.props;

    return (
      <div>
        {(original.subcodeDescription || original.subcode) && (
          <TextCell
            style={{ fontWeight: 600, dispaly: "flex", flexDirection: "row" }}
          >
            {original.subcodeDescription && (
              <span>{original.subcodeDescription}</span>
            )}
            {original.subcode && (
              <span style={{ marginLeft: 5 }}>({original.subcode})</span>
            )}
          </TextCell>
        )}
        {original.statusUpdate && original.statusUpdate.location && (
          <React.Fragment>
            <TextCell>
              {original.statusUpdate.location.name}
              {original.statusUpdate.shipperLocationCode && (
                <React.Fragment>
                  {" "}
                  ({original.statusUpdate.shipperLocationCode})
                </React.Fragment>
              )}
            </TextCell>
            <TextCell>{original.statusUpdate.location.address}</TextCell>
            <TextCell>
              {`${original.statusUpdate.location.city}, ${original.statusUpdate.location.state}, ${original.statusUpdate.location.postalCode},`}
              <CountryName
                countryCode={original.statusUpdate.location.country}
              />
            </TextCell>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation("fv-vin-details")(UpdateSubComponent);
