/**
 * Filter for fields that just check for equality
 */
export const equalsFilter = (item, key, filterValues) => {
  if (!Array.isArray(filterValues)) {
    filterValues = [filterValues];
  }

  for (const filterValue of filterValues) {
    if (String(filterValue) === String(item[key])) {
      return true;
    }
  }
  return false;
};

/**
 * Receives a key and return a filter
 */
export const stringIncludesFilterBuilder = key => {
  return (item, filterValue) => {
    return String(item[key])
      .toLowerCase()
      .includes(String(filterValue).toLowerCase());
  };
};

/**
 * Class name assigned to the element that holds all the filter widgets. Used by
 * the filter popover to position itself; it measures the width of this section
 * to decide how to orient itself when it appears.
 */
export const FILTER_SECTION_CONTAINER_CLASS_NAME =
  "search-bar-filter-container";
