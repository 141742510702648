/** @jsx jsx */
import PropTypes from "prop-types";
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import { useTranslation } from "react-i18next";

export const TabPanelPlaceholder = ({ text }) => (
  <div
    css={{
      height: 80,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontStyle: "italic",
      color: "#d0cfcf"
    }}
  >
    {text}
  </div>
);

TabPanelPlaceholder.propTypes = {
  text: PropTypes.string.isRequired
};

export const PhotoPlaceholder = () => {
  const { t } = useTranslation("components");

  return (
    <div
      css={{
        height: 100,
        maxWidth: 160,
        // width: 160,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        fontStyle: "italic",
        textAlign: "center"
      }}
    >
      {t("components:Photo Unavailable")}
    </div>
  );
};

export const NaPlaceholder = () => {
  const { t } = useTranslation("components");

  return <div>{t("components:N/A")}</div>;
};
