/** @jsx jsx */
import { useState } from "react";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import i18n from "../../../i18n";
import _ from "lodash";
import BoldTextCell from "../../../components/tables/Cell/BoldTextCell";
import LocationShipper from "../components/LocationShipper";
import SimpleAddressCell from "../../../components/tables/Cell/SimpleAddressCell";
import ParentFilter from "../../location-list/components/ParentFilter";
import ShipperFilter from "../../location-list/components/ShipperFilter";
import { getLadLobLabel } from "../../../components/lads";
import { getDefaultUnclassifiedLad } from "../../lads/LadsState";
import { Translation } from "react-i18next";
import { LadChicletCSS as Chiclet } from "../../../components/chiclets";
import { FlexDiv, FlexRowDiv } from "../../../styles/container-elements";
import Colors from "../../../styles/colors";
import { FaSearch, FaAngleDown } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { ReactComponent as LocationGroupIcon } from "../../../assets/icons/location-group.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import LocationTypeSelectFilterModal from "../../location-list/components/LocationTypeSelectFilterModal";

//
// TODO: Move these
/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const CarrierNameRenderer = ({ value }) => {
  return <div>{value}</div>;
};

CarrierNameRenderer.propTypes = {
  value: PropTypes.string
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const ShipperCellRenderer = props => {
  return <LocationShipper shipperId={props.original.customer_id} />;
};

ShipperCellRenderer.propTypes = {
  original: PropTypes.object
};

function selectionFilterMethod(filter, row) {
  if (filter.value.length === 0) {
    return true;
  }
  const rowLadLobLabel = getLadLobLabel(row.type).toLowerCase();
  const activeFilters = new Set(filter.value.map(v => v.toLowerCase()));
  return activeFilters.has(rowLadLobLabel);
}

const LocationTypeCellRenderer = props => {
  const undefinedLad = getDefaultUnclassifiedLad();
  const lad = props.value ? props.value : undefinedLad;
  let label = getLadLobLabel(lad);

  return (
    <Translation ns="locations">
      {t => (
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            height: "100%",
            width: "100%"
          }}
        >
          <div css={{ marginLeft: "1em" }}>
            <Chiclet height={25} width={25} lad={lad} />
          </div>
          <div
            css={{ marginLeft: "0.5em", marginRight: "0.5em", width: "100%" }}
          >
            {label.includes("Unclassified") ? t(`locations:${label}`) : label}
          </div>
        </div>
      )}
    </Translation>
  );
};

LocationTypeCellRenderer.propTypes = {
  value: PropTypes.object
};

/**
 *
 * @param filter
 * @return {null|*}
 * @constructor
 */
const LadFilters = ({ filter }) => {
  if (!filter || filter.length === 0) {
    return null;
  }

  let label = "";

  if (filter[0].includes("_")) {
    label = filter[0].split("_")[1];
  } else {
    label = filter[0];
  }

  label = label.replace(/\w+/g, _.capitalize);

  const plusCount = filter.length - 1 === 0 ? "" : `+${filter.length - 1}`;
  return (
    <FlexRowDiv
      style={{ flex: 1, justifyContent: "flex-start", marginLeft: ".5em" }}
    >
      <div
        style={{ color: Colors.highlight.LIGHT_BLUE }}
      >{`${label} ${plusCount}`}</div>
    </FlexRowDiv>
  );
};

LadFilters.propTypes = {
  filter: PropTypes.array
};

/**
 *
 * @param filter
 * @param onChange
 * @param isCarrierOrg
 * @return {*}
 * @constructor
 */
const LadFilterWidget = ({ filter, onChange, isCarrierOrg }) => {
  const [showWidget, setShowWidget] = useState(false);

  return (
    <div className="search-title-header">
      <FlexDiv
        style={{
          ":hover": {
            backgroundColor: "#e8e8e8"
          },
          alignItems: "center",
          cursor: "pointer",
          height: "2em",
          padding: "0 .35em",
          position: "relative"
        }}
        onClick={() => setShowWidget(true)}
        data-qa="button-toggle-location-type-filter"
      >
        <div className="per-col-search-widget">
          <FaSearch />
          <FaAngleDown />
        </div>
        <LadFilters filter={filter ? filter.value : null} />
        {filter && filter.value.length > 0 ? (
          <div style={{ position: "absolute", zIndex: 10, right: 2 }}>
            <MdClose
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onChange([]);
              }}
              data-qa="button-clear-location-type-selection"
            />
          </div>
        ) : null}
      </FlexDiv>

      <LocationTypeSelectFilterModal
        show={showWidget}
        hide={() => {
          setShowWidget(false);
        }}
        value={filter ? filter.value : null}
        changeHandler={val => onChange(val)}
        isCarrierOrg={isCarrierOrg}
      />
    </div>
  );
};

LadFilterWidget.propTypes = {
  filter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isCarrierOrg: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

/**
 * @param {number} [value=0] - The count of children locations
 *  value > 0: show icon,
 *  value <= 0: hide icon
 */
const ParentIconCell = ({ value = 0 }) => {
  const { t } = useTranslation("locations");

  // Don't display an icon if there aren't any child locations
  if (value <= 0) {
    return null;
  }

  const label = t("child location", { count: value });

  return (
    <OverlayTrigger overlay={<Tooltip>{label}</Tooltip>}>
      <LocationGroupIcon css={{ height: 25, width: 25, margin: "0 auto" }} />
    </OverlayTrigger>
  );
};

ParentIconCell.propTypes = {
  value: PropTypes.number
};

/**
 *
 */
export const LocationParentColumn = {
  Header: i18n.t("locations:Parent"),
  accessor: "children_locations.length",
  Cell: props => <ParentIconCell {...props} />,
  minWidth: 40,
  getProps: () => ({
    style: { padding: 0 }
  }),
  Filter: ParentFilter
};

/**
 * Location Name
 */
export const LocationNameColumn = {
  Header: i18n.t("locations:Name"),
  accessor: "name",
  Cell: BoldTextCell,
  getProps: (state, rowInfo) => ({
    style: {
      paddingLeft: "10px",
      whiteSpace: "unset"
    }
  }),
  filterMethod: (filter, row) => {
    return row.name.toLowerCase().includes(filter.value.toLowerCase());
  }
};

/**
 *
 */
export const LocationCarrierColumn = {
  Header: i18n.t("locations:Carrier"),
  accessor: "carrier_name",
  Cell: CarrierNameRenderer,
  getProps: (state, rowInfo) => ({
    style: {
      paddingLeft: "10px",
      whiteSpace: "unset"
    }
  }),
  filterMethod: (filter, row) => {
    return row.carrier_name.toLowerCase().includes(filter.value.toLowerCase());
  }
};

/**
 *
 */
export const LocationShipperColumn = {
  Header: i18n.t("locations:Shipper"),
  accessor: "organization",
  Cell: ShipperCellRenderer,
  getProps: (state, rowInfo) => ({
    style: {
      paddingLeft: "10px",
      whiteSpace: "unset"
    }
  }),
  Filter: ({ filter, onChange }) => {
    return (
      <ShipperFilter value={filter ? filter.value : null} onChange={onChange} />
    );
  },
  filterMethod: (filter, row) => {
    return row.shipper_name.toLowerCase().includes(filter.value.toLowerCase());
  }
};

/**
 *
 */
export const LocationCodeColumn = {
  Header: i18n.t("locations:Location Code"),
  accessor: "code",
  minWidth: 50,
  Cell: BoldTextCell,
  getProps: (state, rowInfo) => ({
    style: {
      paddingLeft: "10px"
    }
  }),
  filterMethod: (filter, row) => {
    if (row.code) {
      return row.code.toLowerCase().includes(filter.value.toLowerCase());
    } else {
      return false;
    }
  }
};

/**
 *
 */
export const LocationAddressColumn = {
  Header: i18n.t("locations:Address"),
  id: "address",
  accessor: row => {
    return {
      address: row.address,
      city: row.city,
      state: row.state,
      zip: row.postal_code
    };
  },
  Cell: SimpleAddressCell,
  getProps: (state, rowInfo) => ({
    style: {
      paddingLeft: "10px"
    }
  }),
  filterMethod: (filter, row) => {
    const addressMash = _.join(
      _.map(
        _.pick(row.address, ["address", "city", "state", "zip"]),
        _.lowerCase
      ),
      " "
    );
    return addressMash.includes(filter.value.toLowerCase());
  }
};

/**
 *
 */
export const LocationCountColumn = {
  Header: i18n.t("locations:Count"),
  accessor: "count",
  minWidth: 50,
  getProps: (state, rowInfo) => ({
    style: {
      paddingLeft: "10px"
    }
  }),
  filterMethod: (filter, row) => {
    return parseInt(row.count, 10) > parseInt(filter.value, 10);
  }
};

/**
 * TODO: Refactor this so it's not a function.
 */
export const LocationTypeColumn = isCarrierOrg => {
  return {
    Header: i18n.t("locations:Location Type"),
    id: "type",
    sortable: false,
    accessor: "lad",
    Cell: LocationTypeCellRenderer,
    getProps: (state, rowInfo) => ({
      className: rowInfo && rowInfo.viewIndex % 2 ? "dark-even" : "dark-odd",
      style: {
        padding: "0px"
      }
    }),
    filterMethod: selectionFilterMethod,
    Filter: ({ filter, onChange }) => {
      return (
        <LadFilterWidget
          filter={filter}
          onChange={onChange}
          isCarrierOrg={isCarrierOrg}
        />
      );
    }
  };
};
