import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, FormCheck, FormGroup, Badge, Modal } from "react-bootstrap";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import { groupBy, map, mapValues } from "lodash";

import LadsState from "../../lads/LadsState";
import { getOrganizations } from "../../organizations/OrganizationsState";

import { getLadName, getLadLobLabel } from "../../../components/lads";
import { ModalBody, ModalHeader } from "../../../components/modal-elems";
import {
  FlexColDiv,
  FlexRowDiv,
  BoldText
} from "../../../styles/container-elements";

/**
 *
 * @param orgId
 * @param organizations
 */
const getOrganization = (orgId, organizations) => {
  return _.find(organizations, {
    organization_id: Number(orgId)
  });
};

/**
 *
 * @param currentFilters
 * @param filter
 * @param checked
 * @return {*|*[]}
 */
const setFilterValue = (currentFilters, filter, checked) => {
  let filterOption = filter.toLowerCase();
  const filters = currentFilters || [];

  let optionIndex = filters.indexOf(filterOption);

  if (checked) {
    optionIndex === -1
      ? filters.push(filterOption)
      : console.log("Filter option already set");
  } else {
    optionIndex !== -1
      ? filters.splice(optionIndex, 1)
      : console.log("Filter option not set");
  }

  return filters;
};

/**
 *
 * @param lad
 * @param currentFilters
 * @param changeHandler
 * @return {*}
 */
const getCheckboxFilter = (lad, currentFilters, changeHandler) => {
  const ladKey = getLadLobLabel(lad);
  const ladLabel = getLadName(lad);

  const isChecked =
    ladKey && currentFilters
      ? currentFilters.indexOf(ladKey.toLowerCase()) !== -1
      : false;

  return (
    <FormGroup key={lad.id} controlId={lad.id} className="m-0">
      <FormCheck
        type="checkbox"
        name={ladKey}
        label={ladLabel}
        checked={isChecked}
        onChange={evt => {
          let val = setFilterValue(currentFilters, ladKey, evt.target.checked);
          changeHandler(val);
        }}
        data-qa="checkbox-lad-filter"
      />
    </FormGroup>
  );
};

/**
 * Shipper Orgs
 * @param changeHandler
 * @param lads
 * @param value
 * @return {*}
 * @constructor
 */
const ShipperLocationTypeFilterWidget = ({ changeHandler, lads, value }) => {
  const groupedLads = useMemo(() => groupBy(lads, "lob_name"), [lads]);

  const groupBoxes = mapValues(groupedLads, lad =>
    lad.map(l => getCheckboxFilter(l, value, changeHandler))
  );

  const filterColumns = map(groupBoxes, (boxes, groupName) => {
    return (
      <FlexColDiv key={groupName} style={{ flex: 1 }}>
        <BoldText>{groupName}</BoldText>
        {boxes}
      </FlexColDiv>
    );
  });

  return (
    <FlexRowDiv style={{ justifyContent: "space-around" }}>
      {filterColumns}
    </FlexRowDiv>
  );
};

ShipperLocationTypeFilterWidget.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

/**
 *
 * @param lad
 * @param currentFilters
 * @param changeHandler
 * @param orgId
 * @return {*}
 */
const getCarrierCheckboxFilter = (
  lad,
  currentFilters,
  changeHandler,
  orgId
) => {
  const ladKey = getLadLobLabel(lad);
  const ladLabel = getLadName(lad);

  const isChecked =
    ladKey && currentFilters
      ? currentFilters.indexOf(`${orgId}_${ladKey}`.toLowerCase()) !== -1
      : false;

  return (
    <FormGroup key={lad.id} controlId={lad.id} className="m-0">
      <FormCheck
        type="checkbox"
        key={lad.org_lad_id}
        name={ladKey}
        label={ladLabel}
        checked={isChecked}
        onChange={evt => {
          let val = setFilterValue(
            currentFilters,
            `${orgId}_${ladKey}`,
            evt.target.checked
          );
          changeHandler(val);
        }}
        data-qa="checkbox-carrier-lad-filter"
      />
    </FormGroup>
  );
};

/**
 * Carrier Orgs
 *
 * @param changeHandler
 * @param lads
 * @param organizations
 * @param t
 * @param value
 * @return {*}
 * @constructor
 */
const CarrierLocationTypeFilterWidget = ({
  changeHandler,
  lads,
  organizations,
  t,
  value
}) => {
  const [shipper, setShipper] = useState(null);

  const shipperOptions = useMemo(() => {
    const orgs = Object.keys(lads);

    /* default select first org */
    if (orgs.length > 0) {
      setShipper(orgs[0]);
    }

    return orgs.map(orgId => {
      const org = getOrganization(orgId, organizations);
      return org ? { value: orgId, label: org.org_name } : null;
    });
  }, [lads, organizations]);

  const groupedLads = shipper ? groupBy(lads[shipper.value], "lob_name") : {};

  const groupBoxes = mapValues(groupedLads, lad =>
    lad.map(l =>
      getCarrierCheckboxFilter(l, value, changeHandler, shipper.value)
    )
  );

  const filteredColumns = map(groupBoxes, (boxes, groupName) => {
    return (
      <FlexColDiv key={groupName} style={{ flex: 1 }}>
        <BoldText>{groupName}</BoldText>
        {boxes}
      </FlexColDiv>
    );
  });

  return (
    <div className="d-flex flex-column">
      <div className="mb-4">
        <div className=" mx-auto w-50">
          <Badge style={{ fontWeight: "normal" }}>
            {t("locations:Shipper")}
          </Badge>
          <Select
            name="shipper-state"
            multi={false}
            style={{ width: "100%" }}
            options={shipperOptions}
            value={shipper}
            clearable={false}
            onChange={selections => setShipper(selections)}
            data-qa="select-shipper"
          />
        </div>
      </div>

      {shipper ? (
        <FlexRowDiv style={{ justifyContent: "space-around" }}>
          {filteredColumns}
        </FlexRowDiv>
      ) : null}
    </div>
  );
};

CarrierLocationTypeFilterWidget.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  organizations: PropTypes.array,
  t: PropTypes.func,
  value: PropTypes.array
};

/**
 *
 * @param changeHandler
 * @param lads
 * @param hide
 * @param organizations
 * @param show
 * @param value
 * @param t
 * @param isCarrierOrg
 * @return {*}
 * @constructor
 */
const LocationTypeSelectFilterModal = ({
  changeHandler,
  lads,
  hide,
  organizations,
  show,
  value,
  t,
  isCarrierOrg
}) => {
  return (
    <Modal backdrop={"static"} show={show} onHide={() => hide()} size="lg">
      <ModalHeader
        title={
          <Button
            variant="secondary"
            onClick={() => changeHandler([])}
            data-qa="button-clear-location-type-filters"
          >
            {t("locations:Clear Filters")}
          </Button>
        }
      />
      <ModalBody>
        {isCarrierOrg ? (
          <CarrierLocationTypeFilterWidget
            lads={lads}
            changeHandler={changeHandler}
            organizations={organizations}
            value={value}
            t={t}
          />
        ) : (
          <ShipperLocationTypeFilterWidget
            lads={lads}
            changeHandler={changeHandler}
            value={value}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

LocationTypeSelectFilterModal.propTypes = {
  changeHandler: PropTypes.func,
  hide: PropTypes.func,
  isCarrierOrg: PropTypes.bool,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  organizations: PropTypes.array,
  show: PropTypes.bool,
  t: PropTypes.func.isRequired,
  value: PropTypes.array
};

const mapStateToProps = state => {
  return {
    lads: LadsState.selectors.getLadsList(state),
    organizations: getOrganizations(state)
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["locations"])(LocationTypeSelectFilterModal));
