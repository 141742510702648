export const Countries = [
  { value: "CA", label: "Canada" },
  { value: "MX", label: "Mexico" },
  { value: "US", label: "United States" }
];

export const StatesCA = [
  {
    label: "Alberta",
    value: "AB"
  },
  {
    label: "British Columbia",
    value: "BC"
  },
  {
    label: "Manitoba",
    value: "MB"
  },
  {
    label: "New Brunswick",
    value: "NB"
  },
  {
    label: "Newfoundland and Labrador",
    value: "NL"
  },
  {
    label: "Nova Scotia",
    value: "NS"
  },
  {
    label: "Ontario",
    value: "ON"
  },
  {
    label: "Prince Edward Island",
    value: "PE"
  },
  {
    label: "Quebec",
    value: "QC"
  },
  {
    label: "Saskatchewan",
    value: "SK"
  },
  {
    label: "Northwest Territories",
    value: "NT"
  },
  {
    label: "Nunavut",
    value: "NU"
  },
  {
    label: "Yukon",
    value: "YT"
  }
];

export const StatesMX = [
  {
    label: "Distrito Federal",
    value: "DIF"
  },
  {
    label: "Aguascalientes",
    value: "AGU"
  },
  {
    label: "Baja California",
    value: "BCN"
  },
  {
    label: "Baja California Sur",
    value: "BCS"
  },
  {
    label: "Campeche",
    value: "CAM"
  },
  {
    label: "Chiapas",
    value: "CHP"
  },
  {
    label: "Chihuahua",
    value: "CHH"
  },
  {
    label: "Coahuila",
    value: "COA"
  },
  {
    label: "Colima",
    value: "COL"
  },
  {
    label: "Durango",
    value: "DUR"
  },
  {
    label: "Guanajuato",
    value: "GUA"
  },
  {
    label: "Guerrero",
    value: "GRO"
  },
  {
    label: "Hidalgo",
    value: "HID"
  },
  {
    label: "Jalisco",
    value: "JAL"
  },
  {
    label: "Michoacán",
    value: "MIC"
  },
  {
    label: "Morelos",
    value: "MOR"
  },
  {
    label: "México",
    value: "MEX"
  },
  {
    label: "Nayarit",
    value: "NAY"
  },
  {
    label: "Nuevo León",
    value: "NLE"
  },
  {
    label: "Oaxaca",
    value: "OAX"
  },
  {
    label: "Puebla",
    value: "PUE"
  },
  {
    label: "Querétaro",
    value: "QUE"
  },
  {
    label: "Quintana Roo",
    value: "ROO"
  },
  {
    label: "San Luis Potosí",
    value: "SLP"
  },
  {
    label: "Sinaloa",
    value: "SIN"
  },
  {
    label: "Sonora",
    value: "SON"
  },
  {
    label: "Tabasco",
    value: "TAB"
  },
  {
    label: "Tamaulipas",
    value: "TAM"
  },
  {
    label: "Tlaxcala",
    value: "TLA"
  },
  {
    label: "Veracruz",
    value: "VER"
  },
  {
    label: "Yucatán",
    value: "YUC"
  },
  {
    label: "Zacatecas",
    value: "ZAC"
  }
];

export const StatesUS = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AS", label: "American Samoa" },
  { value: "AZ", label: "Arizona" },
  { value: "AR", label: "Arkansas" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DE", label: "Delaware" },
  { value: "DC", label: "District Of Columbia" },
  { value: "FM", label: "Federated States Of Micronesia" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "GU", label: "Guam" },
  { value: "HI", label: "Hawaii" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MH", label: "Marshall Islands" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MS", label: "Mississippi" },
  { value: "MO", label: "Missouri" },
  { value: "MT", label: "Montana" },
  { value: "NE", label: "Nebraska" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NY", label: "New York" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "MP", label: "Northern Mariana Islands" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PW", label: "Palau" },
  { value: "PA", label: "Pennsylvania" },
  { value: "PR", label: "Puerto Rico" },
  { value: "RI", label: "Rhode Island" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VI", label: "Virgin Islands" },
  { value: "VA", label: "Virginia" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" }
];
