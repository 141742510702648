/** @jsx jsx */
import { jsx } from "@emotion/core";
import ReactTable from "react-table";
import { datatableThStyles, styleify } from "../../styles/table-styles";

import { scrollToTableTop } from "../../utils/table-utils";
import { getColumns } from "./LocationMatchingColumns";
import { withTranslation } from "react-i18next";

const CurrentLocationPanel = ({
  t,
  locations,
  lads,
  selectedLocations,
  eventHandler,
  page,
  pageSize,
  totalPages,
  setPagination,
  locationId
}) => {
  const columns = getColumns(t, lads, "check", selectedLocations, null);

  const tableTrProps = (state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        const locID = rowInfo.original.id;
        eventHandler(locID, "SELECT_LOCATION");
      },
      style: { cursor: "pointer" }
    };
  };

  return (
    <div
      className="availablelocations"
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: "1em",
        height: "300px",
        overflow: "auto",
        backgroundColor: "white"
      }}
    >
      <ReactTable
        className="customtable"
        manual
        pages={totalPages}
        page={page}
        pageSize={pageSize}
        onPageChange={newPage => {
          setPagination(locationId, newPage, pageSize);
          scrollToTableTop("availablelocations");
        }}
        onPageSizeChange={newPageSize =>
          setPagination(locationId, 0, newPageSize)
        }
        data={locations}
        columns={columns}
        getTheadThProps={styleify(datatableThStyles)}
        getTrProps={tableTrProps}
      />
    </div>
  );
};

export default withTranslation(["location-matching"])(CurrentLocationPanel);
