/** @jsx jsx */
import { jsx } from "@emotion/core";
import Colors from "../../../styles/colors";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

const titleCss = {
  fontSize: "1.5em",
  fontWeight: "300",
  color: Colors.background.GRAY_BLUE,
  paddingBottom: "1em",
  borderBottom: "1px solid #ddd",
  marginBottom: "1em"
};

const descriptionCss = {
  marginBottom: "2em"
};

const PageHeader = props => {
  const { title, description } = props;

  return (
    <div>
      <div css={titleCss}>{title}</div>
      {description && 
        <div css={descriptionCss}>
          <ReactMarkdown source={description} />
        </div>
      }
    </div>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
export default PageHeader;
