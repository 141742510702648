import PropTypes from "prop-types";
// import { useTranslation } from "react-i18next";

export const LadShape = {
  code: PropTypes.string.isRequired,
  default_name: PropTypes.string,
  lad_name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  lob_name: PropTypes.string,
  description: PropTypes.string.isRequired
};

const LABEL_UNCLASSIFIED = "Unclassified";

export const getLadName = lad => lad.lad_name || lad.name || lad.default_name;

export const getLadLobLabel = lad => {
  if (lad === undefined) {
    return LABEL_UNCLASSIFIED;
  }
  return `${getLadName(lad)} (${lad.lob_name})`;
};

export const getLadByLabelName = (name, lads) => {
  const lobBegin = name.lastIndexOf("(");
  const lobEnd = name.lastIndexOf(")");

  const ladName =
    name && lobBegin > -1
      ? name.substring(0, lobBegin - 1).toLowerCase()
      : name;
  const lobName =
    name && lobBegin > -1 && lobEnd > -1
      ? name.substring(lobBegin + 1, lobEnd).toLowerCase()
      : "";

  return lads.find(
    l =>
      l.lad_name.toLowerCase() === ladName &&
      l.lob_name.toLowerCase() === lobName
  );
};

/* Hardcoded since the client wanted a defined order */
export const LOBS = ["Assembly", "Vehicles", "Aftermarket Parts"];
