/** @jsx jsx */
/**
 * A Search Bar with typeahead from different categories.
 * TODO list:
 *
 * - Expose styling externally for each item;
 * - Make "advanced search" possible to be hide/show
 * - Expose some typeahead attributes, for example, maxResults
 **/
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import React from "react";
import { DropdownItem, Dropdown } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { withTranslation } from "react-i18next";

import { FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

import Colors from "../../styles/colors";
import { FlexRowDiv } from "../../styles/container-elements";
import { useIsMediumAndDown } from "../responsive";

const ClearSearch = props => (
  <div
    onClick={props.clickHandler}
    css={{
      cursor: "pointer",
      color: Colors.background.DARK_BLUE,

      // Vertically center the icon
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",

      // Offset it from the right
      position: "absolute",
      top: 0,
      right: "0.6em"
    }}
    data-qa="button-clear-search"
    className="clear-search"
  >
    <IoMdClose />
  </div>
);

ClearSearch.propTypes = {
  clickHandler: PropTypes.func.isRequired
};

const ResponsiveSearchCategoryDropdownButton = ({
  id,
  title,
  children,
  className
}) => {
  if (!useIsMediumAndDown()) {
    return (
      <Dropdown className={className}>
        <Dropdown.Toggle
          id={id}
          style={ResponsiveSearchCategoryDropdownButton.baseStyles}
          data-qa="dropdown-button-input-search"
          variant="default"
        >
          {title}
        </Dropdown.Toggle>
        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
    );
  }

  return (
    <Dropdown className={className}>
      <Dropdown.Toggle
        id={id}
        style={ResponsiveSearchCategoryDropdownButton.phoneStyles}
        data-qa="dropdown-button-input-search"
        variant="default"
      >
        {""}
      </Dropdown.Toggle>
      <Dropdown.Menu>{children}</Dropdown.Menu>
    </Dropdown>
  );
};

ResponsiveSearchCategoryDropdownButton.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  title: PropTypes.string
};

ResponsiveSearchCategoryDropdownButton.baseStyles = {
  display: "flex",
  height: "2.8em",
  width: "11.25em",
  justifyContent: "space-between",
  alignItems: "center",
  border: "none",
  backgroundColor: Colors.background.LIGHT_GRAY
};

ResponsiveSearchCategoryDropdownButton.phoneStyles = {
  ...ResponsiveSearchCategoryDropdownButton.baseStyles,
  width: "4em"
};

export const TypeaheadOption = ({ option, position, t }) => {
  if (!option.label) {
    return false;
  }

  return (
    <MenuItem
      position={position}
      option={option}
      className="typeahead-menu-item d-flex flex-row justify-content-start align-items-end"
      data-qa="typeahead-options-input-search"
    >
      <div
        css={{
          color: Colors.text.GRAY,
          fontSize: "small",
          fontStyle: "italic",
          marginRight: "1em"
        }}
      >
        {option.categoryLabel(t)}
      </div>
      {option.label}
    </MenuItem>
  );
};

const AdvancedSearchButton = styled.div(
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "9em",
    height: "3.5em",
    borderRadius: "3px",
    fontStyle: "italic",
    ":hover": { cursor: "pointer", color: Colors.background.DARK_BLUE }
  },
  ({
    showFilters = false,
    backgroundColor = Colors.background.LIGHT_GRAY
  }) => ({
    borderBottomRightRadius: showFilters ? "0px" : "3px",
    borderBottomLeftRadius: showFilters ? "0px" : "3px",
    backgroundColor: showFilters ? backgroundColor : "none",
    textDecoration: showFilters ? "none" : "underline"
  })
);

const areThereChangesOnLazyLoadedCategoryData = (
  prevProps,
  newProps,
  categories
) => {
  // Check for any updates to dynamically-loaded category options
  for (const category of categories) {
    const categoryProp = category.property;
    const categoryLoadingProp = category.loadingProperty;
    const hasCategoryAssociatedPropAndLoadingProp =
      categoryProp || categoryLoadingProp;

    if (!hasCategoryAssociatedPropAndLoadingProp) {
      continue;
    }

    const isCategoryDataValid =
      newProps[categoryProp] && newProps[categoryProp].length;
    const isCategoryDataLoading = newProps[categoryLoadingProp];
    const hasCategoryPropDataChanged =
      newProps[categoryProp] !== prevProps[categoryProp];
    if (
      isCategoryDataValid &&
      !isCategoryDataLoading &&
      hasCategoryPropDataChanged
    ) {
      return true;
    }
  }
};

const callLazyLoadPropertyFetchIfItExists = (
  props,
  categories,
  query,
  selectedCategory
) => {
  for (const category of categories) {
    const isCategoryDataLazyLoadable = category.loaderProperty;
    const shouldShowResultsOfThisCategory =
      selectedCategory.queryKey === "everything" ||
      category.queryKey === selectedCategory.queryKey;

    if (isCategoryDataLazyLoadable && shouldShowResultsOfThisCategory) {
      props[category.loaderProperty](query);
    }
  }
};

const areTherePropertiesLazyLoadingData = (props, categories) => {
  for (const category of categories) {
    if (!category.property) {
      continue;
    }
    if (
      category.loaderProperty &&
      category.loadingProperty &&
      props[category.loadingProperty]
    ) {
      return true;
    }
  }
  return false;
};

class SearchInputInternal extends React.Component {
  state = {
    typeaheadOptions: [],
    isLoading: false,
    asyncOptionsLoading: {},
    typeaheadKey: uuidv4()
  };
  constructor(props) {
    super(props);
    this._constructTypeaheadOptions = this._constructTypeaheadOptions.bind(
      this
    );
    this._constructTypeaheadLookup = this._constructTypeaheadLookup.bind(this);
    this.resetSearchBar = this.resetSearchBar.bind(this);

    this.typeaheadOptionsMetadata = this.props.typeaheadOptionsMetadata;
    this.typeaheadOptionsLookup = {};
    for (let category of this.typeaheadOptionsMetadata) {
      this.typeaheadOptionsLookup[category.property] = [];
    }

    this.userInput = false;

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  componentDidMount() {
    this._constructTypeaheadLookup();
  }

  componentDidUpdate(prevProps) {
    const hasSearchTextChanged = this.props.searchText !== prevProps.searchText;
    const hasSearchCategoryDropdownChanged =
      this.props.searchCategory !== prevProps.searchCategory;
    const isLoading = areTherePropertiesLazyLoadingData(
      this.props,
      this.typeaheadOptionsMetadata
    );

    if (this.state.isLoading !== isLoading) {
      this.setState({ isLoading: isLoading });
    }

    if (hasSearchTextChanged) {
      callLazyLoadPropertyFetchIfItExists(
        this.props,
        this.typeaheadOptionsMetadata,
        this.props.searchText,
        this.props.searchCategory
      );
      // If this change did not come from user input
      // we are getting a new search text string, we need
      // to give our a typeahead control a new key so it
      // will remount with this text displayed
      if (!this.userInput) {
        this.setState({ typeaheadKey: uuidv4() });
      }
      this.userInput = false;
    }

    // Because of the number of parts, construct this
    // map outside of the render loop for increased performance
    this._constructTypeaheadLookup(this.props);

    const hasLazyLoadedCategoryDataChanged = areThereChangesOnLazyLoadedCategoryData(
      prevProps,
      this.props,
      this.typeaheadOptionsMetadata
    );

    // Refresh the typeahead options if any values have changed
    if (hasSearchCategoryDropdownChanged || hasLazyLoadedCategoryDataChanged) {
      this._constructTypeaheadOptions(
        this.props.searchText,
        this.props.searchCategory
      );
    }
  }

  _constructTypeaheadLookup(nextProps = null) {
    // Here we use the typeahead metadata to get info from props for each
    // category and build the data we will use for lookup
    // If we receive nextProps, we check if there is some change in data coming
    for (const category of this.typeaheadOptionsMetadata) {
      if (!category.property) {
        continue;
      }

      // Check if some data changed
      if (
        nextProps !== null &&
        (this.props[category.property] === nextProps[category.property]) ===
          this.typeaheadOptionsLookup[category.property]
      ) {
        continue;
      }

      if (!(category.property in this.props)) {
        throw Error(
          `Property ${category.property} is not present in SearchBar instance props`
        );
      }

      let categoryProp = [];
      if (this.props && this.props[category.property]) {
        categoryProp = this.props[category.property];
      }
      if (nextProps && nextProps[category.property]) {
        categoryProp = nextProps[category.property];
      }

      this.typeaheadOptionsLookup[category.property] = categoryProp
        .filter(v => {
          return _.isNil(v) === false;
        })
        .map(v => {
          let label = category.itemLabelProperty
            ? v[category.itemLabelProperty]
            : v;
          let value = category.itemValueProperty
            ? v[category.itemValueProperty]
            : v;
          return {
            label: label,
            value: value,
            category: category.queryKey,
            categoryLabel: category.label,
            categoryLoader: category.loaderProperty
          };
        });
    }
  }

  _constructTypeaheadOptions(query, searchCategory) {
    // If our query is empty, don't do anything
    // otherwise we load everything into the lookahead
    // and the system gets into a bad state
    if (query.length < 1) {
      return;
    }

    const { queryKey } = searchCategory;
    let typeaheadOptions = [];
    for (const category of this.typeaheadOptionsMetadata) {
      if (!category.property) {
        continue;
      }

      if (queryKey === "everything" || queryKey === category.queryKey) {
        typeaheadOptions = typeaheadOptions.concat(
          this.typeaheadOptionsLookup[category.property]
        );
      }
    }
    this.setState({ typeaheadOptions });
  }

  resetSearchBar() {
    this.typeahead.getInstance().clear();
    this.props.resetSearchBar();
  }

  onKeyDown(e) {
    this.userInput = true;
    const {
      showSearchOptions,
      typeaheadOptionsMetadata,
      searchEntities,
      solutionId,
      preventSearchOnChange,
      setSearchCategory
    } = this.props;

    if (e.key === "Enter" && !preventSearchOnChange) {
      searchEntities(solutionId, true, true);
    }

    // When there are no way for an user to select search optin he is
    // using, the option is selected automatically when selecting one typeahead
    // option. User may remove this by clicking on the 'x' but it makes more
    // sense to also unselect the category when cleaning the data on the input.
    if (
      e.key === "Backspace" &&
      e.target.value.length === 1 && // backspace was not applied yet
      !showSearchOptions
    ) {
      setSearchCategory(typeaheadOptionsMetadata[0].queryKey);
    }
  }

  onChangeText(e) {
    // H1-1659: We needed to add the setSearchValue because of the new behavior
    // of current_road search category. To keep backwards compatibility, I used
    // _.noop function as default, making it setSearchValue an optional prop
    // for SearchBar
    const {
      setSearchCategory,
      setSearchText,
      setSearchValue = _.noop,
      preventSearchOnChange,
      searchEntities,
      solutionId
    } = this.props;
    if (e.length > 0) {
      setSearchCategory(e[0].category);
      setSearchText(e[0].label);
      setSearchValue(e[0].value);

      if (!preventSearchOnChange) {
        searchEntities(solutionId);
      }
    }
  }

  zIndexValues = {
    icon: 10,
    focused: 5
  };

  render() {
    const { typeaheadOptions, isLoading } = this.state;
    const {
      searchText,
      setSearchText,
      searchCategory,
      setSearchCategory,
      typeaheadOptionsMetadata,
      showSearchOptions = true,
      getInputProps = () => ({}),
      t
    } = this.props;

    const searchCategoryDropdownItems = typeaheadOptionsMetadata.map(cat => (
      <DropdownItem
        key={cat.queryKey}
        eventKey={cat.queryKey}
        onSelect={k => setSearchCategory(k)}
        data-qa={`menu-item-dropdown-${cat.queryKey}`}
      >
        {cat.label(t)}
      </DropdownItem>
    ));

    const searchCategoryButtonTitle = searchCategory
      ? searchCategory.label(t)
      : t("components:Search Everything");

    return (
      <FlexRowDiv
        className="search-widgets"
        style={{
          border: "1px solid rgba(0, 0, 0, 0.1)",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
          borderRadius: "3px",
          alignItems: "center",
          flexGrow: 2,
          justifyContent: "space-between",
          position: "relative",
          height: 42,
          marginRight: "0.5em"
        }}
      >
        <FlexRowDiv className="search-icon-input" css={{ flexGrow: 2 }}>
          <FaSearch
            style={{
              color: Colors.text.RIVER_BED,
              position: "absolute",
              left: 12,
              top: 12,
              zIndex: this.zIndexValues.icon
            }}
          />
          <AsyncTypeahead
            id="fin-vehicle-search-bar"
            key={this.state.typeaheadKey}
            ref={typeahead => (this.typeahead = typeahead)}
            defaultInputValue={searchText}
            useCache={false}
            clearButton={false}
            options={typeaheadOptions}
            filterBy={(option, props) => {
              // Only show options with the user's query text in the label
              // (skip options from dynamically-loaded categories since they were already filtered server-side)
              if (
                option.categoryLoader ||
                (option.label &&
                  option.label.toLowerCase().includes(props.text.toLowerCase()))
              ) {
                return option;
              }
            }}
            renderMenu={(results, menuProps) => {
              if (!isLoading && !results.length) {
                return null;
              }

              return (
                <Menu {...menuProps}>
                  {results.map((result, index) => (
                    <TypeaheadOption
                      option={result}
                      position={index}
                      t={t}
                      key={index}
                    />
                  ))}
                </Menu>
              );
            }}
            maxResults={5}
            onInputChange={setSearchText}
            onKeyDown={this.onKeyDown}
            onChange={this.onChangeText}
            onSearch={q => this._constructTypeaheadOptions(q, searchCategory)}
            isLoading={isLoading}
            placeholder={searchCategory.placeholder(t)}
            css={{
              flex: 1,
              ":focus-within": {
                zIndex: this.zIndexValues.focused
              },
              input: {
                borderColor: "transparent",
                borderRadius: "3px",
                height: "2.8em",
                paddingLeft: "2.4em",
                width: "100%",
                minWidth: "10em"
              }
            }}
            inputProps={{
              value: searchText,
              ...getInputProps()
            }}
          >
            {!_.isEmpty(searchText) && (
              <ClearSearch
                clickHandler={this.resetSearchBar}
                showSearchOptions={showSearchOptions}
              />
            )}
          </AsyncTypeahead>
        </FlexRowDiv>
        {showSearchOptions && (
          <ResponsiveSearchCategoryDropdownButton
            id="input-dropdown-addon"
            title={searchCategoryButtonTitle}
            css={{ ":focus-within": { zIndex: this.zIndexValues.focused } }}
          >
            {searchCategoryDropdownItems}
          </ResponsiveSearchCategoryDropdownButton>
        )}
      </FlexRowDiv>
    );
  }
}

export const SearchInput = withTranslation(["components"])(SearchInputInternal);

const DarkButton = styled(Button)({
  display: "inline-block",
  marginRight: "0.5em",
  paddingLeft: "2em",
  paddingRight: "2em"
});

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.performSearch = this.performSearch.bind(this);
  }

  componentDidMount() {
    const { fetchDomainData, solutionId } = this.props;

    // H1-1841: Only fetch data if a fetchDomainData() function has been passed to the SearchBar
    // Example: Shipment Search uses global domain data, so it does not pass a fetch
    //   (global domain data is only fetched on App mount/Org switch)
    // Example: Finished Vehicle Search uses FV-specific domain data, so it does pass a fetch
    if (fetchDomainData) {
      fetchDomainData(solutionId);
    }
  }

  performSearch() {
    const { preventSearchOnChange, searchEntities, solutionId } = this.props;

    if (!preventSearchOnChange) {
      return searchEntities(solutionId, true, true);
    }
  }

  render() {
    const {
      isShowingFilters,
      toggleShowFilters,
      isShowingAdvancedSearchButton = true,
      getButtonProps = () => ({}),
      preventSearchOnChange,
      t
    } = this.props;

    return (
      <FlexRowDiv css={{ flexWrap: "wrap" }} className="toolbar">
        <SearchInput {...this.props} data-qa="input-search" />
        {!preventSearchOnChange ? (
          <DarkButton
            onClick={this.performSearch}
            {...getButtonProps()}
            data-qa="button-search"
            className="search-button"
            disabled={preventSearchOnChange}
            variant="dark"
          >
            {t("components:Search")}
          </DarkButton>
        ) : null}
        {isShowingAdvancedSearchButton && (
          <AdvancedSearchButton
            showFilters={isShowingFilters}
            backgroundColor={Colors.background.GRAY}
            onClick={toggleShowFilters}
            data-qa="button-advanced-search"
            className="advanced-search-button"
          >
            <div
              css={{ marginTop: "-5px" }}
              className="advanced-search-label-container"
            >
              {t("components:Advanced Search")}
            </div>
          </AdvancedSearchButton>
        )}
      </FlexRowDiv>
    );
  }
}

const SearchBarWithTranslation = withTranslation(["components"])(SearchBar);
export { SearchBarWithTranslation as SearchBar };
