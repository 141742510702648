/** @jsx jsx */
/**
 * Specific selector for selecting location type inside adc-details vin search.
 */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import SelectField from "../../../../components/forms/fields/SelectField";

const locationSelectCss = {
  alignItems: "center",
  display: "flex",
  flexGrow: 2,
  ".Select": {
    marginBottom: "1em"
  },
  ".Select-control": {
    height: "42px" // The same as the search input
  },
  ".Select-value": {
    marginTop: "5px"
  }
};

const LocationTypeSelect = props => {
  const { onChange, selectedOption, options } = props;

  const optionsForSelect = options.map(item => ({ value: item, label: item }));

  return (
    <div css={locationSelectCss} data-qa="select-location-type">
      <SelectField
        stateValue={selectedOption}
        options={optionsForSelect}
        onChange={option => onChange(option.value)}
      />
    </div>
  );
};

LocationTypeSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array,
  selectedOption: PropTypes.string
};

export default LocationTypeSelect;
