import moment from "moment-timezone";

import {
  getBasicQueryString,
  getBasicQueryStringFilterValuePriority
} from "../../components/search-bar/search-filter-query-strings";
import { DateRangeFilterButton } from "../../components/search-bar/FilterButtons";

const getTimestampRangeQueryString = (queryKey_IGNORED, { from, to }) => {
  let qs = "";
  if (from) {
    qs += `&milestone_start_time=${moment(from).unix()}`;
  }
  if (to) {
    qs += `&milestone_end_time=${moment(to).unix()}`;
  }
  return qs;
};

export const FILTERS = [
  {
    queryKey: "locationsCodes",
    label: t => t("partner-search:Location"),
    optionsGetter: props => props.locationsFilterOptions,
    filterOptions: options => options, // Disable client-side filtering
    queryBuilder: getBasicQueryStringFilterValuePriority
  },
  {
    queryKey: "milestone_events",
    label: t => t("partner-search:Milestone Event"),
    optionsGetter: props => props.eventsFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "milestone_codes",
    label: t => t("partner-search:Milestone Status Code"),
    optionsGetter: props => props.statusCodesFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "unused", // queryBuilder func will supply the query key(s)
    label: t => t("partner-search:Milestone Event Times"),
    component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getTimestampRangeQueryString
  },
  {
    queryKey: "customer_ids",
    label: t => t("partner-search:Shipper"),
    optionsGetter: props => props.shippersFilterOptions,
    queryBuilder: getBasicQueryString
  }
];
