/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import "moment-timezone";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import FormRow from "../../components/forms/FormRow";
import ValidationMessage from "../../components/forms/ValidationMessage";

import Colors from "../../styles/colors";
import { FlexColDiv } from "../../styles/container-elements";
import { checkValidation } from "./CreateShipmentState";
import { getType } from "../geofence-edit/geofence-types";

class ShipmentFormControls extends Component {
  static propTypes = {
    checkStopValidation: PropTypes.func,
    createShipment: PropTypes.func,
    data: PropTypes.object,
    locations: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    organization: PropTypes.object,
    resetForm: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    validation: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.getShipmentPayload = this.getShipmentPayload.bind(this);
  }

  getLocation(id, locations) {
    let loc;
    for (let key in locations) {
      // check if the property/key is defined in the object itself, not in parent
      if (locations.hasOwnProperty(key)) {
        loc = _.head(locations[key].filter(l => l.id === id));
        if (loc) {
          return loc;
        }
      }
    }

    return null;
  }

  getLocationDetails(id) {
    const { locations } = this.props;

    const loc = this.getLocation(id, locations);
    let locDetails = {
      locationId: {
        qualifier: "duns",
        value: loc.code ? loc.code : ""
      },
      name: loc.name ? loc.name : "",
      address1: loc.address ? loc.address : "",
      address2: "",
      city: loc.city ? loc.city : "",
      state: loc.state ? loc.state : "",
      postalCode: loc.postal_code ? loc.postal_code : "",
      country: loc.country ? loc.country : ""
    };

    // If there is a valid geofence for this location, add
    // the center coordinate into our object
    if (loc.geofence) {
      const fenceType = getType(loc.geofence);
      if (fenceType.isValid(loc.geofence)) {
        locDetails["latitude"] = loc.geofence.properties.center.latitude;
        locDetails["longitude"] = loc.geofence.properties.center.longitude;
      }
    }

    return locDetails;
  }

  getStopDetails(stop, i) {
    return {
      stopSequence: i,
      stopType: stop.stopType,
      stopRole: stop.stopRole,
      earlyArrival: moment(stop.earlyArrival)
        .tz(stop.timezone)
        .format(),
      lateArrival: moment(stop.lateArrival)
        .tz(stop.timezone)
        .format(),
      location: this.getLocationDetails(stop.location)
    };
  }

  getShipmentPayload(data) {
    const { destination, origin, stops } = data;
    const { organization } = this.props;

    let stopLocations = [];

    stopLocations.push(this.getStopDetails(origin, 1));

    if (stops.length > 0) {
      stops.forEach((stop, i) => {
        stopLocations.push(this.getStopDetails(stop, i + 2));
      });
    }
    stopLocations.push(this.getStopDetails(destination, stops.length + 2));

    let shipmentReferences = [];

    if (data.routeID !== "") {
      shipmentReferences.push({
        qualifier: "route_id",
        description: "",
        value: data.routeID
      });
    }

    if (data.trailerID !== "") {
      shipmentReferences.push({
        qualifier: "trailer_number",
        description: "",
        value: data.trailerID
      });
    }

    return {
      carrier: {
        fvCarrierId:
          organization && organization.fv_id ? organization.fv_id : "",
        scac: organization && organization.scac ? organization.scac : ""
      },
      telematics: {
        provider: "peoplenet",
        trackingAssetId: data.assetID !== "" ? data.assetID : "NOTAVAILABLE"
      },
      customer: {
        fvCustomerId: data.customer
      },
      shipmentIdentification: {
        shipmentId: data.shipmentID
      },
      shipmentReferences: shipmentReferences,
      stops: stopLocations
    };
  }

  render() {
    const {
      t,
      checkStopValidation,
      createShipment,
      data,
      resetForm,
      validation
    } = this.props;

    return (
      <FlexColDiv
        css={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          color: Colors.text.DARK_BLUE,
          padding: "1em"
        }}
      >
        <FormRow
          css={{
            alignItems: "flex-start",
            display: "flex",
            paddingTop: ".5em"
          }}
        >
          <FlexColDiv css={{ alignItems: "flex-start", flex: 0.5 }}>
            <ValidationMessage
              isValid={
                validation.info ? validation.info.isValidCustomer : false
              }
              msg={t("create-shipment:Must have valid Customer/Shipper")}
            />
            <ValidationMessage
              isValid={
                validation.info ? validation.info.isValidShipmentId : false
              }
              msg={t("create-shipment:Must have valid Shipment ID")}
            />
          </FlexColDiv>
          <FlexColDiv css={{ alignItems: "flex-start", flex: 0.5 }}>
            <ValidationMessage
              isValid={
                validation.origin ? checkValidation(validation.origin) : false
              }
              msg={t("create-shipment:Must have valid Origin")}
            />
            <ValidationMessage
              isValid={
                validation.destination
                  ? checkValidation(validation.destination)
                  : false
              }
              msg={t("create-shipment:Must have valid Destination")}
            />
            {validation.stops && validation.stops.length > 0 ? (
              <ValidationMessage
                isValid={
                  validation.stops
                    ? checkStopValidation(validation.stops)
                    : false
                }
                msg={t("create-shipment:Must have valid Stops")}
              />
            ) : null}
          </FlexColDiv>
        </FormRow>
        <FormRow
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "1em"
          }}
        >
          <Button variant="danger" onClick={() => resetForm()}>
            {t("create-shipment:Reset")}
          </Button>
          <Button
            variant="success"
            disabled={
              !(
                checkValidation(validation.origin) &&
                checkValidation(validation.destination) &&
                checkStopValidation(validation.stops)
              )
            }
            onClick={() => createShipment(this.getShipmentPayload(data))}
          >
            {t("create-shipment:Submit")}
          </Button>
        </FormRow>
      </FlexColDiv>
    );
  }
}

export default withTranslation(["create-shipment"])(ShipmentFormControls);
