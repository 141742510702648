import { connect } from "react-redux";
import { SearchBar } from "../../../../components/search-bar/SearchBar";
import SearchBarState from "./AdcDetailsVinSearchBarState";
import { getSolutionId } from "../../../organizations/OrganizationsState";
import AdcDomainDataState from "../../../domain-data/AdcDomainData";

const mapStateToProps = state => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata
  } = SearchBarState.selectors;
  const {
    getProductTypes,
    getHoldTypes,
    getExceptionTypes
  } = AdcDomainDataState.selectors;
  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    solutionId: getSolutionId(state),
    productTypes: getProductTypes(state),
    holdTypes: getHoldTypes(state),
    exceptionTypes: getExceptionTypes(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    searchEntities
  } = SearchBarState.actionCreators;
  const { fetchDomainData } = AdcDomainDataState.actionCreators;
  return {
    setSearchCategory: key => dispatch(setSearchCategoryForKey(key)),
    setSearchText: text => dispatch(setSearchText(text)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    searchEntities: solutionId => dispatch(searchEntities(solutionId)),
    fetchDomainData: solutionId => dispatch(fetchDomainData(solutionId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
