/** @jsx jsx */
import PropTypes from "prop-types";
import React from "react";
import { jsx } from "@emotion/core";
import moment from "moment";
import _ from "lodash";

import { MadChicletCSS as Chiclet } from "../../components/chiclets";
import {
  textColorForExceptionType,
  iconForExceptionType
} from "../exceptions/ExceptionLink";
import { getArrivalWindowText } from "../shipment-detail/ArrivalWindow";
import {
  extractLastReportedAt,
  getRailLoadedStatusDisplayValue
} from "../shipment-detail/ShipmentUtils";
import CountryName from "../location/components/CountryName";

const TimeAndDateSplit = ({ dateTime }) => {
  const [date, time] = dateTime.split(/ (.+)/);
  return (
    <div>
      <span>{date}</span>
      <br />
      <b>{time}</b>
    </div>
  );
};

TimeAndDateSplit.propTypes = {
  dateTime: PropTypes.string.isRequired
};

const StatusCellRenderer = props => {
  const {
    activeStatus,
    currentStatus,
    exception,
    remainingDistance,
    currentLocation,
    actualDelivery,
    mode,
    statusName,
    railLoadedStatus,
    t
  } = props.value;

  const reportedAt = extractLastReportedAt(
    activeStatus,
    currentStatus,
    exception,
    currentLocation,
    actualDelivery,
    mode,
    t
  );
  const isRailMode = mode === "Rail";
  const railLoadedStatusDisplay = getRailLoadedStatusDisplayValue(
    railLoadedStatus,
    t
  );
  let reportedDetailedInfo = null;

  // H1-1851: If we have information reported, we should show more data that
  // would be different depending on the shipment mode
  if (reportedAt.lastTime) {
    // Rail shipment should present information in one way
    if (isRailMode) {
      reportedDetailedInfo = (
        <React.Fragment>
          <TimeAndDateSplit dateTime={reportedAt.lastTime} />
          <div>{reportedAt.lastCity}</div>
          {statusName ? <div>{statusName}</div> : null}
        </React.Fragment>
      );
    } else if (mode !== "Multimodal") {
      // Non multimodal should present information in another way
      reportedDetailedInfo = (
        <React.Fragment>
          <div>{reportedAt.lastTime}</div>
          <div>{reportedAt.lastCity}</div>
          {reportedAt.showDistance && mode !== "LTL" && (
            <div css={{ marginLeft: "0.5em" }}>
              {`${_.round(remainingDistance, 0)} ${t(
                "shipment-search:miles out"
              )}`}
            </div>
          )}
        </React.Fragment>
      );
    }
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ fontWeight: "bold", marginLeft: "0.25em" }}>
        {activeStatus} {currentStatus}
        {isRailMode && railLoadedStatus && (
          <div css={{ fontWeight: "normal" }}>{railLoadedStatusDisplay}</div>
        )}
      </div>
      <div
        css={{
          color: textColorForExceptionType(exception),
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginTop: "0.5em"
        }}
      >
        <div css={{ fontSize: "large" }}>
          {exception && iconForExceptionType(exception)}
        </div>
        <div css={{ marginLeft: "0.25em" }}>{t(`exceptions:${exception}`)}</div>
      </div>
      {reportedDetailedInfo && (
        <div>
          <div
            css={{
              marginLeft: "0.25em",
              marginTop: "0.5em",
              fontWeight: "bold"
            }}
          >
            {reportedAt.label}
          </div>
          <div css={{ marginLeft: "0.5em" }}>{reportedDetailedInfo}</div>
        </div>
      )}
    </div>
  );
};

StatusCellRenderer.propTypes = {
  value: PropTypes.object.isRequired
};

const ModeCellRenderer = props => {
  const {
    mode,
    currentModeName,
    exception,
    carrier,
    currentRoad,
    t
  } = props.value;

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div css={{ marginLeft: "0.25em", marginBottom: "0.5em" }}>{carrier}</div>
      {currentRoad ? (
        <div css={{ marginLeft: "0.25em", marginBottom: "0.5em" }}>
          <b>{t("shipment-search:Current Road")}: </b>
          {currentRoad}
        </div>
      ) : null}
      <div
        css={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div css={{ marginLeft: "0.25em" }}>
          <Chiclet
            shipmentMode={mode}
            stopMode={currentModeName}
            activeException={exception}
            width={25}
            height={25}
          />
        </div>
        <div css={{ marginLeft: "0.5em", marginRight: "0.5em", width: "100%" }}>
          {mode}
        </div>
      </div>
    </div>
  );
};

ModeCellRenderer.propTypes = {
  value: PropTypes.object.isRequired
};

const NullDatetimeRow = label => {
  return (
    <div css={{ color: "#999", display: "flex", flexDirection: "column" }}>
      <div
        css={{
          fontWeight: "bold",
          fontSize: "small",
          textDecoration: "underline"
        }}
      >
        {label}
      </div>
      N/A
    </div>
  );
};

function DatetimeRow(label, windowTextLine1, windowTextLine2) {
  if (windowTextLine1) {
    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          fontStyle: "normal",
          fontWeight: "bold"
        }}
      >
        <div
          css={{
            fontWeight: "bold",
            fontSize: "small",
            textDecoration: "underline"
          }}
        >
          {label}
        </div>
        {windowTextLine1}
        <div css={{ fontWeight: "normal" }}>{windowTextLine2}</div>
      </div>
    );
  }
}

const RowForWindow = (label, earliest, latest, isDateOnly) => {
  if (!(earliest || latest)) {
    return NullDatetimeRow(label);
  }
  const text = getArrivalWindowText({
    earliest_arrival_datetime: earliest,
    latest_arrival_datetime: latest,
    isDateOnly: isDateOnly
  });
  return DatetimeRow(label, text.windowTextLine1, text.windowTextLine2);
};

/* DEV-1545 LTL shipments displays dates only for Pickup & Delivery, no times.
    update: display times for Actual
*/
const DateTimeWindowCellRenderer = props => {
  const {
    earliestExpected,
    latestExpected,
    arrival,
    departure,
    mode,
    t
  } = props.value;
  let isOnlyDate = mode === "LTL";
  const expectedRow = RowForWindow(
    t("shipment-search:Scheduled"),
    earliestExpected,
    latestExpected,
    isOnlyDate
  );
  const arrivalRow = RowForWindow(
    t("shipment-search:Actual Arrival"),
    arrival,
    null,
    false
  );
  const departureRow = RowForWindow(
    t("shipment-search:Actual Departure"),
    departure,
    null,
    false
  );

  return (
    <div css={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
      {expectedRow}
      <div css={{ height: "0.5em" }} />
      {arrivalRow}
      <div css={{ height: "0.5em" }} />
      {departureRow}
    </div>
  );
};

DateTimeWindowCellRenderer.propTypes = {
  value: PropTypes.object.isRequired
};

const getInterchangeRow = (references, t) => {
  if (references) {
    // Filter down to ETAI references in the future
    const interchangeRefs = references
      .filter(
        r =>
          _.includes(["ETAI"], r.qualifier) &&
          moment(_.split(r.value, "|")[3], "YYYY-MM-DD-HH:mm:SSS") > moment()
      )
      .sort((r1, r2) => {
        const etai1 = _.split(r1.value, "|");
        const eta1 = moment(etai1[3], "YYYY-MM-DD-HH:mm:SSS");
        const etai2 = _.split(r2.value, "|");
        const eta2 = moment(etai2[3], "YYYY-MM-DD-HH:mm:SSS");

        return eta1.valueOf() - eta2.valueOf();
      });

    if (interchangeRefs.length > 0) {
      const etai = _.split(interchangeRefs[0].value, "|");
      let loc = `${etai[0]}, ${etai[1]}`;
      let eta = etai[3];
      let tz = etai[4];

      // DEV-1601: times were off by an hour.  moment() by default parses
      // the date in the local timezone.  We need to parse it in the timezone
      // provided in the ETAI, which can be abbreviated CT, etc.  Convert first..
      // Moment doesn't seem to understand CT and others.  EST might not even be
      // parsed.  Do what is safe and convert.
      let parsingTZ = tz;
      if (tz === "ET" || tz === "EST") {
        parsingTZ = "America/Detroit";
      } else if (tz === "CT" || tz === "CST") {
        parsingTZ = "America/Chicago";
      } else if (tz === "MT" || tz === "MST") {
        parsingTZ = "America/Denver";
      } else if (tz === "PT" || tz === "PST") {
        parsingTZ = "America/Los_Angeles";
      }
      const momentObj = moment
        .tz(eta, "YYYY-MM-DD-HH:mm:SSS", parsingTZ)
        .local();
      let interchangeTime = momentObj.format("HH:mm");
      // Since we convert to local time, now guess the local timezone and format
      // it as MST, EST, CST, etc. depending on the user's local timezone.  This
      // readout should then match what is in the Stops tab of the Shipment Detail
      // page, since that is also a datetime formatted to display in the user's
      // timezone (which is shown).  Show here for validation purposes.
      let localTZ = moment.tz(moment.tz.guess()).format("z");
      let nextInterchangeDisplayTime = interchangeTime + " " + localTZ;
      let nextInterchangeDisplayDate = momentObj.format("l");

      return (
        <div css={{ marginTop: "0.5em" }}>
          <div
            css={{
              fontSize: "small",
              textDecoration: "underline",
              fontWeight: "bold"
            }}
          >
            {t("shipment-search:Next Interchange")}
          </div>
          <div>
            <span css={{ marginTop: "0.5em", fontWeight: "bold" }}>
              {nextInterchangeDisplayTime}
            </span>
            <br />
            <span>{nextInterchangeDisplayDate}</span>
          </div>
          <div css={{ marginTop: "0.5em", fontSize: "small" }}>{loc}</div>
        </div>
      );
    }
  }

  return null;
};

const LocationCellRenderer = props => {
  const {
    name,
    city,
    state,
    postalCode,
    country,
    code,
    references,
    t
  } = props.value;

  return (
    <div css={{ display: "flex", flexDirection: "column", alignItems: "left" }}>
      <div css={{ fontWeight: "bold" }}>{code}</div>
      <div css={{ marginBottom: "0.5em" }}>{name}</div>
      <div css={{ fontSize: "small" }}>
        {city}
        {city && (state || postalCode) ? ", " : ""}
        {state}
        {state && postalCode ? ", " : ""}
        {postalCode}
      </div>
      {country && <CountryName countryCode={country} />}
      {getInterchangeRow(references, t)}
    </div>
  );
};

LocationCellRenderer.propTypes = {
  value: PropTypes.object.isRequired
};

const IDCellRenderer = props => {
  const {
    shipmentID,
    routeID,
    trailerNumber,
    tripPlanNumber,
    railTrainId,
    t
  } = props.value;
  return (
    <div>
      <div css={{ fontWeight: "bold" }}>{shipmentID}</div>
      {routeID && (
        <div>
          <div
            css={{
              marginTop: "0.5em",
              fontWeight: "bold"
            }}
          >
            {t("shipment-search:Route ID")}
          </div>
          {routeID}
        </div>
      )}
      {tripPlanNumber && (
        <div>
          <div
            css={{
              marginTop: "0.5em",
              fontWeight: "bold"
            }}
          >
            {t("shipment-search:Trip Plan ID")}
          </div>
          {tripPlanNumber}
        </div>
      )}
      {trailerNumber && (
        <div>
          <div
            css={{
              marginTop: "0.5em",
              fontWeight: "bold"
            }}
          >
            {t("shipment-search:Trailer Number")}
          </div>
          {trailerNumber}
        </div>
      )}
      {railTrainId && (
        <div>
          <div
            css={{
              marginTop: "0.5em",
              fontWeight: "bold"
            }}
          >
            {t("shipment-search:Train ID")}
          </div>
          {railTrainId}
        </div>
      )}
    </div>
  );
};

IDCellRenderer.propTypes = {
  value: PropTypes.object.isRequired
};

export const getColumns = (lads, t) => {
  return [
    {
      Header: t("shipment-search:Shipment ID"),
      id: "creator_shipment_id",
      accessor: d => {
        return {
          shipmentID: d.creator_shipment_id,
          routeID: d.route_number,
          trailerNumber: d.trailer_number,
          tripPlanNumber: d.trip_plan_number,
          railTrainId: d.rail_train_id,
          t: t
        };
      },
      minWidth: 150,
      Cell: IDCellRenderer
    },
    {
      Header: t("shipment-search:Status"),
      id: "current_status_ng",
      accessor: shipment => {
        return {
          activeStatus: shipment.active_status_ng,
          currentStatus: shipment.current_status_ng,
          exception: shipment.active_exceptions_ng,
          remainingDistance: shipment.remaining_distance,
          currentLocation: shipment.current_location,
          actualDelivery: shipment.destination_actual_arrival,
          mode: shipment.mode_name,
          statusName: shipment.status_name,
          railLoadedStatus: shipment.rail_loaded_status,
          t
        };
      },
      Cell: StatusCellRenderer,
      getProps: (state, rowInfo) => ({
        style: {
          whiteSpace: "unset"
        }
      })
    },
    {
      Header: t("shipment-search:Carrier"),
      id: "carrier_name",
      accessor: d => {
        return {
          mode: d.mode_name,
          currentModeName: d.mode_name,
          exception: d.active_exceptions_ng,
          carrier: `${d.carrier_scac} - ${d.carrier_name}`,
          currentRoad: d.current_road_name,
          t: t
        };
      },
      getProps: (state, rowInfo) => ({
        style: {
          whiteSpace: "unset"
        }
      }),
      Cell: ModeCellRenderer
    },
    {
      Header: t("shipment-search:Origin"),
      id: "origin_name",
      Cell: LocationCellRenderer,
      accessor: d => {
        return {
          name: d.origin_name,
          city: d.origin_city,
          state: d.origin_state,
          postalCode: d.origin_postal_code,
          country: d.origin_country,
          code: d.origin_location_code,
          references: d.shipment_references,
          t: t
        };
      },
      getProps: (state, rowInfo) => ({
        style: {
          whiteSpace: "unset"
        }
      })
    },
    {
      Header: t("shipment-search:Destination"),
      id: "destination_name",
      Cell: LocationCellRenderer,
      accessor: d => {
        return {
          name: d.destination_name,
          city: d.destination_city,
          state: d.destination_state,
          postalCode: d.destination_postal_code,
          country: d.destination_country,
          code: d.destination_location_code,
          t: t
        };
      },
      getProps: (state, rowInfo) => ({
        style: {
          whiteSpace: "unset"
        }
      })
    },
    // DEV-256: for the expected arrival times (at origin and destination), the
    // field ids are still origin_earliest_arrival and destination_earliest_arrival.
    // However, we previously used "d.origin_earliest_arrival" and
    // "d.destination_earliest_arrival" as the data sources for these fields.
    // The ask here is to use latest time instead.
    {
      Header: t("shipment-search:Pickup"),
      id: "origin_earliest_arrival",
      accessor: d => {
        return {
          earliestExpected: d.origin_earliest_arrival,
          latestExpected: d.origin_latest_arrival,
          arrival: d.origin_actual_arrival,
          departure: d.origin_actual_departure,
          mode: d.mode_name,
          t: t
        };
      },
      Cell: DateTimeWindowCellRenderer
    },
    {
      Header: t("shipment-search:Delivery"),
      id: "destination_earliest_arrival",
      accessor: d => {
        return {
          earliestExpected: d.destination_earliest_arrival,
          latestExpected: d.destination_latest_arrival,
          arrival: d.destination_actual_arrival,
          departure: d.destination_actual_departure,
          mode: d.mode_name,
          t: t
        };
      },
      Cell: DateTimeWindowCellRenderer
    }
  ];
};
