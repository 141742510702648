/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import Colors from "../../../styles/colors";
import { withTranslation } from "react-i18next";

const iconCss = {
  marginTop: -5,
  textAlign: "center",
  fontSize: 24,
  color: Colors.background.DARK_TEAL
};

const childLinksCss = {
  margin: "-5px 0 10px 0"
};

const mobileGroupCss = {
  position: "relative",

  ".mobile-menu": {
    backgroundColor: "#2c3948",
    display: "none",
    marginTop: 3,
    position: "fixed",
    left: 48,
    zIndex: 999,

    ".triangle": {
      position: "absolute",
      top: 15,
      left: -10,
      border: "5px solid transparent",
      borderRight: "5px solid #2c3948",
      height: 5,
      width: 10
    }
  },

  ".nav-item": {
    paddingLeft: "10px !important"
  },

  a: {
    textDecoration: "none !important"
  },

  "&:hover": {
    ".mobile-menu": {
      display: "block"
    }
  }
};

// Collapsible group of links displayed in primary nav bar
const NavGroupMini = ({ t, title, icon, children }) => {
  return (
    <div css={mobileGroupCss}>
      <div className="mobile-menu">
        <i className="triangle" />
        <div
          css={{
            padding: 10
          }}
        >
          <strong>{t(title)}</strong>
        </div>
        <div css={childLinksCss}>{children}</div>
      </div>
      <div
        css={iconCss}
        style={{
          width: 48,
          height: 48,
          minWidth: 48,
          minHeight: 48,
          lineHeight: "48px"
        }}
      >
        {icon}
      </div>
    </div>
  );
};

NavGroupMini.propTypes = {
  groupKey: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.object,
  isOpen: PropTypes.bool,
  toggleOpenGroup: PropTypes.func,
  sidebarMinimize: PropTypes.bool
};
export default withTranslation(["appnav"])(NavGroupMini);
