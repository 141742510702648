/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";
import MapSearchResults from "../../components/map-search-results/MapSearchResults";
import Results from "../../components/map-search-results/Results";
import {
  WatchColumn,
  NameAndLocationColumn,
  DemandAreasColumn,
  TotalOnsiteVinsColumn,
  TotalAgingVinsColumn,
  CapacityStatusColumn
} from "./AdcSearchResultsColumns";
import AdcSearchResultsMap from "./AdcSearchResultsMap";
import Colors from "../../styles/colors";

import SavedSearchContainer from "../adc-search/AdcSavedSearchContainer";
import SearchBarContainer from "../adc-search/AdcSearchBarContainer";
import FilterSectionContainer from "../adc-search/AdcFilterSectionContainer";
import { MediaQueries } from "../../components/responsive";

// We expect there to be far fewer ADCs than this. We could remove the page size
// but it seems safer to specify some kind of upper bound.
const LARGE_PAGE_SIZE = 200;

const AdcSearchResultsView = ({
  pushAdcDetailsView,
  adcLocations,
  setPagination,
  searchEntities,
  solutionId,
  watchedLocations = [],
  fetchWatchedLocations
}) => {
  const { t } = useTranslation("adc-search-results");

  useTrackWithMixpanelOnce("Viewed Page: ADC / Search Results");

  useEffect(() => {
    setPagination(solutionId, 0, LARGE_PAGE_SIZE, false);
    searchEntities(solutionId, false);
    fetchWatchedLocations();
  }, [solutionId, setPagination, searchEntities, fetchWatchedLocations]);

  const searchView = (
    <div className="d-flex flex-column mt-2 ml-2 mr-2">
      <SearchBarContainer
        showSearchOptions
        isShowingAdvancedSearchButton={false}
        isShowingFilters={true}
      />
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          [MediaQueries.extraLarge]: {
            flexDirection: "row",
            justifyContent: "space-between"
          }
        }}
      >
        <div className="mt-2 mr-2">
          <FilterSectionContainer
            show
            backgroundColor={Colors.background.GRAY}
            sectionStyle={{ borderRadius: 3 }}
          />
        </div>
        <SavedSearchContainer />
      </div>
    </div>
  );

  // Add watch status to the existing location entries
  const entities = adcLocations.map(location => ({
    ...location,
    watch: watchedLocations.includes(location.locationId)
  }));

  return (
    <MapSearchResults
      search={searchView}
      map={
        <AdcSearchResultsMap
          allAdcLocations={entities}
          onViewLocation={loc => pushAdcDetailsView(loc.locationId)}
        />
      }
      results={
        <Results
          entities={entities}
          total={adcLocations.length}
          totalText={t("adc-search-results:ADCs")}
          columns={[
            WatchColumn,
            NameAndLocationColumn,
            DemandAreasColumn,
            TotalOnsiteVinsColumn,
            TotalAgingVinsColumn,
            CapacityStatusColumn
          ]}
          showPagination={false}
          pageSize={entities.length}
          onRowClick={(rowData, column) => {
            // Don't want to redirect if we clicked on a WatchColumn cell
            if (
              column.id === WatchColumn.id ||
              column.id === DemandAreasColumn.id
            ) {
              return;
            } else {
              pushAdcDetailsView(rowData.original.locationId);
            }
          }}
          minRows={5}
        />
      }
    />
  );
};

AdcSearchResultsView.propTypes = {
  adcLocations: PropTypes.array,
  setPagination: PropTypes.func.isRequired,
  searchEntities: PropTypes.func.isRequired,
  pushAdcDetailsView: PropTypes.func.isRequired,
  solutionId: PropTypes.string,
  watchedLocations: PropTypes.array,
  fetchWatchedLocations: PropTypes.func
};

export default AdcSearchResultsView;
