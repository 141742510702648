import { connect } from "react-redux";
import SavedSearchSelect from "../../components/saved-search/SavedSearchSelect";
import DeleteSavedSearchModal from "../../components/saved-search/DeleteSavedSearchModal";
import MetalTrackingSavedSearchState from "./MetalTrackingSavedSearchState";
import MetalTrackingSearchBarState from "./MetalTrackingSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import EditSavedSearchModalContainer from "../mt-search/MetalTrackingEditSavedSearchModalContainer";

const mapStateToProps = state => ({
  ...state.fvSearch,
  savedSearch: MetalTrackingSearchBarState.selectors.getSelectedSavedSearch(
    state
  ),
  savedSearches: MetalTrackingSavedSearchState.selectors.getSavedSearches(
    state
  ),
  searchObj: {},
  searchFilters: MetalTrackingSearchBarState.selectors.getSearchFilters(state),
  searchCategory: MetalTrackingSearchBarState.selectors.getSearchCategory(
    state
  ),
  searchText: MetalTrackingSearchBarState.selectors.getSearchText(state),
  solutionId: getSolutionId(state),
  editModal: EditSavedSearchModalContainer,
  deleteModal: DeleteSavedSearchModal
});

const mapDispatchToProps = dispatch => ({
  fetchSavedSearches: () =>
    dispatch(MetalTrackingSavedSearchState.actionCreators.fetchSavedSearches()),
  loadSavedSearch: savedSearch =>
    dispatch(
      MetalTrackingSavedSearchState.actionCreators.loadSavedSearch(savedSearch)
    ),
  saveSearch: (name, searchObj) =>
    dispatch(
      MetalTrackingSavedSearchState.actionCreators.saveSearch(name, searchObj)
    ),
  editSearch: (id, name, searchObj) =>
    dispatch(
      MetalTrackingSavedSearchState.actionCreators.editSearch(
        id,
        name,
        searchObj
      )
    ),
  deleteSearch: id =>
    dispatch(MetalTrackingSavedSearchState.actionCreators.deleteSearch(id)),
  exportSearch: (t, solutionId) =>
    dispatch(
      MetalTrackingSearchBarState.actionCreators.exportEntities(t, solutionId)
    ),
  selectSavedSearch: (solutionId, item) =>
    dispatch(
      MetalTrackingSearchBarState.actionCreators.selectSavedSearch(
        solutionId,
        item
      )
    ),
  resetSavedSearch: () =>
    dispatch(MetalTrackingSearchBarState.actionCreators.resetSavedSearch())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedSearchSelect);
