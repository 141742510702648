import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Dialog from "react-bootstrap-dialog";
import { withTranslation } from "react-i18next";

import { deleteOrganization } from "../OrganizationsState";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput
} from "../../../components/modal-elems";
import { FlexRowDiv } from "../../../styles/container-elements";

const initOrg = {
  name: "",
  orgType: "",
  email: "",
  freightVerifyId: "",
  contactName: "",
  phoneNumber: "",
  scac: "",
  base64Image: null,
  id: null
};

const DeleteOrganizationModal = ({
  deleteOrganization,
  hide,
  organization,
  show,
  t
}) => {
  let dialog = useRef();
  const [org, setOrg] = useState(initOrg);

  useEffect(() => {
    Dialog.setOptions({
      defaultOkLabel: t("users:Yes"),
      defaultCancelLabel: t("users:No"),
      primaryClassName: "btn-success"
    });
  });

  useMemo(
    () =>
      setOrg(prevState => {
        return {
          ...prevState,
          id:
            organization && organization.organization_id
              ? organization.organization_id
              : null,
          name:
            organization && organization.org_name ? organization.org_name : "",
          email:
            organization && organization.email_address
              ? organization.email_address
              : "",
          freightVerifyId:
            organization && organization.fv_id ? organization.fv_id : "",
          contactName:
            organization && organization.contact_name
              ? organization.contact_name
              : "",
          phoneNumber:
            organization && organization.phone_no ? organization.phone_no : "",
          scac: organization && organization.scac ? organization.scac : "",
          orgType:
            organization && organization.org_type ? organization.org_type : ""
        };
      }),
    [organization]
  );

  const isCarrier = org.orgType === "Carrier";

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <ModalHeader title={t("organizations:Delete Organization")} />
      <ModalBody>
        <FlexRowDiv>
          <ReadOnlyInput
            label={t("organizations:Organization Name")}
            value={org.name}
          />
          <ReadOnlyInput label={t("organizations:Type")} value={org.orgType} />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput label={t("organizations:Email")} value={org.email} />
          <ReadOnlyInput
            label={t("organizations:FreightVerify ID")}
            value={org.freightVerifyId}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("organizations:Contact Name")}
            value={org.contactName}
          />
          <ReadOnlyInput
            label={t("organizations:Phone Number")}
            value={org.phoneNumber}
          />
        </FlexRowDiv>
        {isCarrier ? (
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <ReadOnlyInput label={t("organizations:SCAC")} value={org.scac} />
          </FlexRowDiv>
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="light"
          style={{ marginRight: ".5em" }}
          onClick={() => {
            hide();
          }}
        >
          {t("organizations:Cancel")}
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            dialog.show({
              body: t(
                "organizations:Are you sure you want to delete this organization?"
              ),
              actions: [
                Dialog.CancelAction(dialog => {
                  dialog.hide();
                }),
                Dialog.OKAction(() => {
                  deleteOrganization(org.id);
                  hide();
                })
              ],
              bsSize: "small",
              onHide: dialog => {
                dialog.hide();
              }
            });
          }}
        >
          {t("organizations:Delete")}
        </Button>
      </ModalFooter>
      <Dialog
        ref={el => {
          dialog = el;
        }}
      />
    </Modal>
  );
};

DeleteOrganizationModal.propTypes = {
  deleteOrganization: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  organization: PropTypes.object,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  deleteOrganization
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["organizations"])(DeleteOrganizationModal));
