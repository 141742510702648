/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import Loader from "react-loader";
import ContentJson from "./documentation-styled-components/ContentJson";
import ContentPdf from "./documentation-styled-components/ContentPdf";
import LinkPdf from "./documentation-styled-components/LinkPdf";
import ContentHtml from "./documentation-styled-components/ContentHtml";

const contentCss = {
  padding: "1em",
  height: "100%",
  display: "flex",
  flex: "1 1 auto"
};

class ContentView extends React.Component {
  componentDidMount() {
    const { filename, baseUrl, fetchContent } = this.props;

    // Only fetch content if we don't have a baseUrl
    if (filename && !baseUrl) {
      fetchContent(filename);
    }
  }

  componentDidUpdate(prevProps) {
    const { filename, baseUrl, fetchContent } = this.props;

    // Only fetch content if we don't have a baseUrl
    if (filename && filename !== prevProps.filename && !baseUrl) {
      fetchContent(filename);
    }
  }

  render() {
    const {
      filename,
      baseUrl,
      content,
      isLoading,
      pushDocumentView
    } = this.props;

    if (!content) {
      return null;
    }

    const isJson =
      filename.includes(".json") &&
      typeof content === "object" &&
      !Array.isArray(content);
    const isUrl = baseUrl && baseUrl.length > 0;
    const isPdf =
      filename.includes(".pdf") && (isUrl || typeof content === "string");
    const isHtml = filename.includes(".htm") && typeof content === "string";

    return (
      <div css={contentCss}>
        <Loader loaded={!isLoading}>
          {isJson ? (
            <ContentJson
              content={content}
              pushDocumentView={pushDocumentView}
            />
          ) : null}
        </Loader>
        {isPdf && !isUrl ? (
          <ContentPdf filename={filename} content={content} />
        ) : null}
        {isPdf && isUrl ? (
          <LinkPdf filename={filename} baseUrl={baseUrl} />
        ) : null}
        {isHtml ? <ContentHtml content={content} /> : null}
      </div>
    );
  }
}

export default ContentView;
