/** @jsx jsx */
// eslint-disable-next-line
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import {
  PanelGroup,
  PanelGroupHeader,
  PanelGroupContent
} from "../../../components/panel-group/PanelGroup";
import OriginDestinationBox from "./OriginDestinationBox";
import RouteHeader from "./RouteHeader";
import { useTranslation } from "react-i18next";
import TimeDateBox from "./TimeDateBox";
import Colors from "../../../styles/colors";
import {
  localizedTimeFormatter,
  localizedDateFormatter
} from "../../../utils/date-time";

const ArrivalDepartureCollapsible = ({
  shipment,
  destShipment,
  origin,
  destination
}) => {
  const { t } = useTranslation("shipment-details");

  let etaBox = null;
  if (destination.calculated_eta && !destination.actual_arrival_datetime) {
    etaBox = <EtaBox eta={destination.calculated_eta} />;
  }

  return (
    <PanelGroup>
      <PanelGroupHeader title={t("Arrivals and Departures")}>
        {etaBox}
      </PanelGroupHeader>
      <RouteHeader shipment={shipment} />
      <PanelGroupContent style={{ padding: 0 }}>
        <OriginDestinationBox
          shipment={shipment}
          destShipment={destShipment}
          origin={origin}
          destination={destination}
          displayRouteHeader={false}
        />
      </PanelGroupContent>
    </PanelGroup>
  );
};

const etaBoxStyles = {
  container: {
    position: "absolute",
    top: 0,
    right: 0,
    color: "white",
    background: Colors.background.DARK_TEAL,
    borderRadius: 0,
    borderTopRightRadius: 4,
    padding: "0 30px",
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    paddingLeft: 60
  },
  eta: {
    position: "absolute",
    top: "50%",
    left: 11,
    transform: "translate(0, -50%)",
    backgroundColor: "white",
    border: "1px solid " + Colors.background.DARK_TEAL,
    borderRadius: 4,
    color: Colors.background.DARK_TEAL,
    fontWeight: "bold",
    padding: "0 5px"
  },
  timeDateBoxTitle: { margin: 0 },
  timeDateBox: {
    width: "unset",
    paddingLeft: 0,
    fontSize: "1.4em",
    div: { color: "white" }
  },
  na: {
    fontWeight: "bold"
  }
};

const EtaBox = ({ eta }) => {
  const { t } = useTranslation("shipment-details");

  return (
    <div css={etaBoxStyles.container}>
      <div css={etaBoxStyles.eta}>{t("ETA")}</div>
      {eta ? (
        <TimeDateBox
          titleStyles={etaBoxStyles.timeDateBoxTitle}
          styles={etaBoxStyles.timeDateBox}
          time={localizedTimeFormatter(eta)}
          date={localizedDateFormatter(eta)}
          innerWrapperStyles={{ marginBottom: 0 }}
        />
      ) : (
        <div css={etaBoxStyles.na}>{t("N/A")}</div>
      )}
    </div>
  );
};

EtaBox.propTypes = {
  eta: PropTypes.string
};

export default ArrivalDepartureCollapsible;
