/** @jsx jsx */
import { Component } from "react";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import {
  VinHolds,
  FinishedVehicleExceptions
} from "./fv-dashboard-styled-components/fv-dashboard-widgets";
import WatchedVinsTableContainer from "./fv-dashboard-styled-components/WatchedVinsTableContainer";
import DashboardTotalVins from "./fv-dashboard-styled-components/DashboardTotalVins";
import { MediaQueries } from "../../components/responsive";
import SearchBarContainer from "../fv-search/FinVehicleSearchBarContainer";
import FiltersContainer from "../fv-search/FinVehicleSearchFiltersContainer";
import SavedSearchContainer from "../fv-search/FinVehicleSavedSearchContainer";
import Colors from "../../styles/colors";

import { withTranslation } from "react-i18next";
import MixpanelUtils from "../../trackers/mixpanel";

class FinVehicleDashboard extends Component {
  static propTypes = {
    clearSearchFilter: PropTypes.func.isRequired,
    clearSearchFilters: PropTypes.func.isRequired,
    domainDataLoading: PropTypes.bool,
    entityCount: PropTypes.number,
    exceptionTypes: PropTypes.array,
    exceptions: PropTypes.array,
    exceptionsLoading: PropTypes.bool,
    fetchEntityCount: PropTypes.func.isRequired,
    fetchExceptions: PropTypes.func.isRequired,
    fetchHoldCounts: PropTypes.func.isRequired,
    holdTypes: PropTypes.any,
    resetSavedSearch: PropTypes.func.isRequired,
    resetSearchBar: PropTypes.func.isRequired,
    searchEntities: PropTypes.any,
    setSearchFilter: PropTypes.func.isRequired,
    solutionId: PropTypes.any,
    t: PropTypes.func.isRequired,
    vinHolds: PropTypes.array,
    vinHoldsLoading: PropTypes.any
  };

  state = {
    showFilters: false
  };

  componentDidMount() {
    const {
      resetSearchBar,
      clearSearchFilters,
      fetchExceptions,
      fetchEntityCount,
      solutionId,
      fetchHoldCounts,
      resetSavedSearch
    } = this.props;

    resetSearchBar();
    clearSearchFilters();
    fetchExceptions(solutionId);
    fetchEntityCount(solutionId);
    fetchHoldCounts(solutionId);
    resetSavedSearch();

    MixpanelUtils.track("Viewed Page: Finished Vehicle / Dashboard");
  }

  render() {
    const {
      t,
      entityCount,
      exceptions,
      exceptionsLoading,
      vinHolds,
      vinHoldsLoading,
      holdTypes,
      setSearchFilter,
      clearSearchFilter,
      searchEntities,
      solutionId,
      exceptionTypes,
      domainDataLoading
    } = this.props;
    const { showFilters } = this.state;

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: "1em"
        }}
      >
        <SearchBarContainer
          isShowingFilters={showFilters}
          toggleShowFilters={() => this.setState({ showFilters: !showFilters })}
        />
        <FiltersContainer
          show={showFilters}
          backgroundColor={Colors.background.GRAY}
          sectionStyle={{ paddingRight: "12em" }}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              [MediaQueries.mediumAndUp]: {
                flexDirection: "row"
              }
            }}
          >
            <DashboardTotalVins />
            <div style={{ marginBottom: "0.5em" }}>
              <SavedSearchContainer readOnly={true} />
            </div>
          </div>
          <div
            css={{
              [MediaQueries.smallAndUp]: {
                display: "flex",
                flexDirection: "column"
              },
              [MediaQueries.mediumAndUp]: {
                gridColumnGap: "20px",
                gridRowGap: "20px",
                justifyItems: "stretch",
                gridTemplateColumns: "repeat(6, 1fr)",
                display: "grid",
                gridTemplateRows: "400px 1fr auto"
              },
              [MediaQueries.largeAndUp]: {
                gridTemplateRows: "213px auto"
              }
            }}
          >
            <div
              css={{
                [MediaQueries.smallAndUp]: {
                  marginBottom: "20px"
                },
                [MediaQueries.mediumAndUp]: {
                  marginBottom: 0,
                  gridColumn: "1 / span 3"
                },
                [MediaQueries.largeAndUp]: {
                  gridColumn: "1 / span 4",
                  gridRow: "1 / span 1"
                }
              }}
            >
              <FinishedVehicleExceptions
                solutionId={solutionId}
                exceptions={exceptions}
                exceptionsLoading={exceptionsLoading}
                setSearchFilter={setSearchFilter}
                clearSearchFilter={clearSearchFilter}
                searchEntities={searchEntities}
                exceptionTypes={exceptionTypes}
                entityCount={entityCount || 0}
                domainDataLoading={domainDataLoading}
              />
            </div>
            <div
              css={{
                [MediaQueries.smallAndUp]: {
                  marginBottom: "20px"
                },
                [MediaQueries.mediumAndUp]: {
                  marginBottom: 0,
                  gridColumn: "4 / span 3",
                  gridRow: "1 / span 2"
                },
                [MediaQueries.largeAndUp]: {
                  gridColumn: "5 / span 2",
                  gridRow: "1 / span 2"
                }
              }}
            >
              <VinHolds
                t={t}
                vinHolds={vinHolds}
                vinHoldsLoading={vinHoldsLoading}
                holdTypes={holdTypes}
                solutionId={solutionId}
                setSearchFilter={setSearchFilter}
                clearSearchFilter={clearSearchFilter}
                searchEntities={searchEntities}
              />
            </div>
            <div
              css={{
                [MediaQueries.mediumAndUp]: {
                  gridColumn: "1 / span 6",
                  gridRow: "3 / auto"
                },
                [MediaQueries.largeAndUp]: {
                  gridColumn: "1 / span 4",
                  gridRow: "2 / auto"
                }
              }}
            >
              <WatchedVinsTableContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["fv-dashboard"])(FinVehicleDashboard);
