import React from "react";
import { withTranslation } from "react-i18next";
import { FlexRow } from "./GeofenceEditForm";
import Colors from "../../styles/colors";
// import { Polygon } from "glamorous";

class PolygonalForm extends React.Component {
  render() {
    const { t, points } = this.props;
    return (
      <FlexRow>
        <div style={{ color: Colors.text.LIGHT_GRAY, fontSize: "0.9em" }}>
          <span style={{ fontWeight: "bold", fontSize: "1.5em" }}>
            {" "}
            {points && points.length ? points.length - 1 : 0}{" "}
          </span>
          {t("geofence-edit:Points")}
        </div>
      </FlexRow>
    );
  }
}

export default withTranslation(["geofence-edit"])(PolygonalForm);
