import { connect } from "react-redux";
import EditSavedSearchModal from "../../components/saved-search/EditSavedSearchModal";
import FinVehicleSearchBarState from "./FinVehicleSearchBarState";
import FinVehicleSavedSearchState from "./FinVehicleSavedSearchState";
import SearchBarContainer from "./FinVehicleSearchBarContainer";
import FilterSectionContainer from "./FinVehicleSearchFiltersContainer";

const mapStateToProps = state => {
  const {
    getSearchFilters,
    getSearchCategory,
    getSearchText
  } = FinVehicleSearchBarState.selectors;

  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer
  };
};

const mapDispatchToProps = dispatch => {
  const { saveSearch, editSearch } = FinVehicleSavedSearchState.actionCreators;

  return {
    saveSearch: (name, search) => dispatch(saveSearch(name, search)),
    editSearch: (id, name, search) => dispatch(editSearch(id, name, search))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSavedSearchModal);
