import { createSelector } from "reselect";
import finVehicleDomainData from "../domain-data/FinVehicleDomainData";

// Format Origin options for autotype dropdown
export const selectOriginOptions = createSelector(
  [finVehicleDomainData.selectors.getOrigins],
  origins => origins.map(o => `${o.name} (${o.code})`)
);

// Format Destination options for autotype dropdown
export const selectDestinationOptions = createSelector(
  [finVehicleDomainData.selectors.getDestinations],
  destinations => destinations.map(d => `${d.name} (${d.code})`)
);
