/** @jsx jsx */
/**
 * Functions that builds the columns for the table in vin-details. Notice that
 * it is needed to encapsulate its complexities in just one file. It can grow
 * in complexity because of translation stuff and also if we need filters and
 * specificities in the future. Because of that we adopt this pattern for all
 * our tables in FV UI.
 */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import _ from "lodash";
import DwellTimeCell from "../../../../components/tables/Cell/DwellTimeCell";
import RangeCell from "../../../../components/tables/Cell/RangeCell";

const ForgivingCellRenderer = props => {
  const val = _.toString(props.value);
  return <div css={{ textOverflow: "auto", overflow: "none" }}>{val}</div>;
};

ForgivingCellRenderer.propTypes = {
  value: PropTypes.any
};

export const resultsTableColumns = (t, locationType) => {
  const sharedColumns = [
    {
      Header: t("adc-details:VIN"),
      accessor: "id",
      width: 200,
      Cell: ForgivingCellRenderer
    },
    {
      Header: t("adc-details:Product Type"),
      accessor: "productType",
      Cell: ForgivingCellRenderer
    }
  ];

  const inboundColumns = [
    {
      Header: t("adc-details:Last Location"),
      accessor: "lastLocation",
      Cell: ForgivingCellRenderer
    },
    {
      Header: t("adc-details:ETA"),
      accessor: "eta",
      Cell: row => <RangeCell t={t} dateRange={row.original.eta} />
    },
    {
      Header: t("adc-details:Status"),
      accessor: "status",
      Cell: ForgivingCellRenderer
    }
  ];

  const outboundColumns = [
    {
      Header: t("adc-details:Carrier"),
      accessor: "nextActiveCarrierName",
      Cell: ForgivingCellRenderer
    },
    {
      Header: t("adc-details:Scheduled Pickup"),
      accessor: "scheduledPickup",
      Cell: row => <RangeCell t={t} dateRange={row.original.scheduledPickup} />
    },
    {
      Header: t("adc-details:Destination Dealer"),
      accessor: "nextPlannedDestination",
      Cell: ForgivingCellRenderer
    },
    {
      Header: t("adc-details:Status"),
      accessor: "status",
      Cell: ForgivingCellRenderer
    }
  ];

  const onsiteColumns = [
    {
      Header: t("adc-details:Destination Dealer"),
      // H2-1084: changed from destinationDealer to nextPlannedDestination only
      // for onsite columns
      accessor: "nextPlannedDestination",
      Cell: ForgivingCellRenderer
    },
    {
      Header: t("adc-details:Available for Pickup"),
      accessor: "availableForPickup",
      Cell: row => (
        <RangeCell t={t} dateRange={row.original.availableForPickup} />
      )
    },
    {
      Header: t("adc-details:Dwell Timer"),
      accessor: "dwellTimer",
      Cell: row => <DwellTimeCell currentDwell={row.original.dwellTimer} />
    }
  ];

  if (locationType === "Inbound") {
    return [...sharedColumns, ...inboundColumns];
  }

  if (locationType === "Scheduled Outbound") {
    return [...sharedColumns, ...outboundColumns];
  }

  if (locationType === "On Site") {
    return [...sharedColumns, ...onsiteColumns];
  }
};
