import { connect } from "react-redux";
import EditSavedSearchModal from "../../components/saved-search/EditSavedSearchModal";
import MetalTrackingSearchBarState from "./MetalTrackingSearchBarState";
import MetalTrackingSavedSearchState from "./MetalTrackingSavedSearchState";
import SearchBarContainer from "../mt-search/MetalTrackingSearchBarContainer";
import FilterSectionContainer from "../mt-search/MetalTrackingFilterSectionContainer";

const mapStateToProps = state => ({
  searchFilters: MetalTrackingSearchBarState.selectors.getSearchFilters(state),
  searchCategory: MetalTrackingSearchBarState.selectors.getSearchCategory(
    state
  ),
  searchText: MetalTrackingSearchBarState.selectors.getSearchText(state),
  SearchBarContainer: SearchBarContainer,
  FilterSectionContainer: FilterSectionContainer
});

const mapDispatchToProps = dispatch => ({
  saveSearch: (name, search) =>
    dispatch(
      MetalTrackingSavedSearchState.actionCreators.saveSearch(name, search)
    ),
  editSearch: (id, name, search) =>
    dispatch(
      MetalTrackingSavedSearchState.actionCreators.editSearch(id, name, search)
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSavedSearchModal);
