/**
 * A basic search bar, without the advanced features of
 * global search bar (typeahead, search select, search button)
 **/
/**@jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { FormControl } from "react-bootstrap";

import Colors from "../../styles/colors";
import { FlexDiv } from "../../styles/container-elements";
import { FaSearch } from "react-icons/fa";

const inputGroupStyles = {
  position: "relative",
  border: "1px solid rgba(0, 0, 0, 0.1)",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.1)",
  borderRadius: ".25em",
  display: "flex",
  flex: 1,
  flexDirection: "row"
};

const iconStyles = {
  color: Colors.background.DARK_BLUE,
  left: "0.8em",
  position: "absolute",
  top: "0.85em",
  zIndex: 10
};

const formControlStyles = {
  backgroundColor: "white",
  borderColor: "white",
  borderRadius: "0.25em",
  flex: 1,
  height: "2.8em",
  paddingLeft: "2.4em",
  zIndex: 5
};

const BasicSearchBar = ({ placeholder, value, changeHandler, style }) => {
  return (
    <FlexDiv style={{ flex: 1, ...style }}>
      <div css={inputGroupStyles}>
        <FaSearch css={iconStyles} />
        <FormControl
          type="text"
          placeholder={placeholder}
          css={formControlStyles}
          onChange={e => changeHandler(e.target.value)}
          value={value}
        />
      </div>
    </FlexDiv>
  );
};

BasicSearchBar.propTypes = {
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.any
};

export default BasicSearchBar;
