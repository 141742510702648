/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component, useState, useEffect, forwardRef, createRef } from "react";
import ReactDOM from "react-dom";
import styled from "@emotion/styled";
import Overlay from "react-bootstrap/Overlay";
import AsyncPaginate from "react-select-async-paginate";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import "react-widgets/dist/css/react-widgets.css";
import moment from "moment-timezone";
import _ from "lodash";
import { MdClose } from "react-icons/md";

import Colors from "../../styles/colors";
import BatchFilterModal from "../../components/search-bar/BatchFilterModal";
import { FILTER_SECTION_CONTAINER_CLASS_NAME } from "./filters";
import SelectField from "../forms/fields/SelectField";

const MAX_FILTER_LABEL_LENGTH = 20;

const ButtonWrapper = styled.div(
  {
    display: "flex",
    overflow: "hidden",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "1em",
    height: "2em",
    marginTop: "0.5em",
    marginBottom: "0.5em",
    marginRight: "0.5em",
    border: `1px solid ${Colors.background.DARK_BLUE}`,
    whiteSpace: "nowrap"
  },
  ({ toggled = false, hasValue = false }) => ({
    backgroundColor: hasValue
      ? "white"
      : toggled
      ? Colors.background.GRAY
      : Colors.background.LIGHT_GRAY,
    color: Colors.background.DARK_BLUE,
    ":hover": {
      cursor: "pointer",
      backgroundColor: Colors.background.GRAY
    }
  })
);

const FilterPopover = forwardRef(
  (
    {
      onToggle,
      filterKey,
      style,
      label,
      popoverPosProps = { left: 0, right: "auto" },
      children
    },
    popoverRef
  ) => {
    return (
      <div
        ref={popoverRef}
        css={{
          ...style,
          zIndex: 100,
          position: "absolute",
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.1)",
          borderRadius: 3,
          backgroundColor: Colors.background.LIGHT_GRAY,
          marginTop: "0.75em",
          ...popoverPosProps
        }}
      >
        <div
          css={{
            display: "flex",
            color: "white",
            backgroundColor: Colors.background.DARK_GRAY,
            borderTopLeftRadius: 3,
            borderTopRightRadius: 3,
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.5em",
            fontSize: "1.2em",
            fontWeight: "300"
          }}
          data-qa={`header-popover-filter-button-${filterKey}`}
        >
          {label}
          <div
            css={{ ":hover": { cursor: "pointer" } }}
            onClick={() => onToggle(filterKey)}
            data-qa={`button-close-filter-button-${filterKey}`}
          >
            <MdClose width="1.25em" height="1.25em" />
          </div>
        </div>
        <div
          css={{ margin: "1em" }}
          data-qa={`content-popover-filter-button-${filterKey}`}
        >
          {children}
        </div>
      </div>
    );
  }
);

class FilterButton extends Component {
  render() {
    const {
      filterKey,
      filterValue,
      label,
      toggled,
      onToggle,
      clearSearchFilter
    } = this.props;

    // Truncate filter value if it's long
    let filterLabel = filterValue;
    if (filterValue && filterValue.length > MAX_FILTER_LABEL_LENGTH) {
      filterLabel = `${filterLabel.slice(0, MAX_FILTER_LABEL_LENGTH)}…`;
    }

    // H2-1075: make sure filter popover is correctly placed as user makes selections
    const popoverRef = createRef();
    const updateFilterPopoverPos = () => {
      const filterContainerNode = document.getElementsByClassName(
        FILTER_SECTION_CONTAINER_CLASS_NAME
      )[0];

      if (filterContainerNode) {
        const containerBounds = filterContainerNode.getBoundingClientRect();
        const halfwayX = containerBounds.x + containerBounds.width / 2;

        // is this popover's filter widget currently right or left of center?
        if (popoverRef && popoverRef.current) {
          const popoverNode = popoverRef.current;
          const popoverBounds = popoverNode.getBoundingClientRect();
          if (popoverBounds.x > halfwayX) {
            popoverNode.style.left = "auto";
            popoverNode.style.right = "0";
          } else {
            popoverNode.style.left = "0";
            popoverNode.style.right = "auto";
          }
        }
      }
    };

    return (
      <div css={{ position: "relative" }}>
        <div
          css={{ position: "relative" }}
          ref={d => {
            this.container = d;
          }}
        >
          <ButtonWrapper
            ref={button => {
              this.target = button;
            }}
            toggled={toggled}
            hasValue={filterValue != null}
            onClick={() => onToggle(filterKey)}
            data-qa={`filter-button-${filterKey}`}
          >
            <div css={{ marginLeft: "0.5em", marginRight: "0.5em" }}>
              {label}
            </div>
            {filterValue === null && (
              <div css={{ marginLeft: "0.5em", marginRight: "0.5em" }}>
                {"▾"}
              </div>
            )}
            {filterValue !== null && (
              <div
                css={{
                  paddingLeft: "0.5em",
                  paddingRight: "0.5em",
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                  backgroundColor: Colors.background.DARK_GRAY,
                  color: "white"
                }}
              >
                {filterLabel}
                <div
                  onClick={e => {
                    clearSearchFilter(filterKey);
                    e.stopPropagation();
                    onToggle(); // remove popover
                  }}
                  css={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                    color: Colors.background.DARK_BLUE,
                    marginLeft: "5px",
                    borderRadius: "1em",
                    width: "1em",
                    height: "1em",
                    ":hover": {
                      backgroundColor: Colors.highlight.RED
                    }
                  }}
                >
                  <MdClose data-qa={`button-close-${filterKey}`} />
                </div>
              </div>
            )}
          </ButtonWrapper>
        </div>
        <Overlay
          show={toggled}
          placement="bottom"
          container={() => ReactDOM.findDOMNode(this.container)}
          target={() => {
            updateFilterPopoverPos();
            return ReactDOM.findDOMNode(this.target);
          }}
          animation={false}
          rootClose={true}
          onHide={onToggle}
        >
          <FilterPopover ref={popoverRef} {...this.props}>
            {this.props.children}
          </FilterPopover>
        </Overlay>
      </div>
    );
  }
}

const SelectFilterButton = props => {
  const {
    filterKey,
    options,
    searchFilters,
    setSearchFilter,
    clearSearchFilter
  } = props;
  const filterValue = searchFilters[filterKey]
    ? options.find(o => o.value === searchFilters[filterKey]).label
    : null;

  return (
    <FilterButton
      {...props}
      filterValue={filterValue}
      clearSearchFilter={clearSearchFilter}
    >
      <SelectField
        options={options}
        name="select-filter"
        stateValue={searchFilters[filterKey]}
        onChange={v => setSearchFilter(filterKey, v.value)}
      />
    </FilterButton>
  );
};

// DEV-1070
// We're using dummy ids for the shipment_events filters to group multiple status ids.
// The grouped status ids are stored in the groupsIds attribute of each filter option.
// Since the status ids in the groupsIds list of each entry won't match the
// actual id of the filter option, we need to do some translation to make the select
// control display the group's label. Also we have need to make sure we're adding each
// grouping filter just once.
const getFilterValues = (selectedFilters, options) => {
  let selFilters = Array.isArray(selectedFilters)
    ? selectedFilters
    : [selectedFilters];
  let filterValues = [];

  let seenValues = new Set();
  selFilters.forEach(val => {
    const option = options.find(
      opt => opt.groupsIds && opt.groupsIds.includes(val)
    );
    if (option) {
      // Check if we've already added this filter value.
      if (!seenValues.has(option.value)) {
        filterValues.push({ value: option.value, label: option.label });
        seenValues.add(option.value);
      }
    }
    // Couldn't find an option in the dropdown containing the value in the
    // groupsIds arrays, return the actual id of the option.
    else {
      const option = options.find(opt => opt.value === val);
      if (option) {
        filterValues.push({ value: option.value, label: option.label });
      }
    }
  });

  return filterValues;
};

// H1-257: Filters should always display the values they were saved with, regardless
// of whether they exist in the current organization.
const addMissingOptions = (selectedFilters, options) => {
  let selFilters = Array.isArray(selectedFilters)
    ? selectedFilters
    : [selectedFilters];
  const missing = selFilters.filter(
    sf =>
      !Boolean(
        options.find(opt => {
          if (!opt.groupsIds) {
            return opt.val === sf;
          } else {
            // H1-1555: Need to ignore filters that are part of groups
            return (
              !Boolean(opt.groupsIds.find(optGroup => optGroup === sf)) ||
              opt.val === sf
            );
          }
        })
      )
  );

  return options.concat(
    missing.map(mf => {
      return { label: mf, value: mf };
    })
  );
};

const MultiSelectFilterButton = props => {
  const {
    filterKey,
    options,
    searchFilters,
    setSearchFilter,
    clearSearchFilter
  } = props;

  // DEV-1070 Get the filter names for the selected options in the dropdown.
  const filterValues =
    searchFilters && searchFilters[filterKey]
      ? getFilterValues(searchFilters[filterKey], options)
      : [];

  const numFilters = filterValues.length;

  let filterValue = null;

  if (numFilters > 0) {
    const firstFilterValue = searchFilters[filterKey][0];
    let foundFilter = options.find(
      o =>
        o.value === firstFilterValue ||
        (o.groupsIds && o.groupsIds.includes(firstFilterValue))
    ); // DEV-1070

    // Label the filter with an actual value in the options.
    // Or, if no option found, with the filter value
    filterValue = foundFilter ? foundFilter.label : firstFilterValue;
  }
  if (numFilters > 1) {
    if (filterValue && filterValue.length > MAX_FILTER_LABEL_LENGTH - 8) {
      filterValue = `${filterValue.slice(0, MAX_FILTER_LABEL_LENGTH - 8)}…`;
    }

    filterValue = filterValue + ` + ${numFilters - 1}`;
  }

  // H1-257: Push into options all the filter values that are not already there.
  let completeOptions =
    searchFilters && searchFilters[filterKey]
      ? addMissingOptions(searchFilters[filterKey], options)
      : options;

  return (
    <FilterButton
      {...props}
      filterValue={filterValue}
      clearSearchFilter={clearSearchFilter}
    >
      <SelectField
        options={completeOptions}
        isMulti={true}
        name="select-filter"
        stateValue={filterValues}
        onChange={v => {
          let values = [];
          if (v) {
            values = v
              .map(o => {
                return o.groupsIds ? o.groupsIds : o.value; // DEV-1070
              })
              .flat();
          }
          setSearchFilter(filterKey, values);
        }}
      />
    </FilterButton>
  );
};

const AsyncMultiSelectFilterButton = props => {
  const {
    filterKey,
    clearSearchFilter,
    values,
    loadOptions,
    setSearchFilter,
    filterOption,
    filterOptions
  } = props;

  const numFilters = values ? values.length : 0;
  let filterValue = null;

  if (numFilters > 0) {
    const firstFilterValue = Object.assign({}, values[0]);
    filterValue = firstFilterValue.label;
  }
  if (numFilters > 1) {
    if (filterValue && filterValue.length > MAX_FILTER_LABEL_LENGTH - 8) {
      filterValue = `${filterValue.slice(0, MAX_FILTER_LABEL_LENGTH - 8)}…`;
    }

    filterValue = filterValue + ` + ${numFilters - 1}`;
  }

  return (
    <FilterButton
      {...props}
      filterValue={filterValue}
      clearSearchFilter={() => {
        setSearchFilter(filterKey, []);
        clearSearchFilter();
      }}
    >
      <AsyncPaginate
        name="async-select-filter"
        css={{ minWidth: "17em", color: "#333" }}
        filterOption={filterOption}
        filterOptions={filterOptions}
        isMulti={true}
        cacheOptions={false}
        value={values}
        loadOptions={loadOptions}
        onChange={v => {
          setSearchFilter(filterKey, v);
        }}
      />
    </FilterButton>
  );
};

// DEV-767 Add user timezone to time display
const zone = moment.tz.guess();

const DateRangeFilterButton = props => {
  const {
    filterKey,
    searchFilters,
    setSearchFilter,
    clearSearchFilter
  } = props;
  const currentFilter = _.get(searchFilters, filterKey);
  const fromValue = currentFilter ? currentFilter.from : null;
  const toValue = currentFilter ? currentFilter.to : null;
  const fromValueDate = fromValue ? new Date(fromValue) : null;
  const toValueDate = toValue ? new Date(toValue) : null;

  let filterLabel = null;
  if (fromValue && !toValue) {
    filterLabel = (
      <div>
        <strong> From: {moment(fromValue).format("l")} </strong>
        {moment(fromValue).format(" HH:mm ")}
        {moment()
          .tz(zone)
          .format("z")}
      </div>
    );
  }

  if (toValue && !fromValue) {
    filterLabel = (
      <div>
        <strong> To: {moment(toValue).format("l")} </strong>
        {moment(toValue).format(" HH:mm ")}
        {moment()
          .tz(zone)
          .format("z")}
      </div>
    );
  }

  if (toValue && fromValue) {
    filterLabel = (
      <div>
        <strong> {moment(fromValue).format("l")} </strong>
        {moment(fromValue).format(" HH:mm ")}
        {moment()
          .tz(zone)
          .format("z ")}
        - <strong> {moment(toValue).format("l")} </strong>
        {moment(toValue).format(" HH:mm ")}
        {moment()
          .tz(zone)
          .format("z")}
      </div>
    );
  }

  return (
    <FilterButton
      {...props}
      clearSearchFilter={clearSearchFilter}
      filterValue={filterLabel}
    >
      <div css={{ marginBottom: "1em" }}>
        <strong className="timeLabel">From:</strong>
        <DateTimePicker
          style={{ minWidth: "17em" }}
          defaultValue={fromValueDate}
          // H1-504: Pick up date and delivery date filters time should default to 0:00.
          defaultCurrentDate={moment()
            .startOf("day")
            .toDate()}
          onChange={date =>
            setSearchFilter(filterKey, { from: date, to: toValue })
          }
        />
      </div>
      <div>
        <strong className="timeLabel">To:</strong>
        <DateTimePicker
          style={{ minWidth: "17em" }}
          defaultValue={toValueDate}
          // H1-504: Pick up date and delivery date filters time should default to 0:00.
          defaultCurrentDate={moment()
            .startOf("day")
            .toDate()}
          onChange={date =>
            setSearchFilter(filterKey, { from: fromValue, to: date })
          }
        />
      </div>
    </FilterButton>
  );
};

// Button to toggle Batch Search filter modal (allows user to upload CSV or enter line-separated filter values)
const BatchFilterButton = props => {
  const {
    filterKey,
    label,
    options,
    values,
    setSearchFilter,
    clearSearchFilter,
    isFilterContainerVisible
  } = props;

  const [showBatchModal, setShowBatchModal] = useState(false);
  useEffect(() => {
    setShowBatchModal(showBatchModal);
  }, [showBatchModal]);

  return (
    <div>
      <div
        css={{
          display: isFilterContainerVisible ? "flex" : "none",
          alignItems: "center",
          height: "2em",
          border: values ? "1px solid " + Colors.background.DARK_GRAY : "",
          borderRadius: "1em",
          backgroundColor: values ? Colors.background.DARK_GRAY : "transparent",
          margin: "0.5em",
          padding: "0 0.5em",
          position: "absolute",
          bottom: 0,
          right: 0
        }}
      >
        <div
          onClick={() => setShowBatchModal(!showBatchModal)}
          className="batch-search-button"
          css={{
            fontSize: 12.5,
            fontStyle: "italic",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            color: values ? "white" : Colors.background.DARK_GRAY,
            textDecoration: values ? "none" : "underline"
          }}
          data-qa="button-batch-search"
        >
          {label}
        </div>
        {values ? (
          <div
            onClick={e => {
              clearSearchFilter(filterKey);
            }}
            css={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "white",
              color: Colors.background.DARK_GRAY,
              marginLeft: "5px",
              borderRadius: "1em",
              width: "1em",
              height: "1em",
              ":hover": {
                backgroundColor: Colors.highlight.RED
              }
            }}
          >
            <MdClose />
          </div>
        ) : null}
      </div>
      <BatchFilterModal
        show={showBatchModal}
        hide={() => setShowBatchModal(false)}
        searchTypeOptions={options}
        onSubmit={data => setSearchFilter(filterKey, data)}
      />
    </div>
  );
};

// Filter control that displays a single-line text input when clicked
const TextFilterButton = props => {
  const {
    filterKey,
    searchFilters,
    setSearchFilter,
    clearSearchFilter
  } = props;
  return (
    <FilterButton
      {...props}
      filterValue={searchFilters[filterKey] || null}
      clearSearchFilter={clearSearchFilter}
    >
      <input
        type="text"
        className="form-control"
        style={{ minWidth: "17em" }}
        name="text-filter"
        value={searchFilters[filterKey] || ""}
        onChange={event => setSearchFilter(filterKey, event.target.value)}
      />
    </FilterButton>
  );
};

export {
  SelectFilterButton,
  MultiSelectFilterButton,
  AsyncMultiSelectFilterButton,
  DateRangeFilterButton,
  BatchFilterButton,
  TextFilterButton
};
