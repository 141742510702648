/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import ShipmentInfoBox from "./ShipmentInfoBox";
import { BasicInfoBox } from "../../../components/multimodal-components/InfoBoxes";
import { convertLineBreaksToHtml } from "../../../utils/html-utils";

const TimeDateBox = ({
  title,
  styles,
  time,
  date,
  titleStyles,
  timeStyles,
  timezoneStyles,
  innerWrapperStyles = { marginBottom: 10 },
  "data-qa": dataQa
}) => {
  let timeOnly, timezoneOnly;
  if (time) {
    let timeSplit = time.split(" ");
    if (timeSplit.length === 2) {
      timeOnly = timeSplit[0];
      timezoneOnly = timeSplit[1];
    } else {
      timeOnly = time;
    }
  }

  return (
    <ShipmentInfoBox
      title={title}
      styles={{
        width: "unset",
        padding: 0,
        ...styles
      }}
      titleStyles={titleStyles}
      data-qa={dataQa}
    >
      <div css={innerWrapperStyles}>
        {timeOnly && (
          <BasicInfoBox
            style={{
              fontWeight: "normal",
              fontSize: "0.9em",
              whiteSpace: "nowrap",
              ...timeStyles
            }}
            data-qa="text-time"
          >
            {convertLineBreaksToHtml(timeOnly)}
            <span
              style={{ marginLeft: 3, fontSize: "0.8em", ...timezoneStyles }}
            >
              {convertLineBreaksToHtml(timezoneOnly)}
            </span>
          </BasicInfoBox>
        )}
        {date && (
          <BasicInfoBox
            style={{ fontWeight: "normal", fontSize: "0.8em" }}
            data-qa="text-date"
          >
            {convertLineBreaksToHtml(date)}
          </BasicInfoBox>
        )}
      </div>
    </ShipmentInfoBox>
  );
};

export default TimeDateBox;
