/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import {
  VictoryChart,
  VictoryScatter,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLabel
} from "victory";
import Colors from "../../styles/colors";

const LineChart = props => {
  const { data, dataClick } = props;

  let maxY = 4;
  if (data && data.length > 0) {
    maxY = Math.max(...data.map(d => d.y));
  }
  if (maxY === 0) {
    // Set a default max so we don't get weird numbers for the Y column
    maxY = 4;
  }

  return (
    <VictoryChart
      padding={{ bottom: 25, left: 30, top: 30, right: 20 }}
      minDomain={{ y: 0 }}
      containerComponent={<VictoryVoronoiContainer />}
    >
      <VictoryAxis
        dependentAxis={true}
        tickFormat={t => parseInt(t)}
        domain={{ y: [0, maxY] }}
        style={{
          axis: { stroke: "none" },
          grid: { stroke: Colors.border.CHART_AXIS_BORDER },
          tickLabels: { fontSize: 13 }
        }}
      />
      <VictoryAxis
        style={{
          tickLabels: { fontSize: 13 },
          axis: { stroke: Colors.border.CHART_AXIS_BORDER }
        }}
      />
      <VictoryLine
        data={data}
        style={{
          data: {
            stroke: Colors.highlight.BRIGHT_BLUE
          }
        }}
      />
      <VictoryScatter
        style={{
          data: { fill: Colors.highlight.BRIGHT_BLUE, cursor: "pointer" }
        }}
        size={({ active }) => (active ? 7 : 5)}
        data={data}
        labels={({ datum }) => datum.tooltip || ""}
        labelComponent={
          <VictoryTooltip
            flyoutStyle={{
              stroke: "none",
              fill: Colors.background.DARK_GRAY
            }}
            labelComponent={<VictoryLabel style={{ fill: "white" }} />}
          />
        }
        events={[
          {
            target: "data",
            eventHandlers: {
              onClick: dataClick
            }
          }
        ]}
      />
    </VictoryChart>
  );
};

LineChart.propTypes = {
  data: PropTypes.array,
  dataClick: PropTypes.func
};

export default LineChart;
