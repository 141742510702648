import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DocumentationState from "./DocumentationState";
import TryApiView from "./TryApiView";

function mapStateToProps(state) {
  // const { getTryApi } = DocumentationState.selectors;
  const {
    getApiSample,
    getApiGroup,
    getTryResponse,
    getTryStatus
  } = DocumentationState.selectors;

  return {
    // content: getTryApi(state),
    content: {
      title: "Try an Api",
      Description: ""
    },
    apiSample: getApiSample(state),
    apiGroup: getApiGroup(state),
    isLoading: getTryStatus(state),
    tryResponse: getTryResponse(state)
  };
}

function mapDispatchToProps(dispatch) {
  // const { fetchTryApi } = DocumentationState.actionCreators;
  const {
    callTryApi,
    fetchApiGroup,
    fetchApiSample
  } = DocumentationState.actionCreators;

  return bindActionCreators(
    {
      callTryApi,
      // fetchTryApi,
      fetchApiSample,
      fetchApiGroup
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TryApiView);
