/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { IoMdCheckmarkCircle } from "react-icons/io";
import _ from "lodash";
import { NumberBubble } from "../../../components/tabs-container-panel";
import Colors from "../../../styles/colors";
import {
  localizedDateFormatter,
  localizedTimeFormatter,
  localizedTimestampDeltaFormatter
} from "../../../utils/date-time";
import { sortExceptions } from "../vin-details-utils";
import CountryName from "../../location/components/CountryName";
import { useTranslation } from "react-i18next";

const boxHeight = 115;

const HeaderText = styled.div({
  fontSize: "1em"
});

const BodyText1 = styled.div({
  color: Colors.holds.DARK_BLUE,
  fontSize: 15,
  fontWeight: 600,
  margin: "15px 0px"
});

export const InfoBox = ({
  children,
  style,
  headerText,
  bodyText,
  "data-qa": dataQa
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      paddingRight: 10,
      alignItems: "center",
      ...style
    }}
    data-qa={dataQa}
  >
    <HeaderText>{headerText}</HeaderText>
    {bodyText && <BodyText1>{bodyText}</BodyText1>}
    {children}
  </div>
);

export const VinBox = ({ vinNumber, references }) => {
  const { t } = useTranslation("fv-vin-details");
  let soldStatusObj = references
    ? _.find(references, ["qualifier", "SoldStatus"])
    : undefined;

  let col = "#aba9a9";
  if (soldStatusObj && soldStatusObj.value === "Stock VIN") {
    col = Colors.holds.DARK_BLUE;
  }
  if (soldStatusObj && soldStatusObj.value === "VIN Sold") {
    col = Colors.highlight.GREEN;
  }

  return (
    <InfoBox style={{ width: "50%" }} headerText="VIN" bodyText={vinNumber}>
      <span
        style={{
          fontSize: 12,
          fontWeight: 600,
          color: col,
          display: "flex"
        }}
      >
        <IoMdCheckmarkCircle style={{ marginRight: 10 }} size="1.5em" />
        {`${
          soldStatusObj
            ? soldStatusObj.value
            : t("fv-vin-details:Sold Status - Not Known")
        }`}
      </span>
    </InfoBox>
  );
};

const dateSubtitleStyle = {
  color: Colors.text.GRAY,
  fontWeight: 400,
  paddingBottom: 5,
  fontSize: 13
};

const currentLocationLabel = (currentLocation, t) => {
  if (!currentLocation) {
    return t("fv-vin-details:No location data");
  }

  let label = "";
  if (currentLocation.city) {
    label = currentLocation.city;
  }
  if (currentLocation.state) {
    label = label
      ? `${label}, ${currentLocation.state}`
      : currentLocation.state;
  }

  return label;
};

function getCarrierScac(actualLegs, creatorShipmentId) {
  if (
    !actualLegs ||
    !actualLegs.tripLegs ||
    actualLegs.tripLegs.length < 0 ||
    !creatorShipmentId
  ) {
    return null;
  }

  let leg = actualLegs.tripLegs.find(l => l.id === creatorShipmentId);

  return leg && leg.carrierInfo && leg.carrierInfo.carrierScac
    ? leg.carrierInfo.carrierScac
    : null;
}

export const LocationBox = ({
  currentLocation,
  creatorShipmentId,
  pushShipmentDetailView,
  vinNumber,
  actualLegs
}) => {
  const { t } = useTranslation("fv-vin-details");
  let scac = getCarrierScac(actualLegs, creatorShipmentId);

  return (
    <InfoBox
      style={{ width: "50%", paddingBottom: 20 }}
      headerText={t("fv-vin-details:Current Location")}
    >
      <BodyText1>
        <span>{currentLocationLabel(currentLocation, t)}</span>
        {currentLocation && currentLocation.country ? (
          <CountryName
            countryCode={currentLocation.country}
            style={{ display: "block" }}
          />
        ) : null}
      </BodyText1>
      {currentLocation && currentLocation.time ? (
        <span style={{ ...dateSubtitleStyle, marginTop: -10 }}>
          {`${localizedDateFormatter(
            currentLocation.time
          )} ${localizedTimeFormatter(currentLocation.time)}`}
        </span>
      ) : null}
      {currentLocation && creatorShipmentId && vinNumber ? (
        <button
          style={{
            fontStyle: "italic",
            fontSize: 12,
            color: Colors.holds.LIGHT_BLUE,
            textDecoration: "underline",
            border: "none",
            textAlign: "left",
            paddingLeft: 0,
            background: "none",
            outline: "inherit"
          }}
          onClick={() =>
            creatorShipmentId
              ? pushShipmentDetailView(creatorShipmentId, scac, vinNumber)
              : null
          }
        >
          {t("fv-vin-details:View Current Shipment")}
        </button>
      ) : null}
    </InfoBox>
  );
};

export const VinInfoContainer = ({
  details,
  currentLocation,
  pushShipmentDetailView,
  actualLegs,
  ...props
}) => {
  const creatorShipmentId = (details && details.activeTripLegId) || null;
  const vinNumber = details && details.id;

  return (
    <div
      css={{
        minHeight: boxHeight,
        margin: "20px 0px",
        flexGrow: 1,
        flexBasis: 0
      }}
    >
      <div
        css={{
          borderBottom: "1px solid #e9e9e9",
          height: "100%",
          display: "flex",
          marginRight: 10
        }}
      >
        <VinBox
          vinNumber={vinNumber}
          references={details && details.references}
        />
        <LocationBox
          lastMovement={details && details.lastMovement}
          currentLocation={currentLocation}
          creatorShipmentId={creatorShipmentId}
          pushShipmentDetailView={pushShipmentDetailView}
          vinNumber={vinNumber}
          actualLegs={actualLegs}
        />
      </div>
    </div>
  );
};

const ExceptionText = ({ count, text, t }) => (
  <div
    css={{
      display: "flex",
      alignItems: "center",
      marginTop: 10
    }}
  >
    <NumberBubble
      style={{
        background: "white",
        color: count === 0 ? "green" : Colors.holds.DARK_RED,
        marginRight: 10,
        fontSize: 12,
        marginBottom: 0,
        marginTop: 0,
        padding: "2px"
      }}
    >
      {count}
    </NumberBubble>
    <BodyText1
      style={{
        // textDecoration: "underline",
        // cursor: "pointer",
        fontSize: "1.25em",
        margin: "0 0",
        color: "white"
      }}
    >
      {t(`exceptions:${text}`)}
    </BodyText1>
  </div>
);

export const ExceptionsBox = props => {
  const { t } = useTranslation("fv-vin-details");
  const { holds, exceptions, style = {} } = props;
  const holdsLength = holds ? holds.length : 0;
  const exceptionsLength = exceptions ? exceptions.length : 0;
  const borderColor =
    holdsLength === 0 && exceptionsLength === 0
      ? Colors.highlight.GREEN
      : Colors.highlight.RED;
  const exceptionCounts = buildExceptionCounts(holds, exceptions);

  return (
    <InfoBox
      style={{
        minHeight: boxHeight,
        background: borderColor,
        padding: ".7em .5em",
        borderRadius: 4,
        color: "white",
        ...style
      }}
      headerText={t("fv-vin-details:Active Exceptions")}
      data-qa="text-active-exceptions"
    >
      <div
        css={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        {exceptionCounts.length > 0 ? (
          exceptionCounts.map(e => (
            <ExceptionText text={e.text} count={e.count} key={e.text} t={t} />
          ))
        ) : (
          <span
            css={{
              marginTop: "0.5em",
              fontSize: "1.25em",
              textAlign: "center"
            }}
          >
            {t("fv-vin-details:No Active Exceptions")}
          </span>
        )}
      </div>
    </InfoBox>
  );
};

ExceptionsBox.propTypes = {
  holds: PropTypes.array.isRequired,
  exceptions: PropTypes.array.isRequired
};

const buildExceptionCounts = (holds, exceptions) => {
  const excludedTypes = ["In Hold", "On Hold"];
  const filteredExceptions = exceptions.filter(
    exception => !excludedTypes.includes(exception.typeName)
  );
  const groupedExceptions = _.groupBy(filteredExceptions, "typeName");
  const exceptionPairs = Object.keys(groupedExceptions).map(typeName => {
    return {
      text: typeName,
      count: groupedExceptions[typeName].length
    };
  });

  if (holds && holds.length > 0) {
    exceptionPairs.push({ text: "On Hold", count: holds.length });
  }

  return sortExceptions(exceptionPairs, "text");
};

const infoStyle = {
  // width: "50%",
  padding: "5px 10px",
  margin: "10px 0px"
};

export const DeliveryInfoContainer = props => {
  const { t } = useTranslation("fv-vin-details");
  const { deliveryInfo, estimatedDelta } = props;
  const estimatedDelivery =
    deliveryInfo && deliveryInfo.estimated && !_.isNil(estimatedDelta)
      ? localizedTimestampDeltaFormatter(deliveryInfo.estimated, estimatedDelta)
      : "";
  const scheduledDelivery = deliveryInfo
    ? formatTimestamp(deliveryInfo.scheduled)
    : "";

  return (
    <div
      css={{
        display: "flex",
        width: "30%",
        minHeight: boxHeight,
        margin: "20px 0px",
        borderRadius: 4,
        border: "1px solid #e9e9e9"
      }}
    >
      <InfoBox
        style={{
          ...infoStyle,
          borderRight: "1px solid #e9e9e9",
          width: "calc(50% + 1px)"
        }}
        headerText={t("fv-vin-details:Estimated Delivery")}
        bodyText={estimatedDelivery}
      />
      <InfoBox
        style={{ ...infoStyle, width: "50%" }}
        headerText={t("fv-vin-details:Scheduled Delivery")}
        bodyText={scheduledDelivery}
      />
    </div>
  );
};

const formatTimestamp = timestamp => {
  if (timestamp) {
    return `${localizedDateFormatter(timestamp)} ${localizedTimeFormatter(
      timestamp
    )}`;
  }

  return "";
};
