/*
 * Utils functions to be used in react tables
 */

/**
 * Just scroll back to the top of the table. Very useful to be used when
 * pagination changes.
 * NOTE: this function unfortunatelly needs to manipulate DOM.
 **/
export const scrollToTableTop = (containerClass = "main-panel") => {
  document.getElementsByClassName(
    containerClass
  )[0].scrollTop = document.getElementsByClassName("ReactTable")[0].offsetTop;
};
