import _ from "lodash";

import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import { combineReducers } from "redux";
import apiUrl from "../../api-url";
import { alfBy } from "../../components/selectors";

const STORE_MOUNT_POINT = "fvDomainData";
const finVehicleDomainDuck = buildFetchDuck("finVehicleDomainDuck");
const finVehicleOriginDuck = buildFetchDuck("finVehicleOriginDuck");
const finVehicleDestinationDuck = buildFetchDuck("finVehicleDestinationDuck");
const finVehicleProductTypeDuck = buildFetchDuck("finVehicleProductTypeDuck");

export function fetchDomainData(solutionId) {
  const fieldsToGetFromEndpoint = [
    "holdType",
    "exceptionType",
    "ref:SoldStatus",
    "carrier",
    // H1-1549: for some reason they use the parameter here as lifecycleState
    // but return this as lifeCycleState, with a capital c, so, this is the only
    // place where whe have this written in this way
    "lifecycleState",
    "ref:OrderType",
    "partner"
  ];
  const queryString = fieldsToGetFromEndpoint
    .map(item => `${item}=1`)
    .join("&");
  const url1 = apiUrl(
    `/entity-search/solution/${solutionId}/list?${queryString}`
  );

  // H2-1066: Separate calls for the origin, destination, and productType
  // to help with load times.
  const url2 = apiUrl(`/entity-search/solution/${solutionId}/list?origin=1`);
  const url3 = apiUrl(
    `/entity-search/solution/${solutionId}/list?destination=1`
  );
  const url4 = apiUrl(
    `/entity-search/solution/${solutionId}/list?productType=1`
  );

  return dispatch => {
    dispatch(finVehicleDomainDuck.fetch(url1));
    dispatch(finVehicleOriginDuck.fetch(url2));
    dispatch(finVehicleDestinationDuck.fetch(url3));
    dispatch(finVehicleProductTypeDuck.fetch(url4));
  };
}

/**
 * Given a property name, this function knows how to get its related data from
 * the fv domain mount point in the store.
 *
 * @param string property: property you want to get from the state.
 * @param string sort: key you want to sort on the return.
 * @param boolean sort: if there is no key, a true value will mean: sort it for
 * me.
 *
 * It avoids code duplication to create selectors based on the same data
 * source.
 */
const createSelectorFromProperty = (property, parent, sort = false) => {
  return state => {
    let dataFromState = state[STORE_MOUNT_POINT][parent].data[property];

    if (dataFromState) {
      if (sort) {
        // Sorting
        if (_.isString(sort)) {
          return _.sortBy(dataFromState, sort);
        }

        // Check if the data is prone to sort. In this case, the only possible
        // type which comes from the backend and is not sortable is object
        if (_.isObject(dataFromState[0])) {
          throw Error(
            `Property ${property} contains objects that can not be sorted`
          );
        }

        return dataFromState.sort();
      }
      return dataFromState;
    }
    return [];
  };
};

const getOrigins = createSelectorFromProperty("origins", "origins");
const selectOriginsAlphabetically = alfBy(getOrigins, "name");

const getDestinations = createSelectorFromProperty(
  "destinations",
  "destinations"
);
const selectDestinationsAlphabetically = alfBy(getDestinations, "name");

const getSoldStatuses = state => {
  // if (state.fvDomainData.data["ref:SoldStatus"]) {
  //   return state.fvDomainData.data["ref:SoldStatus"].sort();
  // }
  // return [];

  /* H2-332 Request to temp hardcode this selector */
  return ["Stock VIN", "VIN Sold"];
};

const getHoldTypes = createSelectorFromProperty("holdTypes", "domainData");
const selectHoldTypesAlphabetically = alfBy(getHoldTypes, "name");

const getExceptionTypes = createSelectorFromProperty(
  "exceptionTypes",
  "domainData"
);
const selectExceptionTypesAlphabetically = alfBy(getExceptionTypes, "name");

// H2-366: Remove VINs from typeahead
const getVINs = state => [];

const getShipments = state => [];

const getProductTypes = createSelectorFromProperty(
  "productTypes",
  "productTypes"
);

const getLifeCycleStates = createSelectorFromProperty(
  "lifecycleState",
  "domainData"
);

const getCarriers = createSelectorFromProperty(
  "carriers",
  "domainData",
  "carrier_name"
);

const getOrderTypes = createSelectorFromProperty(
  "ref:OrderType",
  "domainData",
  true
);

const getPartners = createSelectorFromProperty(
  "partners",
  "domainData",
  "org_name"
);

const getDomainDataLoading = state =>
  state[STORE_MOUNT_POINT].domainData.isLoading || false;

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData
  },
  selectors: {
    getOrigins,
    selectOriginsAlphabetically,
    getDestinations,
    selectDestinationsAlphabetically,
    getSoldStatuses,
    getHoldTypes,
    selectHoldTypesAlphabetically,
    getExceptionTypes,
    selectExceptionTypesAlphabetically,
    getProductTypes,
    getLifeCycleStates,
    getVINs,
    getShipments,
    getCarriers,
    getOrderTypes,
    getPartners,
    getDomainDataLoading
  },
  reducer: combineReducers({
    domainData: finVehicleDomainDuck.reducer,
    origins: finVehicleOriginDuck.reducer,
    destinations: finVehicleDestinationDuck.reducer,
    productTypes: finVehicleProductTypeDuck.reducer
  })
};
