import jwtDecode from "jwt-decode";
import PrivilegeAuthorization from "./PrivilegeAuthorization";
import FeatureAuthorization from "./FeatureAuthorization";
import OrganizationTypeAuthorization from "./OrganizationTypeAuthorization";
import AuthenticationUtils from "./authentication";

export const UserAuthorizationNamespace =
  "http://www.freightverify.com/user_authorization";

export const Privileges = {
  ANY: "ANY",
  CHANGE_ACTIVE_ORGANIZATION: "CHANGE_ACTIVE_ORGANIZATION",
  MANAGE_CARRIER_LOCATIONS: "MANAGE_CARRIER_LOCATIONS",
  MANAGE_LOCATIONS: "MANAGE_LOCATIONS",
  MANAGE_ORGANIZATIONS: "MANAGE_ORGANIZATIONS",
  MANAGE_SHIPMENTS: "MANAGE_SHIPMENTS",
  MANAGE_SHIPPER_LOCATIONS: "MANAGE_SHIPPER_LOCATIONS",
  MANAGE_USERS: "MANAGE_USERS",
  VIEW_LOCATIONS: "VIEW_LOCATIONS",
  VIEW_SHIPMENTS: "VIEW_SHIPMENTS",
  CHANGE_SHIPMENT_REVIEW_STATUS: "CHANGE_SHIPMENT_REVIEW_STATUS",
  CHANGE_SHIPMENT_EVENTS: "CHANGE_SHIPMENT_EVENTS",
  CANCEL_SHIPMENT: "CANCEL_SHIPMENT",
  VIEW_REPORTS: "VIEW_REPORTS",
  BUILD_REPORT: "BUILD_REPORT",
  MANAGE_ENTITY: "MANAGE_ENTITY",
  VIEW_ENTITY: "VIEW_ENTITY",
  ADC_DASHBOARD: "ADC_DASHBOARD",
  CHANGE_USER_PASSWORDS: "CHANGE_USER_PASSWORDS"
};

export const Roles = {
  CARRIER_SYSTEM: "CARRIER_SYSTEM",
  FREIGHTVERIFY_ADMIN: "FREIGHTVERIFY_ADMIN",
  ORGANIZATION_ADMIN: "ORGANIZATION_ADMIN",
  ORGANIZATION_USER: "ORGANIZATION_USER",
  THIRD_PARTY_SYSTEM: "THIRD_PARTY_SYSTEM",
  ORGANIZATION_REPORTING_USER: "ORGANIZATION_REPORTING_USER",
  FINISHED_VEHICLE_SYSTEM: "FINISHED_VEHICLE_SYSTEM",
  FINISHED_VEHICLE_USER: "FINISHED_VEHICLE_USER",
  FINISHED_VEHICLE_ADC: "FINISHED_VEHICLE_ADC",
  PLANT_ASSET_TRACKING_USER: "PLANT_ASSET_TRACKING_USER",
  REPORT_BUILDER_USER: "REPORT_BUILDER_USER"
};

export const Features = {
  FINISHED_VEHICLE: "Finished Vehicle",
  CONNECTED_CAR: "Connected Car",
  PLANT_ASSET_TRACKING: "Plant Asset Tracking",
  AUTO_DISTRIBUTION_CENTER: "Auto Distribution Center"
};

export const OrganizationTypes = {
  SHIPPER: "Shipper",
  CARRIER: "Carrier",
  PARTNER: "Partner",
  FREIGHTVERIFY: "FreightVerify"
};

export default class Authorization {
  constructor(
    currentUser = null, // currentUser object from AuthenticationState
    activeOrganization = null, // activeOrganization object from OrganizationsState
    federationData = null, // federationData object from OrganizationsState
    features = [] // featureData object from OrganizationsState
  ) {
    // Check the provided auth object for the silent flag - or fall back to session storage
    const isSilentAuthentication = AuthenticationUtils.getIsSilentAuthentication();

    // Use the provided federationData object - or fall back to session storage
    const authFederationData =
      federationData || sessionStorage.getItem("federationData");

    // TODO: Extract userAuth token logic to a separate class

    // Use the provided user's claims - or fall back to session storage
    let userAuth = currentUser
      ? currentUser[UserAuthorizationNamespace]
      : jwtDecode(sessionStorage.getItem("access_token"));

    // Check if federation data should be used instead of the user's claims
    if (isSilentAuthentication && authFederationData) {
      userAuth = authFederationData;
    }

    // Check if the user has multiple organization permissions
    const orgPermissions =
      userAuth.privileges_by_organization &&
      userAuth.privileges_by_organization.length
        ? userAuth.privileges_by_organization
        : [];

    // Check for user permissions for the active organization
    let userOrgPermissions = null;
    if (orgPermissions.length && activeOrganization) {
      userOrgPermissions = orgPermissions.find(
        p => p.organization_id === activeOrganization.organization_id
      );
    }

    // Create an array containng all the user's organization IDs
    this.organizationIds = [userAuth.organization_id];
    if (orgPermissions.length) {
      this.organizationIds = orgPermissions.map(p => p.organization_id);
    }

    // Use permission data to instantiate privilege checks
    this.privilegeAuthorization = new PrivilegeAuthorization(
      userAuth,
      userOrgPermissions
    );

    // Use permission and feature data to instantiate feature checks
    this.featureAuthorization = new FeatureAuthorization(
      userAuth,
      userOrgPermissions,
      features,
      this.isFvAdmin()
    );

    // Use the active organization to instantiate organization type checks
    this.organizationTypeAuthorization = new OrganizationTypeAuthorization(
      activeOrganization
    );
  }

  // Does the user have a privilege (or one of the privileges in an array)?
  hasPrivileges(names) {
    return this.privilegeAuthorization.hasAnyPrivilege(names);
  }

  // Does the user have a feature (or one of the features in an array)?
  hasFeatures(names) {
    return this.featureAuthorization.hasAnyFeature(names);
  }

  // Does the organization have a type (or one of the types in an array)?
  hasOrganizationTypes(name) {
    const orgTypes = Array.isArray(name) ? name : [name];
    return this.organizationTypeAuthorization.hasAnyOrganizationType(orgTypes);
  }

  // Is the user authorized based on any of the criteria provided?
  isAuthorized(
    allowedPrivileges = [],
    allowedFeatures = [],
    allowedOrganizationTypes = []
  ) {
    const arePrivilegesSpecified = allowedPrivileges.length > 0;
    const areFeaturesSpecified = allowedFeatures.length > 0;
    const areOrganizationTypesSpecified = allowedOrganizationTypes.length > 0;

    // If no requirements are specified, all users are authorized
    if (
      !arePrivilegesSpecified &&
      !areFeaturesSpecified &&
      !areOrganizationTypesSpecified
    ) {
      return true;
    }

    // If privileges are specified, check if the user has any of them
    const hasAnyPrivilege = arePrivilegesSpecified
      ? this.hasPrivileges(allowedPrivileges)
      : true;

    // If features are specified, check if the user has any of them
    const hasAnyFeature = areFeaturesSpecified
      ? this.hasFeatures(allowedFeatures)
      : true;

    // If organization types are specified, check if the organization has any of them
    const hasAnyOrganizationType = areOrganizationTypesSpecified
      ? this.hasOrganizationTypes(allowedOrganizationTypes)
      : true;

    // Override: for users who only have the Plant Asset Tracking feature, deny non-PAT routes
    const isPatOnlyUser = this.featureAuthorization.plantAssetTrackingOnly();

    if (isPatOnlyUser && areFeaturesSpecified) {
      const isPatRequired = allowedFeatures.includes(
        Features.PLANT_ASSET_TRACKING
      );
      if (!isPatRequired) {
        return false;
      }
    }

    let isAuthorized = false;

    // Only privileges are specified - only authorize users with matching privileges
    if (arePrivilegesSpecified && !areFeaturesSpecified) {
      isAuthorized = hasAnyPrivilege;
    }

    // Only features are specified - only authorize users with matching features
    if (!arePrivilegesSpecified && areFeaturesSpecified) {
      isAuthorized = hasAnyFeature;
    }

    // Both privileges and features are specified - only authorize users with either
    if (arePrivilegesSpecified && areFeaturesSpecified) {
      isAuthorized = hasAnyPrivilege && hasAnyFeature;
    }

    // Organization type is specified - if the user is authorized, check the org type
    if (isAuthorized && areOrganizationTypesSpecified) {
      isAuthorized = hasAnyOrganizationType;
    }

    return isAuthorized;
  }

  // Is the user a FreightVerify admin? (common privilege check)
  isFvAdmin() {
    return this.privilegeAuthorization.isFvAdmin();
  }
}
