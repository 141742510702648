import { connect } from "react-redux";
import { SearchBar } from "./ShipmentStopsSearchBar";
import SearchBarState from "./ShipmentStopsSearchBarState";

const mapStateToProps = state => {
  const {
    getSearchText,
    getSearchCategory,
    getSearchResults
  } = SearchBarState.selectors;

  return {
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    shipperLocations: getSearchResults(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    searchLocations
  } = SearchBarState.actionCreators;
  return {
    setSearchCategory: key => dispatch(setSearchCategoryForKey(key)),
    setSearchText: (text, ind) => dispatch(setSearchText(text, ind)),
    clearSearchText: ind => dispatch(clearSearchText(ind)),
    resetSearchBar: ind => dispatch(resetSearchBar(ind)),
    searchLocations: (shipperId, ind) =>
      dispatch(searchLocations(shipperId, ind))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
