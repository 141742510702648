import PropTypes from "prop-types";
import React from "react";
import Loader from "react-loader";
import styled from "@emotion/styled";
import { Alert } from "react-bootstrap";
import ReactTable from "react-table";
import { minimalistTableStyles } from "../../styles/table-styles";
import {
  PanelGroup,
  PanelGroupHeader,
  PanelGroupContent
} from "../../components/panel-group/PanelGroup";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DataWidgetTooltip } from "../../components/widgets/DataWidgetTooltip";

import { getColumns } from "./ReportsColumns";
import { withTranslation } from "react-i18next";
import MixpanelUtils from "../../trackers/mixpanel";

const Section = styled.section({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "1em"
});

class ReportBuilderView extends React.Component {
  static propTypes = {
    fetchReports: PropTypes.func.isRequired,
    pushReportDetailScreen: PropTypes.func.isRequired,
    reports: PropTypes.array,
    isLoading: PropTypes.bool,
    t: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.fetchReports();
    MixpanelUtils.track("Viewed Page: BI / Report Builder / Dashboard");
  }

  render() {
    const { t, reports, isLoading } = this.props;
    const headers = getColumns(t, false);

    const reportBuilderReport = reports.filter(
      r => r.groupName === "Report Builder"
    );

    const tableTrProps = (state, rowInfo, column, instance) => {
      return {
        onClick: e => {
          const reportId = rowInfo.original.reportId;
          this.props.pushReportDetailScreen(reportId);
        },
        style: { cursor: "pointer" }
      };
    };

    return (
      <Loader loaded={!isLoading}>
        <Section>
          {reportBuilderReport.length === 0 && (
            <Alert variant="warning">
              {t("reports:No Report Builders Found")}
            </Alert>
          )}
          {reportBuilderReport && reportBuilderReport.length > 0 && (
            <PanelGroup>
              <PanelGroupHeader
                title={
                  <div>
                    {t("reports:Report Builder")}
                    <DataWidgetTooltip
                      tooltip={t(
                        "reports:The Report Builder allows you to create your own report from a pre-defined data set. Share your reports with your organization using our shared reports feature."
                      )}
                      icon={faInfoCircle}
                      style={{ marginLeft: "0.5em" }}
                    />
                  </div>
                }
              />
              <PanelGroupContent style={{ padding: 0 }}>
                <ReactTable
                  className="customtable"
                  NoDataComponent={() => null}
                  data={reportBuilderReport}
                  columns={headers}
                  getTheadThProps={() => {
                    return {
                      style: minimalistTableStyles.thead
                    };
                  }}
                  getTrProps={tableTrProps}
                  showPagination={false}
                  pageSize={reportBuilderReport.length}
                  style={minimalistTableStyles.table}
                />
              </PanelGroupContent>
            </PanelGroup>
          )}
        </Section>
      </Loader>
    );
  }
}

export default withTranslation(["reports"])(ReportBuilderView);
