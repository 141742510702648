import React from "react";
import _ from "lodash";
import sizeMe from "react-sizeme";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

import { convertLocationAndItsGeofenceToPoints } from "../utils/geo";
import { LocationInfoPopup } from "../widgets/LocationInfoPopup";
import { isFenceValid } from "../../geofence-edit/geofence-types";
import HereMapPlatform from "../platforms/here/HereMapPlatform";
import GoogleMapPlatform from "../platforms/google/GoogleMapPlatform";
import { LadChicletSVG, BoxChicletSVG } from "../../../components/chiclets";

import {
  getActiveOrganization,
  getMapTypeOverride
} from "../../organizations/OrganizationsState";

export const enrichMapLocations = locations => {
  // Usually this kind of function should not have side effects. In this
  // case I preferred to change mapLocations itself because it may have
  // thousands of elements and duplicate it worsens performance
  locations.forEach(item => {
    if (item.position) {
      return;
    }
    item.position = {
      lat: item.latitude || item.geofence.properties.center.latitude,
      lng: item.longitude || item.geofence.properties.center.longitude
    };
  });
  return locations;
};

export class SimpleMap extends React.Component {
  constructor(props) {
    super(props);

    this.platform = new HereMapPlatform(this);
    this.map = null;
    this.mapObjects = {};
    this.mapDiv = null;
    this.mapLayers = {};

    this.state = {
      mapLocations: [],
      hasUpdatedOnce: false
    };

    // Binding events
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMarkerMouseEnter = this.onMarkerMouseEnter.bind(this);
    this.onMarkerMouseOut = this.onMarkerMouseOut.bind(this);
    this.drawLocations = this.drawLocations.bind(this);

    // Debouncing
    this.debouncedResizeMap = _.debounce(this.resizeMap, 100);
  }

  // Lifecycle
  componentDidUpdate(prevProps) {
    const { hasUpdatedOnce } = this.state;
    const { selectedLocation, mapLocations } = this.props;
    if (!this.isMapInitialized(prevProps)) return;

    if (!hasUpdatedOnce) {
      this.clearMap();
    }

    this.clearInfoBubbles();
    this.loadLocations();

    if (selectedLocation && mapLocations.length === 1) {
      if (!hasUpdatedOnce || prevProps.selectedLocation === null) {
        this.zoomSingleLocation(selectedLocation);
      }
    }

    this.handleMapSizeChanges(prevProps);

    if (!hasUpdatedOnce) {
      this.setState({ hasUpdatedOnce: true });
    }
  }

  componentDidMount() {
    if (!_.isEmpty(this.props.activeOrganization)) {
      this.initAll(this.props.activeOrganization, this.props.mapTypeOverride);
    }
  }

  // Init

  initMap(activeOrganization, mapTypeOverride) {
    if (mapTypeOverride === null) {
      if (activeOrganization.map_type.toUpperCase() === HereMapPlatform.name) {
        this.platform = new HereMapPlatform(this);
      } else {
        this.platform = new GoogleMapPlatform(this);
      }
    } else {
      const mapTypeOverrideUpper = mapTypeOverride.toUpperCase();

      if (mapTypeOverrideUpper === HereMapPlatform.name) {
        this.platform = new HereMapPlatform(this);
      } else if (mapTypeOverrideUpper === GoogleMapPlatform.name) {
        this.platform = new GoogleMapPlatform(this);
      } else {
        throw Error("Unknown platform name ", mapTypeOverride);
      }
    }
    this.platform.initMap(activeOrganization, mapTypeOverride);
  }

  initAll(activeOrganization, mapTypeOverride) {
    // Initialize our map
    this.initMap(activeOrganization, mapTypeOverride);

    this.loadLocations();
  }

  initHeatMap(coords) {
    this.platform.initHeatMap(coords);
  }

  deinitHeatMap() {
    this.platform.deinitHeatMap();
  }

  /**
   * Allow map initialization if there is an activeOrganization selected.
   *
   * @param {Object} prevProps
   */
  isMapInitialized(prevProps) {
    const { activeOrganization, mapTypeOverride } = this.props;

    // Wait until we have an activeOrganization before doing anything with the
    // map.
    if (
      _.isEmpty(activeOrganization) &&
      _.isEmpty(prevProps.activeOrganization)
    ) {
      return false;
    }

    // If we just got the activeOrganization, initialize the map and do nothing
    // else. The map must be initailized before we can do things like adding
    // objects or routes.
    const hasActiveOrganizationChanged =
      activeOrganization !== prevProps.activeOrganization;
    if (hasActiveOrganizationChanged) {
      this.initAll(activeOrganization, mapTypeOverride);
    } else {
      this.handleHeatmapChanges(prevProps);
    }
    return true;
  }

  /**
   * Clear map and toggle heat map / route map when showHeatmap changes
   *
   * @param {Object} prevProps
   */
  handleHeatmapChanges(prevProps) {
    const { heatmapCoords, showHeatmap } = this.props;

    // Clear map and toggle heat map / route map when showHeatmap changes
    if (showHeatmap && !prevProps.showHeatmap) {
      this.initHeatMap(heatmapCoords);
    } else if (!showHeatmap && prevProps.showHeatmap) {
      this.setState({ mapLocations: [] });
      this.clearMap();
      this.deinitHeatMap();
      this.loadLocations();
    }
  }

  /**
   * Resize the map or viewport sizes changes
   *
   * @param {Object} prevProps
   */
  handleMapSizeChanges(prevProps) {
    const { width, height } = this.props.size;
    const hasSizeChanged =
      width !== prevProps.width || height !== prevProps.height;
    if (hasSizeChanged) {
      this.debouncedResizeMap();
    }
  }

  loadLocations() {
    const { mapLocations } = this.props;

    if (mapLocations && !_.isEqual(mapLocations, this.state.mapLocations)) {
      this.setState(
        { mapLocations: enrichMapLocations(mapLocations) },
        this.drawLocations
      );
    }
  }

  // Drawing

  drawLocations() {
    const { drawAllGeofences, selectedLad } = this.props;
    const locations = this.state.mapLocations;
    const areThereLocationsAndALad =
      locations && selectedLad && !_.isEmpty(locations);

    this.clearMap();

    if (!areThereLocationsAndALad) {
      return;
    }

    locations.forEach(location => {
      // Client asked not to map locations that don't have a geofence
      // (i.e. 0 lat/0 long) per DEV-272
      if (this.hasLocationGeofence(location)) {
        this.addLadMarker(
          location.id,
          this.getDisplayLad(location),
          location.position,
          location
        );
      }
    });
    if (drawAllGeofences) this.drawLocationsGeofences();

    this.zoomLocationsConsideringGeofences(locations);
  }

  drawLocationsGeofences() {
    const { selectedLocation } = this.props;
    const locations = this.state.mapLocations;
    locations.forEach(location => {
      // Client asked not to map locations that don't have a geofence
      // (i.e. 0 lat/0 long) per DEV-272
      if (this.hasLocationGeofence(location)) {
        const useAltColor = location === selectedLocation;
        this.drawGeofence(location, false, useAltColor);
      }
    });
  }

  // Zooming

  getMapZoom() {
    return this.map.getZoom();
  }

  setMapCenter(pos) {
    this.platform.setMapCenter(pos);
  }

  setMapZoom(zoom) {
    this.map.setZoom(zoom);
  }

  zoomSingleLocation(location) {
    this.platform.zoomSingleLocation(location);
  }

  zoomLocations(locations) {
    this.platform.zoomLocations(locations);
  }

  zoomLocationsConsideringGeofences(locations) {
    const pointsConsideredToImproveMapBoundsZoom = [];
    if (_.isEmpty(locations)) {
      return;
    }

    locations.forEach(location => {
      pointsConsideredToImproveMapBoundsZoom.push(
        ...convertLocationAndItsGeofenceToPoints(location)
      );
    });

    if (_.isEmpty(pointsConsideredToImproveMapBoundsZoom)) {
      return;
    }

    // If there is only one point, use the traditional single location zoom
    if (pointsConsideredToImproveMapBoundsZoom.length === 1) {
      this.zoomSingleLocation(pointsConsideredToImproveMapBoundsZoom[0]);
    } else {
      this.zoomLocations(pointsConsideredToImproveMapBoundsZoom);
    }
  }

  // Markers

  createAndAddMapMarker(
    name,
    pos,
    zIndex,
    data,
    isClickable,
    iconSvg,
    iconHeight,
    iconWidth,
    iconDefaultHeight,
    iconDefaultWidth,
    iconXOffsetForGoogle,
    iconYOffsetForGoogle,
    iconXAnchorForHERE,
    iconYAnchorForHERE
  ) {
    return this.platform.createAndAddMapMarker(
      name,
      pos,
      zIndex,
      data,
      isClickable,
      iconSvg,
      iconHeight,
      iconWidth,
      iconDefaultHeight,
      iconDefaultWidth,
      iconXOffsetForGoogle,
      iconYOffsetForGoogle,
      iconXAnchorForHERE,
      iconYAnchorForHERE
    );
  }

  addLadMarker(id, lad, position, location = {}, height = 45, width = 45) {
    const { markerIsClickable, useBoxChiclets } = this.props;

    const defaultHeight = 64;
    const defaultWidth = 64;

    let svg = LadChicletSVG({
      lad: lad,
      chicletStyle: useBoxChiclets ? BoxChicletSVG : LadChicletSVG,
      height: defaultHeight,
      width: defaultWidth,
      capacity: location.status || null
    });

    const marker = this.createAndAddMapMarker(
      id,
      position,
      null,
      {
        id: id,
        pos: position,
        location: location,
        lad: lad
      },
      markerIsClickable,
      svg,
      height,
      width,
      defaultHeight,
      defaultWidth,
      28,
      55,
      width / 2,
      height / 2
    );

    if (markerIsClickable) {
      this.addMapMarkerEventListener(marker, "click", this.onMarkerClick);
    }
    this.addMapMarkerEventListener(
      marker,
      "mouseenter",
      this.onMarkerMouseEnter
    );
    this.addMapMarkerEventListener(marker, "mouseout", this.onMarkerMouseOut);
  }

  clearMap(excludeKeyPrefix = null) {
    this.platform.clearMap(excludeKeyPrefix);
  }

  clearMapMarkers(prefix) {
    this.platform.clearMapMarkers(prefix);
  }

  getDisplayLad(location) {
    const { selectedLad, lads } = this.props;
    const locationLad = location.lad;
    if (locationLad && lads) {
      // The lad in the location data does not
      // contain the default name, we need the default
      // name to determine the color
      const foundLad = lads.find(l => Number(l.id) === Number(locationLad.id));
      return foundLad ? foundLad : selectedLad;
    }
    return selectedLad;
  }

  // Popup

  createAndAddMapInfoBubble(pos, content) {
    this.clearInfoBubbles();
    this.platform.createAndAddMapInfoBubble(pos, content);
  }

  clearInfoBubbles() {
    this.platform.clearInfoBubbles();
  }

  getInfoBubble(data, lad) {
    const { eventHandler, popupComponent: PopupComponent } = this.props;

    // FIXME: HERE (and Google) maps requires a string or HTML node.
    // Converting Component to a Node, but feels hacky.
    let tempdiv = document.createElement("div");
    ReactDOM.render(
      <PopupComponent data={data} lad={lad} eventHandler={eventHandler} />,
      tempdiv
    );

    return tempdiv;
  }

  // Events

  addMapEventListener(eventName, callback) {
    const validEvents = ["mapviewchange"];
    if (!validEvents.includes(eventName.toLowerCase())) {
      throw Error(`Invalid event: ${eventName}`);
    }
    this.platform.addMapEventListener(eventName, callback);
  }

  addMapMarkerEventListener(marker, eventName, callback) {
    const validEvents = ["click", "mouseenter", "mouseout"];
    if (!validEvents.includes(eventName.toLowerCase())) {
      throw Error(`Invalid event: ${eventName}`);
    }
    this.platform.addMapMarkerEventListener(marker, eventName, callback);
  }

  onMarkerClick(e, googleMapsMarker) {
    const { mapLocations } = this.props;
    const marker = googleMapsMarker ? googleMapsMarker : e.target;

    if (mapLocations) {
      const markerdata = marker.getData();

      this.createAndAddMapInfoBubble(
        markerdata.pos,
        this.getInfoBubble(markerdata.location, markerdata.lad)
      );

      this.setMapCenter(markerdata.pos);
      this.setMapZoom(8);
    }
  }

  /**
   * Mouse Enter on any marker will hit this method.
   */
  onMarkerMouseEnter(event, googleMapsMarker) {
    const marker = googleMapsMarker ? googleMapsMarker : event.target;
    const markerData = marker.getData();

    const { onMarkerMouseEnter = _.noop } = this.props;
    onMarkerMouseEnter(markerData);
  }

  /**
   * Mouse Out on any marker will hit this method.
   */
  onMarkerMouseOut(event, googleMapsMarker) {
    const { onMarkerMouseOut = _.noop } = this.props;
    onMarkerMouseOut();
  }

  // Bounds

  isMapViewBoundsContainPoint(pos) {
    return this.platform.isMapViewBoundsContainPoint(pos);
  }

  isMapViewBoundsContainLatLng(lat, lng) {
    return this.platform.isMapViewBoundsContainLatLng(lat, lng);
  }

  setMapViewBounds(boundsRect) {
    this.platform.setMapViewBounds(boundsRect);
  }

  // Geofence

  drawGeofence(location, draggable, selected) {
    if (!isFenceValid(location.geofence)) {
      return;
    }

    const geofenceMapId = `geofence:${location.id}:group`;

    try {
      this.clearMapMarkers(geofenceMapId);
    } catch (err) {
      // ignore - we don't care if it already got removed
    }

    const generateLadToUseInsideGeofence = (
      lad,
      useBoxChiclets,
      textSubscript = ""
    ) => {
      return LadChicletSVG({
        lad: lad,
        chicletStyle: useBoxChiclets ? BoxChicletSVG : LadChicletSVG,
        height: 64,
        width: 64,
        textSubscript
      });
    };

    const { useBoxChiclets, selectedLad } = this.props;
    /* H1-2180 Fix for error on Shipment Details when selectedLad is undefined */
    const SVGGenerator = !_.isNil(selectedLad)
      ? _.partial(generateLadToUseInsideGeofence, selectedLad, useBoxChiclets)
      : null;
    const geofenceGroup = this.platform.shapes.createGeofence(
      location,
      draggable,
      selected,
      SVGGenerator
    );
    this.platform.drawGeofence(location, geofenceGroup, geofenceMapId);
  }

  hasLocationGeofence(location) {
    if (_.isEmpty(location.geofence.properties.center)) {
      return false;
    }
    const pos = location.geofence.properties.center;
    const hasGeofence =
      !_.isNil(pos) && !_.isNil(pos.latitude) && !_.isNil(pos.longitude);
    return hasGeofence;
  }

  // Other

  getMapObject(name) {
    return this.mapObjects[name];
  }

  removeMapObject(name) {
    this.platform.removeMapObject(name);
  }

  resizeMap() {
    this.platform.resizeMap();
  }

  render() {
    return (
      <div
        style={{ height: "100%", width: "100%" }}
        ref={el => (this.mapDiv = el)}
      />
    );
  }
}

SimpleMap.propTypes = {
  mapLocations: PropTypes.array,
  popupComponent: PropTypes.elementType,
  popupClickHandler: PropTypes.func,
  showHeatmap: PropTypes.bool,
  heatmapCoords: PropTypes.array,
  selectedLad: PropTypes.object,
  selectedLocation: PropTypes.object,
  useBoxChiclets: PropTypes.bool,
  // Events
  onMarkerMouseEnter: PropTypes.func,
  onMarkerMouseOut: PropTypes.func
};

SimpleMap.defaultProps = {
  popupComponent: LocationInfoPopup
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
    mapTypeOverride: getMapTypeOverride(state),
    ...state.maps
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const sizeMeHOC = sizeMe({ monitorHeight: true })(SimpleMap);
const SimpleMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(sizeMeHOC);
export default SimpleMapContainer;
