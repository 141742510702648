/** @jsx jsx
 * Popup displayed when clicking a VIN on the Connected Car map
 */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import { Button } from "react-bootstrap";
import { FlexColDiv } from "../../../styles/container-elements";
import LocationAddressComplex from "../../location/components/LocationAddressComplex";

import Colors from "../../../styles/colors";

const popupCss = {
  backgroundColor: "white",
  borderRadius: 4,
  color: Colors.text.DARK_GRAY,
  padding: "1em",
  fontSize: "x-small",
  width: "26em",
  border: `1px solid ${Colors.background.WARM_GRAY}`,

  dl: {
    display: "grid",
    gridTemplateColumns: "10em auto",
    columnGap: ".5em",
    rowGap: 0,
    marginBottom: ".5em"
  },

  dt: {
    fontWeight: "normal",
    textAlign: "right"
  },

  dd: {
    fontWeight: "bold",
    marginBottom: 0
  },

  ".btn-success": {
    fontSize: "x-small",
    margin: "0 auto"
  }
};

const AdcLocationInfoPopup = ({ location, onMoreDetailsClick }) => {
  const { t } = useTranslation("map");

  return (
    <FlexColDiv data-qa="popup-location" css={popupCss}>
      <dl>
        <dt>{t("Location Name")}:</dt>
        <dd data-qa="name">{location.name}</dd>

        <dt>{t("Address")}:</dt>
        <dd data-qa="address">
          <LocationAddressComplex
            defaultTextColor={Colors.text.DARK_GRAY}
            addressColor={Colors.text.DARK_GRAY}
            address1={location.address}
            city={location.city}
            state={location.state}
            postalCode={location.postalCode}
            country={location.country}
            lookupCountryNameFromCode={false}
          />
        </dd>

        <dt>{t("Demand Areas")}:</dt>
        <dd data-qa="demand-areas">
          {location.demandAreas.map(da => da.name).join(", ")}
        </dd>

        <dt>{t("map:Total Onsite VINs")}:</dt>
        <dd data-qa="total-onsite-vins">{location.onSiteCount}</dd>

        <dt>{t("map:Total Aging VINs")}:</dt>
        <dd data-qa="total-aging-vins">{location.agedCount}</dd>

        <dt>{t("map:Capacity Status")}:</dt>
        <dd data-qa="capacity-status">{location.status}</dd>
      </dl>

      <Button
        data-qa="button-details"
        className="btn-success"
        onClick={() => onMoreDetailsClick(location)}
      >
        {t("map:View More Details")}
      </Button>
    </FlexColDiv>
  );
};

AdcLocationInfoPopup.propTypes = {
  location: PropTypes.object,
  onMoreDetailsClick: PropTypes.func
};

export default AdcLocationInfoPopup;
