import React from "react";
import PropTypes from "prop-types";
import Colors from "../../../styles/colors";

const RangeCell = props => {
  const { t, dateRange } = props;

  if (!dateRange) {
    return null;
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      {dateRange.start && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dateRange.start.time}</div>
          <div>{dateRange.start.date}</div>
        </div>
      )}
      {dateRange.start && dateRange.end && (
        <div
          style={{
            margin: "0 10px",
            alignSelf: "center",
            color: Colors.text.LIGHT_GRAY
          }}
        >
          {t("adc-details:to")}
        </div>
      )}
      {dateRange.end && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div>{dateRange.end.time}</div>
          <div>{dateRange.end.date}</div>
        </div>
      )}
    </div>
  );
};

const dateTimePropType = PropTypes.shape({
  date: PropTypes.string,
  time: PropTypes.string
});

RangeCell.propTypes = {
  t: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    start: dateTimePropType,
    end: dateTimePropType
  })
};

export default RangeCell;
