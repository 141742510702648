/** @jsx jsx */
import { jsx } from "@emotion/core";
import ReactTable from "react-table";
import { useTranslation } from "react-i18next";

import Colors from "../../styles/colors";
import { getColumns } from "./LocationMatchingColumns";

const CurrentLocationPanel = ({
  currentLocation,
  lads,
  selectedLocations,
  eventHandler,
  organizations
}) => {
  const { t } = useTranslation("location-matching");

  const currentColumns = getColumns(t, lads, null, [], organizations);
  const selectedColumns = getColumns(
    t,
    lads,
    "dismiss",
    selectedLocations,
    null
  );

  const displayedLocations = selectedLocations.length
    ? selectedLocations
    : [""];

  const injectThProps = (state, rowInfow, column) => {
    return { style: { display: "none" } };
  };

  const tableTrProps = (state, rowInfo, column, instance) => {
    return {
      onClick: e => {
        const locID = rowInfo.original.id;
        eventHandler(locID, "DISMISS_LOCATION");
      },
      style: { cursor: "pointer" }
    };
  };

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        padding: "1em",
        maxHeight: "20em",
        backgroundColor: "white"
      }}
    >
      <div
        css={{
          display: "flex",
          height: "2em",
          minHeight: "2em",
          flexDirection: "column",
          backgroundColor: Colors.highlight.LIGHT_BLUE,
          color: "white",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {t("location-matching:Current Location Information")}
      </div>
      <div
        css={{
          display: "flex",
          height: "4.0em",
          minHeight: "4.0em",
          flexDirection: "column"
        }}
      >
        <ReactTable
          className="customtable"
          data={[currentLocation]}
          columns={currentColumns}
          getTheadThProps={injectThProps}
          showPagination={false}
          pageSize={1}
        />
      </div>
      <div
        css={{
          display: "flex",
          height: "2em",
          minHeight: "2em",
          flexDirection: "column",
          backgroundColor: Colors.background.GRAY,
          color: "black",
          fontWeight: "bold",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {t("location-matching:Replace with")}
      </div>
      <div
        css={{
          display: "flex",
          minHeight: "4.0em",
          flexDirection: "column"
        }}
      >
        <ReactTable
          className="customtable"
          data={displayedLocations}
          columns={selectedColumns}
          getTheadThProps={injectThProps}
          getTrProps={tableTrProps}
          showPagination={false}
          minRows={1}
          pageSize={400}
        />
      </div>
    </div>
  );
};

export default CurrentLocationPanel;
