import React from "react";
import PropTypes from "prop-types";

const NumberCell = ({ value, styles = {} }) => {
  return (
    <div style={styles} className="d-flex justify-content-center">
      {value}
    </div>
  );
};

NumberCell.propTypes = {
  value: PropTypes.number
};

export default NumberCell;
