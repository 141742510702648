import _ from "lodash";
import { combineReducers } from "redux";
import axios from "axios";
import apiUrl from "../../api-url";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import { StatesUS } from "../location-edit/LocationEditData";
import { isArray } from "util";

// Redux mount point
const STORE_MOUNT_POINT = "adcLocations";

// Fetch ducks
const locationDuck = buildFetchDuck("location");
const locationsCountDuck = buildFetchDuck("locationsCount");

// URLs
const BASE_LOCATION_URL = apiUrl("/entity/solution");
const BASE_ALL_LOCATIONS_URL = apiUrl("/location/locations");

// Action creators

const pushAdcDetailsView = locationId => dispatch =>
  dispatch({
    type: "ADC_DETAILS",
    payload: { location_id: locationId }
  });

function fetchLocation(solutionId, locationId) {
  const qs = "?lifeCycleState=Active,Completed,Delivered";

  return dispatch => {
    dispatch(locationDuck.clear());
    dispatch(
      locationDuck.fetch(
        `${BASE_LOCATION_URL}/${solutionId}/entity-location/${locationId}${qs}`
      )
    );
  };
}

function fetchLocationsCount() {
  return dispatch => {
    dispatch(locationsCountDuck.clear());
    dispatch(
      locationsCountDuck.fetch(
        `${BASE_ALL_LOCATIONS_URL}?lad=Distribution&lob=Vehicles&children=1`,
        {
          headers: {
            Accept: "application/json;version=count"
          }
        }
      )
    );
  };
}

// Asynchronously return location search results for <Async> select component
async function searchLocations(query, demandArea = null) {
  if (query.length < 2) {
    return [];
  }

  let url = `${BASE_ALL_LOCATIONS_URL}?lad=Distribution&lob=Vehicles&everything=${query}&children=1`;

  if (demandArea) {
    url = `${url}&group=${demandArea}`;
  }

  return axios
    .get(url)
    .then(response => {
      if (!response || !response.data || response.data.length === 0) {
        return [];
      } else {
        const locationOptions = buildSearchLocationOptions(response);
        return locationOptions;
      }
    })
    .catch(err => {
      console.error(err);
    });
}

async function searchLocationsForFilter(query) {
  if (query.length < 2) {
    return {
      options: [],
      hasMore: false
    };
  }

  let options = await searchLocations(query);
  return {
    options: options,
    hasMore: false
  };
}

// Transform async location search response into options for <Async> select component
function buildSearchLocationOptions(searchResponse) {
  const data =
    searchResponse && searchResponse.data && searchResponse.data.length
      ? searchResponse.data
      : [];

  return data.map(l => {
    const state = _.find(StatesUS, { value: l.state });

    try {
      return {
        value: l.id,
        label: l.code ? `${l.name} (${l.code})` : l.name,
        id: l.id,
        code: l.code,
        name: l.name,
        state: l.state,
        stateName: state ? state.label : "",
        city: l.city,
        country: l.country,
        children_locations: l.children_locations ? l.children_locations : []
      };
    } catch (e) {
      console.error(e);
      return {};
    }
  });
}

// Selectors
const getLocation = state => {
  const location = state[STORE_MOUNT_POINT].location.data;
  return isArray(location) ? null : location;
};

const getLocationsCount = state => {
  return _.get(state[STORE_MOUNT_POINT], "locationsCount.data[0].count", 0);
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    pushAdcDetailsView,
    fetchLocation,
    fetchLocationsCount,
    searchLocations,
    searchLocationsForFilter
  },
  selectors: {
    getLocation,
    getLocationsCount
  },
  reducer: combineReducers({
    location: locationDuck.reducer,
    locationsCount: locationsCountDuck.reducer
  })
};
