import moment from "moment";
import _ from "lodash";

import apiUrl from "../../../../api-url";
import buildSearchBarState from "../../../../components/search-bar/SearchBarStateBuilder";
import {
  getBasicQueryString,
  getEverythingQueryString
} from "../../../../components/search-bar/search-filter-query-strings";
import {
  localizedTimeFormatter,
  localizedDateFormatter
} from "../../../../utils/date-time";
import { parseTimeWindow } from "../../../fv-vin-details/vin-details-utils";

const STORE_MOUNT_POINT = "adcDetailsVinSearch";

// Actions
const actionNamer = actionName => `${STORE_MOUNT_POINT}/${actionName}`;
const CHANGE_LOCATION_TYPE = actionNamer("CHANGE_LOCATION_TYPE");

// Helpers
// const findReference = (entity, key) => {
//   if (entity.references === undefined) return null;
//   const ref = entity.references.find(obj => key in obj);
//   return ref ? ref[key] : null;
// };

const formatTimeWindow = timeWindow => {
  return {
    start: {
      date: localizedDateFormatter(timeWindow[0]),
      time: localizedTimeFormatter(timeWindow[0])
    },
    end: {
      date: localizedDateFormatter(timeWindow[1]),
      time: localizedTimeFormatter(timeWindow[1])
    }
  };
};

const findEta = item => {
  // Use eta if it exists
  let eta = item.locationData?.inbound?.eta;
  if (eta) {
    return `${localizedTimeFormatter(eta)} ${localizedDateFormatter(eta)}`;
  }

  // If eta does not exist, use the scheduledWindow if it exists
  eta = item.locationData.inbound.scheduledWindow;
  if (eta) {
    const timeWindow = parseTimeWindow(eta);
    return formatTimeWindow(timeWindow);
  }

  // If eta and scheduledWindow do not exist, default return
  return "";
};

// H2-1081: use shipment exception if there is one
const getStatus = item => {
  return _.get(item, "shipmentActiveException", "On Time");
};

const findLastLocation = item => {
  if (!item.lastPositionUpdate) {
    return "";
  }

  if (!item.lastPositionUpdate.city || !item.lastPositionUpdate.state) {
    return "";
  }

  return `${item.lastPositionUpdate.city}, ${item.lastPositionUpdate.state}`;
};

const findDestinationDealer = item => {
  if (!item.locationData?.locationData?.inbound) {
    return "";
  }

  return `${item.locationData.inbound.name} (${item.locationData.inbound.code})`;
};

const findAvailableForPickup = item => {
  // parseTimeWindow
  if (!item.activeScheduledPickup) {
    return "";
  }
  let timeWindow = parseTimeWindow(item.activeScheduledPickup);
  return formatTimeWindow(timeWindow);
};

const findNextPlannedDestination = item => {
  const value = item.nextPlannedDestination;
  return value && value.name ? `${value.name} (${value.code})` : null;
};

const findScheduledPickup = item => {
  if (!item.locationData?.outbound?.scheduledWindow) {
    return "";
  }

  const timeWindow = parseTimeWindow(
    item.locationData.outbound.scheduledWindow
  );
  return formatTimeWindow(timeWindow);
};

/**
 * Transform the returned data into a format easily understadable by our table
 */
const transformEntities = payload => {
  payload.data = payload.data.map(item => {
    return {
      id: item.id,
      productType: item.description || "",
      lastLocation: findLastLocation(item),
      eta: findEta(item),
      status: getStatus(item),
      destinationDealer: findDestinationDealer(item),
      availableForPickup: findAvailableForPickup(item),
      scheduledPickup: findScheduledPickup(item),
      dwellTimer: item.currentDwell,
      nextPlannedDestination: findNextPlannedDestination(item),
      nextActiveCarrierName: item.nextActiveCarrierName
    };
  });
  return payload;
};

/**
 * We have a special querystring handling here, which transforms the
 * locationType and locationId qs filters into:
 * ?inboundLocation=[locationId]
 * ?currentLocation=[locationId]
 * ?outboundLocation=[locationId]
 * Depending on the locationType we receive.
 */
const fetchSearch = (qs = null, solutionId, duck, dispatch) => {
  let url = apiUrl(`/entity/solution/${solutionId}/entity`);
  const params = new URLSearchParams(qs);
  // Build the changes we need in the qs
  const locationTypeToQueryString = {
    Inbound: "inboundLocation",
    "Scheduled Outbound": "outboundLocation",
    "On Site": "currentLocation"
  };
  const locationType = locationTypeToQueryString[params.get("locationType")];
  const locationId = params.get("locationId");
  /* H1-1594 Add lifecycle status to all VIN searches */
  url = `${url}?lifeCycleState=Active,Completed,Delivered&${locationType}=${locationId}`;

  // Remove params that we have changed
  params.delete("locationType");
  params.delete("locationId");

  // Build url with the other params too
  url = `${url}&${params}`;

  const config = {
    headers: {
      "x-time-zone": moment.tz.guess(),
      Accept: "application/json;version=adc"
    }
  };
  dispatch(duck.fetch(url, config, transformEntities));
};

/**
 * Just dispatch location type change on redux store, which will be captured
 * and changed by the reducer.
 */
export const changeLocationType = value => {
  return dispatch => {
    dispatch({
      type: CHANGE_LOCATION_TYPE,
      payload: value
    });
  };
};

// Selectors
export const getLocationTypeSelected = state =>
  state[STORE_MOUNT_POINT].locationTypeSelected;

// Reducers
/**
 * Change locationTypeSelected on redux store.
 */
const locationTypeReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_LOCATION_TYPE:
      return {
        ...state,
        locationTypeSelected: action.payload
      };

    default:
      return state;
  }
};

export default buildSearchBarState(
  STORE_MOUNT_POINT,
  [
    {
      queryKey: "everything",
      label: t => t("adc-details:Search Everything"),
      placeholder: t => t("adc-details:VIN Search"),
      queryBuilder: getEverythingQueryString,
      property: null
    }
  ],
  // These are not two filter categories that will be showed to the user using
  // the normal FilterSection approach, but we use them in the backstage to
  // filter for what we need. The locationType one is being shown in a
  // different way: as a select and the other one just in the url.
  [
    {
      queryKey: "locationId",
      label: t => null,
      optionsGetter: props => [],
      queryBuilder: getBasicQueryString
    },
    {
      queryKey: "locationType",
      label: t => null,
      optionsGetter: props => [],
      queryBuilder: getBasicQueryString
    }
  ],
  fetchSearch,
  [locationTypeReducer]
);
