import React from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import LocationAddressComplex from "../../location/components/LocationAddressComplex";

import "./ExpandedDetailsTable.css";
import LocationName from "../../location/components/LocationName";

class ExpandedDetailsTable extends React.Component {
  getReferences() {
    const { statusUpdate } = this.props;

    let references = [];
    if (
      statusUpdate &&
      statusUpdate.references &&
      statusUpdate.references.length > 0
    ) {
      for (let i = 0; i < statusUpdate.references.length; i++) {
        // We are not currently translating the qualifiers, but when we do, do it here (add t to props above)
        const reference = statusUpdate.references[i];
        let displayValue = reference.value;
        if (
          ["ship to", "ship from"].includes(reference.qualifier.toLowerCase())
        ) {
          displayValue = (
            <>
              <LocationName
                style={{
                  fontWeight: "normal !important",
                  fontSize: "1em !important"
                }}
                name={reference.name}
              />
              <LocationAddressComplex
                address1={reference.address1}
                address2={reference.address2}
                city={reference.city}
                state={reference.state}
                postalCode={reference.postalCode}
                country={reference.country}
              />
            </>
          );
        }

        references.push(
          <tr key={i} data-qa="rows-table-expanded-details">
            <td className="expanded-details-label">
              {_.capitalize(reference.qualifier)}:
            </td>
            <td>{displayValue}</td>
          </tr>
        );
      }
    }

    return references;
  }

  render() {
    const { comments, t } = this.props;

    return (
      <div style={{ padding: 20, backgroundColor: "rgba(0,0,0,0.03)" }}>
        <table className="expanded-details">
          <thead data-qa="header-table-expanded-details">
            <tr>
              <th>{t("fv-vin-details:Expanded Details")}:</th>
            </tr>
          </thead>
          <tbody>
            {this.getReferences()}
            {comments ? (
              <tr data-qa="rows-table-expanded-details-comments">
                <td className="expanded-details-label">
                  {t("fv-vin-details:Comments")}:
                </td>
                <td>{comments}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withTranslation("fv-vin-details")(ExpandedDetailsTable);
