/*
Blue Newt Software, Inc. Confidential Unpublished Copyright
(c) 2008-2018 Blue Newt Software, Inc., All Rights Reserved.

NOTICE: All information contained herein is, and remains the property of Blue
Newt Software, Inc. The intellectual and technical concepts contained herein
are proprietary to Blue Newt Software, Inc. and may be covered by U.S. and
Foreign Patents, patents in process, and are protected by trade secret or
copyright law. Dissemination of this information or reproduction of this
material is strictly forbidden unless prior written permission is obtained
from Blue Newt Software, Inc. Access to the source code contained herein is
hereby forbidden to anyone except current Blue Newt Software, Inc. employees,
managers or contractors who have executed  Confidentiality and Non-disclosure
agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended
publication or disclosure of this source code, which includes  information
that is confidential and/or proprietary, and is a trade secret, of Blue Newt
Software, Inc. ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC
PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT
THE EXPRESS WRITTEN CONSENT OF Blue Newt Software, Inc. IS STRICTLY
PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND INTERNATIONAL TREATIES.
THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES
NOT CONVEY OR IMPLY ANY RIGHTS  TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS
CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT MAY DESCRIBE, IN
WHOLE OR IN PART.
*/

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component, PureComponent } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { Colors, Borders, BoxShadow } from "../styles/variables";
import { MediaQueries } from "../../../components/responsive";

import { FaExpand } from "react-icons/fa";
import { MdClose } from "react-icons/md";

const overlayRoot = document.getElementById("root");

export class Overlay extends PureComponent {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
  }

  componentDidMount() {
    overlayRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    overlayRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

Overlay.propTypes = {
  children: PropTypes.node
};

// Panels can be defined with a size prop set to 'half', 'third', or 'full'
// If set to 'half', two panels will fit per row unless displayed in a mobile format
const PanelBase = styled.div(
  {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    border: `1px solid ${Colors.background.GEYSER}`,
    boxShadow: BoxShadow,
    marginBottom: "1em",
    overflow: "hidden",
    marginLeft: "1em"
  },
  ({
    size = "full",
    isMaximized = false,
    style = {},
    mobileFlexBasis = "100%"
  }) => ({
    position: isMaximized ? "fixed" : "relative",
    left: isMaximized ? 0 : null,
    top: isMaximized ? 0 : null,
    right: isMaximized ? 0 : null,
    bottom: isMaximized ? 0 : null,
    zIndex: isMaximized ? 4 : 3,
    width: isMaximized ? "100vw" : null,
    height: isMaximized ? "100vh" : null,
    borderRadius: isMaximized ? null : Borders.RADIUS,
    [MediaQueries.smallAndDown]: {
      flexBasis: mobileFlexBasis
    },
    [MediaQueries.mediumAndUp]: {
      flexBasis:
        size === "full" || isMaximized
          ? "100%"
          : size === "third"
          ? "33.33%"
          : "50%",
      maxWidth:
        size === "full" || isMaximized
          ? "100%"
          : size === "third"
          ? "calc(33.33% - 1em)"
          : "calc(50% - 1em)"
    },
    style
  })
);

const PanelHeader = styled.div(
  {
    backgroundColor: "white",
    borderBottom: `1px solid ${Colors.background.GEYSER}`,
    color: Colors.text.RIVER_BED,
    display: "flex",
    alignItems: "center",
    padding: "0.5em",
    fontSize: "18px"
  },
  ({ isMaximized = false }) => ({
    borderTopRightRadius: isMaximized ? 0 : 3.75,
    borderTopLeftRadius: isMaximized ? 0 : 3.75
  })
);

// Container that holds / wraps panels down the page
export const PanelContainer = styled.div({
  display: "flex",
  flexWrap: "wrap",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignContent: "flex-start",
  flex: 1,
  marginLeft: "-1em"
});

export class Panel extends Component {
  state = {
    isMaximized: false
  };

  render() {
    const {
      title,
      headerComponents,
      style,
      headerStyle,
      size,
      children,
      onMaximize,
      canMaximize,
      mobileFlexBasis
    } = this.props;
    const { isMaximized } = this.state;

    const panel = (
      <PanelBase
        size={size}
        isMaximized={isMaximized}
        style={style}
        data-qa={this.props["data-qa"] || null}
        mobileFlexBasis={mobileFlexBasis}
      >
        <PanelHeader
          isMaximized={isMaximized}
          style={headerStyle}
          data-qa="header-panel"
        >
          {title}
          <div
            css={{
              display: "flex",
              flex: 1,
              alignItems: "center",
              [MediaQueries.smallAndDown]: {
                overflowX: "scroll"
              },
              [MediaQueries.smallAndUp]: {
                overflowX: "visible"
              }
            }}
          >
            {headerComponents}
          </div>
          {canMaximize && (
            <div
              onClick={() => {
                if (onMaximize != null) {
                  onMaximize(!isMaximized);
                }
                this.setState({ isMaximized: !isMaximized });
              }}
              css={{
                marginLeft: "1em",
                "&:hover": {
                  cursor: "pointer",
                  color: Colors.text.RIVER_BED
                }
              }}
            >
              {!isMaximized && <FaExpand />}
              {isMaximized && (
                <MdClose style={{ width: "1.5em", height: "1.5em" }} />
              )}
            </div>
          )}
        </PanelHeader>
        <div
          css={{
            overflow: "hidden",
            display: "inline-block",
            height: "100%",
            width: "100%"
          }}
          data-qa="content-panel"
        >
          {children}
        </div>
      </PanelBase>
    );

    return isMaximized ? <Overlay>{panel}</Overlay> : panel;
  }
}

Panel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  size: PropTypes.string,
  headerComponents: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  style: PropTypes.object,
  headerStyle: PropTypes.object,
  onMaximize: PropTypes.func,
  canMaximize: PropTypes.bool
};

Panel.defaultProps = {
  canMaximize: true,
  mobileFlexBasis: "100%"
};
