/** @jsx jsx */ // eslint-disable-next-line
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLegend,
  VictoryStack,
  VictoryTooltip,
  VictoryLabel
} from "victory";

const VerticalStackedBarChart = ({
  data,
  barStyle,
  axisStyle,
  crossAxisStyle,
  flyoutStyle,
  barClick,
  colorScale,
  chartLabels
}) => {
  let axisValues = [];
  let labelData = [];

  let barElem;

  if (
    data &&
    ((data.Aging && data.Aging.length > 0) ||
      (data.Dwell && data.Dwell.length > 0))
  ) {
    barElem = Object.keys(data).map((key, i) => {
      const labelValues = data[key].map(
        items => `${items.y} ${chartLabels[i]}` || ""
      );

      labelData.push({ name: key, symbol: { type: "square" } });

      if (i === 0) {
        data[key].forEach(item => {
          axisValues.push(item.x);
        });
      }

      return (
        <VictoryBar
          key={key}
          style={barStyle}
          data={data[key]}
          labels={labelValues}
          labelComponent={
            <VictoryTooltip
              cornerRadius={0}
              pointerLength={0}
              flyoutStyle={flyoutStyle}
            />
          }
          events={[
            {
              target: "data",
              eventHandlers: {
                onClick: barClick
              }
            }
          ]}
        />
      );
    });
  }

  return (
    <VictoryChart
      domainPadding={30}
      padding={{ top: 50, bottom: 90, left: 30, right: 50 }}
    >
      <VictoryLegend
        x={20}
        y={10}
        colorScale={colorScale}
        data={labelData}
        orientation="horizontal"
      />
      <VictoryAxis
        style={axisStyle}
        tickValues={axisValues}
        tickLabelComponent={
          <VictoryLabel
            dx={-20}
            dy={2}
            angle={20}
            textAnchor="start"
            style={{ fontSize: 12 }}
          />
        }
      />
      <VictoryAxis style={crossAxisStyle} dependentAxis />
      <VictoryStack style={{ data: { width: 15 } }} colorScale={colorScale}>
        {barElem}
      </VictoryStack>
    </VictoryChart>
  );
};

VerticalStackedBarChart.propTypes = {
  data: PropTypes.object.isRequired,
  barStyle: PropTypes.object,
  axisStyle: PropTypes.object,
  crossAxisStyle: PropTypes.object,
  barClick: PropTypes.func,
  colorScale: PropTypes.array,
  flyoutStyle: PropTypes.object,
  chartLabels: PropTypes.array
};

export default VerticalStackedBarChart;
