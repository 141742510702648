import _ from "lodash";
import debouncePromise from "debounce-promise";

import { AsyncMultiSelectFilterButton } from "../../components/search-bar/FilterButtons";
import AdcLocationsState from "../adc-dashboard/AdcLocationsState";
import FinVehicleEntitiesState from "../fv-dashboard/FinVehicleEntitiesState";

import { SelectFilterButton } from "../../components/search-bar/FilterButtons";

import { getEverythingQueryString } from "../../components/search-bar/search-filter-query-strings";
import { getBasicQueryString } from "../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: t => t("adc-search:Search Everything"),
    placeholder: t =>
      t(
        "adc-search:Search Product Type, Country, Capacity, Demand Area or Locations"
      ),
    queryBuilder: getEverythingQueryString,
    property: null
  },
  {
    queryKey: "entityId:1",
    label: t => t("adc-search:VIN"),
    placeholder: t => t("adc-search:Search VIN"),
    queryBuilder: getEverythingQueryString,
    property: null
  },
  {
    queryKey: "description:1",
    label: t => t("adc-search:Product Type"),
    placeholder: t => t("adc-search:Search Product Type"),
    queryBuilder: getEverythingQueryString,
    property: null
  },
  {
    queryKey: "demandArea:1",
    label: t => t("adc-search:Demand Area"),
    placeholder: t => t("adc-search:Search Demand Area"),
    queryBuilder: getEverythingQueryString,
    property: null
  },
  {
    queryKey: "location",
    label: t => t("adc-search:Locations"),
    placeholder: t => t("adc-search:Search Locations"),
    queryBuilder: getEverythingQueryString,
    property: null
  }
];

/*
update the filters to be VIN, Product Type, Country, Capacity, Demand Area,
Locations. These Filters show when user clicks Advance Search.
*/

//H2-744: location filter only needs to pass in an array of the location ids to the query
const locationQueryString = (queryParameter, filterValue) => {
  let location_ids_str;

  // Extract the value properties if present (for simple types like integers, fall back to the raw value)
  if (Array.isArray(filterValue)) {
    const location_ids = filterValue.map(val => _.get(val, "id"));
    location_ids_str = "[";
    location_ids_str += location_ids.join(",");
    location_ids_str += "]";
  }

  return getBasicQueryString(queryParameter, location_ids_str);
};

const vinQueryString = (queryParameter, filterValue) => {
  let vin_ids_str;

  // Extract the value properties if present (for simple types like integers, fall back to the raw value)
  if (Array.isArray(filterValue)) {
    const vin_ids = filterValue.map(val => _.get(val, "id"));
    vin_ids_str = "[";
    vin_ids_str += vin_ids
      .map(function(vin_id) {
        // Wrap each element of the array with quotes
        return '"' + vin_id + '"';
      })
      .join(",");
    vin_ids_str += "]";
  }

  return getBasicQueryString(queryParameter, vin_ids_str);
};

export const FILTERS = [
  {
    queryKey: "entityId",
    component: AsyncMultiSelectFilterButton,
    label: t => t("adc-search:VIN"),
    optionsLoader: debouncePromise(
      (solutionId, query) =>
        FinVehicleEntitiesState.actionCreators.searchVINs(query, solutionId),
      500
    ),
    optionsLoaderRequiresSolutionId: true,
    filterOptions: options => options, // Disable client-side filtering
    queryBuilder: vinQueryString
  },
  {
    // H2-305 for the filters producttype is now called description
    queryKey: "description",
    label: t => t("adc-search:Product Type"),
    optionsGetter: props => props.productTypeFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "country",
    label: t => t("adc-search:Country"),
    optionsGetter: props => props.adcCountryOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "capacity",
    label: t => t("adc-search:Capacity Status"),
    optionsGetter: props => props.capacityOptions,
    queryBuilder: getBasicQueryString,
    component: SelectFilterButton
  },
  {
    queryKey: "demandArea",
    label: t => t("adc-search:Demand Area"),
    optionsGetter: props => props.demandAreaOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "locationIds",
    component: AsyncMultiSelectFilterButton,
    label: t => t("adc-search:Locations"),
    optionsLoader: debouncePromise(query => {
      return AdcLocationsState.actionCreators.searchLocationsForFilter(query);
    }, 500),
    filterOptions: options => options, // Disable client-side filtering
    queryBuilder: locationQueryString
  }
];
