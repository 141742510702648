/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import _ from "lodash";
import styled from "@emotion/styled";
import { Button } from "react-bootstrap";
import Loader from "react-loader";
import GeofenceType, {
  isFenceValid,
  getType as getGeofenceType
} from "../geofence-edit/geofence-types";
import {
  getDefaultLocation,
  ADD_LOCATION_ID
} from "../location/LocationsState";
import LocationEditForm from "./LocationEditForm";
import GeofenceBuilderMap from "../map/components/GeofenceBuilderMap";
import Colors from "../../styles/colors";

import { FaRegCheckCircle } from "react-icons/fa";
import { AiFillFire } from "react-icons/ai";

import { FlexDiv, FlexColDiv } from "../../styles/container-elements";
import { isFullAddressGeocodable } from "./AddressValidation";
import { geofenceWithUpdatedCoordinates } from "../geofence-edit/geofence-coordinates";
import DeleteLocationModal from "./DeleteLocationModal";
import DeletePolygonModal from "./DeletePolygonModal";
import UpdateGeofenceTypeModal from "./UpdateGeofenceTypeModal";
import { Privileges } from "../auth/Authorization";
import { geocode } from "../map/utils/geocoding";
import { isCarrier } from "../../routes";

/**
 *
 */
const FlexRow = styled(FlexDiv)({ width: "100%" });

/**
 *
 */
const Section = styled.section({
  display: "flex",
  flex: 0.5,
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100vh - 3.3em)",
  backgroundColor: "white",
  position: "relative"
});

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const HeatmapButton = props => {
  const { onToggle, toggled, loading } = props;
  const ButtonWrapper = styled.div({
    position: "absolute",
    top: 25,
    right: 25,
    transition: "color .3s",
    color: toggled ? "darkorange" : "black",
    ":hover": {
      cursor: "pointer",
      color: "darkorange"
    }
  });
  return (
    <ButtonWrapper toggled={toggled} onClick={!loading ? onToggle : null}>
      <Loader loaded={!loading}>
        <AiFillFire size="40px" />
      </Loader>
    </ButtonWrapper>
  );
};

HeatmapButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired
};

/**
 *
 * @param isValid
 * @param field
 * @return {*}
 * @constructor
 */
const ValidationRow = ({ isValid, field }) => {
  const { t } = useTranslation("location-edit");

  return (
    <FlexRow style={{ marginBottom: ".25em" }}>
      <FaRegCheckCircle
        style={{
          color: isValid ? Colors.highlight.GREEN : "#e0e0e0",
          fontSize: "large"
        }}
      />
      <span css={{ fontSize: "small", marginLeft: ".5em" }}>
        {`${t("location-edit:Must have valid")} ${field}.`}
      </span>
    </FlexRow>
  );
};

ValidationRow.propTypes = {
  field: PropTypes.string.isRequired,
  isValid: PropTypes.bool
};

/**
 *
 * @param existingData
 * @param lat
 * @param lng
 * @return {*}
 */
const mergeLatLng = (existingData, lat, lng) => {
  const data = _.cloneDeep(existingData);
  let geoDiff;

  if (getGeofenceType(data.geofence) === GeofenceType.RADIAL) {
    geoDiff = {
      geometry: {
        coordinates: [lng, lat]
      },
      properties: {
        center: {
          latitude: lat,
          longitude: lng
        }
      }
    };
  } else {
    geoDiff = {
      properties: {
        center: {
          latitude: lat,
          longitude: lng
        }
      }
    };
  }
  _.merge(data, { geofence: geoDiff });
  return data;
};

class LocationEditView extends Component {
  static propTypes = {
    actionStatus: PropTypes.any,
    activeOrganization: PropTypes.object.isRequired,
    addAndLinkLocation: PropTypes.func.isRequired,
    addLocation: PropTypes.func.isRequired,
    clearActionStatus: PropTypes.func.isRequired,
    clearSubdivisions: PropTypes.func.isRequired,
    countries: PropTypes.array,
    deleteLocation: PropTypes.func.isRequired,
    editLocation: PropTypes.func.isRequired,
    fetchCountries: PropTypes.func.isRequired,
    fetchHeatMapData: PropTypes.func.isRequired,
    fetchLocationDetails: PropTypes.any.isRequired,
    fetchSubdivisions: PropTypes.func.isRequired,
    googleMaps: PropTypes.object.isRequired,
    heatmapData: PropTypes.array,
    heatmapIsLoading: PropTypes.bool,
    hereMapsPlatform: PropTypes.object,
    lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    locationID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    locationLinked: PropTypes.bool.isRequired,
    locationSaved: PropTypes.bool.isRequired,
    mapTypeOverride: PropTypes.string,
    pushLocationsScreen: PropTypes.func.isRequired,
    pushShipmentDetailView: PropTypes.func.isRequired,
    selectedLocation: PropTypes.object,
    setLocationLinked: PropTypes.func.isRequired,
    setLocationSaved: PropTypes.func.isRequired,
    subdivisions: PropTypes.array.isRequired,
    subdivisionsCountryCode: PropTypes.string,
    t: PropTypes.func.isRequired,
    unresolvedLocation: PropTypes.bool,
    unresolvedShipment: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      data: getDefaultLocation(),
      isAddressValid: false,
      typingTimeout: 0,
      showHeatmap: false,
      showDeleteLocationModal: false,
      isTracing: false,
      tracePoints: [],
      editingPolygonIndex: null,
      showDeletePolygonModal: false,
      showUpdateGeofenceTypeModal: false
    };

    this.onDragPolygonalGeofence = this.onDragPolygonalGeofence.bind(this);
    this.onDragRadialGeofence = this.onDragRadialGeofence.bind(this);
    this.onDragGeofenceControlPoint = this.onDragGeofenceControlPoint.bind(
      this
    );

    this.addPointToTrace = this.addPointToTrace.bind(this);
    this.addPolygon = this.addPolygon.bind(this);
    this.cancelTracing = this.cancelTracing.bind(this);
    this.createPolygonFromTrace = this.createPolygonFromTrace.bind(this);
    this.deletePointFromPolygon = this.deletePointFromPolygon.bind(this);
    this.updatePolygonPoints = this.updatePolygonPoints.bind(this);
    this.deletePointFromTrace = this.deletePointFromTrace.bind(this);
    this.deletePolygonConfirm = this.deletePolygonConfirm.bind(this);
    this.deletePolygon = this.deletePolygon.bind(this);
    this.showUpdateGeofenceTypeModal = this.showUpdateGeofenceTypeModal.bind(
      this
    );
    this.updateGeofence = this.updateGeofence.bind(this);
    this.updateGeofenceType = this.updateGeofenceType.bind(this);
  }

  componentDidMount() {
    const {
      fetchLocationDetails,
      fetchHeatMapData,
      locationID,
      fetchCountries,
      activeOrganization
    } = this.props;

    this.props.setLocationSaved(false);
    this.props.setLocationLinked(false);

    fetchCountries();
    this.updateSubdivisions();

    // Do not fetch the heatmap if
    // we are in add mode
    if (locationID !== ADD_LOCATION_ID) {
      const dereference = isCarrier(activeOrganization);
      fetchLocationDetails(locationID, dereference);
      fetchHeatMapData(locationID);
    }

    this.setData(this.props);
  }

  componentWillUnmount() {
    const { clearActionStatus } = this.props;
    clearActionStatus();
    this.setState({ data: getDefaultLocation(), isAddressValid: false });
  }

  componentDidUpdate(prevProps) {
    const nextProps = this.props;

    if (nextProps !== prevProps) {
      this.setData(nextProps);
      this.updateSubdivisions();
    }

    // If we have an unresolved location, look for the linked flag to toggle
    if (_.isEmpty(nextProps.unresolvedLocation) === false) {
      if (
        nextProps.locationLinked === true &&
        prevProps.locationLinked === false
      ) {
        // PUSH shipment screen OR locations screen
        const shipmentID = nextProps.unresolvedShipment
          ? nextProps.unresolvedShipment.id
          : null;
        if (shipmentID) {
          this.props.pushShipmentDetailView(shipmentID);
        } else {
          this.props.pushLocationsScreen();
        }
      }
    } else {
      // Normal operation, we will trap on the location saved flag to to
      // return to the previous page
      if (
        nextProps.locationSaved === true &&
        prevProps.locationSaved === false
      ) {
        this.props.pushLocationsScreen();
      }
    }
  }

  getCurrentCountry() {
    const currentSelectedCountry = this.props.selectedLocation
      ? this.props.selectedLocation.country
      : null;
    const currentUnresolvedCountry = this.props.unresolvedLocation
      ? this.props.unresolvedLocation.country
      : null;
    return currentSelectedCountry || currentUnresolvedCountry;
  }

  setData(newProps) {
    const {
      unresolvedLocation,
      fetchSubdivisions,
      clearSubdivisions
    } = this.props;
    const { selectedLocation, actionStatus } = newProps;
    const currentCountry = this.getCurrentCountry();

    let locationData =
      newProps.locationID !== ADD_LOCATION_ID && !_.isEmpty(selectedLocation)
        ? selectedLocation
        : null;

    // If we are adding a new location AND location data is undefined
    // check for an unresolved location element, if so we will
    // use the unresolved data to seed our display
    if (
      newProps.locationID === ADD_LOCATION_ID &&
      locationData === null &&
      !_.isEmpty(unresolvedLocation)
    ) {
      // Extract the necessary fields from this location,
      // do not pull all the fields, this causes conflicts
      // when trying to create
      locationData = {
        address: unresolvedLocation.address,
        city: unresolvedLocation.city,
        code: unresolvedLocation.code,
        country: unresolvedLocation.country,
        geofence: unresolvedLocation.geofence,
        name: unresolvedLocation.name,
        postal_code: unresolvedLocation.postal_code,
        state: unresolvedLocation.state
      };
    }

    // combine codes into a single field for editing
    if (locationData != null) {
      //H1-1037 - some locations do not have a country code saved to them
      if (!locationData.country) {
        // clear subdivision options
        clearSubdivisions();
      } else if (locationData.country !== currentCountry) {
        fetchSubdivisions(locationData.country);
      }

      this.setState({ data: { ...locationData } });
      this.setState({ isAddressValid: isFullAddressGeocodable(locationData) });
    } else if (actionStatus !== "Duplicate_Location") {
      // reset data if we are not adding a new location
      if (newProps.locationID !== ADD_LOCATION_ID) {
        this.setState({ data: getDefaultLocation() });
        this.setState({ isAddressValid: false });
      }
    }
  }

  updateSubdivisions() {
    const {
      subdivisions,
      subdivisionsCountryCode,
      fetchSubdivisions
    } = this.props;
    const currentCountry = this.getCurrentCountry();
    if (!currentCountry) {
      return;
    }
    if (_.isEmpty(subdivisions) || currentCountry !== subdivisionsCountryCode) {
      fetchSubdivisions(currentCountry);
    }
  }

  isDoneTyping() {
    return Date.now() - this.state.lastKeyTimestamp > 1000;
  }

  onDragPolygonalGeofence(polygonIndex, deltaLat, deltaLng) {
    const { data, isTracing } = this.state;
    const { geofence } = data;

    if (isTracing) {
      return;
    }

    const fenceType = getGeofenceType(geofence);
    let newCoords, newGeofence;

    // Using a slice of the coordinates is not enough for poly geofences since they're
    // deeply nested arrays and slice just does a shallow copy.
    let coordsCopy = _.cloneDeep(geofence.geometry.coordinates);

    if (fenceType === GeofenceType.POLYGONAL) {
      newCoords = coordsCopy[0].map(point => {
        return [point[0] - deltaLng, point[1] - deltaLat];
      });
      newGeofence = geofenceWithUpdatedCoordinates(geofence, [newCoords]);
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      newCoords = coordsCopy[polygonIndex][0].map(point => {
        return [point[0] - deltaLng, point[1] - deltaLat];
      });
      coordsCopy[polygonIndex].splice(0, 1, newCoords);
      newGeofence = geofenceWithUpdatedCoordinates(geofence, coordsCopy);
    }
    this.updateGeofence(newGeofence);
  }

  onDragRadialGeofence(deltaLat, deltaLng) {
    const { data } = this.state;
    const { geofence } = data;

    const newCoords = [
      geofence.geometry.coordinates[0] - deltaLng,
      geofence.geometry.coordinates[1] - deltaLat
    ];

    const newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    this.updateGeofence(newGeofence);
  }

  onDragGeofenceControlPoint(polygonIndex, pointIndex, coords) {
    const { data, isTracing } = this.state;
    const { geofence } = data;

    if (isTracing) {
      return;
    }

    const fenceType = getGeofenceType(geofence);
    // Using a slice of the coordinates is not enough for poly geofences since they're
    // deeply nested arrays and slice just does a shallow copy.
    let newCoords = _.cloneDeep(geofence.geometry.coordinates);

    if (fenceType === GeofenceType.POLYGONAL) {
      newCoords[0][pointIndex] = [coords.lng, coords.lat];
      if (pointIndex === 0) {
        newCoords[0][newCoords[0].length - 1] = [coords.lng, coords.lat];
      }
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      newCoords[polygonIndex][0][pointIndex] = [coords.lng, coords.lat];
      if (pointIndex === 0) {
        newCoords[polygonIndex][0][newCoords[polygonIndex][0].length - 1] = [
          coords.lng,
          coords.lat
        ];
      }
    }
    const newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    this.updateGeofence(newGeofence);
  }

  deletePointFromPolygon(polygonIndex, pointIndex) {
    const { geofence } = this.state.data;
    const fenceType = getGeofenceType(geofence);

    // Using a slice of the coordinates is not enough for poly geofences since they're
    // deeply nested arrays and slice just does a shallow copy.
    let coordsCopy = _.cloneDeep(geofence.geometry.coordinates);

    // Prompt the user to delete this polygon if less than 3 points would remain
    // after deleting the last one. Remember that we have one extra point at the end
    // mirroring the first one.
    if (fenceType === GeofenceType.POLYGONAL) {
      if (coordsCopy[0].length <= 4) {
        this.deletePolygonConfirm(polygonIndex);
      } else {
        coordsCopy[0].splice(pointIndex, 1);
      }
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      if (coordsCopy[polygonIndex][0].length <= 4) {
        this.deletePolygonConfirm(polygonIndex);
      } else {
        coordsCopy[polygonIndex][0].splice(pointIndex, 1);
      }
    }

    const newGeofence = geofenceWithUpdatedCoordinates(geofence, coordsCopy);
    this.updateGeofence(newGeofence);
  }

  updatePolygonPoints(polygonIndex, newPoints) {
    const { geofence } = this.state.data;
    const fenceType = getGeofenceType(geofence);

    // Using a slice of the coordinates is not enough for poly geofences since they're
    // deeply nested arrays and slice just does a shallow copy.
    let newCoords = _.cloneDeep(geofence.geometry.coordinates);

    if (fenceType === GeofenceType.POLYGONAL) {
      newCoords[0] = newPoints;
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      newCoords[polygonIndex][0] = newPoints;
    }

    const newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    this.updateGeofence(newGeofence);
  }

  addPointToTrace(position) {
    const { isTracing, tracePoints } = this.state;

    if (isTracing) {
      let newPoints = tracePoints || [];
      newPoints.push([position.lng, position.lat]);
      this.setState({ tracePoints: newPoints });
    }
  }

  deletePointFromTrace(pointIndex) {
    const { isTracing, tracePoints } = this.state;

    if (isTracing) {
      let newPoints = tracePoints.slice();
      newPoints.splice(pointIndex, 1);
      this.setState({ tracePoints: newPoints });
    }
  }

  createPolygonFromTrace() {
    const { data, isTracing, tracePoints } = this.state;
    const { geofence } = data;

    if (isTracing && tracePoints.length > 2) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let newCoords = _.cloneDeep(geofence.geometry.coordinates);
      let newGeofence;

      const fenceType = getGeofenceType(geofence);
      if (fenceType === GeofenceType.POLYGONAL) {
        // Preserve first-last mirror
        newCoords = [...tracePoints, tracePoints[0]];
        newGeofence = geofenceWithUpdatedCoordinates(geofence, [newCoords]);
      } else if (fenceType === GeofenceType.MULTIPOLYGON) {
        let lastPoly = newCoords[newCoords.length - 1][0];
        // Preserve first-last mirror
        lastPoly.push(...tracePoints, tracePoints[0]);
        newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
      }

      this.setState({
        isTracing: false,
        editingPolygonIndex: null,
        tracePoints: [],
        data: { ...data, geofence: newGeofence }
      });
    }
  }

  toggleEditGeofence() {
    const { isTracing } = this.state;
    this.setState({ isTracing: !isTracing });
  }

  updateGeofence(newGeofence) {
    const { authorization } = this.props;

    if (authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS])) {
      const { data } = this.state;
      const mergedData = {
        ...data,
        geofence: newGeofence
      };
      this.setState({ data: mergedData });
    }
  }

  addPolygon() {
    const { geofence } = this.state.data;
    const fenceType = getGeofenceType(geofence);

    let newGeofence;
    if (fenceType === GeofenceType.POLYGONAL) {
      newGeofence = geofenceWithUpdatedCoordinates(geofence, [[[]]]);
    } else if (fenceType === GeofenceType.MULTIPOLYGON) {
      // Using a slice of the coordinates is not enough for poly geofences since they're
      // deeply nested arrays and slice just does a shallow copy.
      let newCoords = _.cloneDeep(geofence.geometry.coordinates);
      newCoords.push([[]]);
      newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    }

    this.setState({
      editingPolygonIndex: newGeofence.geometry.coordinates.length - 1
    });
    this.updateGeofence(newGeofence);
    this.toggleEditGeofence();
  }

  deletePolygonConfirm(polygonIndex) {
    this.setState({
      showDeletePolygonModal: true,
      editingPolygonIndex: polygonIndex
    });
  }

  deletePolygon(polygonIndex) {
    const { geofence } = this.state.data;

    // Using a slice of the coordinates is not enough for poly geofences since they're
    // deeply nested arrays and slice just does a shallow copy.
    let newCoords = _.cloneDeep(geofence.geometry.coordinates);
    newCoords.splice(polygonIndex, 1);

    const newGeofence = geofenceWithUpdatedCoordinates(geofence, newCoords);
    this.updateGeofence(newGeofence);

    // Close the Delete poly modal.
    this.setState({ showDeletePolygonModal: false });
  }

  showUpdateGeofenceTypeModal() {
    this.setState({ showUpdateGeofenceTypeModal: true });
  }

  updateGeofenceType(newType) {
    this.setState({ showUpdateGeofenceTypeModal: false });
    // Type didn't change, bail.
    if (!newType) {
      return;
    }
    const { locationID } = this.props;
    const { geofence } = this.state.data;

    const fenceType = getGeofenceType(geofence);

    let typeConverterName;

    if (newType.name.toLowerCase() !== "multipolygon") {
      typeConverterName = `to${_.capitalize(newType.name)}`;
    } else {
      typeConverterName = "toMultiPolygon";
    }

    const typeConverter = fenceType[typeConverterName];

    let newGeofence = typeConverter(geofence);
    const newFenceType = getGeofenceType(newGeofence);

    // If this is a new location of poly type, clear the dummy coordinates and enable
    // tracing mode.
    if (
      locationID === ADD_LOCATION_ID &&
      (newFenceType === GeofenceType.POLYGONAL ||
        newFenceType === GeofenceType.MULTIPOLYGON)
    ) {
      this.setState({ isTracing: true });
      const emptyCoords =
        newFenceType === GeofenceType.MULTIPOLYGON ? [[[]]] : [[]];
      newGeofence.geometry.coordinates = emptyCoords;
      this.updateGeofence(newGeofence, () => {
        this.addPolygon();
      });
    } else {
      this.updateGeofence(newGeofence);
    }
  }

  cancelTracing() {
    const { data } = this.state;
    this.setState({
      isTracing: false,
      tracePoints: [],
      editingPolygonIndex: null
    });
    this.deletePolygon(data.geofence.geometry.coordinates.length - 1);
  }

  render() {
    const {
      t,
      authorization,
      locationID,
      addLocation,
      addAndLinkLocation,
      editLocation,
      deleteLocation,
      pushLocationsScreen,
      lads,
      selectedLocation,
      hereMapsPlatform,
      googleMaps,
      heatmapData,
      heatmapIsLoading,
      actionStatus,
      countries,
      subdivisions,
      fetchSubdivisions,
      activeOrganization,
      mapTypeOverride
    } = this.props;

    const {
      data,
      showHeatmap,
      showDeleteLocationModal,
      showDeletePolygonModal,
      isTracing,
      tracePoints,
      editingPolygonIndex
    } = this.state;

    let selectedLad = _.get(selectedLocation, "lad");
    if (
      selectedLad &&
      selectedLad.id &&
      lads &&
      selectedLad.name &&
      selectedLad.name !== "Unclassified"
    ) {
      let foundLad;
      if (Array.isArray(lads)) {
        foundLad = lads.find(l => Number(l.id) === Number(selectedLad.id));
      } else {
        const keys = Object.keys(lads);
        let selectedLadId = selectedLad.id;
        for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
          foundLad = lads[keys[keyIndex]].find(
            l => Number(l.id) === Number(selectedLadId)
          );
        }
      }

      const defaultName = foundLad ? foundLad.default_name : "";
      selectedLad = { ...selectedLad, default_name: defaultName };
    }

    // Use a placeholder geofence while the location details are loading.
    const { geofence } =
      !data.isLoadingDetails && isFenceValid(data.geofence)
        ? data
        : getDefaultLocation();

    let mapLocationData = _.isEmpty(data) ? selectedLocation : data;
    if (mapLocationData.isLoadingDetails) {
      mapLocationData = null;
    } else if (!mapLocationData.latitude && mapLocationData.position) {
      // Adapt selectedLocation to the expected interface
      mapLocationData.latitude = mapLocationData.position.lat;
      mapLocationData.longitude = mapLocationData.position.lng;
    }

    const isNameValid = _.isEmpty(data.name) ? false : data.name.length > 2;
    const isLadValid = !_.isEmpty(data.lad);
    const isCodeValid = data.code && data.code.length > 0;
    const fenceType = getGeofenceType(geofence);
    const isGeofenceValid = fenceType.isValid(geofence);
    const isLocationValid =
      isNameValid && isLadValid && isCodeValid && isGeofenceValid;
    const isAddressValid = isFullAddressGeocodable(data);
    const isNewLocation = locationID === ADD_LOCATION_ID;

    const isCarrierOrg = isCarrier(activeOrganization);
    const readOnly =
      isCarrierOrg ||
      !authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS]);

    const doGeocode = (mergedData, addressUpdated = false) => {
      // H1-82: Geocode the address for new locations and anytime the address is updated
      if (
        (!isNewLocation && !addressUpdated) ||
        (addressUpdated && fenceType !== GeofenceType.RADIAL)
      ) {
        return;
      }

      geocode(
        activeOrganization,
        mapTypeOverride,
        hereMapsPlatform,
        googleMaps,
        mergedData,
        ({ lat, lng }) => {
          if (!(_.isUndefined(lat) || _.isUndefined(lng))) {
            const dataWithLatLng = mergeLatLng(mergedData, lat, lng);
            this.setState({
              data: dataWithLatLng
            });
          }
        },
        err => console.error(err)
      );
    };

    const fieldChangesGeocode = field => {
      return [
        "address",
        "address2",
        "city",
        "country",
        "state",
        "postal_code"
      ].includes(field);
    };

    const updateField = (field, val) => {
      const mergedData = { ...data, [field]: val };
      const isAddressValid = isFullAddressGeocodable(mergedData);
      this.setState({
        isAddressValid,
        data: mergedData
      });

      if (field === "country") {
        fetchSubdivisions(val);

        // if editing an existing location, we need to reset country and state fields when selecting a new one
        if (selectedLocation) {
          selectedLocation.country = val;
          selectedLocation.state = "";
        }
      }

      if (field === "state" && selectedLocation) {
        selectedLocation.state = val;
      }

      if (isAddressValid) {
        if (this.state.typingTimeout) {
          clearTimeout(this.state.typingTimeout);
        }
        if (fieldChangesGeocode(field)) {
          this.setState({
            typingTimeout: setTimeout(() => doGeocode(mergedData, true), 500)
          });
        }
      }
    };

    return (
      <div css={{ display: "flex", flexDirection: "row" }}>
        <Section
          style={{
            borderRight: "1px solid #ddd",
            position: "relative",
            overflow: "scroll",
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <FlexColDiv
            style={{
              position: "absolute",
              justifyContent: "space-between",
              minHeight: "100%",
              width: "100%"
            }}
          >
            <LocationEditForm
              data={data}
              lads={lads}
              updateField={updateField}
              updateGeofence={this.updateGeofence}
              updateGeofenceType={this.updateGeofenceType}
              addressIsValid={isAddressValid}
              isReadOnly={readOnly}
              isTracing={isTracing}
              addPolygon={this.addPolygon}
              cancelTracing={this.cancelTracing}
              editingPolygonIndex={editingPolygonIndex}
              deletePolygon={this.deletePolygonConfirm}
              showUpdateGeofenceTypeModal={this.showUpdateGeofenceTypeModal}
              countries={countries}
              subdivisions={subdivisions}
              pushLocationsScreen={pushLocationsScreen}
              isCarrierOrg={isCarrierOrg}
            />

            <FlexColDiv
              css={{
                backgroundColor: Colors.background.LIGHT_GRAY,
                color: Colors.text.DARK_BLUE,
                padding: "2em",
                justifyContent: "flex-end"
              }}
            >
              {actionStatus && actionStatus === "Duplicate_Location" ? (
                <div
                  css={{
                    color: Colors.highlight.RED,
                    marginTop: ".5em",
                    marginLeft: ".1em"
                  }}
                >
                  {t(
                    "Unable to add new location, location code already exists. Please change the location code and resubmit."
                  )}
                </div>
              ) : null}
              {!readOnly && (
                <ValidationRow isValid={isNameValid} field="name" />
              )}
              {!readOnly && (
                <ValidationRow isValid={isLadValid} field="location type" />
              )}
              {!readOnly && (
                <ValidationRow isValid={isCodeValid} field="location code" />
              )}
              {!readOnly && (
                <ValidationRow isValid={isGeofenceValid} field="geofence" />
              )}
              <FlexRow
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "1em"
                }}
              >
                {locationID !== ADD_LOCATION_ID && !readOnly && (
                  <Button
                    variant="danger"
                    onClick={() =>
                      this.setState({ showDeleteLocationModal: true })
                    }
                  >
                    {t("location-edit:Delete Location")}
                  </Button>
                )}
                {!readOnly && (
                  <div>
                    <Button
                      variant="default"
                      style={{
                        backgroundColor: "white",
                        marginRight: "0.5em",
                        color: "#333",
                        border: "1px solid #ccc"
                      }}
                      className="mr-1"
                      onClick={() => pushLocationsScreen()}
                    >
                      {t("location-edit:Cancel")}
                    </Button>

                    {isNewLocation && (
                      <Button
                        variant="success"
                        disabled={!isLocationValid}
                        onClick={() => {
                          // If we were called from the resolve location
                          // screen we need to perform an add and link
                          if (
                            _.isEmpty(this.props.unresolvedLocation) === false
                          ) {
                            addAndLinkLocation(
                              data,
                              this.props.unresolvedLocation,
                              this.props.unresolvedShipment
                            );
                          } else {
                            addLocation(data);
                          }
                        }}
                      >
                        {t("location-edit:Add Location")}
                      </Button>
                    )}

                    {!isNewLocation && !readOnly && (
                      <Button
                        variant="success"
                        disabled={!isLocationValid}
                        onClick={() => {
                          editLocation(locationID, data);
                        }}
                      >
                        {t("location-edit:Update Location")}
                      </Button>
                    )}
                  </div>
                )}
              </FlexRow>
            </FlexColDiv>
          </FlexColDiv>
        </Section>
        <Section>
          <GeofenceBuilderMap
            showHeatmap={showHeatmap}
            heatmapCoords={heatmapData}
            onDragGeofenceControlPoint={this.onDragGeofenceControlPoint}
            onDragPolygonalGeofence={this.onDragPolygonalGeofence}
            onDragRadialGeofence={this.onDragRadialGeofence}
            selectedLocation={mapLocationData}
            mapLocations={mapLocationData ? [mapLocationData] : []}
            addPointToTrace={this.addPointToTrace}
            deletePointFromPolygon={this.deletePointFromPolygon}
            updatePolygonPoints={this.updatePolygonPoints}
            deletePointFromTrace={this.deletePointFromTrace}
            selectedLad={selectedLad}
            enableDraggingGeofence={!readOnly}
            enableGeofenceBuilder
            isTracing={isTracing}
            isNewLocation={isNewLocation}
            tracePoints={tracePoints}
            onPolygonDrawEnd={this.createPolygonFromTrace}
            useBoxChiclets
          />
          {isTracing && (
            <div
              style={{
                background: "rgba(251, 210, 14, 0.5)",
                padding: "0.5em 0 0.5em 0",
                color: "#364251",
                fontWeight: "bold",
                position: "absolute",
                top: 0,
                right: 0,
                width: "50%",
                textAlign: "center",
                fontSize: "1.0em"
              }}
            >
              {t(
                "Click on map to draw new geofence; click on first point again to complete"
              )}
            </div>
          )}
          <HeatmapButton
            toggled={showHeatmap}
            onToggle={() => this.setState({ showHeatmap: !showHeatmap })}
            loading={heatmapIsLoading}
          />
        </Section>
        <DeleteLocationModal
          show={showDeleteLocationModal}
          hide={() => this.setState({ showDeleteLocationModal: false })}
          handleConfirm={() => {
            deleteLocation(locationID);
            pushLocationsScreen();
          }}
        />
        <DeletePolygonModal
          show={showDeletePolygonModal}
          hide={() =>
            this.setState({
              showDeletePolygonModal: false,
              editingPolygonIndex: null
            })
          }
          handleConfirm={() => {
            this.deletePolygon(editingPolygonIndex);
          }}
        />
        <UpdateGeofenceTypeModal
          show={this.state.showUpdateGeofenceTypeModal}
          fenceType={fenceType}
          hide={() =>
            this.setState({
              showUpdateGeofenceTypeModal: false
            })
          }
          handleConfirm={this.updateGeofenceType}
        />
      </div>
    );
  }
}

export default withTranslation(["location-edit"])(LocationEditView);
