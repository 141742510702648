/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import "react-tabs/style/react-tabs.css";
import ReactTable from "react-table";
import { Translation, useTranslation } from "react-i18next";
import { LadChicletCSS as Chiclet } from "../../../components/chiclets";
import Colors from "../../../styles/colors";
import { getLadName } from "../../../components/lads";
import LadLabelForm from "./LadLabelForm";
import { scrollToTableTop } from "../../../utils/table-utils";

const CHICLET_HEIGHT = 60;

const LocationTypeCellRenderer = props => {
  const { updateLadLabel, ...lad } = props.value;
  const originalLabel = getLadName(lad);
  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <div css={{ marginLeft: "1em" }}>
        <Chiclet
          lad={lad}
          height={CHICLET_HEIGHT}
          width={CHICLET_HEIGHT}
          showLadLabel
        />
      </div>
      <LadLabelForm
        lad={lad}
        height={CHICLET_HEIGHT}
        originalLabel={originalLabel}
        updateLadLabel={updateLadLabel}
      />
    </div>
  );
};

LocationTypeCellRenderer.propTypes = {
  value: PropTypes.object
};

const LadDefinitionCellRenderer = ({ value }) => {
  const definitionKey = value.replace(".", "");
  const color = Colors.editLadsLabelText;

  return (
    <Translation ns="lads">
      {t => (
        <div
          css={{ color: { color }, whiteSpace: "normal", paddingLeft: "1em" }}
        >
          {t(`lads:${definitionKey}`)}
        </div>
      )}
    </Translation>
  );
};

LadDefinitionCellRenderer.propTypes = {
  value: PropTypes.string
};

const ColHeadThStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  textAlign: "left",
  color: Colors.editLadsLabelText,
  backgroundColor: "white",
  height: "50px",
  paddingLeft: "1em"
};

const connectedLadEditColumns = (t, updateLadLabel) => {
  return [
    {
      Header: t("lads:Location types"),
      id: "code-name-chiclet",
      accessor: d => ({
        updateLadLabel,
        ...d
      }),
      Cell: LocationTypeCellRenderer
    },

    {
      Header: t("lads:Definition"),
      accessor: "description",
      Cell: LadDefinitionCellRenderer
    }
  ];
};

const LadTable = ({ data, updateLadLabel }) => {
  const { t } = useTranslation("lads");
  const columns = connectedLadEditColumns(t, updateLadLabel);

  return (
    <ReactTable
      className="customtable fixedpaginationtable"
      data={data}
      columns={columns}
      getTheadThProps={() => ({ style: ColHeadThStyles })}
      onPageChange={page => {
        scrollToTableTop();
      }}
    />
  );
};

LadTable.propTypes = {
  data: PropTypes.array.isRequired,
  updateLadLabel: PropTypes.func.isRequired
};

export default LadTable;
