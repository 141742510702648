import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import { connectRoutes } from "redux-first-router";
import { createBrowserHistory } from "history";
import qs from "qs";

import { routesMap } from "../routes";
import AuthenticationReducer from "../modules/auth/AuthenticationState";
import EndToEndReducer from "../modules/end-to-end/EndToEndState";
import EventsReducer from "../modules/events/EventsState";
import LocationsReducer from "../modules/location/LocationsState";
import shipmentsStatusState from "../modules/shipment-status/ShipmentsState";
import shipmentAiState from "../modules/shipment-ai/ShipmentAiState";
import EditShipmentState from "../modules/shipment-detail/EditShipmentState";
import DomainDataState from "../modules/domain-data/DomainDataState";
import FinVehicleDomainDataState from "../modules/domain-data/FinVehicleDomainData";
import LadsState from "../modules/lads/LadsState";
import ShipmentSearchBarState from "../modules/shipment-search/ShipmentSearchBarState";
import OrganizationsReducer from "../modules/organizations/OrganizationsState";
import MapState from "../modules/map/MapState";
import LocationMatchingState from "../modules/location-resolution-edit/LocationMatchingState";
import HeaderReducer from "../modules/header-bar/HeaderBarState";
import ProfileState from "../modules/profile/ProfileState";
import CreateShipmentState from "../modules/create-shipment/CreateShipmentState";
import ReportsState from "../modules/reports/ReportsState";
import FinVehicleSearchBarState from "../modules/fv-search/FinVehicleSearchBarState";
import FinVehicleEntitiesState from "../modules/fv-dashboard/FinVehicleEntitiesState";
import FinVehicleEntityDetailsState from "../modules/fv-vin-details/FinVehicleEntityDetailsState";
import FinVehicleExceptionsState from "../modules/fv-dashboard/FinVehicleExceptionsState";
import FinVehicleHoldsState from "../modules/fv-dashboard/FinVehicleHoldsState";
import FinVehicleProductTypeState from "../modules/fv-search/FinVehicleProductTypeState";
import FinVehicleSavedSearchState from "../modules/fv-search/FinVehicleSavedSearchState";
import FinVehicleTripLegState from "../modules/fv-vin-details/FinVehicleTripLegState";
import ShipmentStopsSearchBarState from "../modules/create-shipment/ShipmentStopsSearchBarState";
import DocumentationState from "../modules/documentation/DocumentationState";
import ApiLogsSearchBarState from "../modules/documentation/api-logs/ApiLogsSearchBarState";

// Metal Tracking
import MetalTrackingSearchBarState from "../modules/mt-search/MetalTrackingSearchBarState";
import MetalTrackingSavedSearchState from "../modules/mt-search/MetalTrackingSavedSearchState";
import MetalTrackingDomainDataState from "../modules/domain-data/MetalTrackingDomainData";

// ADC Dashboard
import AdcSearchBarState from "../modules/adc-search/AdcSearchBarState";
import AdcDomainDataState from "../modules/domain-data/AdcDomainData";
import AdcEntitiesState from "../modules/adc-dashboard/AdcEntitiesState";
import AdcLocationsState from "../modules/adc-dashboard/AdcLocationsState";
import AdcSavedSearchState from "../modules/adc-search/AdcSavedSearchState";
import AdcDetailsVinSearchBarState from "../modules/adc-details/components/vin-search/AdcDetailsVinSearchBarState";

import ConnectedCarSearchBarState from "../modules/connected-car/ConnectedCarSearchBarState";
import ConnectedCarDomainDataState from "../modules/domain-data/ConnectedCarDomainData";
import LocationSearchBarState from "../modules/location-search/LocationSearchBarState";
import UsersReducer from "../modules/users/UsersState";
import RolesState from "../modules/roles/RolesState";
import CallbackReducer from "../modules/callback/CallbackState";
import ShipmentSavedSearchState from "../modules/shipment-search/ShipmentSavedSearchState";
import PartnerDashboardSearchBarState from "../modules/partner-dashboard/PartnerDashboardSearchBarState";
import PartnerMilestoneDomainDataState from "../modules/partner-dashboard/PartnerMilestoneDomainDataState";
import StatusUpdateDetailState from "../modules/partner-dashboard/StatusUpdateDetailState";
import CreateMilestoneState from "../modules/partner-create-milestone/CreateMilestoneState";
import { trackWithGoogleAnalytics } from "../trackers/googleanalytics";
import ApplicationConfig from "../config";

// where redux-first-router keeps the routing state
export const ROUTER_LOCATION_KEY = "location";

const appReducers = {
  auth: AuthenticationReducer,
  callback: CallbackReducer,

  endToEnd: EndToEndReducer,
  events: EventsReducer,
  locations: LocationsReducer,
  organizations: OrganizationsReducer,
  //suppliers: SuppliersState,
  header: HeaderReducer,
  [ProfileState.mountPoint]: ProfileState.reducer,
  [CreateShipmentState.mountPoint]: CreateShipmentState.reducer,

  users: UsersReducer,
  [RolesState.mountPoint]: RolesState.reducer,

  [MapState.mountPoint]: MapState.reducer,
  [shipmentsStatusState.mountPoint]: shipmentsStatusState.reducer,
  [shipmentAiState.mountPoint]: shipmentAiState.reducer,
  [EditShipmentState.mountPoint]: EditShipmentState.reducer,
  [LadsState.mountPoint]: LadsState.reducer,
  [ShipmentSearchBarState.mountPoint]: ShipmentSearchBarState.reducer,
  [ShipmentSavedSearchState.mountPoint]: ShipmentSavedSearchState.reducer,
  [DomainDataState.mountPoint]: DomainDataState.reducer,
  [FinVehicleDomainDataState.mountPoint]: FinVehicleDomainDataState.reducer,
  [LocationMatchingState.mountPoint]: LocationMatchingState.reducer,
  [ReportsState.mountPoint]: ReportsState.reducer,

  // Finished Vehicle
  [FinVehicleSearchBarState.mountPoint]: FinVehicleSearchBarState.reducer,
  [FinVehicleEntitiesState.mountPoint]: FinVehicleEntitiesState.reducer,
  [FinVehicleEntityDetailsState.mountPoint]:
    FinVehicleEntityDetailsState.reducer,
  [FinVehicleExceptionsState.mountPoint]: FinVehicleExceptionsState.reducer,
  [FinVehicleHoldsState.mountPoint]: FinVehicleHoldsState.reducer,
  [FinVehicleProductTypeState.mountPoint]: FinVehicleProductTypeState.reducer,
  [FinVehicleSavedSearchState.mountPoint]: FinVehicleSavedSearchState.reducer,
  [FinVehicleTripLegState.mountPoint]: FinVehicleTripLegState.reducer,

  // Metal Tracking
  [MetalTrackingDomainDataState.mountPoint]:
    MetalTrackingDomainDataState.reducer,
  [MetalTrackingSearchBarState.mountPoint]: MetalTrackingSearchBarState.reducer,
  [MetalTrackingSavedSearchState.mountPoint]:
    MetalTrackingSavedSearchState.reducer,
  // ADC
  [AdcDomainDataState.mountPoint]: AdcDomainDataState.reducer,
  [AdcSearchBarState.mountPoint]: AdcSearchBarState.reducer,
  [AdcEntitiesState.mountPoint]: AdcEntitiesState.reducer,
  [AdcLocationsState.mountPoint]: AdcLocationsState.reducer,
  [AdcSavedSearchState.mountPoint]: AdcSavedSearchState.reducer,
  [AdcDetailsVinSearchBarState.mountPoint]: AdcDetailsVinSearchBarState.reducer,

  [ConnectedCarDomainDataState.mountPoint]: ConnectedCarDomainDataState.reducer,
  [ConnectedCarSearchBarState.mountPoint]: ConnectedCarSearchBarState.reducer,

  [LocationSearchBarState.mountPoint]: LocationSearchBarState.reducer,
  [ShipmentStopsSearchBarState.mountPoint]: ShipmentStopsSearchBarState.reducer,

  [DocumentationState.mountPoint]: DocumentationState.reducer,
  [ApiLogsSearchBarState.mountPoint]: ApiLogsSearchBarState.reducer,

  [PartnerDashboardSearchBarState.mountPoint]:
    PartnerDashboardSearchBarState.reducer,
  [PartnerMilestoneDomainDataState.mountPoint]:
    PartnerMilestoneDomainDataState.reducer,
  [StatusUpdateDetailState.mountPoint]: StatusUpdateDetailState.reducer,

  [CreateMilestoneState.mountPoint]: CreateMilestoneState.reducer
};

export default function configureStore(preloadedState) {
  const { reducer, middleware, enhancer } = connectRoutes(routesMap, {
    createHistory: createBrowserHistory,
    querySerializer: qs
  });

  let appMiddlewares = [thunkMiddleware];
  if (!!ApplicationConfig.LOG_REDUX_ACTIONS) {
    appMiddlewares.push(createLogger());
  }

  const appliedMiddlewares = applyMiddleware(
    middleware,
    ...appMiddlewares,
    trackWithGoogleAnalytics
  );
  const enhancers = composeWithDevTools(enhancer, appliedMiddlewares);
  const reducers = combineReducers({
    [ROUTER_LOCATION_KEY]: reducer,
    ...appReducers
  });
  const store = createStore(reducers, preloadedState, enhancers);
  return {
    store
  };
}
