/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import { getAuthorization } from "../../auth/AuthorizationSelectors";
import { getActiveOrganization } from "../../organizations/OrganizationsState";
import RolesState from "../../roles/RolesState";
import { addUser, clearActionStatus } from "../UsersState";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormButton,
  ReadOnlyInput,
  TextInput,
  SelectInput,
  FormAlert
} from "../../../components/modal-elems";
import { filterRoleTypes } from "./modal-utils";
import Colors from "../../../styles/colors";
import { FlexRowDiv } from "../../../styles/container-elements";
import { isValidEmail } from "../../../utils/validation-utils";

const isValidForm = ({ email, confirmEmail, selectedRoleTypes }) => {
  return isValidEmail(email) &&
    email === confirmEmail &&
    selectedRoleTypes &&
    selectedRoleTypes.length > 0
    ? true
    : false;
};

const initUser = {
  email: "",
  confirmEmail: "",
  selectedRoleTypes: null
};

const AddUserModal = ({
  addUser,
  authorization,
  activeOrganization,
  actionStatus,
  clearActionStatus,
  hide,
  roles,
  show,
  t
}) => {
  const [user, setUser] = useState(initUser);

  const org = useMemo(
    () =>
      activeOrganization && activeOrganization.org_name
        ? activeOrganization.org_name
        : "",
    [activeOrganization]
  );

  const roleTypes = useMemo(() => {
    return roles ? filterRoleTypes(roles, authorization.isFvAdmin()) : [];
  }, [authorization, roles]);

  useEffect(() => {
    if (actionStatus && actionStatus.includes("USER_ADDED")) {
      setTimeout(() => {
        clearActionStatus();
        clearForm();
        hide();
      }, 3000);
    }
  }, [actionStatus, hide, clearActionStatus]);

  const inputHandler = value => {
    if (actionStatus) {
      clearActionStatus();
    }
    setUser(prevState => {
      return { ...prevState, ...value };
    });
  };

  const clearForm = () => {
    setUser(initUser);
  };

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        clearForm();
        hide();
      }}
    >
      <ModalHeader title={t("users:Add New User")} />
      <ModalBody>
        {actionStatus && actionStatus === "USER_ADDED" ? (
          <FormAlert
            alertType={"SUCCESS"}
            alert={`${t("users:An account with email")} ${user.email} ${t(
              "users:has been created"
            )}`}
          />
        ) : null}
        <FlexRowDiv>
          <ReadOnlyInput
            style={{ marginTop: 0 }}
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <TextInput
            changeHandler={inputHandler}
            label={t("users:Email")}
            prop="email"
            value={user.email}
          />
          <TextInput
            style={{ marginLeft: ".25em" }}
            changeHandler={inputHandler}
            label={t("users:Confirm Email")}
            prop="confirmEmail"
            value={user.confirmEmail}
          />
        </FlexRowDiv>
        {actionStatus && actionStatus === "Duplicate_User" ? (
          <FormAlert
            alertType={"FAIL"}
            alert={`${t("users:An account with email")} ${user.email} ${t(
              "users:already exists"
            )}`}
          />
        ) : null}
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <SelectInput
            label={t("users:User Roles")}
            options={roleTypes.map(r => {
              return {
                value: r.id,
                label: t(`users:${r.friendlyName}`)
              };
            })}
            changeHandler={inputHandler}
            prop="selectedRoleTypes"
            value={user.selectedRoleTypes}
          />
        </FlexRowDiv>
      </ModalBody>
      <ModalFooter>
        <FormButton
          variant="default"
          style={{
            marginRight: ".5em",
            border: "1px solid #ccc"
          }}
          label={t("users:Cancel")}
          clickHandler={() => {
            clearForm();
            hide();
          }}
        />
        <FormButton
          disabled={!isValidForm(user)}
          actionType="ACTION"
          label={t("users:Create")}
          variant="success"
          style={{ border: "1px solid #ccc" }}
          clickHandler={() => {
            const roleIds = user.selectedRoleTypes.map(r => r.value);
            const payload = {
              email: user.email,
              role_ids: roleIds
            };
            addUser(payload);
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

AddUserModal.propTypes = {
  addUser: PropTypes.func.isRequired,
  authorization: PropTypes.object.isRequired,
  activeOrganization: PropTypes.object.isRequired,
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    authorization: getAuthorization(state),
    activeOrganization: getActiveOrganization(state),
    actionStatus: state.users.actionStatus,
    roles: RolesState.selectors.getRoles(state)
  };
}

const mapDispatchToProps = {
  addUser,
  clearActionStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["users"])(AddUserModal));
