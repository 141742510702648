/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import Link from "redux-first-router-link";
import NavItem from "./NavItem";

const propTypes = {
  actionType: PropTypes.string,
  icon: PropTypes.string,
  isActiveScreen: PropTypes.bool,
  sidebarMinimize: PropTypes.bool,
  title: PropTypes.string,
  to: PropTypes.string
};

const NavLink = ({
  to,
  actionType,
  icon,
  title,
  sidebarMinimize,
  isActiveScreen
}) => {
  const linkTo = to || { type: actionType };
  return (
    <Link to={linkTo} data-qa={`link-${actionType}`}>
      <NavItem
        screen={{ title }}
        isActiveNavItem={isActiveScreen}
        icon={icon}
        sidebarMinimize={sidebarMinimize}
      />
    </Link>
  );
};

NavLink.propTypes = propTypes;

export default NavLink;
