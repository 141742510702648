// eslint-disable-next-line
import React from "react";
import { BoldText, SmallText } from "../../../styles/container-elements";
import CountryName from "../../../modules/location/components/CountryName";
import PropTypes from "prop-types";

const LocationCell = ({
  styles = {},
  location,
  showAddress,
  // By default, we this uses td, but sometimes we want to change this parent
  WrappingComponent = ({ children, ...rest }) => <td {...rest}>{children}</td>
}) => {
  return (
    <WrappingComponent style={styles}>
      <BoldText>{location.code}</BoldText>
      <div>{location.name}</div>
      {showAddress ? (
        <SmallText>
          <div>{location.address}</div>
          <div>
            {location.city}
            {location.city && location.state ? ", " : ""}
            {location.state}
            {location.postalCode && " "}
            {location.postalCode}
          </div>
          {location.country ? (
            <CountryName countryCode={location.country} />
          ) : null}
        </SmallText>
      ) : null}
    </WrappingComponent>
  );
};

LocationCell.propTypes = {
  styles: PropTypes.object,
  location: PropTypes.object,
  showAddress: PropTypes.bool
};

export default LocationCell;
