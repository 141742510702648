/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DefinitionListItem from "../../../../components/definition-list/DefinitionListItem";
import DemandAreaList from "./DemandAreaList";

const DemandAreaListItem = ({ location }) => {
  const { t } = useTranslation("adc-details");

  const list = location.demand_areas.map(d => d.name);

  return (
    <DefinitionListItem title={t("adc-details:Demand Areas")}>
      <DemandAreaList list={list} />
    </DefinitionListItem>
  );
};

DemandAreaListItem.propTypes = {
  location: PropTypes.object.isRequired
};

export default DemandAreaListItem;
