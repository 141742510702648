import { connect } from "react-redux";
import ReportBuilderView from "./ReportBuilderView";
import ReportsState from "./ReportsState";

function mapStateToProps(state) {
  const { getReports, getIsLoading } = ReportsState.selectors;

  return {
    reports: getReports(state),
    isLoading: getIsLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  const { fetchReports } = ReportsState.actionCreators;
  return {
    pushReportDetailScreen: reportId =>
      dispatch({
        type: "REPORT_DETAIL",
        payload: { reportId: reportId }
      }),
    fetchReports: () => dispatch(fetchReports())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportBuilderView);
