import _ from "lodash";
import { createSelector } from "reselect";
import axios from "axios";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";
import apiUrl from "../../api-url";
import { getLadName } from "../../components/lads";
import { alfBy } from "../../components/selectors";

const STORE_MOUNT_POINT = "lads";

const duck = buildFetchDuck("lads");

// actions

const UPDATE_LAD_LABEL = "Lads/UPDATE_LAD_LABEL";

export const getDefaultUnclassifiedLad = () => {
  return {
    default_name: "Unclassified",
    lob_name: "Unclassified",
    description: "Unclassified",
    code: "U",
    id: 0
  };
};

// fetchers and action creators

const fetchLads = () => {
  const url = apiUrl(`/location/lads`);
  return dispatch => dispatch(duck.fetch(url));
};

const updateLadLabel = (lad, newLabel) => {
  return dispatch => {
    dispatch({ type: UPDATE_LAD_LABEL, payload: { lad, newLabel } });
    return axios.put(apiUrl(`/location/lads/${lad.id}`), {
      lad_name: newLabel
    });
  };
};

// selectors

const getLadsList = state => state.lads.data.lads || [];
const selectLadsListAlphabetically = alfBy(getLadsList, getLadName);

const selectLadsById = createSelector([getLadsList], ladsList =>
  _.keyBy(ladsList, "id")
);

const selectLadsByCode = createSelector([getLadsList], ladsList =>
  _.keyBy(ladsList, "code")
);

const selectGroupedLads = createSelector([getLadsList], ladsList => {
  return _.groupBy(ladsList, "lob_name");
});

const selectLadLabels = createSelector([getLadsList], ladsList => {
  return _.fromPairs(ladsList.map(lad => [lad.id, getLadName(lad)]));
});

const selectGroupedLadsWithLabels = createSelector(
  [selectGroupedLads, selectLadLabels],
  (groupedLads, ladLabels) => {
    return _.map(groupedLads, (ladGroup, groupName) =>
      _.map(ladGroup, lad => ({ label: ladLabels[lad.id], ...lad }))
    );
  }
);

// Select label/value options for <Select> dropdowns
const selectLadOptions = createSelector([getLadsList], lads =>
  lads.map(lad => ({
    label: lad.lad_name,
    value: lad.id,
    ...lad
  }))
);
const selectLadVehicleOptions = createSelector([selectLadOptions], lads =>
  lads.filter(lad => {
    return lad.lob_name === "Vehicles";
  })
);

// initial state

const initialState = {
  ...duck.initialState,
  ladsById: {}
};

// reducer

const LadsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_LAD_LABEL:
      const { lad: targetLad, newLabel } = action.payload;
      const newLads = state.data.lads.map(lad => {
        if (lad.id === targetLad.id) {
          return {
            ...lad,
            lad_name: newLabel
          };
        }
        return lad;
      });

      return {
        data: {
          lads: newLads
        }
      };

    default:
      return state;
  }
};

// interface

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: { fetchLads, updateLadLabel },
  selectors: {
    getLadsList,
    selectLadsListAlphabetically,
    selectLadsById,
    selectLadsByCode,
    selectGroupedLads,
    selectLadLabels,
    selectGroupedLadsWithLabels,
    selectLadVehicleOptions
  },
  reducer: chainReducers([LadsReducer, duck.reducer])
};
