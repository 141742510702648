/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from "react";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import Colors from "../../../styles/colors";

import { FlexDiv } from "../../../styles/container-elements";

export const RackCountWidget = ({ count, label, color, handler = null }) => {
  return (
    <FlexDiv
      css={{
        alignItems: "center",
        border: "1px solid white",
        borderRadius: "5px",
        padding: 3,
        ":hover": {
          border: `1px solid ${Colors.background.GRAY_BLUE}`,
          backgroundColor: "#fcfcfc",
          cursor: handler ? "pointer" : "auto",
          boxShadow: `inset 0 0 1px ${Colors.background.GRAY_BLUE}`
        }
      }}
      onClick={handler}>
      <FlexDiv
        css={{
          marginTop: ".2em"
        }}
      />
      <div
        css={{
          backgroundColor: color || Colors.background.GRAY,
          borderRadius: 100,
          color: "white",
          fontSize: 15,
          fontWeight: 600,
          height: 26,
          lineHeight: "26px",
          marginRight: 10,
          minWidth: 40,
          textAlign: "center"
        }}>
        {count}
      </div>
      <FlexDiv
        css={{
          fontSize: 13,
          color: Colors.text.GRAY,
          fontStyle: "italic",
          textDecoration: "underline"
        }}>
        {label}
      </FlexDiv>
    </FlexDiv>
  );
};

RackCountWidget.propTypes = {
  data: PropTypes.object,
  handler: PropTypes.func
};
