/** @jsx jsx */
//eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";

import ResultsTable from "../../../components/tables/ResultsTable";

import {
  LocationAddressColumn,
  LocationNameColumn,
  LocationCodeColumn,
  LocationShipperColumn,
  LocationTypeColumn,
  LocationParentColumn
} from "../../location/utils/table-columns";

const LocationTable = props => {
  const {
    loading,
    page,
    pageSize,
    totalPages,
    setPagination,
    setSort,
    solutionId,
    data,
    rowClickHandler,
    defaultFiltered,
    onFilteredChange,
    showCarrierColumns
  } = props;

  return (
    <div className="mt-3">
      <ResultsTable
        manual={true}
        cellCursorPointerEnabled={true}
        showPagination={true}
        fixPaginationToBottom={true}
        rowHeight="auto"
        filterable={true}
        data={data}
        columns={[
          LocationParentColumn,
          LocationNameColumn,
          ...(showCarrierColumns ? [LocationShipperColumn] : []),
          LocationTypeColumn(showCarrierColumns),
          LocationCodeColumn,
          LocationAddressColumn
        ]}
        loading={loading}
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        setPagination={setPagination}
        setSort={setSort}
        solutionId={solutionId}
        rowClickHandler={rowClickHandler}
        onFilteredChange={onFilteredChange}
        defaultFiltered={defaultFiltered}
      />
    </div>
  );
};

LocationTable.propTypes = {
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
  setSort: PropTypes.func.isRequired,
  solutionId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  rowClickHandler: PropTypes.func.isRequired,
  defaultFiltered: PropTypes.array.isRequired,
  onFilteredChange: PropTypes.func.isRequired,
  showCarrierColumns: PropTypes.bool.isRequired
};

export default LocationTable;
