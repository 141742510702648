import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Dialog from "react-bootstrap-dialog";
import styled from "@emotion/styled";
import Colors from "../../styles/colors";

import { StringValue } from "react-values";

// FIXME, this is duplicated from GeofenceEditForm, can we make that a shared component
export const FlexRow = styled.div(
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline"
  },
  ({ styleOverrides }) => {
    return styleOverrides;
  }
);

export const ButtonTab = styled.div(
  {
    backgroundColor: "#eee",
    color: "black",
    fontWeight: "bold",
    padding: "0.5em 2em",
    borderRadius: "1px",
    cursor: "pointer"
  },
  ({ tabKey, selectedKey, selectedColor }) => {
    const isSelected = tabKey === selectedKey;
    if (isSelected) {
      return {
        backgroundColor: selectedColor,
        color: "white"
      };
    }
  }
);

const ButtonTabLabel = styled.p({
  marginLeft: "0.0em",
  marginRight: "1.5em",
  fontWeight: "bold"
});

const UnderReviewToggleBar = ({ value, onChange }) => {
  return (
    <StringValue onChange={onChange}>
      {({ set }) => (
        <FlexRow styleOverrides={{ marginBottom: "2em" }}>
          <ButtonTabLabel>Review Status:</ButtonTabLabel>
          <ButtonTab
            tabKey={"under_review"}
            selectedKey={value}
            selectedColor={Colors.highlight.RED}
            onClick={() => set("under_review")}
          >
            Under Review
          </ButtonTab>
          <ButtonTab
            tabKey={"ok"}
            selectedKey={value}
            selectedColor={Colors.highlight.GREEN}
            onClick={() => set("ok")}
          >
            OK
          </ButtonTab>
        </FlexRow>
      )}
    </StringValue>
  );
};

class UnderReviewModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        underReview: false
      },
      noteValue: ""
    };
    this.initialData = this.state.data;
    this.handleChange = this.handleChange.bind(this);
    this.handleOnEnter = this.handleOnEnter.bind(this);
    this.handleOnChangeNote = this.handleOnChangeNote.bind(this);
  }

  handleChange(e) {
    const data = { underReview: e === "under_review" };
    this.setState({ data: data });
  }

  handleOnChangeNote(event) {
    this.setState({ noteValue: event.target.value });
  }

  componentDidMount() {
    const { t } = this.props;
    Dialog.setOptions({
      defaultOkLabel: t("Yes"),
      defaultCancelLabel: t("No"),
      primaryClassName: "btn-success"
    });
  }

  handleOnEnter() {
    const { reviewStatus } = this.props;
    const data = { underReview: reviewStatus };
    this.setState({ data: data, noteValue: "" });
    this.initialData = data;
  }

  render() {
    const { show, hide, updateReviewStatus, shipment, t } = this.props;
    const { data, noteValue } = this.state;
    const userChangedData = !_.isEqual(this.initialData, data);
    const _this = this;

    return (
      <Modal
        show={show}
        style={{ ":width": "300px" }}
        onHide={() => hide()}
        onEnter={this.handleOnEnter}
      >
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {t("Modify Review Status")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <FormGroup>
            <UnderReviewToggleBar
              value={data.underReview ? "under_review" : "ok"}
              onChange={this.handleChange}
            />
            <FormLabel>{`${"Notes"}:`}</FormLabel>
            <FormControl
              as="textarea"
              placeholder={t("Enter notes about the change here")}
              value={noteValue}
              onChange={this.handleOnChangeNote}
              style={{ resize: "none" }}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em"
            }}
            onClick={() => hide()}
          >
            {t("Cancel")}
          </Button>

          <Button
            disabled={!userChangedData}
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300"
            }}
            onClick={() => {
              _this.dialog.show({
                body: t("Are you sure you wish to make this change?"),

                actions: [
                  Dialog.CancelAction(dialog => {
                    dialog.hide();
                  }),
                  Dialog.OKAction(() => {
                    updateReviewStatus(
                      shipment.id,
                      data.underReview,
                      noteValue
                    );
                    hide();
                  })
                ],
                size: "sm",
                onHide: dialog => {
                  dialog.hide();
                }
              });
            }}
          >
            {t("Submit")}
          </Button>

          <Dialog
            ref={el => {
              this.dialog = el;
            }}
          />
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const withT = withTranslation("shipment-details")(UnderReviewModal);
export default connect(mapStateToProps, mapDispatchToProps)(withT);
