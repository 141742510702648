/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import Colors from "./colors";

export const LinkButton = ({ clickHandler, children, style = {} }) => (
  <button
    style={{
      textDecoration: "underline",
      color: Colors.text.HIT_GRAY,
      fontSize: 12,
      background: "none",
      border: "none",
      padding: 0,
      ...style
    }}
    onClick={clickHandler}
  >
    {children}
  </button>
);

LinkButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};

export const markerStyle = { fontSize: 18, margin: "0 20 0 2" };
export const actionButtonStyle = { fontWeight: 400, fontStyle: "italic" };
