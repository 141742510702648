import {
  getBasicQueryString,
  getEverythingQueryString,
  getBasicQueryStringFilterValuePriority
} from "../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: t => t("shipment-search:Search Everything"),
    placeholder: t =>
      t(
        "shipment-search:Search Location, Shipment ID, Part/Trailer #, Route ID, VIN or Order #"
      ),
    queryBuilder: getEverythingQueryString,
    property: null
  },
  {
    queryKey: "location",
    label: t => t("shipment-search:Location"),
    placeholder: t => t("shipment-search:Search Location"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("shipper_location_name", filterValue),
    loaderProperty: "searchLocations",
    property: "locations",
    loadingProperty: "locationsLoading",
    itemLabelProperty: "label",
    itemValueProperty: "value"
  },
  {
    queryKey: "shipment_id",
    label: t => t("shipment-search:Shipment ID"),
    placeholder: t => t("shipment-search:Search Shipment ID"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("creator_shipment_id", filterValue),
    property: "shipmentIDs"
  },
  {
    queryKey: "part_number",
    label: t => t("shipment-search:Part #"),
    placeholder: t => t("shipment-search:Search Part #"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("part_values", filterValue),
    property: "parts"
  },
  {
    queryKey: "trailer_number",
    label: t => t("shipment-search:Trailer #"),
    placeholder: t => t("shipment-search:Search Trailer #"),
    queryBuilder: getBasicQueryString,
    property: "trailerNumbers"
  },
  {
    queryKey: "route_id",
    label: t => t("shipment-search:Route ID"),
    placeholder: t => t("shipment-search:Search Route ID"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("route_number", filterValue),
    property: "routeIDs"
  },
  {
    queryKey: "vin",
    label: t => t("shipment-search:VIN"),
    placeholder: t => t("shipment-search:Search VIN #"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("vin_values", filterValue),
    loaderProperty: "searchVins",
    property: "vins",
    loadingProperty: "vinsLoading"
  },
  {
    queryKey: "order_number",
    label: t => t("shipment-search:Order #"),
    placeholder: t => t("shipment-search:Search Order #"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("sli_number", filterValue),
    property: "orderNumbers"
  },
  {
    queryKey: "trip_plan_number",
    label: t => t("shipment-search:Trip Plan ID"),
    placeholder: t => t("shipment-search:Search Trip Plan ID"),
    queryBuilder: getBasicQueryString,
    property: "tripPlanNumbers"
  },
  {
    queryKey: "asset_id",
    label: t => t("shipment-search:Asset ID"),
    placeholder: t => t("shipment-search:Search Asset ID"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("asset_id_values", filterValue),
    property: "assetIDs"
  },
  {
    queryKey: "current_road",
    label: t => t("shipment-search:Current Road"),
    placeholder: t => t("shipment-search:Search Current Road"),
    // H1-1783: Use filterValue (Current Road ID) if available (user clicked
    // the typeahead option)
    queryBuilder: getBasicQueryStringFilterValuePriority,
    property: "carriers",
    itemLabelProperty: "name",
    itemValueProperty: "id"
  },
  {
    queryKey: "train_id",
    label: t => t("shipment-search:Train ID"),
    placeholder: t => t("shipment-search:Search Train ID"),
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString("train_id", filterValue),
    property: "trainIDs"
  }
];
