/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { useTranslation } from "react-i18next";
import Colors from "../../../styles/colors";
import { IoMdTimer } from "react-icons/io";
import hold from "../../../assets/icons/hold.svg";
import dwell from "../../../assets/icons/dwell.svg";

export default function ExceptionIcon(props) {
  const { t } = useTranslation("fv-dashboard");

  switch (props.exceptionType) {
    case "Behind Schedule":
      return (
        <IoMdTimer
          css={{
            width: props.width,
            height: props.height,
            color: Colors.text.YELLOW
          }}
        />
      );
    case "On Hold":
    case "In Hold":
      return (
        <img
          src={hold}
          css={{ width: props.width, height: props.height }}
          alt={t(props.exceptionType)}
        />
      );
    case "Excessive Dwell":
      return (
        <img
          src={dwell}
          css={{ width: props.width, height: props.height }}
          alt={t(props.exceptionType)}
        />
      );
    default:
      return null;
  }
}

ExceptionIcon.propTypes = {
  exceptionType: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};
