/**
 * It was created just to avoid complex prop drilling on this
 * component, once we need `lads` inside it.
 *
 */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";

import LadsState from "../../lads/LadsState";
import { LadChicletCSS } from "../../../components/chiclets";
import {
  FlexColDiv,
  FlexDiv,
  FlexRowDiv
} from "../../../styles/container-elements";
import CountryName from "../../location/components/CountryName";
import { getOrganizations } from "../../organizations/OrganizationsState";

const MilestoneCell = ({ lastMilestone, lads, organizations }) => {
  if (_.isNil(lastMilestone)) {
    return <div />;
  }
  const location = lastMilestone.location || {};
  const lad = !_.isEmpty(location)
    ? lads.find(l => l.id === Number(location.lad.id))
    : null;

  // We receive sourceId which is in fact the fv_id for the partner. So, we
  // need to get the partner name from the organizations list we have into
  // our redux store
  let partnerName = null;
  if (lastMilestone.sourceId && organizations) {
    let org = _.find(organizations, { fv_id: lastMilestone.sourceId });
    // H2-1330: In case the org doesn't exist due to bad data, account for this
    if (org) {
      partnerName = org["org_name"];
    }
  }

  return (
    <FlexColDiv>
      {partnerName ? (
        <FlexDiv style={{ marginBotton: ".25em" }}>{partnerName}</FlexDiv>
      ) : null}
      <FlexDiv style={{ marginBotton: ".25em" }}>
        {lastMilestone.codeDescription}{" "}
        {lastMilestone.code ? `(${lastMilestone.code})` : ""}
      </FlexDiv>
      <FlexRowDiv>
        {lad ? <LadChicletCSS lad={lad} width={24} height={24} /> : null}
        <FlexColDiv style={{ marginLeft: ".5em" }}>
          {location.name ? (
            <div>
              {location.name} ({location.code})
            </div>
          ) : null}
          {location.address ? <div>{location.address}</div> : null}
          {location.city ? (
            <div>{`${location.city}, ${location.state} ${location.postal_code}`}</div>
          ) : null}
          {location.country ? (
            <div>
              <CountryName countryCode={location.country} />
            </div>
          ) : null}
        </FlexColDiv>
      </FlexRowDiv>
    </FlexColDiv>
  );
};

MilestoneCell.propTypes = {
  lastMilestone: PropTypes.object,
  lads: PropTypes.array.isRequired,
  organizations: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    lads: LadsState.selectors.getLadsList(state),
    organizations: getOrganizations(state)
  };
}

export default connect(mapStateToProps)(MilestoneCell);
