/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useIsMediumAndDown } from "../../../components/responsive";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import { getSolutionId } from "../../organizations/OrganizationsState";
import AdcSearchBarState from "../../adc-search/AdcSearchBarState";
import { DataWidgetFrame } from "../../../components/widgets/DataWidgetFrame";
import { DataWidgetTooltip } from "../../../components/widgets/DataWidgetTooltip";
import { FlexDiv } from "../../../styles/container-elements";
import LineChart from "../../../components/charts/LineChart";
import InboundVinLocations from "./InboundVinLocations";

const chartCss = {
  backgroundColor: "white",
  padding: "1.5em",
  svg: {
    maxHeight: 260
  }
};

const InboundVins = props => {
  const {
    solutionId,
    demandArea,
    inboundVins,
    selectedLocations,
    setSelectedLocations,
    searchEntities,
    setSearchFilter
  } = props;

  const { t } = useTranslation("adc-dashboard");

  const isMobile = useIsMediumAndDown();

  // Add a location to the selected list
  const toggleLocation = location => {
    let newLocations = [...selectedLocations];

    if (newLocations.includes(location)) {
      newLocations = newLocations.filter(loc => loc !== location);
    } else {
      newLocations.push(location);
    }

    setSelectedLocations(newLocations);
  };

  // Widget header
  const header = (
    <FlexDiv css={{ flexGrow: 1, alignItems: "center" }}>
      <div css={{ flexGrow: 1 }} data-qa="text-header-inbound-vins">
        {t("adc-dashboard:Inbound VINs")}
        <DataWidgetTooltip
          tooltip={t(
            "adc-dashboard:This chart provides forecasting ability for any chosen location. The line graph shows projected incoming VINs on a day by day basis to improve capacity planning."
          )}
          icon={faInfoCircle}
          style={{ marginLeft: "0.5em" }}
        />
      </div>
      <InboundVinLocations
        demandArea={demandArea}
        selectedLocations={selectedLocations}
        resetLocations={() => setSelectedLocations([])}
        toggleLocation={toggleLocation}
      />
    </FlexDiv>
  );

  // Convert API data into display for VictoryChart
  const chartData = () => {
    if (!inboundVins?.data?.daysFromToday) {
      return null;
    }

    const data = Object.entries(inboundVins.data.daysFromToday).map(entry => {
      const days = parseInt(entry[0]);
      const date = moment().add(days, "days");
      const dateLabel =
        days === 0 ? t("adc-dashboard:Today") : date.format("MMM D");
      const count = entry[1].count;
      const ids = entry[1].entity_ids || [];
      const tooltip = `${count} ${
        count === 1
          ? t("adc-dashboard:VIN Inbound")
          : t("adc-dashboard:VINs Inbound")
      }`;

      return {
        x: dateLabel,
        y: count,
        date: date,
        ids: ids,
        tooltip: tooltip
      };
    });

    // Only display 10 days of data (including today)
    return data.slice(0, 10);
  };

  // VictoryChart event (chart data element clicked)
  const chartClick = () => {
    return [
      {
        target: "data",
        mutation: mutationProps => {
          // Chart data element has a date property containing a moment() instance
          const clickedElement = mutationProps.datum;
          const clickedIds = clickedElement.ids;

          // Set the ADC search filters and redirect to results view
          if (clickedIds && clickedIds.length) {
            setSearchFilter("entity_batch", clickedIds);
          }
          if (selectedLocations && selectedLocations.length) {
            setSearchFilter("currentLocation", selectedLocations);
          }
          searchEntities(solutionId);
        }
      }
    ];
  };

  const data = chartData();

  return (
    <DataWidgetFrame
      header={header}
      styles={{
        height: isMobile ? "100%" : "26em",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        marginBottom: isMobile ? "1em" : null,
        minWidth: isMobile ? "null" : "20em"
      }}
      isLoading={inboundVins && inboundVins.isLoading}
    >
      <div css={chartCss} data-qa="chart-inbound-vins">
        {data && <LineChart data={data} dataClick={chartClick} />}
      </div>
    </DataWidgetFrame>
  );
};

InboundVins.propTypes = {
  solutionId: PropTypes.string.isRequired,
  inboundVins: PropTypes.object,
  selectedLocations: PropTypes.array,
  demandArea: PropTypes.string,
  setSelectedLocations: PropTypes.func.isRequired,
  searchEntities: PropTypes.func.isRequired,
  setSearchFilter: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    solutionId: getSolutionId(state)
  };
}

function mapDispatchToProps(dispatch) {
  const { searchEntities, setSearchFilter } = AdcSearchBarState.actionCreators;
  return {
    searchEntities: solutionId => {
      dispatch(searchEntities(solutionId));
    },
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InboundVins);
