/**
 * Defines and controls state for the whole organizations screen.
 */
import axios from "axios";
import _ from "lodash";
import apiUrl from "../../api-url";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";
import { Privileges } from "../auth/Authorization";

// URLS
const ORGANIZATIONS_URL = apiUrl("/iam/organizations");

// Actions
const SET_CURRENT_ORGANIZATION_ID = "Organizations/SET_CURRENT_ORGANIZATION_ID";
const SET_ACTIVE_ORGANIZATION = "Organizations/SET_ACTIVE_ORGANIZATION";
const CLEAR_ACTION_STATUS = "Organizations/CLEAR_ACTION_STATUS";
const SET_IS_FINISHED_VEHICLE_ACTIVE =
  "Organizations/SET_IS_FINISHED_VEHICLE_ACTIVE";

const SET_IS_ORGANIZATIONS_LOADED = "Users/SET_IS_ORGANIZATIONS_LOADED";
const SET_IS_ORGANIZATION_MEMBERS_LOADED =
  "Users/SET_IS_ORGANIZATION_MEMBERS_LOADED";

const RECEIVE_ORGANIZATIONS = "Organizations/RECEIVE_ORGANIZATIONS";
const RECEIVE_ORGANIZATION_MEMBERS =
  "Organizations/RECEIVE_ORGANIZATION_MEMBERS";
const RECEIVE_FEDERATION_DATA = "Organizations/RECEIVE_FEDERATION_DATA";
const RECEIVE_ORGANIZATION_TYPES = "Organizations/RECEIVE_ORGANIZATION_TYPES";

const ADD_ORGANIZATION = "Organizations/ADD_ORGANIZATION";
const ADD_ORGANIZATION_SUCCEEDED = "Organizations/ADD_ORGANIZATION_SUCCEEDED";
const ADD_ORGANIZATION_FAILED = "Organizations/ADD_ORGANIZATION_FAILED";

const UPDATE_ORGANIZATION = "Organizations/UPDATE_ORGANIZATION";
const UPDATE_ORGANIZATION_SUCCEEDED =
  "Organizations/UPDATE_ORGANIZATION_SUCCEEDED";
const UPDATE_ORGANIZATION_FAILED = "Organizations/UPDATE_ORGANIZATION_FAILED";

const DELETE_ORGANIZATION = "Organizations/DELETE_ORGANIZATION";
const DELETE_ORGANIZATION_SUCCEEDED =
  "Organizations/DELETE_ORGANIZATION_SUCCEEDED";
const DELETE_ORGANIZATION_FAILED = "Organizations/DELETE_ORGANIZATION_FAILED";

const FETCH_FEATURE_DATA = "Organizations/FETCH_FEATURE_DATA";
const RECEIVE_FEATURE_DATA = "Organizations/RECEIVE_FEATURE_DATA";
const SET_SOLUTION_ID = "Organizations/SET_SOLUTION_ID";

const SET_MAP_TYPE_OVERRIDE = "Organizations/SET_MAP_TYPE_OVERRIDE";

const duck = buildFetchDuck("organizations");

export function fetchOrganizations() {
  const url = `${ORGANIZATIONS_URL}`;

  return dispatch => {
    dispatch(setIsOrganizationsLoaded(false));
    return axios
      .get(`${url}`)
      .then(response => {
        dispatch({
          type: RECEIVE_ORGANIZATIONS,
          payload: response.data
        });
        dispatch(setIsOrganizationsLoaded(true));
      })
      .catch(err => {
        dispatch({
          type: RECEIVE_ORGANIZATIONS,
          payload: []
        });
        dispatch(setIsOrganizationsLoaded(true));
      });
  };
}

export function fetchOrganizationMembers(organizationId) {
  const url = `${ORGANIZATIONS_URL}/${organizationId}/members`;

  return dispatch => {
    dispatch(setIsOrganizationMembersLoaded(false));
    return axios
      .get(`${url}`)
      .then(response => {
        dispatch({
          type: RECEIVE_ORGANIZATION_MEMBERS,
          payload: response.data
        });
        dispatch(setIsOrganizationMembersLoaded(true));
      })
      .catch(err => {
        dispatch({
          type: RECEIVE_ORGANIZATION_MEMBERS,
          payload: []
        });
        dispatch(setIsOrganizationMembersLoaded(true));
      });
  };
}

export function fetchFederationData() {
  const url = apiUrl("/iam/federation");

  return dispatch => {
    return axios
      .get(`${url}`)
      .then(response => {
        dispatch({
          type: RECEIVE_FEDERATION_DATA,
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: RECEIVE_FEDERATION_DATA,
          payload: []
        });
      });
  };
}

export function fetchOrganizationTypes() {
  const url = apiUrl("/iam/organization_profiles");

  return dispatch => {
    return axios
      .get(`${url}`)
      .then(response => {
        dispatch({
          type: RECEIVE_ORGANIZATION_TYPES,
          payload: response.data
        });
      })
      .catch(err => {
        dispatch({
          type: RECEIVE_ORGANIZATION_TYPES,
          payload: []
        });
        console.log(err);
      });
  };
}

export function fetchFeatureData() {
  const url = apiUrl("/iam/feature");

  return async dispatch => {
    try {
      dispatch({
        type: FETCH_FEATURE_DATA
      });

      const response = await axios.get(`${url}`);
      const featureData = response.data;

      extractAndSetSolutionId(featureData, dispatch);

      dispatch({
        type: RECEIVE_FEATURE_DATA,
        payload: featureData
      });
    } catch (err) {
      dispatch({
        type: RECEIVE_FEATURE_DATA,
        payload: []
      });
    }
  };
}

function extractAndSetSolutionId(featureData, dispatch) {
  const finishedVehicleData = _.find(featureData, {
    feature_name: "Finished Vehicle"
  });
  const solutionId = finishedVehicleData ? finishedVehicleData.solution : null;

  if (solutionId) {
    sessionStorage.setItem("solutionId", solutionId);

    dispatch({
      type: SET_SOLUTION_ID,
      solutionId: solutionId
    });
  }
}

export function setCurrentOrganization(organizationId, authorization) {
  sessionStorage.setItem("currentOrganization", organizationId);

  return dispatch => {
    // fetch org members if user has manage roles permission,
    // easier to check this in one central location.
    // If security issue will have to moved to all areas where set current org is called
    if (
      authorization &&
      authorization.hasPrivileges([Privileges.MANAGE_USERS])
    ) {
      dispatch(fetchOrganizationMembers(organizationId));
    }

    // fetch feature data, which also sets the solutionId
    dispatch(fetchFeatureData());

    dispatch({
      type: SET_CURRENT_ORGANIZATION_ID,
      currentOrganizationId: organizationId
    });
  };
}

export function setActiveOrganization(org) {
  return {
    type: SET_ACTIVE_ORGANIZATION,
    org: org
  };
}

export function setIsOrganizationsLoaded(isLoaded) {
  return dispatch => {
    return dispatch({
      type: SET_IS_ORGANIZATIONS_LOADED,
      isOrganizationsLoaded: isLoaded
    });
  };
}

export function setIsOrganizationMembersLoaded(isLoaded) {
  return dispatch => {
    return dispatch({
      type: SET_IS_ORGANIZATION_MEMBERS_LOADED,
      isOrganizationMembersLoaded: isLoaded
    });
  };
}

export function setMapTypeOverride(mapType) {
  sessionStorage.setItem("mapTypeOverride", mapType);
  return {
    type: SET_MAP_TYPE_OVERRIDE,
    mapType: mapType
  };
}

export function addOrganization(payload) {
  return function(dispatch) {
    dispatch({
      type: ADD_ORGANIZATION,
      payload
    });
    dispatch(setIsOrganizationsLoaded(false));
    const url = ORGANIZATIONS_URL;
    return axios
      .post(url, payload)
      .then(resp => {
        dispatch({
          type: ADD_ORGANIZATION_SUCCEEDED
        });
        dispatch(fetchOrganizations());
      })
      .catch(error => {
        dispatch({
          type: ADD_ORGANIZATION_FAILED,
          error
        });
        dispatch(setIsOrganizationsLoaded(true));
      });
  };
}

export function updateOrganization(organizationId, payload) {
  return function(dispatch) {
    dispatch({
      type: UPDATE_ORGANIZATION,
      organizationId,
      payload
    });
    dispatch(setIsOrganizationsLoaded(false));
    const url = ORGANIZATIONS_URL + `/${organizationId}`;
    return axios
      .patch(url, payload)
      .then(resp => {
        dispatch({
          type: UPDATE_ORGANIZATION_SUCCEEDED,
          organizationId,
          payload
        });
        dispatch(fetchOrganizations());
      })
      .catch(error => {
        dispatch({
          type: UPDATE_ORGANIZATION_FAILED,
          organizationId,
          error
        });
        dispatch(setIsOrganizationsLoaded(true));
      });
  };
}

export function deleteOrganization(organizationId) {
  return function(dispatch) {
    dispatch({
      type: DELETE_ORGANIZATION,
      organizationId
    });
    dispatch(setIsOrganizationsLoaded(false));
    const url = ORGANIZATIONS_URL + `/${organizationId}`;
    return axios
      .delete(url)
      .then(resp => {
        dispatch({
          type: DELETE_ORGANIZATION_SUCCEEDED
        });
        dispatch(fetchOrganizations());
      })
      .catch(error => {
        dispatch({
          type: DELETE_ORGANIZATION_FAILED,
          organizationId,
          error
        });
        dispatch(setIsOrganizationsLoaded(true));
      });
  };
}

export const getCurrentOrganizationId = state =>
  state.organizations.currentOrganizationId;

export const getOrganizations = state =>
  state.organizations.data && state.organizations.data.response
    ? state.organizations.data.response
    : [];

export const getActiveOrganization = state =>
  state.organizations.activeOrganization;

export const getOrganizationMembers = state =>
  state.organizations.organizationMembers
    ? state.organizations.organizationMembers.response
    : state.organizations.organizationMembers;

export const getIsFinishedVehicleActive = state =>
  state.organizations.isFinishedVehicleActive;

export const getSolutionId = state =>
  state.organizations.solutionId || sessionStorage.getItem("solutionId");

export const getFeatureData = state => state.organizations.featureData;
export const getFeatureDataLoading = state =>
  state.organizations.featureDataLoading;

export const getFederationData = state => state.organizations.federationData;

export const getMapTypeOverride = state => state.organizations.mapTypeOverride;

const initialState = {
  actionStatus: null,
  data: [],
  organizationMembers: [],
  isOrganizationMembersLoaded: true,
  isOrganizationsLoaded: true,
  activeOrganization: {},
  currentOrganizationId: null,
  isFinishedVehicleActive: false,
  featureData: [],
  featureDataLoading: true,
  solutionId: null,
  mapTypeOverride: sessionStorage.getItem("mapTypeOverride")
    ? sessionStorage.getItem("mapTypeOverride")
    : null
};

export function clearActionStatus() {
  return {
    type: CLEAR_ACTION_STATUS
  };
}

function OrganizationsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ACTIVE_ORGANIZATION:
      return {
        ...state,
        activeOrganization: action.org
      };
    case SET_CURRENT_ORGANIZATION_ID:
      return {
        ...state,
        currentOrganizationId: action.currentOrganizationId
      };
    case SET_IS_ORGANIZATIONS_LOADED:
      return {
        ...state,
        isOrganizationsLoaded: action.isOrganizationsLoaded
      };
    case SET_IS_ORGANIZATION_MEMBERS_LOADED:
      return {
        ...state,
        isOrganizationMembersLoaded: action.isOrganizationMembersLoaded
      };
    case RECEIVE_ORGANIZATIONS:
      return {
        ...state,
        data: action.payload,
        isOrganizationsLoaded: true
      };
    case RECEIVE_ORGANIZATION_MEMBERS:
      return {
        ...state,
        organizationMembers: action.payload,
        isOrganizationMembersLoaded: true
      };
    case RECEIVE_FEDERATION_DATA:
      return {
        ...state,
        federationData: action.payload
      };
    case SET_IS_FINISHED_VEHICLE_ACTIVE:
      return {
        ...state,
        isFinishedVehicleActive: action.isFinishedVehicleActive
      };
    case RECEIVE_ORGANIZATION_TYPES:
      return {
        ...state,
        organizationTypes: action.payload.response
      };
    case CLEAR_ACTION_STATUS:
      return {
        ...state,
        actionStatus: null
      };
    case ADD_ORGANIZATION_SUCCEEDED:
      return {
        ...state,
        actionStatus: "ORGANIZATION_ADDED"
      };
    case UPDATE_ORGANIZATION_SUCCEEDED:
      return {
        ...state,
        actionStatus: "ORGANIZATION_UPDATED"
      };
    case ADD_ORGANIZATION_FAILED:
    case UPDATE_ORGANIZATION_FAILED:
      return {
        ...state,
        actionStatus:
          action.error.response &&
          action.error.response.status &&
          (action.error.response.status === 409
            ? "Organization_Exists"
            : "Organization_Add_Error")
      };
    case FETCH_FEATURE_DATA:
      return {
        ...state,
        featureDataLoading: true
      };
    case RECEIVE_FEATURE_DATA:
      return {
        ...state,
        featureData: action.payload,
        featureDataLoading: false
      };
    case SET_SOLUTION_ID:
      return {
        ...state,
        solutionId: action.solutionId
      };
    case SET_MAP_TYPE_OVERRIDE:
      return {
        ...state,
        mapTypeOverride: action.mapType
      };
    default:
      return state;
  }
}

export default chainReducers([OrganizationsReducer, duck.reducer]);
