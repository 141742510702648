import PropTypes from "prop-types";
import { Component } from "react";
import { connect } from "react-redux";
import { UserAuthorizationNamespace, OrganizationTypes } from "./Authorization";
import * as UsersActions from "../../modules/users/UsersState";
import * as OrganizationsActions from "../../modules/organizations/OrganizationsState";
import RolesState from "../../modules/roles/RolesState";
import Authorization from "./Authorization";
import ApplicationConfig from "../../config";
import AuthenticationUtils from "./authentication";

// Wraps a component with authorization checks
// If the user is not logged in, redirect to login view
// If the user is not authorized, redirect to error view
export default function AuthorizedComponentWithRedirect(
  WrappedComponent,
  location,
  allowedPrivileges,
  allowedFeatures
) {
  class WithAuthorization extends Component {
    static propTypes = {
      dispatch: PropTypes.func.isRequired
    };

    constructor(props) {
      super(props);

      this.loginRedirectNeeded = this.loginRedirectNeeded.bind(this);
      this.redirectToLogin = this.redirectToLogin.bind(this);
      this.setCurrentUser = this.setCurrentUser.bind(this);
      this.setCurrentOrganization = this.setCurrentOrganization.bind(this);
      this.userClaims = this.userClaims.bind(this);
    }

    loginRedirectNeeded() {
      return (
        ApplicationConfig.REQUIRE_LOGIN &&
        !AuthenticationUtils.isAuthenticated()
      );
    }

    redirectToLogin() {
      const { dispatch } = this.props;

      dispatch(UsersActions.setCurrentUser(null));
      AuthenticationUtils.logout();
      AuthenticationUtils.login();

      return null;
    }

    setCurrentUser() {
      const { dispatch } = this.props;
      const claims = this.userClaims();

      dispatch(UsersActions.setCurrentUser(claims));
    }

    setCurrentOrganization() {
      const { dispatch, federationData } = this.props;
      const claims = this.userClaims();

      if (!AuthenticationUtils.getIsSilentAuthentication()) {
        if (claims && claims.hasOwnProperty(UserAuthorizationNamespace)) {
          const organizationId = sessionStorage.getItem("currentOrganization")
            ? sessionStorage.getItem("currentOrganization")
            : claims[UserAuthorizationNamespace].organization_id;
          dispatch(OrganizationsActions.setCurrentOrganization(organizationId));
        }
      } else {
        dispatch(OrganizationsActions.fetchFederationData());
      }

      // save federation data to session storage
      if (federationData) {
        sessionStorage.setItem("globalFederatedData", federationData);
        dispatch(
          OrganizationsActions.setCurrentOrganization(
            federationData.organization_id
          )
        );
      }
    }

    userClaims() {
      return AuthenticationUtils.getDecodedToken();
    }

    componentDidMount() {
      const { currentUser } = this.props;

      if (this.loginRedirectNeeded()) {
        return this.redirectToLogin();
      }

      if (!currentUser) {
        this.setCurrentUser();
        this.setCurrentOrganization();
      }
    }

    render() {
      const {
        currentUser,
        activeOrganization,
        federationData,
        featureData,
        featureDataLoading
      } = this.props;

      if (featureDataLoading === true) {
        return null;
      }

      const authorization = new Authorization(
        currentUser,
        activeOrganization,
        federationData,
        featureData
      );

      const isAuthorized = authorization.isAuthorized(
        allowedPrivileges,
        allowedFeatures
      );

      // TODO: Centralize redirect logic for special restrictions below (H1-928, H2-720)

      // H1-928: Redirect PAT-only users to dashboard if attempting to access non-PAT routes
      const patOnlyPath = "/plant-asset";
      if (
        authorization.featureAuthorization.plantAssetTrackingOnly() &&
        !location.pathname.includes(patOnlyPath)
      ) {
        window.location.href = patOnlyPath;
        return null;
      }

      // H2-720: Redirect Partners to dashboard if attempting to access non-partner routes
      if (
        activeOrganization &&
        activeOrganization.org_type === OrganizationTypes.PARTNER
      ) {
        const partnerDashboardPath = "/partners";
        const partnerAllowedPaths = [
          partnerDashboardPath,
          "/usermanagement",
          "/documentation"
        ];
        let currentPathAllowed = false;

        partnerAllowedPaths.forEach(p => {
          if (location.pathname.includes(p)) {
            currentPathAllowed = true;
          }
        });

        if (!currentPathAllowed) {
          window.location.href = partnerDashboardPath;
          return null;
        }
      }

      if (!isAuthorized) {
        //TODO: create error page for no access
        window.location.href = "/accessForbiddenError";
        return null;
      }

      return WrappedComponent;
    }
  }

  function mapStateToProps(state) {
    return {
      currentUser: state.users.currentUser,
      federationData: state.organizations.federationData,
      featureData: OrganizationsActions.getFeatureData(state),
      featureDataLoading: OrganizationsActions.getFeatureDataLoading(state),
      activeOrganization: OrganizationsActions.getActiveOrganization(state),
      roles: RolesState.selectors.getRoles(state)
    };
  }

  return connect(mapStateToProps)(WithAuthorization);
}
