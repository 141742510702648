/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import ShipmentLegTabs from "./multimodal/ShipmentLegTabs";
import { useIsMediumAndDown } from "../../components/responsive";

const MultimodalDetailPanel = props => {
  const isMobile = useIsMediumAndDown();
  if (!props.isLoaded) {
    return null;
  }

  /* H1-809: Multimodal panel scrollable like regular shipment panel */
  return (
    <div
      css={{
        backgroundColor: "none",
        height: "100em",
        overflow: isMobile ? null : "auto"
      }}
    >
      <ShipmentLegTabs {...props} />
    </div>
  );
};

MultimodalDetailPanel.propTypes = {
  isLoaded: PropTypes.bool.isRequired
};

export default MultimodalDetailPanel;
