/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";

const htmlCss = {
  width: "100%",
  height: "100%"
};

const ContentHtml = props => {
  const { content } = props;

  if (!content) {
    return null;
  }

  return <div css={htmlCss}>{ReactHtmlParser(content)}</div>;
};

ContentHtml.propTypes = {
  content: PropTypes.string
};
export default ContentHtml;
