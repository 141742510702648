/*
Blue Newt Software, Inc. Confidential Unpublished Copyright
(c) 2008-2018 Blue Newt Software, Inc., All Rights Reserved.

NOTICE: All information contained herein is, and remains the property of Blue
Newt Software, Inc. The intellectual and technical concepts contained herein
are proprietary to Blue Newt Software, Inc. and may be covered by U.S. and
Foreign Patents, patents in process, and are protected by trade secret or
copyright law. Dissemination of this information or reproduction of this
material is strictly forbidden unless prior written permission is obtained
from Blue Newt Software, Inc. Access to the source code contained herein is
hereby forbidden to anyone except current Blue Newt Software, Inc. employees,
managers or contractors who have executed  Confidentiality and Non-disclosure
agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended
publication or disclosure of this source code, which includes  information
that is confidential and/or proprietary, and is a trade secret, of Blue Newt
Software, Inc. ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC
PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT
THE EXPRESS WRITTEN CONSENT OF Blue Newt Software, Inc. IS STRICTLY
PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND INTERNATIONAL TREATIES.
THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES
NOT CONVEY OR IMPLY ANY RIGHTS  TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS
CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT MAY DESCRIBE, IN
WHOLE OR IN PART.
*/

/* eslint-disable react/forbid-foreign-prop-types */

import React from "react";
import PropTypes from "prop-types";
import { FormLabel, FormGroup, FormControl } from "react-bootstrap";
import _ from "lodash";
import TimePicker from "rc-time-picker";

function restProps(props, componentPropTypes) {
  if (!props || !componentPropTypes) {
    return;
  }

  return _.omit(props, Object.keys(componentPropTypes));
}

export function InputElem(props) {
  const { getValidationState } = props;
  let labelElem =
    props.lbl != null ? (
      <FormLabel className="form-label">{props.lbl}</FormLabel>
    ) : null;

  const formGroup = getValidationState ? (
    <FormGroup validationState={getValidationState()}>
      {labelElem}
      {
        <FormControl
          onChange={evt => props.handler(evt.currentTarget.value, props.evt)}
          placeholder={props.placeholder}
          type="text"
          value={props.val}
          {...restProps(props, InputElem.propTypes)}
        />
      }
    </FormGroup>
  ) : (
    <FormGroup>
      {labelElem}
      {
        <FormControl
          onChange={evt => props.handler(evt.currentTarget.value, props.evt)}
          placeholder={props.placeholder}
          type="text"
          value={props.val}
          {...restProps(props, InputElem.propTypes)}
        />
      }
    </FormGroup>
  );

  return <div>{formGroup}</div>;
}
InputElem.propTypes = {
  lbl: PropTypes.string,
  evt: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func,
  val: PropTypes.string
};

export function SelectElem(props) {
  const { t } = props;
  const optionElems = props.list.map((item, i) => {
    const label = t ? t(item) : item;
    return (
      <option key={i} value={i}>
        {label}
      </option>
    );
  });

  let labelElem =
    props.lbl != null ? (
      <FormLabel className="form-label">{props.lbl}</FormLabel>
    ) : null;

  let controlProps = Object.assign({}, props);
  if (t) {
    _.unset(controlProps, "t");
  }

  return (
    <FormGroup style={props.style}>
      {labelElem}
      <FormControl
        as="select"
        placeholder={props.placeholder}
        onChange={evt => props.handler(evt.currentTarget.value, props.evt)}
        value={props.val}
        {...restProps(controlProps, SelectElem.propTypes)}
      >
        {optionElems}
      </FormControl>
    </FormGroup>
  );
}
SelectElem.propTypes = {
  list: PropTypes.array.isRequired,
  lbl: PropTypes.string,
  evt: PropTypes.string,
  placeholder: PropTypes.string,
  handler: PropTypes.func,
  val: PropTypes.number.isRequired
};

export function StaticElem(props) {
  return (
    <FormGroup>
      <FormLabel className="form-label">{props.lbl}</FormLabel>
      <FormControl.Static>{props.children}</FormControl.Static>
    </FormGroup>
  );
}
StaticElem.propTypes = {
  lbl: PropTypes.string
};

export function TimePickerElem(props) {
  const { time, lbl, onChange } = props;
  return (
    <FormGroup>
      <FormLabel className="form-label">{lbl}</FormLabel>
      <TimePicker
        className="full-col"
        showSecond={false}
        defaultValue={time}
        format={"h:mm a"}
        use12Hours
        onChange={onChange}
        allowEmpty={false}
      />
    </FormGroup>
  );
}
TimePickerElem.propTypes = {
  lbl: PropTypes.string,
  time: PropTypes.object.isRequired /* a moment instance */,
  onChange: PropTypes.func
};
