/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import i18n from "../../i18n";

import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { AdcCapacity } from "../adc-search/AdcCapacity";
import AdcEntitiesState from "../adc-dashboard/AdcEntitiesState";

import CheckBoxCell from "../../components/tables/Cell/CheckBoxCell";
import LocationCell from "../../components/tables/Cell/LocationCell";
import NumberCell from "../../components/tables/Cell/NumberCell";

import DemandAreaList from "../adc-details/components/header/DemandAreaList";

const WatchCellRenderer = ({ value, original }) => {
  const dispatch = useDispatch();
  // Duplicating props to state is causing an issue with this component in ReactTable
  // If a row in between others is removed, that row's components is reused for the rows below.
  // That means when we check this to false and the below row is true, we will still see false
  // because the state of the component hasn't changed.
  // Toy example: https://codesandbox.io/s/nostalgic-dubinsky-7flri
  //
  // We did try syncing props and state with an effect but it was still having some issue
  // with this working both in the WatchedLocaationsTable and ADC search results.
  // But we should probably find a way to do this without duplicating props to state.
  const [checked, setChecked] = useState(value);

  const onChange = e => {
    setChecked(!value);
    dispatch(
      AdcEntitiesState.actionCreators.setWatchLocation(
        original.locationId,
        !value
      )
    );
  };

  return <CheckBoxCell checked={checked} onChange={onChange} />;
};

const DemandAreaCellRenderer = ({ original }) => {
  let demandAreaNames = [];
  if (original.demandAreas?.length > 0) {
    demandAreaNames = original.demandAreas.map(d => d.name);
  }
  return (
    <div className="d-flex justify-content-center">
      <DemandAreaList list={demandAreaNames} />
    </div>
  );
};

const StatusRenderer = ({ value }) => {
  const { t } = useTranslation("adc-search-results");

  const capacity = AdcCapacity.forLabel(value);

  return (
    <div
      css={{ color: capacity.color, textOverflow: "auto", overflow: "none" }}
    >
      {capacity.iconElem}
      <span className="pl-1 pt-1">{t(`adc-search-results:${value}`)}</span>
    </div>
  );
};

StatusRenderer.propTypes = {
  value: PropTypes.string.isRequired
};

/*** Columns ***/

const wordWrapHeader = {
  overflow: "unset",
  textOverflow: "unset",
  whiteSpace: "pre-line",
  wordWrap: "break-word",
  textAlign: "center"
};

// Note: From react-table v6 docs:
// A unique ID is required if the accessor is not a string
// https://github.com/tannerlinsley/react-table/tree/v6#columns

export const WatchColumn = {
  // This is so that we can know what column we clicked on
  id: "ADC_WATCH_COLUMN",
  Header: i18n.t("adc-search-results:Watch"),
  accessor: "watch",
  Cell: props => (
    // Forcing React to recreate this component when locationId changes
    // because of an issue in the component.
    <WatchCellRenderer key={props.original.locationId} {...props} />
  ),
  width: 68
};

export const NameAndLocationColumn = {
  id: "ADC_LOCATION_COLUMN",
  Header: i18n.t("adc-search-results:Location"),
  Cell: ({ original, styles }) => (
    <LocationCell
      styles={styles}
      location={original}
      showAddress={true}
      WrappingComponent={({ children, ...rest }) => (
        <div {...rest}>{children}</div>
      )}
    />
  ),
  width: 180,
  minWidth: 150,
  headerStyle: {
    minWidth: 150
  },
  style: {
    minWidth: 150,
    color: "white"
  }
};

export const DemandAreasColumn = {
  id: "ADC_DEMAND_AREA_COLUMN",
  Header: i18n.t("adc-search-results:Demand Areas"),
  sortable: false,
  Cell: DemandAreaCellRenderer,
  minWidth: 82,
  headerStyle: {
    minWidth: 82,
    ...wordWrapHeader
  },
  style: {
    minWidth: 82,
    textAlign: "center"
  }
};

export const TotalOnsiteVinsColumn = {
  id: "ADC_TOTAL_ONSITE_VINS_COLUMN",
  Header: i18n.t("adc-search-results:Total Onsite VINs"),
  accessor: "onSiteCount",
  Cell: NumberCell,
  minWidth: 72,
  headerStyle: {
    minWidth: 72,
    ...wordWrapHeader
  }
};

export const TotalAgingVinsColumn = {
  id: "ADC_TOTAL_AGING_VINS_COLUMN",
  Header: i18n.t("adc-search-results:Total Aging VINs"),
  accessor: "agedCount",
  Cell: NumberCell,
  minWidth: 72,
  headerStyle: {
    minWidth: 72,
    ...wordWrapHeader
  }
};

export const CapacityStatusColumn = {
  id: "ADC_CAPACITY_STATUS_COLUMN",
  Header: i18n.t("adc-search-results:Capacity Status"),
  accessor: "status",
  Cell: props => <StatusRenderer {...props} />,
  minWidth: 125,
  headerStyle: {
    minWidth: 125
  },
  style: {
    minWidth: 125,
    display: "flex"
  }
};
