import { connect } from "react-redux";
import SavedSearchSelect from "../../components/saved-search/SavedSearchSelect";
import EditSavedSearchModalContainer from "./FinVehicleEditSavedSearchModalContainer";
import DeleteSavedSearchModal from "../../components/saved-search/DeleteSavedSearchModal";
import FinVehicleSavedSearchState from "./FinVehicleSavedSearchState";
import FinVehicleSearchBarState from "./FinVehicleSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";

const mapStateToProps = state => ({
  savedSearch: FinVehicleSearchBarState.selectors.getSelectedSavedSearch(state),
  savedSearches: FinVehicleSavedSearchState.selectors.getSavedSearches(state),
  searchObj: {},
  searchFilters: FinVehicleSearchBarState.selectors.getSearchFilters(state),
  searchCategory: FinVehicleSearchBarState.selectors.getSearchCategory(state),
  searchText: FinVehicleSearchBarState.selectors.getSearchText(state),
  solutionId: getSolutionId(state),
  editModal: EditSavedSearchModalContainer,
  deleteModal: DeleteSavedSearchModal
});

const mapDispatchToProps = dispatch => ({
  fetchSavedSearches: () =>
    dispatch(FinVehicleSavedSearchState.actionCreators.fetchSavedSearches()),
  loadSavedSearch: savedSearch =>
    dispatch(
      FinVehicleSavedSearchState.actionCreators.loadSavedSearch(savedSearch)
    ),
  saveSearch: (name, searchObj) =>
    dispatch(
      FinVehicleSavedSearchState.actionCreators.saveSearch(name, searchObj)
    ),
  editSearch: (id, name, searchObj) =>
    dispatch(
      FinVehicleSavedSearchState.actionCreators.editSearch(id, name, searchObj)
    ),
  deleteSearch: id =>
    dispatch(FinVehicleSavedSearchState.actionCreators.deleteSearch(id)),
  exportSearch: solutionId => {
    dispatch(FinVehicleSearchBarState.actionCreators.exportSearch(solutionId));
  },
  selectSavedSearch: (solutionId, item) =>
    dispatch(
      FinVehicleSearchBarState.actionCreators.selectSavedSearch(
        solutionId,
        item
      )
    ),
  resetSavedSearch: () =>
    dispatch(FinVehicleSearchBarState.actionCreators.resetSavedSearch())
});

export default connect(mapStateToProps, mapDispatchToProps)(SavedSearchSelect);
