import PropTypes from "prop-types";
import React from "react";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LOBS } from "../../../components/lads";

const propTypes = {
  activeTab: PropTypes.string.isRequired,
  onTabSelect: PropTypes.func.isRequired
};

/**
 * LADs page nav
 */
const PageNavigation = ({ activeTab, onTabSelect }) => {
  const { t } = useTranslation("lads");

  return (
    <Nav justify variant="tabs" activeKey={activeTab} onSelect={onTabSelect}>
      {LOBS.map(lob => {
        return (
          <Nav.Item key={`lad-tab-${lob}`}>
            <Nav.Link eventKey={lob}>{t(lob)}</Nav.Link>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};

PageNavigation.propTypes = propTypes;

export default PageNavigation;
