/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import React, { Component } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { BooleanValue } from "react-values";
import { MdEventNote } from "react-icons/md";

import {
  PanelContainer,
  Panel
} from "../../vendor/signal-widgets/components/panel";

import Colors from "../../styles/colors";
import { useIsMediumAndDown } from "../../components/responsive";

import {
  iconForExceptionType,
  textColorForExceptionType
} from "../exceptions/ExceptionLink";

const EventRow = props => {
  const { t } = useTranslation("fv-vin-details");

  const { id, shipment, eventType, eventNum, pushShipmentDetailView } = props;

  const icon = iconForExceptionType(eventType);
  const iconColor = textColorForExceptionType(eventType);

  return (
    <div
      onClick={() => pushShipmentDetailView(id)}
      css={{
        padding: "1em",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        backgroundColor: eventNum % 2 ? "#fff" : "#f5f5f5",
        ":hover": { cursor: "pointer", backgroundColor: "#eee" }
      }}
    >
      <div
        css={{
          color: iconColor,
          fontSize: "x-large",
          display: "flex",
          alignItems: "flex-start",
          marginRight: "0.25em"
        }}
      >
        {icon}
      </div>
      <div
        css={{ fontWeight: "bold", display: "flex", flexDirection: "column" }}
      >
        {`${t("fv-vin-details:Shipment")} ${shipment}`}
        <div css={{ fontWeight: "normal", color: "#999" }}>{eventType}</div>
      </div>
    </div>
  );
};

EventRow.propTypes = {
  eventNum: PropTypes.number,
  eventType: PropTypes.any,
  id: PropTypes.number,
  pushShipmentDetailView: PropTypes.func.isRequired,
  shipment: PropTypes.string
};

const eventsIcon = (
  <MdEventNote
    size="2em"
    color="black"
    style={{
      marginTop: "0.25em",
      padding: "0.25em"
    }}
  />
);

const EventsListPanel = ({ children }) => {
  const { t } = useTranslation("fv-vin-details");
  const isSidebarMinimized = useIsMediumAndDown();
  const isInitiallyOpened = !isSidebarMinimized;

  return (
    <BooleanValue defaultValue={isInitiallyOpened}>
      {({ value: opened, toggle }) => {
        const eventsIconWithToggle = <div onClick={toggle}>{eventsIcon}</div>;

        return (
          <React.Fragment>
            {opened && (
              <PanelContainer
                style={{
                  marginLeft: 0,
                  minWidth: "18em",
                  maxWidth: "20em",
                  maxHeight: "calc(100vh - 9em)"
                }}
              >
                <Panel
                  title={
                    <div className="d-flex flex-row justify-content-between align-items-center w-100">
                      <h4 className="my-2">
                        {t("fv-vin-details:My Recent Events")}
                      </h4>
                      {isSidebarMinimized && eventsIconWithToggle}
                    </div>
                  }
                  canMaximize={false}
                  style={{ border: "none" }}
                  headerStyle={{
                    color: Colors.background.DARK_BLUE
                  }}
                >
                  <div
                    css={{
                      height: "100%",
                      overflow: "scroll",
                      WebkitOverflowScrolling: "touch"
                    }}
                  >
                    {children}
                  </div>
                </Panel>
              </PanelContainer>
            )}
            {!opened && eventsIconWithToggle}
          </React.Fragment>
        );
      }}
    </BooleanValue>
  );
};

EventsListPanel.propTypes = {
  children: PropTypes.node.isRequired
};

class EventsList extends Component {
  static propTypes = {
    clearEvents: PropTypes.func.isRequired,
    currentOrganizationId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    events: PropTypes.array,
    extractEventsFromShipments: PropTypes.func,
    fetchEvents: PropTypes.func.isRequired,
    pushShipmentDetailView: PropTypes.func.isRequired,
    shipments: PropTypes.object
  };

  componentDidMount() {
    const {
      fetchEvents,
      shipments,
      extractEventsFromShipments,
      clearEvents
    } = this.props;

    // Clear any existing events in our state
    clearEvents();

    // If a list of shipments was passed to us
    // we will extract the events from those shipments
    // otherwise, we will fetch all recent events
    if (shipments) {
      extractEventsFromShipments(shipments);
    } else {
      fetchEvents();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      currentOrganizationId,
      extractEventsFromShipments,
      fetchEvents
    } = this.props;

    if (this.props.shipments) {
      if (this.props.shipments.length !== prevProps.shipments.length) {
        extractEventsFromShipments(this.props.shipments);
      }
    }

    /* DEV-1084 Update Events on Organization change */
    if (currentOrganizationId !== prevProps.currentOrganizationId) {
      fetchEvents();
    }
  }

  render() {
    const { events, pushShipmentDetailView } = this.props;

    const eventRows = events.map((e, i) => (
      <EventRow
        key={i}
        eventNum={i}
        id={e.shipment_id}
        shipment={e.shipment}
        eventType={e.type}
        pushShipmentDetailView={pushShipmentDetailView}
      />
    ));
    return <EventsListPanel>{eventRows}</EventsListPanel>;
  }
}

export default withTranslation(["fv-vin-details"])(EventsList);
