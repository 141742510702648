/** @jsx jsx */
import { jsx } from "@emotion/core";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaQuestionCircle } from "react-icons/fa";

import { FlexRowDiv } from "../../../styles/container-elements";

const WatchToggle = ({ watch, toggleWatchEntity, style = {} }) => {
  const { t } = useTranslation("fv-vin-details");

  const alertTool = (
    <Tooltip id="alert-tooltip">
      <div
        css={{
          padding: ".75em",
          textAlign: "bottom"
        }}
      >
        {t(
          "fv-vin-details:Add this VIN to your Watched VINs list on the Finished Vehicle Dashboard"
        )}
        :
      </div>
    </Tooltip>
  );

  return (
    <FlexRowDiv
      style={{
        backgroundColor: "white",
        alignItems: "flex-end",
        flex: 1,
        justifyContent: "flex-end",
        padding: "1em",
        fontWeight: 600,
        ...style
      }}
    >
      <div
        css={{ flexDirection: "row", cursor: "pointer" }}
        onClick={toggleWatchEntity}
      >
        <input
          type="checkbox"
          checked={watch ? watch : false}
          onChange={toggleWatchEntity}
          data-qa="checkbox-alert-me-about-this-vin"
        />{" "}
        <span
          css={{
            marginLeft: "0.5em"
          }}
        >
          {t("fv-vin-details:Watch this VIN")}
        </span>
      </div>

      <OverlayTrigger placement="bottom" overlay={alertTool}>
        <FaQuestionCircle
          style={{ marginLeft: ".35em", marginBottom: "0.25em" }}
        />
      </OverlayTrigger>
    </FlexRowDiv>
  );
};
export default WatchToggle;
