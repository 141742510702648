import ApplicationConfig, { getCurrentEnvironment } from "./config";

/**
 * 'path' arg should start with a slash
 * @param path
 * @return {string}
 */
export default function apiUrl(path) {
  const environment = getCurrentEnvironment(true);
  const baseUrl = ApplicationConfig.API[environment]["apiUrl"];
  return `${baseUrl}${path}`;
}

/**
 *
 * @param path
 * @return {string}
 */
export function customerApiUrl(path) {
  const environment = getCurrentEnvironment(true);
  const baseUrl = ApplicationConfig.API[environment]["customerApiUrl"];
  return `${baseUrl}${path}`;
}

// link out to Covisint detail page
export function covisintMessageUrl(messageId) {
  return `https://connect.covisint.com/secured/viewDetailTransactions.do?messageID=${messageId}`;
}
