export const tabsCss = {
  paddingTop: 4,
  backgroundColor: "#99CDFE",
  position: "relative",

  ".react-tabs__tab": {
    backgroundColor: "#ddd",
    marginTop: 4
  },
  ".react-tabs__tab--selected": {
    backgroundColor: "#fff",
    color: "#333",
    height: "52px !important",
    marginTop: "0 !important",
    border: 0
  }
};

export const tabStyle = {
  display: "flex",
  cursor: "pointer",
  alignItems: "center",
  justifyContent: "center",
  height: 48,
  borderRadius: "5px 5px 0 0",
  flexGrow: 1,
  marginLeft: 10
};

export const tabPanelStyle = {
  backgroundColor: "#F2F2F2",
  boxShadow: "0px 15px 12px 0px rgba(0,0,0,0.05)",
  borderRadius: "5px 0px 0px 0px",
  border: "1px solid #aaa"
};
