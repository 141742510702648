import moment from "moment";
import axios from "axios";
import apiUrl from "../../api-url";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";
import { getSolutionId } from "../organizations/OrganizationsState";
import { AdcCapacity } from "../adc-search/AdcCapacity";

// URLS
const STORE_MOUNT_POINT = "adcEntities";
const AGING_VINS_BY_LOCATION_PROPERTY = "agingVinsByLocation";
const ADC_CAPACITY_PROPERTY = "adcCapacity";
const INBOUND_VINS_PROPERTY = "inboundVins";
const DEMAND_AREA_LIST_PROPERTY = "demandAreaList";

/**
 * Transform the returned data into a format easily understandable by our table
 */
const transformAdcLocations = payload => {
  payload = payload.map(loc => {
    const locStatus = AdcCapacity.forPercentFill(loc.percentFill, loc.capacity)
      .label;
    return {
      ...loc,
      displayLocation: `${loc.city}, ${loc.state}`,
      status: locStatus
    };
  });
  return payload;
};

const getEntityLocationUrl = (solutionId, locationId) =>
  apiUrl(`/entity/solution/${solutionId}/entity-location/${locationId}`);

// Ducks
const duckAgingVinsByLocation = buildFetchDuck(
  STORE_MOUNT_POINT,
  AGING_VINS_BY_LOCATION_PROPERTY
);
const duckInboundVins = buildFetchDuck(
  STORE_MOUNT_POINT,
  INBOUND_VINS_PROPERTY
);
const duckAdcCapacity = buildFetchDuck(
  STORE_MOUNT_POINT,
  ADC_CAPACITY_PROPERTY
);
const duckDemandAreaList = buildFetchDuck(
  STORE_MOUNT_POINT,
  DEMAND_AREA_LIST_PROPERTY
);

// Actions

const REQUEST_WATCHED_LOCATIONS = `${STORE_MOUNT_POINT}/REQUEST_WATCHED_LOCATIONS`;
const RECEIVE_WATCHED_LOCATIONS = `${STORE_MOUNT_POINT}/RECEIVE_WATCHED_LOCATIONS`;
const RECEIVE_WATCHED_LOCATIONS_ERROR = `${STORE_MOUNT_POINT}/RECEIVE_WATCHED_LOCATIONS_ERROR`;
const LOCATION_UPDATE_SUCCESS = `${STORE_MOUNT_POINT}/LOCATION_UPDATE_SUCCESS`;

export const DEFAULT_QUERYSTRING =
  "?lob=Vehicles&lad=Distribution&lifeCycleState=Active,Completed,Delivered";

// Action creators

const fetchAgingVinsByLocation = (solutionId, demandArea) => {
  let url = apiUrl(
    `/entity/solution/${solutionId}/entity-location${DEFAULT_QUERYSTRING}`
  );

  if (demandArea) {
    url = `${url}&demandArea=${demandArea}`;
  }

  return dispatch => {
    dispatch(
      duckAgingVinsByLocation.fetch(
        url,
        {
          headers: {
            Accept: "application/json;version=detail"
          }
        },
        payload => {
          return payload || [];
        }
      )
    );
  };
};

/* H2-767 sort is now handled on the backend with sortColumn by percentFill */
const fetchAdcCapacities = (solutionId, demandArea) => {
  let url = apiUrl(
    `/entity/solution/${solutionId}/entity-location${DEFAULT_QUERYSTRING}&sortColumn=percentFill`
  );

  if (demandArea) {
    url = `${url}&demandArea=${demandArea}`;
  }

  return dispatch => {
    dispatch(
      duckAdcCapacity.fetch(url, {}, payload => {
        return payload || [];
      })
    );
  };
};

const fetchInboundVins = (solutionId, locations, demandArea) => {
  let locationQuery = "";
  if (locations && locations.length) {
    const locationIds = locations.map(loc => loc.id);
    locationQuery = `&locationIds=[${locationIds.join()}]`;
  }

  let url = apiUrl(
    `/entity/solution/${solutionId}/entity-location${DEFAULT_QUERYSTRING}${locationQuery}`
  );

  if (demandArea) {
    url = `${url}&demandArea=${demandArea}`;
  }

  return dispatch => {
    dispatch(
      duckInboundVins.fetch(
        url,
        {
          headers: {
            Accept: "application/json;version=groupByDay",
            "x-time-zone": moment.tz.guess()
          }
        },
        payload => {
          return payload || {};
        }
      )
    );
  };
};

const fetchDemandAreaList = solutionId => {
  const url = apiUrl(`/entity-search/solution/${solutionId}/list?demandArea=t`);

  return dispatch => {
    dispatch(
      duckDemandAreaList.fetch(url, {}, payload => {
        return payload || {};
      })
    );
  };
};

const fetchWatchedLocations = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_WATCHED_LOCATIONS
    });

    const solutionId = getSolutionId(getState());
    const url = apiUrl(
      `/entity/solution/${solutionId}/entity-location${DEFAULT_QUERYSTRING}&watched=1`
    );

    let config = {
      headers: {
        Accept: "application/json;version=detail"
      }
    };

    try {
      const resp = await axios.get(url, config);
      return dispatch({
        type: RECEIVE_WATCHED_LOCATIONS,
        payload: { watchedLocations: transformAdcLocations(resp.data.data) }
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_LOCATIONS_ERROR, error });
    }
  };
};

const setWatchLocation = (locationId, watch = true) => {
  return (dispatch, getState) => {
    const solutionId = getSolutionId(getState());
    const url = getEntityLocationUrl(solutionId, locationId);

    return axios
      .patch(url, { watch })
      .then(() => {
        dispatch({ type: LOCATION_UPDATE_SUCCESS });
        dispatch(fetchWatchedLocations());
      })
      .catch(err => {
        throw new Error(err);
      });
  };
};

// Selectors
const getIsWatchedLocationsLoading = state => {
  return state[STORE_MOUNT_POINT].isWatchedLocationsLoading;
};

const getWatchedLocations = state => {
  return state[STORE_MOUNT_POINT].watchedLocations;
};

const getWatchedLocationIDs = state => {
  let watchedLocations = state[STORE_MOUNT_POINT].watchedLocations;
  if (watchedLocations && watchedLocations.length > 0) {
    return watchedLocations.map(location => location.locationId);
  } else {
    return [];
  }
};

// Reducer

const initialState = {
  isWatchedLocationsLoading: false,
  watchedLocations: []
};

const AdcEntityReducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_WATCHED_LOCATIONS:
      return {
        ...state,
        isWatchedLocationsLoading: true
      };
    case RECEIVE_WATCHED_LOCATIONS:
      return {
        ...state,
        watchedLocations: action.payload.watchedLocations,
        isWatchedLocationsLoading: false
      };
    default:
      return state;
  }
};

// Interface
const AdcEntitiesState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchAgingVinsByLocation,
    fetchAdcCapacities,
    fetchInboundVins,
    fetchDemandAreaList,
    fetchWatchedLocations,
    setWatchLocation
  },
  selectors: {
    getAgingVinsByLocation: duckAgingVinsByLocation.selectors.getData,
    getAdcCapacities: duckAdcCapacity.selectors.getData,
    getInboundVins: duckInboundVins.selectors.getData,
    getDemandAreaList: duckDemandAreaList.selectors.getData,
    getIsWatchedLocationsLoading,
    getWatchedLocations,
    getWatchedLocationIDs
  },
  reducer: chainReducers([
    duckAgingVinsByLocation.reducer,
    duckAdcCapacity.reducer,
    duckInboundVins.reducer,
    duckDemandAreaList.reducer,
    AdcEntityReducer
  ])
};

export default AdcEntitiesState;
