/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import _ from "lodash";
import { Component } from "react";
import { AiFillFire } from "react-icons/ai";
import Loader from "react-loader";
import { getDefaultUnclassifiedLad } from "../lads/LadsState";
import SimpleMap from "../map/components/SimpleMap";
import AvailableLocationsPanel from "./AvailableLocationsPanel";
import CurrentLocationPanel from "./CurrentLocationPanel";
import LocationMatchingControls from "./LocationMatchingControls";
import { buildLinkPayload } from "./LocationMatchingState";

import SearchBarContainer from "../location-search/LocationSearchBarContainer";
import { withTranslation } from "react-i18next";

/*******************************************************************************
 * Styled Components
 ******************************************************************************/

const MapSection = styled.section({
  display: "flex",
  flex: 0.35,
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "white"
});

const PanelSection = styled.section({
  display: "flex",
  flex: 0.65,
  flexDirection: "column",
  justifyContent: "space-between",
  height: "calc(100vh - 7.5em)",
  backgroundColor: "white",
  position: "relative"
});

const HeatmapButton = props => {
  const { onToggle, toggled, loading } = props;
  const ButtonWrapper = styled.div({
    position: "absolute",
    top: 25,
    marginLeft: "-40px",
    transition: "color .3s",
    color: toggled ? "darkorange" : "black",
    ":hover": {
      cursor: "pointer",
      color: "darkorange"
    }
  });
  return (
    <ButtonWrapper toggled={toggled} onClick={!loading ? onToggle : null}>
      <Loader loaded={!loading}>
        <AiFillFire size="40px" />
      </Loader>
    </ButtonWrapper>
  );
};

HeatmapButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  toggled: PropTypes.bool.isRequired
};

/*******************************************************************************
 * Main component
 ******************************************************************************/

class LocationMatchingView extends Component {
  static propTypes = {
    locationID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    clearSearchFilters: PropTypes.func.isRequired,
    clearSearchText: PropTypes.func.isRequired,
    clearUnresolvedLocation: PropTypes.func.isRequired,
    fetchHeatMapData: PropTypes.func.isRequired,
    fetchLocationDetails: PropTypes.func.isRequired,
    fetchUnresolvedLocationDetails: PropTypes.func.isRequired,
    heatmapData: PropTypes.any,
    heatmapIsLoading: PropTypes.any,
    heatmapUid: PropTypes.any,
    isUnresolvedLocationLoading: PropTypes.any,
    lads: PropTypes.any,
    linkLocation: PropTypes.any,
    locationSearchResults: PropTypes.array.isRequired,
    organizations: PropTypes.any,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    pushLocationEditScreen: PropTypes.any,
    pushLocationsScreen: PropTypes.any,
    pushShipmentDetailView: PropTypes.any,
    returnToPreviousScreen: PropTypes.any,
    searchLocations: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    setReturnToPreviousScreen: PropTypes.func.isRequired,
    setSearchFilter: PropTypes.func.isRequired,
    shipment: PropTypes.any,
    solutionId: PropTypes.any,
    totalPages: PropTypes.number,
    unresolvedLocation: PropTypes.any
  };

  state = {
    searchObj: null,
    selectedLocationIds: [],
    filteredLocationIds: [],
    aggregatedHeatmap: [],
    lastHeatmapProcessed: null,
    heatmapIdBeingFetched: 0,
    showHeatmap: false,
    updateHeatmap: true,
    deselected: false
  };

  constructor(props) {
    super(props);

    this.eventHandler = this.eventHandler.bind(this);
    this.assignLocationMatch = this.assignLocationMatch.bind(this);
    this.handleHeatmapData = this.handleHeatmapData.bind(this);
  }

  componentDidMount() {
    const {
      locationID,
      fetchUnresolvedLocationDetails,
      setReturnToPreviousScreen
    } = this.props;

    // Clear the flag to return to previous screen
    setReturnToPreviousScreen(false);

    fetchUnresolvedLocationDetails(locationID);
  }

  componentDidUpdate(prevProps) {
    const {
      locationID,
      locationSearchResults,
      unresolvedLocation,
      solutionId,
      clearSearchFilters,
      searchLocations,
      setSearchFilter,
      shipment,
      pushShipmentDetailView,
      pushLocationsScreen,
      returnToPreviousScreen
    } = this.props;

    const hasUnresolvedLocationChanged =
      unresolvedLocation &&
      !_.isEmpty(unresolvedLocation) &&
      !_.isEqual(prevProps.unresolvedLocation, unresolvedLocation);

    if (hasUnresolvedLocationChanged) {
      if (unresolvedLocation) {
        // Set the unresolved location ID filter and fetch search results
        clearSearchFilters();
        setSearchFilter("near_location", locationID);
        searchLocations(solutionId);

        // If the location we're looking at has a perfect match
        // in our location list, auto select it
        const selected = locationSearchResults.find(
          l => Number(l.id) === Number(locationID)
        );

        if (selected) {
          this.addLocationIdToSelected(selected);
        } else {
          this.addLocationHeatMapData(locationID);
        }
      }
    }

    // If the return to previous screen becomes active,
    // act upon it.  This is deferred so we can wait
    // for the response the backend on an location link
    // so we then do not return to a shipment details
    // view before the link has completed.
    // Note: If the user refreshes the page before going back, then
    // they will go back to the Location Management view instead,
    // since we do not track the "previous screen" via URL, only
    // via the state.
    if (returnToPreviousScreen) {
      const shipmentID = shipment ? shipment.id : null;
      if (shipmentID) {
        pushShipmentDetailView(shipmentID);
      } else {
        pushLocationsScreen();
      }
    }

    this.handleHeatmapData();
  }

  componentWillUnmount() {
    const {
      clearUnresolvedLocation,
      clearSearchText,
      clearSearchFilters,
      searchLocations,
      solutionId
    } = this.props;
    clearUnresolvedLocation();
    // H1-2442: The same SearchBar is in another page too (LocationsView)
    // Clearing both the text and filters so that the search bar is reset
    // when navigating back to Locations page
    // Note: We already clear filters when the component updates
    clearSearchText();
    clearSearchFilters();
    searchLocations(solutionId);
  }

  handleHeatmapData() {
    const { lastHeatmapProcessed, heatmapIdBeingFetched } = this.state;
    const { heatmapUid } = this.props;

    // If we have never received any heatmap data, there is nothing to do
    if (_.isNil(heatmapUid)) {
      return;
    }

    // If a heatmap was being fetched and the last heatmap processed ID
    // has changed, indicating the reducer has received heatmap data,
    // process it
    if (
      lastHeatmapProcessed !== this.props.heatmapUid &&
      heatmapIdBeingFetched !== null
    ) {
      // Add this heatmap to our list of aggregated data,
      // Update the last processed state value, clear
      // our flag, and set this to be processed
      this.setState(prevState => ({
        aggregatedHeatmap: {
          ...prevState.aggregatedHeatmap,
          [heatmapIdBeingFetched]: this.props.heatmapData
        },
        lastHeatmapProcessed: this.props.heatmapUid,
        heatmapIdBeingFetched: null,
        updateHeatmap: true
      }));
    }
    // Updates heatmap on deselection
    else if (this.state.deselected) {
      this.setState({ updateHeatmap: true, deselected: false });
    }
  }

  addLocationHeatMapData(locationId) {
    const { fetchHeatMapData, heatmapUid } = this.props;

    // This method invokes the fetch for the heatmap
    // data of a specific location.  Before doing the fetch
    // it needs to prep our state so we know to process the
    // heatmap data that is returned.  We store the
    // ID of the location we are fetching and set
    // the last heatmap processed to the current heatmap id
    // this ensures that when a new heatmap UID is generated
    // it will trigger us to process it
    //
    // NOTE: THIS ALL ASSUMES NO NEW HEATMAP REQUESTS COME
    // BEFORE WE GET A RESPONSE.
    // If the user clicks on multiple locations quickly, the
    // wires will get crossed.
    // If this becomes an issue we could further refactor
    // and store heatmaps per location ID in redux
    //
    // Setting updateHeatmap to false will allow for it be
    // redrawn when the component updates

    this.setState({
      heatmapIdBeingFetched: locationId,
      lastHeatmapProcessed: heatmapUid,
      updateHeatmap: false
    });

    // Fetches heatmap data for location
    fetchHeatMapData(locationId);
  }

  addLocationIdToSelected(locationId) {
    const { fetchLocationDetails } = this.props;
    const { selectedLocationIds } = this.state;

    let newIds = selectedLocationIds.slice();
    newIds.push(locationId);

    // Add location to selected IDs
    this.setState(prevState => ({
      selectedLocationIds: newIds
    }));

    // Fetches selected location details
    fetchLocationDetails(locationId);

    // Add selected location heatmap data
    this.addLocationHeatMapData(locationId);

    return true;
  }

  removeLocationIdFromSelected(locationId) {
    const { selectedLocationIds } = this.state;

    let newIds = selectedLocationIds.slice();
    newIds.splice(newIds.indexOf(locationId), 1);

    // Removes location heatmap from deselected location
    delete this.state.aggregatedHeatmap[locationId];

    // Updates state
    this.setState({
      aggregatedHeatmap: this.state.aggregatedHeatmap,
      selectedLocationIds: newIds,
      updateHeatmap: false,
      deselected: true
    });
    return false;
  }

  /*
   * Matchs two locations
   */
  assignLocationMatch() {
    const data = buildLinkPayload(
      this.props.unresolvedLocation,
      this.state.selectedLocationIds[0],
      this.props.shipment
    );
    this.props.linkLocation(data.id, data.payload);
  }

  toggleSelectLocation(locationId) {
    const { selectedLocationIds } = this.state;

    // Check if we need to remove or add the selected location.
    const foundLocation = selectedLocationIds.find(id => id === locationId);

    return foundLocation
      ? this.removeLocationIdFromSelected(locationId)
      : this.addLocationIdToSelected(locationId);
  }

  /*
   * Handles events of selecting and deselecting locations
   */
  eventHandler(val, type) {
    switch (type) {
      case "SELECT_LOCATION":
      case "DISMISS_LOCATION":
        return this.toggleSelectLocation(val);
      default:
        break;
    }
  }

  getSelectedLocations() {
    const { selectedLocationIds } = this.state;
    const { locationSearchResults } = this.props;
    return locationSearchResults.filter(loc =>
      selectedLocationIds.includes(loc.id)
    );
  }

  render() {
    const {
      locationID,
      unresolvedLocation,
      isUnresolvedLocationLoading,
      lads,
      heatmapIsLoading,
      page,
      pageSize,
      totalPages,
      setPagination,
      locationSearchResults
    } = this.props;
    const { showHeatmap, updateHeatmap, aggregatedHeatmap } = this.state;

    const selectedLocations = this.getSelectedLocations();

    if (_.isEmpty(unresolvedLocation)) {
      return null;
    }

    let selectedLad = _.get(unresolvedLocation, "lad");
    if (selectedLad === undefined) {
      selectedLad = lads.find(l => l.default_name === "Unclassified");

      // If selected LAD is still undefined populate it with place holder data
      if (selectedLad === undefined) {
        selectedLad = getDefaultUnclassifiedLad();
      }
    }

    if (selectedLad && selectedLad.id && lads && selectedLad.name) {
      const ladType = lads.find(l => Number(l.id) === Number(selectedLad.id));

      if (ladType) {
        selectedLad = { ...selectedLad, default_name: ladType.default_name };
      }
    }

    const mapLocations = selectedLocations.slice();
    mapLocations.push(unresolvedLocation);

    // Transform heatmap data object into array of heatmap points
    let heatmapCoords = Object.values(aggregatedHeatmap)
      .flat()
      .filter(function(el) {
        return el !== null;
      });

    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div css={{ display: "flex", flexDirection: "row" }}>
          <MapSection>
            {!isUnresolvedLocationLoading && (
              <SimpleMap
                showHeatmap={showHeatmap && updateHeatmap}
                heatmapCoords={heatmapCoords}
                selectedLocation={unresolvedLocation}
                mapLocations={mapLocations}
                lads={lads}
                selectedLad={selectedLad}
                drawAllGeofences
                useBoxChiclets
              />
            )}
          </MapSection>
          {!isUnresolvedLocationLoading && (
            <PanelSection>
              <HeatmapButton
                toggled={showHeatmap}
                onToggle={() => this.setState({ showHeatmap: !showHeatmap })}
                loading={heatmapIsLoading}
              />
              <CurrentLocationPanel
                currentLocation={unresolvedLocation}
                selectedLocations={selectedLocations}
                lads={lads}
                eventHandler={this.eventHandler}
                organizations={this.props.organizations}
              />
              <div css={{ paddingLeft: "1em", paddingRight: "1em" }}>
                <div
                  css={{
                    style: {
                      marginLeft: "1em",
                      marginRight: "1em"
                    }
                  }}
                >
                  <SearchBarContainer isShowingAdvancedSearchButton={false} />
                </div>
              </div>
              <AvailableLocationsPanel
                locations={locationSearchResults}
                lads={lads}
                selectedLocations={selectedLocations}
                eventHandler={this.eventHandler}
                organizations={this.props.organizations}
                page={page}
                totalPages={totalPages}
                pageSize={pageSize}
                setPagination={setPagination}
                locationId={locationID}
              />
            </PanelSection>
          )}
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end"
          }}
        >
          {!isUnresolvedLocationLoading && (
            <LocationMatchingControls
              pushLocationEditScreen={this.props.pushLocationEditScreen}
              enableUpdate={selectedLocations.length === 1}
              assignLocationMatch={this.assignLocationMatch}
              setReturnToPreviousScreen={this.props.setReturnToPreviousScreen}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation(["location-matching"])(LocationMatchingView);
