/** @jsx jsx */
import { jsx } from "@emotion/core";
import _ from "lodash";
import moment from "moment";

import { TiTrash, TiPencil } from "react-icons/ti";

import { FlexDiv, FlexColDiv } from "../../styles/container-elements";
import colors from "../../styles/colors";
import { Translation } from "react-i18next";

const TIME_FORMAT = "HH:mm";
const DATE_FORMAT = "M/D/YY";

const SearchItem = ({ lbl, val }) => {
  return (
    <div css={{ fontSize: "x-small" }}>
      <span css={{ fontStyle: "italic" }}>{lbl}</span>
      {" : "}
      <span css={{ fontWeight: "bold" }}>{val}</span>
    </div>
  );
};

const formatLabel = lbl => _.capitalize(_.replace(lbl, "_", " "));

function getMappedValue(key, val, rest) {
  const {
    statustypes,
    execptiontypes,
    shipmentmodes,
    carriers,
    locations
  } = rest;
  let mapped = "";

  switch (key) {
    case "status":
      mapped =
        statustypes &&
        !_.isEmpty(statustypes) &&
        val &&
        statustypes.find(o => o.id === val)
          ? statustypes.find(o => o.id === val).name
          : "";
      break;
    case "active_exception":
      mapped =
        execptiontypes &&
        !_.isEmpty(execptiontypes) &&
        val &&
        execptiontypes.find(o => o.id === val)
          ? execptiontypes.find(o => o.id === val).name
          : "";
      break;
    case "mode":
      mapped =
        shipmentmodes &&
        !_.isEmpty(shipmentmodes) &&
        val &&
        shipmentmodes.find(o => o.id === val)
          ? shipmentmodes.find(o => o.id === val).name
          : "";
      break;
    case "carrier":
      mapped =
        carriers &&
        !_.isEmpty(carriers) &&
        val &&
        carriers.find(o => o.id === val)
          ? carriers.find(o => o.id === val).name
          : "";
      break;
    case "location":
      mapped =
        locations &&
        !_.isEmpty(locations) &&
        _.size(locations) > 1 &&
        val &&
        locations.find(o => o.id === val)
          ? locations.find(o => o.id === val).name
          : "";
      break;
    case "destination":
    case "origin":
      let locs =
        locations &&
        !_.isEmpty(locations) &&
        _.size(locations) > 1 &&
        !_.isNil(val) &&
        _.size(val) > 1
          ? val.map(l => locations.find(o => o.id === l).name)
          : null;
      mapped = !_.isNil(locs) && !_.isEmpty(locs) ? locs.join(", ") : "";
      break;
    case "pickup":
    case "delivery":
      let dfrom = Date.parse(val.from);
      let dto = Date.parse(val.to);
      mapped = `from: ${moment(dfrom).format(DATE_FORMAT)} ${moment(
        dfrom
      ).format(TIME_FORMAT)} 
        to: ${moment(dto).format(DATE_FORMAT)} ${moment(dto).format(
        TIME_FORMAT
      )}`;
      break;
    case "shipment_id":
    case "part_number":
    case "vin":
    case "order_number":
      mapped = val;
      break;
    case "everything":
      mapped = "everything";
      break;
    case "pro_number":
    case "trailer_number":
    case "trailer_number_filter":
      mapped = !_.isNil(val) ? (Array.isArray(val) ? val.join(", ") : val) : "";
      break;
    default:
      break;
  }
  return mapped;
}

function parseSearch(search, rest) {
  let elem = [];
  let keys = _.keys(search);

  keys.forEach((key, i) => {
    const mappedVal = getMappedValue(key, search[key], rest);

    elem.push(<SearchItem key={i} lbl={formatLabel(key)} val={mappedVal} />);
    if (i < _.size(keys) - 1) {
      elem.push(
        <div
          key={`${i}-seperator`}
          css={{ fontSize: "x-small", marginRight: ".25em" }}
        >
          {","}
        </div>
      );
    }
  });

  return (
    <FlexDiv
      css={{
        color: colors.text.HIT_GRAY,
        marginLeft: "0.5em",
        marginRight: "0.5em",
        flexWrap: "wrap",
        display: "inline-flex"
      }}
    >
      {elem}
    </FlexDiv>
  );
}

const SavedSearchCellRenderer = props => {
  const { original } = props;

  if (original == null) {
    return <div css={{ height: "100%" }} />;
  }

  let searchElem = original.search
    ? parseSearch(original.search, props.columnProps.rest)
    : null;

  return (
    <FlexColDiv height="100%" alignItems="center">
      <FlexDiv
        css={{ marginLeft: "0.5em", marginRight: "0.5em", width: "100%" }}
      >
        {original.name}
      </FlexDiv>
      {searchElem}
    </FlexColDiv>
  );
};

const ActionCellRenderer = props => {
  const { original } = props;
  const { actionhandler } = props.columnProps.rest;
  const linkStyle = {
    textDecoration: "underline",
    color: colors.text.HIT_GRAY,
    cursor: "pointer",
    fontSize: 12,
    background: "none",
    border: "none",
    padding: 0
  };
  const markerStyle = { margin: "0 20 0 2", fontSize: 18 };

  return (
    <Translation ns="profile">
      {t => (
        <div
          css={{
            display: "flex",
            justifyContent: "center",
            alignItems: "space-around",
            fontWeight: 400,
            fontStyle: "italic"
          }}
        >
          <p>
            <button
              style={linkStyle}
              onClick={() => actionhandler(original, "RENAME")}
            >
              {t("profile:Rename")} <TiPencil style={markerStyle} />
            </button>
          </p>
          <p>
            <button
              style={linkStyle}
              onClick={() => actionhandler(original, "DELETE")}
            >
              {t("profile:Delete")} <TiTrash style={markerStyle} />
            </button>
          </p>
        </div>
      )}
    </Translation>
  );
};

export const getColumns = (
  t,
  savedSearches,
  actionhandler,
  statustypes,
  execptiontypes,
  shipmentmodes,
  carriers,
  locations
) => {
  return [
    {
      Header: t("profile:Search Name"),
      Cell: SavedSearchCellRenderer,
      minWidth: 200,
      getProps: () => {
        return {
          statustypes,
          execptiontypes,
          shipmentmodes,
          carriers,
          locations
        };
      }
    },
    {
      Header: t("profile:Actions"),
      id: "actionCell",
      minWidth: 150,
      style: {
        minWidth: 150,
        display: "flex",
        justifyContent: "center"
      },
      Cell: ActionCellRenderer,
      getProps: () => {
        return { actionhandler };
      }
    }
  ];
};
