import { combineReducers } from "redux";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import apiUrl from "../../api-url";
import { alfBy } from "../../components/selectors";
import { createSelector } from "reselect";

const STORE_MOUNT_POINT = "domainData";

const EXCEPTION_TYPES_URL = apiUrl("/shipping-ng/exception_filters");
const STATUS_TYPES_URL = apiUrl("/shipping-ng/status_filters");
const SHIPMENT_MODES_URL = apiUrl("/shipping-ng/shipment_modes");
const CARRIERS_URL = apiUrl("/shipping-ng/carriers");
const SHIPMENT_IDS_URL = apiUrl("/shipping-ng/carrier-shipment-ids");
const PARTS_URL = apiUrl("/shipping-ng/parts");
const ROUTE_IDS_URL = apiUrl("/shipping-ng/route_numbers");
const PRO_NUMBERS_URL = apiUrl("/shipping-ng/pro_numbers");
const TRAILER_NUMBERS_URL = apiUrl("/shipping-ng/trailer_numbers");
const SHIPMENT_TYPES_URL = apiUrl("/shipping-ng/type_filters");
const SHIPPERS_URL = apiUrl("/shipping-ng/shippers");
const ORDER_NUMBERS_URL = apiUrl("/shipping-ng/order_numbers");
const COUNTRIES_URL = apiUrl("/shipping-ng/countries");
const TRIP_PLAN_URL = apiUrl("/shipping-ng/trip_plan_numbers");
const ASSET_IDS_URL = apiUrl("/shipping-ng/asset_ids");
// H1-2342: new fv-shipment-filter handles this endpoint
const TRAIN_IDS_URL = apiUrl("/shipment-filter/train_ids");
const LINE_OF_BUSINESS_URL = apiUrl(
  "/shipment-filter/line_of_business_filters"
);

const exceptionTypeDuck = buildFetchDuck("exceptionTypes");
const statusTypeDuck = buildFetchDuck("statusTypes");
const shipmentModeDuck = buildFetchDuck("shipmentModes");
const carrierDuck = buildFetchDuck("carriers");
const shipmentIDDuck = buildFetchDuck("shipmentIDs");
const partDuck = buildFetchDuck("parts");
const routeIDDuck = buildFetchDuck("routes");
const trailerNumberDuck = buildFetchDuck("trailerNumbers");
const proNumberDuck = buildFetchDuck("proNumbers");
const shipmentTypesDuck = buildFetchDuck("shipmentTypes");
const shippersDuck = buildFetchDuck("shippers");
const orderNumbersDuck = buildFetchDuck("orderNumbers");
const countriesDuck = buildFetchDuck("countries");
const tripPlanDuck = buildFetchDuck("tripPlan");
const assetIdsDuck = buildFetchDuck("assetIds");
const trainIdsDuck = buildFetchDuck("trainIds");
const lineOfBusinessDuck = buildFetchDuck("lineOfBusiness");

// DEV-1070
export const SHIPMENT_EVENTS_FILTER_OPTION_NAMES = [
  // These two will be consolidated (grouped) on "Arrived at Stop"
  "Arrived at Drop-Off",
  "Arrived at Pickup",

  "Arrived at Destination",
  "Arrived at Origin",
  "Available for Delivery",
  "Departed Destination",
  "Departed Origin",

  // These two will be consolidated on "Departed Stop"
  "Departed Drop-Off",
  "Departed Pickup"
];

export function fetchDomainData() {
  return dispatch => {
    dispatch(exceptionTypeDuck.fetch(EXCEPTION_TYPES_URL));
    dispatch(statusTypeDuck.fetch(STATUS_TYPES_URL));
    dispatch(shipmentModeDuck.fetch(SHIPMENT_MODES_URL));
    dispatch(carrierDuck.fetch(CARRIERS_URL));
    dispatch(shipmentIDDuck.fetch(SHIPMENT_IDS_URL));
    dispatch(partDuck.fetch(PARTS_URL));
    dispatch(routeIDDuck.fetch(ROUTE_IDS_URL));
    dispatch(trailerNumberDuck.fetch(TRAILER_NUMBERS_URL));
    dispatch(proNumberDuck.fetch(PRO_NUMBERS_URL));
    dispatch(shipmentTypesDuck.fetch(SHIPMENT_TYPES_URL));
    dispatch(orderNumbersDuck.fetch(ORDER_NUMBERS_URL));
    dispatch(countriesDuck.fetch(COUNTRIES_URL));
    dispatch(tripPlanDuck.fetch(TRIP_PLAN_URL));
    dispatch(assetIdsDuck.fetch(ASSET_IDS_URL));
    dispatch(trainIdsDuck.fetch(TRAIN_IDS_URL));
    dispatch(lineOfBusinessDuck.fetch(LINE_OF_BUSINESS_URL));
  };
}

// Fetch domain data from URLs that vary by organization type
export function fetchOrganizationTypeDomainData(isCarrier = false) {
  return dispatch => {
    // H1-2301: Add Carrier-only querystring to shippers fetch URL
    dispatch(
      shippersDuck.fetch(`${SHIPPERS_URL}${isCarrier ? "?active=f" : ""}`)
    );
  };
}

// selectors
const getExceptionTypes = state => state.domainData.exceptionTypes.data;
const selectExceptionTypesAlphabetically = alfBy(getExceptionTypes, "name");
const getStatusTypes = state => {
  return state.domainData.statusTypes.data;
};
const selectStatusTypesAlphabetically = alfBy(getStatusTypes, "name");

// DEV-1070
const selectShipmentEventsAlphabetically = state => {
  return state.domainData.statusTypes.data.filter(st =>
    SHIPMENT_EVENTS_FILTER_OPTION_NAMES.includes(st.name)
  );
};

const getShipmentModes = state => {
  // If we have valid mode data, otherwise return hard coded defaults
  // some race condition on the details page, results in blank data here
  if (
    state.domainData.shipmentModes.data &&
    state.domainData.shipmentModes.data.length > 1
  ) {
    return state.domainData.shipmentModes.data;
  } else {
    return [
      { id: 1, name: "Truck" },
      { id: 2, name: "Rail" },
      { id: 3, name: "LTL" },
      { id: 4, name: "Intermodal" },
      { id: 5, name: "Ocean" }
    ];
  }
};
const selectShipmentModesAlphabetically = alfBy(getShipmentModes, "name");
const getTruckMode = createSelector(
  getShipmentModes,
  shipmentModes => shipmentModes.find(m => m.name === "Truck").id
);
const getCarriers = state => {
  return state.domainData.carriers.data;
};
const selectCarriersAlphabetically = alfBy(getCarriers, "name");
const getShipmentIDs = state => {
  return state.domainData.shipmentIDs.data;
};
const getParts = state => {
  return state.domainData.parts.data;
};
const getRouteIDs = state => {
  return state.domainData.routeIDs.data;
};
const getTrailerNumbers = state => {
  return state.domainData.trailerNumbers.data;
};
const getProNumbers = state => {
  return state.domainData.proNumbers.data;
};
const getShipmentTypes = state => {
  return state.domainData.shipmentTypes.data;
};
const selectShipmentTypesAlphabetically = alfBy(getShipmentTypes, "name");
const getShippers = state => {
  return state.domainData.shippers.data;
};
const getOrderNumbers = state => {
  return state.domainData.orderNumbers.data;
};
const getCountries = state => {
  return state.domainData.countries.data;
};
const getTripPlanNumbers = state => {
  return state.domainData.tripPlanNumbers.data;
};
const getAssetIds = state => {
  return state.domainData.assetIds.data;
};
const getTrainIds = state => {
  return state.domainData.trainIds.data;
};

const getLineOfBusiness = state => {
  return state.domainData.lineOfBusiness.data;
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData,
    fetchOrganizationTypeDomainData
  },
  selectors: {
    getExceptionTypes,
    selectExceptionTypesAlphabetically,
    getStatusTypes,
    selectStatusTypesAlphabetically,
    selectShipmentEventsAlphabetically,
    getShipmentModes,
    selectShipmentModesAlphabetically,
    getTruckMode,
    getCarriers,
    selectCarriersAlphabetically,
    getShipmentIDs,
    getParts,
    getRouteIDs,
    getTrailerNumbers,
    getProNumbers,
    getShipmentTypes,
    selectShipmentTypesAlphabetically,
    getShippers,
    getOrderNumbers,
    getCountries,
    getTripPlanNumbers,
    getAssetIds,
    getTrainIds,
    getLineOfBusiness
  },
  reducer: combineReducers({
    exceptionTypes: exceptionTypeDuck.reducer,
    statusTypes: statusTypeDuck.reducer,
    shipmentModes: shipmentModeDuck.reducer,
    carriers: carrierDuck.reducer,
    shipmentIDs: shipmentIDDuck.reducer,
    parts: partDuck.reducer,
    routeIDs: routeIDDuck.reducer,
    trailerNumbers: trailerNumberDuck.reducer,
    proNumbers: proNumberDuck.reducer,
    shipmentTypes: shipmentTypesDuck.reducer,
    shippers: shippersDuck.reducer,
    orderNumbers: orderNumbersDuck.reducer,
    countries: countriesDuck.reducer,
    tripPlanNumbers: tripPlanDuck.reducer,
    assetIds: assetIdsDuck.reducer,
    trainIds: trainIdsDuck.reducer,
    lineOfBusiness: lineOfBusinessDuck.reducer
  })
};
