import {
  SelectFilterButton,
  BatchFilterButton,
  DateRangeFilterButton
} from "../../components/search-bar/FilterButtons";
import {
  getBasicQueryString,
  getEmptyQueryString,
  getEntityDateRangeQueryString
} from "../../components/search-bar/search-filter-query-strings";

export const FILTERS = [
  {
    queryKey: "ref:Decoded-Make:1:1",
    label: t => t("connected-car:Make"),
    optionsGetter: props => props.makesFilterOptions,
    component: SelectFilterButton,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "lifeCycleState",
    label: t => t("connected-car:Status"),
    optionsGetter: props => props.getLifeCycleOptions,
    component: SelectFilterButton,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "ref:Decoded-Model:1:1",
    label: t => t("connected-car:Model"),
    optionsGetter: props => props.modelsFilterOptions,
    component: SelectFilterButton,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "ref:Decoded-ModelYear:1:0",
    label: t => t("connected-car:Year"),
    optionsGetter: props => props.yearsFilterOptions,
    component: SelectFilterButton,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "positionUpdateCity",
    label: t => t("connected-car:City"),
    optionsGetter: props => props.citiesFilterOptions,
    component: SelectFilterButton,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "positionUpdateState",
    label: t => t("connected-car:State"),
    optionsGetter: props => props.statesFilterOptions,
    component: SelectFilterButton,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "positionUpdate",
    label: t => t("connected-car:Timestamp"),
    component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString
  },
  {
    queryKey: "batch",
    label: t => t("connected-car:Batch Search"),
    component: BatchFilterButton,
    optionsGetter: props => {
      const { t } = props;
      return [
        { label: t("connected-car:VIN"), value: "vin" },
        { label: t("connected-car:Product Type"), value: "description" }
      ];
    },
    queryBuilder: getEmptyQueryString
  }
];
