/** @jsx jsx */
/**
 * In a SavedSearch there is a modal when you decide to create/edit a new
 * saved search where you will check if it is exactly the search you want to
 * save and also you may edit some data/change some fields there. After doing
 * this you are able to save the search inside the own modal.
 *
 */
import { jsx } from "@emotion/core";
import { PureComponent } from "react";
import { Modal, Button, FormControl } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import "react-widgets/dist/css/react-widgets.css";
import PropTypes from "prop-types";
import _ from "lodash";

import Colors from "../../styles/colors";

class EditSavedSearchModal extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { name: "", showFilters: true };
    this.onSave = this.onSave.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { savedSearch } = this.props;
    const { name } = this.state;

    // If savedSearch was changed, then the name of our form will come
    // from savedSearch data
    if (savedSearch && !_.isEqual(savedSearch, prevProps.savedSearch)) {
      this.setState({
        name: savedSearch.name
      });
    } else if (_.isEmpty(savedSearch) && !name) {
      this.setState({ name: "" });
    }
  }

  onSave() {
    const {
      savedSearch,
      saveSearch,
      editSearch,
      searchFilters,
      searchCategory,
      searchText,
      hide
    } = this.props;

    const { name } = this.state;

    const searchCategoryKey = searchCategory
      ? searchCategory.queryKey
      : "everything";

    if (_.isEmpty(savedSearch)) {
      saveSearch(name, {
        ...searchFilters,
        [searchCategoryKey]: searchText
      });
    } else {
      editSearch(savedSearch.id, name, {
        ...searchFilters,
        [searchCategoryKey]: searchText
      });
    }

    hide();
  }

  render() {
    const {
      show,
      hide,
      savedSearch,
      deleteSearch,
      preventSearchOnChange,
      t
    } = this.props;
    const { name, showFilters } = this.state;

    return (
      <Modal show={show} onHide={() => hide()} size="lg">
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {savedSearch && savedSearch.id != null
              ? t("components:Edit Saved Search")
              : t("components:Add Saved Search")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <div css={{ paddingBottom: "0.5em" }}> {t("components:Name")} </div>
          <FormControl
            style={{
              backgroundColor: "white",
              height: "3em",
              boxShadow: "none"
            }}
            type="text"
            value={name}
            placeholder={t("components:Input Name")}
            onChange={evt => this.setState({ name: evt.target.value })}
            data-qa="input-name-modal-saved-search"
          />

          <div
            css={{
              height: "1em",
              borderBottom: "1px solid #aaa",
              marginBottom: "1em"
            }}
          />
          <div css={{ paddingBottom: "0.5em" }}> {t("components:Search")} </div>
          <this.props.SearchBarContainer
            isShowingFilters={showFilters}
            toggleShowFilters={() =>
              this.setState({ showFilters: !showFilters })
            }
            preventSearchOnChange={preventSearchOnChange}
          />
          <this.props.FilterSectionContainer
            show={showFilters}
            sectionStyle={{ backgroundColor: "#E2E2E2", paddingRight: "12em" }}
            preventSearchOnChange={preventSearchOnChange}
          />
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          {deleteSearch && savedSearch && savedSearch.id && (
            <Button
              style={{
                position: "absolute",
                backgroundColor: Colors.highlight.RED,
                color: "white",
                left: "1em"
              }}
              onClick={() => {
                deleteSearch(savedSearch.id);
                hide();
              }}
              data-qa="button-delete-modal-saved-search"
            >
              {t("shipment-status:Delete")}
            </Button>
          )}
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em",
              border: "1px solid #ccc"
            }}
            onClick={() => {
              hide();
            }}
            data-qa="button-cancel-modal-saved-search"
          >
            {t("components:Cancel")}
          </Button>
          <Button
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300",
              border: "1px solid #ccc"
            }}
            onClick={() => this.onSave()}
            data-qa="button-save-modal-saved-search"
          >
            {t("components:Save")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

EditSavedSearchModal.propTypes = {
  searchFilters: PropTypes.object,
  searchCategory: PropTypes.object,
  searchText: PropTypes.string,
  saveSearch: PropTypes.func,
  editSearch: PropTypes.func,
  SearchBarContainer: PropTypes.elementType,
  FilterSectionContainer: PropTypes.elementType
};

export default withTranslation(["components"])(EditSavedSearchModal);
