/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";
import oldLogo from "../../assets/fv_badge.png"; // TODO: Remove once Auth0 logo is updated
import logo from "../../assets/logos/fv_logo_light.svg";
import logoSm from "../../assets/logos/fv_mark_light.svg";
import { Logo } from "../../components/logos";
import Colors from "../../styles/colors";
import PrimaryNav from "./components/PrimaryNav";
import PrimaryNavMini from "./components/PrimaryNavMini";

/**
 *
 * @param isMini
 * @return {*}
 * @constructor
 */
const LogoContainer = ({ isMini }) => (
  <div
    css={{
      backgroundColor: Colors.highlight.DARK_GRAY,
      cursor: isMini ? "pointer" : "auto",
      position: "relative",
      textAlign: "center",
      height: "3.3em",
      display: "flex",
      alignItems: "center"
    }}
  >
    <Logo
      key="logo"
      style={{ height: 42, margin: "0 auto" }}
      src={isMini ? logoSm : logo}
    />
    {/* TODO: Remove once Auth0 logo is updated */}
    <img
      key="oldLogo"
      src={oldLogo}
      alt="oldLogo"
      style={{ display: "none" }}
    />
  </div>
);

LogoContainer.propTypes = {
  isMini: PropTypes.bool.isRequired
};

class AppNavView extends Component {
  static propTypes = {
    currentLocation: PropTypes.string.isRequired,
    sidebarMinimize: PropTypes.bool.isRequired
  };

  state = {
    scrollX: 0,
    openGroups: []
  };

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll(event) {
    if (this.refs.footer_nav && window.scrollX !== this.state.scrollX) {
      this.setState({ scrollX: window.scrollX });
    }
  }

  render() {
    const { currentLocation, sidebarMinimize } = this.props;

    return (
      <aside
        css={{
          color: Colors.text.HIT_GRAY,
          flexDirection: "column",
          flex: 1,
          zIndex: 300
        }}
      >
        <LogoContainer isMini={sidebarMinimize} />
        <div
          css={{
            marginTop: "0.5em"
          }}
        >
          {sidebarMinimize && (
            <PrimaryNavMini
              currentLocation={currentLocation}
              sidebarMinimize={true}
            />
          )}
          {!sidebarMinimize && (
            <PrimaryNav
              currentLocation={currentLocation}
              sidebarMinimize={false}
            />
          )}
        </div>
      </aside>
    );
  }
}

export default AppNavView;
