import _ from "lodash";
import sizeMe from "react-sizeme";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { SimpleMap } from "./SimpleMap";

import {
  getActiveOrganization,
  getMapTypeOverride
} from "../../organizations/OrganizationsState";
import { isFenceValid } from "../../geofence-edit/geofence-types";

import { getType as getGeofenceType } from "../../geofence-edit/geofence-types";

class GeofenceBuilderMap extends SimpleMap {
  constructor(props) {
    super(props);

    this.debouncedDrawGeofence = _.debounce(this.drawGeofence, 100);
    this.debouncedDrawTrace = _.debounce(this.drawTrace, 5);

    this.state = {
      mapLocations: [],
      hasUpdatedOnce: false,
      mouseGeoCoords: null
    };
  }

  _hasGeofenceChanged(prevProps) {
    const currentGeofence = _.get(this.props, "selectedLocation.geofence");
    const prevGeofence = _.get(prevProps, "selectedLocation.geofence");
    const didChange = !_.isEqual(currentGeofence, prevGeofence);
    return didChange;
  }

  _hasGeofenceTypeChanged(prevProps) {
    const currentGeofence = _.get(this.props, "selectedLocation.geofence");
    const prevGeofence = _.get(prevProps, "selectedLocation.geofence");
    return (
      currentGeofence &&
      prevGeofence &&
      getGeofenceType(currentGeofence) !== getGeofenceType(prevGeofence)
    );
  }

  componentDidUpdate(prevProps) {
    const { hasUpdatedOnce } = this.state;
    const {
      selectedLocation,
      enableGeofenceBuilder,
      enableDraggingGeofence,
      isTracing,
      isNewLocation,
      mapLocations
    } = this.props;

    if (!this.isMapInitialized(prevProps)) return;

    if (!isTracing && prevProps.isTracing) {
      // Stopped tracing a geofence, clear the markers
      this.clearMapMarkers(`tracing:geofence:`);
    }

    const hasGeofenceChanged = this._hasGeofenceChanged(prevProps);

    // When drawing a geofence, only update the zoom level if receiving the
    // geofence for the first time
    const prevGeofenceCoords = _.get(
      prevProps,
      "selectedLocation.geofence.geometry.coordinates"
    );
    const newGeofenceCoords = _.get(
      this.props,
      "selectedLocation.geofence.geometry.coordinates"
    );
    const hasReceivedNewGeofence =
      prevGeofenceCoords &&
      prevGeofenceCoords.length === 0 &&
      newGeofenceCoords.length > 0;

    const deletedGeofence =
      prevGeofenceCoords &&
      prevGeofenceCoords.length &&
      !newGeofenceCoords.length;

    const shouldUpdate =
      !enableGeofenceBuilder ||
      hasReceivedNewGeofence ||
      deletedGeofence ||
      this._hasGeofenceTypeChanged(prevProps) ||
      !hasUpdatedOnce;

    if (hasGeofenceChanged && shouldUpdate) {
      this.clearMap();
    }
    this.clearInfoBubbles();
    this.loadLocations();

    if (selectedLocation && mapLocations.length === 1) {
      if (shouldUpdate || prevProps.selectedLocation === null) {
        const hasSelectedLocationAValidPosition =
          isFenceValid(selectedLocation.geofence) || selectedLocation.latitude;
        if (hasSelectedLocationAValidPosition) {
          this.zoomSingleLocation(selectedLocation);
        }
      }

      if (enableGeofenceBuilder) {
        this.debouncedDrawGeofence(
          selectedLocation,
          enableDraggingGeofence,
          false
        );
      }

      if (isTracing) {
        this.debouncedDrawTrace(selectedLocation);
      }

      if (isNewLocation && hasGeofenceChanged) {
        this.clearMapMarkers("newLocation:centerMarker");
        this.addNewLocationMarker(selectedLocation);
      }

      // H1-82: Reposition map if geofence changes due to an updated geocoded
      // address
      this.repositionMapIfGeofenceChangedAndUserIsNotDrawing(
        selectedLocation,
        hasGeofenceChanged
      );
    }

    this.handleMapSizeChanges(prevProps);

    if (!hasUpdatedOnce) {
      this.setState({ hasUpdatedOnce: true });
    }
  }

  drawLocations() {
    const { enableGeofenceBuilder } = this.props;

    // When geofence building is not enabled, it means that we should show the
    // normal locations with its geofences
    if (!enableGeofenceBuilder) {
      SimpleMap.prototype.drawLocations.call(this);
      this.drawLocationsGeofences();
    }
  }

  addNewLocationMarker(location) {
    if (this.hasLocationGeofence(location)) {
      const position = { lat: location.latitude, lng: location.longitude };
      const id = "newLocation:centerMarker";
      this.createAndAddMapMarker(id, position, null, {
        id: id
      });
    }
  }

  drawTrace(location) {
    const { tracePoints, onPolygonDrawEnd } = this.props;
    const { mouseGeoCoords } = this.state;

    // Remove any previous markers/polylines.
    const tracingGeofencePrefix = `tracing:geofence:${location.id}`;
    this.clearMapMarkers(tracingGeofencePrefix);

    const lastPolyIndex = location.geofence.geometry.coordinates.length - 1;
    const polygonDraft = this.platform.shapes.createPolygonDrawingDraft(
      location.id,
      lastPolyIndex,
      mouseGeoCoords,
      tracePoints,
      onPolygonDrawEnd
    );

    if (polygonDraft) {
      this.platform.addTraceGroup(polygonDraft);
    }
  }

  repositionMapIfGeofenceChangedAndUserIsNotDrawing(
    location,
    hasGeofenceChanged
  ) {
    const { selectedLocation, enableGeofenceBuilder } = this.props;
    const center = _.get(selectedLocation, "geofence.properties.center");
    if (hasGeofenceChanged && center) {
      const pos = {
        lat: center.latitude,
        lng: center.longitude
      };
      if (pos.lat && pos.lng && !enableGeofenceBuilder) {
        this.setMapCenter(pos);
      }
    }
  }
}

GeofenceBuilderMap.propTypes = {
  mapLocations: PropTypes.array,
  popupComponent: PropTypes.elementType,
  popupClickHandler: PropTypes.func,
  showHeatmap: PropTypes.bool,
  heatmapCoords: PropTypes.array,
  selectedLad: PropTypes.object,
  selectedLocation: PropTypes.object,
  isNewLocation: PropTypes.bool,
  useBoxChiclets: PropTypes.bool,

  // Props related to geofence building
  tracePoints: PropTypes.array,
  enableDraggingGeofence: PropTypes.bool,
  enableGeofenceBuilder: PropTypes.bool,
  isTracing: PropTypes.bool,

  // Events
  onMarkerMouseEnter: PropTypes.func,
  onMarkerMouseOut: PropTypes.func,
  onDragGeofenceControlPoint: PropTypes.func,
  onDragPolygonalGeofence: PropTypes.func,
  onDragRadialGeofence: PropTypes.func,
  deletePointFromTrace: PropTypes.func,
  updatePolygonPoints: PropTypes.func,
  deletePointFromPolygon: PropTypes.func,
  onPolygonDrawEnd: PropTypes.func
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state),
    mapTypeOverride: getMapTypeOverride(state),
    ...state.maps
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

const sizeMeHOC = sizeMe({ monitorHeight: true })(GeofenceBuilderMap);
const GeofenceBuilderMapContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(sizeMeHOC);
export default GeofenceBuilderMapContainer;
