import { connect } from "react-redux";
import FilterSection from "../../components/search-bar/FilterSection";
import { FILTERS } from "./MilestoneEventFilterDefs";
import PartnerDashboardSearchBarState from "./PartnerDashboardSearchBarState";
import PartnerMilestoneDomainDataState from "./PartnerMilestoneDomainDataState";

import { getSolutionId } from "../organizations/OrganizationsState";

const mapStateToProps = state => {
  const {
    getLocationsOptions,
    getEventsOptions,
    getStatusCodesOptions,
    getShippersOptions
  } = PartnerMilestoneDomainDataState.selectors;
  return {
    shippersFilterOptions: getShippersOptions(state),
    eventsFilterOptions: getEventsOptions(state),
    statusCodesFilterOptions: getStatusCodesOptions(state),
    locationsFilterOptions: getLocationsOptions(state),
    searchFilters: PartnerDashboardSearchBarState.selectors.getSearchFilters(
      state
    ),
    solutionId: getSolutionId(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    setSearchFilter,
    clearSearchFilter,
    searchEntities
  } = PartnerDashboardSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: key => dispatch(clearSearchFilter(key)),
    searchEntities: solutionId => dispatch(searchEntities(solutionId))
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FilterSection);
