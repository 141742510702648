// Extensions to add VIN search to ShipmentSearchBarState
import axios from "axios";
import debouncePromise from "debounce-promise";
import apiUrl from "../../api-url";

const FETCH_VINS = "vins/FETCH_VINS";
const RECEIVE_VINS = "vins/RECEIVE_VINS";

const vinsUrl = query =>
  apiUrl(`/shipping-ng/vins?pageNumber=0&pageSize=20&vin=${query}`);

// Actions

// Search VINs and dispatch data to store
export const searchVins = (query, getUrl = vinsUrl) => {
  return dispatch => {
    if (query.length < 2) {
      return null;
    }

    dispatch({ type: FETCH_VINS });

    const url = getUrl(query);
    const request = () => axios.get(url);
    const debounceRequest = debouncePromise(request, 500);

    debounceRequest()
      .then(response => {
        const data =
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length
            ? response.data.data
            : [];

        // VINs are returned as single-element arrays
        const vinOptions = data.map(vin => vin[0]);

        dispatch({
          type: RECEIVE_VINS,
          payload: vinOptions
        });
      })
      .catch(err => {
        console.error(err);

        dispatch({
          type: RECEIVE_VINS,
          payload: []
        });
      });
  };
};

// Reducer

const vinsInitialState = {
  vins: [],
  vinsLoading: false
};

export const vinsReducer = (state = vinsInitialState, action = {}) => {
  switch (action.type) {
    case FETCH_VINS:
      return {
        ...state,
        vins: [],
        vinsLoading: true
      };
    case RECEIVE_VINS:
      return {
        ...state,
        vins: action.payload,
        vinsLoading: false
      };
    default:
      return state;
  }
};
