/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";

import LocationSelect from "./components/header/LocationSelect";
import CurrentTabPanel from "./components/CurrentTabPanel";
import { headerTabsCss } from "../../styles/tab-styles";
import MixpanelUtils from "../../trackers/mixpanel";

const AdcDetailsView = props => {
  const {
    fetchLocation,
    solutionId,
    locationId,
    fetchVinSearch,
    changeLocationType,
    locationTypeSelected = "Inbound",
    clearSearchText,
    resetSearchBar,
    location,
    searchLocations,
    pushAdcDetailsView,
    totalEntities
  } = props;

  const { t } = useTranslation("adc-details");

  useEffect(() => {
    MixpanelUtils.track("Viewed Page: ADC / Details");
    fetchLocation(solutionId, locationId);
  }, [solutionId, locationId, fetchLocation]);

  if (!location) {
    return null;
  }

  return (
    <div>
      <Tabs css={headerTabsCss}>
        <TabList>
          <Tab style={{ width: "25%" }}>{t("Current")}</Tab>
          <div css={{ width: "75%", paddingLeft: "1em" }}>
            <LocationSelect
              searchLocations={searchLocations}
              pushAdcDetailsView={pushAdcDetailsView}
            />
          </div>
        </TabList>
        <TabPanel>
          <CurrentTabPanel
            location={location}
            locationTypeSelected={locationTypeSelected}
            totalEntities={totalEntities}
            changeLocationType={changeLocationType}
            solutionId={solutionId}
            locationId={locationId}
            fetchVinSearch={fetchVinSearch}
            clearSearchText={clearSearchText}
            resetSearchBar={resetSearchBar}
          />
        </TabPanel>
      </Tabs>
    </div>
  );
};

AdcDetailsView.propTypes = {
  changeLocationType: PropTypes.func.isRequired,
  clearSearchText: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  fetchVinSearch: PropTypes.func.isRequired,
  location: PropTypes.object,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationTypeSelected: PropTypes.string,
  pushAdcDetailsView: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired,
  searchLocations: PropTypes.any,
  solutionId: PropTypes.string,
  totalEntities: PropTypes.number
};

export default AdcDetailsView;
