/** @jsx jsx */ // eslint-disable-next-line
import React, { Component, useMemo } from "react";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import Colors from "../../../styles/colors";
import { MediaQueries } from "../../../components/responsive";
import TabTable from "../../../components/tab-table";
import DonutChart from "../../../components/charts/DonutChart";
import { vinHoldsFill } from "../fv-dashboard-styled-components/dashboard-columns";
import { generateHeaderCell } from "../../../components/tab-table";
import { FaCircle } from "react-icons/fa";
import _ from "lodash";
import { VictoryPie, VictoryLabel, VictoryTooltip } from "victory";
import ExceptionIcon from "./ExceptionIcon";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";

import {
  FlexDiv,
  FlexColDiv,
  FlexRowDiv
} from "../../../styles/container-elements";

import { sortExceptions } from "../../fv-vin-details/vin-details-utils";

const searchyPropTypes = {
  solutionId: PropTypes.string.isRequired,
  setSearchFilter: PropTypes.func,
  clearSearchFilter: PropTypes.func,
  searchEntities: PropTypes.func
};

export const DataWidgetFrame = ({ children, styles, header }) => {
  return (
    <div
      css={{
        backgroundColor: "white",
        borderRadius: "3px",
        width: "100%",
        boxShadow: "0px 3px 14px 0.5px rgba(0,0,0,0.05)",
        ...styles
      }}
    >
      <div
        css={{
          display: "flex",
          alignItems: "center",
          boxSizing: "border-box",
          backgroundColor: Colors.background.DARK_GRAY,
          height: 54,
          color: "white",
          fontSize: 18,
          padding: 16,
          borderRadius: "3px 3px 0 0"
        }}
      >
        {header}
      </div>
      <div
        css={{
          padding: 16,
          flexGrow: 1,
          [MediaQueries.mediumAndUp]: {
            padding: 0
          }
        }}
      >
        {children}
      </div>
    </div>
  );
};
DataWidgetFrame.propTypes = {
  children: PropTypes.object,
  styles: PropTypes.object,
  header: PropTypes.string
};

const getExceptionData = (exceptions, exceptionTypes, entityCount) => {
  const updatedExceptions = exceptions.map(exception => {
    return {
      ...exception,
      name: exception.name === "In Hold" ? "On Hold" : exception.name,
      percentage:
        entityCount === 0
          ? "0"
          : ((exception.count / entityCount) * 100).toFixed(1)
    };
  });

  exceptionTypes.forEach(type => {
    if (!_.find(updatedExceptions, { reason_code: type.reasonCode })) {
      updatedExceptions.push({
        name: type.name,
        reason_code: type.reasonCode,
        count: 0,
        percentage: 0
      });
    }
  });

  return sortExceptions(updatedExceptions, "name");
};

const exceptionChartColor = exception => {
  switch (exception.name) {
    case "Behind Schedule":
      return Colors.highlight.YELLOW;
    case "Excessive Dwell":
      return Colors.highlight.RED;
    case "On Hold":
    case "In Hold":
      return Colors.highlight.ORANGE;
    case "No Exception":
      return Colors.highlight.GREEN;
    default:
      return null;
  }
};

const getExceptionChartData = (exceptions, entityCount, total) => {
  return [
    ...exceptions,
    { name: "No Exception", count: entityCount - total || 0 }
  ].map(exception => {
    return {
      x: exception.name,
      y: entityCount ? (exception.count / entityCount) * 100 : 0,
      fill: exceptionChartColor(exception)
    };
  });
};

export const FinishedVehicleExceptions = ({
  solutionId,
  exceptions,
  exceptionsLoading,
  setSearchFilter,
  clearSearchFilter,
  searchEntities,
  exceptionTypes,
  entityCount,
  domainDataLoading
}) => {
  const { t } = useTranslation("fv-dashboard");

  const total = useMemo(
    () => exceptions.reduce((acc, curr) => acc + curr.count, 0),
    [exceptions]
  );

  const data = useMemo(
    () => getExceptionData(exceptions, exceptionTypes, entityCount),
    [exceptions, exceptionTypes, entityCount]
  );

  const chartData = useMemo(
    () => getExceptionChartData(exceptions, entityCount, total),
    [exceptions, entityCount, total]
  );

  const handleClick = e => {
    let exceptionTypeIds = [];

    if (e && e.reason_code) {
      exceptionTypeIds.push(
        _.find(exceptionTypes, { reasonCode: e.reason_code }).id
      );
    } else {
      exceptionTypes.forEach(exceptionType => {
        exceptionTypeIds.push(exceptionType.id);
      });
    }

    clearSearchFilter();
    setSearchFilter("exception", exceptionTypeIds);
    searchEntities(solutionId);
  };

  const showAllVinsSearch = () => {
    searchEntities(solutionId);
  };

  /* H1-2116 Add loader check for DomainData to display all the exceptions at the same time once exceptionTypes is loaded*/
  return (
    <DataWidgetFrame
      header={t("fv-dashboard:Finished Vehicle Exceptions")}
      styles={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyItems: "stretch",
        position: "relative"
      }}
    >
      <Loader loaded={!exceptionsLoading && !domainDataLoading} top="62%">
        <FlexDiv
          css={{
            height: "100%",
            flexDirection: "column",
            [MediaQueries.smallAndUp]: {
              flexDirection: "row"
            }
          }}
        >
          <DonutChart
            data={chartData}
            handler={showAllVinsSearch}
            label={t("fv-dashboard:VINS")}
            total={entityCount}
          />

          <FlexColDiv
            css={{
              padding: "0 2em",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
              [MediaQueries.mediumAndUp]: {
                flexDirection: "row",
                flexGrow: 1
              }
            }}
          >
            {data.map((item, i) => (
              <WidgetTotalItem
                t={t}
                key={i}
                data={item}
                handler={handleClick.bind(this, item)}
              />
            ))}
          </FlexColDiv>
        </FlexDiv>
      </Loader>
    </DataWidgetFrame>
  );
};
FinishedVehicleExceptions.propTypes = {
  exceptions: PropTypes.array,
  exceptionsLoading: PropTypes.bool,
  exceptionTypes: PropTypes.array,
  entityCount: PropTypes.number,
  domainDataLoading: PropTypes.bool,
  ...searchyPropTypes
};

export const VinHolds = ({
  t,
  vinHolds,
  holdTypes,
  clearSearchFilter,
  setSearchFilter,
  searchEntities,
  solutionId,
  vinHoldsLoading
}) => {
  // H2-251: Hide the Show More/Less since not wired to a max on the table as of yet
  // const [showAll, setShowAll] = useState(false);

  const data = vinHoldsFill(vinHolds.slice(0, 6));

  // onClick handler for when the user clicks a slice of the pie graph
  const onHoldTypePieSliceClick = (name, reasonCode) => {
    const holdType = holdTypes.find(
      ht => ht.reasonCode === reasonCode && ht.name === name
    );

    if (holdType) {
      clearSearchFilter();
      setSearchFilter("holdType", [holdType.id]);
      searchEntities(solutionId);
    }
  };

  // Column Generator func
  // To be passed into TabTable
  const vinHoldsColumns = (sortKey, sortDir) => {
    return [
      {
        Header: generateHeaderCell(t("fv-dashboard:Hold Type"), false),
        accessor: "type",
        Cell: cellInfo => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontWeight: 400
              }}
            >
              <FaCircle
                style={{
                  marginRight: 8,
                  fontSize: 10,
                  color: cellInfo.original.fill
                }}
              />
              {cellInfo.original.fullDescription}
            </div>
          );
        }
      },
      {
        Header: generateHeaderCell(t("fv-dashboard:VINs"), false),
        width: 100,
        accessor: "vins_associated"
      }
    ];
  };

  return (
    <DataWidgetFrame
      header={t("fv-dashboard:Top Hold Types")}
      styles={{
        backgroundColor: Colors.background.LIGHT_GRAY
      }}
    >
      <div
        css={{
          position: "relative",
          backgroundColor: "white",
          minHeight: 290,
          margin: -16,
          [MediaQueries.mediumAndUp]: {
            margin: "unset"
          }
        }}
      >
        <Loader loaded={!vinHoldsLoading}>
          <div
            css={{
              position: "relative"
            }}
          >
            <VinHoldCount
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: "75%",
                bottom: "75%"
              }}
              count={_.sumBy(data, "vins_associated")}
            />
            <div
              css={{
                position: "relative"
              }}
            >
              <VictoryPie
                height={185}
                origin={{ y: 185 }}
                style={{
                  data: {
                    fill: ({ datum }) => datum.fill
                  }
                }}
                x="type"
                y="vins_associated"
                startAngle={-90}
                endAngle={90}
                events={[
                  {
                    target: "data",
                    eventHandlers: {
                      onClick: () => {
                        return [
                          {
                            target: "data",
                            mutation: p => {
                              onHoldTypePieSliceClick(
                                p.datum.type,
                                p.datum.reason_code
                              );
                            }
                          }
                        ];
                      }
                    }
                  }
                ]}
                radius={165}
                innerRadius={100}
                data={data}
                labels={({ datum }) => datum.type}
                labelComponent={<VinHoldsTooltip x={200} y={300} />}
              />
            </div>
          </div>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 10px",
              backgroundColor: "white",
              marginTop: -5
            }}
          >
            <TabTable
              data={data}
              columns={vinHoldsColumns}
              rowClickHandler={onHoldTypePieSliceClick}
              sortable={false} // H2-940: Columns for Hold Type and VINS should not be sortable
            />
            {/* H2-251 Hide the Show More/Less since not wired to a max on the table as of yet */}
            {/* <span
            onClick={() => setShowAll(!showAll)}
            css={{
              textAlign: "center",
              fontStyle: "italic",
              fontSize: 14,
              color: Colors.text.GRAY,
              textDecoration: "underline",
              cursor: "pointer"
            }}
          >
            {showAll ? "Show Less" : "Show More"}
          </span> */}
          </div>
        </Loader>
      </div>
    </DataWidgetFrame>
  );
};
VinHolds.propTypes = {
  t: PropTypes.func,
  vinHolds: PropTypes.array,
  holdTypes: PropTypes.array,
  vinHoldsLoading: PropTypes.bool,
  ...searchyPropTypes
};

const VinHoldCount = ({ style, count }) => {
  const { t } = useTranslation("fv-dashboard");
  return (
    <svg css={style} viewBox="0 0 100 100">
      <text
        x="20"
        y="15"
        fontSize="20"
        fontWeight="bold"
        alignmentBaseline="text-before-edge"
      >
        {count}
      </text>
      <text x="20" y="35" fontSize="14" alignmentBaseline="text-before-edge">
        {t("fv-dashboard:Holds")}
      </text>
    </svg>
  );
};
VinHoldCount.propTypes = {
  style: PropTypes.object,
  count: PropTypes.number
};

class VinHoldsTooltip extends Component {
  render() {
    const { active, datum, x, y } = this.props;
    return active ? (
      <g>
        <VictoryLabel
          x={x}
          y={y - 170}
          text={`${datum.vins_associated} VINs`}
          textAnchor="middle"
          style={{
            textAlign: "center",
            fontSize: 32,
            fontWeight: 600,
            fill: Colors.holds.DARK_BLUE
          }}
        />
        <VictoryLabel
          x={x}
          y={y - 135}
          /* H2-940: Add Hold Code next to Description in Parentheses
           * Truncate the text because it can be too long to fit in the inner part of the pie graph
           * Keep in mind that this is user submitted data, the user can use mixed casing or all caps.
           * Because of this, we are forcing all caps because the capital letters in the font are
           *   generally the same width with a few exceptions (e.g. "I")
           *
           * Note: Unfortunately, this is SVG text, it is unaffected by CSS's text-overflow: ellipsis
           */
          text={_.truncate(datum.fullDescription.toUpperCase(), {
            length: 22
          })}
          textAnchor="middle"
          style={{
            textAlign: "center",
            fontSize: 15,
            fill: `${datum.fill}`
          }}
        />
      </g>
    ) : null;
  }
}
VinHoldsTooltip.defaultEvents = VictoryTooltip.defaultEvents;
VinHoldsTooltip.propTypes = {
  active: PropTypes.bool,
  datum: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number
};

export const WidgetTotalItem = ({ t, data, handler = null }) => {
  return (
    <FlexRowDiv
      css={{
        border: "1px solid white",
        borderRadius: "5px",
        margin: "0.25em",
        padding: "0.25em",
        ":hover": {
          border: `1px solid ${Colors.background.GRAY_BLUE}`,
          backgroundColor: "#fcfcfc",
          cursor: handler ? "pointer" : "auto",
          boxShadow: `inset 0 0 1px ${Colors.background.GRAY_BLUE}`
        }
      }}
      onClick={handler}
    >
      <div
        css={{
          marginTop: ".15em",
          marginRight: 10
        }}
      />
      <FlexColDiv>
        <FlexDiv>
          <span
            css={{
              margin: "5px 5px 0 0"
            }}
          >
            <ExceptionIcon exceptionType={data.name} width={24} height={24} />
          </span>
          <span
            css={{
              color: Colors.background.DARK_BLUE,
              fontSize: "x-large"
            }}
          >
            {data.count}
          </span>
          <span
            css={{
              fontSize: 16,
              color: Colors.text.GRAY,
              padding: "9px 0 0 10px"
            }}
          >
            {data.percentage}%
          </span>
        </FlexDiv>
        <div
          css={{
            fontSize: 13,
            color: Colors.background.DARK_BLUE,
            fontStyle: "italic",
            fontWeight: 600
          }}
        >
          {t(`exceptions:${data.name}`)}
        </div>
      </FlexColDiv>
    </FlexRowDiv>
  );
};

WidgetTotalItem.propTypes = {
  t: PropTypes.func,
  data: PropTypes.object,
  handler: PropTypes.func
};
