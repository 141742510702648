/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import Colors from "../../styles/colors";
import { MediaQueries } from "../responsive";

// Column within a DefinitionList - contains title text and child elements
// Used on Shipment Details, VIN Details, ADC Details
const DefinitionListItem = ({
  title,
  children,
  border,
  styles,
  titleStyles,
  "data-qa": dataQa
}) => {
  return (
    <li
      css={{
        border: border ? `1px solid ${border}` : null,
        borderRadius: 3,
        flex: 1,
        padding: "10px 5px",
        ...styles
      }}
    >
      <h6
        css={{
          color: Colors.text.GRAY,
          fontSize: 14,
          fontWeight: "normal",
          marginBottom: 10,
          minHeight: "2em",
          [MediaQueries.largeAndUp]: {
            minHeight: "0em"
          },
          ...titleStyles
        }}
      >
        {title}
      </h6>
      <div
        css={{
          color: Colors.text.DARK_GRAY
        }}
        data-qa={dataQa}
      >
        {children}
      </div>
    </li>
  );
};

DefinitionListItem.propTypes = {
  border: PropTypes.string,
  children: PropTypes.node,
  styles: PropTypes.object,
  title: PropTypes.string,
  titleStyles: PropTypes.string
};

export default DefinitionListItem;
