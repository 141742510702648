/** @jsx jsx */
import { jsx } from "@emotion/core";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Colors from "../../../styles/colors";

const buttonStyle = {
  backgroundColor: Colors.background.DARK_GRAY,
  border: 0,
  borderRadius: 16,
  color: "#fff",
  height: 32,
  outline: "none",
  position: "absolute",
  textAlign: "center",
  top: 15,
  width: 32
};

const PrevTabPageButton = props => {
  const { isVisible, onClick } = props;
  const style = {
    ...buttonStyle,
    display: isVisible ? "block" : "none",
    left: 125
  };

  return (
    <button style={style} onClick={onClick}>
      <IoIosArrowBack />
    </button>
  );
};

const NextTabPageButton = props => {
  const { isVisible, onClick } = props;
  const style = {
    ...buttonStyle,
    display: isVisible ? "block" : "none",
    right: 10
  };

  return (
    <button style={style} onClick={onClick}>
      <IoIosArrowForward />
    </button>
  );
};

const LegTabPageButtons = props => {
  const {
    showPrevTabPageButton,
    showNextTabPageButton,
    showPrevTabPage,
    showNextTabPage
  } = props;

  return (
    <div>
      <PrevTabPageButton
        isVisible={showPrevTabPageButton}
        onClick={showPrevTabPage}
      />
      <NextTabPageButton
        isVisible={showNextTabPageButton}
        onClick={showNextTabPage}
      />
    </div>
  );
};

export default LegTabPageButtons;
