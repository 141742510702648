import { connect } from "react-redux";

import FinVehicleDashboard from "./FinVehicleDashboard";
import FinVehicleEntitiesState from "./FinVehicleEntitiesState";
import FinVehicleExceptionsState from "./FinVehicleExceptionsState";
import FinVehicleHoldsState from "./FinVehicleHoldsState";
import FinVehicleSearchBarState from "../fv-search/FinVehicleSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import finVehicleDomainData from "../domain-data/FinVehicleDomainData";

function mapStateToProps(state) {
  return {
    exceptions: FinVehicleExceptionsState.selectors.getExceptions(state),
    exceptionTypes: finVehicleDomainData.selectors.getExceptionTypes(state),
    holdTypes: finVehicleDomainData.selectors.getHoldTypes(state),
    entityCount: FinVehicleEntitiesState.selectors.getEntityCount(state),
    solutionId: getSolutionId(state),
    vinHolds: FinVehicleHoldsState.selectors.selectHoldCountsForWidget(state),
    vinHoldsLoading: FinVehicleHoldsState.selectors.getHoldCountsLoading(state),
    exceptionsLoading: FinVehicleExceptionsState.selectors.getIsLoading(state),
    showFilters: FinVehicleSearchBarState.selectors.getShowAdvancedSearch(
      state
    ),
    domainDataLoading: finVehicleDomainData.selectors.getDomainDataLoading(
      state
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchExceptions: solutionId =>
      dispatch(
        FinVehicleExceptionsState.actionCreators.fetchExceptions(solutionId)
      ),
    fetchEntityCount: solutionId =>
      dispatch(
        FinVehicleEntitiesState.actionCreators.fetchEntityCount(solutionId)
      ),
    fetchHoldCounts: solutionId =>
      dispatch(FinVehicleHoldsState.actionCreators.fetchHoldCounts(solutionId)),
    selectSavedSearch: item =>
      dispatch(FinVehicleSearchBarState.actionCreators.selectSavedSearch(item)),
    resetSavedSearch: () =>
      dispatch(FinVehicleSearchBarState.actionCreators.resetSavedSearch()),
    setSearchFilter: (key, value) =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.setSearchFilter(key, value)
      ),
    clearSearchFilter: key =>
      dispatch(FinVehicleSearchBarState.actionCreators.clearSearchFilter(key)),
    searchEntities: solutionId =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.searchEntities(solutionId)
      ),
    resetSearchBar: () =>
      dispatch(FinVehicleSearchBarState.actionCreators.resetSearchBar()),
    clearSearchFilters: () =>
      dispatch(FinVehicleSearchBarState.actionCreators.clearSearchFilters()),
    toggleShowFilters: showFilters =>
      dispatch(
        FinVehicleSearchBarState.actionCreators.toggleShowFilters(showFilters)
      )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinVehicleDashboard);
