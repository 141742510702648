import { connect } from "react-redux";
import VinDetailsView from "./VinDetailsView";
import FinVehicleEntityDetailsState from "./FinVehicleEntityDetailsState";
import FinVehicleTripLegState from "./FinVehicleTripLegState";
import LadsState from "../lads/LadsState";
import MapState from "../map/MapState";
import ShipmentsStatusState from "../shipment-status/ShipmentsState";
import { getSolutionId } from "../organizations/OrganizationsState";
import DomainDataState from "../domain-data/DomainDataState";
import { getCombinedTripLegs } from "./TripProgressSelectors";

function mapStateToProps(state) {
  const { entity_id: entityId } = state.location.payload;

  const {
    getEntityDetails,
    getEntityPositionUpdates,
    getEntityConnectedCarPositionUpdates,
    getIsShowingConnectedCarPositions,
    getCombinedEntityExceptions,
    getEntityCurrentLocation
  } = FinVehicleEntityDetailsState.selectors;

  const {
    getPlannedTripLeg,
    getActualTripLeg
  } = FinVehicleTripLegState.selectors;

  const { getLadsList } = LadsState.selectors;

  const {
    getShipmentDetails,
    getChildShipmentDetails,
    getIsLoaded
  } = ShipmentsStatusState.selectors;

  const { getSelectedCoordinate } = MapState.selectors;

  return {
    solutionId: getSolutionId(state),
    entityId,
    ladsList: getLadsList(state),
    details: getEntityDetails(state),
    coords: getEntityPositionUpdates(state),
    connectedCarCoords: getEntityConnectedCarPositionUpdates(state),
    isShowingConnectedCarPositions: getIsShowingConnectedCarPositions(state),
    plannedLegs: getPlannedTripLeg(state),
    actualLegs: getActualTripLeg(state),
    combinedLegs: getCombinedTripLegs(state),
    shipmentModes: DomainDataState.selectors.getShipmentModes(state),
    parentShipment: getShipmentDetails(state),
    childShipments: getChildShipmentDetails(state),
    shipmentsLoaded: getIsLoaded(state),
    selectedCoordinate: getSelectedCoordinate(state),
    combinedExceptions: getCombinedEntityExceptions(state),
    currentLocation: getEntityCurrentLocation(state)
  };
}

const pushShipmentDetailView = dispatch => (
  creatorShipmentId,
  scac,
  vinNumber
) => {
  dispatch({
    type: "SHIPMENT_DETAIL_FINISHED_VEHICLE",
    payload: {
      carrier_scac: scac,
      creator_shipment_id: creatorShipmentId,
      vin: vinNumber
    }
  });
};

const {
  fetchEntityExceptions,
  fetchEntityDetails,
  fetchEntityMedia,
  clearEntityMedia,
  fetchEntityHolds,
  fetchEntityPositionUpdates,
  fetchEntityEvents,
  setWatchEntity
} = FinVehicleEntityDetailsState.actionCreators;
const {
  fetchPlannedTripLeg,
  fetchActualTripLeg
} = FinVehicleTripLegState.actionCreators;
const { fetchLegShipmentDetails } = ShipmentsStatusState.actionCreators;
const { selectCoordinate, clearCoordinate } = MapState.actionCreators;

const actionCreators = {
  fetchEntityPositionUpdates,
  fetchEntityMedia,
  clearEntityMedia,
  fetchEntityDetails,
  fetchEntityExceptions,
  fetchEntityHolds,
  fetchEntityEvents,
  fetchPlannedTripLeg,
  fetchActualTripLeg,
  fetchLegShipmentDetails,
  setWatchEntity,
  pushShipmentDetailView,
  selectCoordinate,
  clearCoordinate
};

export default connect(mapStateToProps, actionCreators)(VinDetailsView);
