import { connect } from "react-redux";
import FilterSection from "../../components/search-bar/FilterSection";
import AdcSearchBarState from "./AdcSearchBarState";
import LadsState from "../lads/LadsState";
import { FILTERS } from "./AdcSearchResultsSearchOptions";
import { getSolutionId } from "../organizations/OrganizationsState";
import {
  selectProductTypeFilterOptions,
  selectDemandAreaFilterOptions
} from "./AdcFilterSectionSelectors";
import {
  getAdcLocationFilterOptions,
  getAdcCountryFilterOptions,
  getAdcCapacityFilterOptions
} from "./AdcSearchBarStateSelectors";

const mapStateToProps = state => ({
  ladsFilterOptions: LadsState.selectors.selectLadVehicleOptions(state),
  solutionId: getSolutionId(state),
  searchFilters: AdcSearchBarState.selectors.getSearchFilters(state),
  productTypeFilterOptions: selectProductTypeFilterOptions(state),
  adcLocationOptions: getAdcLocationFilterOptions(state),
  adcCountryOptions: getAdcCountryFilterOptions(state),
  capacityOptions: getAdcCapacityFilterOptions(state),
  demandAreaOptions: selectDemandAreaFilterOptions(state)
});

const {
  setSearchFilter,
  clearSearchFilter,
  searchEntities
} = AdcSearchBarState.actionCreators;

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS
});

export default connect(
  mapStateToProps,
  {
    setSearchFilter,
    clearSearchFilter,
    searchEntities
  },
  mergeProps
)(FilterSection);
