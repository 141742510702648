/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Suspense } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { getActiveOrganization } from "../../../modules/organizations/OrganizationsState";
import { getAuthorization } from "../../auth/AuthorizationSelectors";
import { routeGroupsMap } from "../../../routes";
import NavGroupMini from "./NavGroupMini";
import DocumentationNav from "../../documentation/DocumentationNav";
import { groupData } from "./NavRouteAuthorization";
import { primaryNavCss, getNavLinks } from "./PrimaryNav";
import { getClosestVisibleRouteMapEntry } from "../../../route-selectors";

const PrimaryNavMini = ({ sidebarMinimize }) => {
  const authorization = useSelector(getAuthorization);
  const activeOrganization = useSelector(getActiveOrganization);
  const closestRouteMapEntry = useSelector(getClosestVisibleRouteMapEntry);

  const groups = Object.entries(routeGroupsMap).map(entry => {
    const groupKey = entry[0];
    const navGroup = entry[1];

    if (navGroup.hidden) {
      return null;
    }

    const { links, privileges, features, organizationTypes } = groupData(
      authorization,
      activeOrganization,
      navGroup
    );
    const linkElems = getNavLinks(links, groupKey, closestRouteMapEntry);
    const isAuthorized = authorization.isAuthorized(
      privileges,
      features,
      organizationTypes
    );

    if (!links || !links.length || !isAuthorized) {
      return null;
    }

    return (
      <NavGroupMini
        key={`nav-item-${_.kebabCase(navGroup.title)}`}
        title={navGroup.title}
        icon={navGroup.icon}
      >
        {linkElems}
      </NavGroupMini>
    );
  });

  return (
    <Suspense fallback="">
      <div css={primaryNavCss}>
        {groups}
        <DocumentationNav isOpen={true} sidebarMinimize={sidebarMinimize} />
      </div>
    </Suspense>
  );
};

export default PrimaryNavMini;

PrimaryNavMini.propTypes = {
  sidebarMinimize: PropTypes.bool
};
