import { connect } from "react-redux";

import CreateMilestoneView from "./CreateMilestoneView";
import {
  getActiveOrganization,
  getOrganizations
} from "../organizations/OrganizationsState";
import CreateMilestoneState from "./CreateMilestoneState";

function mapStateToProps(state) {
  const { getCreateStatus } = CreateMilestoneState.selectors;

  return {
    createStatus: getCreateStatus(state),
    activeOrganization: getActiveOrganization(state),
    organizations: getOrganizations(state)
  };
}

function mapDispatchToProps(dispatch) {
  const {
    createMilestone,
    resetCreateForm
  } = CreateMilestoneState.actionCreators;
  return {
    createMilestone: (solutionId, data) =>
      dispatch(createMilestone(solutionId, data)),
    resetCreateForm: () => dispatch(resetCreateForm())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateMilestoneView);
