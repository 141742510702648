import Colors from "../../../styles/colors";

export const popupCss = {
  backgroundColor: "white",
  borderRadius: 4,
  color: Colors.text.DARK_GRAY,
  padding: "1em",
  fontSize: "x-small",
  width: "20em",
  border: `1px solid ${Colors.background.WARM_GRAY}`,

  dl: {
    display: "grid",
    gridTemplateColumns: "4em auto",
    columnGap: ".5em",
    rowGap: 0,
    marginBottom: ".5em"
  },

  dt: {
    fontWeight: "normal",
    textAlign: "right"
  },

  dd: {
    fontWeight: "bold",
    marginBottom: 0
  },

  ".btn-success": {
    fontSize: "x-small",
    margin: "0 auto"
  },

  ".page-container": {
    color: "white",
    backgroundColor: Colors.background.WARM_GRAY,
    borderRadius: "0 0 3px 3px",
    padding: 4,
    margin: "10px -10px -10px -10px",
    alignItems: "center",
    fontWeight: "bold"
  },

  ".page-icon": {
    marginTop: -4
  },

  ".page-number": {
    flex: 1,
    textAlign: "center"
  }
};

export const pageButtonCss = isDisabled => {
  return {
    width: 16,
    height: 16,
    padding: 0,
    fontSize: 12,
    border: 0,
    backgroundColor: isDisabled
      ? "white"
      : `${Colors.background.DARK_TEAL} !important`,
    color: isDisabled ? Colors.text.DARK_GRAY : "white !important"
  };
};
