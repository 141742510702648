import _ from "lodash";

export function lerp(x, minX, maxX, minY, maxY) {
  return _.max([((x - minX) * (maxY - minY)) / (maxX - minX) + minY, 0.001]);
}

export function getDistance(p1, p2) {
  return Math.sqrt(
    (p1.lat - p2.lat) * (p1.lat - p2.lat) +
      (p1.lng - p2.lng) * (p1.lng - p2.lng)
  );
}

/**
 * Calculate the center/average of multiple GeoLocation coordinates
 * Expects an array of objects with .latitude and .longitude properties
 *
 * @url http://stackoverflow.com/a/14231286/538646
 */
export const centralGeolocation = coords => {
  if (coords.length === 1) {
    return { lat: coords[0].latitude, lng: coords[0].longitude };
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (let coord of coords) {
    let latitude = (coord.latitude * Math.PI) / 180;
    let longitude = (coord.longitude * Math.PI) / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  let total = coords.length;

  x = x / total;
  y = y / total;
  z = z / total;

  let centralLongitude = Math.atan2(y, x);
  let centralSquareRoot = Math.sqrt(x * x + y * y);
  let centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    lat: (centralLatitude * 180) / Math.PI,
    lng: (centralLongitude * 180) / Math.PI
  };
};

export const convertLocationAndItsGeofenceToPoints = location => {
  const extractGeopointsRecursively = array => {
    const points = [];
    if (_.isEmpty(array)) {
      return [];
    } else if (_.isArray(array[0])) {
      points.push(...extractGeopointsRecursively(array[0]));
    } else {
      return [
        {
          longitude: array[0],
          latitude: array[1]
        }
      ];
    }
    return points;
  };
  // Need to be this way (recursively) because we don't know upfront the
  // geometry of the geofence, so, if we do it recursively there is no
  // difference to worry about
  const allGeofencePoints = extractGeopointsRecursively(
    location.geofence.geometry.coordinates
  );
  let validGeofencePoints = [];

  if (
    location.geofence.properties.center.latitude &&
    location.geofence.properties.center.longitude
  ) {
    validGeofencePoints.push({
      latitude: location.geofence.properties.center.latitude,
      longitude: location.geofence.properties.center.longitude
    });
  }
  validGeofencePoints = allGeofencePoints.filter(
    item => item.longitude && item.latitude
  );
  return [...validGeofencePoints];
};
