/** @jsx jsx */
import { jsx } from "@emotion/core";
import DefinitionList from "./DefinitionList";

const headerDefinitionListCss = {
  backgroundColor: "white"
};

// White header bar containing a DefinitionList
// Used on Shipment Details, VIN Details, ADC Details
const HeaderDefinitionList = props => {
  return (
    <div css={headerDefinitionListCss}>
      <DefinitionList style={props.listStyles ? props.listStyles : null}>
        {props.children}
      </DefinitionList>
    </div>
  );
};

export default HeaderDefinitionList;
