import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";
import * as pbi from "powerbi-client";
import ReportSavelocation from "./ReportSaveLocationComponent";
import { Privileges } from "../auth/Authorization";
import moment from "moment-timezone";

// The pbi code is modeled after the code in the
// react-powerbi package, however the react
// component which that generated did not
// work directly, so instead, lifted the relavent
// pieces from there
const powerbi = new pbi.service.Service(
  pbi.factories.hpmFactory,
  pbi.factories.wpmpFactory,
  pbi.factories.routerFactory
);

class ReportsComponent extends React.Component {
  static propTypes = {
    size: PropTypes.object.isRequired,
    report: PropTypes.object,
    setReport: PropTypes.func.isRequired,
    saveReport: PropTypes.func,
    isSaving: PropTypes.bool,
    updateReport: PropTypes.func,
    isEditing: PropTypes.bool,
    authorization: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.component = null;
    this.rootElement = null;
    this.state = {
      config: null,
      showSaveLocationModal: false,
      newReportData: null
    };
    this.reportEmbedded = false;
  }

  componentDidMount() {
    const { report } = this.props;

    this.updateConfig(report);
  }

  componentDidUpdate(prevProps) {
    // Only embed once
    if (this.reportEmbedded === false) {
      if (this.validateConfig(this.state.config)) {
        this.reportEmbedded = true;
        this.embed(this.state.config);
      }
    }
  }

  componentWillUnmount() {
    powerbi.reset(this.rootElement);
    this.component = null;
  }

  embed(config) {
    const { setReport } = this.props;

    this.component = powerbi.embed(this.rootElement, config);

    // Remove events if they already exist, so we don't duplicate
    this.component.off("loaded");
    this.component.off("saved");

    // Add events
    this.component.on("loaded", this.onLoadedTriggered);
    this.component.on("saved", this.onSaveAsTriggered);

    setReport(this.component);

    return this.component;
  }

  onLoadedTriggered = () => {
    const { report } = this.props;
    const { groupName } = report;

    if (groupName === "Report Builder") {
      this.component
        .getPages()
        .then(function(pages) {
          pages[0].setActive().catch(function(errors) {
            console.error("Error setting first page", errors);
          });
        })
        .catch(function(errors) {
          console.error("Error getting report pages", errors);
        });
    }
  };

  onSaveAsTriggered = event => {
    if (!event || !event.detail) {
      return;
    }

    const { report } = this.props;

    if (event.detail.saveAs === true) {
      // Create a copy of a report
      const { workspaceId } = report;

      let data = {
        workspace_number: workspaceId,
        report_number: event.detail.reportObjectId,
        report_name: event.detail.reportName,
        report_description: null,
        group_name: "Saved",
        report_mode: "View"
      };

      this.setState({ newReportData: data, showSaveLocationModal: true });
    } else {
      // Update an existing report
      const { updateReport } = this.props;
      const { reportId } = report;

      updateReport(reportId, moment().format("YYYY-MM-DDTHH:mm:ss"));
    }
  };

  updateConfig(report) {
    if (!report) {
      return;
    }

    const { authorization } = this.props;
    const { accessToken, reportId, groupName, embedUrl } = report;

    // Users with the Report Builder role can update or copy any report under My Reports or Shared Reports
    // As well as make copies of the Report Builder
    const canCreateOrUpdateReport = authorization.hasPrivileges([
      Privileges.BUILD_REPORT
    ]);

    let viewMode = null;
    let permissions = null;
    switch (groupName) {
      case "Report Builder": {
        if (canCreateOrUpdateReport === true) {
          viewMode = pbi.models.ViewMode.Edit;
          permissions = pbi.models.Permissions.Copy;
        } else {
          viewMode = pbi.models.ViewMode.View;
          permissions = pbi.models.Permissions.Read;
        }
        break;
      }
      case "Saved": {
        if (canCreateOrUpdateReport === true) {
          viewMode = pbi.models.ViewMode.Edit;
          permissions = pbi.models.Permissions.All;
        } else {
          viewMode = pbi.models.ViewMode.View;
          permissions = pbi.models.Permissions.Read;
        }
        break;
      }
      default: {
        viewMode = pbi.models.ViewMode.View;
        permissions = pbi.models.Permissions.Read;
      }
    }

    const config = {
      type: "report",
      tokenType: pbi.models.TokenType.Embed,
      accessToken: accessToken,
      embedUrl: embedUrl,
      id: reportId,
      permissions: permissions,
      viewMode: viewMode,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        layoutType: undefined
      }
    };

    this.setState({ config: config });
  }

  validateConfig(config) {
    if (config === null) {
      return false;
    }

    if (config.accessToken === undefined) {
      return false;
    }

    const errors = pbi.models.validateReportLoad(config);

    return errors === undefined;
  }

  render() {
    const { size, saveReport, isSaving } = this.props;
    const { showSaveLocationModal, newReportData } = this.state;

    // Subtract 40 pixels to account for the
    // height of the print icon
    const dimensions = {
      width: size.width,
      height: size.height - 40
    };

    if (_.isNil(dimensions.width)) {
      return <div />;
    }

    return (
      <>
        <ReportSavelocation
          show={showSaveLocationModal}
          reportData={newReportData}
          saveReport={saveReport}
          isSaving={isSaving}
        />
        <div
          className="powerbi-frame"
          ref={el => {
            this.rootElement = el;
          }}
          style={dimensions}
        />
      </>
    );
  }
}

export default ReportsComponent;
