/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { Fragment } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Button } from "react-bootstrap";
import { IoMdCheckmarkCircleOutline, IoMdWarning, IoMdAdd } from 'react-icons/io';
import { TiTrash } from 'react-icons/ti';

import Colors from "../../styles/colors";
import GeofenceType, {
  allowedGeojsonFeatureTypes,
  getType
} from "./geofence-types";
import RadialForm from "./GeofenceEditRadialForm";
import PolygonalForm from "./GeofenceEditPolygonalForm";
import RadialIcon from "../../assets/geofence-type-radial.svg";
import PolygonIcon from "../../assets/geofence-type-polygon.svg";
import { isCoordValid } from "./geofence-coordinates";
import { getUserEmail } from "../users/UsersState";

const FenceValidityMarker = ({ isValid }) => {
  const { t } = useTranslation("geofence-edit");
  const color = isValid ? Colors.highlight.GREEN : Colors.highlight.RED;
  const nougat = isValid ? (
    <Fragment>
      <IoMdCheckmarkCircleOutline /> {t("geofence-edit:Valid Fence")}
    </Fragment>
  ) : (
    <Fragment>
      <IoMdWarning /> {t("geofence-edit:Invalid Fence")}
    </Fragment>
  );
  return (
    <p css={{ marginLeft: "1.5em", color: { color }, fontSize: "0.9em" }}>
      {nougat}
    </p>
  );
};

export const FlexRow = styled.div(
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline"
  },
  ({ styleOverrides }) => {
    return styleOverrides;
  }
);

const PolygonRow = styled.div(
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    border: "2px",
    borderStyle: "solid",
    borderRadius: "5px",
    padding: "2px 0 2px 0",
    margin: "1% 0"
  },
  ({ highlight = false }) => ({
    borderColor: highlight
      ? Colors.highlight.YELLOW
      : Colors.background.LIGHT_GRAY
  })
);

const PolygonIndex = styled.div({
  alignItems: "center",
  backgroundColor: "white",
  border: `3px solid ${Colors.highlight.ALERT_YELLOW}`,
  borderRadius: "50%",
  display: "flex",
  fontSize: "1.5em",
  fontWeight: "bold",
  fontFamily: "sans-serif",
  height: "1.6em",
  justifyContent: "center",
  margin: "1% 0",
  width: "1.6em"
});

const EditGeofenceTypeButton = ({
  disabled,
  fenceType,
  showUpdateGeofenceTypeModal,
  isReadOnly
}) => {
  const { t } = useTranslation("geofence-edit");

  return (
    <div style={{ fontWeight: "bold" }}>
      {fenceType === GeofenceType.RADIAL ? (
        <span>
          <img src={RadialIcon} width="23" height="23" alt="icon" />{" "}
          {t("geofence-edit:Radial Fence")}
        </span>
      ) : fenceType === GeofenceType.POLYGONAL ? (
        <span>
          <img src={PolygonIcon} width="23" height="23" alt="icon" />{" "}
          {t("geofence-edit:Polygonal Fence")}
        </span>
      ) : (
        <span>
          <img src={PolygonIcon} width="23" height="23" alt="icon" />
          <img src={PolygonIcon} width="23" height="23" alt="icon" />{" "}
          {t("geofence-edit:Multi Polygon Fence")}
        </span>
      )}
      {!isReadOnly && (
        <Button
          disabled={disabled}
          variant="link"
          style={linkStyle}
          onClick={() => showUpdateGeofenceTypeModal()}
        >
          {t("geofence-edit:Edit Type")}
        </Button>
      )}
    </div>
  );
};

const linkStyle = {
  textDecoration: "underline",
  color: "#acb5be",
  cursor: "pointer",
  fontSize: "0.9em"
};
const markerStyle = { margin: "0 20 0 2", fontSize: 18 };

const CancelTracingButton = ({ cancelTracing }) => {
  const { t } = useTranslation("geofence-edit");

  return (
    <div>
      <Button variant="link" style={linkStyle} onClick={() => cancelTracing()}>
        {t("geofence-edit:Cancel Tracing")}
      </Button>
    </div>
  );
};

const DeletePolygonButton = ({ deletePolygon, polygonIndex, disabled }) => {
  const { t } = useTranslation("geofence-edit");

  return (
    <div>
      <Button
        disabled={disabled}
        variant="link"
        style={linkStyle}
        onClick={() => deletePolygon(polygonIndex)}
      >
        {t("geofence-edit:Delete")} <TiTrash style={markerStyle} />
      </Button>
    </div>
  );
};

// HT-65: until next sprint, this will just display the last update
// regardless if it was a geofence update
// eslint-disable-next-line
class LastUpdatedLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "" };
  }
  componentDidUpdate(prevProps) {
    if (this.props.userId !== prevProps.userId) {
      const auth0Id = "auth0|" + this.props.userId;
      getUserEmail(auth0Id)
        .then(email => {
          this.setState({ email });
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  render() {
    const { t, lastUpdateTimestring } = this.props;

    const when = moment
      .duration(moment(lastUpdateTimestring).diff(moment()))
      .humanize(true);

    const lastUpdatedMessage =
      this.state.email === ""
        ? `${t("geofence-edit:Location last updated")} ${when}`
        : `${t("geofence-edit:Location last updated")} ${when} ${t(
            "geofence-edit:by"
          )} ${this.state.email}`;
    return (
      <div css={{ marginTop: "1.5em", fontStyle: "italic" }}>
        {lastUpdatedMessage}
      </div>
    );
  }
}

const MultiPolygonForm = ({
  geofence,
  isTracing,
  editingPolygonIndex,
  cancelTracing,
  deletePolygon,
  isReadOnly
}) => {
  return geofence.geometry.coordinates.map((polygon, polygonIndex) => (
    <PolygonRow
      key={`polygon-${polygonIndex + 1}`}
      highlight={editingPolygonIndex === polygonIndex}
    >
      <PolygonIndex> {polygonIndex + 1} </PolygonIndex>
      <PolygonalForm points={polygon[0]} />
      {isTracing && polygonIndex === editingPolygonIndex ? (
        <CancelTracingButton cancelTracing={cancelTracing} />
      ) : !isReadOnly ? (
        <DeletePolygonButton
          disabled={isTracing && polygonIndex !== editingPolygonIndex}
          deletePolygon={deletePolygon}
          polygonIndex={polygonIndex}
        />
      ) : null}
    </PolygonRow>
  ));
};

const GeofenceEditForm = ({
  data,
  addPolygon,
  updateGeofence,
  isTracing,
  editingPolygonIndex,
  cancelTracing,
  deletePolygon,
  showUpdateGeofenceTypeModal,
  isReadOnly
}) => {
  const { t } = useTranslation("geofence-edit");
  const { geofence } = data;
  const fenceType = getType(geofence);
  const center = geofence.properties.center;
  const fenceIsValid = fenceType.isValid(geofence);

  const isAddPolyButtonDisabled = () => {
    const fenceType = getType(data.geofence);
    return (
      isTracing ||
      fenceType === GeofenceType.RADIAL ||
      (fenceType === GeofenceType.POLYGONAL &&
        data.geofence.geometry.coordinates &&
        data.geofence.geometry.coordinates.length > 0)
    );
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
          justifyContent: "space-between"
        }}
      >
        {t("geofence-edit:Geofence")}{" "}
        <FenceValidityMarker isValid={fenceIsValid} />
        <EditGeofenceTypeButton
          fenceType={fenceType}
          // Disable editing fence type until location is geocoded.
          disabled={
            isTracing || !isCoordValid(center.latitude, center.longitude)
          }
          showUpdateGeofenceTypeModal={showUpdateGeofenceTypeModal}
          isReadOnly={isReadOnly}
        />
      </div>
      {fenceType === GeofenceType.RADIAL && (
        <PolygonRow>
          <RadialForm
            data={data}
            isTracing={isTracing}
            updateGeofence={updateGeofence}
            isReadOnly={isReadOnly}
          />
        </PolygonRow>
      )}
      {fenceType === GeofenceType.POLYGONAL &&
        geofence &&
        geofence.geometry &&
        geofence.geometry.coordinates &&
        geofence.geometry.coordinates.length > 0 &&
        geofence.geometry.coordinates[0] && (
          <PolygonRow>
            <PolygonalForm
              points={geofence.geometry.coordinates[0]}
              isReadOnly={isReadOnly}
            />
            {isTracing ? (
              <CancelTracingButton cancelTracing={cancelTracing} />
            ) : !isReadOnly ? (
              <DeletePolygonButton
                deletePolygon={deletePolygon}
                polygonIndex={0}
              />
            ) : null}
          </PolygonRow>
        )}
      {fenceType === GeofenceType.MULTIPOLYGON && (
        <MultiPolygonForm
          geofence={geofence}
          isTracing={isTracing}
          editingPolygonIndex={editingPolygonIndex}
          deletePolygon={deletePolygon}
          cancelTracing={cancelTracing}
          isReadOnly={isReadOnly}
        />
      )}
      <LastUpdatedLabel
        t={t}
        lastUpdateTimestring={geofence.lastUpdateTime}
        userId={geofence.lastUpdater}
      />

      {!isReadOnly && fenceType !== GeofenceType.RADIAL && (
        <Button
          style={{
            marginLeft: "auto",
            backgroundColor: Colors.highlight.GREEN,
            color: "white"
          }}
          disabled={isAddPolyButtonDisabled()}
          onClick={() => {
            addPolygon();
          }}
        >
          <IoMdAdd size="2em" /> {t("geofence-edit:Add Polygon")}
        </Button>
      )}
    </div>
  );
};

GeofenceEditForm.propTypes = {
  data: PropTypes.shape({
    geofence: PropTypes.shape({
      geometry: PropTypes.shape({
        type: PropTypes.oneOf(allowedGeojsonFeatureTypes).isRequired
      }).isRequired
    }).isRequired
  }).isRequired,
  isTracing: PropTypes.bool.isRequired,
  addPolygon: PropTypes.func.isRequired,
  deletePolygon: PropTypes.func.isRequired,
  cancelTracing: PropTypes.func.isRequired,
  updateGeofence: PropTypes.func.isRequired,
  showUpdateGeofenceTypeModal: PropTypes.func.isRequired
};

export default GeofenceEditForm;
