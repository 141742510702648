/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector } from "react-redux";
import Loader from "react-loader";
import FinVehicleEntitiesState from "../FinVehicleEntitiesState";
import DataHeader from "./DataHeader";

const DashboardTotalVins = props => {
  const entityCount = useSelector(
    FinVehicleEntitiesState.selectors.getEntityCount
  );
  const entityCountLoading = useSelector(
    FinVehicleEntitiesState.selectors.getEntityCountLoading
  );

  return (
    <div css={totalVinsStyles}>
      <Loader loaded={!entityCountLoading} scale={0.33}>
        <DataHeader total={entityCount || 0} />
      </Loader>
    </div>
  );
};

const totalVinsStyles = {
  position: "relative",
  ".loader": {
    position: "relative",
    top: -10,
    left: 10
  }
};

export default DashboardTotalVins;
