import _ from "lodash";

export const parseTimeWindow = windowText => JSON.parse(windowText);
const parseWindowStart = windowText => parseTimeWindow(windowText)[1];

// REFACTOR: use spreader syntax to significantly shortent this function
export const convertTripLegData = combinedData => {
  if (!combinedData) {
    return [];
  }

  const returnData = combinedData.map((d, i) => {
    let actualStop = d.actual;
    let plannedStop = d.planned;

    let progressToStop = actualStop ? actualStop.progress : 0;

    const nextPlannedLeg =
      combinedData && i < combinedData.length && combinedData[i + 1]
        ? combinedData[i + 1].planned
        : null;

    // First & Second leg
    if (i === 0) {
      return [
        // First Leg - Ultimate Origin (tripLegs[0].origin)
        {
          type: plannedStop.origin.typeCode,
          name: plannedStop.origin.name,
          code: plannedStop.origin.code,
          city: plannedStop.origin.city,
          address: plannedStop.origin.address,
          state: plannedStop.origin.state,
          location: `${plannedStop.origin.city}, ${plannedStop.origin.state}`,
          country: plannedStop.origin.country,
          scheduled_delivery: null, // Ultimate Origin should never have a delivery date
          actual_delivery: _.get(actualStop, "origin.arrived", null),
          scheduled_pickup: parseWindowStart(
            plannedStop.origin.scheduledArrivalWindow
          ),
          scheduled_pickup_window: parseTimeWindow(
            plannedStop.origin.scheduledArrivalWindow
          ),
          actual_pickup: _.get(actualStop, "origin.departed", null),
          updates: [],
          shipmentMode: plannedStop.transportMode,
          legData: plannedStop
        },
        // Second Leg (tripLegs[0].dest)
        {
          type: plannedStop.dest.typeCode,
          name: plannedStop.dest.name,
          code: plannedStop.dest.code,
          city: plannedStop.dest.city,
          address: plannedStop.dest.address,
          state: plannedStop.dest.state,
          location: `${plannedStop.dest.city}, ${plannedStop.dest.state}`,
          country: plannedStop.dest.country,
          scheduled_delivery: parseWindowStart(
            plannedStop.dest.scheduledArrivalWindow
          ),
          scheduled_delivery_window: parseTimeWindow(
            plannedStop.dest.scheduledArrivalWindow
          ),
          actual_delivery: _.get(actualStop, "dest.arrived", null),
          scheduled_pickup: nextPlannedLeg
            ? parseWindowStart(nextPlannedLeg.origin.scheduledArrivalWindow)
            : null,
          scheduled_pickup_window: nextPlannedLeg
            ? parseTimeWindow(nextPlannedLeg.origin.scheduledArrivalWindow)
            : null,
          // If there is no next leg, this is the Ultimate Destination, and should never have a pickup date
          actual_pickup: nextPlannedLeg
            ? _.get(actualStop, "origin.arrived", null)
            : null,
          updates: [],
          progress: progressToStop,
          shipmentMode: plannedStop.transportMode,
          legData: plannedStop
        }
      ];
    }

    // Last Leg - Ultimate Destination (tripLegs[n].dest)
    if (i === combinedData.length - 1) {
      return {
        type: plannedStop.dest.typeCode,
        name: plannedStop.dest.name,
        code: plannedStop.dest.code,
        city: plannedStop.dest.city,
        state: plannedStop.dest.state,
        location: `${plannedStop.dest.city}, ${plannedStop.dest.state}`,
        country: plannedStop.dest.country,
        scheduled_delivery: parseWindowStart(
          plannedStop.dest.scheduledArrivalWindow
        ),
        scheduled_delivery_window: parseTimeWindow(
          plannedStop.dest.scheduledArrivalWindow
        ),
        actual_delivery: _.get(actualStop, "dest.arrived", null),
        scheduled_pickup: null, // Ultimate Destination should never have a pickup date
        actual_pickup: null, // Ultimate Destination should never have a pickup date
        updates: [],
        progress: progressToStop,
        shipmentMode: plannedStop.transportMode,
        legData: plannedStop
      };
    }

    // Middle Leg (tripLegs[x].dest and tripLegs[x+1].origin)
    return {
      type: plannedStop.dest.typeCode,
      name: plannedStop.dest.name,
      code: plannedStop.dest.code,
      city: plannedStop.dest.city,
      state: plannedStop.dest.state,
      location: `${plannedStop.dest.city}, ${plannedStop.dest.state}`,
      country: plannedStop.dest.country,
      scheduled_delivery: parseWindowStart(
        plannedStop.dest.scheduledArrivalWindow
      ),
      scheduled_delivery_window: parseTimeWindow(
        plannedStop.dest.scheduledArrivalWindow
      ),
      scheduled_pickup: parseWindowStart(
        nextPlannedLeg.origin.scheduledArrivalWindow
      ),
      scheduled_pickup_window: parseTimeWindow(
        nextPlannedLeg.origin.scheduledArrivalWindow
      ),
      actual_delivery: _.get(actualStop, "dest.arrived", null),
      actual_pickup: _.get(actualStop, "origin.arrived", null),
      updates: [],
      progress: progressToStop,
      shipmentMode: plannedStop.transportMode,
      legData: plannedStop
    };
  });
  return _.flatten(returnData);
};

export const addUpdates = (tripPlanData, events) => {
  const allUpdates = [...convertEventsToUpdates(events)];
  const tripLegFinishedEvents = [...getTripLegFinishedEvents(events)];

  return addUpdatesToTripPlan(tripPlanData, allUpdates, tripLegFinishedEvents);
};

const excludedEventCodes = new Set([
  "PositionUpdated",
  "ActualTripLegCreated",
  "PlannedTripLegCreated",
  "PlannedTripLegUpdated",
  "ActualTripLegCreated",
  "ActualTripLegUpdated",
  "ActualTripLegCompleted",
  "ProgressUpdated",
  "PositionUpdated",
  "EntityUpdated",
  "EntityLifeCycleUpdate"
]);

const excludedEventSubcodes = new Set([
  "ExceptionCreated:T",
  "ExceptionCleared:T"
]);

export const convertEventsToUpdates = events => {
  // H2-663: in this issue, we discover that each time we found a
  // ActualTripLegCompleted, we actually find a divider, that is, every time
  // we find an "ActualTripLegCompleted" we should move the update to the
  // next leg
  let tripLegIndex = 1;
  for (const event of events) {
    event.tripLegIndex = tripLegIndex;
    if (event.code === "ActualTripLegCompleted") {
      tripLegIndex++;
    }
  }

  const includedEvents = events.filter(
    event =>
      !excludedEventCodes.has(event.code) &&
      !excludedEventSubcodes.has(`${event.code}:${event.subcode}`)
  );

  const eventUpdates = includedEvents.map(event => {
    return {
      id: event.id,
      entityId: event.entityId,
      eventTs: event.eventTs,
      ts: event.ts,
      update: friendlyEventName(event),
      update_origin: null,
      comments: event.comments || "",
      tripLegIndex: event.tripLegIndex,
      subcode: event.subcode,
      subcodeDescription: event.subcodeDescription,
      code: event.code,
      codeDescription: event.codeDescription,
      statusUpdate: event.statusUpdate
    };
  });

  return _.orderBy(eventUpdates, ["time"], ["asc"]);
};

const getTripLegFinishedEvents = events => {
  const includedEvents = events.filter(
    event => event.code === "ActualTripLegCompleted"
  );
  const eventUpdates = includedEvents.map(event => {
    return {
      id: event.id,
      entityId: event.entityId,
      time: event.eventTs,
      update: friendlyEventName(event),
      update_origin: null,
      comments: event.comments || ""
    };
  });

  return _.orderBy(eventUpdates, ["time"], ["asc"]);
};

export const getDeliveryDates = (plannedTripLegs, actualTripLegs) => {
  const lastLeg = getLastTripLeg(plannedTripLegs);
  // const hasActualLeg =
  //   lastLeg && findTripLegInActualLegs(lastLeg, actualTripLegs);
  const destination = lastLeg ? lastLeg.dest : {};
  // H2-207: Only display ETA if there's an actual trip leg.
  // May be depreciated by H2-266
  const estimatedDelivery =
    destination && destination.scheduledArrivalWindow //&& hasActualLeg
      ? parseWindowStart(destination.scheduledArrivalWindow)
      : "";
  const scheduledDelivery =
    destination && destination.scheduledArrivalWindow
      ? parseWindowStart(destination.scheduledArrivalWindow)
      : "";

  return {
    estimated: estimatedDelivery,
    scheduled: scheduledDelivery
  };
};

export const getOriginAndDestination = plannedTripLegs => {
  const firstLeg = getFirstTripLeg(plannedTripLegs);
  const origin = firstLeg ? firstLeg.origin : {};

  const lastLeg = getLastTripLeg(plannedTripLegs);
  const destination = lastLeg ? lastLeg.dest : {};
  const originWindow = parseTimeWindow(origin.scheduledArrivalWindow);
  const destinationWindow = parseTimeWindow(destination.scheduledArrivalWindow);

  return {
    origin: {
      ...formatLocation(origin),
      earliest_arrival_datetime: originWindow ? originWindow[0] : null,
      latest_arrival_datetime: originWindow ? originWindow[1] : null,
      actual_arrival_datetime: null,
      actual_departure_datetime: null,
      show_eta: false,
      isLTL: false
    },
    destination: {
      ...formatLocation(destination),
      earliest_arrival_datetime: destinationWindow
        ? destinationWindow[0]
        : null,
      latest_arrival_datetime: destinationWindow ? destinationWindow[1] : null,
      actual_arrival_datetime: null,
      actual_departure_datetime: null,
      show_eta: false,
      isLTL: false
    }
  };
};

const friendlyEventName = event => {
  switch (event.code) {
    case "EntityCreated":
      return "VIN Registered";
    case "EntityUpdated":
      return "VIN Updated";
    case "HoldCreated":
    case "HoldCleared":
    case "StatusUpdated":
      return `${event.codeDescription} - ${event.subcodeDescription || ""} (${
        event.subcode
      })`;
    case "ExceptionCreated":
    case "ExceptionCleared":
      return `${event.codeDescription} - ${event.subcodeDescription || ""}`;
    case "ActualTripLegArrivedOrigin":
      return `Arrived for Pickup - ${event.subcodeDescription || ""} (${
        event.subcode
      })`;
    case "ActualTripLegDepartedOrigin":
      return `Departed Pickup - ${event.subcodeDescription || ""} (${
        event.subcode
      })`;
    case "ActualTripLegArrivedDestination":
      return `Arrived for Delivery - ${event.subcodeDescription || ""} (${
        event.subcode
      })`;
    default:
      return event.codeDescription || event.code;
  }
};

/**
 * This algorithm will add items to the "updates" attribute inside the right
 * planned trip legs. In the past, this was a weird algorithm, but now it
 * changed because of H2-663.
 *
 * H2-663: the whole logic here was simplified due to the explanation on this
 * issue that all updates is just partitioned by ActualTripLegCompleted
 * occurrences, following the simple algorithm:
 *
 *   "Every time there is an ActualTripLegCompleted event, move to the next leg"
 *
 * As we need events order to do this, the actual leg partition stuff is done
 * using tripLegIndex attribute that is filled in convertEventsToUpdates helper.
 *
 * The following aspects that were addressed by the old algorithm continue
 * being addressed by the new one:
 *
 * 1) Update “buckets” are created for each planned trip leg
 * 2) Receives as parameters two lists are given as parameters "updates" and all
 *    "ActualTripLegCompleted events", both are sorted by event timestamp
 * 3) If there are no ActualTripLegCompleted events, all updates will be
 * placed in the first bucket
 * 4) If there are ActualTripLegCompleted events, then every update who's
 *    timestamp is "less than or equal" to first tripLegCompleted event  are
 *    put in the first bucket until the condition is met. Once condition met
 *    we iterate to the next event and place updates in the next trip leg, etc.
 * 5) Any events that happen after the final completed event stay in the last
 *    bucket
 *
 */
const addUpdatesToTripPlan = (
  tripPlanData,
  updates,
  tripLegCompletedUpdates
) => {
  const returnTripPlanData = _.cloneDeep(tripPlanData);
  if (tripPlanData.length === 0) {
    return returnTripPlanData;
  }

  updates.forEach(item => {
    // H2-890: Add the updates to the data item for the trip leg index
    // If there is no data at the index, add the updates to the last data item
    const dataIndex = returnTripPlanData[item.tripLegIndex]
      ? item.tripLegIndex
      : returnTripPlanData.length - 1;
    returnTripPlanData[dataIndex].updates.push(item);
  });

  return returnTripPlanData;
};

const getFirstTripLeg = plannedTripLegs => {
  if (!plannedTripLegs || plannedTripLegs.length === 0) {
    return {};
  }

  return plannedTripLegs[0];
};

const getLastTripLeg = plannedTripLegs => {
  if (!plannedTripLegs || plannedTripLegs.length === 0) {
    return {};
  }

  return plannedTripLegs[plannedTripLegs.length - 1];
};

/* H2-266 remove this check, preventing estimated delivery from displaying */
/*
const findTripLegInActualLegs = (tripLeg, actualTripLegs) => {
  return (
    actualTripLegs &&
    actualTripLegs.find(
      actual => tripLeg.orign === actual.origin && tripLeg.dest === actual.dest
    )
  );
};
*/

const formatLocation = location => {
  return {
    name: location && location.name ? location.name : "",
    address: location && location.address ? location.address : "",
    cityStateZip: location ? formatCityStateZip(location) : "",
    country: location && location.country ? location.country : ""
  };
};

const formatCityStateZip = location => {
  let cityStateZip = "";

  if (location.city) {
    cityStateZip = location.city;
  }

  if (location.state) {
    cityStateZip = `${cityStateZip}, ${location.state}`;
  }

  if (location.zip) {
    cityStateZip = `${cityStateZip} ${location.zip}`;
  }

  return cityStateZip;
};
export const processTabsData = (
  combinedExceptions,
  currentLocation,
  details
) => {
  // Get vinEquipment (just remove all order details information from
  // references)
  const references = details && details.references ? details.references : [];
  const vinEquipment = references.filter(
    item => !item.type || item.type.toLowerCase() !== "order"
  );

  // H2-835: Set fields chosen for now
  const validOrderFields = {
    "90DayRollingAvg": "90 Day Rolling Average",
    DealerCounty: "Dealer County",
    DealerFIPS: "Dealer FIPS",
    DealerSPLC: "Dealer SPLC",
    DestinationFVCCode: "Destination FVC Code",
    Division: "Division",
    LaneCategory: "Lane Category",
    Last8OfVIN: "Last 8 Of VIN",
    OrderingDealerCode: "Ordering Dealer Code",
    OrderingDealerName: "Ordering Dealer Name",
    OriginEmissionsWindTunnelTesting: "Origin Emissions Wind Tunnel Testing",
    OriginRouteCode: "Origin Route Code",
    PlantFVC: "Plant FVC",
    ShipmentMethodCode: "Shipment Method Code",
    VehiclePriority: "Vehicle Priority",
    Wheelbase: "Wheelbase"
  };

  // Get all order details field and put a "pretty" label on them
  const orderDetails = _.sortBy(
    references
      .filter(item => item.qualifier in validOrderFields)
      .map(item => ({
        value: item.value,
        qualifier: validOrderFields[item.qualifier]
      })),
    "qualifier"
  );
  return {
    combinedExceptions,
    currentLocation,
    vinEquipment,
    orderDetails
  };
};

// Sorts the given exception array using the exception name parameter,
// in some places this is exception.name, on others it's exception.text
export const sortExceptions = (exceptions, nameAttr) => {
  const withSortedAttr = exceptions.map(exception => {
    let sortOrder = 0;
    switch (exception[nameAttr]) {
      case "Behind Schedule":
        sortOrder = 0;
        break;
      case "On Hold":
        sortOrder = 1;
        break;
      case "Excessive Dwell":
        sortOrder = 2;
        break;
      default:
        sortOrder = 3;
        break;
    }
    return { ...exception, sort: sortOrder };
  });

  return _.sortBy(withSortedAttr, "sort");
};

// look up lad by code (code or id)
export const getLadByCode = (type, lads) => {
  if (!lads || !type || type.toLowerCase() === undefined) {
    return { code: type || "u", id: 0, description: "" };
  }

  let lad;

  // H1-1568 account for ids 1-9 (which are number ids and not letter codes)
  if (type.length === 1 && isNaN(type)) {
    // type is code
    lad = _.keyBy(lads, "code")[type.toLowerCase()];
  } else {
    // type is id
    lad = _.keyBy(lads, "id")[type];
  }

  return lad !== undefined
    ? lad
    : { code: type || "u", id: 0, description: "" };
};

// refactored functions to process trip plan
export const addProgressToPlannedLegs = (plannedTripLegs, actualTripLegs) => {
  if (!plannedTripLegs || !actualTripLegs) {
    return [];
  }
  const plannedWithProgress = plannedTripLegs.tripLegs.map(
    (plannedTripLeg, i) => {
      let actualLeg = _.find(actualTripLegs.tripLegs, a => {
        return (
          a.origin.code === plannedTripLeg.origin.code &&
          a.dest.code === plannedTripLeg.dest.code
        );
      });
      return {
        ...plannedTripLeg,
        progress: actualLeg ? actualLeg.progress : null
      };
    }
  );
  return plannedWithProgress;
};

export const flattenPlannedLegs = plannedLegs => {
  let flattenedArr = [];
  let destArrivalWindow = null;

  plannedLegs.forEach((leg, i) => {
    const originArrivalWindow = leg.origin.scheduledArrivalWindow;

    let keys = i !== plannedLegs.length - 1 ? ["origin"] : ["origin", "dest"];
    keys.forEach(key => {
      let stop = leg[key];

      // For the Ultimate Destination, use the current leg destination
      if (i === plannedLegs.length - 1 && key === "dest") {
        destArrivalWindow = leg.dest.scheduledArrivalWindow;
      }

      const scheduledPickupWindow = originArrivalWindow
        ? JSON.parse(originArrivalWindow)
        : null;
      const scheduledDeliveryWindow = destArrivalWindow
        ? JSON.parse(destArrivalWindow)
        : null;

      flattenedArr.push({
        scheduled_pickup_window: scheduledPickupWindow,
        scheduled_delivery_window: scheduledDeliveryWindow,
        name: stop.name,
        address: stop.address,
        city: stop.city,
        zip: stop.zip,
        state: stop.state,
        country: stop.country,
        typeCode: stop.typeCode,
        updates: [],
        madInfo: {
          progress: leg.progress,
          transportMode: leg.transportMode
        }
      });
    });

    // The delivery window for the next leg will be the current leg's destination scheduled arrival window
    destArrivalWindow = leg.dest.scheduledArrivalWindow;
  });
  return flattenedArr;
};

export const getProgressArray = plannedStops => {
  const tripProgress = plannedStops.map(s => []);
  plannedStops.forEach((s, i) => {
    let progress = s.madInfo.progress;
    let mode = s.madInfo.transportMode;
    if (progress === 100 && i < tripProgress.length - 1) {
      tripProgress[i + 1].push({ progress: 100, mode: mode });
    } else if (progress === 0) {
      tripProgress[i].push({ progress: 0, mode: mode });
    } else if (progress !== null && !_.includes([0, 100], progress)) {
      tripProgress[i].push({ progress: progress, mode: mode });
    }
  });
  return tripProgress;
};

export const getVinDetails = details => {
  const description = details && details.description;
  const yearObj = details && _.find(details.references, ["qualifier", "year"]);
  const makeObj = details && _.find(details.references, ["qualifier", "make"]);
  const modelObj =
    details && _.find(details.references, ["qualifier", "model"]);
  const yearTxt = yearObj ? yearObj.value : "";
  const makeTxt = makeObj ? makeObj.value : "";
  const modelTxt = modelObj ? modelObj.value : "";

  return {
    id: details && details.id,
    type: description || `${yearTxt} ${makeTxt} ${modelTxt}`,
    status: (details && details.lifeCycleState) || ""
  };
};

export const vinTabsArray = (t, vinTabsData, connectedCarCoords) => {
  let tabs = [
    {
      title: t("fv-vin-details:Trip Plan & Updates"),
      accessor: "updates",
      hideNumber: true
    },
    {
      title: t("fv-vin-details:Active Exceptions"),
      accessor: "combinedExceptions",
      hideNumber: true
    },
    {
      title: t("fv-vin-details:Coordinates"),
      accessor: "current_location",
      hideNumber: true
    }
  ];
  if (vinTabsData.orderDetails.length > 0) {
    tabs.push({
      title: t("fv-vin-details:Order Details"),
      hideNumber: true
    });
  }
  tabs.push({
    title: t("fv-vin-details:VIN Details"),
    accessor: "references",
    hideNumber: true
  });
  // Connected Car tab, differently of the other ones that has a kind of "no
  // data" text inside them, should only be showed when there is data from
  // connected car
  if (connectedCarCoords.length !== 0) {
    tabs.push({
      title: t("fv-vin-details:Connected Car"),
      hideNumber: true
    });
  }
  return tabs;
};

const filteredExceptions = combinedExceptions =>
  combinedExceptions.filter(e => !["On Hold"].includes(e.typeName));

/*
  React-tabs expects two parallel arrays, one for the tabs themselves and one
  for the panels that appear under the tabs. Since some tabs don't show up under
  certain conditions, we generate both here and return them together.

  We call the properties of the returned object 'array' and 'data' because those
  are the props the underlying <TabsContainerPanel> uses.
*/
export const getVinTabs = ({
  t,
  combinedExceptions,
  currentLocation,
  details,
  connectedCarCoords
}) => {
  const filteredCombinedExceptions = filteredExceptions(combinedExceptions);
  const data = processTabsData(
    filteredCombinedExceptions,
    currentLocation,
    details
  );
  return {
    array: vinTabsArray(t, data, connectedCarCoords),
    data
  };
};
