import { connect } from "react-redux";
import FilterSection from "../../components/search-bar/FilterSection";
import MetalTrackingSearchBarState from "./MetalTrackingSearchBarState";
import MetalTrackingDomainDataState from "../domain-data/MetalTrackingDomainData";
import { FILTERS } from "./MetalTrackingFilterSectionCategoryDefs";
import { getSolutionId } from "../organizations/OrganizationsState";

const mapStateToProps = state => ({
  locationsFilterOptions: MetalTrackingDomainDataState.selectors.getLocationsAlphabetically(
    state
  ),
  cspcsFilterOptions: MetalTrackingDomainDataState.selectors.getCspcs(state),
  descriptionsFilterOptions: MetalTrackingDomainDataState.selectors.getDescriptionsAlphabetically(
    state
  ),
  maxQuantitiesFilterOptions: MetalTrackingDomainDataState.selectors.getMaxQuantitiesOrdered(
    state
  ),
  searchFilters: MetalTrackingSearchBarState.selectors.getSearchFilters(state),
  solutionId: getSolutionId(state)
});

const mapDispatchToProps = dispatch => {
  const {
    setSearchFilter,
    clearSearchFilter,
    searchEntities
  } = MetalTrackingSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: key => dispatch(clearSearchFilter(key)),
    searchEntities: solutionId => dispatch(searchEntities(solutionId))
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FilterSection);
