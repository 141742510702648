/** @jsx jsx */
//eslint-disable-next-line
import React, { Component } from "react";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { withTranslation } from "react-i18next";
import SectionNavigation from "../../components/sections/location/SectionNavigation";

import { Privileges } from "../auth/Authorization";

import { getOrganizationByID } from "../organizations/OrganizationUtils";
import { isCarrier } from "../../routes";
import TableMetaTotalRecords from "../../components/tables/MetaData/TableMetaTotalRecords";

import UnresolvedLocationTable from "./components/UnresolvedLocationTable";

class LocationResolutionListPage extends Component {
  static propTypes = {
    activeOrganization: PropTypes.any,
    authorization: PropTypes.object,
    fetchUnresolvedLocationsSummary: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    organizations: PropTypes.array,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    pushLocationMatchingView: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    setSearchFilter: PropTypes.func.isRequired,
    setShipmentWithUnresolvedLocation: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    unresolvedLocations: PropTypes.array
  };

  state = {
    updatedUnresolvedLocations: []
  };

  componentDidMount() {
    const { fetchUnresolvedLocationsSummary } = this.props;

    fetchUnresolvedLocationsSummary();
  }

  componentDidUpdate(prevProps) {
    const { unresolvedLocations, organizations } = this.props;

    if (unresolvedLocations !== prevProps.unresolvedLocations) {
      const updatedUnresolvedLocations = unresolvedLocations.map(loc => {
        const org = getOrganizationByID(loc.owner_id, organizations);
        loc["carrier_name"] = org ? org.org_name : "";
        return loc;
      });
      this.setState({
        updatedUnresolvedLocations: updatedUnresolvedLocations
      });
    }
  }

  /**
   *
   * @return {boolean|boolean}
   */
  canUserManageLocations() {
    const { authorization, activeOrganization } = this.props;

    const isCarrierOrg = isCarrier(activeOrganization);

    return (
      authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS]) &&
      !isCarrierOrg
    );
  }

  render() {
    const {
      t,
      isLoading,
      setShipmentWithUnresolvedLocation,
      pushLocationMatchingView
    } = this.props;

    const { updatedUnresolvedLocations } = this.state;

    const canUserManageLocations = this.canUserManageLocations();

    return (
      <div className="p-3">
        {canUserManageLocations && <SectionNavigation activeTab="unresolved" />}
        <TableMetaTotalRecords
          total={updatedUnresolvedLocations.length ?? 0}
          label={t("locations:Locations")}
        />
        <UnresolvedLocationTable
          isLoading={isLoading}
          data={updatedUnresolvedLocations}
          rowClickHandler={rowInfo => {
            const locID = rowInfo.original.location_id;
            setShipmentWithUnresolvedLocation(null);
            pushLocationMatchingView(locID);
          }}
        />
      </div>
    );
  }
}

export default withTranslation(["locations"])(LocationResolutionListPage);
