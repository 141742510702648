import moment from "moment";
import "moment-timezone";

// DEV-767 Add user timezone to time display
const zone = moment.tz.guess();

export const calculateTimeDelta = (time, delta) => {
  if (!time) {
    return null;
  }

  if (delta === null || delta === 0) {
    return time;
  }

  return moment(time, "YYYY-MM-DD HH:mm:ss")
    .add(delta, "hour")
    .format("YYYY-MM-DD HH:mm:ss");
};

export const localizedTimestampDeltaFormatter = (utc, delta) => {
  const locTime = moment
    .utc(utc)
    .local()
    .add(delta, "hour");
  const userTz = moment().tz(zone);

  return locTime.isValid()
    ? `${locTime.format("l")} ${locTime.format("HH:mm")} ${userTz.format("z")}`
    : "";
};

export function localizedTimeFormatter(utc) {
  const locTime = moment.utc(utc).local();
  const userTz = moment().tz(zone);

  return `${locTime.format("HH:mm")} ${userTz.format("z")}`;
}

export function localizedTimeFormatterWithSeconds(utc) {
  const locTime = moment.utc(utc).local();
  const userTz = moment().tz(zone);

  return `${locTime.format("HH:mm:ss")} ${userTz.format("z")}`;
}

export function localizedDateFormatter(utc) {
  const locTime = moment.utc(utc).local();

  return locTime.format("l");
}

export function dateFormatter(utc) {
  const locTime = moment.utc(utc);

  return locTime.format("l");
}

// Timestamps in shipping references have a timezone as
//   as a part of the string. This parses the string
//   and applies the given timezone offset.
export function localizedRefTimeFormatter(utc, tz) {
  const locTime = moment(utc, "YYYY-MM-DD-HH:mm:SSS")
    .utcOffset(tz)
    .local();
  const userTz = moment().tz(zone);

  return `${locTime.format("HH:mm")} ${userTz.format("z")}`;
}

export function localizedRefDateFormatter(utc, tz) {
  const locTime = moment(utc, "YYYY-MM-DD-HH:mm:SSS")
    .utcOffset(tz)
    .local();

  return locTime.format("l");
}

export function stringToMoment(str) {
  return str ? moment(str) : null;
}

export function utcStringToMoment(str) {
  return str ? moment.utc(str) : null;
}

export const getRelativeDuration = ts => {
  const tsZ = ts.slice(-1) === "Z" ? ts : ts + " Z";
  const now = moment();
  const tsMoment = moment(tsZ);
  return moment.duration(tsMoment.diff(now));
};

export const humanizeTimeString = (ts, suffix) => {
  if (!ts) {
    return "";
  }

  // Add a Z to the end of the string, so moment will parse
  // it into the proper time zone
  const duration = getRelativeDuration(ts);
  return duration.humanize(suffix);
};

/**
 * Special date formatter when showing rail date time.
 *
 * NOTE: Customers deserve a format totally different from the other ones used
 * in the system.
 */
export function localizedRailDateTimeFormatter(ts) {
  if (!ts) {
    return "";
  }

  const tsZ = ts.slice(-1) === "Z" ? ts : ts + " Z";
  const locTime = moment(tsZ).local();
  const userTz = moment().tz(zone);

  return `${locTime.format("MM/DD/YYYY HH:mm")} ${userTz.format("z")}`;
}
