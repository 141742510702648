/** @jsx jsx
 * Warning modal displayed if search returns too many results
 *
 * TODO: make messages be configurable externally
 */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Colors from "../../styles/colors";

const RecordLimitModal = props => {
  const { show, onHide } = props;
  const { t } = useTranslation("components");

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={onHide}
      data-qa="modal-record-limit"
    >
      <Modal.Header
        closeButton
        style={{
          padding: "0.5em",
          color: Colors.background.DARK_BLUE,
          backgroundColor: Colors.background.LIGHT_GRAY
        }}
      >
        <Modal.Title style={{ fontWeight: "300" }} data-qa="title-modal">
          {t("components:Please Select Additional Filters")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ backgroundColor: Colors.background.LIGHT_GRAY }}
        data-qa="body-modal"
      >
        <div>
          <p>
            {t(
              "components:The VIN data set must be less than 5,000 records in order to display on the map."
            )}
          </p>
          <p>
            {t(
              "components:Please select more filters to reduce the data set population."
            )}
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
        <Button
          style={{
            backgroundColor: "white",
            color: Colors.background.DARK_BLUE,
            marginRight: "0.5em"
          }}
          onClick={onHide}
          data-qa="button-close-modal"
        >
          {t("components:Close")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

RecordLimitModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func
};

export default RecordLimitModal;
