/** @jsx jsx */
// eslint-disable-next-line
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import React from "react";
import _ from "lodash";
import TimeDateBox from "./TimeDateBox";
import LocationAddressComplex from "../../location/components/LocationAddressComplex";
import {
  dateFormatter,
  localizedTimeFormatter,
  localizedDateFormatter,
  calculateTimeDelta
} from "../../../utils/date-time";
import {
  ShowForLargeAndUp,
  ShowForMediumAndDown
} from "../../../components/responsive";
import { useTranslation } from "react-i18next";
import Colors from "../../../styles/colors";
import RouteHeader from "./RouteHeader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  tabsCss,
  tabPanelStyle,
  tabStyle
} from "../../../components/multimodal-components/tabStyles";

import LocationName from "../../location/components/LocationName";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarEdit,
  faCalendarCheck
} from "@fortawesome/pro-light-svg-icons";

const OriginDestinationBoxStyles = {
  locationsContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    flex: 1,
    justifyContent: "space-between",
    backgroundColor: "white",
    position: "relative",
    padding: "1em 0.25em"
  },
  locationChild: {
    flex: "1 1 320px"
  }
};

const OriginDestinationBox = ({
  origin,
  destination,
  shipment,
  destShipment,
  displayRouteHeader = true
}) => {
  const showRouteHeader =
    displayRouteHeader &&
    !destShipment &&
    !_.isNil(shipment) &&
    !_.isArray(shipment);

  const { t } = useTranslation("shipment-detail");

  const tab = (name, isLastTab = false) => {
    return (
      <Tab
        style={{
          textTransform: "capitalize",
          marginRight: isLastTab === true ? 10 : 0,
          ...tabStyle
        }}
        key={name}
        data-qa={`tab-button-${name}`}
      >
        {t(`${name}`)}
      </Tab>
    );
  };

  const tabs = [tab("origin"), tab("destination", true)];

  return (
    <div>
      {showRouteHeader ? <RouteHeader shipment={shipment} /> : null}
      <ShowForLargeAndUp>
        <div
          css={{
            ...OriginDestinationBoxStyles.locationsContainer
          }}
        >
          <ShipmentInfoRow>
            {ODTitle("Origin")}
            {ODTitle("Destination", true)}
          </ShipmentInfoRow>

          <ShipmentInfoRow>
            {ODLocationName(origin)}
            {ODLocationName(destination, true)}
          </ShipmentInfoRow>

          <ShipmentInfoRow>
            {ODLocationAddress(origin)}
            {ODLocationAddress(destination, true)}
          </ShipmentInfoRow>

          <ShipmentInfoRow>
            {ODHeader("Scheduled", faCalendarEdit, true)}
          </ShipmentInfoRow>

          <ShipmentInfoRow>
            {ODLocationScheduledArrivalWindow(origin)}
            {ODLocationScheduledArrivalWindow(destination, true)}
          </ShipmentInfoRow>

          <ShipmentInfoRow>
            {ODHeader("Actual", faCalendarCheck, true)}
          </ShipmentInfoRow>

          <ShipmentInfoRow>
            {ODLocationActualArrivalWindow(origin)}
            {ODLocationActualArrivalWindow(destination, true)}
          </ShipmentInfoRow>
        </div>
      </ShowForLargeAndUp>
      <ShowForMediumAndDown>
        <Tabs css={tabsCss} style={{ backgroundColor: "#C1C1C1" }}>
          <TabList style={{ display: "flex" }}>{tabs}</TabList>
          <TabPanel
            style={{ textAlign: "center", ...tabPanelStyle }}
            data-qa={`tab-origin`}
          >
            {ODMobile("Origin", origin)}
          </TabPanel>
          <TabPanel
            style={{ textAlign: "center", ...tabPanelStyle }}
            data-qa={`tab-destination`}
          >
            {ODMobile("Destination", destination)}
          </TabPanel>
        </Tabs>
      </ShowForMediumAndDown>
    </div>
  );
};

const ShipmentInfoRow = ({ children }) => {
  return (
    <div
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        width: "unset"
      }}
    >
      {children}
    </div>
  );
};
ShipmentInfoRow.propTypes = {
  children: PropTypes.node
};

const ShipmentInfoColumn = ({ style, hideRightBorder = false, children }) => {
  return (
    <div
      css={{
        flex: 1,
        borderRight: hideRightBorder
          ? 0
          : `1px solid ${Colors.background.GRAY}`,
        padding: "0 0.75em",
        ...style
      }}
    >
      {children}
    </div>
  );
};
ShipmentInfoColumn.propTypes = {
  style: PropTypes.object,
  hideRightBorder: PropTypes.bool,
  children: PropTypes.node
};

const ODTitle = (title, hideRightBorder) => {
  const { t } = useTranslation("shipment-detail");

  return (
    <ShipmentInfoColumn hideRightBorder={hideRightBorder}>
      <div
        css={{
          fontSize: 14,
          marginBottom: 10,
          color: "#8c8888"
        }}
      >
        {t(`shipment-details:${title}`)}
      </div>
    </ShipmentInfoColumn>
  );
};

const ODLocationName = (location, hideRightBorder) => {
  const { t } = useTranslation("shipment-detail");

  return (
    <ShipmentInfoColumn hideRightBorder={hideRightBorder}>
      <LocationName
        name={location.name ? location.name : t("shipment-details:N/A")}
      />
    </ShipmentInfoColumn>
  );
};

const ODLocationAddress = (location, hideRightBorder) => {
  return (
    <ShipmentInfoColumn hideRightBorder={hideRightBorder}>
      {location.name && (
        <LocationAddressComplex
          style={{ marginBottom: "1em" }}
          address1={location.address}
          city={formatCity(location)}
          country={location.country}
        />
      )}
    </ShipmentInfoColumn>
  );
};

const ODHeader = (title, icon, hideRightBorder) => {
  const { t } = useTranslation("shipment-detail");

  return (
    <ShipmentInfoColumn
      hideRightBorder={hideRightBorder}
      style={{ height: 30 }}
    >
      <div
        style={{
          position: "absolute",
          left: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          color: "white",
          backgroundColor: Colors.background.DARK_GRAY,
          padding: "4px 0"
        }}
      >
        <FontAwesomeIcon
          css={{
            height: "1.5em",
            width: "1.5em",
            marginRight: 8
          }}
          icon={icon}
          alt={`${title} Icon`}
        />
        <span>{t(`shipment-details:${title}`)}</span>
      </div>
    </ShipmentInfoColumn>
  );
};

const ODLocationScheduledArrivalWindow = (
  location,
  hideRightBorder = false,
  center = false
) => {
  const arrivalWindow = getArrivalWindowText(location);
  return (
    <ShipmentInfoColumn hideRightBorder={hideRightBorder}>
      <ScheduledArrivalWindow
        earliestArrivalTime={arrivalWindow.earliest}
        latestArrivalTime={arrivalWindow.latest}
        center={center}
      />
    </ShipmentInfoColumn>
  );
};

const ODLocationActualArrivalWindow = (location, hideRightBorder) => {
  return (
    <ShipmentInfoColumn hideRightBorder={hideRightBorder}>
      <ArrivalDepartureTimes
        arrivalTime={location.actual_arrival_datetime}
        departureTime={location.actual_departure_datetime}
      />
    </ShipmentInfoColumn>
  );
};

const ODMobile = (title, location) => {
  return (
    <React.Fragment>
      <ShipmentInfoRow>{ODTitle(title, true)}</ShipmentInfoRow>

      <ShipmentInfoRow>{ODLocationName(location, true)}</ShipmentInfoRow>

      <ShipmentInfoRow>{ODLocationAddress(location, true)}</ShipmentInfoRow>

      <ShipmentInfoRow>
        {ODHeader("Scheduled", faCalendarEdit, true)}
      </ShipmentInfoRow>

      <ShipmentInfoRow>
        {ODLocationScheduledArrivalWindow(location, true, true)}
      </ShipmentInfoRow>

      <ShipmentInfoRow>
        {ODHeader("Actual", faCalendarCheck, true)}
      </ShipmentInfoRow>

      <ShipmentInfoRow>
        {ODLocationActualArrivalWindow(location, true)}
      </ShipmentInfoRow>
    </React.Fragment>
  );
};

const scheduledArrivalWindowStyles = {
  container: { margin: "1em 0" },
  header: {
    borderBottom: `1px solid ${Colors.background.GRAY}`
  },
  headerText: {
    color: Colors.text.DARK_BLUE,
    textAlign: "center"
  },
  scheduledRow: {
    display: "flex",
    flexDirection: "row nowrap",
    alignItems: "center"
  },
  timeWrapper: { flex: "1 0 65%" },
  timeContainer: {
    display: "flex",
    flexDirection: "row"
  },
  toText: {
    display: "inline-flex",
    alignItems: "center",
    margin: "0 1em"
  }
};

const ScheduledArrivalWindow = ({
  earliestArrivalTime,
  latestArrivalTime,
  center = false
}) => {
  const { t } = useTranslation("shipment-details");

  return (
    <div css={scheduledArrivalWindowStyles.container}>
      <div css={scheduledArrivalWindowStyles.header}>
        <span css={scheduledArrivalWindowStyles.headerText}>
          {t("Arrival Window")}
        </span>
      </div>
      {/* Scheduled */}
      <div css={scheduledArrivalWindowStyles.scheduledRow}>
        <div css={scheduledArrivalWindowStyles.timeWrapper}>
          <div
            css={{
              justifyContent: center ? "center" : "flex-start",
              ...scheduledArrivalWindowStyles.timeContainer
            }}
          >
            {earliestArrivalTime && (
              <TimeDateBox
                time={earliestArrivalTime.time}
                date={earliestArrivalTime.date}
                timeStyles={{ fontWeight: "bold" }}
                timezoneStyles={{
                  fontWeight: "normal",
                  color: Colors.text.LIGHT_GRAY
                }}
              />
            )}
            {earliestArrivalTime && latestArrivalTime && (
              <span css={scheduledArrivalWindowStyles.toText}>
                {t("shipment-details:to")}
              </span>
            )}
            {latestArrivalTime && (
              <TimeDateBox
                time={latestArrivalTime.time}
                date={latestArrivalTime.date}
                timeStyles={{ fontWeight: "bold" }}
                timezoneStyles={{
                  fontWeight: "normal",
                  color: Colors.text.LIGHT_GRAY
                }}
              />
            )}
            {!earliestArrivalTime && !latestArrivalTime && (
              <span>{t("shipment-details:N/A")}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ScheduledArrivalWindow.propTypes = {
  earliestArrivalTime: PropTypes.object,
  latestArrivalTime: PropTypes.object,
  center: PropTypes.bool
};

const arrivalDepartureTimesStyles = {
  container: { margin: "1em 0" },
  header: {
    borderBottom: `1px solid ${Colors.background.GRAY}`,
    display: "flex",
    flexDirection: "row nowrap",
    color: Colors.text.DARK_BLUE
  },
  arrivalHeaderText: { flex: "1 0 25%" },
  departureHeaderText: { flex: "1 0 25%" },
  row: {
    display: "flex",
    flexDirection: "row nowrap",
    alignItems: "center",
    minHeight: "3.5em"
  },
  timesContainer: {
    flex: "1 0 65%",
    display: "flex",
    flexDirection: "row nowrap",
    alignItems: "center"
  },
  time: { flex: "1 0 50%" }
};

const ArrivalDepartureTimes = ({ arrivalTime, departureTime }) => {
  const { t } = useTranslation("shipment-details");

  return (
    <div css={arrivalDepartureTimesStyles.container}>
      <div css={arrivalDepartureTimesStyles.header}>
        <span css={arrivalDepartureTimesStyles.arrivalHeaderText}>
          {t("shipment-details:Arrival")}
        </span>
        <span css={arrivalDepartureTimesStyles.departureHeaderText}>
          {t("shipment-details:Departure")}
        </span>
      </div>

      {/* Actual */}
      <div css={arrivalDepartureTimesStyles.row}>
        <div css={arrivalDepartureTimesStyles.timesContainer}>
          {/* Arrival Time */}
          <div css={arrivalDepartureTimesStyles.time}>
            <TimeDateBox
              date={
                arrivalTime
                  ? localizedDateFormatter(arrivalTime)
                  : t("shipment-details:N/A")
              }
              time={arrivalTime ? localizedTimeFormatter(arrivalTime) : null}
              timeStyles={{ fontWeight: "bold" }}
              timezoneStyles={{
                fontWeight: "normal",
                color: Colors.text.LIGHT_GRAY
              }}
            />
          </div>

          {/* Departure Time */}
          <div css={arrivalDepartureTimesStyles.time}>
            <TimeDateBox
              date={
                departureTime
                  ? localizedDateFormatter(departureTime)
                  : t("shipment-details:N/A")
              }
              time={
                departureTime ? localizedTimeFormatter(departureTime) : null
              }
              timeStyles={{ fontWeight: "bold" }}
              timezoneStyles={{
                fontWeight: "normal",
                color: Colors.text.LIGHT_GRAY
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ArrivalDepartureTimes.propTypes = {
  arrivalTime: PropTypes.string,
  departureTime: PropTypes.string
};

export const getLocationDataFromShipment = (shipment, destShipment = null) => {
  let {
    origin_name,
    destination_name,
    origin_earliest_arrival,
    destination_earliest_arrival,
    origin_actual_arrival,
    origin_actual_departure,
    destination_actual_arrival,
    destination_actual_departure,
    origin_latest_arrival,
    destination_latest_arrival,
    origin_city,
    origin_state,
    origin_postal_code,
    origin_country,
    origin_address,
    destination_city,
    destination_state,
    destination_postal_code,
    destination_country,
    destination_address,
    origin_calculated_eta,
    destination_calculated_eta
  } = shipment;

  /* DEV-1545 LTL shipments displays dates only for Scheduled & Actual, no times. Also, no Estimated. */
  let origin_is_ltl = shipment.mode_name === "LTL";
  let destination_is_ltl = origin_is_ltl;

  // If any of our stops have an arrived at time
  // we will not populate the origin's ETA
  let origin_show_eta = true;
  if ("shipment_stops" in shipment && shipment.shipment_stops) {
    shipment.shipment_stops.forEach(stop => {
      if (stop.arrived_at != null) {
        origin_show_eta = false;
      }
    });
  }

  // DEV-1093:
  // If the shipment's delivery window has passed without a delivery, then the
  // system sets this exception.  We no longer populate the destination's ETA if so..
  let destination_show_eta = true;
  if (shipment.active_exceptions_ng === "Missed Drop-Off") {
    destination_show_eta = false;
  }

  if (destShipment) {
    destination_name = destShipment.destination_name;
    destination_earliest_arrival = destShipment.destination_earliest_arrival;
    destination_actual_arrival = destShipment.destination_actual_arrival;
    destination_actual_departure = destShipment.destination_actual_departure;
    destination_latest_arrival = destShipment.destination_latest_arrival;
    destination_city = destShipment.destination_city;
    destination_state = destShipment.destination_state;
    destination_postal_code = destShipment.destination_postal_code;
    destination_country = destShipment.destination_country;
    destination_calculated_eta = destShipment.destination_calculated_eta;
    destination_is_ltl = destShipment.mode_name === "LTL";

    // DEV-1093:
    // If the shipment's delivery window has passed without a delivery, then the
    // system sets this exception.  We no longer populate the destination's ETA if so..
    destination_show_eta = true;
    if (destShipment.active_exceptions_ng === "Missed Drop-Off") {
      destination_show_eta = false;
    }
  }

  const origin = {
    name: origin_name,
    earliest_arrival_datetime: origin_earliest_arrival,
    latest_arrival_datetime: origin_latest_arrival,
    actual_arrival_datetime: origin_actual_arrival,
    actual_departure_datetime: origin_actual_departure,
    city: origin_city,
    state: origin_state,
    postal_code: origin_postal_code,
    address: origin_address,
    country: origin_country,
    calculated_eta: origin_calculated_eta,
    is_ltl: origin_is_ltl,
    show_eta: origin_show_eta
  };
  const destination = {
    name: destination_name,
    earliest_arrival_datetime: destination_earliest_arrival,
    latest_arrival_datetime: destination_latest_arrival,
    actual_arrival_datetime: destination_actual_arrival,
    actual_departure_datetime: destination_actual_departure,
    city: destination_city,
    state: destination_state,
    address: destination_address,
    postal_code: destination_postal_code,
    country: destination_country,
    calculated_eta: destination_calculated_eta,
    is_ltl: destination_is_ltl,
    show_eta: destination_show_eta
  };

  return {
    origin,
    destination
  };
};

export const getLocationDataFromTripPlan = (tripPlan, delta) => {
  if (!tripPlan || tripPlan.length <= 0) {
    return { origin: {}, destination: {} };
  }

  let shipmentO = tripPlan[0];
  let shipmentD = tripPlan[tripPlan.length - 1];
  let showEtaO = false;
  let showEtaD = false;
  let calculatedEtaO = null;
  let calculatedEtaD = null;

  /* if no actual pickup time on Origin, eta from scheduled */
  if (!shipmentO.actual_pickup) {
    showEtaO = true;
    calculatedEtaO = calculateTimeDelta(shipmentO.scheduled_pickup, delta);
  }

  /* if no actual delivery time from Destination, eta from scheduled */
  if (!shipmentD.actual_delivery) {
    showEtaD = true;
    calculatedEtaD = calculateTimeDelta(shipmentD.scheduled_delivery, delta);
  }

  let origin = {
    name: shipmentO.name,
    earliest_arrival_datetime: shipmentO.scheduled_pickup_window[0],
    latest_arrival_datetime: shipmentO.scheduled_pickup_window[1],
    actual_arrival_datetime: shipmentO.actual_delivery,
    actual_departure_datetime: shipmentO.actual_pickup,
    city: shipmentO.city,
    state: shipmentO.state,
    country: shipmentO.country,
    address: shipmentO.address,
    show_eta: showEtaO,
    calculated_eta: calculatedEtaO,
    isLTL: false
  };

  let destination = {
    name: shipmentD.name,
    earliest_arrival_datetime: shipmentD.scheduled_delivery_window[0],
    latest_arrival_datetime: shipmentD.scheduled_delivery_window[1],
    actual_arrival_datetime: shipmentD.actual_delivery,
    actual_departure_datetime: shipmentD.actual_pickup,
    city: shipmentD.city,
    state: shipmentD.state,
    country: shipmentD.country,
    address: shipmentD.address,
    show_eta: showEtaD,
    calculated_eta: calculatedEtaD,
    isLTL: false
  };

  return {
    origin,
    destination
  };
};

/* DEV-1619 for date only display, don't localize */
// Duplicated from ArrivalWindow but with some modifications
// for it to work with this component
const getArrivalWindowText = loc => {
  const empty = { latest: null, earliest: null };
  if (!loc || !(loc.earliest_arrival_datetime || loc.latest_arrival_datetime)) {
    return empty;
  }

  const latestText = !loc.isDateOnly
    ? {
        time: localizedTimeFormatter(loc.latest_arrival_datetime),
        date: localizedDateFormatter(loc.latest_arrival_datetime)
      }
    : {
        time: null,
        date: dateFormatter(loc.latest_arrival_datetime)
      };

  const earliestText = !loc.isDateOnly
    ? {
        time: localizedTimeFormatter(loc.earliest_arrival_datetime),
        date: localizedDateFormatter(loc.earliest_arrival_datetime)
      }
    : {
        time: null,
        date: dateFormatter(loc.earliest_arrival_datetime)
      };

  if (loc.earliest_arrival_datetime && loc.latest_arrival_datetime) {
    // often they are the same
    if (loc.earliest_arrival_datetime === loc.latest_arrival_datetime) {
      return { latest: latestText, earliest: null };
    }

    return { latest: latestText, earliest: earliestText };
  }

  if (loc.latest_arrival_datetime) {
    return { latest: latestText, earliest: null };
  }
  if (loc.earliest_arrival_datetime) {
    return { latest: null, earliest: earliestText };
  }
  console.error("shouldn't get here!");
  return empty;
};

const formatCity = location => {
  if (location.city && location.state) {
    const formattedCity = `${location.city}, ${location.state}`;
    if (location.postal_code) {
      return `${formattedCity} ${location.postal_code}`;
    }
    return formattedCity;
  }
  if (location.city) {
    return location.city;
  }
  if (location.state) {
    return location.state;
  }

  return "";
};

OriginDestinationBox.propTypes = {
  destShipment: PropTypes.object,
  destination: PropTypes.object,
  displayRouteHeader: PropTypes.bool.isRequired,
  origin: PropTypes.object.isRequired,
  shipment: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default OriginDestinationBox;
