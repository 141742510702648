import PropTypes from "prop-types";
import React from "react";

/**
 * TODO: I don't think is is actually a "Cell" more just a basic bold text
 * component.
 *
 * @param children
 * @return {*}
 * @constructor
 */
const BoldCell = ({ children }) => (
  <td>
    <span css={{ fontWeight: "bold" }}>{children}</span>
  </td>
);

BoldCell.propTypes = {
  children: PropTypes.node
};

export default BoldCell;
