/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Modal, Button, FormControl, FormLabel } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import styled from "@emotion/styled";
import Colors from "../../styles/colors";
import DateTimePicker from "react-widgets/lib/DateTimePicker";

import { FlexColDiv } from "../../styles/container-elements";

const FormRow = styled.div(
  {
    display: "flex",
    flexFlow: "row wrap",
    marginLeft: "0.5em",
    marginRight: "0.5em",
    justifyContent: "flex-start",
    alignItems: "flex-end"
  },
  ({ divider }) => ({
    borderBottom: divider ? `1px solid ${"#ddd"}` : "none",
    marginBottom: divider ? `1em` : "none"
  })
);

export const EditShipmentRow = ({
  label,
  value,
  valueKey,
  isTime,
  systemValue,
  updateField
}) => {
  let dateValue = value;

  // String is an invalid type to pass the date time picker
  // null it out, the only string we might see is an empty
  // string.
  if (typeof value === "string") {
    dateValue = null;
  }

  return (
    <FormRow>
      <div
        css={{
          height: "2.5em",
          margin: "0.2em",
          marginTop: "0.5em",
          width: "8em"
        }}
      >
        {label}
      </div>
      {systemValue && (
        <div
          css={{
            height: "2.5em",
            margin: "0.2em",
            marginTop: "0.5em",
            width: "18em",
            fontWeight: "bold"
          }}
        >
          {systemValue}
        </div>
      )}
      {!systemValue && !isTime && (
        <FormControl
          style={{
            backgroundColor: "white",
            width: "18em",
            height: "2.5em",
            boxShadow: "none",
            margin: "0.2em"
          }}
          type="text"
          value={value}
          placeholder=""
          onChange={evt => updateField(valueKey, evt.target.value)}
        />
      )}
      {!systemValue && isTime && (
        <DateTimePicker
          style={{ width: "18em", margin: "0.2em" }}
          onChange={value => updateField(valueKey, value)}
          value={dateValue}
        />
      )}
    </FormRow>
  );
};

class AddAssetModal extends Component {
  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.initialData = this.state.data;

    this.handleOnEnter = this.handleOnEnter.bind(this);
    this.handleOnChangeNote = this.handleOnChangeNote.bind(this);
  }

  getInitialState() {
    return {
      data: {
        assetID: "",
        trailerNumber: "",
        effectiveDate: ""
      },
      noteValue: ""
    };
  }

  handleOnEnter() {
    const data = this.getInitialState();

    this.setState(data);
    this.initialData = data;
  }

  handleOnChangeNote(evt) {
    this.setState({ noteValue: evt.target.value });
  }

  render() {
    const {
      show,
      hide,
      shipment,
      assignAsset,
      assignTrailer,
      organization,
      organizations,
      t
    } = this.props;
    const { data, noteValue } = this.state;

    const updateField = (field, val) => {
      const mergedData = { ...data, [field]: val };
      this.setState({
        data: mergedData
      });
    };
    const userChangedData = !_.isEqual(this.initialData, data);

    return (
      <Modal
        show={show}
        style={{ ":width": "300px" }}
        onHide={() => hide()}
        onEnter={this.handleOnEnter}
      >
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {t("Assign Asset/Trailer")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <FlexColDiv
            css={{
              padding: "1em"
            }}
          >
            <EditShipmentRow
              label={t("Asset ID")}
              value={data.assetID}
              valueKey="assetID"
              updateField={updateField}
              isTime={false}
              systemValue={null}
            />
            <EditShipmentRow
              label={t("Effective Date")}
              value={data.effectiveDate}
              valueKey="effectiveDate"
              updateField={updateField}
              isTime={true}
              systemValue={null}
            />
            <EditShipmentRow
              label={t("Trailer Number")}
              value={data.trailerNumber}
              valueKey="trailerNumber"
              updateField={updateField}
              isTime={false}
              systemValue={null}
            />
          </FlexColDiv>
          <FlexColDiv>
            <FormLabel>{`${t("Notes")}:`}</FormLabel>
            <FormControl
              as="textarea"
              placeholder={t("Enter notes about the change here")}
              value={noteValue}
              onChange={this.handleOnChangeNote}
              style={{ resize: "none" }}
            />
          </FlexColDiv>
        </Modal.Body>

        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em"
            }}
            onClick={() => hide()}
          >
            {t("Cancel")}
          </Button>
          <Button
            disabled={!userChangedData}
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300"
            }}
            onClick={() => {
              // If user assigned a new asset, call that endpoint
              // DEV-1407 Don't call endpoint when asset_id is blank
              if (data.assetID && data.assetID !== "") {
                assignAsset(
                  shipment,
                  organization,
                  organizations,
                  data.assetID,
                  data.effectiveDate,
                  noteValue
                );
              }
              if (data.trailerNumber) {
                assignTrailer(
                  shipment,
                  organization,
                  organizations,
                  data.trailerNumber,
                  noteValue
                );
              }
              hide();
            }}
          >
            {t("Submit")}
          </Button>{" "}
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const withT = withTranslation("shipment-details")(AddAssetModal);
export default connect(mapStateToProps, mapDispatchToProps)(withT);
