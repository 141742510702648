import { connect } from "react-redux";
import debouncePromise from "debounce-promise";

import {
  getSolutionId,
  getCurrentOrganizationId
} from "../organizations/OrganizationsState";
import AdcLocationsState from "../adc-dashboard/AdcLocationsState";
import AdcDetailsView from "./AdcDetailsView";
import AdcDetailsVinSearchBarState, {
  getLocationTypeSelected,
  changeLocationType
} from "./components/vin-search/AdcDetailsVinSearchBarState";

function mapStateToProps(state) {
  const locationId = state.location.payload.location_id;
  const { getLocation } = AdcLocationsState.selectors;
  const { getTotalEntities } = AdcDetailsVinSearchBarState.selectors;

  return {
    solutionId: getSolutionId(state),
    organizationId: getCurrentOrganizationId(state),
    locationId: locationId,
    location: getLocation(state),
    totalEntities: getTotalEntities(state),
    locationTypeSelected: getLocationTypeSelected(state)
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchLocation,
    searchLocations,
    pushAdcDetailsView
  } = AdcLocationsState.actionCreators;

  const {
    searchEntities,
    setSearchFilter,
    clearSearchText,
    resetSearchBar
  } = AdcDetailsVinSearchBarState.actionCreators;

  return {
    fetchLocation: (solutionId, locationId) =>
      dispatch(fetchLocation(solutionId, locationId)),
    searchLocations: debouncePromise(searchLocations, 500),
    pushAdcDetailsView: locationId => dispatch(pushAdcDetailsView(locationId)),
    fetchVinSearch: (solutionId, locationId, locationType) => {
      dispatch(setSearchFilter("locationId", locationId));
      dispatch(setSearchFilter("locationType", locationType));
      dispatch(searchEntities(solutionId, false));
    },
    changeLocationType: (solutionId, value) => {
      // We need to change both: for the select and for the searchBar filter,
      // and also reinstate pagination to avoid problem related on H1-1092,
      // when you change location type after having changed the page
      dispatch(changeLocationType(value));
      dispatch(setSearchFilter("locationType", value));
      dispatch(searchEntities(solutionId, true));
    },
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdcDetailsView);
