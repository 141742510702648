import {
  getBasicQueryString,
  getBasicQueryStringFilterValuePriority,
  getEntityDateRangeQueryString,
  getEmptyQueryString
} from "../../components/search-bar/search-filter-query-strings";
import {
  DateRangeFilterButton,
  BatchFilterButton
} from "../../components/search-bar/FilterButtons";

export const FILTERS = [
  {
    queryKey: "originId",
    label: t => t("fv-vin-search:Origin"),
    optionsGetter: props => props.originFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "destinationId",
    label: t => t("fv-vin-search:Destination"),
    optionsGetter: props => props.destinationFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "carrier",
    label: t => t("fv-vin-search:Carrier"),
    optionsGetter: props => props.carrierFilterOptions,
    queryBuilder: getBasicQueryString
  },
  // TODO: re-add internal space to the "XxType" filter labels once it won't cause
  // a line break in the widget
  // (this already seems to work in the shipment filters)
  {
    // H2-305 for the filters producttype is now called description
    queryKey: "description",
    label: t => t("fv-vin-search:Product Type"),
    optionsGetter: props => props.productTypeFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "lifeCycleState",
    label: t => t("fv-vin-search:Status"),
    optionsGetter: props => props.lifeCycleStateFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "exception",
    label: t => t("fv-vin-search:Exception"),
    optionsGetter: props => {
      // H2-708: content of exceptions should be translated when presented.
      return props.exceptionTypeFilterOptions.map(item => {
        item.label = props.t(`exceptions:${item.label}`);
        return item;
      });
    },
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "pickupDate",
    label: t => t("fv-vin-search:Pickup Date"),
    component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString
  },
  {
    queryKey: "deliveryDate",
    label: t => t("fv-vin-search:Delivery Date"),
    component: DateRangeFilterButton,
    optionsGetter: () => [],
    queryBuilder: getEntityDateRangeQueryString
  },
  {
    queryKey: "holdType",
    label: t => t("fv-vin-search:Hold Type"),
    optionsGetter: props => props.holdTypeFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "ref:OrderType:0",
    label: t => t("fv-vin-search:Order Type"),
    optionsGetter: props => props.orderTypeFilterOptions,
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "partner",
    label: t => t("fv-vin-search:Partner"),
    optionsGetter: props => props.partnerFilterOptions,
    queryBuilder: getBasicQueryStringFilterValuePriority
  },
  {
    queryKey: "batch",
    label: t => t("fv-vin-search:Batch Search"),
    component: BatchFilterButton,
    optionsGetter: props => {
      const { t } = props;
      return [
        { label: t("fv-vin-search:VIN"), value: "vin" },
        { label: t("fv-vin-search:Product Type"), value: "description" }
      ];
    },
    queryBuilder: getEmptyQueryString
  }
];
