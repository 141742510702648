/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { useEffect } from "react";
import { connect } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import LadsState from "../lads/LadsState";
import DomainDataState from "../domain-data/DomainDataState";
import ShipmentsState from "./ShipmentsState";
import ShipmentSavedSearchState from "../shipment-search/ShipmentSavedSearchState";
import { getLocations } from "../location/LocationsState";

import {
  ExceptionLink,
  TRAILER_EXCEPTION_TYPES
} from "../exceptions/ExceptionLink";
import { ExceptionDonutChart } from "../exceptions/ExceptionDonutChart";

import Colors from "../../styles/colors";
import { Panel } from "../../vendor/signal-widgets/components/panel";
import {
  LadChicletCSS,
  CustomSearchChicletCSS,
  colorForLad
} from "../../components/chiclets";

import { MdEdit } from "react-icons/md";
import { BooleanValue } from "react-values";
import { useIsMediumAndDown } from "../../components/responsive";

const WatchListPanelHeader = props => {
  const { toggle, loadSavedSearch, savedSearch, location, lads } = props;

  let titleChiclet = <CustomSearchChicletCSS width={45} height={45} />;

  if (location != null) {
    const lad = lads.find(l => l.id === Number(location.lad.id));
    titleChiclet = lad ? (
      <LadChicletCSS lad={lad} width={45} height={45} showLadLabel />
    ) : null;
  }

  const alertTool = name => (
    <Tooltip id="saved-search-tooltip">
      <div
        css={{
          padding: ".75em",
          textAlign: "bottom"
        }}
      >
        {name}
      </div>
    </Tooltip>
  );

  return (
    <div
      css={{
        cursor: "default",
        display: "flex",
        flex: 10,
        flexDirection: "row",
        marginRight: "0.5em",
        alignItems: "center",
        fontSize: "small",
        overflow: "hidden"
      }}
      onClick={toggle}
    >
      <div
        css={{
          margin: "0.5em",
          marginLeft: "0.25em",
          borderRadius: "3px",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)",
          ":hover": { cursor: "pointer" }
        }}
        onClick={() => loadSavedSearch(savedSearch)}
      >
        {titleChiclet}
      </div>
      <OverlayTrigger placement="top" overlay={alertTool(savedSearch.name)}>
        <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
          {savedSearch.name}
        </span>
      </OverlayTrigger>
    </div>
  );
};

WatchListPanelHeader.propTypes = {
  lads: PropTypes.array,
  loadSavedSearch: PropTypes.func.isRequired,
  location: PropTypes.object,
  savedSearch: PropTypes.object,
  toggle: PropTypes.func.isRequired
};

const WatchlistPanelContents = ({
  trailerExceptionLinks,
  exceptionTotals,
  isWatchlist,
  loadSavedSearch,
  savedSearch,
  underReviewLink
}) => {
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "row",
        height: "100%",
        alignItems: "flex-start"
      }}
    >
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          marginTop: "1.8em",
          marginBottom: "0em"
        }}
      >
        {trailerExceptionLinks}
      </div>
      <div
        css={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          flex: 1
        }}
      >
        <div>
          <ExceptionDonutChart
            shipmentsTotals={exceptionTotals}
            isWatchlist={isWatchlist}
            onClick={() => {
              /* NOW H1-692: rollback DEV-1043 to evaluate current direction
              DEV-935 & DEV-1022 & DEV-1043 Donut onClick filters
              search results by shipment_type = Inbound
              in this case only if the search did not already have a defined
              shipment_type search
              if (!("shipment_type" in savedSearch["search"])) {
              const typeEntry = shipmentTypes.filter(
                s => s.name === "Inbound"
              );
              const typeCode = typeEntry.length > 0 ? typeEntry[0].id : 0;
              savedSearch["search"]["shipment_type"] = typeCode;
              }
              NOTE: there was a reason we were automatically populating Inbound
              even from the saved search widgets, the user would click on the
              number displayed inside of the donut and resulting counts would
              not match exactly.  I'm not convinced this will fix all issues..
              */
              loadSavedSearch(savedSearch);
            }}
          />
        </div>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            flex: 1
          }}
        >
          {underReviewLink}
        </div>
      </div>
    </div>
  );
};

WatchlistPanelContents.propTypes = {
  exceptionTotals: PropTypes.object,
  isWatchlist: PropTypes.bool,
  loadSavedSearch: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  trailerExceptionLinks: PropTypes.array,
  underReviewLink: PropTypes.object
};

const WatchlistPanel = ({
  savedSearch,
  loadSavedSearch,
  lads,
  exceptionTypes,
  exceptionTotals,
  editWatchlistItem,
  shipmentTypes,
  isWatchlist,
  fetchShipmentTotalsForSavedSearch
}) => {
  // If a single location filter is defined, put a LAD chiclet in the header
  let location = null;
  const locSearch = savedSearch.search.location;
  const origin = savedSearch.search.origin || [];
  const destination = savedSearch.search.destination || [];
  const singleLocationFilter =
    [locSearch, ...origin, ...destination].filter(l => l).length === 1;

  if (singleLocationFilter) {
    // TODO: Search location asynchronously for LAD lookup
    // location = locations.find(
    //   l => l.id === (locSearch || origin[0] || destination[0])
    // );
  }

  useEffect(() => {
    fetchShipmentTotalsForSavedSearch(savedSearch);
  }, [savedSearch, fetchShipmentTotalsForSavedSearch]);

  const isSidebarMinimized = useIsMediumAndDown();
  const isInitiallyOpened = !isSidebarMinimized;

  const trailerExceptionLinks = TRAILER_EXCEPTION_TYPES.map((e, i) => (
    <ExceptionLink
      key={i}
      shipmentsTotals={exceptionTotals}
      exceptionType={e}
      exceptionTypes={exceptionTypes}
      savedSearch={savedSearch}
      loadSavedSearch={loadSavedSearch}
      iconAlignment={"row"}
      shipmentTypes={shipmentTypes}
    />
  ));

  const underReviewLink = (
    <ExceptionLink
      key={"Under Review"}
      shipmentsTotals={exceptionTotals}
      exceptionType={"Under Review"}
      exceptionTypes={exceptionTypes}
      savedSearch={savedSearch}
      loadSavedSearch={loadSavedSearch}
      iconAlignment={"column"}
      shipmentTypes={shipmentTypes}
    />
  );

  return (
    <BooleanValue defaultValue={isInitiallyOpened}>
      {({ value: opened, toggle }) => {
        const toggleOnlyWhenSidebarMinimized = isSidebarMinimized
          ? toggle
          : () => {};
        return (
          <Panel
            title={
              <WatchListPanelHeader
                toggle={toggleOnlyWhenSidebarMinimized}
                savedSearch={savedSearch}
                loadSavedSearch={loadSavedSearch}
                location={location}
                lads={lads}
              />
            }
            size="third"
            mobileFlexBasis="60%"
            canMaximize={false}
            style={{ border: "none" }}
            headerComponents={
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  ":hover": { cursor: "pointer", color: "#ccc" }
                }}
                onClick={() => editWatchlistItem()}
              >
                <MdEdit />
              </div>
            }
            headerStyle={{
              backgroundColor:
                location != null
                  ? colorForLad(
                      lads.find(l => l.id === Number(location.lad.id))
                    )
                  : Colors.background.DARK_GRAY,
              color: "white",
              fontWeight: "300"
            }}
          >
            {opened && (
              <WatchlistPanelContents
                trailerExceptionLinks={trailerExceptionLinks}
                exceptionTotals={exceptionTotals}
                isWatchlist={isWatchlist}
                loadSavedSearch={loadSavedSearch}
                savedSearch={savedSearch}
                underReviewLink={underReviewLink}
              />
            )}
          </Panel>
        );
      }}
    </BooleanValue>
  );
};

WatchlistPanel.propTypes = {
  editWatchlistItem: PropTypes.func.isRequired,
  exceptionTotals: PropTypes.object,
  exceptionTypes: PropTypes.array,
  fetchShipmentTotalsForSavedSearch: PropTypes.func.isRequired,
  isWatchlist: PropTypes.bool,
  lads: PropTypes.array,
  loadSavedSearch: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
  shipmentTypes: PropTypes.array
};

function mapStateToProps(state, ownProps) {
  return {
    locations: getLocations(state),
    lads: LadsState.selectors.getLadsList(state),
    exceptionTypes: DomainDataState.selectors.getExceptionTypes(state),
    exceptionTotals: ShipmentsState.selectors.getExceptionTotalsForSavedSearchID(
      state,
      ownProps.savedSearch.id || null
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchShipmentTotalsForSavedSearch: savedSearch =>
      dispatch(
        ShipmentsState.actionCreators.fetchShipmentTotalsForSavedSearch(
          savedSearch
        )
      ),
    loadSavedSearch: savedSearch =>
      dispatch(
        ShipmentSavedSearchState.actionCreators.loadSavedSearch(savedSearch)
      )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WatchlistPanel);
