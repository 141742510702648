/** @jsx jsx */
import { jsx } from "@emotion/core";
import _ from "lodash";
import PropTypes from "prop-types";
import { renderToString } from "react-dom/server";
import { FaSearch } from "react-icons/fa";
import { useTranslation } from "react-i18next";

import { LadShape, getLadName } from "./lads";
import Colors from "../styles/colors";

export const UNCLASSIFIED_CHICLET_COLORS = {
  primary: "grey",
  gradient: ["black", "grey"]
};

export const NAME_FOR_LAD_ID = {
  117: "Border Crossing",
  126: "Border Crossing",
  136: "Border Crossing",
  120: "Carrier Terminal",
  111: "Consolidation",
  123: "Consolidation",
  132: "Consolidation",
  112: "Cross-dock",
  133: "Cross-dock",
  110: "Customer Plant",
  122: "Customer Plant",
  130: "Dealer",
  140: "Dealer",
  116: "Distribution",
  125: "Distribution",
  135: "Distribution",
  113: "Logistics Center",
  118: "Ocean Port",
  127: "Ocean Port",
  137: "Ocean Port",
  119: "Processor",
  138: "Processor",
  121: "Rail Station",
  129: "Rail Station",
  139: "Rail Station",
  109: "Supplier",
  131: "Supplier",
  115: "Warehouse",
  134: "Warehouse",
  114: "Yard",
  124: "Yard"
};

export const CHICLET_COLORS = {
  Assembly: { primary: "#85171a", gradient: ["#85171a", "red"] },
  "Border Crossing": { primary: "#8B4513", gradient: ["#8B4513", "sienna"] },
  "Carrier Terminal": { primary: "#C0C0C0", gradient: ["gray", "silver"] },
  Consolidation: {
    primary: "#c29a44",
    gradient: ["darkgoldenrod", "goldenrod"]
  },
  "Cross-dock": { primary: "#C0C0C0", gradient: ["gray", "silver"] },
  "Customer Plant": { primary: "#85171a", gradient: ["#85171a", "red"] },
  Dealer: { primary: "#653b91", gradient: ["#653b91", "black"] },
  Distribution: {
    primary: "#c29a44",
    gradient: ["darkgoldenrod", "goldenrod"]
  },
  "Logistics Center": {
    primary: "#c29a44",
    gradient: ["darkgoldenrod", "goldenrod"]
  },
  "Ocean Port": {
    primary: "#66CDAA",
    gradient: ["lightseagreen", "mediumaquamarine"]
  },
  Processor: { primary: "#758d3a", gradient: ["#758d3a", "forestgreen"] },
  "Rail Station": { primary: "#C0C0C0", gradient: ["gray", "silver"] },
  Supplier: { primary: "#2884c6", gradient: ["#2884c6", "deepskyblue"] },
  Warehouse: { primary: "#c29a44", gradient: ["darkgoldenrod", "goldenrod"] },
  Yard: { primary: "#b4262c", gradient: ["#b4262c", "crimson"] },
  Unclassified: UNCLASSIFIED_CHICLET_COLORS
};

const letterForShipmentMode = shipmentMode => {
  if (shipmentMode === "Rail") {
    return "R";
  }
  if (shipmentMode === "LTL") {
    return "L";
  }
  if (shipmentMode === "Intermodal") {
    return "I";
  }
  if (shipmentMode === "Ocean") {
    return "O";
  }
  if (shipmentMode === "Air") {
    return "A";
  }
  if (shipmentMode === "Multimodal") {
    return "M";
  }
  return "T";
};

const letterForStopMode = stopMode => {
  if (stopMode === "Rail") {
    return "R";
  }
  if (stopMode === "Intermodal") {
    return "I";
  }
  if (stopMode === "Ocean") {
    return "O";
  }
  if (stopMode === "Air") {
    return "A";
  }
  if (stopMode === "Multimodal") {
    return "M";
  }
  return "T";
};

export const colorsForLad = lad => {
  if (lad) {
    let name = lad.default_name
      ? lad.default_name
      : lad.canonical_name
      ? lad.canonical_name
      : NAME_FOR_LAD_ID[lad.id];

    return _.get(CHICLET_COLORS, name, UNCLASSIFIED_CHICLET_COLORS);
  }

  return UNCLASSIFIED_CHICLET_COLORS;
};

export const colorForLad = lad => colorsForLad(lad).primary;

export const gradientForLad = lad => {
  const [gradFrom, gradTo] = colorsForLad(lad).gradient;
  return `linear-gradient(${gradFrom}, ${gradTo})`;
};

// TODO:
//   - use open sans font by default and make settable

// uses <figure> and <figcaption> instead of <div> to avoid css
// contamination from react-table
export const ChicletCSS = ({
  codeLetter,
  backgroundColor,
  height = 60,
  width = 60,
  textColor = "white",
  gradientBackground = {},
  borderStyle = "none",
  active = false,
  ladLabel,
  lobLabel,
  square,
  style
}) => {
  const fontSize = `${_.floor(height / 24, 1)}em`;

  return (
    <figure
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: square ? "0px" : "3px",
        borderStyle: active ? "solid" : null,
        borderColor: active ? "rgba(255,255,255,.7)" : null,
        position: "relative",
        gradientBackground: gradientBackground,
        border: borderStyle,
        ...style
      }}
    >
      {lobLabel ? (
        <figcaption
          css={{
            top: 3,
            right: 3,
            fontSize: 5,
            fontWeight: "400",
            position: "absolute"
          }}
        >
          {lobLabel}
        </figcaption>
      ) : null}
      {ladLabel ? (
        <figcaption
          css={{
            bottom: 3,
            fontSize: 5,
            fontWeight: "400",
            position: "absolute"
          }}
        >
          {ladLabel}
        </figcaption>
      ) : null}
      <figcaption
        css={{
          fontSize: fontSize,
          fontWeight: "400",
          textTransform: "uppercase"
        }}
      >
        {codeLetter}
      </figcaption>
    </figure>
  );
};

ChicletCSS.propTypes = {
  codeLetter: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  weight: PropTypes.number,
  textColor: PropTypes.string,
  gradientBackground: PropTypes.object,
  borderStyle: PropTypes.string,
  ladLabel: PropTypes.string,
  lobLabel: PropTypes.string,
  square: PropTypes.bool
};

export const LadChicletCSS = ({
  lad,
  gradient,
  showLadLabel,
  showLobLabel,
  ...props
}) => {
  const backgroundColor = colorForLad(lad);
  const gradientBackground = gradient
    ? { backgroundImage: gradientForLad(lad) }
    : {};

  const codeLetter = lad.code.toUpperCase();

  const ladLabel = showLadLabel
    ? _.truncate(getLadName(lad), { length: 20, omission: "" })
    : null;

  const lobLabel = showLobLabel
    ? _.truncate(lad.lob_name, { length: 20, omission: "" })
    : null;

  return (
    <ChicletCSS
      codeLetter={codeLetter}
      backgroundColor={backgroundColor}
      gradientBackground={gradientBackground}
      ladLabel={ladLabel}
      lobLabel={lobLabel}
      {...props}
    />
  );
};

LadChicletCSS.propTypes = {
  lad: PropTypes.shape(LadShape),
  height: PropTypes.number,
  width: PropTypes.number,
  textColor: PropTypes.string,
  gradient: PropTypes.bool,
  showLadLabel: PropTypes.bool,
  showLobLabel: PropTypes.bool
};

export const MadChicletCSS = ({
  shipmentMode,
  stopMode,
  activeException,
  ...props
}) => {
  let codeLetter = letterForShipmentMode(shipmentMode);
  const stopCodeLetter = letterForStopMode(stopMode);
  if (codeLetter !== stopCodeLetter) {
    codeLetter = stopCodeLetter;
  }

  const borderSize = props.height / 9;
  const borderColor = exceptionStringToColor(activeException);
  const border = `${borderSize}px solid ${borderColor}`;

  return (
    <ChicletCSS
      codeLetter={codeLetter}
      backgroundColor={Colors.background.DARK_BLUE}
      borderStyle={border}
      {...props}
    />
  );
};

export const CustomSearchChicletCSS = ({
  backgroundColor,
  height = 60,
  width = 60,
  textColor = "white"
}) => {
  const { t } = useTranslation("components");
  const fontSize = `${_.floor(height / 7, 7)}px`;
  return (
    <figure
      css={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-around",
        width: width,
        height: height,
        backgroundColor: backgroundColor,
        color: textColor,
        borderRadius: "3px"
      }}
    >
      <FaSearch
        height={height / 2.5}
        width={width / 2.5}
        style={{ position: "absolute" }}
      />
      <figcaption
        css={{
          fontSize: fontSize,
          fontWeight: "400",
          textAlign: "center",
          position: "absolute",
          bottom: 2
        }}
      >
        {t("Search")}
      </figcaption>
    </figure>
  );
};

export const PartNumberChicletCSS = ({
  backgroundColor,
  height = 60,
  width = 60,
  textColor = "white",
  lad = null
}) => {
  const fontSize = lad
    ? _.floor(height / 4, 7)
    : `${_.floor(height / 2.75, 12)}px`;

  return (
    <figure
      css={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        alignItems: lad ? "left" : "center",
        justifyContent: lad ? "left" : "space-around",
        width: width,
        height: height,
        backgroundColor: lad ? "none" : backgroundColor,
        background: lad
          ? `linear-gradient(-45deg, ${colorForLad(
              lad
            )} 50%, ${backgroundColor} 50%)`
          : "none",
        color: textColor,
        borderRadius: "3px"
      }}
    >
      <figcaption
        css={{
          fontSize: fontSize,
          fontWeight: "400",
          textAlign: lad ? "left" : "center",
          position: lad ? "absolute" : "relative",
          paddingLeft: lad ? "0.5em" : null
        }}
      >
        Part
        <br />#
      </figcaption>

      {lad && (
        <figcaption
          css={{
            fontSize: fontSize * 2,
            fontWeight: "400",
            textAlign: "center",
            position: "absolute",
            right: 0,
            bottom: 0,
            paddingRight: "0.25em"
          }}
        >
          {lad.code.toUpperCase()}
        </figcaption>
      )}
    </figure>
  );
};
ChicletCSS.propTypes = {
  codeLetter: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  height: PropTypes.number,
  weight: PropTypes.number,
  textColor: PropTypes.string,
  gradientBackground: PropTypes.object
};

export const BoxChicletSVG = ({
  codeLetter,
  backgroundColor,
  decorator = null,
  decoratorColor = "white",
  decoratorFill = "black",
  borderColor = "transparent",
  textColor = "white",
  height = 64,
  width = 64,
  textSubscript = null
}) => {
  const computeCircleRadius = () => {
    if (decorator === null) {
      return 0;
    }

    const extraLength = _.max([0, decorator.toString().length - 2]);

    return 16 + extraLength * 4;
  };
  const circleRadius = computeCircleRadius();

  const boxOffsetY = circleRadius + 1;
  const boxHeight = height - circleRadius;
  const boxWidth = width - circleRadius;

  const textSize = boxHeight / 2;
  const textX = boxWidth / 2;
  const textY = boxHeight / 2 + textSize / 2 + boxOffsetY;
  const circleTextSize = boxHeight / 3;

  let circleElem = decorator ? (
    <g>
      <circle
        style={{ fill: decoratorFill }}
        stroke={decoratorColor}
        cx={boxWidth}
        cy={boxOffsetY}
        r={circleRadius}
      />
      <text
        x={boxWidth}
        alignmentBaseline="middle"
        y={boxOffsetY}
        fontSize={`${circleTextSize}pt`}
        fontFamily="sans-serif"
        fontWeight={600}
        textAnchor="middle"
        fill={decoratorColor}
      >
        {decorator}
      </text>
    </g>
  ) : null;

  return renderToString(
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <rect
        stroke={borderColor}
        strokeWidth={textSize / 2.5}
        fill={backgroundColor}
        x={1}
        y={boxOffsetY}
        rx={2}
        ry={2}
        width={boxWidth - 2}
        height={boxHeight - 2}
      />
      <text
        x={textX}
        y={textY}
        fontSize={`${textSize}pt`}
        fontFamily="sans-serif"
        fontWeight={300}
        textAnchor="middle"
        fill={textColor}
      >
        {codeLetter}
        {textSubscript ? (
          <tspan fontSize="12pt" dy="10">
            {textSubscript}
          </tspan>
        ) : null}
      </text>
      <polygon
        points="6,16 14,16 10,20"
        style={{ fill: backgroundColor, stroke: "transparent" }}
      />
      {circleElem}
    </svg>
  );
};

export const PinChicletSVG = ({
  codeLetter,
  backgroundColor,
  borderColor = "black",
  textColor = "white",
  height = 64,
  width = 64,
  textSubscript = null
}) => {
  return renderToString(
    <svg
      width={width}
      height={height}
      viewBox="-16 -18 72 72"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="rgba(0,0,0,.2)"
        stroke="none"
        transform="translate(13,48) scale(1,0.5) rotate(40) translate(7,-24)"
        d="M0,47 Q0,28 10,15 A15,15 0,1,0 -10,15 Q0,28 0,47"
      />
      <path
        fill={backgroundColor}
        stroke={borderColor}
        strokeWidth="1"
        transform="translate(15,15)"
        d="M0,47 Q0,28 10,15 A15,15 0,1,0 -10,15 Q0,28 0,47"
      />
      <text
        x="14"
        y="25"
        fontSize="14pt"
        fontFamily="sans-serif"
        fontWeight="300"
        textAnchor="middle"
        fill={textColor}
      >
        {codeLetter}
        {textSubscript ? (
          <tspan fontSize="12pt" dy="10">
            {textSubscript}
          </tspan>
        ) : null}
      </text>
    </svg>
  );
};

export const AlertChicletSVG = ({
  codeLetter = "!",
  backgroundColor,
  textColor = "white",
  height = 64,
  width = 64
}) => {
  return renderToString(
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle fill={backgroundColor} cx="16" cy="16" r="16" />
      <text
        x="15"
        y="21"
        fontSize="14pt"
        fontFamily="sans-serif"
        fontWeight="600"
        textAnchor="middle"
        fill={textColor}
      >
        {codeLetter}
      </text>
    </svg>
  );
};

const capacityStringToColor = str => {
  if (str === "Over Capacity") {
    return Colors.highlight.RED;
  } else if (str === "At Capacity") {
    return Colors.highlight.YELLOW;
  } else if (str === "Under Capacity") {
    return Colors.highlight.GREEN;
  }

  return "transparent";
};

export const LadChicletSVG = ({
  lad,
  chicletStyle = PinChicletSVG,
  ...props
}) => {
  let codeLetter = "U";
  if (lad && lad.code) {
    codeLetter = lad.code.toUpperCase();
  }
  const backgroundColor = colorForLad(lad);
  const borderColor = _.isNil(props.capacity)
    ? "transparent"
    : capacityStringToColor(props.capacity);
  const borderRadius = 4;
  return chicletStyle({
    codeLetter,
    backgroundColor,
    decoratorColor: backgroundColor,
    decoratorFill: "white",
    borderColor,
    borderRadius,
    ...props
  });
};

export const exceptionStringToColor = str => {
  if (!str) {
    return Colors.highlight.GREEN;
  } else if (
    str === "Behind Schedule" ||
    str === "Idle Trailer" ||
    str === "Car/Train Idle" ||
    str === "Under Review"
  ) {
    return Colors.highlight.YELLOW;
  }

  return Colors.highlight.RED;
};

export const MadChicletSVG = ({
  shipmentMode,
  stopMode,
  activeException,
  shipmentCount = 1,
  chicletStyle = BoxChicletSVG,
  ...props
}) => {
  let codeLetter = letterForShipmentMode(shipmentMode);
  const stopCodeLetter = letterForStopMode(stopMode);
  if (codeLetter !== stopCodeLetter) {
    codeLetter = stopCodeLetter;
  }

  const decorator = shipmentCount > 1 ? shipmentCount : null;

  const borderColor = exceptionStringToColor(activeException);
  const backgroundColor = Colors.background.DARK_BLUE;
  const decoratorFill = borderColor;

  return chicletStyle({
    codeLetter,
    backgroundColor,
    borderColor,
    decorator,
    decoratorFill,
    ...props
  });
};

export const BreadCrumbSVG = ({
  backgroundColor = "white",
  borderColor = "cornflowerblue",
  height = 16,
  width = 16
}) => {
  const circleRadius = width / 4;

  return renderToString(
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <circle
        style={{ fill: backgroundColor }}
        stroke={borderColor}
        strokeWidth={4}
        cx={width / 2}
        cy={height / 2}
        r={circleRadius}
      />
    </svg>
  );
};

export const CircleChicletSVG = ({
  text,
  backgroundColor = "black",
  borderColor = "transparent",
  textColor = "white",
  radius = 50,
  shadow = false
}) => {
  const cx = radius * 2;
  const cy = radius * 2;
  const width = radius * 4;
  const height = radius * 4;

  return renderToString(
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g>
        {shadow ? (
          <circle
            fill={backgroundColor}
            cx={cx}
            cy={cy}
            strokeWidth={0}
            fillOpacity="0.4"
            r={radius * 1.5}
          />
        ) : null}
        <circle
          fill={backgroundColor}
          cx={cx}
          cy={cy}
          stroke={borderColor}
          strokeWidth={1}
          r={radius}
        />
        <text
          x="50%"
          y="50%"
          alignmentBaseline="middle"
          fontSize={"30pt"}
          fontFamily="sans-serif"
          textAnchor="middle"
          fill={textColor}
        >
          {text}
        </text>
      </g>
    </svg>
  );
};
