import moment from "moment-timezone";
import _ from "lodash";

import apiUrl from "../../api-url";
import buildSearchBarState from "../../components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES, FILTERS } from "./AdcSearchResultsSearchOptions";
import { AdcCapacity } from "./AdcCapacity";
import { getSolutionId } from "../organizations/OrganizationsState";
import { DEFAULT_QUERYSTRING } from "../adc-dashboard/AdcEntitiesState";
const STORE_MOUNT_POINT = "adcLocationSearch";

// Actions
const actionNamer = actionName => `${STORE_MOUNT_POINT}/${actionName}`;
const CHANGE_STATUS_TYPE = actionNamer("CHANGE_STATUS_TYPE");

// Helpers

/**
 * Transform the returned data into a format easily understandable by our table
 */
const transformAdcLocations = filterFunc => payload => {
  payload.data = payload.data.filter(filterFunc).map(loc => {
    const locStatus = AdcCapacity.forPercentFill(loc.percentFill, loc.capacity)
      .label;
    return {
      ...loc,
      displayLocation: `${loc.city}, ${loc.state}`,
      status: locStatus
    };
  });
  return payload;
};

/* H2-771 default sort results by name */
const fetchSearch = (qs = null, solutionId, duck, dispatch) => {
  let url = apiUrl(`/entity/solution/${solutionId}/entity-location`);
  url = `${url}?lad=Distribution&lob=Vehicles&sortColumn=name&reverseSort=1`;
  url = `${url}&lifeCycleState=Active,Completed,Delivered`;
  url = `${url}&detail=1`;

  const params = new URLSearchParams(qs);
  const capacity = AdcCapacity.forLabel(params.get("capacity"));
  url = `${url}${capacity.queryString}`;

  // include the rest of the params too
  params.delete("capacity");
  url = `${url}&${params}`;

  const config = {
    headers: {
      "x-time-zone": moment.tz.guess()
    }
  };
  // H2-1053: omit no-capacity locations when the Under Capacity filter is
  // active
  let locationFilter = _.identity;
  if (capacity === AdcCapacity.UNDER_CAPACITY) {
    locationFilter = loc => loc.capacity > 0;
  }
  dispatch(duck.fetch(url, config, transformAdcLocations(locationFilter)));
  dispatch({ type: "ADC_SEARCH" });
};

/**
 * Just dispatch status type change on redux store, which will be captured
 * and changed by the reducer.
 */
export const changeStatusType = value => {
  return dispatch => {
    dispatch({
      type: CHANGE_STATUS_TYPE,
      payload: value
    });
  };
};

// Selectors
export const getStatusTypeSelected = state =>
  !_.isUndefined(state[STORE_MOUNT_POINT].statusTypeSelected)
    ? state[STORE_MOUNT_POINT].statusTypeSelected
    : "All Statuses";

// Reducers
/**
 * Change statusTypeSelected on redux store.
 */
const statusTypeReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_STATUS_TYPE:
      return {
        ...state,
        statusTypeSelected: action.payload
      };

    default:
      return state;
  }
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [statusTypeReducer]
);

SearchBarState.actionCreators.exportSearch = () => {
  return (dispatch, getState) => {
    const currentSolutionId = getSolutionId(getState());
    dispatch(
      SearchBarState.actionCreators.exportEntities(
        (solutionId, qs) =>
          apiUrl(
            `/entity/solution/${solutionId}/entity-location${DEFAULT_QUERYSTRING}&${qs}`
          ),
        null,
        { headers: { Accept: "text/csv" } },
        "adc-search-results",
        currentSolutionId
      )
    );
  };
};

export default SearchBarState;
