import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";

class DeleteSavedSearchModal extends Component {
  render() {
    const { show, hide, handleConfirm, t } = this.props;

    return (
      <Modal show={show} onHide={() => hide()}>
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,

            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title
            style={{
              fontWeight: "300"
            }}
          >
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          {t("components:Are you sure you want to delete this saved search?")}
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#e2e2e2"
          }}
        >
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em",
              border: "1px solid #ccc"
            }}
            onClick={() => hide()}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: Colors.highlight.RED,
              color: "white",
              border: "1px solid #ccc"
            }}
            onClick={() => handleConfirm()}
          >
            {t("components:Delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation(["components"])(DeleteSavedSearchModal);
