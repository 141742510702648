import { connect } from "react-redux";

import ProfileView from "./ProfileView";
import ShipmentSavedSearchState from "../shipment-search/ShipmentSavedSearchState";
import DomainDataState from "../domain-data/DomainDataState";
import { getLocations } from "../location/LocationsState";
import ProfileState from "./ProfileState";

function mapStateToProps(state) {
  return {
    ...state.profile,
    carriers: DomainDataState.selectors.getCarriers(state),
    exceptionTypes: DomainDataState.selectors.getExceptionTypes(state),
    locations: getLocations(state),
    shipmentModes: DomainDataState.selectors.getShipmentModes(state),
    savedSearches: ShipmentSavedSearchState.selectors.getSavedSearches(state),
    statusTypes: DomainDataState.selectors.getStatusTypes(state),
    trailerNumbers: DomainDataState.selectors.getTrailerNumbers(state),
    proNumbers: DomainDataState.selectors.getProNumbers(state),
    selectedSavedSearch: ProfileState.selectors.getSelectedSavedSearch(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSavedSearches: () =>
      dispatch(ShipmentSavedSearchState.actionCreators.fetchSavedSearches()),
    deleteSearch: id =>
      dispatch(ShipmentSavedSearchState.actionCreators.deleteSearch(id)),
    changePassword: (payload, userId) =>
      dispatch(ProfileState.actionCreators.changePassword(payload, userId)),
    resetPasswordForm: () =>
      dispatch(ProfileState.actionCreators.resetPasswordForm()),
    selectSavedSearch: item =>
      dispatch(ProfileState.actionCreators.selectSavedSearch(item))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
