/** @jsx jsx */
import styled from "@emotion/styled";
import { MediaQueries } from "../responsive";

export const BasicInfoBox = styled.div({
  fontSize: "1em",
  [MediaQueries.mediumAndUp]: {
    fontSize: "1.25em"
  }
});

export const IdBox = styled(BasicInfoBox)({
  wordBreak: "break-word"
});
