// eslint-disable-next-line no-unused-vars
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, Modal } from "react-bootstrap";

const DemandAreaList = ({ list }) => {
  const { t } = useTranslation("adc-details");
  const [showModal, setShowModal] = useState(false);

  const fullList = list.map(name => <div key={name}>{name}</div>);
  const shortList = fullList.slice(0, 2);
  const showMore = fullList.length > 2;
  const moreCount = fullList.length - shortList.length;

  return (
    <div className="d-flex flex-column">
      {shortList}
      {showMore && (
        <Button
          variant="link"
          className="p-0"
          data-qa="button-show-demand-area-modal"
          onClick={() => setShowModal(true)}
        >{`${moreCount} ${t("adc-details:more")}...`}</Button>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton className="bg-light text-body">
          <Modal.Title>{t("adc-details:Demand Areas")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div data-qa="text-demand-area-list">{fullList}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

DemandAreaList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default DemandAreaList;
