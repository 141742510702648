/**@jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";

import Colors from "../../../styles/colors";
import CountryName from "./CountryName";

const propTypes = {
  defaultTextColor: PropTypes.string,
  address1: PropTypes.string,
  address2: PropTypes.string,
  addressColor: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  state: PropTypes.string,
  style: PropTypes.object,
  lookupCountryNameFromCode: PropTypes.bool
};

/**
 * TODO: Merge this with the LocationAddress component, or at least
 * figure out why they are so different.
 */
const LocationAddressComplex = ({
  address1,
  address2,
  city,
  state,
  country,
  postalCode,
  defaultTextColor = Colors.text.GRAY,
  addressColor = Colors.text.DARK_BLUE,
  style = {},
  lookupCountryNameFromCode = true
}) => (
  <div
    css={[
      {
        display: "flex",
        flexDirection: "column",
        color: defaultTextColor,
        fontSize: "1em"
      },
      style
    ]}
  >
    {address1 && (
      <span style={{ color: addressColor }} data-qa="text-address-location">
        {address1}
      </span>
    )}
    {address2 && (
      <span style={{ color: addressColor }} data-qa="text-address-location">
        {address2}
      </span>
    )}
    <span>
      {city && <span data-qa="text-city-location">{city}</span>}
      {city && state && <span>,</span>}
      {state && (
        <span data-qa="text-state-location" css={{ marginLeft: "0.25em" }}>
          {state}
        </span>
      )}
      {postalCode && (
        <span
          data-qa="text-postal-code-location"
          css={{ marginLeft: "0.25em" }}
        >
          {postalCode}
        </span>
      )}
    </span>
    {country && (
      <span data-qa="text-country-location">
        {lookupCountryNameFromCode ? (
          <CountryName countryCode={country} />
        ) : (
          country
        )}
      </span>
    )}
  </div>
);

LocationAddressComplex.propTypes = propTypes;

export default LocationAddressComplex;
