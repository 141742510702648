import axios from "axios";
import apiUrl from "../../api-url";
import _ from "lodash";

// URLS
const STORE_MOUNT_POINT = "fvTripLeg";

// Actions
const RECEIVE_ACTUAL_TRIP_LEG = `${STORE_MOUNT_POINT}/RECIEVE_ACTUAL_TRIP_LEG`;
const RECEIVE_PLANNED_TRIP_LEG = `${STORE_MOUNT_POINT}/RECEIVE_PLANNED_TRIP_LEG`;
const RECEIVE_ACTUAL_TRIP_LEG_PROGRESS_SUCCESS = `${STORE_MOUNT_POINT}/RECEIVE_ACTUAL_TRIP_LEG_PROGRESS_SUCCESS`;
const RECEIVE_ACTUAL_TRIP_LEG_PROGRESS_FAILURE = `${STORE_MOUNT_POINT}/RECEIVE_ACTUAL_TRIP_LEG_PROGRESS_FAILURE`;
const FETCH_ACTUAL_TRIP_LEG_PROGRESS = `${STORE_MOUNT_POINT}/FETCH_ACTUAL_TRIP_LEG_PROGRESS`;

// Action creators
function fetchActualTripLeg(solutionId, entityId) {
  const ids = escape(`['${entityId}']`);
  const url = apiUrl(
    `/trip-leg/solution/${solutionId}/actual-trip-leg?entity_ids=${ids}`
  );
  return dispatch =>
    Promise.all([axios.get(`${url}`)])
      .then(responses => {
        let actualLegs = responses[0].data;
        let unfinishedTripLeg = [];

        // There's currently no endpoint to query for the actual trip legs of a single
        // entity, so the response comes back as an array of actual trip leg objects.
        if (actualLegs && actualLegs.length && actualLegs[0].tripLegs) {
          actualLegs[0].tripLegs = actualLegs[0].tripLegs.map(actualLeg => {
            if (actualLeg.dest.arrived) {
              // Leg has arrived, mark it as complete
              return {
                ...actualLeg,
                progress: 100,
                isProgressLoading: false
              };
            } else {
              // Leg hasn't arrived, query for progress.
              // After trip legs has been dispatched to avoid the race condition
              unfinishedTripLeg.push(actualLeg.id);
              return {
                ...actualLeg,
                progress: 0,
                isProgressLoading: true
              };
            }
          });
        }
        dispatch({ type: RECEIVE_ACTUAL_TRIP_LEG, payload: actualLegs });
        unfinishedTripLeg.forEach(id => {
          dispatch(fetchActualTripLegProgressUpdates(solutionId, id));
        });
      })
      .catch(err => {
        throw new Error(err);
      });
}

function fetchPlannedTripLeg(solutionId, entityId) {
  const ids = escape(`['${entityId}']`);
  const url = apiUrl(
    `/trip-leg/solution/${solutionId}/planned-trip-leg?entity_ids=${ids}`
  );

  return dispatch =>
    Promise.all([axios.get(`${url}`)])
      .then(responses => {
        dispatch({
          type: RECEIVE_PLANNED_TRIP_LEG,
          payload: responses[0].data
        });
      })
      .catch(err => {
        console.log(err);
        //throw new Error(err);
      });
}

function fetchActualTripLegProgressUpdates(solutionId, actualTripLegId) {
  const url = apiUrl(
    `/trip-leg/solution/${solutionId}/actual-trip-leg/${actualTripLegId}/progress-update`
  );

  return dispatch => {
    dispatch({
      type: FETCH_ACTUAL_TRIP_LEG_PROGRESS,
      actualTripLegId
    });
    Promise.all([axios.get(`${url}`)])
      .then(responses => {
        dispatch({
          type: RECEIVE_ACTUAL_TRIP_LEG_PROGRESS_SUCCESS,
          data: responses[0].data
        });
      })
      .catch(err => {
        // Failed to receive progress for the trip leg, set it to 0
        if (err.response && err.response.status === 404) {
          dispatch({
            type: RECEIVE_ACTUAL_TRIP_LEG_PROGRESS_FAILURE,
            actualTripLegId: actualTripLegId
          });
        } else {
          throw new Error(err);
        }
      });
  };
}

// Selectors
const getActualTripLeg = state =>
  state[STORE_MOUNT_POINT].actual || { tripLegs: [] };
const getPlannedTripLeg = state =>
  state[STORE_MOUNT_POINT].planned || { tripLegs: [] };

// Initial state
const initialState = {
  planned: { tripLegs: [] },
  actual: { tripLegs: [] }
};

const FinVehicleTripLegReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ACTUAL_TRIP_LEG:
      return {
        ...state,
        actual: action.payload[0]
      };

    case RECEIVE_PLANNED_TRIP_LEG:
      return {
        ...state,
        planned: action.payload[0]
      };

    case FETCH_ACTUAL_TRIP_LEG_PROGRESS: {
      // Find the actual trip leg in the state, mark its progress as 'loading'.
      const actualLegs = state.actual.tripLegs.map(actualLeg => {
        return actualLeg.id === action.actualTripLegId
          ? {
              ...actualLeg,
              isProgressLoading: true,
              progress: 0
            }
          : actualLeg;
      });
      return {
        ...state,
        actual: {
          ...state.actual,
          tripLegs: actualLegs
        }
      };
    }

    case RECEIVE_ACTUAL_TRIP_LEG_PROGRESS_SUCCESS: {
      let newState = { ...state };
      // Try to merge the incoming progress to the actual trip leg it belongs to.
      if (action.data.progressUpdates && action.data.progressUpdates.length) {
        const lastProgressUpdate = _.orderBy(
          action.data.progressUpdates,
          ["datetime"],
          ["desc"]
        )[0];

        const actualLegs = state.actual.tripLegs.map(actualLeg => {
          return actualLeg.id === action.data.tripLegId
            ? {
                ...actualLeg,
                isProgressLoading: false,
                progress: lastProgressUpdate.percentProgress
              }
            : actualLeg;
        });

        newState.actual = {
          ...newState.actual,
          tripLegs: actualLegs
        };
      }
      return newState;
    }

    case RECEIVE_ACTUAL_TRIP_LEG_PROGRESS_FAILURE: {
      // Failed to retrieve the progress for a trip leg, find it in the actual trip
      // legs and set it to 0.
      const actualLegs = state.actual.tripLegs.map(actualLeg => {
        return actualLeg.id === action.actualTripLegId
          ? {
              ...actualLeg,
              isProgressLoading: false,
              progress: 0
            }
          : actualLeg;
      });
      return {
        ...state,
        actual: {
          ...state.actual,
          tripLegs: actualLegs
        }
      };
    }

    default:
      return state;
  }
};

// interface
const FinVehicleTripLegState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchActualTripLeg,
    fetchPlannedTripLeg
  },
  selectors: {
    getActualTripLeg,
    getPlannedTripLeg
  },
  reducer: FinVehicleTripLegReducer
};
export default FinVehicleTripLegState;
