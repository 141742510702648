import { Enum } from "enumify";
import _ from "lodash";

import { getRadialBounds } from "./geofence-radial-bounds";
import { isCoordValid } from "./geofence-coordinates";
import { INITIAL_RADIUS_M } from "../location/LocationsState";

const MAX_RADIUS_METERS = 100000;

const round5 = _.partial(_.round, _, 5);

export const getRadialCenter = geofenceData => {
  const [lng, lat] = geofenceData.geometry.coordinates;
  return { lat, lng };
};

export const getPolygonalCenter = geofenceData => {
  const [top, left, bottom, right] = getPolygonalBoundingBox(geofenceData);
  return {
    lat: bottom + (top - bottom) / 2,
    lng: left + (right - left) / 2
  };
};

export const getMultiPolygonCenter = geofenceData => {
  const [top, left, bottom, right] = getMultiPolygonBoundingBox(geofenceData);
  return {
    lat: bottom + (top - bottom) / 2,
    lng: left + (right - left) / 2
  };
};

export const getCenter = geofenceData => {
  return getType(geofenceData).center(geofenceData);
};

export const getRadialBoundingBox = geofenceData => {
  const center = getCenter(geofenceData);
  const { buffer } = geofenceData.properties;
  return getRadialBounds(center.lat, center.lng, buffer);
};

export const getPolygonalBoundingBox = geofenceData => {
  const points = geofenceData.geometry.coordinates[0];
  const lats = points.map(pt => pt[1]);
  const lngs = points.map(pt => pt[0]);
  // top, left, bottom, right
  return [_.max(lats), _.min(lngs), _.min(lats), _.max(lngs)];
};

export const getMultiPolygonBoundingBox = geofenceData => {
  const polys = geofenceData.geometry.coordinates;

  const lats = _.flatMap(polys, poly =>
    _.flatMap(poly, points => points.map(p => p[1]))
  );
  const lngs = _.flatMap(polys, poly =>
    _.flatMap(poly, points => points.map(p => p[0]))
  );

  // top, left, bottom, right
  return [_.max(lats), _.min(lngs), _.min(lats), _.max(lngs)];
};

export const getBoundingBox = geofenceData => {
  return getType(geofenceData).boundingBox(geofenceData);
};

export const isRadialFenceValidForFenceData = geofenceData => {
  try {
    const [lng, lat] = geofenceData.geometry.coordinates;
    const { buffer } = geofenceData.properties;
    return isRadialFenceValid(lat, lng, buffer);
  } catch (err) {
    return false;
  }
};

export const isRadialFenceValid = (lat, lng, radius) => {
  if (!isCoordValid(lat, lng)) {
    return false;
  }
  if (!_.isNumber(radius)) {
    return false;
  }
  const result = !!radius && radius > 0 && radius < MAX_RADIUS_METERS;
  return result;
};

export const isFenceValid = geofenceData => {
  return (
    _.get(geofenceData, "geometry.type") &&
    getType(geofenceData).isValid(geofenceData)
  );
};

export const isPolygonalFenceValidForFenceData = geofenceData => {
  const { center } = geofenceData.properties;
  if (!isCoordValid(center.latitude, center.longitude)) {
    return false;
  }
  const { type: geomType, coordinates } = geofenceData.geometry;
  if (geomType !== "Polygon") {
    return false;
  }
  if (
    !_.isArray(coordinates) ||
    coordinates.length < 1 ||
    coordinates[0].length < 3
  ) {
    return false;
  }
  return true;
};

export const isMultiPolygonFenceValidForFenceData = geofenceData => {
  const { center } = geofenceData.properties;
  if (!isCoordValid(center.latitude, center.longitude)) {
    return false;
  }
  const { type: geomType, coordinates } = geofenceData.geometry;
  if (geomType !== "MultiPolygon") {
    return false;
  }
  // MultiPolygon should have at least one polygon, and all of them should be valid.
  if (
    !_.isArray(coordinates) ||
    coordinates.length < 1 ||
    coordinates.some(polygon => polygon.length < 1) ||
    coordinates.some(polygon => polygon[0].length < 3)
  ) {
    return false;
  }
  return true;
};

const radialToPolygonal = fence => {
  const [top, left, bottom, right] = getRadialBoundingBox(fence).map(round5);
  const { lat, lng } = getRadialCenter(fence);
  return {
    geometry: {
      coordinates: [
        [
          [left, top],
          [right, top],
          [right, bottom],
          [left, bottom],
          [left, top]
        ]
      ],
      type: "Polygon"
    },
    properties: {
      buffer: 0,
      center: {
        latitude: lat,
        longitude: lng
      }
    }
  };
};

const polygonalToMultiPolygon = fence => {
  return {
    ...fence,
    geometry: {
      coordinates:
        fence.geometry.coordinates && fence.geometry.coordinates.length > 0
          ? [fence.geometry.coordinates]
          : fence.geometry.coordinates,
      type: "MultiPolygon"
    }
  };
};

const polygonalToRadial = fence => {
  const { latitude, longitude } = fence.properties.center;
  return {
    geometry: {
      coordinates: [longitude, latitude],
      type: "Point"
    },
    properties: {
      buffer: fence.properties.buffer || INITIAL_RADIUS_M,
      center: fence.properties.center
    }
  };
};

export default class GeofenceType extends Enum {}
GeofenceType.initEnum({
  RADIAL: {
    center: getRadialCenter,
    boundingBox: getRadialBoundingBox,
    isValid: isRadialFenceValidForFenceData,
    toRadial: fence => fence,
    toPolygonal: fence => radialToPolygonal(fence),
    toMultiPolygon: fence => polygonalToMultiPolygon(radialToPolygonal(fence))
  },
  POLYGONAL: {
    center: getPolygonalCenter,
    boundingBox: getPolygonalBoundingBox,
    isValid: isPolygonalFenceValidForFenceData,
    toRadial: fence => polygonalToRadial(fence),
    toPolygonal: fence => fence,
    toMultiPolygon: fence => polygonalToMultiPolygon(fence)
  },
  MULTIPOLYGON: {
    center: getMultiPolygonCenter,
    boundingBox: getMultiPolygonBoundingBox,
    isValid: isMultiPolygonFenceValidForFenceData,
    toRadial: fence => polygonalToRadial(fence),
    toPolygonal: fence => {
      return {
        ...fence,
        geometry: {
          ...fence.geometry,
          coordinates:
            fence.geometry.coordinates && fence.geometry.coordinates.length > 0
              ? fence.geometry.coordinates[0]
              : fence.geometry.coordinates,
          type: "Polygon"
        }
      };
    },
    toMultiPolygon: fence => fence
  }
});

const geojsonTypeLookup = [
  ["Point", GeofenceType.RADIAL],
  ["Polygon", GeofenceType.POLYGONAL],
  ["MultiPolygon", GeofenceType.MULTIPOLYGON]
];

export const byFeatureType = _.fromPairs(geojsonTypeLookup);
export const byFenceType = _.fromPairs(geojsonTypeLookup.map((k, v) => [v, k]));
export const allowedGeojsonFeatureTypes = geojsonTypeLookup.map(([k, v]) => k);

export const getType = geofenceData => {
  const geojsonType = _.get(geofenceData, "geometry.type");
  if (geojsonType === undefined) {
    throw new Error("Geojson feature has no type");
  }
  const fenceType = _.get(byFeatureType, geojsonType);
  if (fenceType === undefined) {
    throw new TypeError(
      `Geofence feature has unrecognized geojson type '${geojsonType}'`
    );
  }
  return fenceType;
};
