import { connect } from "react-redux";

import OrganizationsView from "./OrganizationsView";
import {
  fetchOrganizations,
  fetchOrganizationTypes
} from "./OrganizationsState";

function mapStateToProps(state) {
  return {
    isOrganizationsLoaded: state.organizations.isOrganizationsLoaded,
    organizations: state.organizations.data
      ? state.organizations.data.response
      : state.organizations.data,
    organizationTypes: state.organizations.organizationTypes
      ? state.organizations.organizationTypes
      : []
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchOrganizations: () => dispatch(fetchOrganizations()),
    fetchOrganizationTypes: () => dispatch(fetchOrganizationTypes())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrganizationsView);
