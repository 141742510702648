import React, { Component } from "react";
import {
  Modal,
  Button,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import Colors from "../../styles/colors";
import GeofenceType from "../geofence-edit/geofence-types";
import RadialIcon from "../../assets/geofence-type-radial.svg";
import PolygonIcon from "../../assets/geofence-type-polygon.svg";

class UpdateGeofenceTypeModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      defaultType: GeofenceType.RADIAL,
      newType: null
    };

    this.handleChangeType = this.handleChangeType.bind(this);
  }

  handleChangeType(newType) {
    if (!newType) {
      return;
    }
    this.setState({ newType: newType });
  }

  render() {
    const { t, show, hide, handleConfirm, fenceType } = this.props;

    return (
      <Modal show={show} size="lg" onHide={() => hide()}>
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: "white",
            backgroundColor: Colors.background.DARK_GRAY
          }}
        >
          <Modal.Title
            style={{
              fontWeight: "300"
            }}
          >
            {t("location-edit:Update Geofence Type")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                color: Colors.text.LIGHT_GRAY,
                marginBottom: "1%",
                fontWeight: "bold"
              }}
            >
              {t("location-edit:Geofence Type")}
            </div>
            <ButtonToolbar>
              <ToggleButtonGroup
                size="lg"
                type="radio"
                name="options"
                value={
                  this.state.newType
                    ? this.state.newType
                    : fenceType
                    ? fenceType
                    : this.state.defaultType
                }
                onChange={this.handleChangeType}
              >
                <ToggleButton variant="light" value={GeofenceType.RADIAL}>
                  <img src={RadialIcon} width="23" height="23" alt="icon" />
                  {t("location-edit:Radial")}
                </ToggleButton>
                <ToggleButton variant="light" value={GeofenceType.POLYGONAL}>
                  <img src={PolygonIcon} width="23" height="23" alt="icon" />
                  {t("location-edit:Polygonal")}
                </ToggleButton>
                <ToggleButton variant="light" value={GeofenceType.MULTIPOLYGON}>
                  <img src={PolygonIcon} width="23" height="23" alt="icon" />
                  <img src={PolygonIcon} width="23" height="23" alt="icon" />
                  {t("location-edit:Multipolygon")}
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
            <div
              style={{
                borderStyle: "solid",
                borderRadius: "5px",
                border: `solid 2px ${Colors.highlight.RED}`,
                margin: "5% 0 5% 0"
              }}
            >
              <div style={{ margin: "4% 2% 4% 2%" }}>
                <div
                  style={{
                    color: Colors.highlight.RED,
                    fontSize: "1.2em",
                    marginBottom: "1%"
                  }}
                >
                  {t("location-edit:Warning!")}
                </div>
                {t(
                  "location-edit:Changing geofence type will delete previous geofence location data"
                )}
              </div>
            </div>
            <div style={{ fontWeight: "bold" }}>
              {t("location-edit:Do you want to continue?")}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#e2e2e2"
          }}
        >
          <Button
            variant="default"
            style={{
              backgroundColor: "white",
              marginRight: "0.5em",
              color: "#333",
              border: "1px solid #ccc"
            }}
            onClick={() => hide()}
          >
            {t("location-edit:Cancel")}
          </Button>
          <Button
            variant="success"
            onClick={() => {
              handleConfirm(this.state.newType);
            }}
          >
            {t("location-edit:Yes, Update")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation(["location-edit"])(UpdateGeofenceTypeModal);
