/** @jsx jsx */
import { jsx } from "@emotion/core";
import { PhotoPlaceholder } from "../../../components/no-data-placeholders";
import { useTranslation } from "react-i18next";

const vehicleImageStyles = {
  container: {
    marginRight: 20,
    backgroundColor: "#d0cfcf",
    border: "2px solid #d0cfcf",
    borderRadius: 4,
    maxWidth: 160,
    overflow: "hidden"
  },
  placeholderContainer: { padding: 10 },
  image: {
    width: "100%",
    objectFit: "contain"
  }
};

const VehicleImage = ({ media, style = {} }) => {
  const { t } = useTranslation("fv-vin-details");

  const noPic = media === null;

  return (
    <div css={[vehicleImageStyles.container, style]} data-qa="image-shipper">
      {noPic ? (
        <div css={vehicleImageStyles.placeholderContainer}>
          <PhotoPlaceholder />
        </div>
      ) : (
        <img
          css={vehicleImageStyles.image}
          src={`data:image/png;base64, ${media}`}
          alt={t("fv-vin-details:Car")}
        />
      )}
    </div>
  );
};

export default VehicleImage;
