import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import { customerApiUrl } from "../../api-url";
import axios from "axios";
import moment from "moment";
import { getType } from "../geofence-edit/geofence-types";

import { getOrganizationByID } from "../organizations/OrganizationUtils";

import { SHIPMENT_DETAILS_URL } from "../shipment-status/ShipmentsState";

const SHIPMENT_EVENTS_URL = customerApiUrl("/shipment/event");
const SHIPMENT_URL = customerApiUrl("/shipment");
const STORE_MOUNT_POINT = "editShipment";

const editShipmentDuck = buildFetchDuck("editShipment");

const updateReviewStatus = (
  shipmentID,
  reviewStatus,
  notes,
  fetchShipmentDetails
) => {
  return dispatch => {
    const payload = {
      action: reviewStatus ? "CREATE" : "CLEAR",
      status: "under_review",
      remarks: notes
    };
    const url = `${SHIPMENT_DETAILS_URL}${shipmentID}/statuses`;

    return Promise.all([axios.post(url, payload)])
      .then(responses => {
        dispatch(fetchShipmentDetails(shipmentID));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
};

const buildCarrierBlock = carrier => {
  return {
    fvCarrierId: carrier && carrier.fv_id ? String(carrier.fv_id) : "",
    scac: carrier && carrier.scac ? carrier.scac : ""
  };
};

const buildLocationBlock = stop => {
  var locDetails = {
    locationId: {
      qualifier: "",
      value: stop.location.code
    },
    name: stop.location.name,
    address1: stop.location.address,
    address2: "",
    city: stop.location.city,
    state: stop.location.state,
    postalCode: stop.location.postal_code,
    country: stop.location.country ? stop.location.country : ""
  };

  // If there is a valid geofence for this location, add
  // the center coordinate into our object
  if (stop.location.geofence) {
    const fenceType = getType(stop.location.geofence);
    if (fenceType.isValid(stop.location.geofence)) {
      locDetails["latitude"] =
        stop.location.geofence.properties.center.latitude;
      locDetails["longitude"] =
        stop.location.geofence.properties.center.longitude;
    }
  }

  return locDetails;
};

const buildTimeString = (time, stop) => {
  if (stop && stop.timezone) {
    return moment(time)
      .tz(stop.timezone)
      .format();
  } else {
    return moment(time).format();
  }
};

const buildFVshipmentIDString = (shipment, organizations) => {
  //{carrier_fv_id}|{creator_fv_id}|{shipment_id}
  const creatorOrg = getOrganizationByID(
    shipment.created_by_org_id,
    organizations
  );
  const fields = [
    shipment.carrier_fv_id,
    creatorOrg.fv_id,
    String(shipment.creator_shipment_id)
  ];
  return fields.join("|");
};

const buildHeaders = (shipment, organizations) => {
  return {
    "X-WSS-fvShipmentId": buildFVshipmentIDString(shipment, organizations)
  };
};

const createShipmentEvent = (
  shipment,
  organization,
  organizations,
  stop,
  eventCode,
  eventTime,
  notes,
  fetchShipmentDetails
) => {
  const payload = {
    carrier: buildCarrierBlock(organization),
    stopSequence: stop.stop_sequence,
    location: buildLocationBlock(stop),
    eventDetail: {
      parentShipmentDbId: shipment.parent_shipment_id,
      eventDate: buildTimeString(eventTime, stop),
      event: eventCode,
      reason: "NS", // placeholder for manual
      comments: notes
    }
  };
  const headers = buildHeaders(shipment, organizations);

  return dispatch => {
    return Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers })
    ])
      .then(responses => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch(err => {
        console.log(err);
        //throw new Error(err);
      });
  };
};

const assignAsset = (
  shipment,
  organization,
  organizations,
  assetID,
  assignDate,
  notes,
  fetchShipmentDetails
) => {
  // If no asset ID is specified, keep the existing asset ID
  const asset = assetID && assetID.length > 0 ? assetID : shipment.obc_asset_id;

  const dateValue = assignDate ? assignDate : Date();

  const payload = {
    carrier: buildCarrierBlock(organization),
    telematics: {
      trackingAssetId: asset
    },
    eventDetail: {
      eventDate: buildTimeString(dateValue, null),
      event: "XB",
      reason: "NS",
      comments: notes
    }
  };
  const headers = buildHeaders(shipment, organizations);
  return dispatch => {
    return Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers })
    ])
      .then(responses => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
};

const assignTrailer = (
  shipment,
  organization,
  organizations,
  trailerNumber,
  notes,
  fetchShipmentDetails
) => {
  const payload = {
    shipmentDetails: {
      equipmentDetail: {
        trailerNumber: trailerNumber
      }
    }
  };
  const headers = buildHeaders(shipment, organizations);
  return dispatch => {
    return Promise.all([
      axios.patch(`${SHIPMENT_URL}`, payload, { headers: headers })
    ])
      .then(responses => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
};

const cancelShipment = (
  shipment,
  organization,
  organizations,
  notes,
  fetchShipmentDetails
) => {
  const headers = buildHeaders(shipment, organizations);
  const payload = { notes: notes };

  // For the delete to work properly, need to pass that payload
  // explicity as the data object.  Reference:
  // https://github.com/axios/axios/issues/736
  // This is really sort of a workaround as there is debate
  // whether this is good form, passing data to the DELETE
  return dispatch => {
    return Promise.all([
      axios.delete(`${SHIPMENT_URL}`, { headers: headers, data: payload })
    ])
      .then(responses => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch(err => {
        console.log(err);
        throw new Error(err);
      });
  };
};

const startMobileTracking = (
  shipment,
  organization,
  organizations,
  phoneNumber,
  notes,
  fetchShipmentDetails,
  isHaulawayChild
) => {
  const payload = {
    carrier: buildCarrierBlock(organization),
    telematics: {
      trackingAssetId: "FVMB-" + phoneNumber
    },
    eventDetail: {
      eventDate: buildTimeString(Date(), null),
      event: "XB",
      reason: "NS",
      comments: notes
    }
  };

  const headers = shipment.parent_creator_shipment_id
    ? {
        "X-WSS-fvShipmentId": `${shipment.carrier_fv_id}|${shipment.shipper_fv_id}|${shipment.parent_creator_shipment_id}`
      }
    : buildHeaders(shipment, organizations);

  return dispatch => {
    return Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers })
    ])
      .then(responses => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
};

const stopMobileTracking = (
  shipment,
  organization,
  organizations,
  notes,
  fetchShipmentDetails
) => {
  const payload = {
    carrier: buildCarrierBlock(organization),
    telematics: {
      trackingAssetId: "UNKNOWN"
    },
    eventDetail: {
      eventDate: buildTimeString(Date(), null),
      event: "XB",
      reason: "NS",
      comments: notes
    }
  };
  const headers = shipment.parent_creator_shipment_id
    ? {
        "X-WSS-fvShipmentId": `${shipment.carrier_fv_id}|${shipment.shipper_fv_id}|${shipment.parent_creator_shipment_id}`
      }
    : buildHeaders(shipment, organizations);
  return dispatch => {
    return Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers })
    ])
      .then(responses => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
};

const unassignAsset = (
  shipment,
  organization,
  organizations,
  assetID,
  fetchShipmentDetails
) => {
  const payload = {
    carrier: buildCarrierBlock(organization),
    telematics: {
      trackingAssetId: assetID
    },
    eventDetail: {
      eventDate: buildTimeString(Date(), null),
      event: "XX",
      reason: "NS",
      comments: ""
    }
  };
  const headers = buildHeaders(shipment, organizations);

  return dispatch => {
    return Promise.all([
      axios.post(`${SHIPMENT_EVENTS_URL}`, payload, { headers: headers })
    ])
      .then(responses => {
        dispatch(fetchShipmentDetails(shipment.id));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
};

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    assignAsset,
    assignTrailer,
    updateReviewStatus,
    createShipmentEvent,
    cancelShipment,
    startMobileTracking,
    stopMobileTracking,
    unassignAsset
  },
  selectors: {},
  reducer: editShipmentDuck.reducer
};
