import { connect } from "react-redux";
import ReportsView from "./ReportsView";
import ReportsState from "./ReportsState";
import { getAuthorization } from "../auth/AuthorizationSelectors";

function mapStateToProps(state) {
  const { getReports, getIsLoading } = ReportsState.selectors;

  return {
    reports: getReports(state),
    isLoading: getIsLoading(state),
    authorization: getAuthorization(state)
  };
}

function mapDispatchToProps(dispatch) {
  const { fetchReports, deleteReport } = ReportsState.actionCreators;
  return {
    pushReportDetailScreen: reportId =>
      dispatch({
        type: "REPORT_DETAIL",
        payload: { reportId: reportId }
      }),
    fetchReports: () => dispatch(fetchReports()),
    deleteReport: reportId => dispatch(deleteReport(reportId))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsView);
