/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { connect } from "react-redux";
import { jsx } from "@emotion/core";
import { Tabs, TabList, TabPanel } from "react-tabs";
import { getLocationDataFromTripPlan } from "../../shipment-detail/shipment-detail-styled-components/OriginDestinationBox";
import ShipmentDetailPanel from "../../shipment-detail/ShipmentDetailPanel";
import _ from "lodash";
import {
  tabsCss,
  tabPanelStyle
} from "../../../components/multimodal-components/tabStyles";
import {
  endToEndTab,
  legTabs
} from "../../../components/multimodal-components/tabComponents";
import VinDetailsTabsPanel from "./VinDetailsTabsPanel";
import WatchToggle from "./WatchToggle";
import FinVehicleEntityDetailsState from "../FinVehicleEntityDetailsState";
import {
  addUpdates,
  convertEventsToUpdates,
  getVinDetails
} from "../vin-details-utils";
import { getActiveOrganization } from "../../organizations/OrganizationsState";
import { withTranslation, useTranslation } from "react-i18next";
import {
  PanelGroup,
  PanelGroupHeader,
  PanelGroupContent
} from "../../../components/panel-group/PanelGroup";
import OriginDestinationBox from "../../shipment-detail/shipment-detail-styled-components/OriginDestinationBox";
import VehicleImage from "./VehicleImage";
import { DetailRow } from "./SingleShipmentVinDetailPanel";
import { FlexRowDiv, FlexColDiv } from "../../../styles/container-elements";
import { ExceptionsBox } from "./ModalBoxes";
import { MediaQueries } from "../../../components/responsive";
import Colors from "../../../styles/colors";

export const flattenVinDetailsShipment = shipment => {
  let flattendArr = [];
  let locationIds = [];
  shipment.forEach(s =>
    s.shipment_stops.forEach(stop => {
      if (!_.includes(locationIds, stop.location.id)) {
        locationIds.push(stop.location.id);
        flattendArr.push({
          location: stop.location,
          active: _.toLower(s.active_status_ng) === "active",
          progress: s.current_location.distance_progress,
          mode_name: s.mode_name,
          earliest_arrival: stop.earliest_arrival_datetime,
          latest_arrival: stop.latest_arrival_datetime,
          earliest_departure: stop.earliest_departure_datetime,
          latest_departure: stop.latest_departure_datetime
        });
      }
    })
  );
  return flattendArr;
};

const EndToEndTabPanel = ({
  exception,
  details,
  media,
  shipments,
  selectCoordinate,
  clearCoordinate,
  selectedCoordinate,
  holds,
  exceptions,
  connectedCarCoords,
  eventUpdates,
  tripLegUpdates,
  vinTabs,
  tripPlan,
  plannedStops,
  watch,
  toggleWatchEntity
}) => {
  const { t } = useTranslation("fv-vin-details");

  const tripLegsEndToEnd = flattenVinDetailsShipment(shipments);
  let tripProgress = tripLegsEndToEnd.filter(s => s.active);
  if (tripProgress && tripProgress.length && tripProgress[0].progress) {
    tripProgress = tripProgress[0].progress.replace("%", "");
  }
  tripProgress =
    !tripProgress || tripProgress === "None" || tripProgress === "0"
      ? 0
      : parseInt(tripProgress, 10);

  /* H1-1105: origin, destination will come from the trip plan instead of shipments */
  const { origin, destination } = getLocationDataFromTripPlan(
    tripPlan,
    details.deltaFromSchedule
  );

  const vinDetails = getVinDetails(details);

  return (
    <div
      css={{ backgroundColor: Colors.background.LIGHT_GRAY, overflow: "auto" }}
    >
      {/* Details Group */}
      <PanelGroup>
        <PanelGroupHeader title={t("Details")}>
          <WatchToggle
            style={{
              padding: "0 1em 0 0",
              background: "transparent",
              color: "white",
              fontWeight: "normal"
            }}
            watch={watch}
            toggleWatchEntity={toggleWatchEntity}
          />
        </PanelGroupHeader>
        <PanelGroupContent>
          <FlexRowDiv
            css={{
              flexDirection: "column",
              [MediaQueries.largeAndUp]: {
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "nowrap"
              }
            }}
          >
            <FlexColDiv css={{ flex: "1 1 0", marginRight: "1em" }}>
              <DetailRow data-qa="text-vin" label={t("fv-vin-details:VIN")}>
                {vinDetails.id}
              </DetailRow>
              <DetailRow
                data-qa="text-vin-type"
                label={t("fv-vin-details:Type")}
              >
                {vinDetails.type}
              </DetailRow>
              <DetailRow
                data-qa="text-vin-status"
                label={t("fv-vin-details:Status")}
              >
                {vinDetails.status}
              </DetailRow>
            </FlexColDiv>
            <FlexRowDiv
              css={{
                alignSelf: "flex-start",
                flexDirection: "column-reverse",
                [MediaQueries.mediumAndDown]: {
                  flexDirection: "row"
                }
              }}
            >
              <VehicleImage
                style={{
                  width: 150,
                  marginRight: "1em",
                  [MediaQueries.largeAndUp]: {
                    marginTop: "1em"
                  }
                }}
                media={media}
              />
              <ExceptionsBox
                style={{ width: 150, height: 124, justifyContent: "center" }}
                holds={holds}
                exceptions={exceptions}
              />
            </FlexRowDiv>
          </FlexRowDiv>
        </PanelGroupContent>
      </PanelGroup>

      {/* Arrivals and Departures Group */}
      <PanelGroup>
        <PanelGroupHeader title={t("fv-vin-details:Arrivals and Departures")} />
        <PanelGroupContent style={{ padding: 0 }}>
          <OriginDestinationBox
            shipment={tripLegsEndToEnd}
            destShipment={null}
            origin={origin}
            destination={destination}
            displayRouteHeader={false}
          />
        </PanelGroupContent>
      </PanelGroup>

      <div css={{ padding: "1em" }}>
        <VinDetailsTabsPanel
          tabs={vinTabs}
          tripLegs={tripLegUpdates}
          eventUpdates={eventUpdates}
          coords={endToEndCoords(shipments)}
          connectedCarCoords={connectedCarCoords}
          selectCoordinate={selectCoordinate}
          clearCoordinate={clearCoordinate}
          selectedCoordinate={selectedCoordinate}
          plannedStops={plannedStops}
        />
      </div>
    </div>
  );
};

const endToEndPanel = ({
  t,
  watch,
  toggleWatchEntity,
  details,
  media,
  shipments,
  organization,
  shipmentModes,
  selectCoordinate,
  clearCoordinate,
  selectedCoordinate,
  holds,
  exceptions,
  events,
  coords,
  connectedCarCoords,
  eventUpdates,
  tripLegUpdates,
  vinTabs,
  hasTripPlan,
  tripPlan,
  plannedStopsWithUpdates
}) => {
  return (
    <TabPanel
      key="end-to-end"
      style={{ border: "1px solid #aaa" }}
      data-qa="panel-end-to-end"
    >
      <EndToEndTabPanel
        exception={t("fv-vin-details:Behind Schedule")}
        details={details}
        media={media}
        shipments={shipments}
        organization={organization}
        shipmentModes={shipmentModes}
        selectCoordinate={selectCoordinate}
        clearCoordinate={clearCoordinate}
        selectedCoordinate={selectedCoordinate}
        holds={holds}
        exceptions={exceptions}
        events={events}
        coords={coords}
        connectedCarCoords={connectedCarCoords}
        eventUpdates={eventUpdates}
        tripLegUpdates={tripLegUpdates}
        vinTabs={vinTabs}
        hasTripPlan={hasTripPlan}
        tripPlan={tripPlan}
        t={t}
        plannedStops={plannedStopsWithUpdates}
        watch={watch}
        toggleWatchEntity={toggleWatchEntity}
      />
    </TabPanel>
  );
};

const endToEndCoords = shipments => {
  let coordinatesData = [];

  if (!shipments || !shipments.length) {
    return coordinatesData;
  }

  shipments.forEach(s => {
    s.current_location.updates.forEach((c, i) => {
      coordinatesData.push(c);
    });
  });

  return coordinatesData;
};

const legPanels = (
  shipments,
  organization,
  shipmentModes,
  selectCoordinate,
  clearCoordinate,
  selectedCoordinate
) => {
  return shipments.map((shipment, i) => {
    return (
      <TabPanel
        style={tabPanelStyle}
        key={shipment.id}
        data-qa={`panel-leg-${i + 1}`}
      >
        <ShipmentDetailPanel
          shipment={shipment}
          selectCoordinate={selectCoordinate}
          clearCoordinate={clearCoordinate}
          selectedCoordinate={selectedCoordinate}
          enableActionMenu={false}
          enableReviewStatus={false}
          enableShipmentEvents={false}
          enableCancelShipment={false}
          organization={organization}
          shipmentModes={shipmentModes}
          isShipmentLoaded
          isLoaded
        />
      </TabPanel>
    );
  });
};

class MultimodalVinDetailPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabPageIndex: 0,
      tabPageSize: 4,
      tabPageCount: 1,
      showNextTabPageButton: false,
      showPrevTabPageButton: false
    };
    this.resetTabPaging = this.resetTabPaging.bind(this);
    this.isPrevTabPageButtonVisible = this.isPrevTabPageButtonVisible.bind(
      this
    );
    this.isNextTabPageButtonVisible = this.isNextTabPageButtonVisible.bind(
      this
    );
    this.showNextTabPage = this.showNextTabPage.bind(this);
    this.showPrevTabPage = this.showPrevTabPage.bind(this);
    this.sortShipments = this.sortShipments.bind(this);
  }
  resetTabPaging(shipments) {
    const tabCount = shipments.length;
    const tabPageCount = tabCount / this.state.tabPageSize;
    this.setState({ tabPageIndex: 0, tabPageCount: tabPageCount });
  }
  isPrevTabPageButtonVisible(pageIndex) {
    const minTabPage = 0;
    return pageIndex > minTabPage;
  }
  isNextTabPageButtonVisible(pageIndex) {
    const maxTabPage = this.state.tabPageCount - 1;
    return pageIndex < maxTabPage;
  }

  showNextTabPage() {
    const maxTabPage = this.state.tabPageCount - 1;
    const currentTabPage = this.state.tabPageIndex;
    const nextTabPage =
      currentTabPage >= maxTabPage ? maxTabPage : currentTabPage + 1;

    this.setState({
      tabPageIndex: nextTabPage,
      showPrevTabPageButton: this.isPrevTabPageButtonVisible(nextTabPage),
      showNextTabPageButton: this.isNextTabPageButtonVisible(nextTabPage)
    });
  }

  showPrevTabPage() {
    const minTabPage = 0;
    const currentTabPage = this.state.tabPageIndex;
    const prevTabPage =
      currentTabPage <= minTabPage ? minTabPage : currentTabPage - 1;

    this.setState({
      tabPageIndex: prevTabPage,
      showPrevTabPageButton: this.isPrevTabPageButtonVisible(prevTabPage),
      showNextTabPageButton: this.isNextTabPageButtonVisible(prevTabPage)
    });
  }

  sortShipments() {
    const { shipments, hasActualLegs, actualLegs } = this.props;

    // Sort by the origin's earliest arrival timestamp
    let shipmentsSorted = _.orderBy(shipments, [
      s => s.origin_earliest_arrival || "" // Make sure null values appear first
    ]);

    // H1-1451: If there are actual trip plan legs, sort in the exact order returned from the API
    if (hasActualLegs) {
      const actualLegIdsSorted = actualLegs.tripLegs.map(l => l.id);
      shipmentsSorted = _.sortBy(shipments, s =>
        actualLegIdsSorted.indexOf(s.creator_shipment_id)
      );
    }

    return shipmentsSorted;
  }

  componentDidMount() {
    const { shipments } = this.props;
    this.resetTabPaging(shipments);
  }
  componentDidUpdate(prevProps) {
    const { shipments } = this.props;

    if (shipments && shipments !== prevProps.shipments) {
      this.setState({
        tabPageIndex: 0,
        showPrevTabPageButton: this.isPrevTabPageButtonVisible(0),
        showNextTabPageButton: this.isNextTabPageButtonVisible(0)
      });
    }
  }

  render() {
    const {
      t,
      watch,
      toggleWatchEntity,
      eventHandler,
      details,
      media,
      organization,
      shipmentModes,
      selectCoordinate,
      clearCoordinate,
      selectedCoordinate,
      holds,
      exceptions,
      events,
      coords,
      connectedCarCoords,
      vinTabs,
      hasTripPlan,
      tripPlan,
      isLoaded,
      plannedStops
    } = this.props;

    const {
      tabPageIndex,
      tabPageSize,
      showPrevTabPageButton,
      showNextTabPageButton
    } = this.state;
    const shipmentsSorted = this.sortShipments();
    const childSummaries = shipmentsSorted.map(s => ({
      shipment_id: s.id,
      active: _.toLower(s.active_status_ng) === "active"
    }));
    let childDetails = {};
    shipmentsSorted.forEach(s => (childDetails[s.id] = { ...s }));
    const tabListStyle = {
      display: "flex",
      paddingRight: showNextTabPageButton ? 50 : 10
    };

    const tripLegUpdates = addUpdates(tripPlan, events);
    const plannedStopsWithUpdates = addUpdates(plannedStops, events);
    const eventUpdates = convertEventsToUpdates(events);

    const tabs = [
      endToEndTab(showPrevTabPageButton, eventHandler),
      ...legTabs(
        childSummaries,
        childDetails,
        tabPageIndex,
        tabPageSize,
        eventHandler,
        false
      )
    ];

    const panels = [
      endToEndPanel({
        t,
        watch,
        toggleWatchEntity,
        details,
        media,
        shipments: shipmentsSorted,
        organization,
        shipmentModes,
        selectCoordinate,
        clearCoordinate,
        selectedCoordinate,
        holds,
        exceptions,
        events,
        coords,
        connectedCarCoords,
        eventUpdates,
        tripLegUpdates,
        vinTabs,
        hasTripPlan,
        tripPlan,
        plannedStopsWithUpdates
      }),
      ...legPanels(
        shipmentsSorted,
        organization,
        shipmentModes,
        selectCoordinate,
        clearCoordinate,
        selectedCoordinate
      )
    ];

    if (!isLoaded) {
      return null;
    }
    return (
      <div
        css={{
          backgroundColor: "none",
          height: "100em",
          [MediaQueries.mediumAndUp]: {
            overflow: "auto"
          }
        }}
      >
        <Tabs css={tabsCss}>
          <TabList style={tabListStyle}>{tabs}</TabList>
          {panels}
        </Tabs>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    getCombinedEntityExceptions,
    getEntityCurrentLocation,
    getEntityEvents,
    getEntityExceptions,
    getEntityHolds,
    getEntityMedia
  } = FinVehicleEntityDetailsState.selectors;

  return {
    combinedExceptions: getCombinedEntityExceptions(state),
    currentLocation: getEntityCurrentLocation(state),
    events: getEntityEvents(state),
    exceptions: getEntityExceptions(state),
    holds: getEntityHolds(state),
    media: getEntityMedia(state),
    organization: getActiveOrganization(state)
  };
}

export default withTranslation("fv-vin-details")(
  connect(mapStateToProps)(MultimodalVinDetailPanel)
);
