/** @jsx jsx */
import { jsx } from "@emotion/core";
import Colors from "../../styles/colors";
import { ExceptionDonutChart } from "./ExceptionDonutChart";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  MdHourglassEmpty,
  MdTimer,
  MdFileUpload,
  MdFileDownload,
  MdErrorOutline,
  MdLockOutline,
  MdCheck
} from "react-icons/md";
import { FaExchangeAlt } from "react-icons/fa";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { FiEye } from "react-icons/fi";

import { FlexColDiv, FlexDiv } from "../../styles/container-elements";
import {
  ShowForLargeAndUp,
  ShowForMediumAndDown
} from "../../components/responsive";

const shipmentExceptionPropTypes = {
  shipmentType: PropTypes.string,
  shipmentsTotals: PropTypes.object,
  exceptionType: PropTypes.string,
  exceptionTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  loadSavedSearch: PropTypes.func.isRequired,
  shipmentTypes: PropTypes.arrayOf(PropTypes.object).isRequired
};

// Constants

export const TRAILER_EXCEPTION_TYPES = [
  "Behind Schedule",
  "Missed Pickup",
  "Missed Drop-Off",
  "Lost"
];

export const RAIL_EXCEPTION_TYPES = ["Idle Train", "Bad Order", "In Hold"];

export function detailPanelColorForExceptionType(exceptionType) {
  switch (exceptionType) {
    case "Idle Trailer":
    case "Behind Schedule":
    case "Idle Train":
    case "Dweller Shipment":
    case "Driver is behind schedule":
      return Colors.highlight.YELLOW;
    case "Under Review":
      return Colors.highlight.MEDIUM_LIGHT_GRAY;
    case "Inbound":
      return Colors.highlight.GREEN;
    case "Missed Pickup":
    case "Missed Drop-Off":
    case "Bad Order":
    case "In Hold":
    case "Lost":
      return Colors.highlight.RED;
    default:
      return null;
  }
}

// DEV-1429: please note we exclude "Inbound" from the case list
// so that it is NOT populated in the ExceptionDonutChart instance.
// We show the total Inbound count in the center of the chart, so the segment
// we add should be the "On Time" shipments.  Using the Inbound count for
// the green segment is not accurate, it should come from the "total_on_time"
// subset (that is the subset of shipments which are Inbound and WITHOUT
// exceptions).
export function donutChartColorForExceptionType(exceptionType) {
  switch (exceptionType) {
    case "Idle Trailer":
    case "Behind Schedule":
    case "Idle Train":
    case "Dweller Shipment":
    case "Driver is behind schedule":
      return Colors.highlight.YELLOW;
    case "Under Review":
      return Colors.highlight.MEDIUM_LIGHT_GRAY;
    case "On Time":
      return Colors.highlight.GREEN;
    case "Missed Pickup":
    case "Missed Drop-Off":
    case "Bad Order":
    case "In Hold":
    case "Lost":
      return Colors.highlight.RED;
    default:
      return null;
  }
}

export function textColorForExceptionType(exceptionType) {
  switch (exceptionType) {
    case "Idle Trailer":
    case "Behind Schedule":
    case "Idle Train":
    case "Dweller Shipment":
    case "Excessive Dwell":
    case "Driver is behind schedule":
      return Colors.highlight.YELLOW;
    case "Under Review":
      return Colors.highlight.MEDIUM_LIGHT_GRAY;
    case "Inbound":
    case "Shipment Delivered":
    case "Asset Assigned":
    case "Arrived at Pick Up":
    case "Departed Pick Up":
    case "Arrived at Drop Off":
    case "Departed Drop Off":
    case "N/A":
      return Colors.highlight.GREEN;
    case "Rack Return":
      return "#ddd";
    default:
      return Colors.highlight.RED;
  }
}

export function iconForExceptionType(exceptionType) {
  switch (exceptionType) {
    case "Idle Trailer":
    case "Dweller Shipment":
      return <MdHourglassEmpty />;
    case "Idle Train":
      return <MdHourglassEmpty />;
    case "Behind Schedule":
    case "Driver is behind schedule":
      return <MdTimer />;
    case "Missed Pickup":
    case "Driver has missed the pickup window":
    case "Pickup geofence event missing on in-transit shipment":
    case "Departed Pick Up":
    case "Arrived at Pick Up":
      return <MdFileUpload />;
    case "Missed Drop-Off":
    case "Driver has missed the delivery window":
    case "In-transit shipment should have already delivered":
    case "Departed Drop Off":
    case "Arrived at Drop Off":
      return <MdFileDownload />;
    case "Bad Order":
    case "Invalid Asset - not trackable via telematics provider":
    case "No asset assigned - shipment pickup within 30 minutes or before.":
    case "Shipment has conflicting asset IDs":
    case "Missing manual carrier updates":
    case "Invalid asset - multiple shipments with same asset":
    case "No asset assigned - shipment delivery due soon":
    case "Unassigned shipment should be in-transit":
    case "15 minutes before earliest pickup and not tracking":
    case "Delivered shipment not marked as completed":
      return <MdErrorOutline />;
    case "In Hold":
      return <MdLockOutline />;
    case "Inbound":
    case "Shipment Delivered":
    case "Asset Assigned":
      return <MdCheck />;
    case "Rack Return":
      return <FaExchangeAlt />;
    case "Lost":
      return <IoIosHelpCircleOutline />;
    case "Under Review":
      return <FiEye />;
    default:
      return <MdErrorOutline />;
  }
}

// Links to exception types in panel
export const ExceptionLink = props => {
  const { t } = useTranslation("exceptions");

  const {
    shipmentType,
    shipmentsTotals,
    exceptionType,
    exceptionTypes,
    savedSearch,
    loadSavedSearch,
    iconAlignment,
    shipmentTypes
  } = props;

  const value = shipmentsTotals
    ? shipmentsTotals.details.filter(s => s.key === exceptionType)
    : [];

  const shipmentsWithException =
    value.length > 0 ? (value[0].count != null ? value[0].count : 0) : 0;

  const textAlignment = iconAlignment === "column" ? "center" : "left";
  const numMargin = iconAlignment === "column" ? "0px" : "5px";

  const handleClick = () => {
    const exceptionName = exceptionType;
    const exceptionEntry = exceptionTypes.filter(e => e.name === exceptionName);
    const exceptionCode = exceptionEntry.length > 0 ? exceptionEntry[0].id : 0;
    const inboundType = shipmentTypes.filter(s => s.name === shipmentType);
    const inboundCode = inboundType.length > 0 ? inboundType[0].id : 0;

    let searchFilters;

    if (
      exceptionEntry &&
      exceptionEntry[0] &&
      exceptionEntry[0].name &&
      exceptionEntry[0].name === "Rack Return"
    ) {
      searchFilters = savedSearch
        ? { ...savedSearch.search }
        : { shipment_type: [inboundCode, exceptionCode] };

      if (!searchFilters.shipment_type) {
        searchFilters.shipment_type = [];
      }

      if (savedSearch) {
        searchFilters.shipment_type.push(exceptionCode);
      }
    } else if (inboundCode > 0) {
      searchFilters = savedSearch
        ? { ...savedSearch.search, active_exception: exceptionCode }
        : { active_exception: [exceptionCode], shipment_type: [inboundCode] };
    } else {
      searchFilters = savedSearch
        ? { ...savedSearch.search, active_exception: exceptionCode }
        : { active_exception: [exceptionCode] };
    }

    loadSavedSearch({ search: searchFilters });
  };

  return (
    <div
      onClick={() => handleClick()}
      css={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "4em",
        padding: "0.25em",
        paddingTop: 0,
        margin: "0 0.1em",
        fontSize: "x-large",
        border: "1px solid white",
        borderRadius: "5px",
        color: Colors.background.GRAY_BLUE,
        ":hover": {
          border: `1px solid ${Colors.background.GRAY_BLUE}`,
          backgroundColor: "#fcfcfc",
          cursor: "pointer",
          boxShadow: `inset 0 0 1px ${Colors.background.GRAY_BLUE}`
        }
      }}
      data-qa={`box-link-exception-${exceptionType}`}
    >
      <div
        css={{
          alignItems: "center"
        }}
      >
        <div
          css={{
            color: textColorForExceptionType(exceptionType),
            display: "flex",
            flexDirection: iconAlignment,
            alignItems: "center"
          }}
        >
          {iconForExceptionType(exceptionType)}
          <div
            css={{
              color: Colors.background.GRAY_BLUE,
              marginLeft: numMargin
            }}
          >
            {shipmentsWithException}
          </div>
        </div>
      </div>
      <div
        css={{
          fontStyle: "italic",
          fontSize: "small",
          fontWeight: 600,
          color: Colors.background.GRAY_BLUE,
          textAlign: textAlignment
        }}
      >
        {t(`exceptions:${exceptionType}`)}
      </div>
    </div>
  );
};
ExceptionLink.propTypes = {
  savedSearch: PropTypes.object,
  iconAlignment: PropTypes.oneOf(["column", "row"]).isRequired,
  ...shipmentExceptionPropTypes
};

export const ExceptionRow = props => {
  const {
    shipmentType,
    shipmentsTotals,
    exceptionTypes,
    loadSavedSearch,
    shipmentTypes
  } = props;

  const isNotFvLost = eType =>
    !(shipmentType === "Finished Vehicle" && eType === "Lost");

  const exceptionLinks = [
    ...TRAILER_EXCEPTION_TYPES.filter(isNotFvLost),
    ...RAIL_EXCEPTION_TYPES
  ].map((e, i) => {
    return (
      <ExceptionLink
        key={i}
        shipmentType={shipmentType}
        shipmentsTotals={shipmentsTotals}
        exceptionType={e}
        exceptionTypes={exceptionTypes}
        iconAlignment={"row"}
        loadSavedSearch={loadSavedSearch}
        shipmentTypes={shipmentTypes}
      />
    );
  });

  const underReviewLink = (
    <ExceptionLink
      shipmentType={shipmentType}
      shipmentsTotals={shipmentsTotals}
      exceptionType={"Under Review"}
      exceptionTypes={exceptionTypes}
      iconAlignment={"column"}
      loadSavedSearch={loadSavedSearch}
      shipmentTypes={shipmentTypes}
    />
  );

  return (
    <div css={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <FlexColDiv css={{ alignItems: "flex-start", paddingTop: ".5em" }}>
        <ExceptionDonutChart
          shipmentType={shipmentType}
          shipmentsTotals={shipmentsTotals}
          isTotalShipments={true}
          onClick={() => {
            /* DEV-935 & DEV-1022 Donut onClick filters
          search results by shipment_type = Inbound */
            loadSavedSearch({ search: {} });
          }}
        />
        <ShowForMediumAndDown>
          <FlexDiv css={{ flex: 1 }}>{underReviewLink}</FlexDiv>
        </ShowForMediumAndDown>
      </FlexColDiv>
      <FlexColDiv
        css={{ flex: 1, alignItems: "flex-start" }}
        className="exception-types-cell"
      >
        <FlexDiv
          css={{ flex: 1, width: "100%", flexWrap: "wrap" }}
          className="exception-types-row"
        >
          <FlexColDiv
            css={{
              color: Colors.background.DARK_BLUE,
              margin: "1em",
              fontSize: "medium",
              flex: 1,
              width: "50%",
              alignItems: "flex-start"
            }}
          >
            <FlexDiv
              css={{
                width: "100%",
                marginLeft: "-0.5em",
                flexWrap: "wrap"
              }}
              className="trailer-exceptions-row-container"
            >
              {exceptionLinks}
            </FlexDiv>
          </FlexColDiv>
          <div
            css={{
              borderLeft: "1px solid #eaeaea",
              width: 0,
              margin: "10px 0"
            }}
          />
        </FlexDiv>
      </FlexColDiv>
      <ShowForLargeAndUp>
        <FlexColDiv css={{ flex: 0.25, alignItems: "center" }}>
          <FlexDiv css={{ flex: 1 }}>
            <div>{underReviewLink}</div>
          </FlexDiv>
        </FlexColDiv>
      </ShowForLargeAndUp>
    </div>
  );
};
ExceptionRow.propTypes = { ...shipmentExceptionPropTypes };
