/** @jsx jsx */
import { jsx } from "@emotion/core";
import ResultsTable from "../../../components/tables/ResultsTable";
import { useApiLogs } from "./ApiLogsHooks";
import { getColumns } from "./ApiLogsTableColumns";
import { getExpandedRow } from "./ApiLogsTableExpandedRow";

const tableCss = {
  ".rt-td.rt-expandable": {
    paddingLeft: "5px !important"
  }
};

const totalCss = {
  marginBottom: 10
};

// Search count and results table displayed on API Logs view
const ApiLogsSearchResults = props => {
  const {
    t,
    solutionId,
    searchResults,
    isLoading,
    loadingError,
    page,
    pageSize,
    totalPages,
    totalEntities,
    setPagination
  } = useApiLogs();

  const columns = () => getColumns(t);
  const expandedRow = row => getExpandedRow(row, t);

  const total = loadingError ? 0 : totalEntities;
  const data = loadingError ? [] : searchResults;

  return (
    <div css={tableCss}>
      <div css={totalCss} data-qa="text-total-results">
        <strong>{total}</strong> {t("documentation:Total Logs")}
      </div>
      <ResultsTable
        data={data}
        manual={true}
        columns={columns}
        cellCursorPointerEnabled={false}
        showPagination={true}
        loading={isLoading}
        page={page}
        pageSize={pageSize}
        totalPages={totalPages}
        setPagination={setPagination}
        fixPaginationToBottom={true}
        solutionId={solutionId}
        SubComponent={expandedRow}
      />
    </div>
  );
};

export default ApiLogsSearchResults;
