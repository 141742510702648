import {
  getBasicQueryString,
  getJsonQueryString
} from "../../components/search-bar/search-filter-query-strings";

export const FILTERS = [
  {
    queryKey: "name_search",
    label: t => t("locations:Name"),
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "full_address_search",
    label: t => t("locations:Address"),
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "code_search",
    label: t => t("locations:Location Code"),
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "organization_lad_id",
    label: t => t("locations:Location Type Search"),
    optionsGetter: () => [],
    queryBuilder: getJsonQueryString
  },
  {
    queryKey: "customer_id",
    label: t => t("locations:Shipper"),
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "near_location",
    label: t => t("locations:Near Location"),
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "dereference",
    label: t => t("locations:Dereference"),
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "dereference_search",
    label: t => t("locations:Dereference Search"),
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  },
  {
    queryKey: "category",
    label: t => t("locations:Category"),
    optionsGetter: () => [],
    queryBuilder: getBasicQueryString
  }
];
