import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";
import { FlexDiv } from "../../styles/container-elements";
import { FILTER_SECTION_CONTAINER_CLASS_NAME } from "./filters";

// TODO: transform these FilterButtons in a component
import { MultiSelectFilterButton } from "./FilterButtons";

const FilterContainer = styled.div(
  {
    display: "flex",
    flexFlow: "row wrap",
    borderRadius: "3px",
    borderTopRightRadius: "0px",
    paddingLeft: "0.5em",
    marginBottom: "0.5em"
  },
  ({
    show = false,
    backgroundColor = Colors.background.LIGHT_GRAY,
    style = null
  }) => ({
    ...style,
    backgroundColor: backgroundColor,
    overflow: show ? "visible" : "hidden",
    maxHeight: show ? "100em" : "0em",
    transition: show ? "max-height 0.4s ease-in" : "max-height 0.4s ease-out"
  })
);

class FilterSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toggledFilter: null
    };

    this.toggleFilter = this.toggleFilter.bind(this);
    this.isFilterToggled = this.isFilterToggled.bind(this);
    this.setSearchFilter = this.setSearchFilter.bind(this);
    this.clearSearchFilter = this.clearSearchFilter.bind(this);
  }

  toggleFilter(filter) {
    if (this.state.toggledFilter === filter) {
      this.setState({ toggledFilter: null });
    } else {
      this.setState({ toggledFilter: filter });
    }
  }

  isFilterToggled(filter) {
    return this.props.show && filter === this.state.toggledFilter;
  }

  setSearchFilter(key, value) {
    this.props.setSearchFilter(key, value);

    if (!this.props.preventSearchOnChange) {
      this.props.searchEntities(this.props.solutionId);
    }
  }

  clearSearchFilter(key) {
    this.props.clearSearchFilter(key);

    if (!this.props.preventSearchOnChange) {
      this.props.searchEntities(this.props.solutionId);
    }
  }

  render() {
    const {
      backgroundColor,
      show,
      sectionStyle,
      searchFilters,
      filtersMetadata,
      t,
      solutionId
    } = this.props;
    const filterComponents = filtersMetadata.map(f => {
      // H2-816: Do not render hidden filters (such as Batch VIN)
      if (f.hidden && f.hidden(this.props)) {
        return null;
      }

      const Component = _.get(f, "component", MultiSelectFilterButton);
      return (
        <Component
          filterKey={f.queryKey}
          label={f.label(t)}
          key={`filter-${f.queryKey}`}
          toggled={this.isFilterToggled(f.queryKey)}
          onToggle={this.toggleFilter}
          options={f.optionsGetter ? f.optionsGetter(this.props) : undefined}
          loadOptions={
            f.optionsLoaderRequiresSolutionId
              ? _.partial(f.optionsLoader, solutionId)
              : f.optionsLoader
          }
          searchFilters={searchFilters}
          setSearchFilter={this.setSearchFilter}
          clearSearchFilter={this.clearSearchFilter}
          values={_.get(searchFilters, f.queryKey)}
          filterOptions={f.filterOptions}
          isFilterContainerVisible={show}
        />
      );
    });
    return (
      <div style={{ width: "100%" }} data-qa="filter-section">
        <FilterContainer
          show={show}
          backgroundColor={backgroundColor}
          style={{ ...sectionStyle, position: "relative" }}
          className={FILTER_SECTION_CONTAINER_CLASS_NAME}
        >
          <FlexDiv style={{ color: backgroundColor, flexWrap: "wrap" }}>
            {filterComponents}
          </FlexDiv>
        </FilterContainer>
      </div>
    );
  }
}
FilterSection.propTypes = {
  show: PropTypes.bool
};

export default withTranslation(["components"])(FilterSection);
