/** @jsx jsx */
import { jsx } from "@emotion/core";
import { MdWarning, MdDone } from 'react-icons/md';
import Colors from "../../../styles/colors";
import moment from "moment";
import { Privileges } from "../../auth/Authorization";

const warnings = {
  "<24 hours": Colors.highlight.YELLOW,
  "<7 days": Colors.highlight.ORANGE,
  "<7-14 days": Colors.highlight.RED,
  "15+ days": Colors.highlight.GREEN
};

const WarningRenderer = props => {
  if (props.original.warning === "15+ days") {
    return (
      <MdDone
        color={warnings[props.original.warning]}
        css={{ width: 24, height: 24 }}
      />
    );
  } else {
    return (
      <MdWarning
        color={warnings[props.original.warning]}
        css={{ width: 24, height: 24 }}
      />
    );
  }
};

const WatchRackRenderer = props => {
  const watch = props.original.watch;
  const {
    authorization,
    toggleWatchedRackFlag,
    solutionId
  } = props.columnProps.rest;

  // Only users with MANAGE_ENTITY can toggle the watched rack flag
  const isAuthorized = authorization.hasPrivileges([Privileges.MANAGE_ENTITY]);

  return (
    <input
      type="checkbox"
      checked={watch}
      onChange={() => {
        if (isAuthorized) {
          toggleWatchedRackFlag(solutionId, props.original);
        }
      }}
      style={{ margin: 0 }}
      disabled={!isAuthorized}
    />
  );
};

const DateRenderer = props => {
  return props.value ? (
    <div>{moment(props.value).format("l")}</div>
  ) : (
    <div>N/A</div>
  );
};

const LocationLinkRenderer = props => {
  const { chooseLocation } = props.columnProps.rest;
  return (
    <div
      onClick={chooseLocation ? () => chooseLocation(props.value) : null}
      style={
        chooseLocation
          ? {
              textDecoration: "underline",
              cursor: "pointer",
              color: Colors.highlight.BRIGHT_BLUE
            }
          : null
      }
    >
      {props.value}
    </div>
  );
};

const dateSort = (a, b) => {
  const dateA = moment(a.date + " " + a.time);
  const dateB = moment(b.date + " " + b.time);

  if (!dateA._isValid) {
    return 1;
  } else if (!dateB._isValid) {
    return -1;
  }
  return dateA.diff(dateB) > 0 ? 1 : -1;
};

export const resultsTableColumns = (
  t,
  solutionId,
  authorization,
  toggleWatchedRackFlag,
  chooseLocation
) => {
  return [
    {
      Header: "",
      accessor: "watch",
      Cell: WatchRackRenderer,
      width: 30,
      getProps: () => {
        return {
          solutionId,
          authorization,
          toggleWatchedRackFlag,
          style: {
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 10,
            paddingRight: 10
          }
        };
      }
    },
    {
      Header: "",
      accessor: "warning",
      Cell: WarningRenderer,
      width: 44,
      getProps: () => {
        return {
          style: {
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 10,
            paddingRight: 10
          }
        };
      }
    },
    {
      Header: t("mt-dashboard:Rack ID"),
      accessor: "id",
      width: 180,
      Cell: cellInfo => (
        <div
          style={{
            fontWeight: 600
          }}
        >
          {cellInfo.original.id}
        </div>
      )
    },
    {
      Header: t("mt-dashboard:Tag ID"),
      accessor: "tagId"
    },
    {
      Header: t("mt-dashboard:CSPC"),
      accessor: "cspcId",
      width: 100
    },
    {
      Header: t("mt-dashboard:Heat Treatment Date"),
      accessor: "heatTreatDate",
      Cell: DateRenderer,
      sortMethod: dateSort,
      width: 140
    },
    {
      Header: t("mt-dashboard:Use By Date"),
      accessor: "useByDate",
      Cell: DateRenderer,
      sortMethod: dateSort,
      width: 100
    },
    {
      Header: t("mt-dashboard:Actual Quantity"),
      accessor: "actualQuantity",
      width: 100
    },
    {
      Header: t("mt-dashboard:Max Quantity"),
      accessor: "maxQuantity",
      width: 100
    },
    {
      Header: t("mt-dashboard:Rack Type"),
      accessor: "description",
      width: 100
    },
    {
      Header: t("mt-dashboard:Rack Location"),
      Cell: LocationLinkRenderer,
      accessor: "rackLocation",
      getProps: () => {
        // Only users with VIEW_LOCATIONS can view location results
        if (authorization.hasPrivileges([Privileges.VIEW_LOCATIONS])) {
          return {
            chooseLocation
          };
        }
      }
    }
  ];
};
