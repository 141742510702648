import { connect } from "react-redux";
import { SearchBar } from "../../components/search-bar/SearchBar";
import SearchBarState from "./FinVehicleSearchBarState";
import { getSolutionId } from "../organizations/OrganizationsState";
import finVehicleDomainData from "../domain-data/FinVehicleDomainData";

import {
  selectOriginOptions,
  selectDestinationOptions
} from "./FinVehicleSearchCategorySelectors";

const mapStateToProps = state => {
  const {
    getSearchText,
    getSearchCategory,
    getTypeaheadOptionsMetadata
  } = SearchBarState.selectors;
  return {
    typeaheadOptionsMetadata: getTypeaheadOptionsMetadata(state),
    searchText: getSearchText(state),
    searchCategory: getSearchCategory(state),
    solutionId: getSolutionId(state),
    vins: finVehicleDomainData.selectors.getVINs(state),
    shipments: finVehicleDomainData.selectors.getShipments(state),
    origins: selectOriginOptions(state),
    destinations: selectDestinationOptions(state),
    productTypes: finVehicleDomainData.selectors.getProductTypes(state),
    holdTypes: finVehicleDomainData.selectors.getHoldTypes(state),
    exceptionTypes: finVehicleDomainData.selectors.getExceptionTypes(state),
    soldStatuses: finVehicleDomainData.selectors.getSoldStatuses(state)
  };
};

const mapDispatchToProps = dispatch => {
  const {
    setSearchCategoryForKey,
    setSearchText,
    clearSearchText,
    resetSearchBar,
    searchEntities
  } = SearchBarState.actionCreators;

  return {
    setSearchCategory: key => dispatch(setSearchCategoryForKey(key)),
    setSearchText: text => dispatch(setSearchText(text)),
    clearSearchText: () => dispatch(clearSearchText()),
    resetSearchBar: () => dispatch(resetSearchBar()),
    searchEntities: solutionId => dispatch(searchEntities(solutionId)),
    fetchDomainData: solutionId =>
      dispatch(finVehicleDomainData.actionCreators.fetchDomainData(solutionId))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
