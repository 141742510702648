/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component, Suspense } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import i18n, { options as i18nOptions } from "./i18n";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";
import {
  fetchDomainData,
  fetchOrganizationTypeDomainData
} from "./modules/domain-data/DomainDataState";
import { searchLocations } from "./modules/location/LocationsState";
import {
  fetchOrganizations,
  getActiveOrganization,
  getCurrentOrganizationId,
  getOrganizations,
  setActiveOrganization,
  getSolutionId,
  getFeatureData,
  getMapTypeOverride
} from "./modules/organizations/OrganizationsState";
import RolesState from "./modules/roles/RolesState";
import ShipmentSearchBarState from "./modules/shipment-search/ShipmentSearchBarState";
import LocationSearchBarState from "./modules/location-search/LocationSearchBarState";

import HeaderBar from "./modules/header-bar/HeaderBarView";
import AuthorizedComponentWithRedirect from "./modules/auth/AuthorizedComponentWithRedirect";
import AuthenticationUtils from "./modules/auth/authentication";
import Authorization, { Privileges } from "./modules/auth/Authorization";

import LadsState from "./modules/lads/LadsState";
import MapState, { getGoogleMaps } from "./modules/map/MapState";
import { Sidebar } from "./modules/appnav/SidebarNav";

import {
  routeForLocation,
  navigationForLocation,
  verifyRouteForShipper,
  verifyBrowser,
  isCarrier
} from "./routes";

import "./styles/App.scss";
import Colors from "./styles/colors";

const isErrorView = location => {
  const { type } = location;
  return (
    type &&
    (type === "VERIFY_USER_ERROR" ||
      type === "ACCESS_FORBIDDEN_ERROR" ||
      type === "UNSUPPORTED_BROWSER" ||
      type === "NOT_FOUND" ||
      type === "@@redux-first-router/NOT_FOUND")
  );
};

/**
 * Not all pages should have a sidebar rendered.
 * @param location
 * @return {null|*}
 * @constructor
 */
const ApplicationSidebar = ({ location }) => {
  if (!navigationForLocation(location)) {
    return null;
  }

  if (!AuthenticationUtils.isAuthenticated()) {
    return null;
  }

  if (isErrorView(location)) {
    return null;
  }

  return <Sidebar location={location} />;
};

ApplicationSidebar.propTypes = {
  location: PropTypes.object.isRequired
};

/**
 * Not all pages should have a header rendered.
 * @param location
 * @return {null|*}
 * @constructor
 */
const ApplicationHeader = ({ location }) => {
  if (!navigationForLocation(location)) {
    return null;
  }

  if (!AuthenticationUtils.isAuthenticated()) {
    return null;
  }

  if (isErrorView(location)) {
    return null;
  }

  return (
    <Suspense fallback="">
      <div
        style={{
          backgroundColor: Colors.background.LIGHT_GRAY,
          borderBottom: "1px solid #ddd",
          minHeight: "3.3em",
          padding: "0.25em 0.25em 0.25em 1em"
        }}
      >
        <HeaderBar location={location} />
      </div>
    </Suspense>
  );
};

ApplicationHeader.propTypes = {
  location: PropTypes.object.isRequired
};

class App extends Component {
  static propTypes = {
    activeOrganization: PropTypes.any,
    currentOrganizationId: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    currentUser: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    featureData: PropTypes.array,
    federationData: PropTypes.object,
    googleMaps: PropTypes.object,
    location: PropTypes.object,
    mapTypeOverride: PropTypes.string,
    organizations: PropTypes.array
  };

  state = {};

  constructor(props) {
    super(props);
    this.setMainView = this.setMainView.bind(this);
  }

  reloadDomainData() {
    const { dispatch } = this.props;

    if (AuthenticationUtils.isAuthenticated()) {
      // Always dispatch these methods, we cannot get
      // here unless we have made it pass the auth interceptor
      dispatch(searchLocations());
      dispatch(fetchOrganizations());
      dispatch(LadsState.actionCreators.fetchLads());
      dispatch(fetchDomainData());
    }
  }

  reloadActiveOrganizationDomainData() {
    const {
      dispatch,
      currentUser,
      activeOrganization,
      federationData,
      featureData
    } = this.props;

    if (AuthenticationUtils.isAuthenticated() && activeOrganization) {
      const authorization = new Authorization(
        currentUser,
        activeOrganization,
        federationData,
        featureData
      );
      const isCarrierOrg = isCarrier(activeOrganization);

      // H1-476: Restrict loading the roles list to the manage users privilege.
      if (authorization.hasPrivileges([Privileges.MANAGE_USERS])) {
        dispatch(RolesState.actionCreators.fetchRoles());
      }

      dispatch(fetchOrganizationTypeDomainData(isCarrierOrg));
    }
  }

  /**
   *
   * @param location
   */
  setMainView(location) {
    const { activeOrganization } = this.props;

    // if not a shipper make sure user does not have access to locations
    let checkLocation = verifyRouteForShipper(location, activeOrganization);

    const mainViewRoute = routeForLocation(verifyBrowser(checkLocation));
    const { view, privileges, features } = mainViewRoute;

    if (privileges || features) {
      const MainView = AuthorizedComponentWithRedirect(
        view,
        location,
        privileges,
        features
      );

      this.setState({
        mainView: <MainView />
      });
    } else {
      this.setState({ mainView: view });
    }
  }

  componentDidMount() {
    //
    // TODO: Do we need to format dates/times/numbers?
    Moment.locale("en");
    momentLocalizer();

    const { dispatch } = this.props;

    this.reloadDomainData();

    dispatch(MapState.actionCreators.initHere(window.H, window.google));

    if (this.props.location) {
      this.setMainView(this.props.location);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      googleMaps,
      activeOrganization,
      currentOrganizationId,
      mapTypeOverride,
      dispatch,
      organizations,
      location
    } = this.props;

    // Sometimes Google Maps takes a second to load, so if we don't have it yet
    // and window.google has it, let's initialize
    if (window.google && !googleMaps) {
      dispatch(MapState.actionCreators.initHere(window.H, window.google));
    }

    if (
      currentOrganizationId &&
      prevProps.currentOrganizationId &&
      currentOrganizationId !== prevProps.currentOrganizationId
    ) {
      // Reload domain data for the new organization and return to the root screen
      this.reloadDomainData();
      dispatch({ type: "ROOT" });
      dispatch(ShipmentSearchBarState.actionCreators.resetSearchAndFilters());

      // TODO: Add a clean way to reset entire Redux state when switching orgs
      // ^^^ Solution: Hard refresh the entire page using window.location on every org switch.
      dispatch(LocationSearchBarState.actionCreators.clearEntities());

      // Re-init i18n to fetch remote resources
      i18n.init(i18nOptions);
    }

    if (mapTypeOverride !== prevProps.mapTypeOverride) {
      // Refresh the page so that we can update the current map platform
      window.location.reload(false);
    }

    // Find current organization and save as active
    if (!_.isEqual(organizations, prevProps.organizations)) {
      let org = _.find(organizations, {
        organization_id: Number(currentOrganizationId)
      });

      if (!_.isNil(org)) {
        dispatch(setActiveOrganization(org));
      }
    }

    // Reload domain data that depends on the activeOrganization (once available)
    if (
      activeOrganization &&
      activeOrganization !== prevProps.activeOrganization
    ) {
      this.reloadActiveOrganizationDomainData();
    }

    /* DEV-1437 clear search bar when navigating away from Shipment Search via sidebar */
    /* H1-694: extend clearing search bar to navigating away from detail pages */
    if (
      (prevProps.location.type === "SHIPMENT_SEARCH" ||
        prevProps.location.type === "SHIPMENT_DETAIL" ||
        prevProps.location.type === "SHIPMENT_DETAIL_CUSTOMER") &&
      (location.type === "ROOT" ||
        location.type === "END_TO_END" ||
        location.type === "LOCATION_MANAGEMENT" ||
        location.type === "USER_MANAGEMENT" ||
        location.type === "ORGANIZATION_MANAGEMENT" ||
        location.type === "REPORTS" ||
        location.type === "CREATE_SHIPMENT")
    ) {
      dispatch(ShipmentSearchBarState.actionCreators.resetSearchAndFilters());
    }
    if (
      location !== prevProps.location ||
      activeOrganization !== prevProps.activeOrganization
    ) {
      this.setMainView(location);
    }
  }

  render() {
    const { location } = this.props;
    const { mainView } = this.state;

    if (!mainView) {
      return null;
    }

    // i18n translations might still be loaded by the xhr backend
    // use react's Suspense
    return (
      <div className="wrapper">
        <ApplicationSidebar location={location} />
        <div className="main-panel">
          <ApplicationHeader location={location} />
          <div className="content">
            <Suspense fallback="">{mainView}</Suspense>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    location: state.location,
    currentUser: state.users.currentUser,
    federationData: state.organizations.federationData,
    googleMaps: getGoogleMaps(state),
    activeOrganization: getActiveOrganization(state),
    currentOrganizationId: getCurrentOrganizationId(state),
    organizations: getOrganizations(state),
    solutionId: getSolutionId(state),
    featureData: getFeatureData(state),
    mapTypeOverride: getMapTypeOverride(state)
  };
}

export default connect(mapStateToProps)(App);
