import PropTypes from "prop-types";
import React from "react";

export function Logo({ style, ...props }) {
  return <img style={style} src={props.src} alt={props.alt} />;
}

Logo.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  style: PropTypes.object
};
