import Colors from "./colors";

// Styles for tabs displayed in page headers
// Used on Shipment Details, VIN Details, ADC Details, etc
export const headerTabsCss = {
  paddingTop: 4,
  position: "relative",

  ".react-tabs__tab-list": {
    display: "flex",
    margin: "10px 10px 0 0"
  },

  ".react-tabs__tab": {
    alignItems: "center",
    backgroundColor: "#ddd",
    border: "1px solid transparent",
    borderBottomColor: Colors.border.TAB_BORDER,
    borderRadius: "5px 5px 0 0",
    cursor: "pointer",
    display: "flex",
    flexGrow: 1,
    height: 48,
    margin: "4px 0 0 10px"
  },

  ".react-tabs__tab--selected": {
    backgroundColor: "white",
    border: `1px solid ${Colors.border.TAB_BORDER}`,
    borderBottomColor: "white",
    color: Colors.text.DARK_GRAY,
    height: 52,
    marginTop: 0
  },

  ".react-tabs__tab-panel": {
    borderTop: "1px solid #aaa"
  }
};
