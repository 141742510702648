import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import { withTranslation } from "react-i18next";
import _ from "lodash";

import { addOrganization, clearActionStatus } from "../OrganizationsState";

import {
  FormAlert,
  ModalHeader,
  ModalBody,
  ModalFooter,
  SelectInput,
  TextInput
} from "../../../components/modal-elems";
import { FlexRowDiv } from "../../../styles/container-elements";

const isValidForm = ({ name, email, selectedType }) => {
  return name && selectedType ? true : false;
};

const initOrg = {
  name: "",
  selectedType: null,
  email: "",
  freightVerifyId: "",
  contactName: "",
  phoneNumber: "",
  scac: "",
  base64Image: null
};

const AddOrganizationModal = ({
  addOrganization,
  actionStatus,
  clearActionStatus,
  hide,
  organizationName,
  organizationTypes,
  show,
  t
}) => {
  const [org, setOrg] = useState(initOrg);

  const inputHandler = value => {
    if (actionStatus) {
      clearActionStatus();
    }
    setOrg(prevState => {
      return { ...prevState, ...value };
    });
  };

  const clearForm = () => {
    setOrg(initOrg);
  };

  const orgTypeOptions = useMemo(
    () =>
      organizationTypes.map(ot => {
        return {
          value: ot.id,
          label: t(`organizations:${ot.name}`)
        };
      }),
    [organizationTypes, t]
  );

  useMemo(() => {
    if (!_.isNil(organizationName)) {
      const selectedType = orgTypeOptions.find(
        type => type.label === organizationName
      );
      setOrg(prevState => {
        return { ...prevState, selectedType };
      });
    }
  }, [organizationName, orgTypeOptions]);

  useEffect(() => {
    let timeout;

    if (actionStatus && actionStatus.includes("ORGANIZATION_ADDED")) {
      timeout = setTimeout(() => {
        setOrg(initOrg);
        clearActionStatus();
        hide();
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [actionStatus, hide, clearActionStatus]);

  const isCarrier =
    org.selectedType && org.selectedType.label === "Carrier" ? true : false;

  const onImageDrop = image => {
    if (image && image[0] instanceof Blob) {
      let reader = new FileReader();
      reader.readAsDataURL(image[0]);
      reader.onload = () => {
        inputHandler({ base64Image: reader.result });
      };
      reader.onerror = error => {
        console.error("Error: ", error);
      };
    }
  };

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        clearForm();
        hide();
      }}
    >
      <ModalHeader title={t("organizations:Add New Organization")} />
      <ModalBody>
        {actionStatus && actionStatus === "ORGANIZATION_ADDED" ? (
          <FormAlert
            alertType={"SUCCESS"}
            alert={`${t("organizations:An organization ")} ${org.name} ${t(
              "organizations:has been created"
            )}`}
          />
        ) : null}
        <FlexRowDiv>
          <TextInput
            label={t("organizations:Organization Name")}
            value={org.name}
            changeHandler={inputHandler}
            prop="name"
          />
          <SelectInput
            label={t("organizations:Type")}
            options={orgTypeOptions}
            value={org.selectedType}
            changeHandler={inputHandler}
            prop="selectedType"
            multi={false}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <TextInput
            label={t("organizations:Email")}
            value={org.email}
            changeHandler={inputHandler}
            prop="email"
          />
          <TextInput
            label={t("organizations:FreightVerify ID")}
            value={org.freightVerifyId}
            changeHandler={inputHandler}
            prop="freightVerifyId"
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <TextInput
            label={t("organizations:Contact Name")}
            value={org.contactName}
            changeHandler={inputHandler}
            prop="contactName"
          />
          <TextInput
            label={t("organizations:Phone Number")}
            value={org.phoneNumber}
            changeHandler={inputHandler}
            prop="phoneNumber"
          />
        </FlexRowDiv>
        {isCarrier ? (
          <FlexRowDiv style={{ marginTop: "1em" }}>
            <TextInput
              label={t("organizations:SCAC")}
              value={org.scac}
              changeHandler={inputHandler}
              prop="scac"
            />
          </FlexRowDiv>
        ) : null}
        <FlexRowDiv style={{ marginTop: "1.5em" }}>
          <ImageUploader
            withIcon={true}
            withPreview={true}
            singleImage={true}
            buttonText={t("organizations:Choose images")}
            label={t("organizations:Max file size 5mb, accepted jpg|gif|png")}
            fileSizeError={t("organizations:file size is too big")}
            fileTypeError={t("organizations:is not supported file extension")}
            onChange={onImageDrop}
            imgExtension={[".png"]}
            maxFileSize={5242880}
          />
        </FlexRowDiv>
        {actionStatus && actionStatus === "Organization_Exists" ? (
          <FormAlert
            alertType={"FAIL"}
            alert={t(
              "organizations:An organization with that name already exists"
            )}
          />
        ) : null}
        {actionStatus && actionStatus === "Organization_Add_Error" ? (
          <FormAlert
            alertType={"FAIL"}
            alert={t(
              "organizations:An error occurred while adding the organization"
            )}
          />
        ) : null}
      </ModalBody>
      <ModalFooter>
        <Button
          variant="light"
          style={{ marginRight: ".5em" }}
          onClick={() => {
            clearForm();
            hide();
          }}
        >
          {t("organizations:Cancel")}
        </Button>
        <Button
          variant="success"
          disabled={!isValidForm(org)}
          onClick={() => {
            const payload = {
              name: org.name,
              email_address: org.email,
              fv_id: org.freightVerifyId,
              contact_name: org.contactName,
              phone_no: org.phoneNumber,
              organization_profile_id: org.selectedType
                ? organizationTypes[org.selectedType.value].profile_id
                : 0,
              logo: org.base64Image,
              scac: org.scac
            };

            addOrganization(payload);
          }}
        >
          {t("organizations:Create")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

AddOrganizationModal.propTypes = {
  actionStatus: PropTypes.string,
  addOrganization: PropTypes.func.isRequired,
  clearActionStatus: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  organizationName: PropTypes.string,
  organizationTypes: PropTypes.array.isRequired,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    actionStatus: state.organizations.actionStatus,
    organizationTypes: state.organizations.organizationTypes
      ? state.organizations.organizationTypes
      : []
  };
}

const mapDispatchToProps = {
  addOrganization,
  clearActionStatus
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["organizations"])(AddOrganizationModal));
