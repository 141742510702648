/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { Button, FormLabel, FormControl, Modal } from "react-bootstrap";

import Colors from "../styles/colors";
import SelectField from "./forms/fields/SelectField";

export const ModalHeader = ({ title }) => {
  return (
    <Modal.Header
      closeButton
      style={{
        padding: "0.5em",
        color: Colors.background.DARK_BLUE,
        backgroundColor: Colors.background.LIGHT_GRAY
      }}
    >
      <Modal.Title style={{ fontWeight: "300" }}>{title}</Modal.Title>
    </Modal.Header>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object
  ]).isRequired
};

export const ModalBody = ({ children }) => {
  return (
    <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
      {children}
    </Modal.Body>
  );
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired
};

export const ModalFooter = ({ children }) => {
  return (
    <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
      {children}
    </Modal.Footer>
  );
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired
};

export const ReadOnlyInput = ({ label, style = {}, value, valueStyles }) => {
  return (
    <div css={{ width: "50%", ...style }}>
      <FormLabel style={{ fontWeight: "normal", color: Colors.text.HIT_GRAY }}>
        {label}:
      </FormLabel>
      <div style={{ ...valueStyles }}>{value}</div>
    </div>
  );
};

ReadOnlyInput.propTypes = {
  label: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.string,
  valueStyles: PropTypes.object
};

export const TextInput = ({
  changeHandler,
  label,
  prop,
  style = {},
  value
}) => {
  return (
    <div css={{ width: "50%", color: Colors.text.HIT_GRAY, ...style }}>
      <FormLabel style={{ fontWeight: "normal" }}>{label}</FormLabel>
      <FormControl
        style={{
          backgroundColor: "white",
          height: "3em",
          boxShadow: "none"
        }}
        type="text"
        value={value}
        onChange={evt => changeHandler({ [prop]: evt.target.value })}
      />
    </div>
  );
};

TextInput.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  prop: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.string
};

export const SelectInput = ({
  changeHandler,
  label,
  options,
  prop,
  style = {},
  value,
  multi = true
}) => {
  return (
    <div css={{ width: "50%", color: Colors.text.HIT_GRAY, ...style }}>
      <FormLabel style={{ fontWeight: "normal" }}>{label}</FormLabel>
      <SelectField
        name="selected-state"
        isMulti={multi}
        style={{ height: "3em" }}
        options={options}
        value={value}
        onChange={selections => {
          changeHandler({ [prop]: selections });
        }}
      />
    </div>
  );
};

SelectInput.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  prop: PropTypes.string.isRequired,
  style: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  multi: PropTypes.bool
};

const actionStyles = {
  backgroundColor: Colors.highlight.GREEN,
  color: "white",
  fontWeight: "300"
};

const dangerStyles = {
  backgroundColor: Colors.highlight.RED,
  color: "white",
  fontWeight: "300"
};

const defaultStyles = {
  backgroundColor: "white",
  color: Colors.background.DARK_BLUE
};

const getButtonStyles = actionType => {
  if (actionType === "ACTION") {
    return actionStyles;
  }
  if (actionType === "DANGER") {
    return dangerStyles;
  }
  return defaultStyles;
};

export const FormButton = ({
  actionType = null,
  clickHandler,
  disabled = false,
  label,
  style = {}
}) => {
  const buttonStyles = getButtonStyles(actionType);

  return (
    <Button
      style={{
        ...buttonStyles,
        ...style
      }}
      disabled={disabled}
      onClick={() => clickHandler()}
    >
      {label}
    </Button>
  );
};

FormButton.propTypes = {
  actionType: PropTypes.string,
  clickHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  style: PropTypes.object
};

export const FormAlert = ({ alert, alertType, style = {} }) => {
  return (
    <div
      style={{
        color:
          alertType === "SUCCESS"
            ? Colors.highlight.GREEN
            : Colors.highlight.RED,
        marginTop: "1em",
        marginBottom: "1em",
        ...style
      }}
    >
      {alert}
    </div>
  );
};

FormAlert.propTypes = {
  alert: PropTypes.node.isRequired,
  alertType: PropTypes.string.isRequired,
  style: PropTypes.object
};
