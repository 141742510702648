/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import MapSearchResults from "../../components/map-search-results/MapSearchResults";
import Results from "../../components/map-search-results/Results";

import SearchBar from "./components/SearchBar";
import { RECORD_LIMIT } from "./ConnectedCarSearchBarState";
import { enrichLocationsWithGeofence } from "./utils/map";
import ClumpingMap from "../map/components/ClumpingMap";
import PaginatedLocationInfoPopup from "../map/widgets/PaginatedLocationInfoPopup";
import { getColumns } from "./ConnectedCarColumns";

import { useTrackWithMixpanelOnce } from "../../trackers/mixpanel";

const ConnectedCarView = props => {
  const {
    solutionId,
    setPagination,
    connectedCarEntities,
    totalEntities,
    isLoading,
    isLoadingError,
    isExporting,
    pushVinDetailsScreen,
    clickedSearchOrEnter,
    resetClickedSearchOrEnter,
    areThereFiltersSelected
  } = props;
  useTrackWithMixpanelOnce("Viewed Page: Connected Car / Dashboard");

  const { t } = useTranslation("connected-car");
  const underRecordLimit = totalEntities <= RECORD_LIMIT && !isLoadingError;
  const mapLocations = enrichLocationsWithGeofence(
    connectedCarEntities,
    underRecordLimit
  );
  const [mapVisibleLocations, setMapVisibleLocations] = useState([]);
  const [mapHoveredLocations, setMapHoveredLocations] = useState([]);
  // Record limit warning modal
  const [showRecordLimitModal, setShowRecordLimitModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  // When user searches again, selected location should become empty
  useEffect(() => {
    setSelectedLocation(null);
  }, [connectedCarEntities]);

  useEffect(() => {
    setShowRecordLimitModal(
      isLoadingError && clickedSearchOrEnter && !isLoading
    );
  }, [isLoadingError, clickedSearchOrEnter, isLoading]);

  // Set the pageSize to 10,000 to ensure that all VINs are displayed on the map
  // (ReactTable will handle paging client-side)
  useEffect(() => {
    setPagination(solutionId);
  }, [setPagination, solutionId]);

  const hasDataToDownload = mapLocations.length > 0;
  const selectedIds = mapHoveredLocations.map(item => item.id);
  if (selectedLocation) selectedIds.push(selectedLocation.id);

  const toggleSelectedLocation = rowData => {
    const locationId = rowData.original.vin;
    if (selectedLocation?.id === locationId) {
      setSelectedLocation(null);
    } else {
      const selectedLocation = _.find(mapLocations, { id: locationId });
      setSelectedLocation(selectedLocation);
    }
  };
  return (
    <MapSearchResults
      isExporting={isExporting}
      isShowingOverlay={
        !areThereFiltersSelected || (isLoadingError && !isLoading)
      }
      isShowingRecordLimitModal={showRecordLimitModal}
      onHideRecordLimitModal={() => {
        setShowRecordLimitModal(false);
        resetClickedSearchOrEnter();
      }}
      search={<SearchBar isShowingDownloadData={hasDataToDownload} />}
      map={
        <ClumpingMap
          t={t}
          selectedLocation={selectedLocation}
          mapLocations={mapLocations}
          popupComponent={PaginatedLocationInfoPopup}
          popupClickHandler={location => {
            pushVinDetailsScreen(location.vin);
          }}
          onMapViewChange={visibleLocations => {
            setMapVisibleLocations(visibleLocations);
          }}
          onMarkerMouseEnter={hoveredLocations => {
            setMapHoveredLocations(hoveredLocations);
          }}
          onMarkerMouseOut={() => {
            setMapHoveredLocations([]);
          }}
          onPopupChangePage={location => {
            setSelectedLocation(location);
          }}
        />
      }
      results={
        <Results
          entities={!isLoadingError ? mapVisibleLocations : []}
          total={!isLoadingError ? mapVisibleLocations.length : 0}
          totalText={t("connected-car:VINs")}
          columns={getColumns(t)}
          isLoading={isLoading}
          onRowClick={toggleSelectedLocation}
          selectedEntitiesIds={selectedIds}
        />
      }
    />
  );
};

ConnectedCarView.propTypes = {
  areThereFiltersSelected: PropTypes.bool,
  solutionId: PropTypes.string.isRequired,
  setPagination: PropTypes.func.isRequired,
  connectedCarEntities: PropTypes.array.isRequired,
  totalEntities: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isExporting: PropTypes.bool.isRequired,
  isLoadingError: PropTypes.bool.isRequired,
  pushVinDetailsScreen: PropTypes.func.isRequired,
  clickedSearchOrEnter: PropTypes.bool.isRequired,
  resetClickedSearchOrEnter: PropTypes.func.isRequired
};

export default ConnectedCarView;
