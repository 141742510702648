import { connect } from "react-redux";
import AdcDashboardView from "./AdcDashboardView";
import AdcEntitiesState from "./AdcEntitiesState";
import AdcLocationsState from "./AdcLocationsState";
import FinVehicleEntitiesState from "../fv-dashboard/FinVehicleEntitiesState";
import AdcSearchBarState from "../adc-search/AdcSearchBarState";
import {
  getSolutionId,
  getCurrentOrganizationId
} from "../organizations/OrganizationsState";

function mapStateToProps(state) {
  return {
    solutionId: getSolutionId(state),
    organizationId: getCurrentOrganizationId(state),
    agingVinsByLocation: AdcEntitiesState.selectors.getAgingVinsByLocation(
      state
    ),
    adcCapacities: AdcEntitiesState.selectors.getAdcCapacities(state),
    inboundVins: AdcEntitiesState.selectors.getInboundVins(state),
    demandAreas: AdcEntitiesState.selectors.getDemandAreaList(state),
    locationsCount: AdcLocationsState.selectors.getLocationsCount(state)
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchAgingVinsByLocation,
    fetchAdcCapacities,
    fetchInboundVins,
    fetchDemandAreaList
  } = AdcEntitiesState.actionCreators;
  const {
    fetchLocationsCount,
    pushAdcDetailsView,
    searchLocations
  } = AdcLocationsState.actionCreators;
  const {
    clearSearchFilters,
    resetSavedSearch,
    resetSearchBar
  } = AdcSearchBarState.actionCreators;
  const { fetchEntityCount } = FinVehicleEntitiesState.actionCreators;
  return {
    fetchAgingVinsByLocation: (solutionId, demandArea = null) =>
      dispatch(fetchAgingVinsByLocation(solutionId, demandArea)),
    fetchAdcCapacities: (solutionId, demandArea = null) =>
      dispatch(fetchAdcCapacities(solutionId, demandArea)),
    fetchInboundVins: (solutionId, locations, demandArea = null) =>
      dispatch(fetchInboundVins(solutionId, locations, demandArea)),
    fetchLocationsCount: () => dispatch(fetchLocationsCount()),
    fetchDemandAreaList: solutionId =>
      dispatch(fetchDemandAreaList(solutionId)),
    pushAdcDetailsView: locationId => {
      dispatch(pushAdcDetailsView(locationId));
    },
    searchLocations: (query, demandArea) =>
      dispatch(searchLocations(query, demandArea)),
    fetchTotalVins: solutionId => dispatch(fetchEntityCount(solutionId)),
    resetSearchBar: () => dispatch(resetSearchBar()),
    clearSearchFilters: () => dispatch(clearSearchFilters()),
    resetSavedSearch: () => dispatch(resetSavedSearch())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdcDashboardView);
