/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Component } from "react";
import colors from "../styles/colors";
import { FaRegCheckCircle } from 'react-icons/fa';
import styled from "@emotion/styled";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { FlexDiv } from "../styles/container-elements";

const FlexRow = styled(FlexDiv)({ width: "100%" });

class IsPasswordValidChecks extends Component {
  render() {
    const { t, lengthIsValid, passwordsMatch, regexIsValid } = this.props;

    const validateLength = _.get(this.props, "validateLength", true);
    const validateMatch = _.get(this.props, "validateMatch", true);
    const validateRegex = _.get(this.props, "validateRegex", true);

    return (
      <div>
        {validateLength ? (
          <FlexRow style={{ marginBottom: ".25em" }}>
            <FaRegCheckCircle
              style={{
                color: lengthIsValid ? colors.highlight.GREEN : "#e0e0e0",
                fontSize: "large"
              }}
            />
            <span css={{ fontSize: "small", marginLeft: ".5em" }}>
              {t("components:Must be at least 8 characters long")}
            </span>
          </FlexRow>
        ) : null}
        {validateRegex ? (
          <FlexRow css={{ marginBottom: ".25em" }}>
            <FaRegCheckCircle
              style={{
                color: regexIsValid ? colors.highlight.GREEN : "#e0e0e0",
                fontSize: "large"
              }}
            />
            <span css={{ fontSize: "small", marginLeft: ".5em" }}>
              {t("components:Must have 1 lowercase letter, 1 digit")}
            </span>
          </FlexRow>
        ) : null}
        {validateMatch ? (
          <FlexRow css={{ marginBottom: "1em" }}>
            <FaRegCheckCircle
              style={{
                color: passwordsMatch ? colors.highlight.GREEN : "#e0e0e0",
                fontSize: "large"
              }}
            />
            <span css={{ fontSize: "small", marginLeft: ".5em" }}>
              {t("components:Passwords match")}
            </span>
          </FlexRow>
        ) : null}
      </div>
    );
  }
}

export default withTranslation(["components"])(IsPasswordValidChecks);
