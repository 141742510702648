import React from "react";
import _ from "lodash";
import { isChrome, isSafari } from "react-device-detect";

// Icons
import {
  MdLocalShipping,
  MdDirectionsCar,
  MdLocationOn,
  MdLightbulbOutline,
  MdContentPaste,
  MdExplore
} from "react-icons/md";

import { HandshakeIcon } from "./components/icons";

// Authorization & Authentication modules
import {
  Privileges,
  Features,
  OrganizationTypes
} from "./modules/auth/Authorization";
import AuthenticationUtils from "./modules/auth/authentication";

// Top-level view containers
import EndToEndContainer from "./modules/end-to-end/EndToEndContainer";
import ShipmentStatusViewContainer from "./modules/shipment-status/ShipmentStatusViewContainer";
import ShipmentDetailViewContainer from "./modules/shipment-detail/ShipmentDetailViewContainer";
import ShipmentSearchViewContainer from "./modules/shipment-search/ShipmentSearchViewContainer";
import LocationsViewContainer from "./modules/location-list/LocationsViewContainer";
import EditLadsContainer from "./modules/location-lads-list/LocationLadsListContainer";
import LocationResolutionList from "./modules/location-resolution-list/Container";
import LocationEditViewContainer from "./modules/location-edit/LocationEditViewContainer";
import LocationMatchingViewContainer from "./modules/location-resolution-edit/LocationMatchingViewContainer";
import OrganizationsViewContainer from "./modules/organizations/OrganizationsViewContainer";
import UsersViewContainer from "./modules/users/UsersViewContainer";
import ProfileContainer from "./modules/profile/ProfileContainer";
import CreateShipmentContainer from "./modules/create-shipment/CreateShipmentContainer";
import ReportDetailViewContainer from "./modules/report-detail/ReportDetailViewContainer";
import ReportsViewContainer from "./modules/reports/ReportsViewContainer";
import ReportBuilderViewContainer from "./modules/reports/ReportBuilderViewContainer";
import FinVehicleDashboardContainer from "./modules/fv-dashboard/FinVehicleDashboardContainer";
import VinSearchViewContainer from "./modules/fv-vin-search/VinSearchViewContainer";
import VinDetailsContainer from "./modules/fv-vin-details/VinDetailsContainer";
import DocumentationContainer from "./modules/documentation/DocumentationContainer";
import ApiGroupContainer from "./modules/documentation/ApiGroupContainer";
import ApiSampleContainer from "./modules/documentation/ApiSampleContainer";
import ApiLogsView from "./modules/documentation/api-logs/ApiLogsView";
import TryApiContainer from "./modules/documentation/TryApiContainer";
import MetalTrackingDashboardContainer from "./modules/mt-dashboard/MetalTrackingDashboardContainer";
import MetalTrackingSearchResultsViewContainer from "./modules/mt-search-results/MetalTrackingSearchResultsViewContainer";
import MetalTrackingLocationResultsViewContainer from "./modules/mt-location-results/MetalTrackingLocationResultsViewContainer";
import AdcDashboardContainer from "./modules/adc-dashboard/AdcDashboardContainer";
import AdcDetailsContainer from "./modules/adc-details/AdcDetailsContainer";
import AdcSearchResultsContainer from "./modules/adc-search-results/AdcSearchResultsContainer";
import CreateMilestoneContainer from "./modules/partner-create-milestone/CreateMilestoneContainer";

import { StatusCodes } from "./modules/errors/ErrorView";
import ErrorContainer from "./modules/errors/ErrorContainer";
import CallbackContainer from "./modules/callback/CallbackContainer";
import ConnectedCarViewContainer from "./modules/connected-car/ConnectedCarViewContainer";
import PartnerDashboardView from "./modules/partner-dashboard/PartnerDashboard";

// Route Groups
// Each visible group will appear as a collapsible section in the primary navigation
// Hidden groups and routes will not appear in the primary navigation
// Routes are hidden in the primary navigation from users without the specified privileges/features
// Users without the specified privileges/features will be redirected if they visit a route directly
export const routeGroupsMap = {
  /*
  GROUP_KEY: {
    title: "Title of this group (will be displayed in navbar)",
    icon: <AnyIconComponent />,

    // Optional - set to true if the group should not appear in navbar (default:
    false)
    hidden: false,

    routes: {
      CHILD_ROUTE_KEY: {
        path: "/path-to-show-this-view/:param",
        title:
          "Title of this view (will be displayed in navbar and in the page header)",

        // Optional - User must have at least 1 of the privileges specified
        privileges: [Privileges.FOO, Privileges.BAR],

        // Optional - User must have at least 1 of the features specified
        features: [Features.FOO, Features.BAR],

        // Top-level view to show when user visits the route (unauthorized users
        // will be redirected)
        view: <AnyViewContainer />,

        // Optional - set to true if the route link should not appear in navbar
        // (default: false)
        hidden: false,

        // Optional - set this if 1) your route is hidden, and 2) the route that
        // should be highlighted in the sidenav is _not_ the first route in your
        // group (which is the default)
        navProxyRoute: () => routesMap.ANY_ROUTE
      }
    }
  },
  */
  SHIPMENTS: {
    title: "Shipments",
    icon: <MdLocalShipping />,
    hidden: false,
    routes: {
      ROOT: {
        path: "/",
        title: "Shipment Exceptions",
        description:
          "All shipments for your organization and their related shipment exceptions",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentStatusViewContainer />
      },
      CREATE_SHIPMENT: {
        path: "/create-shipment",
        title: "Create Shipment",
        description:
          "A form that allows Carriers to manually create their own shipments",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <CreateShipmentContainer />
      },
      SHIPMENT_DETAIL: {
        path: "/shipments/:shipment_id",
        title: "Shipment Detail",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentDetailViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT
      },
      SHIPMENT_CHILD_DETAIL: {
        path: "/shipments/:shipment_id/:child_shipment_id",
        title: "Shipment Child Detail",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentDetailViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT
      },
      SHIPMENT_DETAIL_CUSTOMER: {
        path: "/carriers/:carrier_scac/shipments/:creator_shipment_id",
        title: "Shipment Detail",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentDetailViewContainer customerView={true} />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT
      },
      SHIPMENT_DETAIL_FINISHED_VEHICLE: {
        path: "/carriers/:carrier_scac/shipments/:creator_shipment_id/vin/:vin",
        title: "Shipment Details",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentDetailViewContainer finishedVehicleView={true} />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT
      },
      SHIPMENT_SEARCH: {
        path: "/search",
        title: "Search Results",
        privileges: [Privileges.VIEW_SHIPMENTS, Privileges.MANAGE_SHIPMENTS],
        view: <ShipmentSearchViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.ROOT
      }
    }
  },
  FINISHED_VEHICLE: {
    title: "Finished Vehicle",
    icon: <MdDirectionsCar />,
    routes: {
      FINISHED_VEHICLE: {
        path: "/finishedvehicle",
        title: "Finished Vehicle Dashboard",
        description:
          "All VINs for your organization and their related exceptions and holds",
        features: [Features.FINISHED_VEHICLE],
        view: <FinVehicleDashboardContainer />
      },
      CONNECTED_CAR: {
        path: "/connected-car",
        title: "Connected Car Dashboard",
        features: [Features.CONNECTED_CAR],
        view: <ConnectedCarViewContainer />
      },
      ADC_DASHBOARD: {
        path: "/adc",
        title: "Auto Distribution Center",
        description:
          "All Auto Distribution Center locations, their current capacity, and forecasted capacity",
        features: [Features.AUTO_DISTRIBUTION_CENTER],
        view: <AdcDashboardContainer />
      },
      VIN_SEARCH: {
        path: "/vinsearch",
        title: "Search Results",
        features: [Features.FINISHED_VEHICLE],
        view: <VinSearchViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.FINISHED_VEHICLE
      },
      VIN_DETAILS: {
        path: "/vindetails/:entity_id",
        title: "VIN Details",
        features: [Features.FINISHED_VEHICLE],
        view: <VinDetailsContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.FINISHED_VEHICLE
      },
      ADC_DETAILS: {
        path: "/adcdetails/:location_id",
        title: "ADC Details",
        features: [Features.FINISHED_VEHICLE],
        view: <AdcDetailsContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.ADC_DASHBOARD
      },
      ADC_SEARCH: {
        path: "/adcsearch",
        title: "ADC Search Results",
        features: [Features.FINISHED_VEHICLE],
        view: <AdcSearchResultsContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.ADC_DASHBOARD
      }
    }
  },
  ASSET_TRACKING: {
    title: "Asset Tracking",
    icon: <MdLocationOn />,
    routes: {
      METAL_TRACKING_DASHBOARD: {
        path: "/plant-asset",
        title: "Plant Asset Tracking",
        description:
          "A dashboard to assist with metal rack expiration and inventory management",
        features: [Features.PLANT_ASSET_TRACKING],
        view: <MetalTrackingDashboardContainer />
      },
      METAL_TRACKING_SEARCH_RESULTS: {
        path: "/plant-asset/search-results",
        title: "Search Results",
        features: [Features.PLANT_ASSET_TRACKING],
        view: <MetalTrackingSearchResultsViewContainer />,
        hidden: true
      },
      METAL_TRACKING_LOCATION_RESULTS: {
        path: "/plant-asset/location-results/:rackLocation",
        title: "Metal Rack Locations",
        features: [Features.PLANT_ASSET_TRACKING],
        view: <MetalTrackingLocationResultsViewContainer />,
        hidden: true
      }
    }
  },
  BUSINESS_INTELLIGENCE: {
    title: "Business Intelligence",
    icon: <MdLightbulbOutline />,
    routes: {
      REPORTS: {
        path: "/reports",
        title: "BI Dashboard",
        description:
          "Business intelligence reporting platform for organizational analytics",
        privileges: [Privileges.VIEW_REPORTS],
        view: <ReportsViewContainer />
      },
      REPORT_BUILDER: {
        path: "/report_builder",
        title: "Report Builder",
        description:
          "The Report Builder allows you to create your own report from a pre-defined data set. Share your reports with your organization using our shared reports feature.",
        privileges: [Privileges.BUILD_REPORT],
        view: <ReportBuilderViewContainer />
      },
      REPORT_DETAIL: {
        path: "/reports/:reportId",
        title: "Report",
        privileges: [Privileges.VIEW_REPORTS],
        view: <ReportDetailViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.REPORTS
      },
      END_TO_END: {
        path: "/locations",
        title: "Locations",
        description: "All the locations of an organization visualized",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS
        ],
        view: <EndToEndContainer />
      }
    }
  },
  PARTNERS: {
    title: "Partners",
    icon: <HandshakeIcon />,
    routes: {
      PARTNER_DASHBOARD: {
        path: "/partners",
        title: "Partner Dashboard",
        description: "All milestones for a partner organization",
        privileges: [Privileges.ANY],
        organizationTypes: [OrganizationTypes.PARTNER],
        view: <PartnerDashboardView />
      },
      PARTNER_CREATE_MILESTONE_EVENT: {
        path: "/partners/create-milestone-event",
        title: "Create Milestone Event",
        description:
          "A form that allows Partners to manually create a milestone for a VIN",
        privileges: [Privileges.ANY],
        organizationTypes: [OrganizationTypes.PARTNER],
        view: <CreateMilestoneContainer />
      }
    }
  },
  ADMINISTRATION: {
    title: "Administration",
    icon: <MdContentPaste />,
    routes: {
      LOCATION_MANAGEMENT: {
        path: "/manage/locations",
        title: "Location Management",
        description: "Create, Update, and Delete Locations for an organization",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS
        ],

        view: <LocationsViewContainer />
      },
      LOCATION_LADS: {
        path: "/manage/locations/lads",
        title: "LADs",
        description: "Create, Update, and Delete Locations for an organization",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS
        ],
        hidden: true,
        view: <EditLadsContainer />
      },
      LOCATION_UNRESOLVED: {
        path: "/manage/locations/unresolved",
        title: "Unresolved Locations",
        description: "Create, Update, and Delete Locations for an organization",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS
        ],
        hidden: true,
        view: <LocationResolutionList />
      },
      LOCATION_EDIT: {
        path: "/manage/locations/edit/:location_id",
        title: "Edit Location",
        privileges: [
          Privileges.VIEW_LOCATIONS,
          Privileges.MANAGE_SHIPPER_LOCATIONS
        ],
        view: <LocationEditViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.LOCATION_MANAGEMENT
      },
      LOCATION_MATCHING: {
        path: "/locationmatching/:location_id",
        title: "Resolve Location",
        privileges: [Privileges.MANAGE_SHIPPER_LOCATIONS],
        view: <LocationMatchingViewContainer />,
        hidden: true,
        navProxyRoute: () => routesMap.LOCATION_MANAGEMENT
      },
      USER_MANAGEMENT: {
        path: "/manage/users",
        title: "User Management",
        description:
          "Add, delete, or update permissions for users in your organization",
        privileges: [Privileges.MANAGE_USERS],
        view: <UsersViewContainer />
      },
      ORGANIZATION_MANAGEMENT: {
        path: "/manage/organizations",
        title: "Organization Management",
        description:
          "Add, delete, or update an organization within the platform",
        privileges: [Privileges.MANAGE_ORGANIZATIONS],
        view: <OrganizationsViewContainer />
      }
    }
  },
  DOCUMENTATION: {
    title: "Documentation",
    icon: <MdExplore />,
    hidden: true,
    routes: {
      API_LOGS: {
        path: "/documentation/api-logs",
        title: "Documentation & Utilities",
        privileges: [Privileges.ANY],
        view: <ApiLogsView />
      },
      DOCUMENTATION: {
        path: "/documentation/:filename",
        title: "Documentation & Utilities",
        privileges: [Privileges.ANY],
        view: <DocumentationContainer />
      },
      DOCUMENTATION_URL: {
        path: "/documentation/:baseUrl/:filename",
        title: "Documentation & Utilities",
        privileges: [Privileges.ANY],
        view: <DocumentationContainer />
      },
      API_GROUP: {
        path: "/documentation/api-group/:filename",
        title: "Documentation & Utilities",
        privileges: [Privileges.ANY],
        view: <ApiGroupContainer />
      },
      API_SAMPLE: {
        path: "/documentation/api-sample/:filename",
        title: "Documentation & Utilities",
        privileges: [Privileges.ANY],
        view: <ApiSampleContainer />
      },
      TRY_API: {
        path: "/documentation/utilities/try-api",
        title: "Documentation & Utilities",
        privileges: [Privileges.ANY],
        view: <TryApiContainer />
      }
    }
  },
  USER: {
    title: "User routes (hidden from nav)",
    hidden: true,
    routes: {
      PROFILE: {
        path: "/profile",
        title: "My Profile",
        privileges: [Privileges.ANY],
        view: <ProfileContainer />
      }
    }
  },
  UNAUTHENTICATED: {
    title: "Unauthenticated routes (hidden from nav)",
    hidden: true,
    routes: {
      NOT_FOUND: {
        path: "/notfound",
        title: "Not Found",
        view: <ErrorContainer statusCode={StatusCodes.PAGE_NOT_FOUND} />
      },
      CALLBACK: {
        path: "/callback",
        title: "Callback",
        view: <CallbackContainer />
      },
      SAML_CALLBACK: {
        path: "/saml-callback",
        title: "SAML Callback",
        view: <CallbackContainer />
      },
      VERIFY_USER_ERROR: {
        path: "/verifyUserError",
        title: "Verify User Error",
        view: <ErrorContainer statusCode={StatusCodes.UNAUTHORIZED} />
      },
      ACCESS_FORBIDDEN_ERROR: {
        path: "/accessForbiddenError",
        title: "Access Forbidden Error",
        view: <ErrorContainer statusCode={StatusCodes.ACCESS_FORBIDDEN} />
      },
      UNSUPPORTED_BROWSER: {
        path: "/unsupportedbrowser",
        title: "Unsupported Browser",
        view: <ErrorContainer statusCode={StatusCodes.NOT_SUPPORTED} />
      }
    }
  }
};

// Build an object containing all routes within all groups
const buildRoutesMap = () => {
  let routes = {};
  _.values(routeGroupsMap).forEach(navGroup => {
    routes = Object.assign({}, routes, navGroup.routes);
    // H1-1882: we want to highlight something in the sidenav even when the
    // route we're showing isn't in the nav menu. Normally the hidden route
    // would be a child that you only reach through a parent, in which case we'd
    // generally want to show the parent. For most routes that's the first route
    // listed in their group. Some groups have more than one logical parent --
    // for these cases, individual routes can override the default with a custom
    // navProxyRoute in their route definition.
    const groupRoot = _.values(navGroup.routes)[0];
    _.forEach(navGroup.routes, r => {
      if (!r.navProxyRoute) {
        r.navProxyRoute = () => groupRoot;
      }
    });
  });
  return routes;
};
export const routesMap = buildRoutesMap();

export function routeForLocation(location, defaultRoute = routesMap.NOT_FOUND) {
  if (
    location.pathname !== "/callback" &&
    location.pathname !== "/saml_callback"
  ) {
    AuthenticationUtils.setLastRequestedUrl(location.pathname);
  }
  return _.get(routesMap, location.type, defaultRoute);
}

export function navigationForLocation(location) {
  return location.pathname !== "/registration";
}

export const isShipper = organization =>
  organization
    ? organization.org_type === OrganizationTypes.SHIPPER ||
      organization.org_type === OrganizationTypes.FREIGHTVERIFY
    : false;

export const isCarrier = organization =>
  organization
    ? organization.org_type === OrganizationTypes.CARRIER ||
      organization.org_type === OrganizationTypes.FREIGHTVERIFY
    : false;

export const isPartner = organization =>
  organization.org_type === OrganizationTypes.PARTNER;

export function verifyRouteForShipper(location, organization) {
  const forbiddenPaths = ["/locations"];

  if (
    !_.isEmpty(organization) &&
    !isShipper(organization) &&
    forbiddenPaths.some(p => location.pathname === p)
  ) {
    location.pathname = "/accessForbiddenError";
    location.type = "ACCESS_FORBIDDEN_ERROR";
  }

  return location;
}

export function verifyRouteForCarrier(location, organization) {
  const forbiddenPaths = ["/create-shipment"];

  if (
    !_.isEmpty(organization) &&
    !isCarrier(organization) &&
    forbiddenPaths.some(p => location.pathname === p)
  ) {
    location.pathname = "/accessForbiddenError";
    location.type = "ACCESS_FORBIDDEN_ERROR";
  }

  return location;
}

// DEV-127 Check browser for Chrome or Safari
//  if unsupported browser, redirect to unsupported view
export function verifyBrowser(location) {
  if (!(isChrome || isSafari)) {
    location.pathname = "/unsupportedbrowser";
    location.type = "UNSUPPORTED_BROWSER";
  }

  return location;
}
