import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";

class DeletePolygonModal extends Component {
  render() {
    const { t, show, hide, handleConfirm } = this.props;

    return (
      <Modal show={show} onHide={() => hide()}>
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,

            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title
            style={{
              fontWeight: "300"
            }}
          >
            {t("location-edit:Delete polygon")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body
          style={{
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          {t(
            "location-edit:Warning, this will delete the polygon from this location's geofence - Would you like to proceed?"
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#e2e2e2"
          }}
        >
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em"
            }}
            onClick={() => hide()}
          >
            {t("location-edit:Cancel")}
          </Button>
          <Button
            style={{
              backgroundColor: Colors.highlight.RED,
              color: "white"
            }}
            onClick={handleConfirm}
          >
            {t("location-edit:Delete the polygon")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default withTranslation(["location-edit"])(DeletePolygonModal);
