/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import SectionHeader from "./SectionHeader";
import CodeSample from "./CodeSample";

const requestsCss = {
  padding: "1em 0"
};

const ApiResponse = ({ tryResponse, t }) => {
  return (
    <div id="requests" css={requestsCss}>
      <SectionHeader title={t("API Response")} />
      <CodeSample description="" code={tryResponse ? tryResponse.data : {}} />
    </div>
  );
};

ApiResponse.propTypes = {
  tryResponse: PropTypes.object
};
export default withTranslation(["documentation"])(ApiResponse);
