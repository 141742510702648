import _ from "lodash";

/**
 * Centralized place for all configurable items based on environment.
 */
const ApplicationConfig = {
  //
  // Generic Settings
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,

  HOSTNAMES: {
    local: ["localhost"],
    development: [
      "portal-d.freightverify.com",
      "localhost",
      "beta.freightverify.com",
      "fv-react-auth0.freightverify.com",
      "finished-vehicle.freightverify.com",
      "fv-dev-mobile.freightverify.com",
      "beta-d.freightverify.com"
    ],
    qa: ["portal-q.freightverify.com"],
    test: ["fv-react-dev.freightverify.com", "portal-t.freightverify.com"],
    production: ["portal.freightverify.com"]
  },

  //
  // API Settings
  API: {
    local: {
      apiUrl: "https://data-d.freightverify.com",
      customerApiUrl: "https://dev.api.freightverify.com/ws/rest/v2/tl"
    },
    development: {
      apiUrl: "https://data-d.freightverify.com",
      customerApiUrl: "https://dev.api.freightverify.com/ws/rest/v2/tl"
    },
    qa: {
      apiUrl: "https://data-q.freightverify.com",
      customerApiUrl: "https://qa.api.freightverify.com/ws/rest/v2/tl"
    },
    test: {
      apiUrl: "https://data-t.freightverify.com",
      customerApiUrl: "https://test.api.freightverify.com/ws/rest/v2/tl"
    },
    production: {
      apiUrl: "https://data-b.freightverify.com",
      customerApiUrl: "https://api-b.freightverify.com/ws/rest/v2/tl"
    }
  },

  //
  // Features
  REQUIRE_LOGIN: true,

  //
  // Auth Settings
  AUTH: {
    local: {
      clientId: "l1fv302czTdpNdv3abSPdNJHUIJ7QaLD",
      loginUrl: "https://login-s.freightverify.com"
    },
    development: {
      clientId: "l1fv302czTdpNdv3abSPdNJHUIJ7QaLD",
      loginUrl: "https://login-s.freightverify.com"
    },
    qa: {
      clientId: "l1fv302czTdpNdv3abSPdNJHUIJ7QaLD",
      loginUrl: "https://login-s.freightverify.com"
    },
    test: {
      clientId: "5fNJ1aQe6mkvdVqaWwjMIqQRM8q2XUrv",
      loginUrl: "https://login-t.freightverify.com"
    },
    production: {
      clientId: "25bPZYkU3nnJhXNmQQxq5O9H8eNfkbFa",
      loginUrl: "https://login.freightverify.com"
    }
  },

  //
  // Tracking
  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  GOOGLE_ANALYTICS_UA: process.env.REACT_APP_GA_ID,

  //
  // Developer Utils
  LOG_REDUX_ACTIONS: process.env.REACT_APP_IS_LOGGING_ACTIONS,
  DEVELOPER_ENVIRONMENT_OVERRIDE:
    process.env.REACT_APP_DEVELOPER_ENVIRONMENT_OVERRIDE
};

/**
 * Get the current environment
 * @return {string|null}
 */
const findEnvironment = (allowDeveloperOverride = false) => {
  //
  // Used for overriding api and auth for local dev.
  if (
    allowDeveloperOverride &&
    ApplicationConfig.DEVELOPER_ENVIRONMENT_OVERRIDE
  ) {
    return ApplicationConfig.DEVELOPER_ENVIRONMENT_OVERRIDE;
  }

  if (ApplicationConfig.ENVIRONMENT) {
    return ApplicationConfig.ENVIRONMENT;
  }

  //
  // TODO: Once all envs and devs have REACT_APP_ENVIRONMENT set
  // Delete all of this and depend on that value alone.
  // this is legacy in order to ensure things keep working without
  // any changes.
  const hostname = window.location.hostname;

  if (_.includes(ApplicationConfig.HOSTNAMES.local, hostname)) {
    return "local";
  }

  if (_.includes(ApplicationConfig.HOSTNAMES.development, hostname)) {
    return "development";
  }

  if (_.includes(ApplicationConfig.HOSTNAMES.qa, hostname)) {
    return "qa";
  }

  if (_.includes(ApplicationConfig.HOSTNAMES.test, hostname)) {
    return "test";
  }

  if (_.includes(ApplicationConfig.HOSTNAMES.production, hostname)) {
    return "production";
  }

  return null;
};

export const getCurrentEnvironment = _.memoize(findEnvironment);

/**
 *
 */
export function isEnvironment(environmentName) {
  return getCurrentEnvironment() === environmentName;
}

export default ApplicationConfig;
