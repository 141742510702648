/**@jsx jsx */
import { jsx } from "@emotion/core";
import { connect } from "react-redux";
import { TabPanel } from "react-tabs";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import Colors from "../../../styles/colors";
import TabsContainerPanel, {
  tabPanelStyle
} from "../../../components/tabs-container-panel";
import TabTable from "../../../components/tab-table";
import TabTableExpandable from "../../../components/tab-table-expandable";
import { TextCell, TimeCell } from "./vin-details-columns";
import CoordinatesTable from "../../../components/CoordinatesTable";
import UpdatesTable from "./UpdatesTable";
import { TabPanelPlaceholder } from "../../../components/no-data-placeholders";
import {
  localizedTimeFormatter,
  localizedDateFormatter
} from "../../../utils/date-time";
import { generateHeaderCell } from "../../../components/tab-table";
import { getProgressArray } from "../vin-details-utils";
import FinVehicleEntityDetailsState from "../FinVehicleEntityDetailsState";
import {
  CONNECTED_CAR_MAP_ICON_PATH,
  CONNECTED_CAR_MAP_ICON_HEIGHT,
  CONNECTED_CAR_MAP_ICON_WIDTH,
  CONNECTED_CAR_MAP_ICON_DEFAULT_HEIGHT,
  CONNECTED_CAR_MAP_ICON_DEFAULT_WIDTH
} from "../../map/components/RoutingMap";
import ExpandedDetailsTable from "./ExpandedDetailsTable";
import UpdateSubComponent from "./UpdateSubComponent";
import CommentsSubComponent from "./CommentsSubComponent";

const getTimeString = dateTimeStr => {
  return dateTimeStr && localizedTimeFormatter(dateTimeStr);
};
const getDateString = dateTimeStr => {
  return dateTimeStr && localizedDateFormatter(dateTimeStr);
};

/**
 * H2-1060: Special comment handling for deliveries
 */

// Use the trip leg data pointed to by the update to return our carrier. Default
// to "" which will still work ok in the resulting user message.
const getCarrierName = (update, tripLegs) => {
  const legIndex = update.tripLegIndex - 1; // convert to 0-index
  return _.get(tripLegs, `[${legIndex}].legData.carrierInfo.carrierName`, "");
};

// make a copy of the plannedStops structure, modify the 'trip completed'
// updates (but only for some shippers), and return the copy
const updatedEventTextForShipper = (plannedStops, tripLegs) => {
  if (!plannedStops || !plannedStops.length) {
    return [];
  }

  return plannedStops.map(plannedStop => {
    return {
      ...plannedStop,
      updates: plannedStop.updates.map(update => {
        if (update.code !== "TripPlanCompleted") {
          return update;
        }
        const carrierName = getCarrierName(update, tripLegs);
        return {
          ...update,
          comments: `Carrier ${carrierName} Reports Vehicle Arrival at Ultimate Destination`
        };
      })
    };
  });
};
/* end H2-1060 special delivery comments handling */

const VinDetailsTabsPanel = props => {
  const { t } = useTranslation("fv-vin-details");
  const {
    tabs,
    tripLegs,
    eventUpdates,
    coords,
    connectedCarCoords,
    selectCoordinate,
    clearCoordinate,
    selectedCoordinate,
    plannedStops,
    showConnectedCarPositions,
    hideConnectedCarPositions
  } = props;
  const customNumStyles = {
    combinedExceptions:
      tabs.data.combinedExceptions.length === 0
        ? null
        : { backgroundColor: Colors.holds.DARK_RED }
  };

  // H2-1060: change comment text for "trip completed" update
  const plannedStopsForShipper = updatedEventTextForShipper(
    plannedStops,
    tripLegs
  );

  const holdsColumns = (sortKey, sortDir) => {
    return [
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Time"),
          sortKey === "activatedEventDate",
          sortDir
        ),
        width: 150,
        accessor: "activatedEventDate",
        Cell: cellInfo => (
          <TimeCell
            time={getTimeString(cellInfo.original.activatedEventDate)}
            date={getDateString(cellInfo.original.activatedEventDate)}
          />
        )
      },
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Active Exception"),
          sortKey === "typeName",
          sortDir
        ),
        accessor: "typeName",
        Cell: cellInfo => {
          // H2-708: content of exceptions should be translated before it is
          // presented
          return (
            <TextCell>{t(`exceptions:${cellInfo.original.typeName}`)}</TextCell>
          );
        }
      },
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Exception Comments"),
          sortKey === "activatedComments",
          sortDir
        ),
        accessor: "activatedComments",
        Cell: cellInfo => (
          <TextCell>{cellInfo.original.activatedComments}</TextCell>
        )
      }
    ];
  };

  const equipmentColumns = (sortKey, sortDir) => {
    return [
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Name"),
          sortKey === "qualifier",
          sortDir
        ),
        accessor: "qualifier",
        Cell: cellInfo => {
          return <TextCell> {cellInfo.original.qualifier}</TextCell>;
        }
      },
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Value"),
          sortKey === "value",
          sortDir
        ),
        accessor: "value",
        Cell: cellInfo => {
          return <TextCell> {cellInfo.original.value}</TextCell>;
        }
      }
    ];
  };

  const orderDetailsColumns = (sortKey, sortDir) => {
    return [
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Name"),
          sortKey === "label",
          sortDir
        ),
        Cell: cellInfo => {
          return <TextCell> {cellInfo.original.qualifier}</TextCell>;
        }
      },
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Value"),
          sortKey === "value",
          sortDir
        ),
        Cell: cellInfo => {
          return <TextCell> {cellInfo.original.value}</TextCell>;
        }
      }
    ];
  };

  const updateColumns = (sortKey, sortDir) => {
    return [
      {
        // Need an "expander" field that we hide,
        // so we can create our own custom expander in a different column
        show: false,
        expander: true
      },
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Event Time"),
          sortKey === "eventTs",
          sortDir
        ),
        accessor: "eventTs",
        width: 150,
        Cell: row => (
          <TimeCell
            time={localizedTimeFormatter(row.value)}
            date={localizedDateFormatter(row.value)}
          />
        )
      },
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Received Time"),
          sortKey === "ts",
          sortDir
        ),
        accessor: "ts",
        width: 150,
        Cell: row => (
          <TimeCell
            time={localizedTimeFormatter(row.value)}
            date={localizedDateFormatter(row.value)}
          />
        )
      },
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Update"),
          sortKey === "update",
          sortDir
        ),
        accessor: "update",
        Cell: props => {
          if (props.original.code === "StatusUpdated") {
            return (
              <UpdateSubComponent
                original={props.original}
              ></UpdateSubComponent>
            );
          } else {
            return <TextCell>{props.original.update}</TextCell>;
          }
        }
      },
      {
        Header: generateHeaderCell(
          t("fv-vin-details:Comments"),
          sortKey === "comments",
          sortDir
        ),
        accessor: "comments",
        Cell: props => {
          if (props.original.code === "StatusUpdated") {
            return (
              <CommentsSubComponent
                t={t}
                original={props.original}
                isExpanded={props.isExpanded}
                columnProps={props.columnProps}
                nestingPath={props.nestingPath}
              ></CommentsSubComponent>
            );
          } else {
            return <TextCell>{props.original.comments}</TextCell>;
          }
        }
      }
    ];
  };

  const tripProgress = plannedStops ? getProgressArray(plannedStops) : [];
  const onTabsSelect = (index, lastIndex, event) => {
    // Connected Car
    if (event.target.textContent === t("fv-vin-details:Connected Car")) {
      showConnectedCarPositions();
    } else {
      hideConnectedCarPositions();
    }

    // Coordinates
    // if we leave from the coordinates tab
    if (event.target.textContent !== t("fv-vin-details:Coordinates")) {
      clearCoordinate();
    }
  };
  return (
    <TabsContainerPanel
      tabsData={tabs.data}
      tabsArray={tabs.array}
      eventUpdates={eventUpdates}
      numStyles={customNumStyles}
      onSelect={onTabsSelect}
      style={{
        ".react-tabs__tab-list": {
          overflowX: "auto",
          overflowY: "hidden"
        },
        ".details-tab": {
          boxShadow: "none"
        }
      }}
    >
      <TabPanel style={tabPanelStyle}>
        {plannedStopsForShipper ? (
          <div style={{ padding: "25px 20px 10px 15px" }}>
            {plannedStopsForShipper.map((d, i) => {
              return (
                <UpdatesTable
                  vinInfo={d}
                  key={`planned-stop-${_.kebabCase(d.name)}-${i}`}
                  start={i === 0}
                  end={i === plannedStopsForShipper.length - 1}
                  tripProgress={tripProgress[i]}
                />
              );
            })}
          </div>
        ) : (
          <TabPanelPlaceholder
            text={t("fv-vin-details:No Trip Plan Available")}
          />
        )}
        {tripLegs.length === 0 && eventUpdates.length > 0 ? (
          <TabTableExpandable
            data={eventUpdates}
            columns={updateColumns}
            subComponent={({ original }) => {
              return (
                <ExpandedDetailsTable
                  statusUpdate={original.statusUpdate}
                  comments={original.comments}
                  t={t}
                />
              );
            }}
          />
        ) : null}
      </TabPanel>
      <TabPanel
        style={{
          ...tabPanelStyle
          // padding: 10,
        }}
      >
        {tabs.data.combinedExceptions.length !== 0 ? (
          <div css={{ margin: 10 }}>
            <TabTable
              data={tabs.data.combinedExceptions}
              columns={holdsColumns}
              data-qa="active-exceptions"
            />
          </div>
        ) : (
          <TabPanelPlaceholder
            text={t("fv-vin-details:No Active Exceptions")}
          />
        )}
      </TabPanel>
      <TabPanel style={tabPanelStyle}>
        {coords.length > 0 ? (
          <CoordinatesTable
            selectCoordinate={(lat, long, time, ind, row) => {
              selectCoordinate(lat, long, time, ind, {
                City: row.city,
                State: row.state,
                Country: row.country
              });
            }}
            clearCoordinate={clearCoordinate}
            selectedCoordinate={selectedCoordinate}
            coords={coords}
          />
        ) : (
          <TabPanelPlaceholder
            text={t("fv-vin-details:No Coordinates Available")}
          />
        )}
      </TabPanel>
      {tabs.data.orderDetails.length > 0 && (
        <TabPanel style={tabPanelStyle}>
          <TabTable
            data={tabs.data.orderDetails}
            columns={orderDetailsColumns}
            defaultSorted={{ id: "label", desc: false }}
            data-qa="order-details"
          />
        </TabPanel>
      )}
      <TabPanel style={tabPanelStyle}>
        {tabs.data.vinEquipment.length > 0 ? (
          <TabTable
            data={tabs.data.vinEquipment}
            columns={equipmentColumns}
            defaultSorted={{ id: "qualifier", desc: false }}
            data-qa="vin-details"
          />
        ) : (
          <TabPanelPlaceholder
            text={t("fv-vin-details:No Details Available")}
          />
        )}
      </TabPanel>
      {connectedCarCoords.length > 0 ? (
        <TabPanel style={tabPanelStyle}>
          <CoordinatesTable
            selectedCoordinate={selectedCoordinate}
            selectCoordinate={(lat, long, time, ind, row) => {
              selectCoordinate(lat, long, time, ind, {
                icon: CONNECTED_CAR_MAP_ICON_PATH,
                iconHeight: CONNECTED_CAR_MAP_ICON_HEIGHT,
                iconWidth: CONNECTED_CAR_MAP_ICON_WIDTH,
                iconDefaultHeight: CONNECTED_CAR_MAP_ICON_DEFAULT_HEIGHT,
                iconDefaultWidth: CONNECTED_CAR_MAP_ICON_DEFAULT_WIDTH,
                City: row.city,
                State: row.state,
                Country: row.country
              });
            }}
            clearCoordinate={clearCoordinate}
            coords={connectedCarCoords}
            data-qa="connected-car"
          />
        </TabPanel>
      ) : null}
    </TabsContainerPanel>
  );
};
VinDetailsTabsPanel.defaultProps = {
  tripLegs: []
};
const mapStateToProps = state => ({
  connectedCarCoords: FinVehicleEntityDetailsState.selectors.getEntityConnectedCarPositionUpdates(
    state
  )
});

const mapDispatchToProps = dispatch => ({
  showConnectedCarPositions: () =>
    dispatch(
      FinVehicleEntityDetailsState.actionCreators.showConnectedCarPositions()
    ),
  hideConnectedCarPositions: () =>
    dispatch(
      FinVehicleEntityDetailsState.actionCreators.hideConnectedCarPositions()
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VinDetailsTabsPanel);
