import React from "react";
import PropTypes from "prop-types";

import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DataWidgetTooltip = ({ tooltip, icon, style }) => {
  return (
    <OverlayTrigger
      key={"Test"}
      placement={"bottom"}
      overlay={<Tooltip style={{ fontSize: "14px" }}>{tooltip}</Tooltip>}
    >
      <FontAwesomeIcon
        style={{
          height: "1em",
          width: "1em",
          cursor: "help",
          ...style
        }}
        icon={icon}
      />
    </OverlayTrigger>
  );
};

DataWidgetTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  style: PropTypes.object
};
