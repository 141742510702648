// eslint-disable-next-line
import PropTypes from "prop-types";
import React from "react";

const CheckBoxCell = ({ checked = false, onChange }) => {
  return (
    <div className="h-100 d-flex justify-content-around align-items-center">
      <input type="checkbox" checked={checked} onChange={onChange} />
    </div>
  );
};

CheckBoxCell.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default CheckBoxCell;
