/*
Blue Newt Software, Inc. Confidential Unpublished Copyright
(c) 2008-2018 Blue Newt Software, Inc., All Rights Reserved.

NOTICE: All information contained herein is, and remains the property of Blue
Newt Software, Inc. The intellectual and technical concepts contained herein
are proprietary to Blue Newt Software, Inc. and may be covered by U.S. and
Foreign Patents, patents in process, and are protected by trade secret or
copyright law. Dissemination of this information or reproduction of this
material is strictly forbidden unless prior written permission is obtained
from Blue Newt Software, Inc. Access to the source code contained herein is
hereby forbidden to anyone except current Blue Newt Software, Inc. employees,
managers or contractors who have executed  Confidentiality and Non-disclosure
agreements explicitly covering such access.

The copyright notice above does not evidence any actual or intended
publication or disclosure of this source code, which includes  information
that is confidential and/or proprietary, and is a trade secret, of Blue Newt
Software, Inc. ANY REPRODUCTION, MODIFICATION, DISTRIBUTION, PUBLIC
PERFORMANCE, OR PUBLIC DISPLAY OF OR THROUGH USE OF THIS SOURCE CODE WITHOUT
THE EXPRESS WRITTEN CONSENT OF Blue Newt Software, Inc. IS STRICTLY
PROHIBITED, AND IN VIOLATION OF APPLICABLE LAWS AND INTERNATIONAL TREATIES.
THE RECEIPT OR POSSESSION OF THIS SOURCE CODE AND/OR RELATED INFORMATION DOES
NOT CONVEY OR IMPLY ANY RIGHTS  TO REPRODUCE, DISCLOSE OR DISTRIBUTE ITS
CONTENTS, OR TO MANUFACTURE, USE, OR SELL ANYTHING THAT IT MAY DESCRIBE, IN
WHOLE OR IN PART.
*/

import _ from "lodash";
import axios from "axios";

/**
 * Just return the data inside an object with a property named with the value
 * inside `property`. If property is null, ignore this and return data exactly
 * as it was received.
 */
const asProperty = (data, property = null) => {
  if (property === null) return data;
  const obj = {};
  obj[property] = data;
  return obj;
};

/**
 * Return data from the property if property is not null
 */
const fromProperty = (data, property = null) => {
  if (property === null) return data;
  return data[property];
};

/**
 * Build a fetcher function that simplifies fetch process, adding the dispatchs
 * when it starts, is loading and when it finishes.
 *
 * - topic: the topic you are written into the state;
 * - property: allows you to use several ducks in the same topic, adding a
 *   property where the duch will store in, instead of storing directly into
 *   the topic data itself.
 */
const buildFetchDuck = (topic, property = null) => {
  const cancelTokenSources = {};

  function actionNamer(phase, addError: false) {
    // eg, ('schedule', 'request') => 'Schedule/REQUEST_ROUTE_SCHEDULE'
    const errAddendum = addError ? "_ERROR" : "";
    const propertyAddendum = property ? `_${property.toUpperCase()}` : "";

    return `${_.capitalize(
      topic
    )}/${phase.toUpperCase()}_${topic.toUpperCase()}${propertyAddendum}${errAddendum}`;
  }
  const actions = {
    REQUEST: actionNamer("request"),
    RECEIVE: actionNamer("receive"),
    REQUEST_ERROR: actionNamer("request", { addError: true }),
    CLEAR: actionNamer("clear")
  };
  const initialState = asProperty(
    {
      data: [],
      url: "",
      isLoading: false,
      isLoadingError: false,
      loadingError: null
    },
    property
  );

  /**
   * Same as fetchWithoutCancelingSimilar but cancel other similar requests if
   * they are using the same URI.
   *
   * @param {String} url Url where you want to get data from.
   * @param {Object} config Allows you to pass parameters (e.g. headers) to
   *    the request.
   * @param {Function} transform Function that will be executed and transform
   * the data as soon as data is returned from fetch execution. It avoids
   * needing a reducer for simple transformations of the response.
   *
   * It is useful for frequent requests when you change just a part of it, for
   * example, requests of input texts changing its value.
   */
  function fetch(url, config = {}, transform = data => data) {
    const urlObj = new URL(url);
    const urlKey = urlObj.origin + urlObj.pathname;
    const cancelTokenSource = cancelTokenSources[urlKey];

    if (cancelTokenSource) {
      cancelTokenSource.cancel("Cancelled manually");
    }
    cancelTokenSources[urlKey] = axios.CancelToken.source();
    config.cancelToken = cancelTokenSources[urlKey].token;

    return dispatch => {
      dispatch({ type: actions.REQUEST, url });
      return axios
        .get(url, config)
        .then(resp => {
          dispatch({ type: actions.RECEIVE, payload: transform(resp.data) });
        })
        .catch(error => {
          // When it is cancelled intentionally, we do not need to dispatch an
          // error
          if (error.message !== "Cancelled manually") {
            dispatch({ type: actions.REQUEST_ERROR, error });
          }
        });
    };
  }

  /**
   * Allows you to fetch data from a given url. This method cancels any other
   * currently running fetch with the same url/params
   *
   * @param {String} url Url where you want to get data from.
   * @param {Object} config Allows you to pass parameters (e.g. headers) to
   *    the request.
   * @param {Function} transform Function that will be executed and transform
   * the data as soon as data is returned from fetch execution. It avoids
   * needing a reducer for simple transformations of the response.
   */
  function fetchWithoutCancelingSimilar(
    url,
    config = null,
    transform = data => data
  ) {
    return dispatch => {
      dispatch({ type: actions.REQUEST, url });
      return axios
        .get(url, config)
        .then(resp => {
          dispatch({ type: actions.RECEIVE, payload: transform(resp.data) });
        })
        .catch(error => dispatch({ type: actions.REQUEST_ERROR, error }));
    };
  }

  function fetchSummary(url) {
    return dispatch => {
      dispatch({ type: actions.REQUEST, url });
      return axios
        .get(url, {
          headers: { Accept: "application/json;version=summary" }
        })
        .then(resp => dispatch({ type: actions.RECEIVE, payload: resp.data }))
        .catch(error => dispatch({ type: actions.REQUEST_ERROR, error }));
    };
  }

  function fetchEntity(url) {
    return dispatch => {
      dispatch({ type: actions.REQUEST, url });
      return axios
        .get(url, {
          headers: { Accept: "application/json;version=entity" }
        })
        .then(resp => dispatch({ type: actions.RECEIVE, payload: resp.data }))
        .catch(error => dispatch({ type: actions.REQUEST_ERROR, error }));
    };
  }

  function clear() {
    return dispatch => {
      dispatch({ type: actions.CLEAR });
    };
  }

  return {
    actions,
    fetch,
    fetchSummary,
    fetchEntity,
    fetchWithoutCancelingSimilar,
    clear,
    initialState,

    selectors: {
      getData: state => {
        return fromProperty(state[topic], property);
      }
    },
    reducer: (state = initialState, action = {}) => {
      switch (action.type) {
        case actions.REQUEST:
          return Object.assign(
            {},
            state,
            asProperty(
              {
                isLoading: true,
                url: action.url
              },
              property
            )
          );

        case actions.RECEIVE:
          return Object.assign(
            {},
            state,
            asProperty(
              {
                isLoading: false,
                isLoadingError: false,
                loadingError: null,
                data: action.payload
              },
              property
            )
          );

        case actions.REQUEST_ERROR:
          return Object.assign(
            {},
            state,
            asProperty(
              {
                isLoading: false,
                isLoadingError: true,
                loadingError: action.error
              },
              property
            )
          );

        case actions.CLEAR:
          return Object.assign(
            {},
            state,
            asProperty(
              {
                data: initialState.data
              },
              property
            )
          );

        default:
          return state;
      }
    }
  };
};

export default buildFetchDuck;
