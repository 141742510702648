/** @jsx jsx */
// eslint-disable-next-line
import React, { Component } from "react";
import { jsx } from "@emotion/core";
import sizeMe from "react-sizeme";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";

import {
  LadChicletCSS as Chiclet,
  MadChicletCSS as MadChiclet
} from "../../../components/chiclets";
import Colors from "../../../styles/colors";
import { getKeyUpdateForShipment } from "../../shipment-detail/multimodal/EndToEndTabsPanel";
import { getExceptionFillColor } from "../../../components/multimodal-components/utils";

function getChartDimensions(margin, chicletSize, height, width) {
  return {
    xRange: width - (margin * 2 + chicletSize),
    x0: margin,
    yMid: height / 2
  };
}

export const processTripProgress = (shipmentDetail, shipmentSummary, lads) => {
  let progress = shipmentDetail.current_location
    ? shipmentDetail.current_location.distance_progress.replace("%", "")
    : 0;
  progress =
    progress === "None" || progress === "0" ? 0 : parseInt(progress, 10);
  return shipmentSummary.shipment_stops.map((s, i) => {
    let newLoc = {
      ...s.location,
      lad: lads ? lads[s.location.lad.code] : s.location.lad
    };
    return {
      ...s,
      shipmentId: shipmentDetail.id,
      type: s.location.lad.code,
      progress: i === 0 ? null : progress,
      updates: [],
      locationId: s.location.id,
      name: s.location.name,
      city: s.location.city,
      state: s.location.state,
      country: s.location.country,
      location: newLoc
    };
  });
};

export const flattenShipmentStops = (t, parent, childs, lads) => {
  let flattenedArr = [];
  parent.child_shipments.forEach(c => {
    let shipment = childs[c.shipment_id];
    if (shipment) {
      processTripProgress(shipment, c, lads).forEach((s, i) => {
        let activeStatus =
          c.shipment_id === parent.active_shipment ? true : false;
        if (i === 0) {
          flattenedArr.push({
            ...s,
            active: false
          });
        } else {
          flattenedArr.push({
            ...s,
            active: activeStatus,
            updates: getKeyUpdateForShipment(t, shipment)
          });
        }
      });
    }
  });

  let dedupedArr = [];
  flattenedArr.forEach((s, i) => {
    if (i === 0) {
      dedupedArr.push(s);
    } else if (dedupedArr.slice(-1)[0].locationId === s.locationId) {
      let lastStop = dedupedArr.slice(-1)[0];
      let updates = [...lastStop.updates, ...s.updates];
      // H1-825 Sort updates by ascending time
      lastStop.updates = updates.sort((a, b) => (a.time > b.time ? 1 : -1));
    } else {
      dedupedArr.push(s);
    }
  });

  return dedupedArr.map(d => ({
    ...d,
    arrived_at: d.arrived,
    departed_at: d.departed
  }));
};

export const getLads = list => {
  const codes = {};
  list.forEach(lad => {
    codes[lad.code] = {
      lad_name: lad.lad_name,
      default_name: lad.default_name,
      code: lad.code,
      description: lad.description,
      id: lad.id
    };
  });
  return codes;
};

const TripStops = ({
  data,
  margin = 16,
  chicletSize = 30,
  height,
  width,
  lads
}) => {
  if (!data) {
    return null;
  }

  let stops = data.length - 1;
  let chartDimensions = getChartDimensions(margin, chicletSize, height, width);

  return data.map((s, i) => {
    let x = chartDimensions.x0 + (i / stops) * chartDimensions.xRange;
    let y = chartDimensions.yMid - chicletSize / 2;

    let lad =
      lads && s.type && lads[s.type.toLowerCase()] !== undefined
        ? lads[s.type.toLowerCase()]
        : { code: s.type || "u", id: 0, description: "" };

    return (
      <div
        key={i}
        style={{
          position: "absolute",
          transform: `translate(${x}px, ${y}px)`,
          zIndex: 50 + i
        }}
      >
        <Chiclet lad={lad} height={chicletSize} width={chicletSize} />
      </div>
    );
  });
};

const TripProgress = ({
  data,
  margin = 16,
  chicletSize = 30,
  height,
  width,
  showAllMads,
  shipment
}) => {
  if (!data) {
    return null;
  }

  let chartDimensions = getChartDimensions(margin, chicletSize, height, width);
  let stops = data.length - 1;
  let lineElem = [];
  let modeElem = [];
  const progressBarColor = getExceptionFillColor(shipment.active_exception_ng);
  data.forEach((s, i) => {
    if (i > 0) {
      let x1 = chartDimensions.x0 + ((i - 1) / stops) * chartDimensions.xRange;
      let x2 = chartDimensions.x0 + (i / stops) * chartDimensions.xRange;
      let col = Colors.text.LIGHT_GRAY;
      let prog = parseInt(s.progress, 10);
      if (prog === 100) {
        col = Colors.highlight.GREEN;
      }
      if (prog > 0 && prog < 100) {
        col = Colors.text.LIGHT_GRAY;
      }

      if (s.actual_delivery) {
        col = Colors.highlight.GREEN;
      }

      lineElem.push(
        <line
          key={i}
          x1={x1}
          y1={chartDimensions.yMid}
          x2={x2}
          y2={chartDimensions.yMid}
          style={{ stroke: col, strokeWidth: 2, zIndex: 100 + i }}
        />
      );

      const shipmentMode = s.shipmentMode ? s.shipmentMode : "Truck";

      if (showAllMads || s.active) {
        let madSize = chicletSize * 0.75;

        let xP =
          x1 + chicletSize + ((x2 - madSize - (x1 + chicletSize)) * prog) / 100;
        let yP = chartDimensions.yMid - madSize / 2;
        // let progressFill =
        modeElem.push(
          <div
            key={i}
            style={{
              position: "absolute",
              transform: `translate(${xP}px, ${yP}px)`,
              zIndex: 150 + i
            }}
          >
            <MadChiclet
              shipmentMode={shipmentMode}
              stopMode={shipmentMode}
              activeException={null}
              height={madSize}
              width={madSize}
            />
          </div>
        );

        if (prog > 0 && prog < 100) {
          modeElem.push(
            <div
              key={`${i}-lbl`}
              style={{
                position: "absolute",
                transform: `translate(${xP + 2}px, ${yP - chicletSize}px)`,
                zIndex: 150 + i
              }}
            >
              <span
                style={{
                  fontSize: ".8em",
                  fontWeight: 600,
                  color: Colors.holds.DARK_BLUE
                }}
              >
                {`${prog}%`}
              </span>
            </div>
          );

          lineElem.push(
            <line
              key={`${i}-progress`}
              x1={x1}
              y1={chartDimensions.yMid}
              x2={xP}
              y2={chartDimensions.yMid}
              style={{
                stroke: progressBarColor,
                strokeWidth: 2,
                zIndex: 150 + i
              }}
            />
          );
        }
      }
    }
  });

  return (
    <div css={{}}>
      {modeElem}
      <svg height={height} width={width}>
        {lineElem}
      </svg>
    </div>
  );
};

class TripProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showingLeftSide: true
    };

    this.handleArrowClick = this.handleArrowClick.bind(this);
  }

  handleArrowClick() {
    const { showingLeftSide } = this.state;
    this.setState({ showingLeftSide: !showingLeftSide });
  }

  render() {
    const {
      lads,
      size,
      tripLegs,
      shipmentModes,
      isActiveLeg,
      shipment
    } = this.props;
    const { showingLeftSide } = this.state;
    const { height, width } = size;
    const orientation = showingLeftSide ? { left: 10 } : { left: -width + 10 };
    const scroll = tripLegs.length > 5;
    const scrollStyles = scroll
      ? {
          overflowX: "hidden",
          overflowY: "hidden"
        }
      : {};
    const scrollLineStyles = scroll
      ? {
          position: "absolute",
          top: 0,
          width: (width * tripLegs.length) / 5
        }
      : { width: width };
    return (
      <div
        css={{
          height: "6em",
          margin: "0 1em 2em 1em"
        }}
      >
        {scroll ? (
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div onClick={this.handleArrowClick}>
              <IoIosArrowBack
                size="1.5em"
                style={{
                  display: showingLeftSide ? "none" : "inline",
                  cursor: showingLeftSide ? "none" : "pointer"
                }}
              />
            </div>
            <div onClick={this.handleArrowClick}>
              <IoIosArrowForward
                size="1.5em"
                style={{
                  display: !showingLeftSide ? "none" : "inline",
                  cursor: !showingLeftSide ? "none" : "pointer"
                }}
              />
            </div>
          </div>
        ) : null}
        <div
          style={{
            height: "5.5em",
            position: "relative",
            width: "100%",
            ...scrollStyles
          }}
          ref={el => (this.container = el)}
        >
          <div
            css={{
              ...scrollLineStyles,
              ...orientation
            }}
          >
            <TripStops
              data={tripLegs}
              lads={lads}
              height={height}
              width={scroll ? (width * tripLegs.length) / 5 : width}
            />
            <TripProgress
              showAllMads={isActiveLeg}
              data={tripLegs}
              height={height}
              width={scroll ? (width * tripLegs.length) / 5 : width}
              shipmentModes={shipmentModes}
              shipment={shipment}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default sizeMe({ monitorHeight: true })(TripProgressBar);
