export default {
  highlight: {
    GREEN: "#46b630",
    RED: "#d5473f",
    YELLOW: "#fcd800",
    ALERT_YELLOW: "#fbd20e",
    LIGHT_BLUE: "#2077a8",
    BRIGHT_BLUE: "#67B5FF",
    ORANGE: "#ffb500",
    LIGHT_GRAY: "#e9e9e9",
    DARK_GRAY: "#252525",
    DARK_TEAL: "#1a6886",
    LIGHT_YELLOW: "#ffec92",
    MEDIUM_LIGHT_GRAY: "#9B9B9B"
  },
  text: {
    DARK_BLUE: "#081422",
    GRAY: "#8b8b8b",
    YELLOW: "#dbb800",
    LIGHT_GRAY: "#bac5cb",
    HIT_GRAY: "#acb5be",
    DARK_GRAY: "#333",
    LIGHTER_GRAY: "rgba(82, 82, 82, 0.5)",
    HEADER_ORANGE: "#de910b",
    BLUE_BLACK: "#273141"
  },
  holds: {
    LIGHT_BLUE: "#67b5ff",
    DARK_BLUE: "#2D3848",
    LIGHT_GREEN: "#45B139",
    BROWN_GRAY: "#525252",
    RED: "#d54e43",
    YELLOW: "#fbd20e",
    VIVID_PURPLE: "#4520E2",
    BRIGHT_RED: "#E24420",
    VIVID_BLUE: "#20BEE2",
    DEEP_BLUE: "#063844",
    DARK_RED: "#d54e43"
  },
  finishedVehicles: {
    LIGHT_BLUE: "#67b5ff"
  },
  background: {
    DARK_RED: "#8b181b",
    DARK_BLUE: "#273042",
    GRAY_BLUE: "#354251",
    LIGHT_GRAY: "#f2f2f2",
    GRAY: "#e0e0e0",
    DARK_GRAY: "#2d2d2d",
    DARK_HIGHLIGHT_GRAY: "#444444",
    WARM_GRAY: "#8A8A8A",
    AQUA: "#52C6DA",
    LIGHT_TEAL: "#51A0AE",
    DARK_TEAL: "#2785A9",
    DARKER_BLUE: "#143E7D",
    LIGHTER_BLUE: "#1150AE",
    YELLOW: "#fbd20e",
    GRAY_OVERLAY: "rgba(51, 51, 51, 0.75)"
  },
  locationType: {
    DARK_RED: "#960000",
    MEDIUM_RED: "#c90000",
    OLIVE_GREEN: "#7A8B45",
    CORAL_GREEN: "#80CAAB",
    GREEN_YELLOW: "#FFFFFF",
    PURPLE: "#5F3F8C"
  },
  border: {
    INNER_GRAY_BORDER: "#f3f3f3",
    TAB_BORDER: "#aaa",
    BOTTOM_AXIS_BORDER: "#ccc",
    CHART_AXIS_BORDER: "#e0e0e0"
  },
  bannerText: "#384655",
  bannerLinkText: "white",
  authInfoText: "#384655",
  tableHeadingBackground: "#2D2D2D", // Dark gray
  tableHeadingText: "#f2f2f2",
  formHeadingColor: "#969696",
  editLadsLabelText: "#aaa",
  linkText: "#2785A9", // dark teal
  buttonBarBorder: "#aaa",
  reactTableBorder: "rgba(0, 0, 0, 0.1)",
  geofence: {
    fillColor: "rgba(252, 216, 0, 0.2)",
    strokeColor: "rgba(252, 216, 0, 1.0)"
  },
  maps: {
    LIGHT_BLUE: "#0070A0",
    DARK_BLUE: "#48BCD6",
    LIGHT_ORANGE: "#FFAB40",
    DARK_ORANGE: "#E35333",
    LIGHT_RED: "#C73347"
  }
};

export const shadeColor = (color, percent) => {
  let f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};
