/** @jsx jsx */
import { jsx } from "@emotion/core";
import Select from "react-select";

/**
 * Filter <Select> dropdown for use in Results Table filter row
 * @param props
 * @return {*}
 * @constructor
 */
const SelectFilter = ({ isClearable = true, ...remainingProps }) => {
  return (
    <Select
      isClearable={isClearable}
      {...remainingProps}
      styles={{
        container: provided => ({ ...provided, height: "2.1em" }),
        control: provided => ({
          ...provided,
          height: "2.1em",
          minHeight: "unset"
        }),
        indicatorsContainer: provided => ({ ...provided, height: "2.1em" })
      }}
    />
  );
};

SelectFilter.propTypes = Select.propTypes;

export default SelectFilter;
