/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";
import ReactTable from "react-table";
import "react-tabs/style/react-tabs.css";

import { TiPencil, TiTrash, TiUserOutline, TiMail } from "react-icons/ti";

import { FlexColDiv, FlexDiv } from "../../../styles/container-elements";
import Colors from "../../../styles/colors";
import { datatableThStyles, styleify } from "../../../styles/table-styles";
import { tableDateSort } from "../../../components/tables/utils";

import {
  LinkButton,
  markerStyle,
  actionButtonStyle
} from "../../../styles/table-action-styles";

const TIME_FORMAT = "HH:mm";
const DATE_FORMAT = "M/D/YY";

const getStatusColor = status => {
  let color;

  switch (status) {
    case "Active":
      color = Colors.highlight.GREEN;
      break;
    case "Disabled":
    case "Invitation Expired":
      color = Colors.highlight.RED;
      break;
    default:
      color = "black";
      break;
  }

  return color;
};

const getFormatedDatetime = dateTime => {
  if (!dateTime) {
    return null;
  }

  return {
    time: moment(dateTime).format(TIME_FORMAT),
    date: moment(dateTime).format(DATE_FORMAT)
  };
};

const RoleTypeCell = props => {
  const { roles, id } = props.value;

  const items = roles
    ? roles.map(role => {
        const item =
          role.users.includes(id) && role.friendlyName ? (
            <div key={`${role.name}`}>{`${role.friendlyName}`}</div>
          ) : null;
        return item;
      })
    : null;

  return <FlexColDiv>{items}</FlexColDiv>;
};

RoleTypeCell.propTypes = {
  value: PropTypes.object.isRequired
};

const StatusCell = props => {
  const { actionHandler, status, t } = props.value;

  return (
    <FlexDiv style={{ justifyContent: "center", alignItems: "space-around" }}>
      <div style={{ color: getStatusColor(status), fontSize: 12 }}>
        {status}
      </div>
      {status === "Invitation Sent" ? (
        <LinkButton
          clickHandler={() =>
            actionHandler("RESEND", {
              ...props.original,
              rowID: props.index
            })
          }
        >
          {t("users:(Resend)")}
        </LinkButton>
      ) : null}
    </FlexDiv>
  );
};

StatusCell.propTypes = {
  value: PropTypes.object.isRequired,
  original: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

const LastLoginCell = props => {
  const { lastLogin } = props.value;
  const datetime = getFormatedDatetime(lastLogin);

  return (
    <FlexColDiv>
      {datetime ? (
        <div>
          <div>{datetime.time}</div>
          <div>{datetime.date}</div>
        </div>
      ) : null}
    </FlexColDiv>
  );
};

LastLoginCell.propTypes = {
  value: PropTypes.object.isRequired
};

const ActionCell = props => {
  const { actionHandler, roles, status, t } = props.value;

  const rowId = props.index;

  return (
    <div style={{ justifyContent: "center", alignItems: "center" }}>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler(status === "Disabled" ? "UNBLOCK" : "BLOCK", {
            ...props.original,
            rowID: rowId
          })
        }
      >
        {status === "Disabled"
          ? t("users:Mark Active")
          : t("users:Mark Inactive")}{" "}
        <TiUserOutline style={markerStyle} />
      </LinkButton>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("UPDATE", {
            ...props.original,
            rowID: rowId
          })
        }
      >
        {t("users:Edit User")} <TiPencil style={markerStyle} />
      </LinkButton>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("DELETE", {
            ...props.original,
            rowID: rowId
          })
        }
      >
        {t("users:Delete User")} <TiTrash style={markerStyle} />
      </LinkButton>
      {roles && ( // H1-533: Only FV_ADMIN and ORG_ADMIN can set/reset user's passwords.
        <LinkButton
          style={actionButtonStyle}
          clickHandler={() =>
            actionHandler("RESET", {
              ...props.original,
              rowID: rowId
            })
          }
        >
          {t("users:Reset Password")} <TiMail style={markerStyle} />
        </LinkButton>
      )}
    </div>
  );
};

ActionCell.propTypes = {
  value: PropTypes.object.isRequired,
  original: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

const UsersTable = ({ t, actionHandler, data, roles }) => {
  const usersColumns = [
    {
      Header: t("users:Email"),
      accessor: "email",
      minWidth: 250,
      style: {
        display: "flex"
      }
    },
    {
      Header: t("users:Role or Roles"),
      id: "user-roles",
      width: 200,
      style: {
        width: 200,
        display: "flex"
      },
      accessor: d => {
        return {
          roles: roles,
          id: d.user_id
        };
      },
      Cell: RoleTypeCell
    },
    {
      Header: t("users:Status"),
      id: "user-status",
      width: 100,
      style: {
        width: 100,
        display: "flex"
      },
      accessor: d => {
        return {
          actionHandler: actionHandler,
          status: d.status,
          t: t
        };
      },
      Cell: StatusCell
    },
    {
      Header: t("users:Last Login"),
      id: "last-login",
      width: 140,
      style: {
        width: 140,
        display: "flex"
      },
      Cell: LastLoginCell,
      accessor: d => {
        return {
          lastLogin: d.last_login
        };
      },
      sortMethod: tableDateSort
    },
    {
      Header: t("users:# of Logins"),
      accessor: "logins_count",
      width: 100,
      style: {
        display: "flex"
      }
    },
    {
      Header: t("users:Actions"),
      id: "user-actions",
      width: 500,
      Cell: ActionCell,
      accessor: d => {
        return {
          actionHandler: actionHandler,
          roles: roles,
          status: d.status,
          t: t
        };
      }
    }
  ];

  return (
    <ReactTable
      className="customtable fixedpaginationtable"
      data={data}
      columns={usersColumns}
      getTheadThProps={styleify(datatableThStyles)}
      previousText={t("users:Previous")}
      nextText={t("users:Next")}
      loadingText={`${t("users:Loading")}...`}
      noDataText={t("users:No rows found")}
      pageText={t("users:Page")}
      ofText={t("users:of")}
      rowsText={t("users:rows")}
      pageJumpText={t("users:jump to page")}
      rowsSelectorText={t("users:rows per page")}
    />
  );
};

UsersTable.propTypes = {
  t: PropTypes.func.isRequired,
  actionHandler: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  roles: PropTypes.array.isRequired
};

export default withTranslation(["users"])(UsersTable);
