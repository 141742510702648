import React from "react";
import styled from "@emotion/styled";

import {
  localizedTimeFormatter,
  localizedDateFormatter,
  dateFormatter
} from "../../utils/date-time";
import { convertLineBreaksToHtml } from "../../utils/html-utils";

const SmallerTextDiv = styled.div({
  fontSize: "smaller"
});

const BoldSmallerTextDiv = styled(SmallerTextDiv)({
  fontWeight: "bold"
});

/* DEV-1619 for date only display, don't localize */
export const getArrivalWindowText = loc => {
  const empty = { windowTextLine1: "N/A", windowTextLine2: null };
  if (!loc || !(loc.earliest_arrival_datetime || loc.latest_arrival_datetime)) {
    return empty;
  }

  const latestText = !loc.isDateOnly
    ? {
        windowTextLine1: localizedTimeFormatter(loc.latest_arrival_datetime),
        windowTextLine2: localizedDateFormatter(loc.latest_arrival_datetime)
      }
    : {
        windowTextLine1: dateFormatter(loc.latest_arrival_datetime),
        windowTextLine2: null
      };

  const earliestText = !loc.isDateOnly
    ? {
        windowTextLine1: localizedTimeFormatter(loc.earliest_arrival_datetime),
        windowTextLine2: localizedDateFormatter(loc.earliest_arrival_datetime)
      }
    : {
        windowTextLine1: dateFormatter(loc.earliest_arrival_datetime),
        windowTextLine2: null
      };

  if (loc.earliest_arrival_datetime && loc.latest_arrival_datetime) {
    // often they are the same
    if (loc.earliest_arrival_datetime === loc.latest_arrival_datetime) {
      return latestText;
    }

    return !loc.isDateOnly
      ? {
          windowTextLine1: `${localizedTimeFormatter(
            loc.earliest_arrival_datetime
          )}\n${localizedDateFormatter(loc.earliest_arrival_datetime)}\nto`,
          windowTextLine2: `${localizedTimeFormatter(
            loc.latest_arrival_datetime
          )}\n${localizedDateFormatter(loc.latest_arrival_datetime)}`
        }
      : {
          windowTextLine1: `${dateFormatter(
            loc.earliest_arrival_datetime
          )}\nto`,
          windowTextLine2: `${dateFormatter(loc.latest_arrival_datetime)}`
        };
  }

  if (loc.latest_arrival_datetime) {
    return latestText;
  }
  if (loc.earliest_arrival_datetime) {
    return earliestText;
  }
  console.error("shouldn't get here!");
  return empty;
};

/* H1-1349 Scheduled Delivery has different propeties, added function to extract those for display */
export const getDeliveryWindowText = loc => {
  const empty = { windowTextLine1: "N/A", windowTextLine2: null };
  if (
    !loc ||
    !(
      loc.delivery_earliest_arrival_datetime ||
      loc.delivery_latest_arrival_datetime
    )
  ) {
    return empty;
  }

  const latestText = {
    windowTextLine1: localizedTimeFormatter(
      loc.delivery_earliest_arrival_datetime
    ),
    windowTextLine2: localizedDateFormatter(
      loc.delivery_latest_arrival_datetime
    )
  };

  const earliestText = {
    windowTextLine1: localizedTimeFormatter(
      loc.delivery_earliest_arrival_datetime
    ),
    windowTextLine2: localizedDateFormatter(
      loc.delivery_latest_arrival_datetime
    )
  };

  if (
    loc.delivery_earliest_arrival_datetime &&
    loc.delivery_latest_arrival_datetime
  ) {
    // often they are the same
    if (
      loc.delivery_earliest_arrival_datetime ===
      loc.delivery_latest_arrival_datetime
    ) {
      return latestText;
    }

    return {
      windowTextLine1: `${localizedTimeFormatter(
        loc.delivery_earliest_arrival_datetime
      )}\n${localizedDateFormatter(
        loc.delivery_earliest_arrival_datetime
      )}\nto`,
      windowTextLine2: `${localizedTimeFormatter(
        loc.delivery_latest_arrival_datetime
      )}\n${localizedDateFormatter(loc.delivery_latest_arrival_datetime)}`
    };
  }

  if (loc.delivery_earliest_arrival_datetime) {
    return latestText;
  }
  if (loc.delivery_latest_arrival_datetime) {
    return earliestText;
  }
  console.error("shouldn't get here!");
  return empty;
};

export const ScheduledArrivalWindow = ({ loc }) => {
  const { windowTextLine1, windowTextLine2 } = getArrivalWindowText(loc);
  return (
    <React.Fragment>
      <BoldSmallerTextDiv>
        {convertLineBreaksToHtml(windowTextLine1)}
      </BoldSmallerTextDiv>
      <BoldSmallerTextDiv>
        {convertLineBreaksToHtml(windowTextLine2)}
      </BoldSmallerTextDiv>
    </React.Fragment>
  );
};
