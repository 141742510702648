/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SectionHeader from "./SectionHeader";

const navCss = {
  backgroundColor: "#ddd",
  padding: "1em",
  position: "fixed",
  top: "4em",
  right: "2em",
  nav: {
    marginTop: "-0.5em"
  },
  width: 160,
  a: {
    display: "block",
    fontStyle: "italic",
    fontWeight: 600,
    marginBottom: "0.5em",
    textDecoration: "underline"
  }
};

const SampleNav = props => {
  const { t, documentation } = props;

  if (!documentation) {
    return null;
  }

  const headers =
    documentation["Header Parameters"] &&
    documentation["Header Parameters"].length;
  const parameters =
    documentation.Parameters && documentation.Parameters.length;
  const body = documentation["Request Body"];
  const requests = documentation["Request Sample"];
  const responses = documentation["Response Sample"];
  const notes = Object.keys(documentation["Usage Notes"]).length > 0;
  const links = Object.keys(documentation["Extranl Docs"]).length > 0;

  return (
    <div css={navCss}>
      <SectionHeader title={t("documentation:Page Content")} />
      <nav>
        {headers ? (
          <a href="#headers">{t("documentation:Request Headers")}</a>
        ) : null}
        {parameters ? (
          <a href="#parameters">{t("documentation:Request Parameters")}</a>
        ) : null}
        {body ? <a href="#body">{t("documentation:Request Body")}</a> : null}
        {requests ? (
          <a href="#requests">{t("documentation:Request Samples")}</a>
        ) : null}
        {responses ? (
          <a href="#responses">{t("documentation:Response Samples")}</a>
        ) : null}
        {notes ? <a href="#notes">{t("documentation:Usage Notes")}</a> : null}
        {links ? <a href="#links">{t("documentation:Related Links")}</a> : null}
      </nav>
    </div>
  );
};

SampleNav.propTypes = {
  documentation: PropTypes.object.isRequired
};
export default withTranslation(["documentation"])(SampleNav);
