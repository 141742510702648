import { connect } from "react-redux";
import EditSavedSearchModal from "../../components/saved-search/EditSavedSearchModal";
import AdcSearchBarState from "./AdcSearchBarState";
import AdcSavedSearchState from "./AdcSavedSearchState";
import SearchBarContainer from "../adc-search/AdcSearchBarContainer";
import FilterSectionContainer from "./AdcFilterSectionContainer";

const mapStateToProps = state => {
  const {
    getSearchFilters,
    getSearchCategory,
    getSearchText
  } = AdcSearchBarState.selectors;

  return {
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    SearchBarContainer: SearchBarContainer,
    FilterSectionContainer: FilterSectionContainer
  };
};

const { saveSearch, editSearch } = AdcSavedSearchState.actionCreators;

const actionCreators = { saveSearch, editSearch };

export default connect(mapStateToProps, actionCreators)(EditSavedSearchModal);
