/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { IoIosDownload } from "react-icons/io";
import { useTranslation } from "react-i18next";

import Colors from "../../styles/colors";

const DownloadDataButton = ({ onClick, style, className }) => {
  const { t } = useTranslation("components");

  return (
    <div
      className={className}
      data-qa="button-search-results-export"
      css={{
        fontSize: 12.5,
        fontStyle: "italic",
        marginRight: 10,
        textDecoration: "underline",
        color: Colors.text.LIGHTER_GRAY,
        alignItems: "center",
        display: "flex",
        cursor: "pointer",
        ...style
      }}
      onClick={onClick}
    >
      {t("components:Download Data")} (.csv){" "}
      <IoIosDownload
        style={{
          fontSize: "1.9em",
          marginLeft: "0.1em"
        }}
      />
    </div>
  );
};

DownloadDataButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};

export default DownloadDataButton;
