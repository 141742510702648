import PropTypes from "prop-types";
import React from "react";
import Loader from "react-loader";
import styled from "@emotion/styled";
import { Alert } from "react-bootstrap";
import ReactTable from "react-table";
import { minimalistTableStyles } from "../../styles/table-styles";
import {
  PanelGroup,
  PanelGroupHeader,
  PanelGroupContent
} from "../../components/panel-group/PanelGroup";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DataWidgetTooltip } from "../../components/widgets/DataWidgetTooltip";
import { Privileges } from "../auth/Authorization";
import { getColumns } from "./ReportsColumns";
import { withTranslation } from "react-i18next";
import MixpanelUtils from "../../trackers/mixpanel";
import Dialog from "react-bootstrap-dialog";

const Section = styled.section({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "flex-start",
  padding: "1em"
});

class ReportsView extends React.Component {
  static propTypes = {
    fetchReports: PropTypes.func.isRequired,
    deleteReport: PropTypes.func.isRequired,
    pushReportDetailScreen: PropTypes.func.isRequired,
    reports: PropTypes.array,
    isLoading: PropTypes.bool,
    authorization: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.deleteReportCallback = this.deleteReportCallback.bind(this);
  }

  componentDidMount() {
    this.props.fetchReports();
    MixpanelUtils.track("Viewed Page: BI / Reports / Dashboard");

    Dialog.setOptions({
      defaultOkLabel: "Delete",
      defaultCancelLabel: "Cancel",
      primaryClassName: "btn-success"
    });
  }

  deleteReportCallback = (reportId, onCancel) => {
    const { t, fetchReports, deleteReport } = this.props;

    this.dialog.show({
      body: t("reports:Are you sure you want to delete this report?"),
      actions: [
        Dialog.CancelAction(() => {
          this.dialog.hide();
          onCancel();
        }),
        Dialog.OKAction(() => {
          deleteReport(reportId).then(() => {
            fetchReports();
          });
          this.dialog.hide();
        })
      ],
      bsSize: "small",
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  render() {
    const { t, reports, isLoading, authorization } = this.props;
    const headers = getColumns(t, false);

    let canDeleteReport = authorization.hasPrivileges([
      Privileges.BUILD_REPORT
    ]);

    const headersWithActions = getColumns(
      t,
      canDeleteReport,
      this.deleteReportCallback
    );

    const coreReports = reports.filter(r => r.groupName === "Default");
    const myReports = reports.filter(
      r => r.groupName === "Saved" && r.private === true
    );
    const sharedReports = reports.filter(
      r => r.groupName === "Saved" && !r.private
    );

    const tableTdProps = (state, rowInfo, column, instance) => {
      return {
        onClick: e => {
          if (column.id === "user-actions") {
            return;
          } else {
            const reportId = rowInfo.original.reportId;
            this.props.pushReportDetailScreen(reportId);
          }
        },
        style: { cursor: "pointer" }
      };
    };

    return (
      <Loader loaded={!isLoading}>
        <Section>
          {coreReports.length === 0 &&
            sharedReports.length === 0 &&
            myReports.length === 0 && (
              <Alert variant="warning">{t("reports:No Reports Found")}</Alert>
            )}

          {coreReports && coreReports.length > 0 && (
            <PanelGroup>
              <PanelGroupHeader
                title={
                  <div>
                    {t("reports:Core Reports")}
                    <DataWidgetTooltip
                      tooltip={t(
                        "reports:These reports are provided by the FreightVerify Reporting Team."
                      )}
                      icon={faInfoCircle}
                      style={{ marginLeft: "0.5em" }}
                    />
                  </div>
                }
              />
              <PanelGroupContent style={{ padding: 0 }}>
                <ReactTable
                  className="customtable"
                  NoDataComponent={() => null}
                  data={coreReports}
                  columns={headers}
                  getTheadThProps={() => {
                    return {
                      style: minimalistTableStyles.thead
                    };
                  }}
                  getTdProps={tableTdProps}
                  showPagination={false}
                  pageSize={coreReports.length}
                  style={minimalistTableStyles.table}
                />
              </PanelGroupContent>
            </PanelGroup>
          )}

          {sharedReports && sharedReports.length > 0 && (
            <PanelGroup>
              <PanelGroupHeader
                title={
                  <div>
                    {t("reports:Shared Reports")}
                    <DataWidgetTooltip
                      tooltip={t(
                        "reports:These reports have been created by the Report Builder and are shared across your organization."
                      )}
                      icon={faInfoCircle}
                      style={{ marginLeft: "0.5em" }}
                    />
                  </div>
                }
              />
              <PanelGroupContent style={{ padding: 0 }}>
                <ReactTable
                  className="customtable"
                  NoDataComponent={() => null}
                  data={sharedReports}
                  columns={headersWithActions}
                  getTheadThProps={() => {
                    return {
                      style: minimalistTableStyles.thead
                    };
                  }}
                  getTdProps={tableTdProps}
                  showPagination={false}
                  pageSize={sharedReports.length}
                  style={minimalistTableStyles.table}
                />
              </PanelGroupContent>
            </PanelGroup>
          )}

          {myReports && myReports.length > 0 && (
            <PanelGroup>
              <PanelGroupHeader
                title={
                  <div>
                    {t("reports:My Reports")}
                    <DataWidgetTooltip
                      tooltip={t(
                        "reports:These reports have been created by the Report Builder and are only visible to you."
                      )}
                      icon={faInfoCircle}
                      style={{ marginLeft: "0.5em" }}
                    />
                  </div>
                }
              />
              <PanelGroupContent style={{ padding: 0 }}>
                <ReactTable
                  className="customtable"
                  NoDataComponent={() => null}
                  data={myReports}
                  columns={headersWithActions}
                  getTheadThProps={() => {
                    return {
                      style: minimalistTableStyles.thead
                    };
                  }}
                  getTdProps={tableTdProps}
                  showPagination={false}
                  pageSize={myReports.length}
                  style={minimalistTableStyles.table}
                />
              </PanelGroupContent>
            </PanelGroup>
          )}
          <Dialog
            ref={el => {
              this.dialog = el;
            }}
          />
        </Section>
      </Loader>
    );
  }
}

export default withTranslation(["reports"])(ReportsView);
