/** @jsx jsx */
import { connect } from "react-redux";
import { jsx } from "@emotion/core";
import VinDetailsTabsPanel from "./VinDetailsTabsPanel";
import { getOriginAndDestination } from "../vin-details-utils";
import WatchToggle from "./WatchToggle";
import _ from "lodash";
import FinVehicleEntityDetailsState from "../FinVehicleEntityDetailsState";
import { addUpdates, convertEventsToUpdates } from "../vin-details-utils";
import {
  PanelGroup,
  PanelGroupHeader,
  PanelGroupContent
} from "../../../components/panel-group/PanelGroup";
import { useTranslation } from "react-i18next";
import { getVinDetails } from "../vin-details-utils";
import {
  FlexRowDiv,
  FlexColDiv,
  FlexDiv
} from "../../../styles/container-elements";
import VehicleImage from "./VehicleImage";
import Colors from "../../../styles/colors";
import { ExceptionsBox } from "./ModalBoxes";
import { MediaQueries } from "../../../components/responsive";
import ArrivalDepartureCollapsible from "../../shipment-detail/shipment-detail-styled-components/ArrivalDepartureCollapsible";
/*import { ShipmentTimeline } from "../../shipment-detail/ShipmentDetailPanel";

const processSingleShipment = (shipments, ladsList) => {
  const shipmentsList = shipments.map(s => {
    const lad = ladsList.filter(l => l.code === s.type.toLowerCase())[0];
    return {
      location: { lad: lad }
    };
  });
  return shipmentsList;
};
*/

const SingleShipmentVinDetailPanel = ({
  plannedLegs,
  watch,
  toggleWatchEntity,
  details,
  shipments,
  shipmentModes,
  vinTabs,
  events,
  coords,
  connectedCarCoords,
  selectCoordinate,
  clearCoordinate,
  media,
  holds,
  exceptions,
  ladsList,
  organization,
  hasTripPlan,
  size,
  tripPlan,
  combinedExceptions,
  currentLocation
}) => {
  const { t } = useTranslation("fv-vin-details");

  const tripLegsAvailable =
    !_.isNil(plannedLegs.tripLegs) && plannedLegs.tripLegs.length > 0;
  const originAndDestination =
    tripLegsAvailable && getOriginAndDestination(plannedLegs.tripLegs);
  let origin = tripLegsAvailable && originAndDestination.origin;
  let destination = tripLegsAvailable && originAndDestination.destination;
  /*(
  let tripLegsEndToEnd = [];
  let progress = 0;
  if (shipments.length > 0) {
    tripLegsEndToEnd = processSingleShipment(shipments, ladsList);
    progress = destination.progress;
  }
  */
  const tripLegUpdates = addUpdates(tripPlan, events);
  const eventUpdates = convertEventsToUpdates(events);
  const vinDetails = getVinDetails(details);

  return (
    <div
      css={{
        backgroundColor: Colors.background.LIGHT_GRAY,
        [MediaQueries.mediumAndUp]: {
          overflow: "auto"
        }
      }}
    >
      {/* Details Group */}
      <PanelGroup>
        <PanelGroupHeader title={t("Details")}>
          <WatchToggle
            style={{
              padding: "0 1em 0 0",
              background: "transparent",
              color: "white",
              fontWeight: "normal"
            }}
            watch={watch}
            toggleWatchEntity={toggleWatchEntity}
          />
        </PanelGroupHeader>
        <PanelGroupContent>
          <FlexDiv
            css={{
              flexDirection: "column",
              [MediaQueries.largeAndUp]: {
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "nowrap"
              }
            }}
          >
            <FlexColDiv css={{ flex: "1 1 0", marginRight: "1em" }}>
              <DetailRow label={t("fv-vin-details:VIN")}>
                {vinDetails.id}
              </DetailRow>
              <DetailRow label={t("fv-vin-details:Type")}>
                {vinDetails.type}
              </DetailRow>
              <DetailRow label={t("fv-vin-details:Status")}>
                {vinDetails.status}
              </DetailRow>
            </FlexColDiv>
            <FlexRowDiv css={{ alignSelf: "flex-start" }}>
              {tripLegsAvailable && (
                <VehicleImage style={{ marginRight: "1em" }} media={media} />
              )}
              <ExceptionsBox
                style={{ width: 150, height: 124, justifyContent: "center" }}
                holds={holds}
                exceptions={exceptions}
              />
            </FlexRowDiv>
          </FlexDiv>
        </PanelGroupContent>
      </PanelGroup>

      {/* Arrivals and Departures Group */}
      {tripLegsAvailable && (
        <ArrivalDepartureCollapsible
          shipment={null}
          destShipment={null}
          origin={origin}
          destination={destination}
          displayRouteHeader={false}
        />
      )}
      {/* H2-636: removing shipmenttimeline until new bar is created
        tripLegsEndToEnd.length > 0 || hasTripPlan ? (
          <div>
            <ShipmentTimeline
              shipment={{
                shipment_stops: tripLegsEndToEnd,
                // TODO: Add actual exceptions
                active_exceptions_ng: "",
                current_location: {
                  distance_progress: `${progress}%`,
                  active_leg_idx: 1
                }
              }}
              organization={organization}
              shipmentModes={shipmentModes}
              ladsList={ladsList}
              equalSpacing={true}
              width={size.width}
            />
          </div>
            ) : null */}
      <div
        css={{
          padding: "1.3em 1em"
        }}
      >
        <VinDetailsTabsPanel
          tabs={vinTabs}
          tripLegs={tripLegUpdates}
          eventUpdates={eventUpdates}
          events={events}
          coords={coords}
          connectedCarCoords={connectedCarCoords}
          selectCoordinate={selectCoordinate}
          clearCoordinate={clearCoordinate}
          multimodal={false}
        />
      </div>
    </div>
  );
};

export const DetailRow = ({ label, children }) => {
  return (
    <FlexRowDiv
      css={{
        padding: "1em 0",
        alignItems: "center",
        borderTop: `1px solid ${Colors.background.GRAY}`
      }}
    >
      <span
        css={{
          color: Colors.text.GRAY,
          fontSize: 12,
          width: "4em",
          marginLeft: "1em"
        }}
      >
        {label}
      </span>
      <span
        css={{
          color: Colors.text.DARK_BLUE,
          fontSize: "1.25em",
          wordBreak: "break-word"
        }}
      >
        {children}
      </span>
    </FlexRowDiv>
  );
};

function mapStateToProps(state) {
  const {
    getCombinedEntityExceptions,
    getEntityCurrentLocation,
    getEntityEvents,
    getEntityExceptions,
    getEntityHolds,
    getEntityMedia
  } = FinVehicleEntityDetailsState.selectors;

  return {
    combinedExceptions: getCombinedEntityExceptions(state),
    currentLocation: getEntityCurrentLocation(state),
    events: getEntityEvents(state),
    exceptions: getEntityExceptions(state),
    holds: getEntityHolds(state),
    media: getEntityMedia(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleShipmentVinDetailPanel);
