import React from "react";
import { connect } from "react-redux";
import {
  Modal,
  Button,
  FormControl,
  FormGroup,
  FormLabel
} from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";

class CancelShipmentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      noteValue: ""
    };
    this.handleOnEnter = this.handleOnEnter.bind(this);
    this.handleOnChangeNote = this.handleOnChangeNote.bind(this);
  }

  handleOnChangeNote(event) {
    this.setState({ noteValue: event.target.value });
  }

  componentDidMount() {}

  handleOnEnter() {
    this.setState({ noteValue: "" });
  }

  render() {
    const {
      show,
      hide,
      shipment,
      cancelShipment,
      organization,
      organizations,
      t
    } = this.props;
    const { noteValue } = this.state;
    const userChangedData = noteValue.length > 0;

    return (
      <Modal
        show={show}
        style={{ ":width": "300px" }}
        onHide={() => hide()}
        onEnter={this.handleOnEnter}
      >
        <Modal.Header
          closeButton
          style={{
            padding: "0.5em",
            color: Colors.background.DARK_BLUE,
            backgroundColor: Colors.background.LIGHT_GRAY
          }}
        >
          <Modal.Title style={{ fontWeight: "300" }}>
            {t("Cancel Shipment")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: Colors.background.LIGHT_GRAY }}>
          <FormGroup>
            <FormLabel>{`${t("Reason")}:`}</FormLabel>
            <FormControl
              as="textarea"
              placeholder={t("Enter reason for canceling this shipment")}
              value={noteValue}
              onChange={this.handleOnChangeNote}
              style={{ resize: "none" }}
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#e2e2e2" }}>
          <Button
            style={{
              backgroundColor: "white",
              color: Colors.background.DARK_BLUE,
              marginRight: "0.5em"
            }}
            onClick={() => hide()}
          >
            {t("Cancel")}
          </Button>

          <Button
            disabled={!userChangedData}
            style={{
              backgroundColor: Colors.highlight.GREEN,
              color: "white",
              fontWeight: "300"
            }}
            onClick={() => {
              cancelShipment(shipment, organization, organizations, noteValue);
              hide();
            }}
          >
            {t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

const withT = withTranslation("shipment-details")(CancelShipmentModal);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withT);
