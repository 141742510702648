/** @jsx jsx */
import React from "react";
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import DetailsTable from "./DetailsTable";
import ReceivedTimeCell from "./tables/Cell/ReceivedTimeCell";
import ReportedTimeCell from "./tables/Cell/ReportedTimeCell";

import { localizedDateFormatter } from "../utils/date-time";
import Colors from "../styles/colors";

import { FaAngleDown, FaAngleRight } from "react-icons/fa";

/* DEV-1163 Group the matching lat/long and display as single entries as a start-end time*/
/* DEV-1717 Only group the matching lat/long if they are consecutive by time */
function groupCoords(arr) {
  let result = [];
  let prev = {};

  arr.forEach((o, i) => {
    let coord;

    if (
      prev &&
      prev.latitude &&
      prev.longitude &&
      prev.latitude === o.latitude &&
      prev.longitude === o.longitude
    ) {
      result[result.length - 1].count += 1;
      result[result.length - 1].start = o.time;
    } else {
      coord = Object.assign({}, o, {
        count: 1,
        start: o.time,
        end: o.time
      });

      result.push(coord);
      prev = o;
    }
  });

  return result;
}

class CoordinatesTable extends React.Component {
  constructor(props) {
    super(props);

    // Sort the time in descending order, so most
    // recent update is at top of list
    const sortedCoords = _.orderBy(props.coords, ["time"], ["desc"]);

    this.state = {
      coords: sortedCoords,
      // H1-190: Always expand the first day of coordinates.
      expandedRows: sortedCoords.length ? [0] : []
    };
  }

  componentDidMount() {
    this.props.clearCoordinate();
  }

  render() {
    const {
      t,
      selectCoordinate,
      selectedCoordinate,
      "data-qa": dataQa
    } = this.props;
    const dataQaStr = dataQa ? `${dataQa}-` : "";
    const { coords, expandedRows } = this.state;

    const onRowClick = (row, ind) => {
      const { latitude, longitude, time } = row;
      selectCoordinate(latitude, longitude, time, ind, row);
    };

    /* DEV-1163 Group the Coordinates table by date with expand/collapse */
    let rows = [];
    let coordsByDate = _.groupBy(coords, c => localizedDateFormatter(c.time));

    Object.keys(coordsByDate).map((d, i) => {
      let coords = coordsByDate[d];
      rows.push(
        <tr
          key={i}
          onClick={() => {
            const newExRows = expandedRows.slice();
            const idx = newExRows.indexOf(i);
            idx > -1 ? newExRows.splice(idx, 1) : newExRows.push(i);
            this.setState({ expandedRows: newExRows });
          }}
          css={{
            ":hover": {
              cursor: "pointer",
              backgroundColor: `${Colors.highlight.LIGHT_GRAY} !important`
            }
          }}
          data-qa={`rows-coordinates-table-${dataQaStr}parent`}
        >
          <td colSpan="6">
            {expandedRows.includes(i) ? (
              <FaAngleDown style={{ marginRight: ".5em" }} />
            ) : (
              <FaAngleRight style={{ marginRight: ".5em" }} />
            )}
            <span>{d}</span>
          </td>
        </tr>
      );

      let coordRows;

      if (expandedRows.includes(i)) {
        let grouped = groupCoords(coords);
        coordRows = grouped.map((coord, j) => {
          const ind = `${i}_${j}`;
          const isSelectedCoordinate =
            selectedCoordinate && selectedCoordinate.ind === ind;

          const {
            bgColor,
            textColor,
            highlightBgColor,
            highlightTextColor
          } = isSelectedCoordinate
            ? {
                bgColor: `${Colors.background.DARK_TEAL} !important`,
                textColor: "#fff",
                highlightBgColor: `${Colors.highlight.DARK_TEAL} !important`,
                highlightTextColor: "#fff"
              }
            : {
                bgColor: Colors.background.LIGHT_GRAY,
                textColor: "inherit",
                highlightBgColor: `${Colors.highlight.LIGHT_GRAY} !important`,
                highlightTextColor: "inherit"
              };

          return (
            <tr
              key={ind}
              onClick={() => onRowClick(coord, ind)}
              css={{
                ":hover": {
                  cursor: "pointer",
                  backgroundColor: highlightBgColor,
                  color: highlightTextColor
                },
                backgroundColor: bgColor,
                color: textColor
              }}
              data-qa={`rows-coordinates-table-${dataQaStr}child`}
            >
              <ReportedTimeCell coord={coord} />
              <ReceivedTimeCell coord={coord} />
              <td>{coord.latitude}</td>
              <td>{coord.longitude}</td>
              <td>{coord.city}</td>
              <td>{coord.state}</td>
            </tr>
          );
        });

        rows.push(coordRows);
      }
      return 0;
    });

    return (
      <DetailsTable
        headers={[
          t("components:Event Time"),
          t("components:Received Time"),
          t("components:Latitude"),
          t("components:Longitude"),
          t("components:City"),
          t("components:State")
        ]}
        rows={rows}
      />
    );
  }
}

CoordinatesTable.propTypes = {
  coords: PropTypes.array,
  selectCoordinate: PropTypes.func,
  clearCoordinate: PropTypes.func,
  selectedCoordinate: PropTypes.object
};

CoordinatesTable.defaultProps = {
  coords: []
};

export default withTranslation(["components"])(CoordinatesTable);
