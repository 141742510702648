import { createSelector } from "reselect";
import _ from "lodash";
import DomainDataState from "../domain-data/DomainDataState";

const filterNullValuesAndMapFirstItem = values =>
  values.filter(t => _.isNil(t) === false).map(value => value[0]);

// Trailer Numbers are returned from API as arrays - convert to strings
export const selectTrailerNumberCategoryOptions = createSelector(
  [DomainDataState.selectors.getTrailerNumbers],
  filterNullValuesAndMapFirstItem
);

// Asset IDs are returned from API as arrays - convert to strings
export const selectAssetIdOptions = createSelector(
  [DomainDataState.selectors.getAssetIds],
  filterNullValuesAndMapFirstItem
);

// Train IDs are returned from API as arrays - convert to strings
export const selectTrainIdOptions = createSelector(
  [DomainDataState.selectors.getTrainIds],
  filterNullValuesAndMapFirstItem
);
