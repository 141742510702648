import { createSelector } from "reselect";
import _ from "lodash";
import FinVehicleTripLegState from "./FinVehicleTripLegState";

const getPlannedTripLegs = createSelector(
  [FinVehicleTripLegState.selectors.getPlannedTripLeg],
  planned => planned.tripLegs
);

const getActualTripLegs = createSelector(
  [FinVehicleTripLegState.selectors.getActualTripLeg],
  actual => actual.tripLegs
);

// const getLegIdStem = leg => leg.id.slice(0, -1);
const getLegLocationCodeString = leg => leg.origin.code + "_" + leg.dest.code;
const getLocationInfo = loc =>
  _.pick(loc, ["code", "name", "city", "state", "country"]);

export const getCombinedTripLegs = createSelector(
  [getPlannedTripLegs, getActualTripLegs],
  (plannedTripLegs, actualTripLegs) => {
    const actualByLocationCodes = _.keyBy(
      actualTripLegs,
      getLegLocationCodeString
    );
    const findActual = planned =>
      _.get(actualByLocationCodes, getLegLocationCodeString(planned));
    const locationRows = plannedTripLegs
      ? plannedTripLegs.map((planned, i) => {
          const actual = findActual(planned);
          return {
            planned,
            actual,
            plannedLegId: planned.id,
            actualLegId: _.get(actual, "id"),
            legNum: i
          };
        })
      : [];

    return locationRows;
  }
);

export const getLocationRows = createSelector(
  [getCombinedTripLegs],
  locationRowData => {
    const locationRows = locationRowData.map(row => {
      return {
        ...row,
        locationInfo: getLocationInfo(row.planned.origin)
      };
    });
    const lastLocationRow = _.last(locationRows);
    if (lastLocationRow) {
      locationRows.push({
        ...lastLocationRow,
        legNum: lastLocationRow.legNum + 1,
        locationInfo: getLocationInfo(lastLocationRow.planned.dest)
      });
    }
    return locationRows;
  }
);
