import PropTypes from "prop-types";
import React from "react";

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const BoldTextCell = props => {
  return (
    <div css={{ fontWeight: "bold", textOverflow: "auto", overflow: "none" }}>
      {props.value}
    </div>
  );
};

BoldTextCell.propTypes = {
  value: PropTypes.string
};

export default BoldTextCell;
