// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";
import FormCell from "../FormCell";
import SelectField from "../fields/SelectField";

const SelectInput = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  isReadOnly,
  loadOptions,
  isLoading,
  minWidth,
  borderColor
}) => {
  return (
    <FormCell label={label}>
      {loadOptions ? (
        <SelectField
          isAsync={true}
          isClearable={false}
          stateValue={value}
          onChange={value => onChange(value.value)}
          isDisabled={isReadOnly}
          loadOptions={loadOptions}
          defaultOptions={false}
          cacheOptions={false}
          isLoading={isLoading}
          placeholder={placeholder}
          borderWidth="2px"
          borderColor={borderColor}
          minWidth={minWidth}
        />
      ) : (
        <SelectField
          options={options}
          stateValue={value}
          onChange={value => onChange(value.value)}
          isDisabled={isReadOnly}
          borderWidth="2px"
          borderColor={borderColor}
          minWidth={minWidth}
        />
      )}
    </FormCell>
  );
};

SelectInput.propTypes = {
  isLoading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loadOptions: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  minWidth: PropTypes.string,
  borderColor: PropTypes.string
};

SelectInput.defaultProps = {
  minWidth: "17em"
};

export default SelectInput;
