import { connect } from "react-redux";
import ReportDetailView from "./ReportDetailView";
import ReportsState from "../reports/ReportsState";
import { getAuthorization } from "../auth/AuthorizationSelectors";

function mapStateToProps(state) {
  const {
    getReport,
    getIsLoading,
    getIsSaving,
    getIsEditing,
    getIsDeleting,
    getSelectedReportId
  } = ReportsState.selectors;

  return {
    report: getReport(state),
    isLoading: getIsLoading(state),
    isSaving: getIsSaving(state),
    isEditing: getIsEditing(state),
    isDeleting: getIsDeleting(state),
    selectedReportId: getSelectedReportId(state),
    authorization: getAuthorization(state)
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchReport,
    saveReport,
    updateReport,
    deleteReport
  } = ReportsState.actionCreators;
  return {
    fetchReport: reportId => dispatch(fetchReport(reportId)),
    saveReport: data => dispatch(saveReport(data)),
    updateReport: (reportId, lastUpdatedDatetime) =>
      dispatch(updateReport(reportId, lastUpdatedDatetime)),
    deleteReport: reportId => dispatch(deleteReport(reportId)),
    pushBIDashboardScreen: () =>
      dispatch({
        type: "REPORTS"
      })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetailView);
