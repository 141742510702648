import PropTypes from "prop-types";
import React from "react";
import styled from "@emotion/styled";

import { useIsMediumAndDown } from "../components/responsive";

export const FlexDiv = styled.div({ display: "flex" });
export const FlexColDiv = styled(FlexDiv)({ flexDirection: "column" });
export const FlexRowDiv = styled(FlexDiv)({ flexDirection: "row" });

export const Section = styled.section({
  display: "flex",
  height: "calc(100vh - 3.3em)",
  flexDirection: "column",
  justifyContent: "space-between"
});

export const OneColumnSection = styled(Section)({
  height: "calc(40vh - 3em)",
  maxWidth: "100%"
});

export const ResponsiveSection = props => {
  const isMobile = useIsMediumAndDown();
  const SectionComponent = isMobile ? OneColumnSection : Section;
  return <SectionComponent {...props}>{props.children}</SectionComponent>;
};

ResponsiveSection.propTypes = {
  children: PropTypes.node.isRequired
};

export const BoldText = styled.div({ fontWeight: "bold" });
export const BoldSpan = styled.span({ fontWeight: "bold" });
export const UnderlineText = styled.div({ textDecoration: "underline" });
export const ItalicText = styled.div({ fontStyle: "italic" });
export const SmallText = styled.div({ fontSize: "small" });
