import PropTypes from "prop-types";
import React from "react";
import ReactTable from "react-table";
import { scrollToTableTop } from "../../utils/table-utils";
import { resultsTableStyles, styleify } from "../../styles/table-styles";
import ReactTableTd from "./Layout/ReactTableTd";

const ResultsTable = ({
  data,
  manual,
  columns,
  rowClickHandler,
  cellClickHandler,
  cellCursorPointerEnabled,
  showPagination,
  fixPaginationToBottom,
  loading,
  page,
  pageSize,
  totalPages,
  // Default for tables that don't need manual control
  setPagination = () => {},
  solutionId,
  rowHeight,
  setSort,
  SubComponent,
  onFilteredChange,
  filterable,
  defaultFiltered,
  sortable
}) => {
  let tableColumns;
  if (typeof columns === "function") {
    tableColumns = columns();
  } else {
    tableColumns = columns;
  }

  const sortHandler = setSort
    ? newSorted => {
        const { id: col, desc: isReverse } = newSorted[0];
        setSort(solutionId, col, isReverse);
      }
    : () => {};

  return (
    <ReactTable
      className={`customtable ${
        fixPaginationToBottom === true ? " fixedpaginationtable" : ""
      }`}
      TdComponent={ReactTableTd}
      manual={manual}
      style={resultsTableStyles.table}
      getTheadProps={styleify(resultsTableStyles.thead)}
      getTheadThProps={styleify(resultsTableStyles.theadTh)}
      getTheadFilterThProps={styleify(resultsTableStyles.theadFilterTh)}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          style: {
            ...resultsTableStyles.td,
            height: rowHeight ? rowHeight : 50,
            cursor: cellCursorPointerEnabled === true ? "pointer" : "auto"
          },
          onClick: (e, handleOriginal) => {
            if (cellClickHandler) {
              cellClickHandler(state, rowInfo, column, instance);
            }
            // allow SubComponent clicks to function
            if (handleOriginal) {
              handleOriginal();
            }
          }
        };
      }}
      getTrProps={(state, rowInfo) => ({
        onClick: (e, handleOriginal) => {
          if (rowClickHandler) {
            rowClickHandler(rowInfo);
          }
          // allow SubComponent clicks to function
          if (handleOriginal) {
            handleOriginal();
          }
        }
      })}
      showPagination={showPagination || false}
      minRows={!data || data.length === 0 ? 5 : 0} // H2-211 : 'No rows' overlaps columns
      data={data}
      columns={tableColumns}
      loading={loading || false}
      // If we force defaults  on pages, page, and pageSize,
      // pagination will break for non-manual tables
      pages={totalPages}
      page={page}
      pageSize={pageSize}
      onPageChange={newPage => {
        setPagination(solutionId, newPage, pageSize);
        scrollToTableTop();
      }}
      onPageSizeChange={newPageSize =>
        setPagination(solutionId, 0, newPageSize)
      }
      onSortedChange={sortHandler}
      SubComponent={SubComponent}
      onFilteredChange={onFilteredChange}
      filterable={filterable || false}
      defaultFiltered={defaultFiltered || []}
      sortable={sortable}
    />
  );
};

ResultsTable.defaultProps = {
  manual: false,
  sortable: true
};

ResultsTable.propTypes = {
  SubComponent: PropTypes.node,
  cellClickHandler: PropTypes.any,
  cellCursorPointerEnabled: PropTypes.any,
  columns: PropTypes.oneOfType([PropTypes.func, PropTypes.array]).isRequired,
  data: PropTypes.array,
  defaultFiltered: PropTypes.any,
  filterable: PropTypes.any,
  fixPaginationToBottom: PropTypes.any,
  loading: PropTypes.any,
  manual: PropTypes.bool,
  onFilteredChange: PropTypes.func,
  page: PropTypes.any,
  pageSize: PropTypes.any,
  rowClickHandler: PropTypes.func,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setPagination: PropTypes.func,
  setSort: PropTypes.func,
  showPagination: PropTypes.bool,
  solutionId: PropTypes.string,
  sortable: PropTypes.bool,
  totalPages: PropTypes.number
};

export default ResultsTable;
