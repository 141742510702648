import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import configureStore from "./redux/store";
import AuthenticationUtils from "./modules/auth/authentication";
import "./i18n";
import { initGoogleAnalytics } from "./trackers/googleanalytics";
import { initMixpanel } from "./trackers/mixpanel";

//
// Init tracking
initGoogleAnalytics();
initMixpanel();

//
// send auth headers with every (axios-based) xhr request
AuthenticationUtils.installAuthInterceptor();

const { store } = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
