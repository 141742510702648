/** @jsx jsx */
import { jsx } from "@emotion/core";
import { TabPanel } from "react-tabs";
import _ from "lodash";
import TabsContainerPanel, {
  tabPanelStyle
} from "../../../components/tabs-container-panel";
import { withTranslation } from "react-i18next";

import CoordinatesTable from "../../../components/CoordinatesTable";
import { TabPanelPlaceholder } from "../../../components/no-data-placeholders";
import StopsTable from "../shipment-detail-styled-components/StopsTable";
import UpdatesTable from "../../fv-vin-details/vin-details-styled-components/UpdatesTable";
import { flattenShipmentStops } from "../../fv-vin-details/vin-details-styled-components/TripProgressBarNew";

export const getKeyUpdateForShipment = (t, childShipment) => {
  // H1-799: Match friendly event name formatting in fv-vin-details/vin-details-utils.js
  const keyUpdateFields = [
    {
      fieldName: "destination_actual_arrival",
      comment: `${t("Arrived for Delivery")} - ${
        childShipment.destination_name
      } (${childShipment.destination_location_code})`
    },
    {
      fieldName: "origin_actual_arrival",
      comment: `${t("Arrived for Pickup")} - ${childShipment.origin_name} (${
        childShipment.origin_location_code
      })`
    },
    {
      fieldName: "origin_actual_departure",
      comment: `${t("Departed Pickup")} - ${childShipment.origin_name} (${
        childShipment.origin_location_code
      })`
    }
  ];
  let keyUpdates = [];
  keyUpdateFields.forEach(field => {
    if (childShipment[field.fieldName] !== null) {
      keyUpdates.push({
        time: childShipment[field.fieldName],
        update: field.comment
      });
    }
  });
  return keyUpdates;
};

export const processKeyUpdates = (t, parentShipment, childShipments) => {
  const tripLegsEndToEnd = flattenShipmentStops(
    t,
    parentShipment,
    childShipments
  );
  return tripLegsEndToEnd.map(leg => ({
    ...leg,
    code: "",
    scheduled_delivery: null,
    scheduled_pickup: null
  }));
};

export const processCoordinates = (parentShipment, childShipments) => {
  let data = {};

  if (!parentShipment || _.isEmpty(childShipments)) {
    return data;
  }

  let coordinatesData = [];

  Object.keys(childShipments).forEach(id => {
    let s = childShipments[id];

    s.current_location.updates.forEach((c, i) => {
      coordinatesData.push(c);
    });
  });

  return {
    coordinates: coordinatesData
  };
};

export const processStops = (parentShipment, childShipments) => {
  let data = {};

  if (!parentShipment || _.isEmpty(childShipments)) {
    return data;
  }

  let stopsData = [];
  let active_exceptions_ng;

  parentShipment.child_shipments.forEach(childShipment => {
    childShipment.shipment_stops.forEach(childShipmentStop => {
      stopsData.push(childShipmentStop);
    });
  });

  // H1-796: Add functionality here to dedupe stops
  // Compare each stop to the previous stop to see if they are identical
  // if they are identical, merge them
  // H1-844 first stop of a shipment leg is the pickup, second is delivery
  // So we arrived (pickup) from prevStop, and departed (delivery) from s
  let dedupedStops = [];

  // H1-1349 Update to dedupedStops process. earliest_arrival_datetime and latest_arrival_datetime
  // are the scheudled pickup window of the stop and come from the first shipment leg.
  // Added var to grab the delivery_earliest_arrival_datetime and delivery_latest_arrival_datetime
  // for the scheduled delivery window, which come from the second shipment in the previous stopData in queue.
  // Also update dedupedStops for getting the correct sequence for actual pickup and delivery arrival times
  // using the same process as scheduled.
  let deliveryWindow = {
    delivery_earliest_arrival_datetime: null,
    delivery_latest_arrival_datetime: null
  };

  stopsData.forEach((s, i) => {
    if (i === 0) {
      dedupedStops.push(
        Object.assign({}, s, {
          ...deliveryWindow,
          pickup: s.arrived,
          delivery: null
        })
      );
    }
    if (i === stopsData.length - 1) {
      // for last stop, delivery window times come from earliest_arrival_datetime/latest_arrival_datetime
      dedupedStops.push(
        Object.assign({}, s, {
          delivery_earliest_arrival_datetime: s.earliest_arrival_datetime,
          delivery_latest_arrival_datetime: s.latest_arrival_datetime,
          pickup: null,
          delivery: s.arrived
        })
      );
    } else {
      // check if this stop has the same location.id as the previous stop
      let prevStop = dedupedStops.slice(-1)[0];
      if (prevStop.location.id === s.location.id) {
        // these are the relevant fields that are used in stopRows
        prevStop.departed = s.departed;
        prevStop.departed_at = s.departed_at;
        prevStop.eta = prevStop.eta ? prevStop.eta : s.eta;

        // update scheduled pickup
        prevStop.earliest_arrival_datetime = s.earliest_arrival_datetime;
        prevStop.latest_arrival_datetime = s.latest_arrival_datetime;

        // update scheduled delivery
        prevStop.delivery_earliest_arrival_datetime =
          deliveryWindow.delivery_earliest_arrival_datetime;
        prevStop.delivery_latest_arrival_datetime =
          deliveryWindow.delivery_latest_arrival_datetime;

        // update actual pickup
        prevStop.pickup = s.arrived;
      } else {
        dedupedStops.push(
          Object.assign({}, s, {
            ...deliveryWindow,
            pickup: null,
            delivery: s.arrived
          })
        );

        // if second leg, save those times for scheduled delivery
        if (s.stop_sequence === 2) {
          deliveryWindow.delivery_earliest_arrival_datetime =
            s.earliest_arrival_datetime;
          deliveryWindow.delivery_latest_arrival_datetime =
            s.latest_arrival_datetime;
        }
      }
    }
  });

  const orderedStopsData = _.orderBy(dedupedStops, [
    "earliest_arrival_datetime"
  ]);

  // H1-815: Use parent shipment ETA if ultimate destination ETA is null
  const lastStop = _.last(orderedStopsData);
  if (lastStop && !lastStop.eta) {
    lastStop.eta = parentShipment.destination_eta;
  }

  return {
    stops: orderedStopsData,
    active_exceptions_ng: active_exceptions_ng
  };
};

const EndToEndTabsPanel = ({
  tabsArray,
  tabsData,
  childShipments,
  parentShipment,
  selectedCoordinate,
  selectCoordinate,
  clearCoordinate,
  t
}) => {
  const keyUpdatesData = processKeyUpdates(t, parentShipment, childShipments);
  const updatesArray = _.flatten(
    keyUpdatesData.map(shipment => shipment.updates)
  );

  const stopsData = processStops(parentShipment, childShipments);
  const coordinatesData = processCoordinates(parentShipment, childShipments);
  return (
    <div
      css={{
        ".react-tabs__tab--selected": {
          marginTop: "4px !important"
        }
      }}
    >
      <TabsContainerPanel
        tabsArray={tabsArray}
        tabsData={{
          ...tabsData,
          updates: updatesArray
        }}
      >
        <TabPanel style={tabPanelStyle}>
          {keyUpdatesData.length === 0 ? (
            <TabPanelPlaceholder text={t("No Trip Plan Available")} />
          ) : (
            <div style={{ padding: "25px 20px 10px 15px" }}>
              {keyUpdatesData.map((d, i) => (
                <UpdatesTable
                  vinInfo={{ ...d, typeCode: d.location.lad.code }}
                  key={`${d.shipment_id}-${i}`}
                  start={i === 0}
                  end={i === keyUpdatesData.length - 1}
                />
              ))}
            </div>
          )}
        </TabPanel>
        <TabPanel style={tabPanelStyle}>
          {stopsData.stops && !_.isEmpty(stopsData.stops) ? (
            <StopsTable
              mode={parentShipment.mode_name}
              stops={stopsData.stops}
              active_exceptions_ng={stopsData.active_exceptions_ng}
            />
          ) : (
            <TabPanelPlaceholder text={t("No Stops Available")} />
          )}
        </TabPanel>
        <TabPanel style={tabPanelStyle}>
          {coordinatesData.coordinates &&
          !_.isEmpty(coordinatesData.coordinates) ? (
            <CoordinatesTable
              coords={coordinatesData.coordinates}
              selectCoordinate={selectCoordinate}
              clearCoordinate={clearCoordinate}
              selectedCoordinate={selectedCoordinate}
            />
          ) : (
            <TabPanelPlaceholder text={t("No Coordinates Available")} />
          )}
        </TabPanel>
      </TabsContainerPanel>
    </div>
  );
};

export default withTranslation("shipment-details")(EndToEndTabsPanel);
