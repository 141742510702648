import _ from "lodash";
import moment from "moment";
import axios from "axios";
import fileDownload from "js-file-download";
import qs from "qs";

export const EXPORT_REQUEST = "search/EXPORT_REQUEST";
export const EXPORT_DOWNLOAD_URL_RECEIVED =
  "search/EXPORT_DOWNLOAD_URL_RECEIVED";
export const EXPORT_SEARCH_SUCCEEDED = "search/EXPORT_SEARCH_SUCCEEDED";
export const EXPORT_SEARCH_FAILED = "search/EXPORT_SEARCH_FAILED";

export const csvHeaders = ({ tz }) => {
  if (_.isNil(tz)) {
    tz = moment.tz.guess();
  }
  return {
    Accept: "text/csv",
    // H1-551 Send the timezone for the export request in the headers.
    "x-time-zone": tz
  };
};

// H1-1405: instead of directly responding with the exported data, the
// api now gives us a download url pointing to somewhere in S3-land. We
// need to fetch the data with a separate request.
export const downloadCsvFromReflectedFetch = (
  dispatch,
  fetchPromise,
  config,
  exportFileName
) => {
  dispatch({ type: EXPORT_REQUEST });
  fetchPromise
    .then(resp => {
      const downloadUrl = resp.data;
      dispatch({
        type: EXPORT_DOWNLOAD_URL_RECEIVED,
        payload: { downloadUrl }
      });
      // S3 will reject our download request if the api auth headers are
      // present
      const downloadRequestConfig = { ...config };
      const noInterceptorsAxios = axios.create();
      return noInterceptorsAxios.get(downloadUrl, downloadRequestConfig);
    })
    .then(resp => {
      fileDownload(resp.data, exportFileName);
      dispatch({
        type: EXPORT_SEARCH_SUCCEEDED
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: EXPORT_SEARCH_FAILED
      });
    });
};

// add pagination query params to url (a string). If params already present,
// return unchanged.
export const ensurePagination = (
  url,
  pagination = { pageNumber: 0, pageSize: 1000 }
) => {
  const newUrl = new URL(url);
  const { searchParams } = newUrl;
  if (searchParams.has("pageNumber") && searchParams.has("pageSize")) {
    return url;
  }
  const { pageNumber, pageSize } = pagination;
  if (!searchParams.has("pageNumber")) {
    searchParams.set("pageNumber", pageNumber);
  }
  if (!searchParams.has("pageSize")) {
    searchParams.set("pageSize", pageSize);
  }
  return newUrl.href;
};

// remove pagination params from the queryString; return a string
export const withoutPagination = queryString => {
  const { pageNumber, pageSize, ...qsParams } = qs.parse(queryString);
  return qs.stringify(qsParams);
};

export const exportReducer = (state, action = {}) => {
  switch (action.type) {
    case EXPORT_REQUEST:
      return {
        ...state,
        isExporting: true,
        exportFailed: false
      };
    case EXPORT_SEARCH_SUCCEEDED:
      return {
        ...state,
        isExporting: false
      };
    case EXPORT_SEARCH_FAILED:
      return {
        ...state,
        isExporting: false,
        exportFailed: true
      };
    default:
      return state;
  }
};
exportReducer.initialState = {
  isExporting: false,
  exportFailed: false
};
