import { filter } from "lodash";
import { Roles } from "../../auth/Authorization";

export const filterRoleTypes = (roles, isFvAdmin) => {
  return !isFvAdmin
    ? filter(roles, function(r) {
        return (
          r.name === Roles.ORGANIZATION_ADMIN ||
          r.name === Roles.ORGANIZATION_USER ||
          r.name === Roles.ORGANIZATION_REPORTING_USER ||
          r.name === Roles.FINISHED_VEHICLE_USER ||
          r.name === Roles.FINISHED_VEHICLE_ADC ||
          r.name === Roles.PLANT_ASSET_TRACKING_USER ||
          r.name === Roles.REPORT_BUILDER_USER
        );
      })
    : roles;
};
