import { connect } from "react-redux";
import SavedSearchSelect from "../../components/saved-search/SavedSearchSelect";
import DeleteSavedSearchModal from "../../components/saved-search/DeleteSavedSearchModal";

import { getSolutionId } from "../organizations/OrganizationsState";
import AdcSavedSearchState from "./AdcSavedSearchState";
import AdcSearchBarState from "./AdcSearchBarState";
import EditSavedSearchModalContainer from "./AdcEditSavedSearchModalContainer";

const mapStateToProps = state => {
  const {
    getSelectedSavedSearch,
    getSearchFilters,
    getSearchCategory,
    getSearchText
  } = AdcSearchBarState.selectors;
  const { getSavedSearches } = AdcSavedSearchState.selectors;
  return {
    ...state.fvSearch,
    savedSearch: getSelectedSavedSearch(state),
    savedSearches: getSavedSearches(state),
    searchObj: {},
    searchFilters: getSearchFilters(state),
    searchCategory: getSearchCategory(state),
    searchText: getSearchText(state),
    solutionId: getSolutionId(state),
    editModal: EditSavedSearchModalContainer,
    deleteModal: DeleteSavedSearchModal
  };
};

const {
  fetchSavedSearches,
  loadSavedSearch,
  saveSearch,
  editSearch,
  deleteSearch
} = AdcSavedSearchState.actionCreators;
const {
  exportEntities,
  selectSavedSearch,
  resetSavedSearch,
  exportSearch
} = AdcSearchBarState.actionCreators;

const actionCreators = {
  fetchSavedSearches,
  loadSavedSearch,
  saveSearch,
  editSearch,
  deleteSearch,
  exportEntities,
  selectSavedSearch,
  resetSavedSearch,
  exportSearch
};

export default connect(mapStateToProps, actionCreators)(SavedSearchSelect);
