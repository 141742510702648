/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";

export default function ReportSaveLocation(props) {
  const { t } = useTranslation("reports");

  const { show, saveReport, isSaving, reportData } = props;
  const [selectedLocation, setSelectedLocation] = useState(null);

  const onSaveLocation = () => {
    reportData.location = selectedLocation.value;
    saveReport(reportData);
  };

  const options = [
    {
      value: "my",
      label: "My Reports"
    },
    {
      value: "shared",
      label: "Shared Reports"
    }
  ];

  return (
    <Modal
      show={show}
      size="lg"
      backdrop="static"
      centered
      css={{ ".modal-content": { borderRadius: 0 } }}
    >
      <Modal.Header
        closeButton={false}
        style={{
          backgroundColor: "transparent",
          color: "black",
          borderBottom: 0,
          paddingBottom: 0
        }}
      >
        <Modal.Title style={{ fontSize: "1.8em" }}>
          {t("reports:Save report location")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t(
            "reports:Where would you like to save your report? Selecting 'Shared Reports' will display this report to everyone in your organization."
          )}
        </p>
        <div>
          <Select
            name="report-save-location"
            multi={false}
            style={{ width: "100%" }}
            options={options}
            value={selectedLocation}
            clearable={false}
            onChange={selection => {
              setSelectedLocation(selection);
            }}
            isDisabled={isSaving}
            data-qa="save-location"
          />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: 0, paddingTop: 0 }}>
        <Button
          onClick={onSaveLocation}
          disabled={!selectedLocation || isSaving}
          data-qa="save-report-button"
        >
          {isSaving === true
            ? t("reports:Saving...")
            : t("reports:Save Report")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ReportSaveLocation.propTypes = {
  show: PropTypes.bool,
  saveReport: PropTypes.func,
  isSaving: PropTypes.bool,
  reportData: PropTypes.object
};
