import PropTypes from "prop-types";
import React from "react";

/**
 * @param props
 * @return {null|*}
 * @constructor
 */
const SimpleAddressCell = props => {
  const { address, city, state, zip } = props.value;

  if (address == null) {
    return null;
  }

  if (address.length === 0) {
    return null;
  }

  return (
    <div>
      <div>{`${address},`}</div>
      <div>{`${city}, ${state}, ${zip}`}</div>
    </div>
  );
};

export default SimpleAddressCell;

SimpleAddressCell.propTypes = {
  value: PropTypes.shape({
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string
  })
};
