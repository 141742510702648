/**
 * The domain data for this module is generated by getting listing all data
 * from the list endpoint and organizing this in the way we need that.
 */
import { createSelector } from "reselect";
import _ from "lodash";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import apiUrl from "../../api-url";
import { alfBy } from "../../components/selectors";

const STORE_MOUNT_POINT = "adcDomainData";
const adcDomainDuck = buildFetchDuck("adcDomainDuck");

// Dynamic Meta Data: hold types, product types, exception types, carriers, order types
export function fetchDomainData(solutionId) {
  const url = apiUrl(
    `/entity-search/solution/${solutionId}/list?&holdType=1&productType=1&exceptionType=1&carrier=1&ref:OrderType=1`
  );
  return dispatch => {
    dispatch(adcDomainDuck.fetch(url));
  };
}

const getOrderTypes = state =>
  _.get(state[STORE_MOUNT_POINT], "data.ref:OrderType", []);

const selectOrderTypesAlphabetically = createSelector(
  getOrderTypes,
  types => types.sort()
);

const getHoldTypes = state =>
  _.get(state[STORE_MOUNT_POINT], "data.holdTypes", []);

const selectHoldTypesAlphabetically = alfBy(getHoldTypes, "name");

const getExceptionTypes = state =>
  _.get(state[STORE_MOUNT_POINT], "data.exceptionTypes", []);

const selectExceptionTypesAlphabetically = alfBy(getExceptionTypes, "name");

const getVINs = state => {
  // H2-366: Remove VINs from typeahead
  return [];
};

const getShipments = state => [];

const getProductTypes = state =>
  _.get(state[STORE_MOUNT_POINT], "data.productTypes", []);

const getCarriers = state =>
  _.get(state[STORE_MOUNT_POINT], "data.carriers", []);

const selectCarriersAlphabetically = createSelector(
  getCarriers,
  carriers => carriers.sort()
);

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchDomainData
  },
  selectors: {
    getOrderTypes,
    selectOrderTypesAlphabetically,
    getHoldTypes,
    selectHoldTypesAlphabetically,
    getExceptionTypes,
    selectExceptionTypesAlphabetically,
    getProductTypes,
    getVINs,
    getShipments,
    getCarriers,
    selectCarriersAlphabetically
  },
  reducer: adcDomainDuck.reducer
};
