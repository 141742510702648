import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import momentTz from "moment-timezone";
import _ from "lodash";
import produce from "immer";
import { Alert } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import ShipmentInfoForm from "./ShipmentInfoForm";
import ShipmentStopsForm from "./ShipmentStopsForm";
import ShipmentFormControls from "./ShipmentFormControls";
import { checkValidation } from "./CreateShipmentState";
import { FlexColDiv, Section } from "../../styles/container-elements";
import MixpanelUtils from "../../trackers/mixpanel";

const USER_TIMEZONE = momentTz.tz.guess();

export const STOP_INIT = {
  location: null,
  stopType: null,
  stopRole: null,
  earlyArrival: null,
  lateArrival: null,
  timezone: USER_TIMEZONE
};

class CreateShipmentView extends React.Component {
  static propTypes = {
    activeOrganization: PropTypes.object,
    createShipment: PropTypes.func.isRequired,
    createStatus: PropTypes.func,
    organizations: PropTypes.array,
    resetCreateForm: PropTypes.func.isRequired,
    shipperLocations: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  state = {
    data: {
      customer: "",
      assetID: "",
      shipmentID: "",
      trailerID: "",
      routeID: "",
      origin: STOP_INIT,
      destination: STOP_INIT,
      stops: [],
      selectedShipper: 0
    },
    locationOptions: [],
    tabIndex: 0
  };

  validation = {};

  constructor(props) {
    super(props);
    this.addStop = this.addStop.bind(this);
    this.checkStopValidation = this.checkStopValidation.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.removeStop = this.removeStop.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.reorderStop = this.reorderStop.bind(this);
    this.updateStop = this.updateStop.bind(this);
    this.updateField = this.updateField.bind(this);
    this.setSearchFilter = this.setSearchFilter.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { createStatus, resetCreateForm, shipperLocations } = this.props;

    if (shipperLocations !== prevProps.shipperLocations) {
      this.constructLocationLookup(shipperLocations);
    }

    if (createStatus && createStatus !== prevProps.createStatus) {
      if (createStatus !== "ERROR" && createStatus !== "DUPLICATE") {
        this.resetForm();
      }
      setTimeout(resetCreateForm, 3000);
    }
  }

  componentDidMount() {
    MixpanelUtils.track("Viewed Page: Shipments / Create");
  }

  setSearchFilter(label, category) {
    const { shipperLocations } = this.props;

    const filteredLocationIds = new Set();

    if (category === "name" || category === "everything") {
      const nameLocations = shipperLocations.filter(l => {
        return l.name && l.name.toLowerCase().includes(label.toLowerCase());
      });
      nameLocations.forEach(l => filteredLocationIds.add(l.id));
    }

    if (category === "address" || category === "everything") {
      const addressLocations = shipperLocations.filter(l => {
        return (
          l.address && l.address.toLowerCase().includes(label.toLowerCase())
        );
      });
      addressLocations.forEach(l => filteredLocationIds.add(l.id));
    }

    if (category === "city" || category === "everything") {
      const cityLocations = shipperLocations.filter(l => {
        return l.city && l.city.toLowerCase().includes(label.toLowerCase());
      });
      cityLocations.forEach(l => filteredLocationIds.add(l.id));
    }

    if (category === "state" || category === "everything") {
      const stateLocations = shipperLocations.filter(l => {
        return l.state && l.state.toLowerCase().includes(label.toLowerCase());
      });
      stateLocations.forEach(l => filteredLocationIds.add(l.id));
    }

    if (category === "zip" || category === "everything") {
      const zipLocations = shipperLocations.filter(l => {
        return (
          l.postal_code &&
          l.postal_code.toLowerCase().includes(label.toLowerCase())
        );
      });
      zipLocations.forEach(l => filteredLocationIds.add(l.id));
    }

    if (category === "locationCode" || category === "everything") {
      const codeLocations = shipperLocations.filter(l => {
        return l.code && l.code.toLowerCase().includes(label.toLowerCase());
      });
      codeLocations.forEach(l => filteredLocationIds.add(l.id));
    }

    this.setState({ filteredLocationIds: Array.from(filteredLocationIds) });
  }

  constructLocationLookup(locations) {
    const { t } = this.props;

    const sortedLocations = _.sortBy(locations, "name");

    this.setState(
      produce(draft => {
        draft.locationOptions = sortedLocations.map(l => {
          return {
            label: `${l.name} (${l.code})\n${l.address}\n${l.city}, ${l.state} ${l.postal_code}`,
            value: l.id,
            category: "location",
            categoryLabel: t("create-shipment:Location"),
            value2: l.time_zone
          };
        });
      })
    );
  }

  updateField(field, val) {
    const { organizations } = this.props;

    // special handling for the custoomer field
    // when a customer is selected, we need to
    /// get associated locations
    if (field === "customer") {
      const org = _.head(organizations.filter(o => o.fv_id === val));
      this.setState({ selectedShipper: org });
    }

    this.setState(
      produce(draft => {
        draft.data[field] = val;
      })
    );
  }

  updateStop(n, field, val) {
    this.setState(
      produce(draft => {
        if (n === 101) {
          draft.data.destination[field] = val;
        } else if (n === 100) {
          draft.data.origin[field] = val;
        } else {
          draft.data.stops[n][field] = val;
        }
      })
    );
  }

  addStop() {
    this.setState(
      produce(draft => {
        if (draft.tabIndex > draft.data.stops.length) {
          draft.tabIndex += 1;
        }
        draft.data.stops.push(STOP_INIT);
      })
    );
  }

  removeStop(n) {
    this.setState(
      produce(draft => {
        if (draft.tabIndex >= n + 1) {
          draft.tabIndex -= 1;
        }
        draft.data.stops.splice(n, 1);
      })
    );
  }

  reorderStop(from, to) {
    this.setState(
      produce(draft => {
        draft.tabIndex = to + 1;

        let stop = draft.data.stops[from];

        draft.data.stops.splice(from, 1);
        draft.data.stops.splice(to, 0, stop);
      })
    );
  }

  changeTab(n) {
    this.setState(
      produce(draft => {
        draft.tabIndex = n;
      })
    );
  }

  checkStopValidation(stops) {
    let isValid = true;

    stops.forEach(stop => {
      let v = checkValidation(stop);
      isValid = isValid && v;
    });

    return isValid;
  }

  resetForm() {
    this.setState(
      produce(draft => {
        draft.data = {
          customer: "",
          assetID: "",
          shipmentID: "",
          trailerID: "",
          routeID: "",
          origin: STOP_INIT,
          destination: STOP_INIT,
          stops: []
        };
        draft.tabIndex = 0;
      })
    );
  }

  isValid({ customer, shipmentID, origin, destination, stops }) {
    let origEarly = moment(origin.earlyArrival).tz(origin.timezone);
    let origLate = moment(origin.lateArrival).tz(origin.timezone);
    let destEarly = moment(destination.earlyArrival).tz(destination.timezone);
    let destLate = moment(destination.lateArrival).tz(destination.timezone);

    let stopsValid = [];

    stops.forEach((stop, i) => {
      let stopEarly = moment(stop.earlyArrival).tz(stop.timezone, true);
      let stopLate = moment(stop.lateArrival).tz(stop.timezone, true);

      stopsValid.push({
        isValidLoc: !_.isNil(stop.location),
        isValidEarly: stopEarly.isValid(),
        isValidLate: stopLate.isValid() && stopEarly.isBefore(stopLate),
        isValidStopType: !_.isNil(stop.stopType),
        isValidStopRole: !_.isNil(stop.stopRole),
        isValidAfterOrigin:
          origEarly.isValid() &&
          stopEarly.isValid() &&
          stopEarly.isAfter(origEarly),
        isValidBeforeDest:
          destEarly.isValid() &&
          stopEarly.isValid() &&
          stopEarly.isBefore(destEarly)
      });
    });

    return {
      info: {
        isValidCustomer: customer !== "",
        isValidShipmentId: shipmentID !== ""
      },
      origin: {
        isValidLoc: !_.isNil(origin.location),
        isValidStopType: !_.isNil(origin.stopType),
        isValidStopRole: !_.isNil(origin.stopRole),
        isValidEarly: origEarly.isValid(),
        isValidLate: origLate.isValid() && origEarly.isBefore(origLate)
      },
      destination: {
        isValidLoc: !_.isNil(destination.location),
        isValidStopType: !_.isNil(destination.stopType),
        isValidStopRole: !_.isNil(destination.stopRole),
        isValidEarly: destEarly && destEarly.isValid(),
        isValidLate:
          destLate &&
          destEarly &&
          destLate.isValid() &&
          destEarly.isBefore(destLate),
        isValidAfterOrigin:
          origEarly.isValid() &&
          destEarly.isValid() &&
          destEarly.isAfter(origEarly)
      },
      stops: stopsValid
    };
  }

  render() {
    const { data, locationOptions, tabIndex, selectedShipper } = this.state;
    const {
      activeOrganization,
      createStatus,
      createShipment,
      shipperLocations
    } = this.props;

    this.validation = this.isValid(data);
    return (
      <Section>
        <FlexColDiv
          css={{
            flex: 1,
            paddingLeft: "1em",
            paddingRight: "1em",
            paddingTop: "1em"
          }}
        >
          {createStatus && createStatus === "CREATED" ? (
            <Alert
              variant="success"
              style={{ display: "flex", flex: 1, paddingBottom: "2.25em" }}
            >
              New shipment has been created.
            </Alert>
          ) : null}
          {createStatus && createStatus === "ERROR" ? (
            <Alert
              variant="danger"
              style={{ display: "flex", flex: 1, paddingBottom: "2.25em" }}
            >
              Create shipment failed.
            </Alert>
          ) : null}
          {createStatus && createStatus === "DUPLICATE" ? (
            <Alert
              variant="danger"
              style={{ display: "flex", flex: 1, paddingBottom: "2.25em" }}
            >
              {`Shipment ${data.shipmentID} already exists.`}
            </Alert>
          ) : null}
          <ShipmentInfoForm data={data} updateField={this.updateField} />
          <ShipmentStopsForm
            addStop={this.addStop}
            changeTab={this.changeTab}
            data={data}
            locationOptions={locationOptions}
            removeStop={this.removeStop}
            reorderStop={this.reorderStop}
            tabIndex={tabIndex}
            updateStop={this.updateStop}
            validation={this.validation}
            setSearchFilter={this.setSearchFilter}
            selectedShipper={selectedShipper}
          />
        </FlexColDiv>
        <ShipmentFormControls
          data={data}
          checkStopValidation={this.checkStopValidation}
          createShipment={createShipment}
          locations={shipperLocations}
          resetForm={this.resetForm}
          validation={this.validation}
          organization={activeOrganization}
        />
      </Section>
    );
  }
}

export default withTranslation(["create-shipment"])(CreateShipmentView);
