import {
  getBasicQueryString,
  getEverythingQueryString
} from "../../components/search-bar/search-filter-query-strings";

export const SEARCH_CATEGORIES = [
  {
    queryKey: "everything",
    label: t => t("fv-vin-search:Search Everything"),
    placeholder: t =>
      t(
        "fv-vin-search:Search VIN, Shipment ID, Product Type, Origin, or Destination"
      ),
    queryBuilder: getEverythingQueryString,
    property: null
  },
  {
    queryKey: "entityId",
    label: t => t("fv-vin-search:VIN"),
    placeholder: t => t("fv-vin-search:Search VIN"),
    queryBuilder: getBasicQueryString,
    property: "vins"
  },
  {
    queryKey: "shipmentId",
    label: t => t("fv-vin-search:Shipment ID"),
    placeholder: t => t("fv-vin-search:Search Shipment ID"),
    queryBuilder: getBasicQueryString,
    property: "shipments"
  },
  {
    // H2-305 productType is now descriptionSearch for the search bar
    queryKey: "descriptionSearch",
    label: t => t("fv-vin-search:Product Type"),
    placeholder: t => t("fv-vin-search:Search Product Type"),
    queryBuilder: getBasicQueryString,
    property: "productTypes"
  },
  {
    queryKey: "origin",
    label: t => t("fv-vin-search:Origin"),
    placeholder: t => t("fv-vin-search:Search Origin"),
    queryBuilder: getBasicQueryString,
    property: "origins"
  },
  {
    queryKey: "destination",
    label: t => t("fv-vin-search:Destination"),
    placeholder: t => t("fv-vin-search:Search Destination"),
    queryBuilder: getBasicQueryString,
    property: "destinations"
  },
  {
    queryKey: "statusUpdateSearch",
    label: t => t("fv-vin-search:Last Milestone"),
    placeholder: t => t("fv-vin-search:Search Last Milestone"),
    queryBuilder: getBasicQueryString,
    property: null
  }
];
