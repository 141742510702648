import { createSelector } from "reselect";
import _ from "lodash";
import AdcSearchBarState from "./AdcSearchBarState";
import { AdcCapacity } from "./AdcCapacity";
import DomainDataState from "../domain-data/DomainDataState";

const { getEntities } = AdcSearchBarState.selectors;
const { getCountries } = DomainDataState.selectors;

const ciFieldGetter = (fieldName, defaultValue = "") => item =>
  _.get(item, fieldName, defaultValue).toLowerCase();

export const getAdcCountryFilterOptions = createSelector(
  getCountries,
  countries =>
    countries.map(country => ({
      label: country.name,
      value: country.code
    }))
);

export const getAdcLocationFilterOptions = createSelector(getEntities, adcs =>
  _.uniqBy(adcs, ciFieldGetter("displayLocation")).map(adc => ({
    label: adc.displayLocation,
    value: adc.displayLocation.toLowerCase()
  }))
);

// This 'selector' doesn't really look into store state, but it's implemented as
// a selector so we don't have to change downstream code if capacities are
// defined server-side someday.
export const getAdcCapacityFilterOptions = state =>
  AdcCapacity.enumValues
    .filter(cap => cap !== AdcCapacity.NA)
    .map(cap => {
      return {
        label: cap.label,
        value: cap.label
      };
    });
