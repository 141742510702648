import { createSelector } from "reselect";

import FinVehicleDomainDataState from "../domain-data/FinVehicleDomainData";
import { createOptionsMapper } from "../../utils/selectors-utils";

// Helpers
//
const locationsMapper = createOptionsMapper({
  labelKey: item => `${item.name} (${item.code})`,
  valueKey: "id"
});

const valuesMapper = createOptionsMapper({ sorted: false });

// Selectors
export const selectOriginFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.selectOriginsAlphabetically],
  locationsMapper
);

export const selectDestinationFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.selectDestinationsAlphabetically],
  locationsMapper
);

export const selectCarrierFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getCarriers],
  createOptionsMapper({
    labelKey: item => `${item.carrier_name} (${item.carrier_scac})`,
    valueKey: "carrier_id"
  })
);

export const selectHoldTypeFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.selectHoldTypesAlphabetically],
  createOptionsMapper({
    labelKey: item => `${item.name} (${item.reasonCode})`,
    valueKey: "id"
  })
);

export const selectExceptionTypeFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.selectExceptionTypesAlphabetically],
  createOptionsMapper({ labelKey: "name", valueKey: "id" })
);

export const selectSoldStatusFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getSoldStatuses],
  valuesMapper
);

export const selectProductTypeFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getProductTypes],
  valuesMapper
);

export const selectLifeCycleStateFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getLifeCycleStates],
  valuesMapper
);

export const selectOrderTypeFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getOrderTypes],
  valuesMapper
);

export const selectPartnerFilterOptions = createSelector(
  [FinVehicleDomainDataState.selectors.getPartners],
  createOptionsMapper({ labelKey: "org_name", valueKey: "fv_id" })
);
