import Colors from "../../styles/colors";
import {
  detailPanelColorForExceptionType,
  textColorForExceptionType
} from "../../modules/exceptions/ExceptionLink";

export const getExceptionTextColor = exception => {
  if (!exception) {
    return Colors.highlight.GREEN;
  } else {
    return textColorForExceptionType(exception);
  }
};

export const getExceptionFillColor = exception => {
  if (!exception) {
    return Colors.highlight.GREEN;
  } else {
    return detailPanelColorForExceptionType(exception);
  }
};
