/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import sizeMe from "react-sizeme";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { EventBar } from "../../shipment-detail/ShipmentDetailPanel";

class ShipmentTimelineContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showingLeftSide: true
    };

    this.handleArrowClick = this.handleArrowClick.bind(this);
  }

  handleArrowClick() {
    const { showingLeftSide } = this.state;
    this.setState({ showingLeftSide: !showingLeftSide });
  }
  render() {
    const { size, shipment, t } = this.props;
    const { showingLeftSide } = this.state;
    const width = size.width;
    const numLegs = shipment.shipment_stops.length - 1;
    const scrollThreshold = 4.2;
    const scroll = numLegs > scrollThreshold;
    const orientation = showingLeftSide ? { left: 10 } : { left: -width + 10 };
    const scrollStyles = scroll
      ? {
          overflowX: "hidden",
          overflowY: "hidden"
        }
      : {};
    return (
      <div
        css={{
          position: "relative",
          height: scroll ? "10em" : "100%",
          margin: "0 10px",
          ...scrollStyles
        }}
      >
        {scroll ? (
          <div
            css={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div onClick={this.handleArrowClick}>
              <IoIosArrowBack
                size="1.5em"
                style={{
                  display: showingLeftSide ? "none" : "inline",
                  cursor: showingLeftSide ? "none" : "pointer"
                }}
              />
            </div>
            <div onClick={this.handleArrowClick}>
              <IoIosArrowForward
                size="1.5em"
                style={{
                  display: !showingLeftSide ? "none" : "inline",
                  cursor: !showingLeftSide ? "none" : "pointer"
                }}
              />
            </div>
          </div>
        ) : null}
        <div
          css={{
            width: scroll ? (width * numLegs) / scrollThreshold : "100%",
            position: scroll ? "absolute" : null,
            ...orientation
          }}
        >
          <EventBar
            shipment={shipment}
            scrollSize={4}
            t={t}
            multimodal={true}
          />
        </div>
      </div>
    );
  }
}
export default sizeMe({ monitorHeight: true })(ShipmentTimelineContainer);
