/**
 * Implements MapPlatformInterface for Here Maps.
 */
import { color } from "d3-color";
import { scaleLinear } from "d3-scale";
import _ from "lodash";

import MapPlatformInterface from "../MapPlatformInterface";
import GeofenceType, {
  getBoundingBox,
  getType,
  getCenter,
  isFenceValid
} from "../../../geofence-edit/geofence-types";
import interpolateViridis from "./interpolate-viridis";
import HereMapsShapes from "./HereMapsShapes";

class HereMapPlatform extends MapPlatformInterface {
  static get name() {
    return "HERE";
  }

  constructor(baseMap) {
    super(baseMap);

    const { hereMaps } = this.props;
    this.shapes = new HereMapsShapes(hereMaps);

    this.onDragStart = this.onDragStart.bind(this);
    this.onDrag = this.onDrag.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  // Specific drag stuff for Here Maps
  onDragStart(e) {
    const {
      hereMaps,
      enableGeofenceBuilder,
      enableDraggingGeofence
    } = this.props;

    // Disable the default draggability of the underlying map
    // when starting to drag a marker object
    let target = e.target,
      pointer = e.currentPointer;

    if (
      enableGeofenceBuilder &&
      enableDraggingGeofence &&
      (target instanceof hereMaps.map.Marker ||
        target instanceof hereMaps.map.Polygon ||
        target instanceof hereMaps.map.Circle)
    ) {
      // Set original lat / lng
      const pos = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
      this.dragStartLat = pos.lat;
      this.dragLat = pos.lat;
      this.dragStartLng = pos.lng;
      this.dragLng = pos.lng;

      if (target instanceof hereMaps.map.Marker) {
        document.body.style.cursor = "grabbing";
      }
      this.hereMapsBehavior.disable();
    }
  }

  onDrag(e) {
    const {
      hereMaps,
      enableGeofenceBuilder,
      isTracing,
      enableDraggingGeofence
    } = this.props;
    let target = e.target;
    let pointer = e.currentPointer;

    if (
      !isTracing &&
      enableGeofenceBuilder &&
      enableDraggingGeofence &&
      (target instanceof hereMaps.map.Marker ||
        target instanceof hereMaps.map.Polygon ||
        target instanceof hereMaps.map.Circle)
    ) {
      const newPos = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
      if (target instanceof hereMaps.map.Marker) {
        target.setPosition(newPos);
      }

      // Update radial geofence
      if (target instanceof hereMaps.map.Circle) {
        this.updateCircle(newPos, target);
      }
      // Update polygonal geofence
      else {
        // Get the group this target belongs to and update every member's position.
        this.mapObjects[target.getData().groupId].forEach(c => {
          if (c instanceof hereMaps.map.Polygon) {
            this.updatePolygon(target, newPos, c);
          } else if (
            c instanceof hereMaps.map.Marker &&
            target instanceof hereMaps.map.Polygon
          ) {
            this.updateControlPoint(newPos, c);
          }
        });
      }

      this.dragLat = newPos.lat;
      this.dragLng = newPos.lng;
    }
  }

  onDragEnd(e) {
    const {
      hereMaps,
      onDragPolygonalGeofence,
      onDragRadialGeofence,
      onDragGeofenceControlPoint,
      enableGeofenceBuilder,
      enableDraggingGeofence
    } = this.props;

    // Re-enable the default draggability of the underlying map
    // when dragging has completed
    let target = e.target,
      pointer = e.currentPointer;
    if (enableGeofenceBuilder && enableDraggingGeofence) {
      if (target instanceof hereMaps.map.Marker) {
        const targetData = target.getData();
        onDragGeofenceControlPoint(
          Number(targetData.polygonIndex),
          Number(targetData.pointIndex),
          this.map.screenToGeo(pointer.viewportX, pointer.viewportY)
        );
        document.body.style.cursor = "grab";
      } else if (target instanceof hereMaps.map.Circle) {
        onDragRadialGeofence(
          this.dragStartLat - this.dragLat,
          this.dragStartLng - this.dragLng
        );
      } else if (target instanceof hereMaps.map.Polygon) {
        onDragPolygonalGeofence(
          Number(target.getData().polygonIndex),
          this.dragStartLat - this.dragLat,
          this.dragStartLng - this.dragLng
        );
      }
      this.hereMapsBehavior.enable();
    }
  }

  getHeatmapProvider() {
    const { hereMaps } = this.props;

    let provider = new hereMaps.datalens.RawDataProvider({
      dataToFeatures: data => {
        let features = [];
        JSON.parse(data).forEach(row => {
          let feature = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [Number(row.longitude), Number(row.latitude)]
            }
          };
          features.push(feature);
        });
        return features;
      },
      featuresToRows: (features, x, y, z, tileSize, helpers) => {
        let counts = {};
        for (let i = 0; i < features.length; i++) {
          let feature = features[i];
          let coordinates = feature.geometry.coordinates;
          let lng = coordinates[0];
          let lat = coordinates[1];

          let p = helpers.latLngToPixel(lat, lng, z, tileSize);
          let px = p[0];
          let py = p[1];
          let tx = px % tileSize;
          let ty = py % tileSize;
          let key = tx + "-" + ty;

          if (counts[key]) {
            counts[key] += 1;
          } else {
            counts[key] = 1;
          }
        }

        const keys = Object.keys(counts);
        const rows = keys.map(key => {
          let t = key.split("-");
          let tx = Number(t[0]);
          let ty = Number(t[1]);
          let count = counts[key];
          return { tx, ty, count, value: count };
        });

        return rows;
      }
    });

    return provider;
  }

  updateCircle(newPos, circle) {
    const movementLat = this.dragLat - newPos.lat;
    const movementLng = this.dragLng - newPos.lng;
    const currentPosition = circle.getCenter();
    circle.setCenter({
      lat: currentPosition.lat - movementLat,
      lng: currentPosition.lng - movementLng
    });
  }

  updateControlPoint(newPos, point) {
    const movementLat = this.dragLat - newPos.lat;
    const movementLng = this.dragLng - newPos.lng;
    const currentPosition = point.getPosition();
    point.setPosition({
      lat: currentPosition.lat - movementLat,
      lng: currentPosition.lng - movementLng
    });
  }

  // Methods to help keeping signatures simpler inside platform implementations
  get hereMapsBehavior() {
    return this.baseMap.hereMapsBehavior;
  }

  set hereMapsBehavior(data) {
    this.baseMap.hereMapsBehavior = data;
  }

  get hereMapsUI() {
    return this.baseMap.hereMapsUI;
  }

  set hereMapsUI(data) {
    this.baseMap.hereMapsUI = data;
  }

  // Initialization
  initMap(activeOrganization, mapTypeOverride) {
    const { hereMaps, hereMapsPlatform } = this.props;

    let defaultLayers = hereMapsPlatform.createDefaultLayers();

    this.map = new hereMaps.Map(this.mapDiv, defaultLayers.normal.map, {
      zoom: 5,
      center: { lat: 41.8625, lng: -87.6166 }
    });

    this.hereMapsBehavior = new hereMaps.mapevents.Behavior(
      new hereMaps.mapevents.MapEvents(this.map)
    );

    // Add map listeners
    this.map.addEventListener("dragstart", this.onDragStart, false);
    this.map.addEventListener("drag", this.onDrag, false);
    this.map.addEventListener("dragend", this.onDragEnd, false);
    this.map.addEventListener("pointermove", this.onMouseMove, false);
    this.map.addEventListener("tap", this.onTap, false);

    this.hereMapsUI = hereMaps.ui.UI.createDefault(this.map, defaultLayers);
    this.hereMapsUI.getControl("zoom").setAlignment("right-bottom");
    this.hereMapsUI.setUnitSystem(hereMaps.ui.UnitSystem.IMPERIAL);
  }

  initHeatMap(coords) {
    const { hereMaps, hereMapsPlatform } = this.props;

    // Set map to dark mode
    let defaultLayers = hereMapsPlatform.createDefaultLayers();
    this.map.setBaseLayer(defaultLayers.normal.basenight);

    let provider = this.getHeatmapProvider();

    function viridisWithAlpha(t) {
      let c = color(interpolateViridis(t));
      c.opacity = scaleLinear()
        .domain([0, 0.05, 1])
        .range([0, 1, 1])(t);
      return c + "";
    }

    // heatmap layer
    let layer = new hereMaps.datalens.HeatmapLayer(provider, {
      rowToTilePoint: row => {
        return {
          x: row.tx,
          y: row.ty,
          count: row.count,
          value: row.count
        };
      },
      // Lower bandwidth: Good for very dense routes
      // bandwidth: [{
      //   value: 1,
      //   zoom: 9
      // }, {
      //   value: 10,
      //   zoom:16
      // }],
      // Higher bandwidth: Good for less densely updated routes
      bandwidth: [
        {
          value: 1,
          zoom: 4
        },
        {
          value: 4,
          zoom: 16
        }
      ],
      valueRange: z => [0, 10 / Math.pow(z, 2)],
      countRange: [0, 0],
      opacity: 0.7, // 1 = 100%
      colorScale: viridisWithAlpha,
      aggregation: hereMaps.datalens.HeatmapLayer.Aggregation.SUM,
      inputScale: hereMaps.datalens.HeatmapLayer.InputScale.LINEAR
    });

    // If we have coordinates, display them
    if (coords && coords.length > 0) {
      provider.pushData(coords);
    }

    // add layer to map
    this.mapLayers["heatmap_layer"] = layer;
    this.map.addLayer(layer);
  }

  deinitHeatMap() {
    const { hereMapsPlatform } = this.props;

    let defaultLayers = hereMapsPlatform.createDefaultLayers();
    this.map.setBaseLayer(defaultLayers.normal.map);
  }

  // Events
  onTap(e) {
    const {
      hereMaps,
      isTracing,
      addPointToTrace,
      onPolygonDrawEnd
    } = this.props;

    if (isTracing) {
      // Tapped on a marker while tracing.
      if (e.target instanceof hereMaps.map.Marker) {
        const data = e.target.getData();
        if (data.pointIndex === 0) {
          onPolygonDrawEnd();
        }
      } else {
        // Didn't click on a marker, add a point here.
        const pointer = e.currentPointer;
        const pos = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
        addPointToTrace(pos);
      }
    }
  }

  onMouseMove(e) {
    const { hereMaps, enableGeofenceBuilder, isTracing } = this.props;

    if (isTracing) {
      const pointer = e.currentPointer;
      const pos = this.map.screenToGeo(pointer.viewportX, pointer.viewportY);
      this.setState({ mouseGeoCoords: pos });

      document.body.style.cursor = "default";

      if (e.target instanceof hereMaps.map.Marker) {
        const data = e.target.getData();
        if (data && data.pointIndex === 0) {
          // Hovering over the first point while tracing, show that clicking it will
          // complete the polygon.
          document.body.style.cursor = "crosshair";
        } else {
          // Tracing and hovering over nothing important, default icon.
          document.body.style.cursor = "default";
        }
      }
    } else if (
      e.target instanceof hereMaps.map.Marker &&
      enableGeofenceBuilder
    ) {
      const data = e.target.getData();
      if (data && !("lad" in data)) {
        document.body.style.cursor = "grab";
      }
    } else if (
      (e.target instanceof hereMaps.map.Polygon ||
        e.target instanceof hereMaps.map.Circle) &&
      enableGeofenceBuilder
    ) {
      document.body.style.cursor = "move";
    } else {
      document.body.style.cursor = "default";
    }
  }

  // Events Related
  addMapEventListener(eventName, callback) {
    const hereEventsTable = {
      mapviewchange: "mapviewchangeend"
    };
    this.map.addEventListener(hereEventsTable[eventName], callback);
  }

  addMapMarkerEventListener(marker, eventName, callback) {
    const hereEventsTable = {
      click: "tap",
      mouseenter: "pointerenter",
      mouseout: "pointerleave"
    };
    marker.addEventListener(hereEventsTable[eventName], callback);
  }

  // Drawing
  createAndAddMapMarker(
    name,
    pos,
    zIndex,
    data,
    isClickable,
    iconSvg,
    iconHeight,
    iconWidth,
    iconDefaultHeight,
    iconDefaultWidth,
    iconXOffsetForGoogle,
    iconYOffsetForGoogle,
    iconXAnchorForHERE,
    iconYAnchorForHERE
  ) {
    const { hereMaps } = this.props;

    const marker = new hereMaps.map.Marker(pos);

    if (zIndex) {
      marker.setZIndex(zIndex);
    }

    if (data) {
      marker.setData(data);
    }

    let icon = null;
    let iconSize = null;
    if (iconHeight && iconWidth) {
      iconSize = { h: iconHeight, w: iconWidth };
    }

    let iconAnchor = null;
    if (iconXAnchorForHERE && iconYAnchorForHERE) {
      iconAnchor = { x: iconXAnchorForHERE, y: iconYAnchorForHERE };
    }

    if (iconSvg) {
      if (iconSize && iconAnchor) {
        icon = new hereMaps.map.Icon(iconSvg, {
          size: iconSize,
          anchor: iconAnchor
        });
      } else if (iconSize) {
        icon = new hereMaps.map.Icon(iconSvg, {
          size: iconSize
        });
      } else if (iconAnchor) {
        icon = new hereMaps.map.Icon(iconSvg, {
          anchor: iconAnchor
        });
      } else {
        icon = new hereMaps.map.Icon(iconSvg);
      }

      if (icon) {
        marker.setIcon(icon);
      }
    }

    this.map.addObject(marker);
    this.mapObjects[name] = marker;

    return marker;
  }

  createAndAddMapPolyline(
    name,
    linestring,
    zIndex,
    lineWidth,
    lineJoin,
    hasArrows
  ) {
    const { hereMaps } = this.props;

    let routeLine = new hereMaps.map.Polyline(linestring, {
      style: { lineWidth: lineWidth, lineJoin: lineJoin },
      arrows: hasArrows
    });

    if (hasArrows === true) {
      const arrowStyle = new hereMaps.map.ArrowStyle({ frequency: 1 });
      routeLine.setArrows(arrowStyle);
    }

    routeLine.setZIndex(zIndex);

    this.map.addObjects([routeLine]);
    this.mapObjects[name] = routeLine;

    return routeLine;
  }

  createMapLineString() {
    const { hereMaps } = this.props;

    return new hereMaps.geo.LineString();
  }

  createAndAddMapInfoBubble(pos, content) {
    const { hereMaps } = this.props;

    // Improve positioning of bubble once heremaps puts the bubble over the
    // marker by default
    const bubbleXY = this.map.geoToScreen(pos);
    const bubble = new hereMaps.ui.InfoBubble(
      this.map.screenToGeo(bubbleXY.x + 30, bubbleXY.y - 20),
      {
        content
      }
    );
    this.hereMapsUI.addBubble(bubble);
  }

  addMapLineStringLatLong(linestring, lat, lng) {
    linestring.pushLatLngAlt(lat, lng);
  }

  drawGeofence(location, geofenceGroup, geofenceMapId, lad) {
    // Radial geofences groups are just the circle itself.
    if (getType(location.geofence) === GeofenceType.RADIAL) {
      this.map.addObject(geofenceGroup);
      this.mapObjects[geofenceMapId] = geofenceGroup;
    } else {
      // Polygon and MultiPolygon geofences are a list of groups: each one with
      // a polygon and control points.
      geofenceGroup.forEach((group, index) => {
        this.map.addObject(group);
        this.mapObjects[group.getData().groupId] = group;
      });
    }
  }

  addTraceGroup(traceGroup) {
    this.map.addObject(traceGroup);
    this.mapObjects[traceGroup.getData().groupId] = traceGroup;
  }

  // Clearing
  clearInfoBubbles() {
    let bubbles = this.hereMapsUI.getBubbles();

    if (!_.isEmpty(bubbles)) {
      bubbles.forEach(b => {
        this.hereMapsUI.removeBubble(b);
      });
    }
  }

  clearMap(excludeKeyPrefix = null) {
    // Clear objects
    if (!_.isNil(this.mapObjects) && !_.isEmpty(this.mapObjects)) {
      _.forOwn(this.mapObjects, (val, key) => {
        if (
          _.isNil(excludeKeyPrefix) ||
          excludeKeyPrefix === "" ||
          !key.includes(excludeKeyPrefix)
        ) {
          try {
            this.map.removeObject(val);
          } catch (ex) {}
        }
      });

      // H1-1577: Remove any objects still attached to map
      try {
        this.map.getObjects().forEach(o => {
          this.map.removeObject(o);
        });
      } catch (ex) {}
      this.mapObjects = {};
    }
    this.mapObjects = {};

    // Clear layers
    if (!_.isNil(this.mapLayers) && !_.isEmpty(this.mapLayers)) {
      _.forOwn(this.mapLayers, (val, key) => {
        this.map.removeLayer(val);
      });

      this.mapLayers = {};
    }

    this.mapLayers = {};
  }

  clearMapMarkers(prefix) {
    let delList = [];
    for (let key in this.mapObjects) {
      if (key.includes(prefix)) {
        this.map.removeObject(this.mapObjects[key]);
        delList.push(key);
      }
    }
    delList.forEach(value => {
      delete this.mapObjects[value];
    });
  }

  // Actions
  setMapViewBounds(boundsRect) {
    this.map.setViewBounds(boundsRect);
  }

  setMapCenter(position) {
    this.map.setCenter(position);
  }

  resizeMap() {
    if (this.map) {
      this.map.getViewPort().resize();
    }
  }

  zoomSingleLocation(location) {
    const { geofence } = location;

    if (isFenceValid(geofence)) {
      const { hereMaps } = this.props;
      const bounds = getBoundingBox(geofence);
      this.setMapViewBounds(new hereMaps.geo.Rect(...bounds));
      const centerPos = getCenter(geofence);
      this.setMapCenter(centerPos);
    } else {
      this.map.setZoom(11);
      this.setMapCenter({ lat: location.latitude, lng: location.longitude });
    }
  }

  zoomLocations(locations) {
    const points = locations.map(item => [item.longitude, item.latitude]);

    const poly = new this.shapes.api.map.Polyline(
      this.shapes.lineString(points)
    );
    this.map.setViewBounds(poly.getBounds());
  }

  updatePolygon(target, newPos, polygon) {
    const { hereMaps } = this.props;

    let newLatLngArray = [];
    let geometry = polygon.getGeometry();
    const latLngArray = geometry.getExterior().getLatLngAltArray();

    // If a control point is being dragged, update that point. Otherwise move the entire polygon
    if (target instanceof hereMaps.map.Polygon) {
      const movementLat = this.dragLat - newPos.lat;
      const movementLng = this.dragLng - newPos.lng;

      newLatLngArray = latLngArray.map((l, i) => {
        if (i % 3 === 0) {
          return l - movementLat;
        } else if (i % 3 === 1) {
          return l - movementLng;
        } else {
          return l;
        }
      });
    } else {
      const coords = _.uniqBy(convertLatLngArrayToCoords(latLngArray), item =>
        JSON.stringify(item)
      );
      const index = getControlPointIndex(coords, newPos.lat, newPos.lng);
      coords[index] = [newPos.lng, newPos.lat];
      newLatLngArray = convertCoordsToArray(coords);
    }

    geometry
      .getExterior()
      .spliceLatLngAlts(0, latLngArray.length, newLatLngArray);
    polygon.setGeometry(geometry);
  }

  mergeBounds(bounds1, bounds2) {
    return bounds1.mergeRect(bounds2);
  }

  removeMapObject(name) {
    this.map.removeObject(this.mapObjects[name]);
  }

  // Helpers
  isMapViewBoundsContainPoint(pos) {
    const bounds = this.map.getViewBounds();
    return bounds.containsPoint(pos);
  }

  isMapViewBoundsContainLatLng(lat, lng) {
    const bounds = this.map.getViewBounds();
    return bounds.containsLatLng(lat, lng);
  }

  getBoundsRect(pos, boundsBuffer = 0.01) {
    const { hereMaps } = this.props;

    return new hereMaps.geo.Rect(
      pos.lat - boundsBuffer,
      pos.lng - boundsBuffer,
      pos.lat + boundsBuffer,
      pos.lng + boundsBuffer
    );
  }
}

const convertLatLngArrayToCoords = arr => {
  let coords = [];
  arr.forEach((l, i) => {
    if (i % 3 === 0) {
      coords.push([arr[i + 1], l]);
    }
  });

  return coords;
};

const convertCoordsToArray = coords => {
  let latLngArray = [];
  coords.forEach(c => {
    latLngArray.push(c[1]);
    latLngArray.push(c[0]);
    latLngArray.push(100);
  });

  return latLngArray;
};

const getControlPointIndex = (coords, lat, lng) => {
  let index = 0;
  let distance = 9999;
  coords.forEach((c, i) => {
    const dist = Math.sqrt(Math.pow(c[0] - lng, 2) + Math.pow(c[1] - lat, 2));
    if (dist <= distance) {
      index = i;
      distance = dist;
    }
  });

  return index;
};

export default HereMapPlatform;
