import _ from "lodash";

export const DEFAULT_GEOCODING = {
  City: "-",
  Country: "-",
  County: "-",
  District: "-",
  Label: "-",
  PostalCode: "-",
  State: "-"
};

export function geocode(
  activeOrganization,
  mapTypeOverride,
  hereMapsPlatform,
  googleMaps,
  params,
  resultCallback,
  errorCallback
) {
  const address = `${params.address}, ${params.city}, ${params.state} ${params.postal_code}, ${params.country}`;

  if (
    mapTypeOverride === "Here" ||
    (!mapTypeOverride && activeOrganization.map_type === "Here")
  ) {
    const geocoder = hereMapsPlatform.getGeocodingService();

    geocoder.geocode(
      {
        searchtext: address
      },
      result => {
        const geoPoint = _.get(
          result,
          "Response.View[0].Result[0].Location.DisplayPosition"
        );
        if (!_.isUndefined(geoPoint)) {
          const { Latitude, Longitude } = geoPoint;
          resultCallback({ lat: Latitude, lng: Longitude });
        } else {
          resultCallback({});
        }
      },
      err => errorCallback(err)
    );
  } else {
    let geocoder = new googleMaps.Geocoder();
    geocoder.geocode({ address: address }, function(results, status) {
      if (status === "OK") {
        if (results && results.length > 0) {
          let lat = results[0].geometry.location.lat();
          let lng = results[0].geometry.location.lng();
          resultCallback({ lat: lat, lng: lng });
        } else {
          resultCallback({});
        }
      } else {
        errorCallback(results);
      }
    });
  }
}

export function reverseGeocode(
  activeOrganization,
  mapTypeOverride,
  hereMapsPlatform,
  googleMaps,
  lat,
  lng,
  resultCallback,
  errorCallback
) {
  if (
    mapTypeOverride === "Here" ||
    (!mapTypeOverride && activeOrganization.map_type === "Here")
  ) {
    let params = {
      prox: `${lat},${lng},150`,
      mode: "retrieveAddresses",
      maxresults: 1
    };

    let geocoder = hereMapsPlatform.getGeocodingService();
    geocoder.reverseGeocode(params, resultCallback, errorCallback);
  } else {
    let latLng = new googleMaps.LatLng(lat, lng);

    let geocoder = new googleMaps.Geocoder();
    geocoder.geocode({ location: latLng }, function(results, status) {
      if (status === "OK") {
        resultCallback(results);
      } else {
        errorCallback(results);
      }
    });
  }
}
