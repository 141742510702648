import axios from "axios";
import _ from "lodash";

import { buildSearchLocationOptions } from "../location-search/LocationSearchStateExtensions";
import buildSavedSearchState from "../../components/saved-search/SavedSearchStateBuilder";
import { SEARCH_CATEGORIES } from "./ShipmentSearchCategoryDefs";
import { FILTERS } from "./ShipmentSearchFilterDefs";
import apiUrl from "../../api-url";
import SearchBarState from "./ShipmentSearchBarState";

const STORE_MOUNT_POINT = "shipmentSavedSearch";

// Preload origin/destination locations using their saved search ID values
const fetchSavedSearchLocations = (filterKey, locationValues) => {
  return dispatch => {
    const url = apiUrl("/location/locations");
    const locationIds = locationValues.map(val => val.id || val);
    const locationUrls = locationIds.map(id => axios.get(`${url}/${id}`));

    // Fetch details using the saved search origin/destination IDs
    return Promise.all(locationUrls)
      .then(responses => {
        const locations = responses.map(response =>
          response && response.data ? response.data : {}
        );
        const filterValues = buildSearchLocationOptions(locations);

        // Replace the search filter selected IDs with the new label/value objects
        dispatch(
          SearchBarState.actionCreators.setSearchFilter(filterKey, filterValues)
        );
      })
      .catch(err => {
        console.error(err);
      });
  };
};

const SavedSearchState = buildSavedSearchState({
  topic: STORE_MOUNT_POINT,
  savedSearchUrl: apiUrl("/preferences-ng/search-history"),
  searchType: "SHIPMENT",
  searchCategories: SEARCH_CATEGORIES,
  actions: {
    clearSearchFilters: () =>
      SearchBarState.actionCreators.clearSearchFilters(),
    setSearchCategory: category =>
      SearchBarState.actionCreators.setSearchCategory(category),
    setSearchText: searchText =>
      SearchBarState.actionCreators.setSearchText(searchText),
    setSearchFilter: (key, value) =>
      SearchBarState.actionCreators.setSearchFilter(key, value)
  }
});

/**
 * The way shipments load saved searches is pretty specific for this case in
 * several manners, that's why we defined to change the whole method. What is
 * different is because it:
 *
 * 1. Handles loading data for origin/destination fields;
 * 2. Performs search and go to /search page when preventRedirect is false
 *
 */
const loadSavedSearch = (savedSearch, preventRedirect = false) => {
  return (dispatch, getState) => {
    const {
      setSearchCategory,
      setSearchText,
      setSearchFilter,
      searchEntities,
      clearSearchFilters
    } = SearchBarState.actionCreators;

    const {
      searchCategory,
      searchObj,
      searchFilters
    } = SavedSearchState.helpers.getSearchComponents(savedSearch.search);

    // Clear the search
    dispatch(clearSearchFilters());

    // Set search category and value
    if (searchObj != null) {
      dispatch(setSearchCategory(searchCategory));
      dispatch(setSearchText(searchObj.value));
    }

    Object.keys(searchFilters).forEach(k => {
      const selectedFilters =
        typeof searchFilters[k] === "number"
          ? [searchFilters[k]]
          : searchFilters[k];

      dispatch(setSearchFilter(k, selectedFilters));

      // H1-1866: Fetch full location details for origin/destination values
      const filterDef = _.find(FILTERS, { queryKey: k });
      if (filterDef && filterDef.valuesLoader) {
        dispatch(filterDef.valuesLoader(k, selectedFilters));
      }
    });

    // Perform search and navigate to search view
    if (!preventRedirect) {
      dispatch(
        searchEntities(
          searchObj || { label: "", value: "" },
          searchCategory,
          searchFilters
        )
      );
      dispatch({ type: "SHIPMENT_SEARCH" });
    }
  };
};

SavedSearchState.actionCreators = {
  ...SavedSearchState.actionCreators,
  fetchSavedSearchLocations,
  loadSavedSearch
};

export default SavedSearchState;
