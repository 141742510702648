/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";
import styled from "@emotion/styled";
import ReactTable from "react-table";
import Loader from "react-loader";
import { withTranslation, useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdClose } from "react-icons/io";

import DownloadDataButton from "../../components/saved-search/DownloadDataButton";
import { isValidFilter } from "../../components/search-bar/search-filter-query-strings";
import SearchBarContainer from "./ShipmentSearchBarContainer";
import FiltersContainer from "./ShipmentSearchFiltersContainer";
import { SEARCH_CATEGORIES } from "./ShipmentSearchCategoryDefs";
import EditSavedSearchModalContainer from "../shipment-search/ShipmentEditSavedSearchModalContainer";
import { getColumns } from "./ShipmentSearchColumns";

import { datatableThStyles } from "../../styles/table-styles";
import Colors from "../../styles/colors";
import { useIsMediumAndDown } from "../../components/responsive";
import MixpanelUtils from "../../trackers/mixpanel";
import { scrollToTableTop } from "../../utils/table-utils";

const Section = styled.section({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "1em",
  backgroundColor: "white"
});

const SearchResultsTable = ({
  totalPages,
  page,
  pageSize,
  shipments,
  columns,
  isLoading,
  tableTrProps,
  setPagination,
  setSort,
  solutionId
}) => {
  const { t } = useTranslation("shipment-search");
  const isMobile = useIsMediumAndDown();
  let tableClasses = ["customtable"];
  if (!isMobile) {
    tableClasses.push("fixedpaginationtable");
  }
  return (
    <ReactTable
      className={tableClasses.join(" ")}
      manual
      pages={totalPages}
      page={page}
      pageSize={pageSize}
      onPageChange={newPage => {
        setPagination(solutionId, newPage, pageSize);
        scrollToTableTop();
      }}
      onPageSizeChange={newPageSize =>
        setPagination(solutionId, 0, newPageSize)
      }
      onSortedChange={newSorted =>
        setSort(solutionId, newSorted[0].id, newSorted[0].desc)
      }
      data={shipments}
      columns={columns}
      loading={isLoading}
      getFooterProps={() => ({
        // XXX: It is not working, I still don't know why. It should add
        // the data-qa attribute but it is not being created :(
        "data-qa": "footer-table-search-results"
      })}
      getTheadThProps={() => ({
        style: datatableThStyles,
        "data-qa": "header-table-search-results"
      })}
      getTrProps={tableTrProps}
      defaultSorted={[
        {
          id: "destination_earliest_arrival",
          desc: false
        }
      ]}
      previousText={t("shipment-search:Previous")}
      nextText={t("shipment-search:Next")}
      loadingText={`${t("shipment-search:Loading")}...`}
      noDataText={t("shipment-search:No rows found")}
      pageText={t("shipment-search:Page")}
      ofText={t("shipment-search:of")}
      rowsText={t("shipment-search:rows")}
      pageJumpText={t("shipment-search:jump to page")}
      rowsSelectorText={t("shipment-search:rows per page")}
    />
  );
};

class ShipmentSearchView extends Component {
  static propTypes = {
    exportAllShipments: PropTypes.func.isRequired,
    isExporting: PropTypes.bool,
    isLoading: PropTypes.bool,
    lads: PropTypes.array,
    page: PropTypes.number,
    pageSize: PropTypes.number,
    pushShipmentDetailView: PropTypes.func,
    resetSearch: PropTypes.func.isRequired,
    searchCategory: PropTypes.object,
    searchFilters: PropTypes.object,
    searchObj: PropTypes.object,
    setPagination: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    shipments: PropTypes.any,
    showFilters: PropTypes.any,
    solutionId: PropTypes.any,
    t: PropTypes.func.isRequired,
    toggleShowFilters: PropTypes.func.isRequired,
    totalPages: PropTypes.number,
    totalShipments: PropTypes.number
  };

  state = {
    resultsString: "",
    showEditSavedSearchModal: false
  };

  componentDidMount() {
    MixpanelUtils.track("Viewed Page: Shipments / Search");
  }

  componentDidUpdate(prevProps) {
    // When finished loading a search, update the results string
    if (prevProps.isLoading && !this.props.isLoading) {
      this.updateResultsString(this.props);
    }
  }

  updateResultsString(props) {
    const {
      totalShipments,
      searchObj,
      searchCategory,
      searchFilters,
      t
    } = props;

    let numFilters = 0;
    Object.keys(searchFilters).forEach(f => {
      const filterValue = searchFilters[f];
      if (isValidFilter(filterValue)) {
        numFilters++;
      }
    });

    const resultsString = (
      <div
        css={{
          flexDirection: "row",
          color: Colors.text.GRAY,
          marginTop: "0.5em",
          marginBottom: "0.5em"
        }}
      >
        {t("shipment-search:Viewing")}{" "}
        <span
          css={{ color: Colors.background.GRAY_BLUE, fontWeight: "bold" }}
          data-qa="total-search-results"
        >{`${totalShipments} ${t("shipment-search:shipments")}`}</span>
        {searchObj && searchObj.label && ` ${t("shipment-search:for")} `}
        {searchObj && searchObj.label && (
          <span
            css={{ color: Colors.background.GRAY_BLUE, fontWeight: "bold" }}
          >{`${SEARCH_CATEGORIES[searchCategory].label}: ${searchObj.label}`}</span>
        )}
        {numFilters > 0 && ` ${t("shipment-search:with")} `}
        {numFilters > 0 && (
          <span
            css={{ color: Colors.background.GRAY_BLUE, fontWeight: "bold" }}
          >{`${numFilters} ${
            numFilters > 1
              ? t("shipment-search:filters")
              : t("shipment-search:filter")
          }`}</span>
        )}
      </div>
    );

    this.setState({ resultsString });
  }

  render() {
    const {
      shipments,
      lads,
      isLoading,
      isExporting,
      page,
      pageSize,
      totalPages,
      setPagination,
      setSort,
      resetSearch,
      exportAllShipments,
      showFilters,
      toggleShowFilters,
      solutionId,
      t
    } = this.props;
    const { resultsString, showEditSavedSearchModal } = this.state;
    const columns = getColumns(lads, t);

    const tableTrProps = (state, rowInfo, column, instance) => {
      return {
        onClick: e => {
          const shipmentID = rowInfo.original.id;
          this.props.pushShipmentDetailView(shipmentID);
        },
        style: { cursor: "pointer" },
        "data-qa": "rows-search-results"
      };
    };

    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div css={{ marginTop: "1em", marginLeft: "1em", marginRight: "1em" }}>
          <SearchBarContainer
            isShowingFilters={showFilters}
            toggleShowFilters={() => toggleShowFilters(!showFilters)}
          />
          <FiltersContainer
            show={showFilters}
            backgroundColor={Colors.background.GRAY}
            sectionStyle={{ paddingRight: "12em" }}
          />
        </div>
        <Section>
          <Loader loaded={!isExporting} />
          <ToastContainer
            position="top-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable={false}
            pauseOnHover
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap"
            }}
          >
            {resultsString}
            {resultsString && (
              <div
                css={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                <div
                  css={{
                    color: Colors.text.GRAY,
                    marginLeft: "1em",
                    fontStyle: "italic",
                    textDecoration: "underline",
                    ":hover": {
                      cursor: "pointer",
                      color: Colors.background.DARK_BLUE
                    }
                  }}
                  onClick={() => resetSearch()}
                  data-qa="button-search-results-reset"
                >
                  {t("shipment-search:Reset")}{" "}
                  <IoMdClose
                    style={{
                      borderWidth: "1px",
                      borderStyle: "solid",
                      padding: "2px",
                      borderRadius: "1em",
                      marginLeft: "0.25em"
                    }}
                  />
                </div>
                <div
                  css={{
                    width: "1px",
                    height: "2em",
                    marginLeft: "1em",
                    backgroundColor: Colors.background.LIGHT_GRAY
                  }}
                />
                <div
                  css={{
                    color: Colors.text.GRAY,
                    marginLeft: "1em",
                    fontStyle: "italic",
                    textDecoration: "underline",
                    ":hover": {
                      cursor: "pointer",
                      color: Colors.background.DARK_BLUE
                    }
                  }}
                  onClick={() =>
                    this.setState({ showEditSavedSearchModal: true })
                  }
                  data-qa="button-save-this-search"
                >
                  {t("shipment-search:Save this search")}
                </div>
                <div
                  css={{
                    width: "1px",
                    height: "2em",
                    marginLeft: "1em",
                    backgroundColor: Colors.background.LIGHT_GRAY
                  }}
                />
                <DownloadDataButton
                  onClick={() => exportAllShipments(t)}
                  style={{
                    fontSize: "14",
                    color: Colors.text.GRAY,
                    marginLeft: "1em",
                    ":hover": {
                      cursor: "pointer",
                      color: Colors.background.DARK_BLUE
                    }
                  }}
                />
              </div>
            )}
          </div>
          <SearchResultsTable
            totalPages={totalPages}
            page={page}
            pageSize={pageSize}
            shipments={shipments}
            columns={columns}
            isLoading={isLoading}
            tableTrProps={tableTrProps}
            setPagination={setPagination}
            setSort={setSort}
            solutionId={solutionId}
          />
          <EditSavedSearchModalContainer
            show={showEditSavedSearchModal}
            hide={() => this.setState({ showEditSavedSearchModal: false })}
            preventSearchOnChange={true}
          />
        </Section>
      </div>
    );
  }
}

export default withTranslation(["shipment-search"])(ShipmentSearchView);
