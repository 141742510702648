/** @jsx jsx */
import PropTypes from "prop-types";
import { Component } from "react";
import { jsx } from "@emotion/core";
import Loader from "react-loader";
import { Alert } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import SearchBarContainer from "../mt-search/MetalTrackingSearchBarContainer";
import FilterSectionContainer from "../mt-search/MetalTrackingFilterSectionContainer";
import SavedSearchContainer from "../mt-search/MetalTrackingSavedSearchContainer";
import ResultsTable from "../../components/tables/ResultsTable";
import Colors from "../../styles/colors";
import { resultsTableColumns } from "../mt-dashboard/mt-dashboard-components/results-table-columns";
import DataHeader from "../mt-dashboard/mt-dashboard-components/DataHeader";
import MixpanelUtils from "../../trackers/mixpanel";

class MetalTrackingSearchResultsView extends Component {
  static propTypes = {
    authorization: PropTypes.object.isRequired,
    chooseLocation: PropTypes.any,
    exportFailed: PropTypes.any,
    isExporting: PropTypes.any,
    isLoading: PropTypes.bool,
    loadSavedSearch: PropTypes.func.isRequired,
    page: PropTypes.number,
    pushRackDetailView: PropTypes.any,
    savedSearch: PropTypes.any,
    searchResults: PropTypes.array,
    setPagination: PropTypes.func.isRequired,
    showFilters: PropTypes.bool.isRequired,
    solutionId: PropTypes.any,
    t: PropTypes.func.isRequired,
    toggleShowFilters: PropTypes.func,
    toggleWatchedRackFlag: PropTypes.func.isRequired,
    totalPages: PropTypes.number.isRequired
  };

  state = {
    // If the user adds a filter to a search, or edits it in any way, consider this
    // a new search which could be saved.
    searchParamsChanged: false,
    savedSearch: this.props.savedSearch,
    showDeleteSavedSearchModal: false
  };

  constructor(props) {
    super(props);
    this.rowClickHandler = this.rowClickHandler.bind(this);
  }

  componentDidMount() {
    const { savedSearch, loadSavedSearch } = this.props;

    // If a saved search was previously selected, automatically preload its query values
    if (savedSearch) {
      loadSavedSearch(savedSearch);
    }

    MixpanelUtils.track("Viewed Page: Metal / Search");
  }

  rowClickHandler(rowInfo) {
    this.props.pushRackDetailView(rowInfo.original.id);
  }

  render() {
    const {
      authorization,
      searchResults,
      isLoading,
      showFilters,
      toggleShowFilters,
      toggleWatchedRackFlag,
      chooseLocation,
      page,
      totalPages,
      setPagination,
      solutionId,
      isExporting,
      exportFailed,
      t
    } = this.props;

    const searchData = isLoading ? [] : searchResults;

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#fff"
        }}
      >
        <Loader loaded={!isExporting} />
        {exportFailed ? (
          <Alert
            variant="danger"
            style={{ display: "flex", flex: 1, paddingBottom: "2.25em" }}
          >
            Export Failed.
          </Alert>
        ) : null}
        <div
          css={{
            display: "flex",
            flexDirection: "column",
            padding: "20px 20px 0 20px"
          }}
        >
          <SearchBarContainer
            isShowingFilters={showFilters}
            toggleShowFilters={() => toggleShowFilters(!showFilters)}
          />
          <FilterSectionContainer
            show={showFilters}
            backgroundColor={Colors.background.GRAY}
          />
        </div>
        <div
          css={{
            backgroundColor: "#fff",
            padding: "0px 20px 20px 20px"
          }}
        >
          <div
            css={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <DataHeader
              title={t("mt-search-results:Racks")}
              total={searchResults.length || 0}
            />
            <SavedSearchContainer />
          </div>
          <ResultsTable
            manual={false}
            rowClickHandler={this.rowClickHandler}
            data={searchData}
            columns={() =>
              resultsTableColumns(
                t,
                solutionId,
                authorization,
                toggleWatchedRackFlag,
                chooseLocation
              )
            }
            isLoading={isLoading}
            page={page}
            pageSize={100000} // H1-1445: Set page size manually
            totalPages={totalPages}
            setPagination={setPagination}
            fixPaginationToBottom={false}
            solutionId={solutionId}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation(["mt-search-results"])(
  MetalTrackingSearchResultsView
);
