/** @jsx jsx */
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";
import { AiFillFire } from 'react-icons/ai';

export default props => {
  const { onToggle, toggled } = props;
  const ButtonWrapper = styled.div({
    position: "absolute",
    top: 5,
    right: 0,
    zIndex: 2,
    transition: "color .3s",
    color: toggled ? "darkorange" : "black",
    ":hover": {
      cursor: "pointer",
      color: "darkorange"
    }
  });

  return (
    <ButtonWrapper toggled={toggled} onClick={onToggle}>
      <AiFillFire size="40px" />
    </ButtonWrapper>
  );
};
