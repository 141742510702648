// Displays a table of Watched VINs, and allows user to watch or unwatch each VIN

/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Loader from "react-loader";
import FinVehicleEntitiesState from "../FinVehicleEntitiesState";
import FinVehicleEntityDetailsState from "../../fv-vin-details/FinVehicleEntityDetailsState";
import { getSolutionId } from "../../organizations/OrganizationsState";
import ResultsTable from "../../../components/tables/ResultsTable";
import DwellTimeCell from "../../../components/tables/Cell/DwellTimeCell";

export const WatchedVinsTable = props => {
  const {
    t,
    watchedVinArray,
    watchedVinsLoading,
    solutionId,
    fetchWatchedVins
  } = props;
  const [unwatchedVins, setUnwatchedVins] = useState([]);
  let unwatchTimers = {};

  useEffect(() => {
    fetchWatchedVins(solutionId);
  }, [solutionId, fetchWatchedVins]);

  // Render unchecked VIN rows with dimmed style
  const cellOpacity = dim => (dim ? 0.25 : 1);
  const CellRenderer = cellInfo => {
    const dim = unwatchedVins.includes(cellInfo.original.id);
    return <div css={{ opacity: cellOpacity(dim) }}>{cellInfo.value}</div>;
  };

  const tableColumns = (sortKey, sortDir) => {
    return [
      {
        Header: t("fv-dashboard:Watch"),
        accessor: "watch",
        width: 80,
        Cell: cellInfo => {
          const vin = cellInfo.original.id;
          const checked = !unwatchedVins.includes(vin);

          return (
            <div css={{ opacity: cellOpacity(!checked) }}>
              <input
                type="checkbox"
                value={vin}
                checked={checked}
                onChange={e => {
                  toggleWatch(vin);
                }}
              />
            </div>
          );
        }
      },
      {
        Header: t("fv-dashboard:VIN"),
        accessor: "id",
        Cell: cellInfo => {
          const dim = unwatchedVins.includes(cellInfo.original.id);

          return (
            <div
              style={{
                fontWeight: 600,
                opacity: cellOpacity(dim)
              }}
            >
              {cellInfo.original.id}
            </div>
          );
        }
      },
      {
        Header: t("fv-dashboard:Active Shipment"),
        accessor: "activeTripLegId",
        Cell: CellRenderer
      },
      {
        Header: t("fv-dashboard:Carrier"),
        accessor: "activeCarrierName",
        Cell: CellRenderer
      },
      {
        Header: t("fv-dashboard:Mode"),
        accessor: "activeTransportMode",
        Cell: CellRenderer
      },
      {
        Header: t("fv-dashboard:Dwell Time"),
        accessor: "currentDwellSeconds",
        Cell: row => <DwellTimeCell currentDwell={row.original.currentDwell} />
      }
    ];
  };

  const cellClickHandler = (state, rowInfo, column, instance) => {
    const { setSelectedEntityId } = props;

    // Prevent navigation if clicking in "unwatch" checkbox cell
    if (column.id === "watch") {
      return;
    }

    // Navigate to VIN Details when clicking row
    setSelectedEntityId(rowInfo.row.id);
  };

  const toggleWatch = vin => {
    const { setWatchEntity, fetchWatchedVins, solutionId } = props;
    let newUnwatchedVins = [...unwatchedVins];

    // If the table is pending a refresh, cancel it
    if (unwatchTimers[vin]) {
      clearTimeout(unwatchTimers[vin]);
    }

    if (newUnwatchedVins.includes(vin)) {
      // VIN checkbox has already been unchecked - re-watch it
      newUnwatchedVins = newUnwatchedVins.filter(v => v !== vin);
      setWatchEntity(solutionId, vin, true);
    } else {
      // VIN checkbox is checked - unwatch it
      newUnwatchedVins.push(vin);
      setWatchEntity(solutionId, vin, false);
    }

    setUnwatchedVins(newUnwatchedVins);

    // Refresh the table after delay (gives the user time to undo a click)
    unwatchTimers[vin] = setTimeout(() => {
      fetchWatchedVins(solutionId);
    }, 2000);
  };

  return (
    <div>
      <h3 css={{ fontSize: 16 }}>{t("fv-dashboard:Watched VINs")}</h3>
      <div css={{ position: "relative", minHeight: 54 }}>
        <Loader loaded={!watchedVinsLoading}>
          <ResultsTable
            data={watchedVinArray}
            columns={tableColumns}
            cellClickHandler={cellClickHandler}
            cellCursorPointerEnabled={true}
            fixPaginationToBottom={false}
          />
        </Loader>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const {
    getWatchedVins,
    getWatchedVinsLoading
  } = FinVehicleEntitiesState.selectors;

  return {
    solutionId: getSolutionId(state),
    watchedVinArray: getWatchedVins(state),
    watchedVinsLoading: getWatchedVinsLoading(state)
  };
};

const mapDispatchToProps = dispatch => {
  const { fetchWatchedVins } = FinVehicleEntitiesState.actionCreators;
  const { setWatchEntity } = FinVehicleEntityDetailsState.actionCreators;

  return {
    fetchWatchedVins: solutionId => dispatch(fetchWatchedVins(solutionId)),
    setWatchEntity: (solutionId, entityId, watch) =>
      dispatch(setWatchEntity(solutionId, entityId, watch)),
    setSelectedEntityId: entityId =>
      dispatch({ type: "VIN_DETAILS", payload: { entity_id: entityId } })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["fv-dashboard"])(WatchedVinsTable));
