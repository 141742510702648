/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Button } from "react-bootstrap";
import Colors from "../../../styles/colors";
import ApiLogsFilterSectionContainer from "./ApiLogsFilterSectionContainer";
import {
  useApiLogs,
  useDefaultFilters,
  useInitialSearch
} from "./ApiLogsHooks";

const formCss = {
  display: "flex",
  flexWrap: "wrap",
  backgroundColor: Colors.background.GRAY,
  margin: "-1.5em 0 0.5em 0"
};

const filtersCss = {
  backgroundColor: Colors.background.GRAY,
  paddingTop: "0.5em"
};

const buttonCss = {
  height: "3em",
  margin: "0.5em",
  padding: "0 2em",
  backgroundColor: Colors.background.DARK_GRAY,
  color: "white"
};

// Search filters and search button displayed on API Logs view
const ApiLogsSearchBar = props => {
  const { t, searchEntities } = useApiLogs();
  useDefaultFilters();
  useInitialSearch(searchEntities);

  const handleSubmit = event => {
    event.preventDefault();
    searchEntities();
  };

  return (
    <form css={formCss} onSubmit={handleSubmit}>
      <div css={{ flexGrow: 1 }}>
        <ApiLogsFilterSectionContainer show={true} sectionStyle={filtersCss} />
      </div>
      <Button type="submit" style={buttonCss} data-qa="button-search">
        {t("documentation:Search")}
      </Button>
    </form>
  );
};

export default ApiLogsSearchBar;
