// eslint-disable-next-line
import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import Colors from "../../../styles/colors";
import { IoMdTimer } from "react-icons/io";

/**
 *
 * @param currentDwell
 * @return {*}
 * @constructor
 */
const DwellTimeCell = ({ currentDwell }) => {
  const { dwellTimeSeconds, sla } = currentDwell;
  const dwellTime = moment.duration(dwellTimeSeconds, "seconds");
  const prepTimeComponent = (component, label) =>
    component ? `${component.toString()}${label} ` : "";

  /**
   * Time component & label pairs sent to prepTimeComponent
   * before being assigned to the corresponding variable.
   * Will result in formatted strings that are empty if the
   * time component is zero. A few examples: 1d 2h 3min, 5h 27min, 1d 23min, 0min
   */
  const [days, hours, minutes] = [
    [dwellTime.days(), "d"],
    [dwellTime.hours(), "h"],
    [dwellTime.minutes(), "min"]
  ].map(x => prepTimeComponent(...x));

  let formattedTime = `${days}${hours}${minutes}`.trim();
  formattedTime = formattedTime ? formattedTime : "0min";

  const isWarning = dwellTime.subtract(sla, "seconds").asSeconds() > 0;
  const clockColor = dwellTimeSeconds
    ? isWarning
      ? Colors.holds.RED
      : Colors.holds.LIGHT_GREEN
    : Colors.text.LIGHT_GRAY;

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <IoMdTimer
        style={{
          fontSize: 20,
          marginRight: 15,
          color: clockColor
        }}
      />
      <span
        style={{
          fontWeight: isWarning ? 600 : 400
        }}
      >
        {formattedTime}
      </span>
    </div>
  );
};

DwellTimeCell.propTypes = {
  currentDwell: PropTypes.object.isRequired
};

export default DwellTimeCell;
