// eslint-disable-next-line
import React from "react";
import PropTypes from "prop-types";

import FormCell from "../FormCell";
import { FormControl } from "react-bootstrap";
import Colors from "../../../styles/colors";

const StandardInput = ({
  label,
  type,
  value,
  placeholder,
  onChange,
  isDisabled,
  isReadOnly
}) => {
  return (
    <FormCell label={label}>
      <FormControl
        style={{
          backgroundColor: isReadOnly ? Colors.background.LIGHT_GRAY : "white",
          height: "3em",
          boxShadow: "none",
          borderRadius: "5px",
          borderWidth: 2,
          width: "100%"
        }}
        type={type}
        value={value || ""}
        placeholder={placeholder}
        onChange={evt => onChange(evt.target.value)}
        readOnly={isReadOnly}
        disabled={isDisabled}
      />
    </FormCell>
  );
};

StandardInput.propTypes = {
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any
};

StandardInput.defaultProps = {
  type: "text"
};

export default StandardInput;
