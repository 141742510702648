export const enrichLocationsWithGeofence = (
  connectedCarEntities,
  underRecordLimit
) => {
  return connectedCarEntities
    .filter(item => item.latitude && item.longitude)
    .map(item => ({
      ...item,
      id: item.vin,
      geofence: {
        properties: {
          center: { latitude: item.latitude, longitude: item.longitude }
        }
      }
    }));
};
