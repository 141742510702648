/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import _ from "lodash";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import SectionHeader from "./SectionHeader";
import CodeSample from "./CodeSample";
import Colors from "../../../styles/colors";

const responsesCss = {
  padding: "1em 0"
};

const tabsCss = {
  ".react-tabs__tab-list": {
    marginBottom: "1em"
  },
  ".react-tabs__tab": {
    backgroundColor: "white",
    border: "1px solid #ddd",
    borderRadius: 0,
    color: Colors.highlight.RED,
    marginLeft: -1,
    padding: "1em 2em"
  },
  ".react-tabs__tab.success": {
    color: Colors.highlight.GREEN
  },
  ".react-tabs__tab:first-of-type": {
    border: "1px solid #ddd",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    marginLeft: 0
  },
  ".react-tabs__tab:last-of-type": {
    border: "1px solid #ddd",
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5
  },
  ".react-tabs__tab--selected": {
    backgroundColor: Colors.highlight.RED,
    border: "1px solid transparent !important",
    color: "white !important"
  },
  ".react-tabs__tab--selected.success": {
    backgroundColor: Colors.highlight.GREEN,
    border: "1px solid transparent !important",
    color: "white !important"
  }
};

const tabs = sample => {
  return _.keys(sample).map((key, i) => {
    const className = isOkResponse(key)
      ? "react-tabs__tab success"
      : "react-tabs__tab";

    return (
      <Tab key={i} className={className}>
        {key}
      </Tab>
    );
  });
};

const panels = (t, sample) => {
  return _.keys(sample).map((key, i) => {
    const response = sample[key];
    const description = response.description || "";

    return (
      <TabPanel key={i}>
        {responseDescription(key, t(`documentation-remote:${description}`))}
        {codeSamples(t, response.content)}
      </TabPanel>
    );
  });
};

const isOkResponse = responseCode => {
  return responseCode.startsWith("2");
};

const responseDescription = (code, description) => {
  return (
    <div
      css={{
        color: isOkResponse(code)
          ? Colors.highlight.GREEN
          : Colors.highlight.RED,
        margin: "1em 0"
      }}
    >
      <strong>{code}</strong> {description}
    </div>
  );
};

const codeSamples = (t, content) => {
  const sample = content.examples ? content.examples : content;

  return _.values(sample).map((example, i) => {
    const summary = example.summary || "";

    return (
      <CodeSample
        key={i}
        description={t(`documentation-remote:${summary}`)}
        code={example.value}
      />
    );
  });
};

const SampleResponse = props => {
  const { t, sample } = props;

  if (!sample) {
    return null;
  }

  return (
    <div id="responses" css={responsesCss}>
      <SectionHeader title={t("documentation:Response Samples")} />
      <Tabs css={tabsCss}>
        <TabList>{tabs(sample)}</TabList>
        {panels(t, sample)}
      </Tabs>
    </div>
  );
};

SampleResponse.propTypes = {
  sample: PropTypes.object
};
export default withTranslation(["documentation", "documentation-remote"])(
  SampleResponse
);
