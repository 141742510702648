/**
 * Utils functions that make it easy to work with state selectors.
 */
import _ from "lodash";

/**
 * Creates a function that can be added into the chain of createSelector
 * (reselect) to map fields for options in several kinds of Components in the
 * FV UI.
 *
 * @param {string} labelKey: key that will be used to get the label. If it is
 * null will use the own list item value as the label.
 * @param {function} labelKey: will execute this function to get the label
 * and this function will receive the item as its parameter.
 * @param {string} valueKey: key that will be used to get the value. If it is
 * null will use the own list item value as the value.
 * @param {function} valueKey: will execute this function to get the value
 * and this function will receive the item as its parameter.
 * @param {boolean} sorted: if true the list will be returned sorted by the
 * label.
 */
export const createOptionsMapper = ({
  labelKey = null,
  valueKey = null,
  sorted = false
}) => {
  const getContent = (item, key) => {
    // Case 1: key is null, return the list item
    if (key === null) {
      return item;
    }

    // Case 2: key is string, return the value of the property
    if (_.isString(key)) {
      return item[key];
    }

    // Case 3: key is a function
    if (_.isFunction(key)) {
      return key(item);
    }

    throw Error("key is of an invalid type", typeof key);
  };

  return items => {
    const result = items.map(item => {
      return {
        label: getContent(item, labelKey),
        value: getContent(item, valueKey)
      };
    });
    if (sorted) {
      return _.sortBy(result, "label");
    }
    return result;
  };
};
