/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getSolutionId } from "../../organizations/OrganizationsState";
import Colors from "../../../styles/colors";

import DownloadDataButton from "../../../components/saved-search/DownloadDataButton";

import SearchBarContainer from "../ConnectedCarSearchBarContainer";
import FilterSectionContainer from "../ConnectedCarFilterSectionContainer";
import ConnectedCarSearchBarState from "../ConnectedCarSearchBarState";

const SearchBar = ({ isShowingDownloadData }) => {
  const dispatch = useDispatch();
  const solutionId = useSelector(getSolutionId);

  return (
    <div className="m-2">
      <div className="mb-2">
        <SearchBarContainer
          isShowingFilters={false}
          isShowingAdvancedSearchButton={false}
          showSearchOptions={false}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: Colors.background.GRAY,
          borderRadius: "3px"
        }}
      >
        <div style={{ width: isShowingDownloadData ? "89%" : "100%" }}>
          <FilterSectionContainer
            show={true}
            backgroundColor={Colors.background.GRAY}
            sectionStyle={{ marginRight: "0.5em" }}
          />
        </div>
        {isShowingDownloadData ? (
          <DownloadDataButton
            style={{
              color: Colors.highlight.DARK_GRAY,
              fontSize: 12.5,
              marginBottom: "0.5em",
              marginRight: 0
            }}
            onClick={() => {
              dispatch(
                ConnectedCarSearchBarState.actionCreators.exportSearch(
                  solutionId
                )
              );
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  isShowingDownloadData: PropTypes.bool.isRequired
};

export default SearchBar;
