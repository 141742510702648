import axios from "axios";
import _ from "lodash";
import { createSelector } from "reselect";
// import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import apiUrl from "../../api-url";

import { SEARCH_CATEGORIES } from "../shipment-search/ShipmentSearchCategoryDefs";
import { FILTERS } from "../shipment-search/ShipmentSearchFilterDefs";

// URLS
const BASE_URL = apiUrl("/shipping-ng");
const SHIPMENT_TOTALS_URL = BASE_URL + "/shipments/totals";
const BATCH_SHIPMENT_TOTALS_URL = BASE_URL + "/batch_shipments/totals";
export const SHIPMENT_DETAILS_URL = BASE_URL + "/shipments/";
const CARRIERS_URL = BASE_URL + "/carriers";
const MULTIMODAL_DETAILS_URL = BASE_URL + "/trip/";
const HEAT_MAP_URL = BASE_URL + "/heat_map";

const STORE_MOUNT_POINT = "shipmentsStatus";

// Actions
const RECEIVE_INBOUND_SHIPMENT_TOTALS = "shipments/RECEIVE_SHIPMENT_TOTALS";
const RECEIVE_WATCHLIST_ITEM_SHIPMENTS =
  "shipments/RECEIVE_WATCHLIST_ITEM_SHIPMENTS";
const FETCH_INBOUND_SHIPMENT_TOTALS = "shipments/FETCH_INBOUND_SHIPMENT_TOTALS";
const FETCH_SHIPMENT_DETAILS = "shipment/FETCH_SHIPMENT_DETAILS";
const FETCH_MULTIMODAL_DETAILS = "shipments/FETCH_MULTIMODAL_DETAILS";
const RECEIVE_MULTIMODAL_DETAILS = "shipments/RECEIVE_MULTIMODAL_DETAILS";
const RECEIVE_SHIPMENT_DETAILS = "shipments/RECEIVE_SHIPMENT_DETAILS";
const FETCH_CHILD_SHIPMENT_DETAILS = "shipments/FETCH_CHILD_SHIPMENT_DETAILS";
const RECEIVE_CHILD_SHIPMENT_DETAILS =
  "shipments/RECEIVE_CHILD_SHIPMENT_DETAILS";
const RECEIVE_ALL_CHILD_SHIPMENT_DETAILS =
  "shipments/RECEIVE_ALL_CHILD_SHIPMENT_DETAILS";
const CLEAR_SHIPMENT_DETAILS = "shipments/CLEAR_SHIPMENT_DETAILS";
const CLEAR_CHILD_SHIPMENT_DETAILS = "shipments/CLEAR_CHILD_SHIPMENT_DETAILS";
const RECEIVE_ROUTE_HEATMAP = "shipment/RECEIVE_ROUTE_HEATMAP";

//fetcher helper
// H1-678: only need to pass along the VIN search if it was last used
const urlBuilder = ({
  shipmentID,
  state,
  searchObj = {},
  searchCategory = "everything"
} = {}) => {
  let url = SHIPMENT_DETAILS_URL + shipmentID;

  if (searchObj.value) {
    let category = SEARCH_CATEGORIES[searchCategory];
    if (category === SEARCH_CATEGORIES["vin"]) {
      url += category.queryBuilder(searchObj.value, state);
      // Replace the first query parameter prefix with ?
      url = url.replace("&", "?");
    }
  }

  return url;
};

// Fetchers

// H1-609: Added multimodal shipment fetch to shipment details
//  if regular shipment, multimodal details fetch will return 404
//  if multimodal, regular fetch details will return 404
//  whichever call returns successfully will populate the shipment prop
//  on successfull return of multimodal, fetch child shipment details
const fetchShipmentDetails = (shipmentID, detailUrl) => {
  return (dispatch, getState) => {
    // standard
    const state = getState();

    dispatch({
      type: CLEAR_SHIPMENT_DETAILS
    });

    dispatch({
      type: FETCH_SHIPMENT_DETAILS
    });

    // H1-678: is this how this is done?  Need the url built here to also reflect
    // the search state in one possible way.
    // If a fetch URL was explicitly passed (from carrier or VIN), use it. Otherwise, build the URL
    let url =
      detailUrl ||
      urlBuilder({
        shipmentID: shipmentID,
        state: state,
        searchObj: state.search.searchObj,
        searchCategory: state.search.searchCategory
      });

    Promise.all([axios.get(url)])
      .then(responses => {
        const shipmentDetails = responses[0].data;
        if (shipmentDetails && shipmentDetails.mode_name === "Multimodal") {
          return null;
        }

        dispatch({
          type: RECEIVE_SHIPMENT_DETAILS,
          data: shipmentDetails
        });
      })
      .catch(err => console.log(err));

    // multimodal
    dispatch({
      type: FETCH_MULTIMODAL_DETAILS
    });

    url = `${MULTIMODAL_DETAILS_URL}${shipmentID}`;
    Promise.all([axios.get(url)])
      .then(responses => {
        let data = responses[0].data;
        dispatch({
          type: RECEIVE_MULTIMODAL_DETAILS,
          data: data
        });

        // fetch child shipments
        dispatch(fetchChildShipmentDetails(data));
      })
      .catch(err => console.log(err));
  };
};

/* H1-1218 Update to support change in api call. Fetch on carriers/TEST/shipments/:creatorShipmentId:
 will only return the shipment_id and not the full details. Once that returns, we call fetchShipmentDetails
 with that shipment_id like a regular shipment to ge the rest of the details */
const fetchShipmentDetailsFromCarrierInfo = (scac, creatorShipmentId) => {
  return dispatch => {
    const url = `${CARRIERS_URL}/${scac}/shipments/${creatorShipmentId}`;

    dispatch({
      type: CLEAR_SHIPMENT_DETAILS
    });

    dispatch({
      type: FETCH_SHIPMENT_DETAILS
    });

    Promise.all([axios.get(url)])
      .then(responses => {
        const { shipment_id } = responses[0].data;
        dispatch(fetchShipmentDetails(shipment_id));
      })
      .catch(err => console.log(err));
  };
};

/* H2-268 Finished Vehicle Shipment Details has a different api call similar to Customer Carrier Info */
const fetchShipmentDetailsFromVin = (scac, creatorShipmentId, vin) => {
  const url = `${CARRIERS_URL}/${scac}/shipments/${creatorShipmentId}?vin_values=${vin}`;
  return dispatch => dispatch(fetchShipmentDetails(creatorShipmentId, url));
};

const fetchRouteHeatmap = routeId => {
  return dispatch => {
    const url = `${HEAT_MAP_URL}?routeId=${routeId}`;

    axios
      .get(url)
      .then(response => {
        let data = response.data;
        dispatch({
          type: RECEIVE_ROUTE_HEATMAP,
          data: data
        });
      })
      .catch(err => console.log(err));
  };
};

const fetchInboundShipmentTotals = () => {
  return dispatch => {
    dispatch({ type: FETCH_INBOUND_SHIPMENT_TOTALS });
    return axios
      .get(SHIPMENT_TOTALS_URL)
      .then(response => {
        dispatch({
          type: RECEIVE_INBOUND_SHIPMENT_TOTALS,
          payload: response.data
        });
      })
      .catch(err => {
        console.log(err);
      });
  };
};

const fetchShipmentTotalsForSavedSearch = savedSearch => {
  return (dispatch, getState) => {
    if (savedSearch != null && savedSearch.search != null) {
      let url = SHIPMENT_TOTALS_URL;
      let axiosRequest = null;

      // Use saved search properties to build URL querystring
      Object.keys(savedSearch.search).forEach(f => {
        const filterValue = savedSearch.search[f];
        let filterCategory = _.find(FILTERS, {
          queryKey: f
        });

        if (filterCategory == null) {
          filterCategory = _.find(SEARCH_CATEGORIES, {
            queryKey: f
          });
        }

        if (filterCategory && filterValue) {
          url += filterCategory.queryBuilder(f, filterValue, getState());
        }
      });

      // Replace the first query parameter prefix with ?
      url = url.replace("&", "?");

      if (savedSearch.search.batch) {
        // H1-1805: Modify totals request if saved search includes batch filter data
        const { batch } = savedSearch.search;
        const separator = url.includes("?") ? "&" : "?";
        const data = {
          batch_list: batch.batch_list
        };

        url = url.replace(SHIPMENT_TOTALS_URL, BATCH_SHIPMENT_TOTALS_URL);
        url = `${url}${separator}batchType=${batch.batch_type}`;

        axiosRequest = axios.post(url, data);
      } else {
        // Normal saved search totals request
        axiosRequest = axios.get(url);
      }

      return axiosRequest
        .then(response => {
          dispatch({
            type: RECEIVE_WATCHLIST_ITEM_SHIPMENTS,
            id: savedSearch.id,
            data: response.data
          });
        })
        .catch(err => {
          console.log(err);
        });
    }
  };
};

const fetchChildShipmentDetails = parentShipment => {
  return dispatch => {
    let requests = [];
    let shipmentIdHash = {};

    parentShipment.child_shipments.forEach(childShipment => {
      const url = `${SHIPMENT_DETAILS_URL}${childShipment.shipment_id}`;
      requests.push(axios.get(url));
      shipmentIdHash[url] = childShipment.shipment_id;
    });

    dispatch({
      type: FETCH_CHILD_SHIPMENT_DETAILS
    });

    Promise.all(requests)
      .then(responses => {
        responses.forEach(resp => {
          const childShipmentId = shipmentIdHash[resp.config.url];
          dispatch({
            type: RECEIVE_CHILD_SHIPMENT_DETAILS,
            id: childShipmentId,
            data: resp.data
          });
        });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        dispatch({
          type: RECEIVE_ALL_CHILD_SHIPMENT_DETAILS
        });
      });
  };
};

const fetchLegShipmentDetails = activeLegs => {
  return dispatch => {
    let requests = [];
    let shipmentIdHash = {};
    let vin = activeLegs.id;

    if (activeLegs.tripLegs !== null) {
      activeLegs.tripLegs.forEach(leg => {
        /* H1-1261 Add parameter 'format=DETAIL' to use "full details" header for fetch shipment request */
        const url = `${CARRIERS_URL}/${leg.carrierInfo.carrierScac}/shipments/${leg.id}?vin_values=${vin}&format=DETAIL`;
        requests.push(
          axios.get(url).catch(err => {
            // Catching errors here so that the other promises can get through if one fails
            console.error(err);
          })
        );
        shipmentIdHash[url] = leg.id;
      });

      dispatch({
        type: FETCH_CHILD_SHIPMENT_DETAILS
      });

      Promise.all(requests)
        .then(responses => {
          responses.forEach(resp => {
            if (resp) {
              const childShipmentId = shipmentIdHash[resp.config.url];
              dispatch({
                type: RECEIVE_CHILD_SHIPMENT_DETAILS,
                id: childShipmentId,
                data: resp.data
              });
            }
          });
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          dispatch({
            type: RECEIVE_ALL_CHILD_SHIPMENT_DETAILS
          });
        });
    } else {
      dispatch({
        type: CLEAR_CHILD_SHIPMENT_DETAILS
      });
    }
  };
};

const initialState = {
  exceptionTotals: {
    total_shipments: null,
    details: []
  },
  shipmentExceptionTotals: {},
  exceptionTotalsIsLoading: false,
  data: {},
  routeHeatmap: null,
  isLoading: false,
  childShipmentDetails: {}
};

function createSummary(apiData) {
  let summaryList = [];
  summaryList.push({ key: "Idle Trailer", count: apiData.total_idle_trailer });
  summaryList.push({ key: "Idle Train", count: apiData.total_idle_train });
  summaryList.push({
    key: "Behind Schedule",
    count: apiData.total_behind_schedule
  });
  summaryList.push({
    key: "Missed Pickup",
    count: apiData.total_missed_pickup
  });
  summaryList.push({
    key: "Missed Drop-Off",
    count: apiData.total_missed_dropoff
  });
  summaryList.push({ key: "Bad Order", count: apiData.total_bad_order });
  summaryList.push({ key: "In Hold", count: apiData.total_in_hold });
  summaryList.push({ key: "On Time", count: apiData.total_on_time });
  summaryList.push({ key: "Inbound", count: apiData.total_inbound });
  summaryList.push({ key: "Under Review", count: apiData.total_under_review });
  summaryList.push({ key: "Lost", count: apiData.total_lost });

  return {
    total_shipments: apiData.total_shipments,
    total_inbound_shipments: apiData.total_inbound,
    details: summaryList
  };
}

function ShipmentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_INBOUND_SHIPMENT_TOTALS:
      return {
        ...state,
        exceptionTotalsIsLoading: true
      };
    case RECEIVE_INBOUND_SHIPMENT_TOTALS:
      return {
        ...state,
        exceptionTotalsIsLoading: false,
        exceptionTotals: createSummary(action.payload)
      };
    case RECEIVE_WATCHLIST_ITEM_SHIPMENTS:
      return {
        ...state,
        shipmentExceptionTotals: {
          ...state.shipmentExceptionTotals,
          [action.id]: createSummary(action.data)
        }
      };
    case CLEAR_SHIPMENT_DETAILS:
      return {
        ...state,
        data: {}
      };
    case RECEIVE_SHIPMENT_DETAILS:
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    case RECEIVE_MULTIMODAL_DETAILS:
      return {
        ...state,
        data: action.data,
        isLoading: false
      };
    case FETCH_SHIPMENT_DETAILS:
    case FETCH_MULTIMODAL_DETAILS:
    case FETCH_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: null,
        isLoading: true
      };
    case RECEIVE_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: {
          ...state.childShipmentDetails,
          [action.id]: action.data
        }
      };
    case CLEAR_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        childShipmentDetails: null
      };
    case RECEIVE_ALL_CHILD_SHIPMENT_DETAILS:
      return {
        ...state,
        isLoading: false
      };
    case RECEIVE_ROUTE_HEATMAP:
      return {
        ...state,
        routeHeatmap: action.data
      };
    default:
      return state;
  }
}

// selectors

const getShipmentDetails = state => {
  return state[STORE_MOUNT_POINT].data || {};
};

// FIXME: this selector will always return true. It's not completely clear what
// it's used for downstream. Some components (like ShipmentDetailView and Panel)
// get the value as an isShipmentDetailsLoaded prop but then don't seem to do
// anything with the prop.
const getShipmentDetailsIsLoaded = state =>
  !state[STORE_MOUNT_POINT].isLoading || true;

const getRouteHeatmap = state => {
  return state[STORE_MOUNT_POINT].routeHeatmap || null;
};

const getExceptionTotals = state => state[STORE_MOUNT_POINT].exceptionTotals;

const getExceptionTotalsForSavedSearchID = (state, id) =>
  id != null ? state[STORE_MOUNT_POINT].shipmentExceptionTotals[id] : {};

const getExceptionTotalsIsLoaded = state =>
  !state[STORE_MOUNT_POINT].exceptionTotalsIsLoading;

const getChildShipmentDetails = state => {
  return state[STORE_MOUNT_POINT].childShipmentDetails || {};
};

const getAllShipmentsFlattened = createSelector(
  getShipmentDetails,
  getChildShipmentDetails,
  (parentShipment, childShipments) =>
    [parentShipment, ..._.values(childShipments)].filter(_.negate(_.isEmpty))
);

const getIsLoaded = state => !state[STORE_MOUNT_POINT].isLoading;

// interface
const shipmentsStatusState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchShipmentDetails,
    fetchShipmentDetailsFromCarrierInfo,
    fetchShipmentDetailsFromVin,
    fetchRouteHeatmap,
    fetchInboundShipmentTotals,
    fetchShipmentTotalsForSavedSearch,
    fetchLegShipmentDetails
  },
  selectors: {
    getShipmentDetails,
    getShipmentDetailsIsLoaded,
    getRouteHeatmap,
    getExceptionTotals,
    getExceptionTotalsForSavedSearchID,
    getExceptionTotalsIsLoaded,
    getChildShipmentDetails,
    getAllShipmentsFlattened,
    getIsLoaded
  },
  reducer: ShipmentsReducer
};
export default shipmentsStatusState;
