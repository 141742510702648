/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { jsx, css } from "@emotion/core";
import Collapsible from "react-collapsible";

import { IoIosArrowDown } from "react-icons/io";

import LadsState from "../../lads/LadsState";
import {
  LadChicletCSS as LadChiclet,
  MadChicletCSS as MadChiclet
} from "../../../components/chiclets";

import Colors from "../../../styles/colors";
import { NumberBubble } from "../../../components/tabs-container-panel";
import TabTableExpandable from "../../../components/tab-table-expandable";
import CountryName from "../../location/components/CountryName";
import { getLocationRows } from "../TripProgressSelectors";
import { generateHeaderCell } from "../../../components/tab-table";
import { TextCell, TimeCell } from "./vin-details-columns";
import { getLadByCode } from "../vin-details-utils";
import ExpandedDetailsTable from "./ExpandedDetailsTable";
import UpdateSubComponent from "./UpdateSubComponent";
import CommentsSubComponent from "./CommentsSubComponent";

import "./updates-table-style.css";
import {
  localizedTimeFormatter,
  localizedDateFormatter
} from "../../../utils/date-time";

const caretStyleClose = css({
  transition: "transform 0.4s linear",
  transform: "rotate(-90deg)"
});
const caretStyleOpen = css({
  transition: "transform 0.4s linear"
});

const PickupDeliveryText = ({ text, window, "data-qa": dataQa }) => (
  <div
    css={{
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "flex-start"
    }}
  >
    <span
      css={{ fontWeight: 600, fontSize: 12 }}
      data-qa={`texts-rows-scheduled-${dataQa}`}
    >
      Scheduled {text}
    </span>
    <span
      css={{
        marginLeft: 5,
        fontSize: 12,
        display: "flex",
        flexWrap: "wrap"
      }}
      data-qa={`texts-rows-time-from-to-${dataQa}`}
    >
      {`${localizedDateFormatter(window[0])} ${localizedTimeFormatter(
        window[0]
      )}`}
      <span css={{ margin: "0 0.5em" }}>{" to "}</span>
      {`${localizedDateFormatter(window[1])} ${localizedTimeFormatter(
        window[1]
      )}`}
    </span>
  </div>
);

const TriggerUpdates = ({ open, updateCount, disable, t }) => (
  <div
    css={{
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      backgroundColor: disable ? "#f3f3f3" : Colors.background.LIGHT_GRAY,
      height: 50,
      paddingLeft: 20,
      borderRadius: "5px 5px 0px 0px"
    }}
    data-qa="buttons-toggle-updates"
  >
    <div css={open ? caretStyleOpen : caretStyleClose}>
      <IoIosArrowDown style={{ fontSize: 20, color: Colors.text.HIT_GRAY }} />
    </div>
    <NumberBubble
      css={{
        backgroundColor: disable ? "#dedbdb" : Colors.holds.LIGHT_BLUE,
        marginBottom: 0,
        marginLeft: 10
      }}
    >
      {updateCount}
    </NumberBubble>
    <span
      css={{
        marginLeft: 10,
        fontSize: 15,
        fontWeight: 600,
        textDecoration: "underline",
        color: disable ? "#a2a2a2" : "black"
      }}
    >
      {t("fv-vin-details:Updates")}
    </span>
  </div>
);

const LocationRow = ({ vinInfo, start, end, t }) => {
  const showName = !_.isNil(vinInfo.name);
  const showCity = !_.isNil(vinInfo.city);
  const showCountry = !_.isNil(vinInfo.country);

  return (
    <div
      css={{
        display: "flex",
        flexWrap: "wrap",
        flexGrow: 1,
        alignItems: "stretch",
        justifyContent: "space-between",
        padding: "0 10px"
      }}
    >
      {/* Location Name and Info */}
      <div css={{ display: "flex", flexDirection: "column" }}>
        {showName && (
          <span
            css={{ fontWeight: 600, fontSize: 14 }}
            data-qa="texts-rows-name-location"
          >
            {vinInfo.name}
          </span>
        )}
        {showCity && (
          <span
            css={{
              color: Colors.text.LIGHT_GRAY,
              fontWeight: 600,
              fontSize: 14
            }}
            data-qa="texts-rows-city-location"
          >
            {_.startCase(vinInfo.city.toLowerCase())}, {vinInfo.state}
          </span>
        )}
        {showCountry && (
          <CountryName
            countryCode={vinInfo.country}
            style={{
              color: Colors.text.LIGHT_GRAY,
              fontWeight: 600,
              fontSize: 14
            }}
            data-qa="texts-rows-country-location"
          />
        )}
      </div>
      {/* Scheduled Pickup/Delivery Times */}
      <div>
        {/*H2-641 don't show Scheduled Delivery at Ultimate Origin */
        vinInfo.scheduled_delivery_window && !start && (
          <PickupDeliveryText
            text={t("fv-vin-details:Delivery")}
            window={vinInfo.scheduled_delivery_window}
            data-qa="delivery"
          />
        )}
        {/*H2-641 don't show Scheduled Pickup at Ultimate Destination */
        vinInfo.scheduled_pickup_window && !end && (
          <PickupDeliveryText
            text={t("fv-vin-details:Pickup")}
            window={vinInfo.scheduled_pickup_window}
            data-qa="pickup"
          />
        )}
      </div>
    </div>
  );
};

class UpdatesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };

    this.handleCollapse = this.handleCollapse.bind(this);
  }

  handleCollapse() {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  }

  render() {
    const { open } = this.state;
    const { vinInfo, start, end, lads, t, tripProgress } = this.props;
    const completedMad = _.find(tripProgress, ["progress", 100]);
    const noProgressMad = _.find(tripProgress, ["progress", 0]);
    const inProgressMad = _.find(tripProgress, t => {
      return t.progress && !_.includes([0, 100], t.progress);
    });
    const TempMad = ({ progress, mode }) => {
      return (
        <MadChiclet
          shipmentMode={mode}
          stopMode={mode}
          activeException={null}
          height={22}
          width={22}
          style={{
            // TODO: Need to make this margin proportional to actual progress
            marginTop: progress && progress !== 0 && 10
          }}
        />
      );
    };

    let lad = getLadByCode(vinInfo.typeCode, lads);

    const disable = vinInfo.updates.length === 0;

    const updateColumns = (sortKey, sortDir) => {
      return [
        {
          // Need an "expander" field that we hide,
          // so we can create our own custom expander in a different column
          show: false,
          expander: true
        },
        {
          Header: generateHeaderCell(
            t("fv-vin-details:Event Time"),
            sortKey === "eventTs",
            sortDir
          ),
          accessor: "eventTs",
          width: 150,
          Cell: row => (
            <TimeCell
              time={localizedTimeFormatter(row.value)}
              date={localizedDateFormatter(row.value)}
            />
          )
        },
        {
          Header: generateHeaderCell(
            t("fv-vin-details:Received Time"),
            sortKey === "ts",
            sortDir
          ),
          accessor: "ts",
          width: 150,
          Cell: row => (
            <TimeCell
              time={localizedTimeFormatter(row.value)}
              date={localizedDateFormatter(row.value)}
            />
          )
        },
        {
          Header: generateHeaderCell(
            t("fv-vin-details:Update"),
            sortKey === "update",
            sortDir
          ),
          accessor: "update",
          Cell: props => {
            if (props.original.code === "StatusUpdated") {
              return <UpdateSubComponent original={props.original} />;
            } else {
              return <TextCell>{props.original.update}</TextCell>;
            }
          }
        },
        {
          Header: generateHeaderCell(
            t("fv-vin-details:Comments"),
            sortKey === "comments",
            sortDir
          ),
          accessor: "comments",
          Cell: props => {
            if (props.original.code === "StatusUpdated") {
              return (
                <CommentsSubComponent
                  t={t}
                  original={props.original}
                  isExpanded={props.isExpanded}
                  columnProps={props.columnProps}
                  nestingPath={props.nestingPath}
                />
              );
            } else {
              return <TextCell>{props.original.comments}</TextCell>;
            }
          }
        }
      ];
    };
    return (
      <div css={{ display: "flex" }}>
        <div
          css={{
            padding: "0 5px",
            display: "flex",
            flexDirection: "column"
          }}
        >
          {start ? (
            <div
              css={{
                border: "3px solid green",
                borderRadius: 7,
                width: 14,
                height: 14,
                marginTop: -10,
                marginLeft: 13
              }}
            />
          ) : null}
          <div
            css={{
              width: 3,
              backgroundColor: "green",
              height: 5,
              flexGrow: 1,
              marginTop: -3,
              marginLeft: 18.5
            }}
          />
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 99
            }}
          >
            {completedMad ? <TempMad mode={completedMad.mode} /> : null}
            <LadChiclet lad={lad} height={40} width={40} />
            {!end && noProgressMad ? (
              <TempMad mode={noProgressMad.mode} />
            ) : null}
            {!end && inProgressMad ? (
              <TempMad
                mode={inProgressMad.mode}
                progress={inProgressMad.progress}
              />
            ) : null}
          </div>
        </div>
        <div css={{ flexGrow: 1, marginLeft: 10, overflow: "auto" }}>
          {!start && (
            <Collapsible
              triggerDisabled={disable}
              trigger={
                <TriggerUpdates
                  open={open}
                  updateCount={vinInfo.updates.length}
                  disable={disable}
                  t={t}
                />
              }
              onOpening={this.handleCollapse}
              onClosing={this.handleCollapse}
            >
              <div
                css={{
                  padding: 10,
                  backgroundColor: "#E8E7E8",
                  borderRadius: "0px 0px 5px 5px"
                }}
              >
                <TabTableExpandable
                  data={vinInfo.updates}
                  columns={updateColumns}
                  defaultSorted={{ id: "time", desc: false }}
                  subComponent={({ original }) => {
                    return (
                      <ExpandedDetailsTable
                        statusUpdate={original.statusUpdate}
                        comments={original.comments}
                        t={t}
                      />
                    );
                  }}
                  data-qa="updates"
                />
              </div>
            </Collapsible>
          )}

          <LocationRow vinInfo={vinInfo} start={start} end={end} t={t} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { getLadsList } = LadsState.selectors;

  return {
    lads: getLadsList(state),
    locationInfoList: getLocationRows(state)
  };
}

let withT = withTranslation("fv-vin-details")(UpdatesTable);
export default connect(mapStateToProps, null)(withT);
