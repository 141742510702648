/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { partial } from "lodash";

import Colors from "../../../styles/colors";
import { useIsMediumAndDown } from "../../../components/responsive";
import { FlexDiv, FlexColDiv } from "../../../styles/container-elements";

import HeaderDefinitions from "./header/HeaderDefinitions";
import SearchBarContainer from "./vin-search/AdcDetailsVinSearchBarContainer";
import LocationTypeSelect from "./vin-search/LocationTypeSelect";
import ResultsTableContainer from "./vin-search/ResultsTableContainer";
import { resultsTableColumns } from "./vin-search/results-table-columns";

const CurrentTabPanel = props => {
  const { t } = useTranslation("adc-details");
  const {
    location,
    totalEntities,
    locationTypeSelected,
    changeLocationType,
    solutionId,
    locationId,
    fetchVinSearch,
    clearSearchText,
    resetSearchBar
  } = props;

  const locationTypeOptions = [
    t("adc-details:Inbound"),
    t("adc-details:Scheduled Outbound"),
    t("adc-details:On Site")
  ];

  const isMobile = useIsMediumAndDown();

  useEffect(() => {
    changeLocationType(solutionId, locationTypeSelected);
    fetchVinSearch(solutionId, locationId, locationTypeSelected);
    clearSearchText();
    resetSearchBar();
  }, [
    solutionId,
    locationId,
    locationTypeSelected,
    changeLocationType,
    fetchVinSearch,
    clearSearchText,
    resetSearchBar
  ]);

  return (
    <div>
      <HeaderDefinitions location={location} />
      <div>
        <div
          css={{
            backgroundColor: Colors.background.DARK_GRAY,
            fontSize: "large",
            color: "white",
            padding: ".5em"
          }}
        >
          {t("adc-details:Location Activity")}
        </div>
        <div
          css={{
            padding: "1em"
          }}
        >
          <FlexDiv
            css={{
              marginTop: "1.5em",
              marginBottom: "1.5em",
              flexDirection: isMobile ? "column" : "row"
            }}
          >
            <FlexColDiv
              css={{
                width: isMobile ? "auto" : "50%",
                marginRight: isMobile ? "-0.5em" : null
              }}
            >
              <SearchBarContainer
                isShowingFilters={false}
                isShowingAdvancedSearchButton={false}
                showSearchOptions={false}
              />
            </FlexColDiv>
            <FlexColDiv
              css={{
                width: isMobile ? "100%" : "50%",
                maxWidth: isMobile ? "100%" : 300,
                marginLeft: "auto",
                marginTop: isMobile ? "0.5em" : null
              }}
            >
              <LocationTypeSelect
                options={locationTypeOptions}
                selectedOption={locationTypeSelected}
                onChange={partial(changeLocationType, solutionId)}
              />
            </FlexColDiv>
          </FlexDiv>
          <div>
            <strong data-qa="text-total-entities">
              {totalEntities.toLocaleString()}{" "}
            </strong>
            <span>
              {locationTypeSelected} {t("adc-details:VINs")}{" "}
              {t("adc-details:Today")}
            </span>
          </div>
          <div css={{ marginTop: "1em" }}>
            <ResultsTableContainer
              showPagination={true}
              fixPaginationToBottom={false}
              columns={() => resultsTableColumns(t, locationTypeSelected)}
              manual={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CurrentTabPanel.propTypes = {
  location: PropTypes.object,
  totalEntities: PropTypes.number.isRequired,
  locationTypeSelected: PropTypes.string.isRequired,
  changeLocationType: PropTypes.func.isRequired,
  solutionId: PropTypes.string.isRequired,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fetchVinSearch: PropTypes.func.isRequired,
  clearSearchText: PropTypes.func.isRequired,
  resetSearchBar: PropTypes.func.isRequired
};

export default CurrentTabPanel;
