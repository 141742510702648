/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import styled from "@emotion/styled";

export const TimeCell = ({ time, date }) => (
  <TextCell>
    <span style={{ fontWeight: 600 }}>{time}</span>
    <span style={{ fontWeight: 400 }}>{date}</span>
  </TextCell>
);

export const TextCell = styled.div({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  fontSize: 12,
  whiteSpace: "normal"
});
