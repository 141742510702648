/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import Loader from "react-loader";
import _ from "lodash";

import SectionHeader from "./SectionHeader";
import CodeSampleEditor from "./CodeSampleEditor";
import StandardInput from "../../../components/forms/inputs/StandardInput";
import Colors from "../../../styles/colors";

const requestsCss = {
  padding: "1em"
};

const inputGroupCss = {
  display: "flex",
  flexWrap: "wrap"
};

const inputCss = {
  flex: "1 1 33%"
};

const buttonCss = {
  backgroundColor: Colors.highlight.GREEN,
  color: "white",
  float: "right"
};

const getHeaderInputs = (headers, eventHandler, t) => {
  let headersElem = [];

  Object.values(headers).forEach((el, i) => {
    headersElem.push(
      <div style={inputCss} key={i}>
        <StandardInput
          label={el.name}
          placeholder={`(${el.type})`}
          value={el.value}
          onChange={val => eventHandler("headers", el.name, val)}
        />
      </div>
    );
  });

  return (
    <div>
      <SectionHeader title={t("Request Headers")} />
      <div style={inputGroupCss}>{headersElem}</div>
    </div>
  );
};

const getParameterInputs = (parameters, eventHandler, t) => {
  let paramElem = [];

  Object.values(parameters).forEach((el, i) => {
    paramElem.push(
      <div style={inputCss} key={i}>
        <StandardInput
          label={el.name}
          placeholder={`(${el.type})`}
          value={el.value}
          onChange={val => eventHandler("parameters", el.name, val)}
        />
      </div>
    );
  });

  return (
    <div>
      <SectionHeader title={t("Request Parameters")} />
      <div style={inputGroupCss}>{paramElem}</div>
    </div>
  );
};

const ApiRequest = ({ data, eventHandler, isLoading, submitHandler, t }) => {
  return (
    <div id="requests" css={requestsCss}>
      <Loader loaded={!isLoading} />
      {data.headers && !_.isEmpty(data.headers)
        ? getHeaderInputs(data.headers, eventHandler, t)
        : null}
      {data.parameters && !_.isEmpty(data.parameters)
        ? getParameterInputs(data.parameters, eventHandler, t)
        : null}
      <SectionHeader title={t("Request Body")} />
      <CodeSampleEditor code={data.body} eventHandler={eventHandler} />
      <div>
        <Button
          disabled={isLoading}
          style={buttonCss}
          onClick={() => submitHandler()}
        >
          {t("Execute")}
        </Button>
      </div>
    </div>
  );
};

ApiRequest.propTypes = {
  data: PropTypes.object,
  eventHandler: PropTypes.func,
  isLoading: PropTypes.bool,
  submitHandler: PropTypes.func
};
export default withTranslation(["documentation"])(ApiRequest);
