/** @jsx jsx */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ReactTable from "react-table";

import { datatableThStyles, styleify } from "../../../styles/table-styles";
import { FlexDiv } from "../../../styles/container-elements";
import {
  LinkButton,
  markerStyle,
  actionButtonStyle
} from "../../../styles/table-action-styles";

import { TiPencil, TiTrash } from "react-icons/ti";

const ActionCell = props => {
  const { actionHandler, t } = props.value;

  const rowId = props.index;

  return (
    <FlexDiv>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("UPDATE", {
            ...props.original,
            rowID: rowId
          })
        }
      >
        {t("organizations:Edit Org")} <TiPencil style={markerStyle} />
      </LinkButton>
      <LinkButton
        style={actionButtonStyle}
        clickHandler={() =>
          actionHandler("DELETE", {
            ...props.original,
            rowID: rowId
          })
        }
      >
        {t("organizations:Delete Org")} <TiTrash style={markerStyle} />
      </LinkButton>
    </FlexDiv>
  );
};

ActionCell.propTypes = {
  value: PropTypes.object.isRequired,
  original: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
};

const OrganizationsTable = ({ t, actionHandler, data, organizationType }) => {
  const organizationsColumns = [
    {
      Header: organizationType
        ? t(`organizations:${organizationType}`) + ` ${t("organizations:Name")}`
        : t("organizations:Name"),
      accessor: "org_name",
      minWidth: 200,
      style: {
        minWidth: 200,
        display: "flex"
      }
    },
    {
      Header: t("organizations:Organization Type"),
      accessor: "org_type",
      minWidth: 100,
      style: {
        minWidth: 100,
        display: "flex"
      }
    },
    {
      Header: t("organizations:FreightVerify ID"),
      accessor: "fv_id",
      minWidth: 100,
      style: {
        minWidth: 100,
        display: "flex"
      }
    },
    {
      Header: t("organizations:# of Users"),
      accessor: "no_of_user",
      minWidth: 75,
      style: {
        minWidth: 75,
        display: "flex"
      }
    },
    {
      Header: t("organizations:# of Locations"),
      accessor: "no_of_location",
      minWidth: 75,
      style: {
        minWidth: 75,
        display: "flex"
      }
    },
    {
      Header: t("users:Actions"),
      id: "user-actions",
      minWidth: 200,
      style: {
        minWidth: 200,
        display: "flex",
        alignItems: "flex-start"
      },
      Cell: ActionCell,
      accessor: d => {
        return {
          actionHandler: actionHandler,
          t: t
        };
      }
    }
  ];

  if (organizationType === "Carrier") {
    organizationsColumns.splice(3, 0, {
      Header: t("organizations:SCAC"),
      accessor: "scac",
      minWidth: 75,
      style: {
        minWidth: 75,
        display: "flex"
      }
    });
  }

  return (
    <ReactTable
      className="customtable fixedpaginationtable"
      data={data}
      columns={organizationsColumns}
      getTheadThProps={styleify(datatableThStyles)}
      getTdProps={styleify({ height: 55 })}
      previousText={t("organizations:Previous")}
      nextText={t("organizations:Next")}
      loadingText={`${t("organizations:Loading")}...`}
      noDataText={t("organizations:No rows found")}
      pageText={t("organizations:Page")}
      ofText={t("organizations:of")}
      rowsText={t("organizations:rows")}
      pageJumpText={t("organizations:jump to page")}
      rowsSelectorText={t("organizations:rows per page")}
    />
  );
};

OrganizationsTable.propTypes = {
  actionHandler: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  organizationType: PropTypes.string,
  t: PropTypes.func.isRequired
};

export default withTranslation(["organizations"])(OrganizationsTable);
