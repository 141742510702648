/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import { getActiveOrganization } from "../../organizations/OrganizationsState";
import { blockUser, unblockUser } from "../UsersState";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput,
  FormButton
} from "../../../components/modal-elems";
import Colors from "../../../styles/colors";
import { FlexRowDiv } from "../../../styles/container-elements";

const initUser = {
  email: "",
  isActive: false
};

const UserActiveModal = ({
  activeOrganization,
  blockUser,
  hide,
  show,
  t,
  unblockUser,
  user
}) => {
  const [updateUser, setUser] = useState(initUser);

  useMemo(() => {
    setUser({
      email: user && user.email ? user.email : "",
      isActive: user && user.status ? user.status === "Active" : false
    });
  }, [user]);

  const org = useMemo(
    () =>
      activeOrganization && activeOrganization.org_name
        ? activeOrganization.org_name
        : "",
    [activeOrganization]
  );

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <ModalHeader
        title={
          updateUser.isActive
            ? t("users:Mark User Inactive")
            : t("users:Mark User Active")
        }
      />
      <ModalBody>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={updateUser.email}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold"
            }}
          />
        </FlexRowDiv>
      </ModalBody>
      <ModalFooter>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={t("users:Cancel")}
          clickHandler={() => hide()}
        />
        <FormButton
          actionType={updateUser.isActive ? "DANGER" : "ACTION"}
          label={
            updateUser.isActive
              ? t("users:Mark Inactive")
              : t("users:Mark Active")
          }
          clickHandler={() => {
            updateUser.isActive
              ? blockUser(user, activeOrganization.organization_id)
              : unblockUser(user, activeOrganization.organization_id);
            hide();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

UserActiveModal.propTypes = {
  blockUser: PropTypes.func.isRequired,
  unblockUser: PropTypes.func.isRequired,
  activeOrganization: PropTypes.object.isRequired,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  user: PropTypes.object,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state)
  };
}

const mapDispatchToProps = {
  blockUser,
  unblockUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["users"])(UserActiveModal));
