/** @jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import { Component } from "react";
import styled from "@emotion/styled";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Button, FormGroup } from "react-bootstrap";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import moment from "moment";
import { useTranslation, withTranslation } from "react-i18next";

import { FlexDiv, FlexColDiv } from "../../styles/container-elements";

import SelectInput from "../../components/forms/inputs/SelectInput";
import { checkValidation, STOP_ROLES, STOP_TYPES } from "./CreateShipmentState";
import FormRow from "../../components/forms/FormRow";
import ValidationMessage from "../../components/forms/ValidationMessage";

import Colors from "../../styles/colors";

import { MdClose, MdWarning } from "react-icons/md";
import { FaPlus, FaCaretLeft, FaCaretRight } from "react-icons/fa";

import SearchBarContainer from "./ShipmentStopsSearchBarContainer";

const PointerOnHover = styled.span({
  ":hover": {
    cursor: "pointer"
  }
});

/**
 *
 * @return {*}
 * @constructor
 */
const WarningIcon = () => (
  <span css={{ color: Colors.highlight.YELLOW, marginRight: ".25em" }}>
    <MdWarning />
  </span>
);

/**
 *
 * @param eventHandler
 * @param ind
 * @param lbl
 * @param stop
 * @param style
 * @return {*}
 * @constructor
 */
const ArrivalTimeCell = ({ eventHandler, ind, lbl, stop, style }) => {
  const { t } = useTranslation("create-shipment");

  const dtField = lbl === "Early" ? "earlyArrival" : "lateArrival";
  const lblTranslated = t(`create-shipment:${lbl}`);

  /* Min = now rounded to next half hour */
  const now = moment();
  const remainder = 30 - (now.minute() % 30);
  let min = now.clone().add(remainder, "minutes");

  /* Max is min + 60 days */
  let max = min.clone().add(60, "days");

  return (
    <FormRow style={{ marginLeft: 0, marginRight: 0 }}>
      <FormGroup style={{ marginLeft: ".5em", marginRight: ".5em", ...style }}>
        <FlexDiv
          css={{
            color: "rgb(39, 48, 66)",
            marginBottom: "5px"
          }}
          className="timeLabel"
        >
          <span>
            {`${lblTranslated} ${t("create-shipment:Arrival Time")}`}
            <sup>*</sup>
          </span>
        </FlexDiv>
        <DateTimePicker
          style={{ width: "22em" }}
          defaultValue={stop[dtField]}
          value={stop[dtField]}
          onChange={value => eventHandler(ind, dtField, value)}
          format="l HH:mm"
          min={min.toDate()}
          max={max.toDate()}
        />
      </FormGroup>
      {stop.timezone ? (
        <FlexDiv css={{ flex: 1, height: "3em", marginTop: "2.5em" }}>
          {`${stop.timezone} (GMT${moment.tz(stop.timezone).format("Z z")})`}
        </FlexDiv>
      ) : (
        <FlexDiv css={{ flex: 1, height: "3em" }} />
      )}
    </FormRow>
  );
};

ArrivalTimeCell.propTypes = {
  eventHandler: PropTypes.func,
  ind: PropTypes.number,
  lbl: PropTypes.string,
  stop: PropTypes.object,
  style: PropTypes.object
};

/**
 *
 * @param eventHandler
 * @param ind
 * @param stop
 * @param validation
 * @param selectedShipper
 * @return {*}
 * @constructor
 */
const StopForm = ({ eventHandler, ind, stop, validation, selectedShipper }) => {
  const { t } = useTranslation("create-shipment");

  return (
    <FlexColDiv>
      <FormRow style={{ marginLeft: 0, marginRight: 0 }}>
        <SearchBarContainer
          ind={ind}
          label={
            <span>
              {t("create-shipment:Location")}
              <sup>*</sup>
            </span>
          }
          eventHandler={eventHandler}
          selectedShipper={selectedShipper}
          stop={stop}
        />
        <SelectInput
          label={
            <span>
              {t("create-shipment:Stop Type")}
              <sup>*</sup>
            </span>
          }
          options={STOP_TYPES(t)}
          value={stop.stopType}
          onChange={value => {
            eventHandler(ind, "stopType", value);
          }}
          borderColor="#ced4da"
        />

        <SelectInput
          label={
            <span>
              {t("create-shipment:Stop Role")}
              <sup>*</sup>
            </span>
          }
          options={STOP_ROLES(t)}
          value={stop.stopRole}
          borderColor="#ced4da"
          onChange={value => {
            eventHandler(ind, "stopRole", value);
          }}
        />
      </FormRow>

      <FlexDiv>
        <FlexColDiv css={{ flex: ".667" }}>
          <ArrivalTimeCell
            stop={stop}
            ind={ind}
            eventHandler={eventHandler}
            lbl="Early"
          />
          <ArrivalTimeCell
            stop={stop}
            ind={ind}
            eventHandler={eventHandler}
            lbl="Late"
          />
        </FlexColDiv>
        <FlexColDiv
          css={{ alignItems: "flex-start", flex: ".333", marginTop: "2em" }}
        >
          <ValidationMessage
            isValid={
              validation.isValidLoc &&
              validation.isValidStopType &&
              validation.isValidStopRole &&
              validation.isValidEarly &&
              validation.isValidLate
            }
            msg={t("create-shipment:Must fill in all required fields for stop")}
          />
          <ValidationMessage
            isValid={validation.isValidEarly}
            msg={t("create-shipment:Must have valid Early Arrival")}
          />
          <ValidationMessage
            isValid={validation.isValidLate}
            msg={t("create-shipment:Must have valid Late Arrival")}
          />

          {ind === 101 ? (
            <ValidationMessage
              isValid={
                validation.isValidAfterOrigin
                  ? validation.isValidAfterOrigin
                  : false
              }
              msg={t("create-shipment:Delivery must be after Pickup")}
            />
          ) : null}

          {ind < 100 ? (
            <ValidationMessage
              isValid={
                validation.isValidAfterOrigin
                  ? validation.isValidAfterOrigin
                  : false
              }
              msg={t("create-shipment:Stop must be after Pickup")}
            />
          ) : null}

          {ind < 100 ? (
            <ValidationMessage
              isValid={
                validation.isValidBeforeDest
                  ? validation.isValidBeforeDest
                  : false
              }
              msg={t("create-shipment:Stop must be before Delivery")}
            />
          ) : null}
        </FlexColDiv>
      </FlexDiv>
    </FlexColDiv>
  );
};

StopForm.propTypes = {
  eventHandler: PropTypes.func,
  ind: PropTypes.number,
  selectedShipper: PropTypes.object,
  stop: PropTypes.object,
  validation: PropTypes.object
};

/**
 *
 */
class ShipmentStopsForm extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    addStop: PropTypes.func.isRequired,
    changeTab: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    removeStop: PropTypes.func.isRequired,
    reorderStop: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
    updateStop: PropTypes.func.isRequired,
    validation: PropTypes.object.isRequired,
    selectedShipper: PropTypes.object
  };
  render() {
    const {
      t,
      addStop,
      changeTab,
      data,
      removeStop,
      reorderStop,
      tabIndex,
      updateStop,
      validation,
      selectedShipper
    } = this.props;

    let tabElem = [];
    let panelElem = [];

    // Stops
    data.stops.forEach((stop, i) => {
      let arrowL =
        tabIndex - 1 === i && i > 0 ? (
          <PointerOnHover
            onClick={e => {
              e.stopPropagation();
              reorderStop(i, i - 1);
            }}
          >
            <FaCaretLeft />
          </PointerOnHover>
        ) : null;
      let arrowR =
        tabIndex - 1 === i && i < data.stops.length - 1 ? (
          <PointerOnHover
            onClick={e => {
              e.stopPropagation();
              reorderStop(i, i + 1);
            }}
          >
            <FaCaretRight />{" "}
          </PointerOnHover>
        ) : null;

      //   Stop tab
      tabElem.push(
        <Tab key={i} style={{ marginRight: ".5em" }}>
          {arrowL}
          {validation &&
          validation.stops &&
          checkValidation(validation.stops[i]) ? null : (
            <WarningIcon />
          )}
          {`${t("create-shipment:Stop")} ${i + 1}`}
          {arrowR}
          <PointerOnHover
            onClick={e => {
              e.stopPropagation();
              removeStop(i);
            }}
          >
            <MdClose />
          </PointerOnHover>
        </Tab>
      );

      //   Stop panel
      panelElem.push(
        <TabPanel
          key={i}
          style={{
            marginTop: "1em",
            marginLeft: 0,
            marginRight: 0,
            width: "100%"
          }}
        >
          <StopForm
            stop={stop}
            ind={i}
            eventHandler={updateStop}
            validation={validation.stops ? validation.stops[i] : {}}
            selectedShipper={selectedShipper}
          />
        </TabPanel>
      );
    });

    /* just hardcoded indexes for origin as 100 and destination as 101
    as to be noticably different and outside of the range of the stops.
    probably a more eligant way to do that */

    // Origin
    //   Origin tab
    tabElem.unshift(
      <Tab key={100} style={{ marginRight: ".5em" }}>
        {validation &&
        validation.origin &&
        checkValidation(validation.origin) ? null : (
          <WarningIcon />
        )}
        {t("create-shipment:Origin")}
      </Tab>
    );
    //    Origin panel
    panelElem.unshift(
      <TabPanel
        key={100}
        style={{
          marginTop: "1em",
          marginLeft: 0,
          marginRight: 0,
          width: "100%"
        }}
      >
        <StopForm
          stop={data.origin}
          ind={100}
          eventHandler={updateStop}
          validation={validation.origin}
          selectedShipper={selectedShipper}
        />
      </TabPanel>
    );

    // Destination
    //    Destination tab
    tabElem.push(
      <Tab key={101} style={{ marginRight: ".5em" }}>
        {validation &&
        validation.destination &&
        checkValidation(validation.destination) ? null : (
          <WarningIcon />
        )}
        {t("create-shipment:Destination")}
      </Tab>
    );

    //    Destination panel
    panelElem.push(
      <TabPanel
        key={101}
        style={{
          marginTop: "1em",
          marginLeft: 0,
          marginRight: 0,
          width: "100%"
        }}
      >
        <StopForm
          stop={data.destination}
          ind={101}
          eventHandler={updateStop}
          validation={validation.destination}
          selectedShipper={selectedShipper}
        />
      </TabPanel>
    );

    return (
      <FlexDiv css={{ marginLeft: ".5em", marginRight: ".5em" }}>
        <Tabs
          style={{ flex: 1, marginLeft: ".5em", marginRight: ".5em" }}
          selectedIndex={tabIndex}
          onSelect={tabIndex => changeTab(tabIndex)}
        >
          <TabList>
            {tabElem}
            <Button
              variant="success"
              style={{ float: "right" }}
              onClick={() => addStop()}
            >
              <FaPlus /> {t("create-shipment:Add Stop")}
            </Button>
          </TabList>
          {panelElem}
        </Tabs>
      </FlexDiv>
    );
  }
}

export default withTranslation(["create-shipment"])(ShipmentStopsForm);
