import { connect } from "react-redux";

import ShipmentStatusView from "./ShipmentStatusView";
import ShipmentSearchBarState from "../shipment-search/ShipmentSearchBarState";
import LadsState from "../lads/LadsState";
import ShipmentSavedSearchState from "../shipment-search/ShipmentSavedSearchState";
import DomainDataState from "../domain-data/DomainDataState";
import { getLocations } from "../location/LocationsState";
import { getActiveOrganization } from "../organizations/OrganizationsState";

import ShipmentsStatusState from "./ShipmentsState";

function mapStateToProps(state) {
  return {
    savedSearches: ShipmentSavedSearchState.selectors.getSavedSearches(state),
    locations: getLocations(state),
    exceptionTotals: ShipmentsStatusState.selectors.getExceptionTotals(state),
    activeOrganization: getActiveOrganization(state),
    exceptionTypes: DomainDataState.selectors.getExceptionTypes(state),
    statusTypes: DomainDataState.selectors.getStatusTypes(state),
    shipmentModes: DomainDataState.selectors.getShipmentModes(state),
    lads: LadsState.selectors.getLadsList(state),
    currentOrganizationId: state.organizations.currentOrganizationId,
    shipmentTypes: DomainDataState.selectors.getShipmentTypes(state),
    isExceptionTotalsLoaded: ShipmentsStatusState.selectors.getExceptionTotalsIsLoaded(
      state
    ),
    showFilters: ShipmentSearchBarState.selectors.getShowAdvancedSearch(state),
    isLoadingSavedSearch: ShipmentSavedSearchState.selectors.getIsLoading(state)
  };
}

function mapDispatchToProps(dispatch) {
  const { fetchInboundShipmentTotals } = ShipmentsStatusState.actionCreators;
  return {
    fetchSavedSearches: () =>
      dispatch(ShipmentSavedSearchState.actionCreators.fetchSavedSearches()),
    fetchInboundShipmentTotals: () => dispatch(fetchInboundShipmentTotals()),
    loadSavedSearch: (savedSearch, preventRedirect) =>
      dispatch(
        ShipmentSavedSearchState.actionCreators.loadSavedSearch(
          savedSearch,
          preventRedirect
        )
      ),
    selectSavedSearch: (solutionId, item) =>
      dispatch(
        ShipmentSearchBarState.actionCreators.selectSavedSearch(
          solutionId,
          item,
          true
        )
      ),
    resetSearch: () =>
      dispatch(ShipmentSearchBarState.actionCreators.resetSearchBar()),
    clearSearchFilters: () =>
      dispatch(ShipmentSearchBarState.actionCreators.clearSearchFilters()),
    toggleShowFilters: showFilters =>
      dispatch(
        ShipmentSearchBarState.actionCreators.toggleShowFilters(showFilters)
      )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentStatusView);
