import _ from "lodash";
import { createSelector } from "reselect";
import buildSearchBarState from "../../components/search-bar/SearchBarStateBuilder";
import apiUrl from "../../api-url";
import { axiosConfigHeaders } from "../../utils/fetch-utils";
import { SEARCH_CATEGORIES } from "./MilestoneEventSearchCategoryDefs";
import { FILTERS } from "./MilestoneEventFilterDefs";

const STORE_MOUNT_POINT = "partnerMilestoneSearch";

const PARTNER_MILESTONE_SEARCH = "partners/MilestoneSearch";

// helpers

export const milestonesUrl = (solutionId, queryString) => {
  const url = apiUrl("/entity-search/status_update");
  if (queryString) {
    return `${url}?${queryString || ""}`;
  }
  return url;
};

// action creators

// This is an action creator, but it's intended to be called internally by
// buildSearchBarState.actionCreators.searchEntities(). Component code should
// call searchEntities() rather than this.
const fetchSearch = (queryString = null, solutionId, duck, dispatch) => {
  let url = milestonesUrl(solutionId, queryString);

  dispatch(duck.fetch(url, axiosConfigHeaders()));
  dispatch({ type: PARTNER_MILESTONE_SEARCH });
};

// selectors

const getMilestoneEventsResponse = state =>
  _.get(state, `${STORE_MOUNT_POINT}.data`, {});

const getMilestoneEvents = createSelector(getMilestoneEventsResponse, resp =>
  _.get(resp, "data", [])
);

// reducer

const initialState = {
  data: { data: [] }
};

// just here to supply initial state
const reducer = (state = initialState, action) => state;

// interface

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  FILTERS,
  fetchSearch,
  [reducer]
);

export default {
  ...SearchBarState,
  selectors: {
    ...SearchBarState.selectors,
    getMilestoneEvents
  },
  actionCreators: {
    ...SearchBarState.actionCreators,
    exportSearch: _.partial(
      SearchBarState.actionCreators.exportEntities,
      milestonesUrl,
      null,
      { headers: { Accept: "text/csv;version=full" } },
      "partner-search-results"
    )
  }
};
