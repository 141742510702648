/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-regular-svg-icons";

import AdcLocationsState from "../AdcLocationsState";
import Colors from "../../../styles/colors";

const wrapperCss = {
  color: "black",
  fontSize: 16,
  position: "relative"
};

const buttonCss = {
  appearance: "none",
  backgroundColor: "white",
  border: "none",
  borderRadius: 3,
  color: "black",
  fontSize: 14,
  height: 40,
  outline: "none",
  padding: "0 10px",
  position: "relative",
  textAlign: "left",
  width: 180,

  svg: {
    position: "absolute",
    top: 12,
    right: 10
  }
};

const dropdownCss = isVisible => {
  return {
    backgroundColor: Colors.background.LIGHT_GRAY,
    boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.05)",
    borderRadius: 5,
    display: isVisible ? "block" : "none",
    padding: 10,
    position: "absolute",
    top: 43,
    right: -10,
    width: 230,
    zIndex: 999
  };
};

const searchBoxCss = {
  position: "relative",

  svg: {
    color: Colors.text.LIGHT_GRAY,
    position: "absolute",
    top: 10,
    left: 12,
    fontSize: "0.9em"
  },

  ".form-control": {
    paddingLeft: 36
  }
};

const InboundVinLocations = props => {
  const {
    demandArea,
    resetLocations,
    selectedLocations,
    toggleLocation
  } = props;

  const { t } = useTranslation("adc-dashboard");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [locationResults, setLocationResults] = useState([]);
  const dropdownEl = useRef(null);

  const title =
    selectedLocations && selectedLocations.length
      ? `${t("adc-dashboard:Locations")} (${selectedLocations.length})`
      : t("adc-dashboard:All Locations");
  const icon = dropdownVisible ? (
    <FontAwesomeIcon icon={faChevronUp} />
  ) : (
    <FontAwesomeIcon icon={faChevronDown} />
  );

  useEffect(() => {
    // Hide the dropdown when clicking outside
    const hideDropdown = event => {
      if (dropdownEl && !dropdownEl.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", hideDropdown, false);
    // Specify how to clean up after this effect:
    return () => {
      document.removeEventListener("mousedown", hideDropdown, false);
    };
  }, []);

  useEffect(
    () => {
      resetLocations();
      setLocationResults([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [demandArea]
  );

  const submitSearch = async query => {
    if (query.length < 2) {
      return;
    }
    resetLocations();
    const locationResults = await AdcLocationsState.actionCreators.searchLocations(
      query,
      demandArea
    );
    setLocationResults(locationResults);
  };

  const checkboxes = () => {
    const selectedIds = selectedLocations.map(loc => loc.id);

    if (!locationResults || locationResults.length === 0) {
      return null;
    }

    return locationResults.map(l => {
      const isChecked = selectedIds.includes(l.id);

      return (
        <div key={l.id} className="form-check mt-1">
          <input
            id={`location_${l.id}`}
            type="checkbox"
            className="form-check-input"
            data-qa="checkbox-location-inbound-vins"
            onChange={() => {
              toggleLocation(l);
            }}
            checked={isChecked}
          />
          <label
            htmlFor={`location_${l.id}`}
            className="form-check-label small"
            data-qa="checkbox-label-location-inbound-vins"
          >
            {l.name}
          </label>
        </div>
      );
    });
  };

  return (
    <div ref={dropdownEl} css={wrapperCss}>
      <button
        css={buttonCss}
        data-qa="button-locations-inbound-vins"
        onClick={() => setDropdownVisible(!dropdownVisible)}
      >
        {title} {icon}
      </button>
      <div css={dropdownCss(dropdownVisible)}>
        <div css={searchBoxCss}>
          <FontAwesomeIcon
            icon={faSearch}
            alt={t("adc-dashboard:Search Icon")}
          />
          <input
            key={demandArea} //force react to recreate the element upon demand area change, clearing the text
            type="text"
            className="form-control small"
            placeholder={t("adc-dashboard:Search")}
            data-qa="input-search-locations-inbound-vins"
            onChange={event => submitSearch(event.target.value)}
          />
        </div>
        <div>{checkboxes()}</div>
      </div>
    </div>
  );
};

InboundVinLocations.propTypes = {
  resetLocations: PropTypes.func.isRequired,
  selectedLocations: PropTypes.array.isRequired,
  toggleLocation: PropTypes.func.isRequired,
  demandArea: PropTypes.string
};

export default InboundVinLocations;
