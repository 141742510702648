import _ from "lodash";
import produce from "immer";
import buildFetchDuck from "../../vendor/signal-utils/build-fetch-duck";
import chainReducers from "../../vendor/signal-utils/chain-reducers";
import { milestonesUrl } from "./PartnerDashboardSearchBarState";
import { axiosConfigHeaders } from "../../utils/fetch-utils";

const STORE_MOUNT_POINT = "statusUpdateDetails";

const UPDATE_DETAILS = `${STORE_MOUNT_POINT}/UPDATE_DETAILS`;

const duck = buildFetchDuck(STORE_MOUNT_POINT);

// action creators

const fetchStatusUpdateDetails = statusUpdateId => dispatch => {
  const url = milestonesUrl(`status_update_id=${statusUpdateId}`);
  dispatch(
    duck.fetch(url, axiosConfigHeaders(), responseData => {
      dispatch({
        type: UPDATE_DETAILS,
        payload: { statusUpdateId, data: responseData.data }
      });
      return responseData;
    })
  );
};

// selectors

const getAllStatusUpdateDetails = state =>
  _.get(state, `${STORE_MOUNT_POINT}.index`, {});

// Note: this isn't a selector until you call it with an id first
const statusUpdateDetailGetter = statusUpdateId => state => {
  const allUpdates = getAllStatusUpdateDetails(state);
  return _.get(allUpdates, statusUpdateId, {});
};

// reducer

const initialState = { index: {} };

const reducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case UPDATE_DETAILS:
        const { statusUpdateId, data } = action.payload;
        draft.index[statusUpdateId] = data;
        break;

      default:
        return draft;
    }
  });

export default {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: { fetchStatusUpdateDetails },
  selectors: {
    getAllStatusUpdateDetails,
    statusUpdateDetailGetter
  },
  reducer: chainReducers([reducer, duck.reducer])
};
