/** @jsx jsx
 * Standardized Results Table List to be used for MapSearchResults.
 */
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import ReactTable from "react-table";
import _ from "lodash";

import Colors from "../../styles/colors";
import { darkResultsTableStyles } from "../../styles/table-styles";
import { FlexDiv, FlexColDiv } from "../../styles/container-elements";
import { scrollToTableTop } from "../../utils/table-utils";

const DEFAULT_PAGE_SIZE = 20;

const Results = ({
  entities,
  columns,
  total = 0,
  totalText = "",
  isLoading = false,
  onRowClick = _.noop,
  selectedEntitiesIds = [],
  showPagination = true,
  defaultPageSize = DEFAULT_PAGE_SIZE,
  pageSize = DEFAULT_PAGE_SIZE,
  minRows = 0
}) => {
  const { t } = useTranslation("components");

  return (
    <FlexColDiv
      className="results-list"
      css={{
        alignItem: "center",
        display: "inline-flex",
        fontSize: "small",
        minHeight: "6.75em",
        width: "100%"
      }}
    >
      <FlexDiv
        data-qa="total-vins"
        css={{
          alignItems: "center",
          justifyContent: "center",
          padding: "1em",
          fontSize: 18,
          fontWeight: 600,
          color: Colors.tableHeadingText
        }}
      >
        {total.toLocaleString()} {totalText}
      </FlexDiv>
      <FlexDiv
        css={{
          flex: "1 0 0",
          marginBottom: ".25em",
          fontSize: 13,
          overflow: "auto"
        }}
      >
        <ReactTable
          css={darkResultsTableStyles}
          data={entities}
          loading={isLoading}
          columns={columns}
          getTrProps={(state, rowInfo, column, instance) => ({
            style: {
              cursor: "pointer"
            }
          })}
          getTdProps={(state, rowInfo, column, instance) => ({
            onClick: e => {
              // Row click is called here because having the column
              // that the user clicked on is useful and
              // `column` isn't defined under `getTrProps`
              onRowClick(rowInfo, column);
            },
            style: {
              backgroundColor:
                rowInfo && selectedEntitiesIds.includes(rowInfo.original.vin)
                  ? "#504D2F"
                  : null
            }
          })}
          onPageChange={newPage => {
            scrollToTableTop("results-list");
          }}
          previousText={t("components:Previous")}
          nextText={t("components:Next")}
          loadingText={`${t("components:Loading")}...`}
          noDataText=""
          pageText={t("components:Page")}
          ofText={t("components:of")}
          rowsText={t("components:rows")}
          pageJumpText={t("components:jump to page")}
          rowsSelectorText={t("components:rows per page")}
          showPagination={showPagination}
          defaultPageSize={defaultPageSize}
          pageSize={pageSize}
          minRows={minRows}
        />
      </FlexDiv>
    </FlexColDiv>
  );
};

Results.propTypes = {
  entities: PropTypes.array,
  columns: PropTypes.array,
  selectedEntitiesIds: PropTypes.array,
  total: PropTypes.number,
  totalText: PropTypes.string,
  isLoading: PropTypes.bool,
  onRowClick: PropTypes.func,
  showPagination: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  pageSize: PropTypes.number,
  minRows: PropTypes.number
};

export default Results;
