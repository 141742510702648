import axios from "axios";
import apiUrl from "../../api-url";
import { createSelector } from "reselect";
import _ from "lodash";

// URLS
const STORE_MOUNT_POINT = "fvEntityDetails";

// Actions
const RECEIVE_ENTITY_DETAILS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_DETAILS`;
const RECEIVE_ENTITY_EXCEPTIONS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_EXCEPTIONS`;
const RECEIVE_ENTITY_POSITION_UPDATES = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_POSITION_UPDATES`;
const RECEIVE_ENTITY_CONNECTED_CAR_POSITION_UPDATES = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_CONNECTED_CAR_POSITION_UPDATES`;
const CLEAR_MEDIA = `${STORE_MOUNT_POINT}/CLEAR_MEDIA`;
const RECEIVE_ENTITY_MEDIA = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_MEDIA`;
const RECEIVE_ENTITY_HOLDS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_HOLDS`;
const RECEIVE_ENTITY_EVENTS = `${STORE_MOUNT_POINT}/RECEIVE_ENTITY_EVENTS`;
const ENTITY_UPDATE_SUCCESS = `${STORE_MOUNT_POINT}/ENTITY_UPDATE_SUCCESS`;
const SHOW_CONNECTED_CAR_POSITIONS = `${STORE_MOUNT_POINT}/SHOW_CONNECTED_CAR_POSITIONS`;
const HIDE_CONNECTED_CAR_POSITIONS = `${STORE_MOUNT_POINT}/HIDE_CONNECTED_CAR_POSITIONS`;

const entityUrl = (solutionId, entityId) =>
  apiUrl(`/entity/solution/${solutionId}/entity/${entityId}`);

// Action creators
function fetchEntityDetails(solutionId, entityId) {
  const url = entityUrl(solutionId, entityId);

  return dispatch => {
    return Promise.all([axios.get(url)])
      .then(responses => {
        dispatch({
          type: RECEIVE_ENTITY_DETAILS,
          payload: { entityDetails: responses[0].data }
        });
      })
      .catch(err => {
        console.log("ERROR retreiving entity details");
        console.log(err);
        //throw new Error(err);
      });
  };
}

const positionUpdateIsConnectedCar = item => {
  return item.type.toLowerCase().includes("entity");
};

function fetchEntityPositionUpdates(solutionId, entityId) {
  const url = entityUrl(solutionId, entityId) + "/position-update";

  return dispatch => {
    // Connected Car and trip legs comes together in the same position-update
    // method and are only diferentiated by the "type" attribute. When it is
    // from connected car, it starts with "entity", when it is not, it starts
    // with "trip_leg_id"
    return axios
      .get(url)
      .then(response => {
        const [connectedCarUpdates, tripLegUpdates] = _.partition(
          response.data,
          positionUpdateIsConnectedCar
        );
        dispatch({
          type: RECEIVE_ENTITY_POSITION_UPDATES,
          payload: {
            entityPositionUpdates: tripLegUpdates
          }
        });
        dispatch({
          type: RECEIVE_ENTITY_CONNECTED_CAR_POSITION_UPDATES,
          payload: connectedCarUpdates
        });
      })
      .catch(err => {
        console.error("fetchEntityPositionUpdates error:", err);
      });
  };
}

function clearEntityMedia() {
  return dispatch =>
    dispatch({
      type: CLEAR_MEDIA
    });
}

function fetchEntityMedia(solutionId, key) {
  if (key === null) {
    return dispatch =>
      dispatch({
        type: CLEAR_MEDIA
      });
  }

  const url = apiUrl(`/entity/solution/${solutionId}/media/${key}`);

  return dispatch => {
    return Promise.all([
      axios({
        method: "get",
        url: url,
        headers: { Accept: "image/*" },
        responseType: "arraybuffer"
      })
    ])
      .then(responses => {
        const buffer = Buffer.from(responses[0].data, "base64");
        dispatch({
          type: RECEIVE_ENTITY_MEDIA,
          payload: { entityMedia: buffer }
        });
      })
      .catch(err => {
        console.log("fetchEntityMedia error:", err);
        dispatch({
          type: CLEAR_MEDIA
        });
      });
  };
}

function fetchEntityExceptions(
  solutionId,
  entityId,
  exceptionStatus = "ACTIVE"
) {
  let url = entityUrl(solutionId, entityId) + "/exception";

  if (exceptionStatus) {
    url += `?status=${exceptionStatus}`;
  }

  return dispatch => {
    return Promise.all([axios.get(url)])
      .then(responses => {
        dispatch({
          type: RECEIVE_ENTITY_EXCEPTIONS,
          payload: { entityExceptions: responses[0].data }
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

function fetchEntityHolds(solutionId, entityId, holdStatus = "ACTIVE") {
  let url = entityUrl(solutionId, entityId) + "/hold";

  if (holdStatus) {
    url += `?status=${holdStatus}`;
  }

  return dispatch => {
    return Promise.all([axios.get(url)])
      .then(responses => {
        dispatch({
          type: RECEIVE_ENTITY_HOLDS,
          payload: { entityHolds: responses[0].data }
        });
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

function fetchEntityEvents(solutionId, entityId, exceptionStatus = "ACTIVE") {
  let url = `${entityUrl(solutionId, entityId)}/event`;
  if (exceptionStatus) {
    url += `?status=${exceptionStatus}`;
  }
  return dispatch => {
    return Promise.all([
      axios.get(url, {
        headers: {
          Accept: "application/vnd+freightVerify.eventDetail.v1+json"
        }
      })
    ])
      .then(responses => {
        dispatch({
          type: RECEIVE_ENTITY_EVENTS,
          payload: { entityEvents: responses[0].data }
        });
      })
      .catch(err => {
        throw new Error(err);
        //console.log(err);
      });
  };
}

function setWatchEntity(solutionId, entityId, watch = true) {
  return dispatch => {
    dispatch(updateEntity(solutionId, entityId, { watch }));
  };
}

function updateEntity(solutionId, entityId, updateObj) {
  const url = entityUrl(solutionId, entityId);

  return dispatch => {
    return Promise.all([axios.patch(url, updateObj)])
      .then(responses => {
        dispatch({ type: ENTITY_UPDATE_SUCCESS });
        dispatch(fetchEntityDetails(solutionId, entityId));
      })
      .catch(err => {
        throw new Error(err);
      });
  };
}

function showConnectedCarPositions() {
  return { type: SHOW_CONNECTED_CAR_POSITIONS };
}

function hideConnectedCarPositions() {
  return { type: HIDE_CONNECTED_CAR_POSITIONS };
}

// Selectors
const getEntityDetails = state => state[STORE_MOUNT_POINT].entityDetails;

const translatePositionUpdates = item => {
  return {
    time: item.datetime,
    db_time: item.receivedDatetime,
    latitude: item.latitude,
    longitude: item.longitude,
    city: item.city,
    state: item.state,
    country: item.country
  };
};

const getEntityPositionUpdates = state => {
  if (state[STORE_MOUNT_POINT].entityPositionUpdates.entityPositionUpdates) {
    return state[
      STORE_MOUNT_POINT
    ].entityPositionUpdates.entityPositionUpdates.map(translatePositionUpdates);
  }
  return [];
};

const getEntityConnectedCarPositionUpdates = state => {
  return state[STORE_MOUNT_POINT].entityConnectedCarPositionUpdates.map(
    translatePositionUpdates
  );
};

const getEntityExceptions = state => {
  return state[STORE_MOUNT_POINT].entityExceptions
    ? _.flatten(state[STORE_MOUNT_POINT].entityExceptions)
    : [];
};

const getEntityHolds = state => {
  // FIXME remove this once the attributes come in the same case.
  return state[STORE_MOUNT_POINT].entityHolds.map(hold => {
    let holdCopy = Object.assign({}, hold);
    holdCopy.typeName = _.get(hold, "type_name", "");
    hold.typeName && delete holdCopy.type_name;

    if (holdCopy.type) {
      holdCopy.typeName = `${holdCopy.typeName} (${holdCopy.type})`;
    }

    return holdCopy;
  });
};

const getEntityEvents = state => {
  return state[STORE_MOUNT_POINT].entityEvents || [];
};

const getEntityMedia = state => {
  return state[STORE_MOUNT_POINT].entityMedia || null;
};

// Returns a list of the combined holds and exceptions, transformed to the attributes
// that our components expect.
// Tries to normalise the parameter names for both endpoints into a single naming
// standard.
const getCombinedEntityExceptions = createSelector(
  getEntityExceptions,
  getEntityHolds,
  (exceptions, holds) => [...exceptions, ...holds]
);

const getEntityCurrentLocation = createSelector(
  getEntityPositionUpdates,
  positionUpdates => _.orderBy(positionUpdates, ["time"], ["desc"])[0]
);

const getIsShowingConnectedCarPositions = state =>
  state[STORE_MOUNT_POINT].isShowingConnectedCarPositions;

// Initial state
const initialState = {
  entityExceptions: [],
  entityHolds: [],
  entityPositionUpdates: [],
  entityConnectedCarPositionUpdates: [],
  isShowingConnectedCarPositions: false
};

const FinVehicleEntityDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_ENTITY_DETAILS:
      return {
        ...state,
        entityDetails: action.payload.entityDetails
      };

    case RECEIVE_ENTITY_MEDIA:
      return {
        ...state,
        entityMedia: action.payload.entityMedia
      };

    case CLEAR_MEDIA:
      return {
        ...state,
        entityMedia: null
      };

    case RECEIVE_ENTITY_POSITION_UPDATES:
      return {
        ...state,
        entityPositionUpdates: action.payload
      };

    case RECEIVE_ENTITY_CONNECTED_CAR_POSITION_UPDATES:
      return {
        ...state,
        entityConnectedCarPositionUpdates: action.payload
      };

    case RECEIVE_ENTITY_EXCEPTIONS:
      return {
        ...state,
        entityExceptions: action.payload.entityExceptions
      };

    case RECEIVE_ENTITY_HOLDS:
      return {
        ...state,
        entityHolds: action.payload.entityHolds
      };

    case RECEIVE_ENTITY_EVENTS:
      return {
        ...state,
        entityEvents: action.payload.entityEvents
      };

    case SHOW_CONNECTED_CAR_POSITIONS:
      return {
        ...state,
        isShowingConnectedCarPositions: true
      };

    case HIDE_CONNECTED_CAR_POSITIONS:
      return {
        ...state,
        isShowingConnectedCarPositions: false
      };

    default:
      return state;
  }
};

// interface
const FinVehicleEntityDetailsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchEntityDetails,
    clearEntityMedia,
    fetchEntityMedia,
    fetchEntityExceptions,
    fetchEntityHolds,
    fetchEntityPositionUpdates,
    fetchEntityEvents,
    setWatchEntity,
    showConnectedCarPositions,
    hideConnectedCarPositions
  },
  selectors: {
    getCombinedEntityExceptions,
    getEntityCurrentLocation,
    getEntityExceptions,
    getEntityHolds,
    getEntityDetails,
    getEntityPositionUpdates,
    getEntityConnectedCarPositionUpdates,
    getEntityEvents,
    getEntityMedia,
    getIsShowingConnectedCarPositions
  },
  reducer: FinVehicleEntityDetailsReducer
};
export default FinVehicleEntityDetailsState;
