import { isEnvironment } from "../../config";

// Hack: have Test Shipper as an option only in development environments
export const SHIPPERS = isEnvironment("development")
  ? [
      { value: "FORD", label: "Ford Motor Company" },
      { value: "FV3221778A", label: "Test Shipper" }
    ]
  : [{ value: "FORD", label: "Ford Motor Company" }];

export const ROLES = [
  { value: "Origin Yard Operator", label: "Origin Yard Operator" },
  { value: "Inbound Yard Operator", label: "Inbound Yard Operator" },
  { value: "Port Processor", label: "Port Processor" },
  { value: "Over the Road", label: "Over the Road" },
  { value: "Ocean Port", label: "Ocean Port" },
  { value: "Mod Center", label: "Mod Center" },
  { value: "Upfitter", label: "Upfitter" },
  { value: "Rail", label: "Rail" }
];

// Hack: The solution Id for the shippers is not currently available in the organzation data
//   using a hardcoded lookup for now, it would require an additonal api call to return the string
export const SHIPPER_SOLUTION_IDS = {
  FORD: "FORD_FV",
  FV3221778A: "FV_TEST"
};

const milestoneOptions = {
  "Origin Yard Operator": [
    { value: "R1", label: "Vehicle Received" },
    { value: "XB", label: "Inspection Complete" },
    { value: "A9", label: "Damage Identified" },
    { value: "CP", label: "VIN Loaded on Railcar" },
    { value: "D1", label: "VIN Unloaded from Railcar" },
    { value: "J1", label: "Released to Carrier" },
    { value: "A3", label: "Returned to Plant" },
    { value: "OA", label: "Compound Exit" },
    { value: "PR", label: "Hold Set" },
    { value: "AI", label: "Hold Released" },
    { value: "SD", label: "Delayed in Transit" },
    { value: "X1", label: "Delivered" }
  ],
  "Inbound Yard Operator": [
    { value: "R1", label: "Vehicle Received" },
    { value: "XB", label: "Inspection Complete" },
    { value: "A9", label: "Damage Identified" },
    { value: "CP", label: "VIN Loaded on Railcar" },
    { value: "D1", label: "VIN Unloaded from Railcar" },
    { value: "J1", label: "Released to Carrier" },
    { value: "A3", label: "Returned to Plant" },
    { value: "OA", label: "Compound Exit" },
    { value: "PR", label: "Hold Set" },
    { value: "AI", label: "Hold Released" },
    { value: "SD", label: "Delayed in Transit" },
    { value: "X1", label: "Delivered" }
  ],
  "Port Processor": [
    { value: "R1", label: "Vehicle Received" },
    { value: "XB", label: "Inspection Complete" },
    { value: "A9", label: "Damage Identified" },
    { value: "AV", label: "Work Scheduled" },
    { value: "X2", label: "Work Estimated Complete" },
    { value: "X6", label: "Work Started" },
    { value: "X5", label: "Work Completed" },
    { value: "J1", label: "Released to Carrier" },
    { value: "OA", label: "Compound Exit" },
    { value: "PR", label: "Hold Set" },
    { value: "AI", label: "Hold Released" },
    { value: "SD", label: "Delayed in Transit" },
    { value: "CP", label: "VIN Loaded on Railcar" },
    { value: "K1", label: "Released to Mod Center" },
    { value: "X1", label: "Delivered" }
  ],
  "Over the Road": [
    { value: "R1", label: "Vehicle Received" },
    { value: "XB", label: "Inspection Complete" },
    { value: "A9", label: "Damage Identified" },
    { value: "AG", label: "Estimated Delivery" },
    { value: "AF", label: "Departed" },
    { value: "SD", label: "Delayed in Transit" },
    { value: "X1", label: "Delivered" },
    { value: "PR", label: "Hold Set" },
    { value: "AI", label: "Hold Released" }
  ],
  "Ocean Port": [
    { value: "CO", label: "Received at Port of Loading" },
    { value: "NT", label: "Inspection Complete" },
    { value: "B", label: "Damage Identified" },
    { value: "AE", label: "On-Board Vessel at Port of Loading" },
    { value: "DTM02-L-139", label: "Estimated Departure from Port of Loading" },
    { value: "VD", label: "Departed from Port of Loading" },
    { value: "VA", label: "Arrived at Place of Delivery" },
    { value: "VD-T", label: "Departed from Transship Port" },
    { value: "DTM02-D-139", label: "Estimated Arrival at Place of Delivery" },
    { value: "VA-T", label: "Arrived at Transship Port" },
    { value: "UV", label: "Discharged at Place of Delivery" },
    { value: "SD", label: "Delayed in Transit" },
    { value: "PR", label: "Hold Set" },
    { value: "AI", label: "Hold Released" },
    { value: "XB", label: "Inspection Complete" },
    { value: "AE-L-L", label: "On-Board Vessel at Port of Loading" },
    { value: "A9", label: "Damage Identified" },
    { value: "I-E-D", label: "Empty Container Returned" },
    { value: "X2-L-D", label: "Estimated Time of Cargo Delivery" },
    { value: "X1-L-D", label: "Full Container Delivery" },
    {
      value: "UV-L-D",
      label: "Full Container Discharged at Port of Discharge"
    },
    { value: "I-L-L", label: "Full In Gate at Ocean Terminal" },
    { value: "OA-L-D", label: "Full Out Gate from Ocean Terminal" },
    { value: "AR-L-E", label: "Off Rail (Destination)" },
    { value: "AR-L-I", label: "Off Rail (Interim)" },
    { value: "AR-L-L", label: "Off Rail (Origin)" },
    { value: "AL-L-D", label: "On Rail (Destination)" },
    { value: "AL-L-I", label: "On Rail (Interim)" },
    { value: "AL-L-R", label: "On Rail (Origin)" },
    { value: "R1", label: "Vehicle Received" },
    { value: "VA-L-D", label: "Vessel Arrived at Port of Discharge" },
    { value: "VA-L-Y", label: "Vessel Arrived at Transship Point" },
    { value: "VD-L-L", label: "Vessel Departed from Port of Loading" },
    { value: "VD-L-Y", label: "Vessel Departed from Transship Point" },
    { value: "E-L-D", label: "Vessel Estimated Time of Arrival" },
    { value: "C-L-L", label: "Vessel Estimated Time of Departure" }
  ],
  "Mod Center": [
    { value: "R1", label: "Vehicle Received" },
    { value: "XB", label: "Inspection Complete" },
    { value: "A9", label: "Damage Identified" },
    { value: "AV", label: "Work Scheduled" },
    { value: "X2", label: "Work Estimated Complete" },
    { value: "X6", label: "Work Started" },
    { value: "X5", label: "Work Completed" },
    { value: "J1", label: "Released to Carrier" },
    { value: "OA", label: "Compound Exit" },
    { value: "PR", label: "Hold Set" },
    { value: "AI", label: "Hold Released" },
    { value: "SD", label: "Delayed in Transit" },
    { value: "X1", label: "Delivered" }
  ],
  Upfitter: [
    { value: "R1", label: "Vehicle Received" },
    { value: "XB", label: "Inspection Complete" },
    { value: "A9", label: "Damage Identified" },
    { value: "AV", label: "Work Scheduled" },
    { value: "X2", label: "Work Estimated Complete" },
    { value: "X6", label: "Work Started" },
    { value: "X5", label: "Work Completed" },
    { value: "J1", label: "Released to Carrier" },
    { value: "OA", label: "Compound Exit" },
    { value: "PR", label: "Hold Set" },
    { value: "AI", label: "Hold Released" },
    { value: "SD", label: "Delayed in Transit" },
    { value: "X1", label: "Delivered" }
  ],
  Rail: [
    { value: "A9", label: "Damage Identified" },
    { value: "CP", label: "VIN Loaded on Railcar" },
    { value: "SD", label: "Delayed in Transit" },
    { value: "PR", label: "Hold Set" },
    { value: "AI", label: "Hold Released" },
    { value: "BAD", label: "Bad Order" },
    { value: "ICH", label: "Interchange Arrived" },
    { value: "ICR", label: "Interchange Complete" },
    { value: "ARR", label: "Arrived" },
    { value: "SPT", label: "Railcar Spotted" },
    { value: "PRB", label: "Damage Identified" },
    { value: "OA", label: "Compound Exit" },
    { value: "TDL", label: "Delayed" },
    { value: "X1", label: "Delivered" },
    { value: "AG", label: "Estimated Delivery" },
    { value: "XB", label: "Inspection Complete" },
    { value: "D1", label: "VIN Unloaded from Railcar" },
    { value: "TMD", label: "Rail Departed" },
    { value: "ERD", label: "Rail Estimated Arrival" },
    { value: "J1", label: "Released to Carrier" },
    { value: "IRD", label: "Railcar Location Update" },
    { value: "X3", label: "Vehicle Location Update" },
    { value: "R1", label: "Vehicle Received" }
  ]
};

const reasonCodeOptions = {
  A9: [
    { value: "BK", label: "Onsite Repair" },
    { value: "BT", label: "Offsite Repair" }
  ],
  SD: [
    { value: "BM", label: "Equipment Shortage" },
    { value: "S1", label: "Labor Shortage" },
    { value: "AH", label: "Labor Strike" },
    { value: "AO", label: "Weather Related" },
    { value: "AI", label: "Conveyance Mechanical Failure" },
    { value: "P1", label: "Mass Vehicle Release/ Batch and Hold" },
    { value: "A1", label: "Next Compound Unable to Accept Vehicle" },
    { value: "AJ", label: "Miscellaneous" },
    { value: "A5", label: "Vehicle Confirmed as Stolen" }
  ],
  PR: [
    { value: "BN", label: "Commercial" },
    { value: "AP", label: "Logistics" },
    { value: "BL", label: "Quality" },
    { value: "AM", label: "Miscellaneous" },
    { value: "C6", label: "Launch/OKTB" },
    { value: "AK", label: "Vehicle Total Loss/Scrap" }
  ]
};

// ---------

const VinListInput = {
  key: "VIN_LIST",
  label: "VIN List (if multiple VINs, line separate)",
  type: "list",
  required: true
};

const MilestoneEventSelect = {
  key: "MILESTONE_EVENT",
  label: "Milestone Event",
  type: "select",
  options: milestoneOptions,
  dependency: "ROLE",
  required: true
};

// Paired with Milestone Event, not editable
const MilestoneStatusCodeInput = {
  key: "MILESTONE_STATUS_CODE",
  label: "Milestone Status Code",
  type: "input",
  editable: false,
  dependency: "MILESTONE_EVENT"
};

// Options change based on Milestone selected
const ReasonCodeSelect = {
  key: "REASON_CODE",
  label: "Reason Code",
  type: "select",
  options: reasonCodeOptions,
  dependency: "MILESTONE_EVENT"
};

const YardCodeInput = {
  key: "YARD_CODE",
  label: "Yard Code",
  type: "input",
  required: false
};

const CompoundCodeInput = {
  key: "COMPOUND_CODE",
  label: "Compound Code",
  type: "input",
  required: false
};

const BayLocationInput = {
  key: "BAY_LOCATION",
  label: "Bay Location",
  type: "input",
  required: false
};

const StatusDateTimePicker = {
  key: "STATUS_DATE_TIME",
  label: "Status Date/Time",
  type: "datetime",
  required: true
};

// Api call to populate
const StatusLocationSelect = {
  key: "STATUS_LOCATION",
  label: "Status Location",
  type: "shipperlocationselect",
  options: null,
  required: true
};

const BillOfLadingInput = {
  key: "BILL_OF_LADING",
  label: "Bill of Lading Number",
  type: "input",
  required: {
    default: true,
    dependency: "ROLE",
    "Over the Road": {
      dependency: "MILESTONE_EVENT",
      R1: false, // Vehicle Received
      XB: false, // Inspection Complete
      A9: false, // Damage Identified
      AG: false, // Estimated Delivery
      AF: true, // Departed
      SD: true, // Delayed in Transit
      X1: true, // Delivered
      PR: false, // Hold Set
      AI: false // Hold Released
    },
    "Ocean Port": false
  }
};

const NextCarrierInput = {
  key: "NEXT_CARRIER",
  label: "Next Carrier",
  type: "input",
  required: false
};

const TrailerInput = {
  key: "TRAILER",
  label: "Trailer Number",
  type: "input",
  required: {
    default: true,
    dependency: "ROLE",
    "Over the Road": {
      dependency: "MILESTONE_EVENT",
      R1: false, // Vehicle Received
      XB: false, // Inspection Complete
      A9: false, // Damage Identified
      AG: false, // Estimated Delivery
      AF: true, // Departed
      SD: true, // Delayed in Transit
      X1: true, // Delivered
      PR: false, // Hold Set
      AI: false // Hold Released
    }
  }
};

const ShipTripIdInput = {
  key: "SHIP_TRIP_ID",
  label: "Ship ID/Trip ID",
  type: "input",
  required: {
    default: true,
    dependency: "ROLE",
    "Over the Road": {
      dependency: "MILESTONE_EVENT",
      R1: false, // Vehicle Received
      XB: false, // Inspection Complete
      A9: false, // Damage Identified
      AG: false, // Estimated Delivery
      AF: true, // Departed
      SD: true, // Delayed in Transit
      X1: true, // Delivered
      PR: false, // Hold Set
      AI: false // Hold Released
    }
  }
};

const LoadTypeInput = {
  key: "LOAD_TYPE",
  label: "Load Type",
  type: "input",
  required: {
    default: true,
    dependency: "ROLE",
    "Over the Road": {
      dependency: "MILESTONE_EVENT",
      R1: false, // Vehicle Received
      XB: false, // Inspection Complete
      A9: false, // Damage Identified
      AG: false, // Estimated Delivery
      AF: true, // Departed
      SD: true, // Delayed in Transit
      X1: true, // Delivered
      PR: false, // Hold Set
      AI: false // Hold Released
    },
    "Ocean Port": false
  }
};

const LaneTypeSelect = {
  key: "LANE_TYPE",
  label: "Lane Type",
  type: "select",
  options: [
    { value: "C2C", label: "Compound to Compound" },
    { value: "C2Y", label: "Compound to Yard" }
  ],
  required: true
};

const CarrierInput = {
  key: "CARRIER",
  label: "Carrier",
  type: "input",
  required: {
    default: true,
    dependency: "ROLE",
    "Ocean Port": false
  }
};

const BookingInput = {
  key: "BOOKING",
  label: "Booking Number",
  type: "input",
  required: false
};

const VoyageInput = {
  key: "VOYAGE",
  label: "Voyage Number",
  type: "input",
  required: false
};

const VesselInput = {
  key: "VESSEL",
  label: "Vessel Name",
  type: "input",
  required: false
};

const PortOfLoadingInput = {
  key: "PORT_OF_LOADING",
  label: "Port of Loading",
  type: "input",
  required: false
};

const TransshipPortInput = {
  key: "TRANSSHIP_PORT",
  label: "Transship Port",
  type: "input",
  required: false
};

const PortOfDischargeInput = {
  key: "PORT_OF_DISCHARGE",
  label: "Port of Discharge",
  type: "input",
  required: false
};

const BillOfLadingOriginInput = {
  key: "BILL_OF_LADING_ORIGIN_INPUT",
  label: "Bill of Lading Origin",
  type: "input",
  required: {
    default: true,
    dependency: "ROLE",
    "Ocean Port": false
  }
};

const BillOfLadingDestinationInput = {
  key: "BILL_OF_LADING_DESTINATION_INPUT",
  label: "Bill of Lading Destination",
  type: "input",
  required: {
    default: true,
    dependency: "ROLE",
    "Ocean Port": false
  }
};

const BayCodeInput = {
  key: "BAY_CODE_INPUT",
  label: "Bay Code",
  type: "input",
  required: false
};

const WaybillInput = {
  key: "WAYBILL_INPUT",
  label: "Waybill Number",
  type: "input",
  required: true
};

const RailCarInput = {
  key: "RAIL_CAR_INPUT",
  label: "Rail Car Number",
  type: "input",
  required: true
};

// Locations from customer/shipper org
const ShipToInput = {
  key: "SHIP_TO",
  label: "Ship To",
  type: "shipperlocationselect",
  options: null,
  required: true
};

const ShipFromInput = {
  key: "SHIP_FROM",
  label: "Ship From",
  type: "shipperlocationselect",
  options: null,
  required: true
};

const CommentsInput = {
  key: "COMMENTS",
  label: "Comments",
  type: "textarea",
  required: false
};

// ---- Field helper functions ----
const defaultDependencies = { role: "", milestoneEvent: "" };
export const isRequired = (input, dependencies = defaultDependencies) => {
  return isRequiredHelper(input.required, dependencies);
};

const isRequiredHelper = (valueDef, dependencies) => {
  // This was called with the final "required" value
  if (typeof valueDef === "boolean") {
    return valueDef;
  }
  // This was called with an object where we have to
  // look the next value with a "dependency"
  else if (typeof valueDef === "object") {
    if (valueDef.dependency) {
      // Peek the next value by calling this function recusively
      let nextValueDef;
      if (valueDef.dependency === "ROLE") {
        nextValueDef = isRequiredHelper(
          valueDef[dependencies.role],
          dependencies
        );
      } else if (valueDef.dependency === "MILESTONE_EVENT") {
        nextValueDef = isRequiredHelper(
          valueDef[dependencies.milestoneEvent],
          dependencies
        );
      }

      // If the next call is not undefined or null
      //
      // Note: Using this check so that we don't accidentally
      // interperet the value false as undefined or null.
      // eslint-disable-next-line
      if (nextValueDef != null) {
        return nextValueDef;
      }
    }

    // There wasn't a dependency so we will return
    // the default value
    return valueDef.default;
  }
};

// ---- Fields for Roles ----
export const ORIGIN_YARD_OPERATOR = [
  VinListInput,
  MilestoneEventSelect,
  MilestoneStatusCodeInput,
  ReasonCodeSelect,
  YardCodeInput,
  CompoundCodeInput,
  BayLocationInput,
  StatusDateTimePicker,
  StatusLocationSelect,
  NextCarrierInput,
  CommentsInput
];

export const INBOUND_YARD_OPERATOR = [
  VinListInput,
  MilestoneEventSelect,
  MilestoneStatusCodeInput,
  ReasonCodeSelect,
  YardCodeInput,
  CompoundCodeInput,
  BayLocationInput,
  StatusDateTimePicker,
  StatusLocationSelect,
  NextCarrierInput,
  CommentsInput
];

export const PORT_PROCESSOR = [
  VinListInput,
  MilestoneEventSelect,
  MilestoneStatusCodeInput,
  ReasonCodeSelect,
  YardCodeInput,
  CompoundCodeInput,
  BayLocationInput,
  StatusDateTimePicker,
  StatusLocationSelect,
  NextCarrierInput,
  CommentsInput
];

export const OVER_THE_ROAD = [
  VinListInput,
  MilestoneEventSelect,
  MilestoneStatusCodeInput,
  ReasonCodeSelect,
  BillOfLadingInput,
  TrailerInput,
  ShipTripIdInput,
  LoadTypeInput,
  LaneTypeSelect,
  CarrierInput,
  ShipToInput,
  ShipFromInput,
  StatusDateTimePicker,
  StatusLocationSelect,
  CommentsInput
];

export const OCEAN_PORT = [
  VinListInput,
  MilestoneEventSelect,
  MilestoneStatusCodeInput,
  ReasonCodeSelect,
  BillOfLadingInput,
  BookingInput,
  VoyageInput,
  LoadTypeInput,
  VesselInput,
  CarrierInput,
  PortOfLoadingInput,
  TransshipPortInput,
  PortOfDischargeInput,
  BillOfLadingOriginInput,
  BillOfLadingDestinationInput,
  StatusDateTimePicker,
  StatusLocationSelect,
  CommentsInput
];

export const MOD_CENTER = [
  VinListInput,
  MilestoneEventSelect,
  MilestoneStatusCodeInput,
  ReasonCodeSelect,
  YardCodeInput,
  CompoundCodeInput,
  BayCodeInput,
  StatusDateTimePicker,
  StatusLocationSelect,
  NextCarrierInput,
  CommentsInput
];

export const UPFITTER = [
  VinListInput,
  MilestoneEventSelect,
  MilestoneStatusCodeInput,
  YardCodeInput,
  CompoundCodeInput,
  BayCodeInput,
  StatusDateTimePicker,
  StatusLocationSelect,
  NextCarrierInput,
  CommentsInput
];

export const RAIL = [
  VinListInput,
  MilestoneEventSelect,
  MilestoneStatusCodeInput,
  WaybillInput,
  RailCarInput,
  CarrierInput,
  BillOfLadingOriginInput,
  BillOfLadingDestinationInput,
  ShipToInput,
  ShipFromInput,
  StatusDateTimePicker,
  StatusLocationSelect,
  CommentsInput
];

// ---------

export const ROLE_FORM_MAP = {
  "Origin Yard Operator": ORIGIN_YARD_OPERATOR,
  "Inbound Yard Operator": INBOUND_YARD_OPERATOR,
  "Port Processor": PORT_PROCESSOR,
  "Over the Road": OVER_THE_ROAD,
  "Ocean Port": OCEAN_PORT,
  "Mod Center": MOD_CENTER,
  Upfitter: UPFITTER,
  Rail: RAIL
};
