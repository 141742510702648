/** @jsx jsx */
// eslint-disable-next-line
import React from "react";
import { jsx } from "@emotion/core";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import TimeForceGraph from "./heat-graphs/time-force-graph";
import { getLocationDataFromShipment } from "./shipment-detail-styled-components/OriginDestinationBox";
import { withSize } from "react-sizeme";
import moment from "moment";

const getDatetimeEndpoint = (loc, showETA) => {
  const hasArrived = loc.actual_arrival_datetime !== null;
  let expectedActualTime;

  if (hasArrived) {
    expectedActualTime = loc.actual_arrival_datetime;
  } else {
    if (showETA && loc.calculated_eta !== null) {
      expectedActualTime = loc.calculated_eta;
    } else {
      expectedActualTime = loc.latest_arrival_datetime;
    }
  }

  return expectedActualTime;
};

const iconStyle = {
  fontSize: 20,
  cursor: "pointer"
};

const iconDivStyle = {
  width: 25,
  margin: "0 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
};

const parseEventData = (eventRefs, key) => {
  if (eventRefs === undefined) {
    return [];
  }
  return eventRefs
    .filter(e => e.qualifier === key)
    .map(event => {
      return {
        timestamp: moment(event.created_at).unix(),
        readable_timestamp: moment(event.created_at).format("lll"),
        y: parseInt(event.value, 10)
      };
    });
};

class TimeForceCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showIdx: 0,
      accelData: [],
      tempData: []
    };
    this.onGraphChange = this.onGraphChange.bind(this);
  }

  componentDidMount() {
    const { shipment } = this.props;
    const accelData = parseEventData(shipment.event_references, "g-force");
    const tempData = parseEventData(shipment.event_references, "temp");
    this.setState({
      accelData: accelData,
      tempData: tempData,
      showIdx: accelData.length === 0 ? 1 : 0
    });
  }

  onGraphChange(newIdx) {
    this.setState({ showIdx: newIdx });
  }

  render() {
    const { showIdx, accelData, tempData } = this.state;
    const { size, shipment } = this.props;
    const { origin, destination } = getLocationDataFromShipment(shipment);
    const start = getDatetimeEndpoint(origin, true);
    const end = getDatetimeEndpoint(destination, true);
    const title = showIdx === 0 ? "G Force" : "Temperature";
    const dangerThreshold = showIdx === 0 ? 8 : 90;
    const data = showIdx === 0 ? accelData : tempData;
    return (
      <div
        css={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <div css={iconDivStyle}>
          {showIdx === 1 && accelData.length > 0 ? (
            <FaArrowLeft
              style={iconStyle}
              onClick={() => this.onGraphChange(0)}
            />
          ) : null}
        </div>
        {data.length > 0 ? (
          <TimeForceGraph
            size={size}
            title={title}
            data={data}
            dangerThreshold={dangerThreshold}
            start={start}
            end={end}
          />
        ) : null}
        <div css={iconDivStyle}>
          {showIdx === 0 && tempData.length > 0 ? (
            <FaArrowRight
              style={iconStyle}
              onClick={() => this.onGraphChange(1)}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
export default withSize()(TimeForceCarousel);
