/**@jsx jsx */
import PropTypes from "prop-types";
import { jsx } from "@emotion/core";
import React, { useState, useContext } from "react";
import { FaCaretDown } from "react-icons/fa";

import { FlexDiv } from "../../styles/container-elements";
import Colors from "../../styles/colors";

const contentAnim = {
  duration: 333,
  easing: "ease-in-out"
};

const iconAnim = {
  duration: 150,
  easing: "ease-in"
};

const borderRadius = 4;

const PanelGroupCollapsibleContext = React.createContext({
  collapsed: false,
  setCollapsed: () => {}
});

export const PanelGroup = props => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <div
      css={{
        backgroundColor: "white",
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.1)",
        margin: "1em",
        borderRadius
      }}
    >
      <PanelGroupCollapsibleContext.Provider
        value={{ collapsed, setCollapsed }}
      >
        {props.children}
      </PanelGroupCollapsibleContext.Provider>
    </div>
  );
};

PanelGroup.propTypes = {
  children: PropTypes.node.isRequired
};

export const PanelGroupHeader = props => {
  const { title, children } = props;

  const { collapsed, setCollapsed } = useContext(PanelGroupCollapsibleContext);

  return (
    <header
      css={{
        color: Colors.tableHeadingText,
        backgroundColor: Colors.tableHeadingBackground,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0.5em 0.5em 0.5em 1em",
        position: "relative",
        borderRadius,
        borderBottomLeftRadius: collapsed ? borderRadius : 0,
        borderBottomRightRadius: collapsed ? borderRadius : 0,
        transition: `border-radius ${contentAnim.duration}ms ${contentAnim.easing}`
      }}
    >
      <FlexDiv style={{ maxWidth: "50%" }}>
        <div
          css={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // This is the height of the Action button used
            //   with the Details group in the Shipments panel
            // Applying this min-height makes the headers
            //   have some consistent height
            minHeight: 34
          }}
        >
          <button
            onClick={() => setCollapsed(!collapsed)}
            css={{
              marginRight: "0.5em",
              fontSize: "1.25em",
              border: "none",
              background: "transparent",
              display: "flex",
              ":hover": {
                // TODO
              },
              ":active": {
                // TODO
              }
            }}
          >
            <FaCaretDown
              className="text-white"
              css={{
                transform: `rotate(${collapsed ? -90 : 0}deg)`,
                transition: `transform ${iconAnim.duration}ms ${iconAnim.easing}`
              }}
            />
          </button>

          <h3 css={{ fontSize: "1.2em", margin: "0", padding: "0" }}>
            {title}
          </h3>
        </div>
      </FlexDiv>
      {children}
    </header>
  );
};

export const PanelGroupContent = props => {
  const { collapsed } = useContext(PanelGroupCollapsibleContext);

  return (
    <div
      css={{
        overflow: "hidden",
        maxHeight: collapsed ? 0 : 1000,
        transition: `max-height ${contentAnim.duration}ms ${contentAnim.easing}`
      }}
    >
      <div css={props.style}>{props.children}</div>
    </div>
  );
};

PanelGroupContent.defaultProps = {
  style: { padding: "1em" }
};
