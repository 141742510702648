/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FormControl,
  FormGroup,
  InputGroup,
  Modal,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { withTranslation } from "react-i18next";
import fileDownload from "js-file-download";
import { isNil } from "lodash";
import { FaQuestionCircle } from "react-icons/fa";

import { clearActionStatus, importUsers } from "../UsersState";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormButton,
  FormAlert
} from "../../../components/modal-elems";
import Colors from "../../../styles/colors";
import { FlexRowDiv, FlexDiv } from "../../../styles/container-elements";

const IMPORT_TIMEOUT_MILIS = 30000;

const importFileValid = ({ importFile, importCsv }) => {
  return !isNil(importFile) && !isNil(importCsv);
};

const isValidForm = users => {
  return importFileValid(users);
};

const initUser = {
  importCsv: null,
  importFile: null,
  autoCloseWindowTimer: null
};

const ImportUsersModal = ({
  actionStatus,
  clearActionStatus,
  hide,
  importUsers,
  show,
  t
}) => {
  const [users, setUsers] = useState(initUser);

  useEffect(() => {
    if (actionStatus && actionStatus.includes("USERS_IMPORTED")) {
      setTimeout(() => {
        clearActionStatus();
        clearForm();
        hide();
      }, 3000);
    }
  }, [actionStatus, hide, clearActionStatus]);

  const onFileLoad = file => {
    const csv = file.target.result.trim();
    const lines = csv.split(/\r\n|\n/);
    if (lines.length > 1) {
      if (actionStatus) {
        clearActionStatus();
      }
      setUsers(prevState => {
        return { ...prevState, importCsv: csv };
      });
    }
  };

  const fileSelectHandler = newFile => {
    const reader = new FileReader();
    reader.readAsText(newFile);
    reader.onload = onFileLoad;

    setUsers(prevState => {
      return { ...prevState, importFile: newFile };
    });
  };

  const fileDropHandler = newItems => {
    if (newItems && newItems.length === 1) {
      const file = newItems[0].getAsFile();

      // Read the schedule name
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = onFileLoad;

      setUsers(prevState => {
        return { ...prevState, importFile: file };
      });
    }
  };

  const downloadUsersTemplate = () => {
    const exportFileName = `fv-users-template-${Date.now()}.csv`;
    const templateContent = `${t("users:Email")},${t("users:Roles")}`;
    fileDownload(templateContent, exportFileName);
  };

  const clearForm = () => {
    setUsers(initUser);
  };

  const disableClosingTimer = () => {
    clearTimeout(users.autoCloseWindowTimer);
  };

  const enableClosingTimer = () => {
    const closingTimer = setTimeout(() => {
      console.log(
        `Closing window after waiting for ${IMPORT_TIMEOUT_MILIS /
          1000.0} seconds`
      );
      hide();
    }, IMPORT_TIMEOUT_MILIS);

    setUsers(prevState => {
      return { ...prevState, autoCloseWindowTimer: closingTimer };
    });
  };

  const csvTooltip = (
    <Tooltip id="cvs-tooltip">
      <div
        css={{
          padding: ".75em",
          textAlign: "left"
        }}
      >
        {t("users:The file must contain both")}:
        <ul style={{ paddingLeft: "1.25em" }}>
          <li>{t("users:Email address")}</li>
          <li>{t("users:Role Designation")}</li>
        </ul>
      </div>
    </Tooltip>
  );

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        clearForm();
        hide();
      }}
    >
      <ModalHeader title={t("users:Import")} />
      <ModalBody>
        <div>
          {actionStatus && actionStatus === "USERS_IMPORTED" ? (
            <FormAlert
              alertType={"SUCCESS"}
              alert={t(
                "users:Imported accounts have been created successfully"
              )}
            />
          ) : null}
          {actionStatus &&
          actionStatus === "Import_Error" &&
          disableClosingTimer() ? (
            <FormAlert
              alertType={"FAIL"}
              alert={t(
                "users:Import failed - The file format you uploaded does not match the upload criteria"
              )}
            />
          ) : null}
          {actionStatus &&
          actionStatus === "Permission_Error" &&
          disableClosingTimer() ? (
            <FormAlert
              alertType={"FAIL"}
              alert={t(
                "users:Import failed - User with role ORGANIZATION_ADMIN does not have permission to create user with role freightverify_admin"
              )}
            />
          ) : null}

          <FlexRowDiv css={{ marginBottom: "1em" }}>
            <span>{t("users:Select a CSV file to import")}.</span>{" "}
            <OverlayTrigger placement="right" overlay={csvTooltip}>
              <FaQuestionCircle />
            </OverlayTrigger>
            <FlexDiv
              css={{
                color: "#999",
                marginLeft: "1em",
                fontStyle: "italic",
                textDecoration: "underline",
                ":hover": {
                  cursor: "pointer",
                  color: Colors.background.DARK_BLUE
                }
              }}
              onClick={() => downloadUsersTemplate()}
            >
              {t("users:Download Template")}
            </FlexDiv>
          </FlexRowDiv>
          <FormGroup>
            <InputGroup>
              <label className="input-group-btn">
                <span className="btn btn-light">
                  {t("users:Browse")}&hellip;{" "}
                  <input
                    id="files"
                    type="file"
                    accept=".csv"
                    title={null}
                    onChange={e => {
                      fileSelectHandler(e.target.files[0]);
                    }}
                    hidden
                  />
                </span>
              </label>

              <FormControl
                type="type"
                value={users.importFile ? users.importFile.name : ""}
                placeholder={t("users:Select file")}
                htmlFor="files"
                onDragOver={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onDrop={e => {
                  e.stopPropagation();
                  e.preventDefault();

                  fileDropHandler(e.dataTransfer.items);
                }}
                readOnly
              />
            </InputGroup>
          </FormGroup>
        </div>
      </ModalBody>
      <ModalFooter>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={t("users:Cancel")}
          clickHandler={() => hide()}
        />
        <FormButton
          disabled={!isValidForm(users)}
          actionType="ACTION"
          label={t("users:Import")}
          clickHandler={() => {
            const payload = {
              csv: users.importCsv
            };

            importUsers(payload) && enableClosingTimer();
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

ImportUsersModal.propTypes = {
  importUsers: PropTypes.func.isRequired,
  actionStatus: PropTypes.string,
  clearActionStatus: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    actionStatus: state.users.actionStatus
  };
}

const mapDispatchToProps = {
  clearActionStatus,
  importUsers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["users"])(ImportUsersModal));
