/** @jsx jsx */
import React from "react";
import { jsx } from "@emotion/core";
import PropTypes from "prop-types";
import { Component } from "react";
import Loader from "react-loader";
import sizeMe from "react-sizeme";
import styled from "@emotion/styled";
import ReportsComponent from "./ReportsComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faTrashAlt } from "@fortawesome/pro-regular-svg-icons";
import MixpanelUtils from "../../trackers/mixpanel";
import { Translation } from "react-i18next";
import { Privileges } from "../auth/Authorization";
import {
  localizedTimeFormatter,
  localizedDateFormatter
} from "../../utils/date-time";
import Dialog from "react-bootstrap-dialog";

const Section = styled.section({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between"
});

const HeaderSection = styled.section({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  backgroundColor: "white"
});

class ReportDetailView extends Component {
  static propTypes = {
    fetchReport: PropTypes.func.isRequired,
    selectedReportId: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    report: PropTypes.object,
    saveReport: PropTypes.func.isRequired,
    isSaving: PropTypes.bool.isRequired,
    updateReport: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    deleteReport: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    pushBIDashboardScreen: PropTypes.func.isRequired,
    authorization: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.report = null;
  }

  componentDidMount() {
    MixpanelUtils.track("Viewed Page: BI / Reports / Details");

    const { selectedReportId, fetchReport } = this.props;

    fetchReport(selectedReportId);

    Dialog.setOptions({
      defaultOkLabel: "Delete",
      defaultCancelLabel: "Cancel",
      primaryClassName: "btn-success"
    });
  }

  setReport = report => {
    this.report = report;
  };

  saveReport = data => {
    const { saveReport, pushBIDashboardScreen } = this.props;

    // Figure out whether this is a private report or not based on the location value
    if (data.location === "my") {
      data.private = true;
    } else if (data.location === "shared") {
      data.private = false;
    } else {
      console.error("Invalid location specified: ", data.location);
    }

    // No longer need location value
    delete data.location;

    saveReport(data).then(() => {
      // Redirect to BI Dashboard after saving report
      pushBIDashboardScreen();
    });
  };

  onPrint = () => {
    if (this.report) {
      this.report.print();
    }
  };

  onDelete = t => {
    const { report, deleteReport, pushBIDashboardScreen } = this.props;

    this.dialog.show({
      body: t("reports:Are you sure you want to delete this report?"),
      actions: [
        Dialog.CancelAction(() => {
          this.dialog.hide();
        }),
        Dialog.OKAction(() => {
          this.dialog.hide();
          deleteReport(report.reportId).then(() => {
            // Redirect to BI Dashboard after deleting report
            pushBIDashboardScreen();
          });
        })
      ],
      bsSize: "small",
      onHide: dialog => {
        dialog.hide();
      }
    });
  };

  render() {
    const { isLoading, isDeleting, authorization, report } = this.props;
    const canDeleteReport =
      authorization.hasPrivileges([Privileges.BUILD_REPORT]) &&
      report &&
      report.groupName === "Saved";

    return (
      <Translation ns="reports">
        {t => (
          <React.Fragment>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                height: "100%"
              }}
            >
              <Loader loaded={!isLoading}>
                <HeaderSection>
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <FontAwesomeIcon
                      css={{
                        height: "1.5em",
                        width: "1.5em",
                        margin: "4px",
                        marginLeft: "1em",
                        ":hover": {
                          cursor: "pointer",
                          color: "rgba(0,0,0,0.5)"
                        }
                      }}
                      icon={faPrint}
                      alt={t("Print")}
                      onClick={this.onPrint}
                    />
                    {canDeleteReport === true && (
                      <div
                        css={{
                          position: "relative",
                          margin: "4px",
                          marginLeft: "0.5em",
                          "> .loadedContent": { display: "flex" }
                        }}
                      >
                        <Loader loaded={!isDeleting} scale={0.33}>
                          <FontAwesomeIcon
                            css={{
                              height: "1.5em",
                              width: "1.5em",
                              ":hover": {
                                cursor: "pointer",
                                color: "rgba(0,0,0,0.5)"
                              }
                            }}
                            icon={faTrashAlt}
                            alt={t("Delete Report")}
                            onClick={() => {
                              return this.onDelete(t);
                            }}
                          />
                        </Loader>
                      </div>
                    )}
                  </div>
                  <div
                    css={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "1em"
                    }}
                  >
                    Created by{" "}
                    {report && report.createdBy ? report.createdBy : "Unknown"}
                    {report && (
                      <span style={{ marginLeft: 5 }}>
                        on {localizedDateFormatter(report.lastUpdated)}{" "}
                        {localizedTimeFormatter(report.lastUpdated)}
                      </span>
                    )}
                  </div>
                </HeaderSection>
                <Section>
                  <ReportsComponent
                    {...this.props}
                    setReport={this.setReport}
                    saveReport={this.saveReport}
                  />
                </Section>
              </Loader>
            </div>
            <Dialog
              ref={el => {
                this.dialog = el;
              }}
            />
          </React.Fragment>
        )}
      </Translation>
    );
  }
}

export default sizeMe({ monitorHeight: true })(ReportDetailView);
