import { connect } from "react-redux";
import { getSolutionId } from "../../../organizations/OrganizationsState";
import AdcDetailsVinSearchBarState from "./AdcDetailsVinSearchBarState";
import ResultsTable from "../../../../components/tables/ResultsTable";

function mapStateToProps(state) {
  const locationId = state.location.payload.location_id;
  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getTotalPages,
    getTotalEntities
  } = AdcDetailsVinSearchBarState.selectors;
  return {
    locationId: locationId,
    solutionId: getSolutionId(state),
    data: getSearchResults(state),
    loading: getIsLoading(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    totalPages: getTotalPages(state),
    totalEntities: getTotalEntities(state)
  };
}

function mapDispatchToProps(dispatch) {
  const { setPagination } = AdcDetailsVinSearchBarState.actionCreators;
  return {
    rowClickHandler: rowInfo =>
      dispatch({
        type: "VIN_DETAILS",
        payload: { entity_id: rowInfo.original.id }
      }),
    setPagination: (solutionId, page, pageSize) => {
      dispatch(setPagination(solutionId, page, pageSize));
    }
  };
}

const AdcResultsTableContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResultsTable);

AdcResultsTableContainer.defaultProps = {
  sortable: false
};

export default AdcResultsTableContainer;
