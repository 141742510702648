/** @jsx jsx */
import PropTypes from "prop-types";
import { Component } from "react";
import { jsx } from "@emotion/core";
import { withTranslation } from "react-i18next";

import Colors from "../../styles/colors";
import { ExpiringRacks } from "./mt-dashboard-components/ExpiringRacks";
import ResultsTable from "../../components/tables/ResultsTable";
import { MediaQueries } from "../../components/responsive";
import SearchBarContainer from "../mt-search/MetalTrackingSearchBarContainer";
import FilterSectionContainer from "../mt-search/MetalTrackingFilterSectionContainer";
import DataHeader from "./mt-dashboard-components/DataHeader";
import SavedSearchContainer from "../mt-search/MetalTrackingSavedSearchContainer";
import { RacksByLocation } from "./mt-dashboard-components/RacksByLocation";
import { RacksByType } from "./mt-dashboard-components/RacksByType";
import { resultsTableColumns } from "./mt-dashboard-components/results-table-columns";

class MetalTrackingDashboard extends Component {
  static propTypes = {
    authorization: PropTypes.object.isRequired,
    chooseLocation: PropTypes.func,
    metalTrackingEntities: PropTypes.array,
    setSearchFilter: PropTypes.func,
    showFilters: PropTypes.bool,
    solutionId: PropTypes.string,
    t: PropTypes.func.isRequired,
    toggleWatchedRackFlag: PropTypes.func
  };

  state = {
    showFilters: this.props.showFilters
  };

  render() {
    const {
      authorization,
      solutionId,
      metalTrackingEntities,
      toggleWatchedRackFlag,
      setSearchFilter,
      chooseLocation,
      t
    } = this.props;

    const { showFilters } = this.state;
    const watchedRacks = metalTrackingEntities
      ? metalTrackingEntities.filter(obj => obj.watch === true)
      : [];

    return (
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          padding: 20
        }}
      >
        <SearchBarContainer
          isShowingFilters={showFilters}
          toggleShowFilters={() => this.setState({ showFilters: !showFilters })}
        />
        <FilterSectionContainer
          show={showFilters}
          backgroundColor={Colors.background.GRAY}
          sectionStyle={{ paddingRight: "12em" }}
        />
        <div
          css={{
            display: "flex",
            flexDirection: "column"
          }}
        >
          <div
            css={{
              [MediaQueries.smallAndUp]: {
                display: "flex",
                flexDirection: "column"
              },
              [MediaQueries.mediumAndUp]: {
                gridColumnGap: "20px",
                gridRowGap: "20px",
                justifyItems: "stretch",
                gridTemplateColumns: "repeat(6, 1fr)",
                display: "grid",
                gridTemplateRows: "420px 1fr auto"
              },
              [MediaQueries.largeAndUp]: {
                gridTemplateRows: "233px auto"
              }
            }}
          >
            <div
              css={{
                [MediaQueries.smallAndUp]: {
                  marginBottom: "20px"
                },
                [MediaQueries.mediumAndUp]: {
                  marginBottom: 0,
                  gridColumn: "1 / span 2"
                },
                [MediaQueries.largeAndUp]: {
                  gridColumn: "1 / span 2",
                  gridRow: "1 / span 1"
                }
              }}
            >
              <ExpiringRacks
                solutionId={solutionId}
                metalTrackingEntities={metalTrackingEntities}
                setSearchFilter={setSearchFilter}
              />
            </div>
            <div
              css={{
                [MediaQueries.smallAndUp]: {
                  marginBottom: "20px"
                },
                [MediaQueries.mediumAndUp]: {
                  marginBottom: 0,
                  gridColumn: "3 / span 2",
                  gridRow: "1 / span 1"
                },
                [MediaQueries.largeAndUp]: {
                  gridColumn: "3 / span 2",
                  gridRow: "1 / span 1"
                }
              }}
            >
              <RacksByLocation
                solutionId={solutionId}
                metalTrackingEntities={metalTrackingEntities}
                setSearchFilter={setSearchFilter}
              />
            </div>
            <div
              css={{
                [MediaQueries.smallAndUp]: {
                  marginBottom: "20px"
                },
                [MediaQueries.mediumAndUp]: {
                  marginBottom: 0,
                  gridColumn: "5 / span 2",
                  gridRow: "1 / span 1"
                },
                [MediaQueries.largeAndUp]: {
                  gridColumn: "5 / span 2",
                  gridRow: "1 / span 1"
                }
              }}
            >
              <RacksByType
                solutionId={solutionId}
                metalTrackingEntities={metalTrackingEntities}
                setSearchFilter={setSearchFilter}
              />
            </div>
            <div
              css={{
                [MediaQueries.smallAndUp]: {
                  gridColumn: "1 / span 6",
                  gridRow: "3 / auto"
                },
                [MediaQueries.mediumAndUp]: {
                  gridColumn: "1 / span 6",
                  gridRow: "2 / auto"
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <DataHeader
                  title={t("mt-dashboard:Watched Racks")}
                  total={watchedRacks.length || 0}
                />
                <SavedSearchContainer />
              </div>

              <ResultsTable
                manual={false}
                rowClickHandler={() => {}}
                data={watchedRacks}
                fixPaginationToBottom={false}
                columns={() =>
                  resultsTableColumns(
                    t,
                    solutionId,
                    authorization,
                    toggleWatchedRackFlag,
                    chooseLocation
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(["mt-dashboard"])(MetalTrackingDashboard);
