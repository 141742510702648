import React from "react";

// This icon is from material design but it's not included in react-icons.
// Generated with @svgr/cli.
export const HandshakeIcon = props => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="currentColor"
      style={{ verticalAlign: "middle" }}
      {...props}
    >
      <path d="M11 6h3l3.29-3.3a1 1 0 011.42 0l2.58 2.59a1 1 0 010 1.41L19 9h-8v2a1 1 0 01-1 1 1 1 0 01-1-1V8a2 2 0 012-2m-6 5v4l-2.29 2.29a1 1 0 000 1.41l2.58 2.59a1 1 0 001.42 0L11 17h4a1 1 0 001-1v-1h1a1 1 0 001-1v-1h1a1 1 0 001-1v-1h-7v1a2 2 0 01-2 2H9a2 2 0 01-2-2V9z" />
    </svg>
  );
};
