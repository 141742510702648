import React from "react";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import moment from "moment";
import _ from "lodash";
import { FormControl, FormGroup, InputGroup } from "react-bootstrap";

import {
  FlexRowDiv,
  FlexColDiv,
  FlexDiv
} from "../../../styles/container-elements";
import ShipperLocationSelect from "./ShipperLocationSelect";
import { isRequired } from "../MilestoneFormFieldDefs";
import StandardInput from "../../../components/forms/inputs/StandardInput";
import SelectInput from "../../../components/forms/inputs/SelectInput";

const getFormElems = (
  data,
  formFields,
  role,
  selectedShipper,
  timezone,
  updateField
) => {
  if (!formFields) {
    return null;
  }

  let elems = [];

  formFields.forEach(item => {
    let requiredAsterisk =
      isRequired(item, {
        role,
        milestoneEvent: data.MILESTONE_EVENT.value
      }) === true ? (
        <sup>*</sup>
      ) : null;
    if (item.type === "input") {
      const inputVal =
        item.dependency && data[item.dependency]
          ? data[item.dependency].value || data[item.dependency]
          : data[item.key];

      elems.push(
        <FlexDiv key={item.key} style={{ width: "31%", marginRight: "1em" }}>
          <StandardInput
            label={
              <span>
                {item.label}
                {requiredAsterisk}
              </span>
            }
            value={inputVal}
            onChange={
              !_.isNil(item.editable) && item.editable === false
                ? () => null
                : value => updateField(item.key, value)
            }
            isDisabled={!_.isNil(item.editable) ? !item.editable : false}
          />
        </FlexDiv>
      );
    }

    if (item.type === "select") {
      if (item.dependency) {
        const dependentVal =
          item.dependency === "ROLE" ? role : data[item.dependency].value;

        if (dependentVal && dependentVal !== "" && item.options[dependentVal]) {
          elems.push(
            <FlexDiv
              key={item.key}
              style={{ width: "31%", marginRight: "1em" }}
            >
              <SelectInput
                label={
                  <span>
                    {item.label}
                    {requiredAsterisk}
                  </span>
                }
                options={item.options[dependentVal]}
                value={data[item.key].value || null}
                onChange={value => {
                  let selected = item.options[dependentVal].find(
                    item => item.value === value
                  );
                  updateField(item.key, selected);
                }}
              />
            </FlexDiv>
          );
        }
      } else {
        elems.push(
          <FlexDiv key={item.key} style={{ width: "31%", marginRight: "1em" }}>
            <SelectInput
              label={
                <span>
                  {item.label}
                  {requiredAsterisk}
                </span>
              }
              options={item.options}
              value={data[item.key].value || null}
              onChange={value => {
                let selected = item.options.find(item => item.value === value);
                updateField(item.key, selected);
              }}
            />
          </FlexDiv>
        );
      }
    }

    if (item.type === "datetime") {
      /* Min = now rounded to next half hour */
      const now = moment();
      const remainder = 30 - (now.minute() % 30);
      let min = now.clone().add(remainder, "minutes");

      /* Max is min + 60 days */
      let max = min.clone().add(60, "days");

      elems.push(
        <FlexDiv key={item.key} style={{ width: "31%", marginRight: "1em" }}>
          <FlexColDiv style={{ padding: "0 .5em", width: "100%" }}>
            <FlexDiv
              style={{
                color: "rgb(39, 48, 66)",
                marginBottom: ".25em"
              }}
              className="timeLabel"
            >
              <span>
                {item.label}
                {requiredAsterisk}
              </span>
            </FlexDiv>
            <DateTimePicker
              style={{ width: "100%" }}
              defaultValue={data[item.key]}
              value={data[item.key]}
              onChange={value => updateField(item.key, value)}
              format="l HH:mm"
              max={max.toDate()}
            />
            {timezone ? (
              <FlexDiv
                style={{
                  flex: 1,
                  height: "3em",
                  fontSize: "9pt",
                  marginTop: ".5em"
                }}
              >
                {`${timezone} (GMT${moment.tz(timezone).format("Z z")})`}
              </FlexDiv>
            ) : null}
          </FlexColDiv>
        </FlexDiv>
      );
    }

    if (item.type === "textarea") {
      elems.push(
        <FlexDiv key={item.key} style={{ width: "95%" }}>
          <FlexColDiv style={{ flex: 1, paddingLeft: ".5em" }}>
            <FormGroup>
              <span>
                {item.label}
                {requiredAsterisk}
              </span>
            </FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <FormControl
                as="textarea"
                value={data[item.key]}
                onChange={value => updateField(item.key, value.target.value)}
                style={{ resize: "none" }}
              />
            </InputGroup>
          </FlexColDiv>
        </FlexDiv>
      );
    }

    if (item.type === "shipperlocationselect") {
      elems.push(
        <FlexDiv key={item.key} style={{ width: "31%", marginRight: "1em" }}>
          <FlexColDiv style={{ flex: 1, paddingLeft: ".5em" }}>
            <FormGroup style={{ marginBottom: ".3em" }}>
              <span>
                {item.label}
                {requiredAsterisk}
              </span>
            </FormGroup>
            <InputGroup style={{ width: "100%" }}>
              <ShipperLocationSelect
                onChange={data => updateField(item.key, data)}
                value={data[item.key]}
                selectedShipper={selectedShipper}
              />
            </InputGroup>
          </FlexColDiv>
        </FlexDiv>
      );
    }
  });

  return elems;
};

const getFormList = (data, formFields, updateField) => {
  if (!formFields) {
    return null;
  }

  const item = formFields.find(obj => obj.type === "list");

  return item ? (
    <FlexDiv key={item.key} style={{ width: "95%" }}>
      <FlexColDiv style={{ flex: 1, paddingLeft: ".5em" }}>
        <FormGroup>
          <span>
            {item.label}
            {item.required === true ? <sup>*</sup> : null}
          </span>
        </FormGroup>
        <InputGroup style={{ width: "100%" }}>
          <FormControl
            as="textarea"
            value={data[item.key]}
            onChange={value => updateField(item.key, value.target.value)}
            style={{ resize: "none" }}
            rows={15}
          />
        </InputGroup>
      </FlexColDiv>
    </FlexDiv>
  ) : null;
};

const CreateMilestoneForm = ({
  data,
  formFields,
  role,
  selectedShipper,
  timezone,
  updateField
}) => {
  const formElems = getFormElems(
    data,
    formFields,
    role,
    selectedShipper,
    timezone,
    updateField
  );

  const listElem = getFormList(data, formFields, updateField);

  return (
    <FlexRowDiv style={{ padding: "1em" }}>
      <FlexDiv style={{ width: "25%" }}>{listElem}</FlexDiv>
      <FlexDiv style={{ marginLeft: "1em", width: "75%" }}>
        <FlexRowDiv style={{ flexWrap: "wrap" }}>{formElems}</FlexRowDiv>
      </FlexDiv>
    </FlexRowDiv>
  );
};

export default CreateMilestoneForm;
