/** @jsx jsx */
import { jsx } from "@emotion/core";
import { LadChicletCSS as Chiclet } from "../../../components/chiclets";
import { getLadName } from "../../../components/lads";
import { FlexDiv, FlexRowDiv } from "../../../styles/container-elements";
import colors from "../../../styles/colors";

const LadRowItem = ({ lad, ind, showLadsPanel, eventHandler, active }) => {
  return (
    <FlexRowDiv
      css={{
        backgroundColor:
          ind % 2 === 0
            ? colors.highlight.DARK_GRAY
            : colors.background.DARK_GRAY,
        minHeight: "60px",
        maxHeight: "60px",
        ":hover": {
          backgroundColor: colors.background.DARK_TEAL,
          cursor: "pointer"
        }
      }}
      onClick={() => eventHandler(lad, "SELECT_LAD")}
    >
      <Chiclet lad={lad} active={active} showLadLabel showLobLabel square />
      {showLadsPanel ? (
        <FlexDiv
          css={{
            alignItems: "center",
            color: "white",
            fontWeight: 400,
            marginLeft: ".5em"
          }}
        >
          {getLadName(lad)}
        </FlexDiv>
      ) : null}
    </FlexRowDiv>
  );
};

export default LadRowItem;
