/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import Dialog from "react-bootstrap-dialog";
import { withTranslation } from "react-i18next";

import { getActiveOrganization } from "../../organizations/OrganizationsState";
import { deleteUser } from "../UsersState";

import {
  ModalHeader,
  ModalBody,
  ModalFooter,
  ReadOnlyInput,
  FormButton
} from "../../../components/modal-elems";
import Colors from "../../../styles/colors";
import { FlexRowDiv } from "../../../styles/container-elements";

const DeleteUserModal = ({
  activeOrganization,
  deleteUser,
  hide,
  show,
  user,
  t
}) => {
  let dialog = useRef();

  useEffect(() => {
    Dialog.setOptions({
      defaultOkLabel: t("users:Yes"),
      defaultCancelLabel: t("users:No"),
      primaryClassName: "btn-success"
    });
  });

  const userEmail = useMemo(() => (user && user.email ? user.email : ""), [
    user
  ]);

  const org = useMemo(
    () =>
      activeOrganization && activeOrganization.org_name
        ? activeOrganization.org_name
        : "",
    [activeOrganization]
  );

  return (
    <Modal
      backdrop={"static"}
      show={show}
      onHide={() => {
        hide();
      }}
    >
      <ModalHeader title={t("users:Delete User")} />
      <ModalBody>
        <FlexRowDiv style={{ marginTop: 0 }}>
          <ReadOnlyInput
            label={t("users:Organization")}
            value={org}
            valueStyles={{ color: Colors.highlight.GREEN, fontWeight: "bold" }}
          />
        </FlexRowDiv>
        <FlexRowDiv style={{ marginTop: "1em" }}>
          <ReadOnlyInput
            label={t("users:Email")}
            value={userEmail}
            valueStyles={{
              color: "black",
              fontStyle: "italic",
              fontWeight: "bold"
            }}
          />
        </FlexRowDiv>
      </ModalBody>
      <ModalFooter>
        <FormButton
          style={{ marginRight: ".5em" }}
          label={t("users:Cancel")}
          clickHandler={() => hide()}
        />
        <FormButton
          actionType="DANGER"
          label={t("users:Delete")}
          clickHandler={() => {
            dialog.show({
              body: t("users:Are you sure you want to delete this user?"),
              actions: [
                Dialog.CancelAction(dialog => {
                  dialog.hide();
                }),
                Dialog.OKAction(() => {
                  deleteUser(user, activeOrganization.organization_id);
                  hide();
                })
              ],
              size: "sm",
              onHide: dialog => {
                dialog.hide();
              }
            });
          }}
        />
        <Dialog
          ref={el => {
            dialog = el;
          }}
        />
      </ModalFooter>
    </Modal>
  );
};

DeleteUserModal.propTypes = {
  activeOrganization: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  user: PropTypes.object,
  t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    activeOrganization: getActiveOrganization(state)
  };
}

const mapDispatchToProps = {
  deleteUser
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(["users"])(DeleteUserModal));
