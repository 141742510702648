import { connect } from "react-redux";
import FilterSection from "../../../components/search-bar/FilterSection";
import ApiLogsSearchBarState from "./ApiLogsSearchBarState";
import DomainDataState from "../../domain-data/DomainDataState";
import { FILTERS } from "./ApiLogsFilterDefs";

const mapStateToProps = state => ({
  shipperFilterOptions: DomainDataState.selectors.getShippers(state).map(s => {
    return { value: s.fv_id, label: s.name };
  }),
  searchFilters: ApiLogsSearchBarState.selectors.getSearchFilters(state)
});

const mapDispatchToProps = dispatch => {
  const {
    setSearchFilter,
    clearSearchFilter,
    searchEntities
  } = ApiLogsSearchBarState.actionCreators;
  return {
    setSearchFilter: (key, value) => dispatch(setSearchFilter(key, value)),
    clearSearchFilter: key => dispatch(clearSearchFilter(key)),
    searchEntities: solutionId => dispatch(searchEntities(solutionId, true))
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  filtersMetadata: FILTERS
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(FilterSection);
