/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import _ from "lodash";

import DomainDataState from "../../domain-data/DomainDataState";

const propTypes = {
  shipperId: PropTypes.number.isRequired
};

/**
 * Displays the Shipper Name for a location's Shipper ID
 */
const LocationShipper = ({ shipperId }) => {
  const shippers = useSelector(DomainDataState.selectors.getShippers);
  const shipper = _.find(shippers, { id: shipperId });

  return <div data-qa="location-shipper">{shipper ? shipper.name : ""}</div>;
};

LocationShipper.propTypes = propTypes;

export default LocationShipper;
