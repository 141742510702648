/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import fileDownload from "js-file-download";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

import { MdAdd } from "react-icons/md";
import DownloadDataButton from "../../components/saved-search/DownloadDataButton";
import Loader from "react-loader";

import AddUserModal from "./components/AddUserModal";
import ImportUsersModal from "./components/ImportUsersModal";
import UserActiveModal from "./components/UserActiveModal";
import EditUserModal from "./components/EditUserModal";
import DeleteUserModal from "./components/DeleteUserModal";
import UserPasswordModal from "./components/UserPasswordModal";
import BasicSearchBar from "../../components/search-bar/BasicSearchBar";
import UsersTable from "./components/UsersTable";

export const ModalMode = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  BLOCK: "BLOCK",
  UNBLOCK: "UNBLOCK",
  RESET: "RESET",
  IMPORT: "IMPORT"
};

const convertDataToCSV = (data, roles, t) => {
  let csvData = "";

  // Generate the header
  csvData += `${t("users:Email")},${t("users:Roles")},${t("users:Status")},${t(
    "users:Last Login"
  )},${t("users:No of Logins")}\n`;

  // Generate the body
  for (let j = 0, ld = data.length; j < ld; j++) {
    let d = data[j];
    let m = moment(d.last_login);

    let lastLogin = m.isValid()
      ? `${m.format("HH:mm")} ${m.format("M/D/YY")}`
      : "";

    let roleNames = roles
      ? roles.filter(role => role.users.includes(d.user_id)).map(r => r.name)
      : null;
    roleNames = roleNames ? roleNames.join("|").toLowerCase() : roleNames;

    csvData += `${d.email},${roleNames},${d.status},${lastLogin},${d.logins_count}\n`;
  }

  return csvData;
};

const saveCSVFile = (users, roles, t) => {
  let exportFileName = `fv-users${Date.now()}.csv`;
  let csvText = convertDataToCSV(users, roles, t);

  fileDownload(csvText, exportFileName);

  return 0;
};

const UsersView = ({
  currentOrganizationId,
  fetchRoles,
  fetchOrganizationMembers,
  isOrganizationMembersLoaded,
  organizationMembers,
  roles,
  t
}) => {
  const [showModal, setShowModal] = useState({ modalMode: null, user: null });
  const [searchStr, setSearchStr] = useState("");

  useEffect(() => {
    fetchRoles();
    fetchOrganizationMembers(currentOrganizationId);
  }, [currentOrganizationId, fetchOrganizationMembers, fetchRoles]);

  const closeModal = () => {
    setShowModal({ modalMode: null, user: null });
  };

  const rowActionHandler = (action, val) => {
    setShowModal({ modalMode: ModalMode[action], user: val });
  };

  // filter bad data until it cleaned up
  let filteredUsers = organizationMembers
    ? _.filter(organizationMembers, function(o) {
        return o.name !== "<User Not Found>";
      })
    : [];

  filteredUsers = searchStr
    ? filteredUsers.filter(u => {
        return (
          (u.first_name &&
            u.first_name.toLowerCase().includes(searchStr.toLowerCase())) ||
          (u.last_name &&
            u.last_name.toLowerCase().includes(searchStr.toLowerCase())) ||
          (u.email && u.email.toLowerCase().includes(searchStr.toLowerCase()))
        );
      })
    : filteredUsers;

  return (
    <div className="p-3">
      <div className="d-flex">
        <div className="flex-grow-1">
          <BasicSearchBar
            placeholder={t("users:Search for user")}
            value={searchStr}
            changeHandler={searchStr => setSearchStr(searchStr)}
          />
        </div>

        <Button
          variant="success"
          className="ml-2"
          onClick={() =>
            setShowModal({ modalMode: ModalMode.CREATE, user: null })
          }
        >
          <MdAdd /> {t("users:Add New User")}
        </Button>

        <Button
          variant="success"
          className="ml-2"
          onClick={() =>
            setShowModal({ modalMode: ModalMode.IMPORT, user: null })
          }
        >
          {t("users:Import Users")}
        </Button>
      </div>
      <Loader loaded={isOrganizationMembersLoaded} />

      <div className="d-flex py-3">
        <DownloadDataButton
          onClick={() => saveCSVFile(filteredUsers, roles, t)}
          className="ml-auto pr-0"
          style={{
            fontSize: "14",
            color: "#999"
          }}
        />
      </div>

      <div>
        <UsersTable
          data={filteredUsers}
          roles={roles}
          actionHandler={rowActionHandler}
        />
      </div>
      <AddUserModal
        show={showModal.modalMode === ModalMode.CREATE}
        hide={() => closeModal()}
      />
      <ImportUsersModal
        show={showModal.modalMode === ModalMode.IMPORT}
        hide={() => closeModal()}
      />
      <UserActiveModal
        show={
          showModal.modalMode === ModalMode.BLOCK ||
          showModal.modalMode === ModalMode.UNBLOCK
        }
        hide={() => closeModal()}
        user={showModal.user}
      />
      <EditUserModal
        show={showModal.modalMode === ModalMode.UPDATE}
        hide={() => closeModal()}
        user={showModal.user}
      />
      <DeleteUserModal
        show={showModal.modalMode === ModalMode.DELETE}
        hide={() => closeModal()}
        user={showModal.user}
      />
      <UserPasswordModal
        show={showModal.modalMode === ModalMode.RESET}
        hide={() => closeModal()}
        user={showModal.user}
      />
    </div>
  );
};

UsersView.propTypes = {
  currentOrganizationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  fetchRoles: PropTypes.func.isRequired,
  fetchOrganizationMembers: PropTypes.func.isRequired,
  isOrganizationMembersLoaded: PropTypes.bool.isRequired,
  organizationMembers: PropTypes.array,
  roles: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation(["users"])(UsersView);
