/** @jsx jsx */
import PropTypes from "prop-types";

import { jsx } from "@emotion/core";
import { useTranslation } from "react-i18next";

import { getLadName } from "../../components/lads";
import FormRow from "../../components/forms/FormRow";

import GeofenceEditForm from "../geofence-edit/GeofenceEditForm";
import LocationShipper from "../location/components/LocationShipper";
import StandardInput from "../../components/forms/inputs/StandardInput";
import LADSelectInput from "../../components/forms/inputs/LADSelectInput";
import SelectInput from "../../components/forms/inputs/SelectInput";

const LocationEditForm = ({
  data,
  lads,
  updateField,
  updateGeofence,
  isReadOnly,
  isTracing,
  editingPolygonIndex,
  addPolygon,
  deletePolygon,
  cancelTracing,
  showUpdateGeofenceTypeModal,
  countries,
  subdivisions,
  pushLocationsScreen,
  isCarrierOrg
}) => {
  const { t } = useTranslation("location-edit");

  const countryOptions = countries
    ? countries.map(c => {
        return { value: c.code, label: c.name };
      })
    : [];

  const subdivisionOptions = subdivisions
    ? subdivisions.map(s => {
        // H1-1020: Use full subdivision name as value if code is numeric
        return { value: isNaN(s.code) ? s.code : s.name, label: s.name };
      })
    : [];

  const label =
    subdivisions && subdivisions.length > 0 ? subdivisions[0].type : "";

  let ladOptions;
  if (Array.isArray(lads)) {
    ladOptions = lads.map(l => {
      const ladName = getLadName(l);
      const lobName = l.lob_name;
      return { value: l.id, label: `${ladName} (${lobName})` };
    });
  } else {
    const keys = Object.keys(lads);
    for (let keyIndex = 0; keyIndex < keys.length; keyIndex++) {
      ladOptions = lads[keys[keyIndex]].map(l => {
        const ladName = getLadName(l);
        const lobName = l.lob_name;
        return { value: l.org_lad_id, label: `${ladName} (${lobName})` };
      });
    }
  }

  // Skip LAD lookup for Carriers (LADs data will not match the dereferenced location LAD ID)
  if (isCarrierOrg && data && data.lad) {
    const l = data.lad;
    ladOptions = [{ ...l, value: l.id, label: `${l.name} (${l.lob_name})` }];
  }

  return (
    <div
      css={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        padding: "1em",
        backgroundColor: "white"
      }}
    >
      {isReadOnly ? (
        <div css={{ display: "flex", alignItems: "center" }}>
          {isCarrierOrg && (
            <div css={{ fontSize: "1.25em" }}>
              <LocationShipper shipperId={data.customer_id} />
            </div>
          )}
        </div>
      ) : null}
      <FormRow>
        <StandardInput
          label={t("location-edit:Location Name")}
          value={data.name}
          onChange={value => updateField("name", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow divider={true}>
        <LADSelectInput
          label={t("location-edit:Location Type")}
          options={ladOptions}
          value={data.lad ? Number(data.lad.id) : null}
          onChange={value => {
            updateField("lad", value);
          }}
          lads={isCarrierOrg ? ladOptions : lads}
          isReadOnly={isReadOnly}
          selectedLad={data.lad}
        />
        <StandardInput
          label={t("location-edit:Location Code")}
          value={data.code}
          onChange={value => updateField("code", value)}
          isReadOnly={isReadOnly}
        />
        <SelectInput
          label={t("location-edit:Country")}
          options={countryOptions}
          value={data.country}
          onChange={value => updateField("country", value)}
          isReadOnly={isReadOnly}
          minWidth="10em"
        />
      </FormRow>
      <FormRow>
        <StandardInput
          label={t("location-edit:Address 1")}
          value={data.address}
          onChange={value => updateField("address", value)}
          isReadOnly={isReadOnly}
        />
        <StandardInput
          label={t("location-edit:Address 2")}
          value={data.address2}
          onChange={value => updateField("address2", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      <FormRow divider={true}>
        <StandardInput
          label={t("location-edit:City")}
          value={data.city}
          onChange={value => updateField("city", value)}
          isReadOnly={isReadOnly}
        />
        <SelectInput
          label={label}
          options={subdivisionOptions}
          value={data.state}
          onChange={value => updateField("state", value)}
          isReadOnly={isReadOnly}
          minWidth="12em"
        />
        <StandardInput
          label={t("location-edit:Postal Code")}
          value={data.postal_code}
          onChange={value => updateField("postal_code", value)}
          isReadOnly={isReadOnly}
        />
      </FormRow>
      {data.geofence && (
        <FormRow>
          <GeofenceEditForm
            isTracing={isTracing}
            editingPolygonIndex={editingPolygonIndex}
            data={data}
            updateGeofence={updateGeofence}
            addPolygon={addPolygon}
            cancelTracing={cancelTracing}
            deletePolygon={deletePolygon}
            showUpdateGeofenceTypeModal={showUpdateGeofenceTypeModal}
            isReadOnly={isReadOnly}
          />
        </FormRow>
      )}
    </div>
  );
};

LocationEditForm.propTypes = {
  addPolygon: PropTypes.func,
  cancelTracing: PropTypes.func,
  countries: PropTypes.any,
  data: PropTypes.any,
  deletePolygon: PropTypes.func,
  editingPolygonIndex: PropTypes.any,
  isCarrierOrg: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isTracing: PropTypes.bool,
  lads: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  pushLocationsScreen: PropTypes.any,
  showUpdateGeofenceTypeModal: PropTypes.any,
  subdivisions: PropTypes.any,
  updateField: PropTypes.any,
  updateGeofence: PropTypes.any
};

export default LocationEditForm;
